
<template>
  <el-container class="padding nt-height-100">
    <el-header class="no-margin no-padding height-auto">
      <el-row type="flex" justify="space-between">
        <el-form inline @keyup.enter.native="onSearchQueryClick">
          <nt-form-item label="店铺">
            <nt-dropdown-grid configKey="shop" v-model="queryModel.shopId" style="width:200px;"
              v-model:text="queryModel.shopName" placeholder="请选择店铺" :searchable="true"
              :iconSearch="false"></nt-dropdown-grid>
          </nt-form-item>
          <nt-form-item label="名称">
            <el-input placeholder="请输入名称查询" v-model="queryModel.storeName"></el-input>
          </nt-form-item>
          <nt-form-item label="备注">
            <el-input placeholder="请输入备注查询" v-model="queryModel.remark"></el-input>
          </nt-form-item>
          <nt-form-item>
            <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
          </nt-form-item>
        </el-form>
        <div>
          <el-button type="primary"> 面单回收 </el-button>
          <el-button type="primary"> 物流轨迹 </el-button>
          <el-button type="primary"> 面单余额 </el-button>
        </div>

      </el-row>

    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="面单编号" width="160" prop="waybillNo"></el-table-column>
        <el-table-column label="订单编号" width="160" prop="orderNo"></el-table-column>
        <el-table-column label="所属门店" width="120" prop="shopName"></el-table-column>
        <el-table-column label="买家" width="120" prop="buyerName"></el-table-column>
        <el-table-column label="买家电话" width="120" prop="buyerTel"></el-table-column>
        <el-table-column label="收货地址" width="220" prop="buyerAddress"></el-table-column>
        <el-table-column label="买家备注" width="120" prop="buyerRemark"></el-table-column>
        <el-table-column label="商品信息" width="220" prop="platform"></el-table-column>
        <el-table-column label="发货地址" width="160" prop="senderAddress"></el-table-column>
        <el-table-column label="所属网点" width="160" prop="way_dept_no"></el-table-column>
        <el-table-column label="包裹序号" width="160" prop="packageSn"></el-table-column>
        <el-table-column label="面单状态" width="160" prop="waybillStatus"></el-table-column>
        <el-table-column label="ERP单号" width="160" prop="erpOrderNo"></el-table-column>
        <el-table-column label="同步结果" width="160" prop="erpOrderNo"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "StoreList",
  mixins: [DataListMixin],
  components: {
    NtFormItem
  },
  data() {
    return {

    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_WAYBILL, params);
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },

    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.PLATFORM_WAYBILL, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>