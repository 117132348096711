<template>
  <div class=" padding nt-bg-white border" style="height:200px;border-radius:2px;box-sizing: border-box; box-shadow: var(--el-box-shadow-light);">
    <div class="display-flex space-between">
      <div>
        <span style="font-size:12px;color:#666;">统计数据每小时更新一次({{ formatDate(summaryInfo.lastTime) }})</span>
        <el-button size="small" @click="onRefreshClick" link type="primary" :loading="isLoading">刷新</el-button>
      </div>
      <!-- <el-button type="primary" style="font-size:20px;height:40px;" @click="onStartClick">打印发货</el-button> -->
    </div>
    <div class="margin-top" style="column-count:6;column-gap: 0;">
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">今日订单</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.orderCount}}</div>
      </div>
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">今日交易</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.orderTotal}}</div>
      </div>
      
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">今日打印数</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.waybillPrintCount}}</div>
      </div>
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">今天发货数</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.waybillDispatchCount}}</div>
      </div>
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">待发货</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.allUnSendCount}}</div>
      </div>
      <div class="nt-height-100 align-center" style="cursor:pointer;">
        <span style="font-size:48px;color:var(--el-color-primary-light-5);" class="iconfont icon-shipinhaoxiaodian1"></span>
        <div class="margin">已打印未发货</div>
        <div style="font-weight:bold;color:var(--el-color-primary);">{{summaryInfo.unSendPrintCount}}</div>
      </div>
    </div>
    <div class=" margin-top">
      <el-descriptions >
        <el-descriptions-item label="视频号打印组件">
          <el-icon v-if="isWxConnected" color="var(--el-color-success)"><CircleCheck  /></el-icon>
          <el-icon v-else color="var(--el-color-danger)"><CircleClose  /></el-icon>
          {{isWxConnected? '已连接':'未连接'}}
          <el-button link type="primary" size="small" v-if="!isWxConnected">帮助</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="菜鸟打印组件">
          <el-icon v-if="isCnConnected" color="var(--el-color-success)"><CircleCheck  /></el-icon>
          <el-icon v-else color="var(--el-color-danger)"><CircleClose  /></el-icon>
          {{isCnConnected? '已连接':'未连接'}}
          <el-button link type="primary" size="small" v-if="!isCnConnected">帮助</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="定时预发货">
          <span>{{preDispatchConfig.isOpen? `已开启(每日${preDispatchConfig.hours}时${preDispatchConfig.minutes}分)`:'未开启'}} </span>
          <el-button link type="primary" size="small" @click="onPreDispachClick"> 设置</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </div>  
    <!-- <div>
      <span>正在检查： 1 打印组件（ok）  2 发货地址(ok) 3 面单模版 </span>
    </div> -->
  </div>
  
</template>

<script>
  import {getHomeSummary} from '@/service/report/rpt_platform_order'
  import { LoginUserStore } from '@/piana/login_user_service'
  import { useCainiaoPrintServiceStore } from '@/piana/cainiao_printer_service.js'
  import { useWeixinPrintServiceStore } from '@/piana/weixin_printer_service.js'
  import * as dayjs from 'dayjs'
  export default {
    name: "print_home",
    data() {
      return {
        summaryInfo:{},
        isLoading:false,
        preDispatchConfig:{},
        mainShop:null
      }
    },
    computed:{
      isCnConnected:function() {
        return useCainiaoPrintServiceStore().isConnected
      },
      isWxConnected:function() {
        return useWeixinPrintServiceStore().isConnected
      }
    },
    created(){
      
      let configObj = LoginUserStore().getConfig('AUTO_PRE_DISPATCH') // 预发货配置
      this.preDispatchConfig = JSON.parse(configObj?.configValue||null) || {isOpen:false}
      this.getSummary()
    },
    methods:{
      async getSummary(isReload=false){
        this.isLoading = true
        let res = await getHomeSummary({reload:isReload})
        this.isLoading = false
        if (res.code == 200) {
          this.summaryInfo = res.data
        } else {
          this.$message.error(res.msg || '获取数据失败')
        }
      },
      onStartClick(){
        this.$router.push('/print/index')
      },
      onPreDispachClick(){
        this.$router.push('/print/preDispatch')
      },
      onRefreshClick(){
        this.getSummary(true)
      },
      formatDate(timestamp){
        return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
</script>

<style scoped>

</style>