<template>
  <el-table-column v-bind="$attrs" class-name="no-padding">
    <template #header>
      <div class="display-flex align-left" style="column-gap:2px;">
        <div style="flex-basis: 120px;">
          <span>出库仓</span>
          <el-tooltip placement="top" content="erp系统将同步扣减当前仓库的库存">
            <el-icon><InfoFilled /></el-icon>
          </el-tooltip></div>
        <div class="goods-name align-left flex-auto">商品信息</div>
      </div>
    </template>
    <template #default="{ row }">
      <el-row :gutter="8"  v-for="(item, index) in (row.orderItemList)">
        <el-col style="flex-basis: 120px;">
          <nt-dropdown-grid key="ndg_store"  configKey="store"  v-model="item.storeId"  v-model:text="item.storeName"
            placeholder="请选择出库仓" :searchable="true"
          ></nt-dropdown-grid>
        </el-col>
        <el-col style="flex-basis:32px;">
          <el-avatar :size="32" shape="square" :src="item.goodsThumb" />
          
        </el-col>
        <el-col class="flex-auto">
          <div class="goods-title"  style="line-height:16px;height:16px;font-size:14px;">
            <el-text  line-clamp="1">{{ item.goodsName }}</el-text>
          </div>
          <div class="goods-price">{{ item.price }} *{{ item.quantity }}</div>
        </el-col>
        <el-icon class="absolute right-top" v-if="item.erpGoodsId" @click="onLinkClick(item)"><View /></el-icon>
        <el-icon class="absolute right-top" v-else style="color:red;" @click="onLinkClick(item)"><Connection /></el-icon>
      </el-row>
      
    </template>
    
  </el-table-column>
  <el-dialog append-to-body v-model="dialogVisible"  destroyOnClose width="450" class="nt-resource-dialog" title="ERP商品选择" :close-on-click-modal="false"
    @close="dialogVisible = false">
    <erp-goods-choose @choose="onGoodsChooseHandler" @cancel="dialogVisible = false" v-if="dialogVisible"></erp-goods-choose>
  </el-dialog>
</template>
<script >

import {bindSkuToErpSku} from '@/service/resource/platform_goods'
import ErpGoodsChoose from '../goods/erp_goods_choose'
export default {
  data() {
    return {
      dialogVisible:false,
      record:null
    }
  },
  components:{ErpGoodsChoose},
  props: {
    orderItemList:{
      type:Array,
      required:true
    }
  },
  methods: {
    
    onLinkClick(row){
      this.record = row
      this.dialogVisible = true
    },
    async onGoodsChooseHandler(erpGoodsInfo){
      let res = await bindSkuToErpSku({
        skuList: this.record.goodsSkuId,
        erpGoodsId:erpGoodsInfo.goodsId,
        erpUnitId:erpGoodsInfo.unitId,
        erpSkuId: erpGoodsInfo.erpSkuId
      })
      if(res.code == 200){
        this.$message.success('关联成功')
      }else {
        this.$message.error(res?.message || res?.msg || '关联商品失败')
      }
      this.dialogVisible = false
    }
  }
}
</script>
