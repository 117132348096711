/**
 * 面单查询条件
 */

// 面单查询
let  waybillQuery= [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_CREATE', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型', 
            options:[// 针对el-select 有效
                {label:'打印时间',value:'TIME_RANGE_PRINT'},
                {label:'面单获取时间',value:'TIME_RANGE_CREATE'},
                {label:'面单回收时间',value:'TIME_RANGE_CANCEL'}
            ]
        }
    },
    
    {
        queryKey:'waybillStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'面单状态', 
            options:[
                {label:'生效',value:'SUCCESS'},
                {label:'回收',value:'CANCELED'},
            ]
        }
    },
    {
        queryKey:'template', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'打印模板', 
            options:[
                {label:'生效',value:'SUCCESS'},
                {label:'回收',value:'CANCELED'},
            ]
        }
    },
    {
        queryKey:'template', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'打印状态', 
            options:[
                {label:'已打印',value:'SUCCESS'},
                {label:'未打印',value:'CANCELED'},
            ]
        }
    },
    {
        queryKey:'dispatchStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'发货状态', 
            options:[
                {label:'未发货',value:'SUCCESS'},
                {label:'待发货',value:'CANCELED'},
                {label:'已发货',value:'CANCELED'},
                {label:'预发货',value:'CANCELED'},
            ]
        }
    },
    {
        queryKey:'delivery', // 用于确定查询的字段
        cType: 'NtSelectDelivery', //组件类型
        label:'快递公司', //用于快捷配置显示标题
        defaultValue:{
            queryType:'delivery', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'快递公司',
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型', 
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{
            queryType:'RECEIVER_NAME', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    }
]



let  waybillPrintQuery= [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_PRINT', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型',
            options:[// 针对el-select 有效
                {label:'打印时间',value:'TIME_RANGE_PRINT'},
                {label:'面单获取时间',value:'TIME_RANGE_CREATE'},
                {label:'面单回收时间',value:'TIME_RANGE_CANCEL'}
            ]
        }
    },
    
    
    {
        queryKey:'delivery', // 用于确定查询的字段
        cType: 'NtSelectDelivery', //组件类型
        label:'快递公司', //用于快捷配置显示标题
        defaultValue:{
            queryType:'delivery', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'快递公司', 
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型', 
            queryType: null, // 默认不选择下拉框项
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{
            queryType:'RECEIVER_NAME', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    },
    {
        queryKey:'printCount', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'打印次数', //用于快捷配置显示标题
        defaultValue:{
            queryType:'PRINT_COUNT_GT_0', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'请选择打印次数', //
            size:"small",
            clearable:false,
            options:[// 针对el-select 有效
                {label:'打印1次',value:'PRINT_COUNT_EQ_1'},
                {label:'打印2次',value:'PRINT_COUNT_EQ_2'},
                {label:'打印3次',value:'PRINT_COUNT_EQ_3'},
                {label:'打印4次',value:'PRINT_COUNT_EQ_4'},
                {label:'打印5次',value:'PRINT_COUNT_EQ_5'},
                {label:'已打印',value:'PRINT_COUNT_GT_0'},
                {label:'打印超过1次',value:'PRINT_COUNT_GT_1'},
                {label:'打印超过2次',value:'PRINT_COUNT_GT_2'},
                {label:'打印超过3次',value:'PRINT_COUNT_GT_3'},
                {label:'打印超过4次',value:'PRINT_COUNT_GT_4'},
                {label:'打印超过5次',value:'PRINT_COUNT_GT_5'},
            ]
        }
    },
]

let  waybillDispathQuery= [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_DISPATCH', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型', 
            options:[// 针对el-select 有效
                {label:'按发货时间',value:'TIME_RANGE_DISPATCH'},
                {label:'按下单时间',value:'TIME_RANGE_CREATE'}
            ]
        }
    },
    
    {
        queryKey:'province', // 用于确定查询的字段
        cType: 'NtSelectProvince', //组件类型
        label:'所属省份', //用于快捷配置显示标题
        defaultValue:{
            queryType:'province', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'所属省份',
        }
    },
    
    {
        queryKey:'delivery', // 用于确定查询的字段
        cType: 'NtSelectDelivery', //组件类型
        label:'快递公司', //用于快捷配置显示标题
        defaultValue:{
            queryType:'delivery', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'快递公司',
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型', 
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    {
        queryKey:'weight', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品重量', //用于快捷配置显示标题
        defaultValue:{
            queryType:'weight', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'重量(起)', // 区间，待确
            endPlaceholder:'重量(止)',
            size:'small'
        }
    },
    {
        queryKey:'total', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'订单金额', //用于快捷配置显示标题
        defaultValue:{
            queryType:'total', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'金额(起)', // 区间，待确
            endPlaceholder:'金额(止)',
            size:'small'
        }
    },
    {
        queryKey:'volume', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品体积', //用于快捷配置显示标题
        defaultValue:{
            queryType:'volume', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'体积(起)', // 区间，待确
            endPlaceholder:'体积(止)',
            size:'small'
        }
    },
    {
        queryKey:'goodsQuantity', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品数量', //用于快捷配置显示标题
        defaultValue:{
            queryType:'quantity', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'数量(起)', // 区间，待确
            endPlaceholder:'数量(止)',
            size:'small'
        }
    },
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{
            queryType:'RECEIVER_NAME', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    },
]

let  waybillPreDispathQuery= [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_CREATE', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型',
            options:[// 针对el-select 有效
                {label:'按下单时间',value:'TIME_RANGE_CREATE',default:true},
                {label:'按付款时间',value:'TIME_RANGE_PAY'},
            ]
        }
    },
    
    {
        queryKey:'province', // 用于确定查询的字段
        cType: 'NtSelectProvince', //组件类型
        label:'所属省份', //用于快捷配置显示标题
        defaultValue:{
            queryType:'province', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'所属省份', 
        }
    },
    
    {
        queryKey:'delivery', // 用于确定查询的字段
        cType: 'NtSelectDelivery', //组件类型
        label:'快递公司', //用于快捷配置显示标题
        defaultValue:{
            queryType:'delivery', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'快递公司',
        }
    },
    {
        queryKey:'lockStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'锁定/异常状态', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'锁定/异常状态', 
            options:[
                {label:'未锁定',value:'INNER_UNLOCK'},
                {label:'已锁定',value:'INNER_LOCK'},
                {label:'有异常',value:'INNER_ABNORMAL'},
                {label:'无异常',value:'INNER_NO_ABNORMAL'},
                {label:'偏远地区',value:'INNER_FAR_AREA'}
            ]
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型', 
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    {
        queryKey:'weight', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品重量', //用于快捷配置显示标题
        defaultValue:{
            queryType:'weight', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'重量(起)', // 区间，待确
            endPlaceholder:'重量(止)',
            size:'small'
        }
    },
    {
        queryKey:'total', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'订单金额', //用于快捷配置显示标题
        defaultValue:{
            queryType:'total', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'金额(起)', // 区间，待确
            endPlaceholder:'金额(止)',
            size:'small'
        }
    },
    {
        queryKey:'volume', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品体积', //用于快捷配置显示标题
        defaultValue:{
            queryType:'volume', // 默认查询类型
        },
        props:{ // 组件属性
            startPlaceholder:'体积(起)', // 区间，待确
            endPlaceholder:'体积(止)',
            size:'small'
        }
    },
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{
            queryType:'RECEIVER_NAME', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    },
]


export {waybillQuery,waybillPrintQuery,waybillDispathQuery,waybillPreDispathQuery} 