<template>
  <el-container class="padding nt-height-100">
    <!-- <RegionGroup   v-model="region" style="width:100%;" >
        <template #default="{ region, visible }">
          <el-button></el-button>
        </template>
          
        </RegionGroup> -->
    <el-aside width="250px" class="margin-right-12 nt-box-panel nt-bg-white" v-spliter-directive>
      
      <category-page @change="onCategoryChange"/>
    </el-aside>
    <el-main class=" no-padding nt-box-panel">
      <el-tabs v-model="activeTabName"  class="nt-width-100 nt-height-100 nt-tab-page" >
      <el-tab-pane label="全部" name="all" lazy>
        <list-page key="all"></list-page>
      </el-tab-pane>
      <el-tab-pane label="客户" name="customer" lazy >
        <list-page :companyType="1" key="company1" ></list-page>
      </el-tab-pane>
      <el-tab-pane label="供应商" name="supplier" lazy >
        <list-page :companyType="2" key="company2"></list-page>
      </el-tab-pane>
      <el-tab-pane label="客户&供应商" name="ss" lazy >
        <list-page :companyType="3" key="company3"></list-page>
      </el-tab-pane>
    </el-tabs>
    </el-main>
  </el-container>

</template>
<script>
import listPage from './list'
import { computed } from 'vue'
import categoryPage from './category/'
// import {RegionGroup  } from 'v-region'
export default {
  name: "BIZ_COMPANY",
  components: {
    listPage,
    categoryPage,

    // RegionGroup
  },
  
  data() {
    return {
      activeTabName:'all',
      categoryId: null,
      region:null

    }
  },
  provide(){
    return {
      /* 计算属性使用说明： 默认provide的属性不会有响应特性，需通过computed 方法增加其响应式特性 */
      categoryId:computed(_=> this.categoryId) 
    }
  },
  methods: {
    onCategoryChange(categoryId){
      this.categoryId = categoryId
    }
  }
}
</script>