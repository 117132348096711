<template>
  <div class="display-flex flex-column row-gap-4" style="align-items: center;">
    
    <div v-if="order.orderExtra.mergeCnt>1" class="order-tag check merge">合并</div>
    <el-tooltip :content="order.orderExtra.lockRemark" v-if="order.orderExtra.isLocked">
      <template #default>
        <div  class="order-tag check lock">锁定</div>

      </template>
    </el-tooltip>

    <div v-if="order.orderExtra.packageCount>1" class="order-tag check multiple">多包</div>
    
    <el-tooltip :content="formatAbnormalText(order.orderExtra.abnormalFlag)" v-if="order.orderExtra.abnormalFlag>0">
      <template #default>
        <div  class="order-tag check abnormal">异常</div>
      </template>
    </el-tooltip>
    
  </div>
</template>

<script>

  export default {
    data () {
      return {
      }
    },
    props:{
      order:{}
    },
    methods:{
      formatAbnormalText(flag){
      // 格式化异常原因 1： 高亮偏远地区--含自定义  2 快递不可达 4 异常地址
      let info={
        '1': '偏远地区',
        '2': '快递不可达',
        '4': '异常地址'
      }
      
      let result = []
      if((flag & 1) == 1){
        result.push(info['1'])
      } 
      if((flag & 2) == 2){
        result.push(info['2'])
      } 
      if((flag) & 4 == 4){
        result.push(info['4'])
      }
      
      return result.join(',')
    },
    }
}
</script>
