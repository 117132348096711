
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>
          <div class="display-flex" style="align-items:center;">
            <div>采购统计({{queryModel.planName =='byGoods'? '按商品':'按分类'}})</div>
            <el-select v-model="queryModel.planName" @change="onPlanChange" style="width:140px;">
              <el-option value="byGoods" label="按商品"></el-option>
              <el-option value="byCategory" label="按分类"></el-option>
            </el-select>
          </div>
          </template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchClick">
        <el-form-item label="采购时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <component ref="refCurrent" :is="currentPage"></component>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  

</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import {markRaw} from 'vue'
import NtDateRange from '@/views/plugin/nt_date_range'
import ByGoodsPage from './purchase_total_goods'
import ByCategoryPage from './purchase_total_category'
import moment from 'moment'
export default {
  name: "purchase_goods",
  components: {
    NtRptLayout,
    NtRptToolbar,
    ByGoodsPage,
    NtDateRange
  },
  data() {
    return {
      // 查询条件
      currentPage:null,
      queryModel: {
        planName:'byGoods',
        startTime: moment().subtract(1, 'month').format('YYYY-MM-01'),
        endTime: moment().format('YYYY-MM-DD')
      }
    }
  },
  created(){
    this.pagesList=[markRaw(ByGoodsPage),markRaw(ByCategoryPage)]
    this.onPlanChange('byGoods')
    
  },
  methods: {
    onPlanChange(planName){
      this.currentPage = this.pagesList[planName=='byGoods'? 0:1]
    },
    onSearchClick() {
      if(this.$refs.refCurrent?._query){
        this.$refs.refCurrent._query(this.queryModel)
      } else {
        this.$message.error('当前页面找不到查询接口:_query')
      }
    },
    onExportClick() {
      if(this.$refs.refCurrent?._exportXlsx){
        this.$refs.refCurrent._exportXlsx(this.queryModel)
      } else {
        this.$message.error('当前页面找不到查询接口:_exportXlsx')
      }
    }
  }
}
</script>