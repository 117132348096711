<template>
  <div>
    <div class="table-page-search-wrapper" >
      <!-- 搜索区域 -->
      <a-form layout="inline" @keyup.enter.native="onSearchQueryClick">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="单据编号" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
              <a-input placeholder="请输入单据编号查询" v-model="queryModel.billNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="商品信息" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
              <a-input placeholder="条码|名称|规格|型号" v-model="queryModel.keyword"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                style="width: 100%"
                v-model="queryModel.createTimeRange"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
            <a-col :md="6" :sm="24">
              <a-button type="primary" @click="onQueryClick">查询</a-button>
              <a-button style="margin-left: 8px" @click="onSearchResetClick">重置</a-button>
            </a-col>
          </span>
        </a-row>
      </a-form>
    </div>
    <!-- table区域-begin -->
    <div class="billMain">
      <a-row :gutter="12">
        <a-col :span="14">
          <a-table
            style="width:100%"
            bordered
            ref="table"
            size="middle"
            rowKey="id"
            :columns="columns"
            :dataSource="datasource"
            :scroll="{x:true}"
            :pagination="false"
            :customRow="rowAction"
            :rowClassName="getRowClassName"
            v-loading="isLoading">
            
            <!-- <span slot="numberCustomRender" slot-scope="text, record">
              <a v-if="!queryModel.purchaseStatus" @click="myHandleDetail(record)">{{record.billNo}}</a>
              <span v-if="queryModel.purchaseStatus">{{record.billNo}}</span>
            </span> -->
            <template slot="customRenderStatus" slot-scope="text, record">
              <template v-if="!queryModel.purchaseStatus">
                <a-tag v-if="record.status === '0'" color="red">未审核</a-tag>
                <a-tag v-if="record.status === '1'" color="green">已审核</a-tag>
                <!-- <a-tag v-if="record.status === '2' && queryModel.subType === '采购订单'" color="cyan">完成采购</a-tag>
                <a-tag v-if="record.status === '2' && queryModel.subType === '销售订单'" color="cyan">完成销售</a-tag>
                <a-tag v-if="record.status === '3' && queryModel.subType === '采购订单'" color="blue">部分采购</a-tag>
                <a-tag v-if="record.status === '3' && queryModel.subType === '销售订单'" color="blue">部分销售</a-tag> -->
              </template>
              <!-- <template v-if="queryModel.purchaseStatus">
                <a-tag v-if="record.purchaseStatus === '0'" color="red">未采购</a-tag>
                <a-tag v-if="record.purchaseStatus === '2' && queryModel.subType === '销售订单'" color="cyan">完成采购</a-tag>
                <a-tag v-if="record.purchaseStatus === '3' && queryModel.subType === '销售订单'" color="blue">部分采购</a-tag>
              </template> -->
              
            </template>
          </a-table>
        </a-col>
        <a-col :span="10">
          <a-table
            size="middle"
            rowKey="id"
            bordered
            :pagination="false"
            style="width:100%;height:200px;background-color: #fff;"
            :scroll="{x:true}"
            :columns="columnsDetail"
            :dataSource="billHeader? billHeader.tradeItemList :[]"
            :rowSelection="{fixed:true,onChange: onSelectDetailChange, type: 'checkbox',
              getCheckboxProps:record => ({
                props: {
                  disabled: (record.finishQuantity||0)>= record.quantity
                  },
                })
              }
            ">
          </a-table>
        </a-col>
      </a-row>
    </div>
    <a-row type="flex" justify="space-between" class="margin-top">
      <a-pagination size="small"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :current="this.pageInfo.pageIndex"
        :pageSize="this.pageInfo.pageSize"
        :total="this.pageInfo.total"
        @change="onPageIndexChange"
        @showSizeChange="onPageSizeChange"
      />
      <a-space>
        <a-button @click="onCancelClick">取1消</a-button>
        <a-button type="primary"  @click="onConfirmClick">确定</a-button>
      </a-space>
    </a-row>
  </div>
  
</template>

<script>
  // import {getTradeList} from '@/service/gen'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  export default {
    name: 'LinkBillList',
    mixins:[DataListMixin],
    components: {
      // BillDetail
    },
    data () {
      return {
        title: "操作",
        selectedDetailRowKeys: [],
        selectBillDetailRows: [],
        billHeader:null,
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
          { title: '往来单位', dataIndex: 'companyName',width:120, ellipsis:true},
          { title: '单据编号', dataIndex: 'billNo',width:140 },
          { title: '单据日期', dataIndex: 'billTime',width:145},
          { title: '金额合计', dataIndex: 'total',width:100,
            customRender:function(t,r,index){
              return r.total/100;
            }},
          { title: '状态', dataIndex: 'status', width: 120, align: "center",
            scopedSlots: { customRender: 'customRenderStatus' }
          }
        ],
        goodsColumns:[
          { title: '商品', dataIndex: 'materialName',width:120,
            customRender:function(t,r,index){
              return r.material.name
            }
          },
        ],
        
        columnsDetail: [
          // { title: '条码', dataIndex: 'barcode',width:120},
          { title: '名称', dataIndex: 'materialName',width:100, ellipsis:true,
            customRender:function(t,r,index){
              return r.material.name
            }
          },
          // { title: '规格', dataIndex: 'standard',width:100, ellipsis:true},
          // { title: '型号', dataIndex: 'model',width:150, ellipsis:true},
          { title: '单位', dataIndex: 'unit',width:50,
            customRender:function(t,r,index){
              return r.materialExtend.unitName
            }
          },
          { title: '数量', dataIndex: 'quantity',width:80},
          { title: '已引用', dataIndex: 'finishQuantity',width:80},
          { title: '单价', dataIndex: 'price',width:80,
            customRender:function(t,r,index){
              return r.price/100;
            }
          },
          // { title: '金额', dataIndex: 'total',width:80,
          //   customRender:function(t,r,index){
          //     return r.total/100;
          //   }
          // },
          // { title: '税率(%)', dataIndex: 'taxRate', width:80},
          // { title: '税额', dataIndex: 'taxMoney', width:80,
          //   customRender:function(t,r,index){
          //     return r.total*(r.taxRate||0)/100;
          //   }
          // },
          { title: '价税合计', dataIndex: 'totalWidthTax', width:80,
            customRender:function(t,r,index){
              return r.totalWithTax/100;
            }
          },
          { title: '备注', dataIndex: 'remark',width:100, ellipsis:true},
        ]
      }
    },
    props:{
      billType:{
        type:String,
        required:true
      },
      companyId:{
        type:String,
        required:true
      }
    },
    watch:{
      
    },
    created() {
    },
    methods: {
      async _loadList(params){
        this.billHeader = null
        // todo 增加 过滤 已关闭单据
        return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({billType: this.billType, companyId:this.companyId, closeFlag:1, status:'1'},params));
      },
      _loadFinished(){
        if(this.datasource && this.datasource.length>0){
          this.billHeader = this.datasource[0]
        }
      },
      
      onSelectDetailChange(selectedRowKeys) {
        this.selectedDetailRowKeys = selectedRowKeys;
      },

      onConfirmClick () {
        if(this.selectedDetailRowKeys.length) {
          this.getSelectBillDetailRows()
          this.$emit('choose', this.selectBillDetailRows, this.billHeader)
        } else {
          this.$message.warning('抱歉，请选择单据明细！')
        }
        
      },
      
      onDateChange: function (value, dateString) {
        this.queryModel.startTime=dateString[0];
        this.queryModel.endTime=dateString[1];
      },
      onDateOk(value) {
        console.log(value);
      },
      onSearchResetClick() {
        this.queryModel = {
          billNo:'',
          keword:''
        }
        this.resetPage =true
        this.loadList()
      },
      onQueryClick(){
        this.resetPage =true
        this.loadList()
      },
      getSelectBillDetailRows() {
        if(!this.billHeader){
          this.selectBillDetailRows=[]
        } else{
          this.selectBillDetailRows = this.billHeader.tradeItemList.filter(el=> this.selectedDetailRowKeys.includes(el.id))
        }
      },
      getRowClassName(record){
        if(this.billHeader && this.billHeader.id==record.id){
          return 'row-selected'
        }
      },
      rowAction(record, index) {
        return {
          on: {
            click: () => {
              this.billHeader =record
            }
          }
        }
      },
      onCancelClick(){
        this.$emit('cancel');
      }
    }
  }
</script>

<style scoped lang="less">
:deep(.ant-table-row.row-selected){
  background-color: #e6f7ff;
}
</style>