<template>
    <!-- 从外部构建整个查询的query条件，根据key绑定query中的属性 -->
    <component v-model="queryModel[editor.queryKey]" :is="editor.cType" :label="editor.label"  v-for="editor in editors" v-bind="editor.props"></component>
</template>

<script>

import {
  NtSelect,
  NtSelectWithText,
  NtDateRange,
  NtTextRange,
  NtTextSearch,
  NtSelectShop,
  NtSelectFlag,
  NtDropdownSelector,
  NtSelectDelivery,
  NtSelectProvince
} from './component'

export default {
  data() {
    return {
      
    }
  },
  components:{
    NtSelect,
    NtSelectWithText,
    NtDateRange,
    NtTextRange,
    NtTextSearch,
    NtSelectShop,
    NtSelectFlag,
    NtDropdownSelector,
    NtSelectDelivery,
    NtSelectProvince
  },
  props:{
    queryModel:{
      type:Object
    },
    editors:{
      type:Array,
      default: _=>[]
    }
  },
  methods: {
    
  }
}
</script>