import { axios as service } from '@/utils/request'


export const getCompanySummaryList = (data) => {
  return service({
    url: '/report/company/summary',
    method: 'post',
    data
  })
}

/**
 * 往来单位 应收应付余额
 *  */ 
export const getPaymentBalanceList = (data) => {
  return service({
    url: '/report/company/paymentBalance',
    method: 'post',
    data
  })
}

/**
 * 往来单位 应收总账
 *  往来单位 期初 本期应收 本期已收 本期应付 本期已付 期末余额
 *  */ 
export const getPaymentSubtotalList = (data) => {
  return service({
    url: '/report/company/paymentSubtotal',
    method: 'post',
    data
  })
}

/**
 * 应收明细
 *   往来单位 期初 单据日期 单据类型 单据号 本期应收 本期已收 本期应付 本期已付 期末余额
 *  */ 
export const getPaymentDetailList = async (data) => {
  let res = await  service({
    url: '/report/company/paymentDetail',
    method: 'post',
    data
  })
  if(res.code===200){
    res.data = res.data.reduce((pre,cur)=>{
      let balance = cur.initTotal||0 // 账户期初余额
      cur.balance =  balance
      
      let children = cur.children
      delete cur.children
      pre.push(cur)
      let takeBillTotal = 0, takeSettleTotal=0,payBillTotal=0,paySettleTotal=0
      children?.forEach(el=>{
        balance +=  (el.takeBillTotal -el.takeSettleTotal) - (el.payBillTotal - el.paySettleTotal) // 明细的收入/支出金额
        el.balance = balance
        takeBillTotal += el.takeBillTotal
        takeSettleTotal += el.takeSettleTotal
        payBillTotal += el.payBillTotal
        paySettleTotal += el.paySettleTotal
        el.isDetail = true
        pre.push(el)
      })
       pre.push({companyName:'小计',
        balance:balance,
        takeBillTotal:takeBillTotal, takeSettleTotal:takeSettleTotal,
        payBillTotal:payBillTotal,paySettleTotal:paySettleTotal,
        isDetail:true
        }) // 增加往来单位小计
      return pre
    },[])
  }
  return res
}

/**
 * 往来单位 交易流水
 *  单据 日期  类型  单据总额  已核销  
 *  */ 
export const getTradeDetailList = (data) => {
  return service({
    url: '/report/company/tradeDetail',
    method: 'post',
    data
  })
}

/**
 * 往来单位 欠款汇总信息： 欠款往来单位数量， 总数量 ，总欠款
 *  单据 日期  类型  单据总额  已核销  
 *  */ 
export const getDebtSummary = (data) => {
  data = data || {}
  return service({
    url: '/report/company/debtSummary',
    method: 'post',
    data
  })
}


/**
 * 往来单位 超期应收应付款
 *  */ 
export const getOverduePayment = (data) => {
  data = data || {}
  return service({
    url: '/report/company/overduePayment',
    method: 'post',
    data
  })
}
/**
 * 往来单位  采购 销售业务单据--不含订单
 *  */ 
export const getTradeHeadExcludeOrder = (data) => {
  data = data || {}
  return service({
    url: '/report/company/tradeHeadList',
    method: 'post',
    data
  })
}


/**
 * 往来单位  收付款查询采购销售核销
 *  */ 
export const getPaymentCompareWidthTrade = (data) => {
  data = data || {}
  return service({
    url: '/report/company/paymentCompareTrade',
    method: 'post',
    data
  })
}

/**
 * 往来单位  采购销售查询付款核销情况
 *  */ 
export const getTradeCompareWidthPayment = (data) => {
  data = data || {}
  return service({
    url: '/report/company/tradeComparePayment',
    method: 'post',
    data
  })
}


