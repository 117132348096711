
<!-- 现金银行流水查询：
  账户类型  账户       日期      单据类型  摘要     单据编码 收入金额 支出金额  余额 对方账户名 备注 单据来源 
    现金    现金     期初余额                                          100
                      20220-01  付款单   付款-说明  No100     10      0        110  某公司
-->
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>现金银行流水查询</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="searchQuery">
        <el-form-item label="时间范围">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%"
        :row-class-name="getRowClassName"
        :data="datasource"
        v-loading="isLoading">
        <!--账户类型  账户       日期      单据类型  摘要     单据编码 收入金额 支出金额  余额 对方账户名 备注 单据来源  -->
        <el-table-column label="账户类型"  prop="accountTypeName" width="100">
        </el-table-column>
        <el-table-column label="账户名称"  prop="accountName" width="160">
        </el-table-column>
        <el-table-column label="日期" width="120" prop="billDate">
          <template #default="{row}">
            <span v-if="!row.isDetail">上期余额</span>
            <span v-if="row.billDate"> {{  formatDate(row.billDate, 'YYYY-MM-DD') }}</span>
          </template>
        </el-table-column>
        <nt-bill-no-column label="单据编码" width="165"  show-bill-type></nt-bill-no-column>
        
        <el-table-column label="摘要" width="180" prop="remark">
          <template #default="{row}">
            <span v-if="row.isDetail">{{row.remark}}</span>
          </template>
        </el-table-column>
        

        <el-table-column label="收入金额" width="90" align="right">
          <template #default="{row}">
            <span v-if="row.isDetail">{{ ((row.inTotal||0)).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支出金额" width="90" align="right">
          <template #default="{row}">
            <span v-if="row.isDetail">{{ ((row.outTotal||0)).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="余额" width="90" prop="total"  align="right">
          <template #default="{row}">
            {{ (row.balance||0).toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column label="对方账户" width="120">
        </el-table-column>
        <el-table-column label="备注" width="120" >
        </el-table-column>
        <el-table-column label="单据来源" width="120">
        </el-table-column>
          
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
  import {getAccountFlowDetailList} from '@/service/report/rpt_account'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import AccountInOutList from './flow'
  import NTDateRange from '@/views/plugin/nt_date_range'
  export default {
    name: "AccountSummary",
    mixins:[DataListMixin],
    components: {
      NtRptLayout,
    NtRptToolbar,
      AccountInOutList,
      NTDateRange
    },
    data () {
      return {
        detailVisible:false,
        toggleSearchStatus:false,
        queryModel: {
          startTime: this.moment().format('YYYY-MM-01'),
          endTime: this.moment().format('YYYY-MM-DD')
        },
        subQuery:{

        }
      }
    },
    
    methods: {
      
      async _loadList(params){
        return await getAccountFlowDetailList(Object.assign(params,{}))
      },
      searchQuery() {
        this.loadList();
      },
      getRowClassName({row,rowIndex}){
        return row.isSubtotal? 'nt-subtotal-row':''
      }
    }
  }
</script>
