<template>
  <div style="width:100%;">
    <el-tooltip ref="popoverRef" effect="light" :id="$attrs.id" :visible="suggestionVisible" :placement="configInfo.placement || ''"
      :virtual-ref="$refs.inputRef" popper-class="no-padding" trigger="click">
      <template #content>
        <el-table :height="`${configInfo.height || 200}px`"
          :style="{ width: configInfo.width + 'px', background: '#f5f5f5' }" v-loading="isLoading" :data="suggestions"
          ref="refTable" :border="[1, true, '1', 'true', null, undefined].includes(configInfo.border)"
          :showHeader="[1, true, '1', 'true', null, undefined].includes(configInfo.showHeader)" highlight-current-row
          @select ="onRowCheckBoxChange"
          @select-all="onCheckAllChange"
          @rowClick="onRowItemSelected">
          <el-table-column v-if="multiple" type="selection"></el-table-column>
          <el-table-column v-for="(column, index) in configInfo.columns" :key="column.prop" :width="column.width"
            :prop="column.prop" :label="column.label" />
        </el-table>
        <el-row type="flex" justify="space-between" style="margin-top:0px;align-items:center;padding:4px;">
          <el-button size="small" type="primary" @click="onAddClick" v-if="showAddAction">新增</el-button>
          <span v-else></span>
          <el-pagination v-if="configInfo.showPager" :current-page="this.pageInfo.pageIndex"
            :page-size="this.pageInfo.pageSize" :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" small
            background layout="total, prev, next" @current-change="onPageIndexChange" @size-change="onPageSizeChange" />
        </el-row>
      </template>
      <!-- 多选模式 -->
      <div v-if="multiple" class=" nt_tag_select el-select__selected-item el-select__input-wrapper"  @click="onMultipleModeFocus">
        <div class="tag_wrapper">
          <div class="el-select__selected-item" v-for="(tag,index) in tags" ref="buttonRef" :key="index">
            <el-tag ref="tagRef" @close="onMultipleModeTagClose(index)" type="info" :size="size" light round :closable="!readonly">
            {{tag}}
            </el-tag>
          </div>
          <!-- 超过特定数量时，即控件不足时，显示+,点击浮窗显示所有数据，考虑显示于左上角 -->
          <i v-if="tags?.length>2" style="margin-left:2px;border:1px solid #ddd;background:#f5f5f5;color:#666;font-size:10px;height: 16px;padding:2px;border-radius: 4px;" size="10">+N</i>
          <el-input ref="txtInput" v-model="input" v-click-outside="onClickOutside" :size="size"
            spellcheck="false" @keydown.enter.stop.prevent="onMultipleModeKeyEnter"
            @focus="onTextFocus" @input="onMultipleModeInputChange" autocomplete="off"
            @keydown.up.prevent="highlight(highlightedIndex - 1)" @keydown.down.prevent="highlight(highlightedIndex + 1)"
            class="el-select__input" :style="{width: `${width}px`}"/>
          <span ref="txtCalc" class="input-editor" v-text="input" ></span>
        </div>
        
        <div>
          <el-icon :class="{ 'el-select__caret': true, 'el-select__icon': true, 'is-reverse': suggestionVisible }"
            v-if="!readonly" @click="suggestionVisible = !suggestionVisible">
            <ArrowDown />
          </el-icon>
          <el-icon @click.stop="onAdvanceSearchClick" style="cursor:pointer;" color="var(--el-color-primary)" v-if="advanceSearch && !readonly">
            <MoreFilled />
          </el-icon>
        </div>
        
    </div>
      <!-- 单选模式 -->
      <el-input v-else ref="inputRef" v-click-outside="onClickOutside" v-model="displayText" :placeholder="placeholder" autocomplete="off"
        :readnly="readonly" @blur="handleBlur" @focus="onTextFocus" @input="onTextChange" :size="size"
        @keydown.up.prevent="highlight(highlightedIndex - 1)" @keydown.down.prevent="highlight(highlightedIndex + 1)"
        @keydown.enter="onKeyEnterHandler">
        <template #suffix>
          <el-icon :class="{ 'el-select__caret': true, 'el-select__icon': true, 'is-reverse': suggestionVisible }"
            v-if="!readonly" @click="suggestionVisible = !suggestionVisible">
            <ArrowDown />
          </el-icon>
          <el-icon @click.stop="onAdvanceSearchClick" style="cursor:pointer;" color="var(--el-color-primary)" v-if="advanceSearch && !readonly">
            <MoreFilled />
          </el-icon>
        </template>
      </el-input>

    </el-tooltip>
    <DynamicResourceEdtor ref="refEditor"></DynamicResourceEdtor>
    <DynamicAdvanceSelector ref="refAdvance"></DynamicAdvanceSelector>
  </div>
</template>
<script>
import { ClickOutside } from 'element-plus'
import { DropdownGridConfig } from './dropdown_config'
import DynamicResourceEdtor from './dynamic_resource_editor' // 新增资料弹窗
import DynamicAdvanceSelector from './dynamic_advance_selector' // 高级弹窗选择窗口

export default {
  data() {
    return {
      detailVisible: false,
      detailPage: null, // 动态添加资料页
      pageInfo: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      configInfo: DropdownGridConfig[this.configKey], // 加载配置文件信息

      suggestionVisible: false,
      lastValidText: this.text || '', //最后选择的有效文本
      displayText: this.text || '',
      highlightedIndex: -1,// 网络中默认聚焦行
      isLoading: false,
      suggestions: [],
      lastSearchText: null,
      // 以下为多选属性配置
      input: '' ,
      width:11,
      editTag:{},// 当前操作的tag
      selectedList:[], // 多选模式下，选择的key-value 列表
      tagValue: this.modelValue || ''
    }
  },
  components: {
    DynamicResourceEdtor,
    DynamicAdvanceSelector
  },
  directives: { ClickOutside },
  emit: ['update:modelValue'],
  props: {
    modelValue: null,// 对象keytext:null,// key -value 用于文本框显示内容
    searchable: {// 是否支持搜索
      type: Boolean,
      default: true,
    },
    clearable: { // 清除操作
      type: Boolean,
      default: false
    },
    triggerOnFocus: { // 组件获焦时，显示下拉框
      type: Boolean,
      default: true
    },
    advanceSearch: {// 显示 更新，高级搜索
      type: Boolean,
      default: false
    },
    configKey: {
      type: String, // 配置项key
      required: true
    },
    multiple:{ // 是否为多选模式
      type: Boolean,
      default: false
    },
    requestParam: { // 组件传入额外查询参数
      type: Object
    },
    text: {
      type: String
    },
    tagSplitter:{// 标签分隔
      type: String,
      default: ','
    },
    size:{
      type:String
    },
    showAddAction:{ // 是否显示新增按钮
      type:Boolean,
      default:false
    }
  },
  computed: {
    placeholder: function () {
      return this.lastValidText || this.$attrs.placeholder
    },
    readonly: {
      get() { return !this.searchable || this.$attrs.readonly }
    },
    tags:function(){// 监控标签的变化
    // 根据绑定的值，进行数据切割，切割内容分隔符，切割色值$,形成标签对象
      if(!this.selectedList || this.selectedList.length == 0){
        return []
      }
      // 根据对象，多选，单选，对象类型，id-key 映射配置显示数据
      return this.selectedList.map(item=>item[this.configInfo.text]) // 返回显示文本
      // return this.tagValue.split(this.tagSplitter).map(item=>item)
    }
  },
  watch: {
    suggestionVisible: function () {
      if (this.suggestionVisible && !this.firstLoadData) { // 首次显示下拉框时，加载数据
        this._timer = setTimeout(_ => this.loadDataList(), 100)
      }
      this.suggestionVisible && this.syncCheckBoxStatus()
    },
    text: function (newVal) {
      console.log('text change',newVal)
      this.lastValidText = this.text
      this.dispalyText = this.text // 会触发获取焦点，然后触发失焦事件??
      this._lock = true
      setTimeout(_ => this._lock = false, 100) // 禁止失焦事件
    },
    requestParam:function(oVal,newVal){
      // 查询条件发生变化，重新初始化
      console.log('fking change',oVal,newVal)
      this.firstLoadData=false
      this.suggestionVisible = false
      this.selectedList=[]
      this.lastValidText = '', //最后选择的有效文本
      this.displayText = '',
      this.highlightedIndex = -1,// 网络中默认聚焦行
      this.suggestions= [],
      this.lastSearchText= null,
      // 以下为多选属性配置
      this.editTag ={},// 当前操作的tag
      this.tagValue = ''
    },
  },
  created() {
  },
  methods: {
    onClickOutside(e) {
      if (this.$refs.popoverRef?.isFocusInsideContent()) { // 下拉框点击点击焦点，属于
        return
      }
      this.suggestionVisible = false
    },
    onRowCheckBoxChange(selection,row){ // 点击网格中的checkbox 时，触发
      this.selectedList = selection
      // 翻页时，合并数据？
      this.tagValueChanged()
    },
    onCheckAllChange(selection){
      this.selectedList = selection
      // 翻页时，合并数据？
    },
    onRowItemSelected(row) { // 触发v-model 回写this. lastSearchText =this.displayText // 选择数据后，以此次作为最后查询
      if(!this.multiple){
        this.displayText = row[this.configInfo.text]
        this.lastValidText = row[this.configInfo.text] // 最后一次选择的有效text
        this.highlightedIndex = -1
        this.suggestionVisible = false
        this.ignoreFocusEvent = true
        this.$emit('update:modelValue', row[this.configInfo.value])
        this.$emit('update:text', row[this.configInfo.text])
        // this.$emit('choose', row) // TODO 返回行对象或对象集合 deprecated
        this.$emit('change', row)

        setTimeout(_ => this.$refs.inputRef?.blur())
      } else { 
        // 根据当前已选数据，进行反选操作
        // this.configInfo.value
        let matchIndex = this.selectedList.findIndex(item=>item[this.configInfo.value]==row[this.configInfo.value])
        // console.log('match index', matchIndex)
        if(matchIndex != -1){
          this.selectedList.splice(matchIndex,1) // 移除
        } else {
          this.selectedList.push(row) // 仅返回key -value ??
        }
        this.$refs.refTable?.toggleRowSelection(row)
        // 触发通知，将完整的选中列表通知绑定对象
        this.$emit('update:modelValue', this.selectedList) // 返回完整对象

      }
      
    },
    onAdvanceSearchClick() { // 高级弹窗搜索
      // 调用高级选择框时，将当前组件已选择的数据传给窗体，用于默认勾选显示 TODO
      this.$refs.refAdvance.showSelector(this.configKey,this.multiple, result=>{
        //根据单复选类型，控制返回结果
        // 根据当前下拉框组件是否是多选，即tag 列表方式，将结果合并的tag数据源  selectedList k-v 格式
        // 针对于内嵌网模式，实际网格仍为单选， 第一个作为当前选择数据，其他数据通过其他事件 @extraSelected 进行数据传递
        // 网格中监听该事件，并将用户的多选结果形成网络行数据
        this.selectedList = result 
      })
    },
    highlight(rowIndex) { // 设置选中行状态
      if (this.suggestionVisible && rowIndex >= 0 && rowIndex < this.suggestions.length) {
        this.highlightedIndex = rowIndex
        this.$refs.refTable?.setCurrentRow(this.suggestions[rowIndex], true)
      }
    },
    onKeyEnterHandler() { // 输入框中，回车时，如下拉框已显示，且网格中列表存在数据，单选情况 直接选择复选则进行多选操作
      if (this.suggestionVisible && this.highlightedIndex >= 0 &&
        this.highlightedIndex < this.suggestions.length) {
        this.onRowItemSelected(this.suggestions[this.highlightedIndex])
      }
      else if (this.selectWhenUnmatched) {//没有数据时，则回传原来传入的数据
        this.$emit('select', { value: this.modelValue })
        suggestions = []
        this.highlightedIndex = -1
      }
    },
    handleBlur(evt) {
      if (this._lock) return // 忽略通过watch text 事件触发的blur 事件

      setTimeout(() => {
        // validate currentfocus event is insideel-tooltip-content
        // if so, ignore the blurevent and the next focusevent
        if (this.$refs.popoverRef?.isFocusInsideContent()) {
          this.ignoreFocusEvent = true
          return false
        }
        this.suggestionVisible = false
        if (this.lastValidText != this.displayText) {// 失焦后，判断输入的文本是否为最后一次选择的值，否则恢复至最后一次有效值
          this.displayText = this.lastValidText || ''
          this._lastSearchText = null

          this.ignoreFocusEvent = false
          this.$emit('blur', evt)
        }
      })
    },
    onTextFocus() {// 根据条件判断是否获取
      // 根据牵体判断是否获取集点时，显示下拉框
      if (['', 'true', 1, true].includes(this.$attrs.readonly)) {
        return
      }
      this.displayText = '' // 清空文本,显示占位符最后的值

      if (this.suggestionVisible || this.ignoreFocusEvent) {
        return
      }
      if (this.triggerOnFocus) {
        this.suggestionVisible = true
      }
    },
    onTextChange(val) {
      // 开始过滤数据，本地 远程loading， 缓存首次查询的结里乃&ev 防拉过程中可不正确，
      if (this.suggestionVisible) {
        this.suggestionVisible = true
        this._timer && clearInterval(this._timer)
        this._timer = setTimeout(_ => this.loadDataList(val), 500)
      }
    },
    async loadDataList(keyword = "") {
      if (keyword == this.lastSearchText) {
        return
      }
      this._lastSearchText = keyword
      this.isLoading = true
      if (this.configInfo.remote) { // 远程搜索
        let arParams = []
        if (this.configInfo.entityType) {
          arParams.push(this.configInfo.entityType)
        }
        
        let requestParam = Object.assign({}, this.configInfo.requestParam, { pageIndex: this.pageInfo.pageIndex, pageSize: this.pageInfo.pageSize }, this.requestParam)
        if (this.configInfo.search) { // 搜索字段
          requestParam[this.configInfo.search] = keyword
        }
        arParams.push(requestParam)
        let res = await this.configInfo.postMethod(...arParams) // 动态构建参数, 支持2个
        this.isLoading = false
        if (res.code == 200) {
          this.suggestions = res.data.list || res.data/*不带分页的配置*/
          this.pageInfo.total = res.data.total
        } else {
          this.$message.error(res.msg || res.data)
        }
      } else { // 本地搜索
        this._timer = setTimeout(_ => {
          this.suggestions = this.configInfo?.localData?.filter(item => item[this.configInfo.text].toLowerCase().indexOf(keyword.toLowerCase()) != -1)
          this.isLoading = false
        }, 200)
      }
      if (!this.firstLoadData) {
        this.firstLoadData = true
      }
      //如果多选模式下，显示数据，默认将已选择的数据勾选
      this.syncCheckBoxStatus()
    },
    syncCheckBoxStatus(){
      if(this.multiple){
        // 切换下拉框显示，节点尚未挂载完成，导致null， 通过setTimeout==> 进入执行队列
        setTimeout(_=>{
          this.selectedList.forEach(item=>{
            let matchRow = this.suggestions.find(el=>el[this.configInfo.value] == item[this.configInfo.value])
            if(matchRow){
              this.$refs.refTable?.toggleRowSelection(matchRow,true)
            }
          })
        },0)
      }
    },
    // 组合式函数可以随时更改其状态。
    onPageSizeChange(current, val) {
      this.pageInfo.pageSize = val
      this.loadDataList()
    },

    onPageIndexChange(val) {
      this.pageInfo.pageIndex = val
      this.loadDataList()
    },
    onAddClick() { // 新增资料
      // 根据configKey 调用工具类，弹窗编辑资料，并接收保存后结果
      // 新增资料后，直接填充组件
      this.suggestionVisible = false
      this.$refs.refEditor.showEditor(this.configKey, this.onRowItemSelected)
    },
    // 以下为多选操作控制事件
    onMultipleModeFocus(){
      if(this.readonly){
        return
      }
      this.$refs.txtInput?.focus()
    },
    onMultipleModeInputChange(val){
      this.input= val //event.target.value
      // 根据已输入tag总宽度组件的总宽度 计算输入框的最大值
      this.width = this.$refs.txtCalc.getBoundingClientRect().width + 8
      this.onTextChange(this.input)
    },
    
    onMultipleModeTagClose(index){
      this.selectedList.splice(index,1)
      this.tagValueChanged()
    },
    tagValueChanged(){
      let resultList = this.selectedList.map(item=>{
        return {
          [this.configInfo.text]: item[this.configInfo.text],
          [this.configInfo.value]: item[this.configInfo.value]
        }
      })
      
      // 根据配置文件信息判断返回的数据格式 数组对象，字符串数组，数组按分隔符转字符串 TODO
      this.$emit('update:modelValue',resultList)
    }
  }
}
</script>
<style lang="less" scoped>
  .nt_tag_select{
    box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
    border-radius:var(--el-input-border-radius,var(--el-border-radius-base));
    padding:4px;
    flex-wrap:wrap;
    row-gap:4px;
    column-gap:4px;
    min-height:22px;
    display: flex;
    justify-content: space-around;
    width:100%;
    align-items: center;
    .tag_wrapper{
      flex:1;
      display: flex;
      column-gap: 2px;
      flex-wrap: wrap;
      align-items: center;
      // height:20px; // 根据 small 调整高度 todo
    }
    :deep(.el-input__wrapper){
      padding:0px;
      border:none;
      box-shadow: none;
    }
    &.readonly{
      cursor: not-allowed;
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #c0c4cc;
    }
    .el-tag{
      border:none;
    }
    :deep(.el-tag__content){
      max-width:80px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    :deep(.el-select__input){
      margin-left:0px!important;
      min-width:11px !important;
      max-width:100%;
      // height: 0px;
    }
    :deep(.el-select__selected-item){
      line-height:18px;
    }
    .input-editor{
      position: absolute;
      left: 0;
      top: 0;
      max-width:100%;
      visibility: hidden;
      overflow: hidden;
      height:100%;
    }
  }
</style>