<template>
  <div class="designer-container unselectable" >
    
    <div style="background:#fff;position:absolute;left:0px;top:0px;width:30px;height:30px;z-index:1000;"></div>
    <div class="ruler-x">
      <div class="ruler-label">
        <span v-for="(item,index) in (new Array(52))">{{index-1}}</span>
      </div>
      <div class="ruler-1"></div>
      <div class="ruler-2"></div>
      <div class="ruler-3"></div>
    </div>
    <div class="ruler-y">
      <div class="ruler-label">
        <div></div>
        <div v-for="(item,index) in (new Array(51))"><span >{{index}}</span></div>
      </div>
      <div class="ruler-1"></div>
      <div class="ruler-2"></div>
      <div class="ruler-3"></div>
    </div>
    <div class="paper" :style="{width:pageWidth+'mm',height:pageHeight + 'mm'}">
      <div class="padding-line vertical" :style="{left:pageSetting.padding.left +'px'}"></div>
      <div class="padding-line vertical" :style="{right:pageSetting.padding.right +'px'}"></div>
      <div class="padding-line horizental" :style="{top:pageSetting.padding.top +'px'}"></div>
      <div class="padding-line horizental" :style="{bottom:pageSetting.padding.bottom +'px'}"></div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageWidth:this.width,
      pageHeight:this.height
    }
  },
  components: {},
  props: {
    width:{
      type:Number,
      default:0
    },
    height:{
      type:Number,
      default:0
    },
    pageSetting:{
      type:Object,
      required:true
    }
  },
  watch:{
    width:function(){
      this.pageWidth = this.width
    },
    height:function(){
      this.pageHeight = this.height
    }
  },
  methods: {

  }
}
</script>