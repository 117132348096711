
<template>
  <el-container class="nt-height-100 nt-box-panel padding">
    <el-header class="no-margin no-padding height-auto margin-bottom">
      <el-row type="flex" justify="space-between">
        <!-- <div style="color:red"> 电商平台按店铺的维度，同步其名下可用的模版（按已开通的快递公司）</div> -->
        <div>
          <span style="font-size:14px;margin-right:4px;">所属平台</span>
          <el-select v-model="selectedPlatform" style="width:240px;" @change="onPlatformChangeHandler">
            <el-option value="WX_VIDEO" label="微信小店"></el-option>
          </el-select>
        </div>
        <div>
          <el-button type="success" @click="onChooseClick">从平台选择模版</el-button>
          <!-- <el-button type="primary" v-if="selectedPlatform" @click="onSyncClick"><el-icon><Refresh /></el-icon>同步面单模版</el-button> -->
          <el-button type="primary" v-if="selectedPlatform" @click="onAddClick"><el-icon><Plus /></el-icon>添加模版</el-button>
          <!-- <el-button type="primary" @click="isWaybillAccountVisible = true"> 面单账号管理--平台接口获取 </el-button> -->
        </div>

      </el-row>

    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border stripe ref="table" class="nt-resource-table" :data="datasource"  v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="快递公司" width="110" prop="deliveryId">
          <template #default="{row}">
            {{ formatDelivery(row.deliveryId) }}-{{ (row.deliveryId) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="物流公司" width="140" prop="deliveryName">
        </el-table-column> -->
        <!-- <el-table-column label="模版类型" width="120" prop="templateType"></el-table-column> -->
        <el-table-column label="模版名称" prop="templateName">
          <template #default="{ row }">
            <div v-if="row.isStandard"><el-tag type="primary">标准模版</el-tag></div>
            <div v-else>{{ row.templateName }}</div>
            <div>{{ row.templateDesc }}</div>
            <el-tag class="right-top" type="primary" v-if="row.isDefault">默认</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺">
          <template #default="{ row }">
            {{formatShopName(row.waybillAccountShopId)}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="模版描述" width="160" prop="templateDesc">
        </el-table-column> -->
        <el-table-column label="纸张大小(mm)" width="120">
          <template #default="{ row }">
            {{ row.height }}*{{ row.width }}
          </template>
        </el-table-column>
        <el-table-column label="默认打印机" width="200" prop="printerName">
          <template #default="{ row }">
            <nt-printer v-model="row.printerName" placeholder="请选择默认打印机"
              @change="val => onPrinterChangeHandler(val, row)">
            </nt-printer>
          </template>
        </el-table-column>
        <el-table-column label="默认发货地址" width="280">
          <template #default="{row}">
            <div v-if="row.senderAddress">
              <div>{{ row.senderAddress.userName }}-{{ row.senderAddress.cellPhone }}</div>
              <div>{{ row.senderAddress.addressDetail }}</div>
              <el-button link type="primary" size="small" @click="onChooseAddressClick(row)">修改</el-button>
              <el-button link type="danger" size="small" @click="onRemoveAddressClick(row)">删除</el-button>
            </div>
            <el-button v-else link type="primary" size="small" @click="onChooseAddressClick(row)">绑定</el-button>
          </template>
          <!-- <template #default="{row}">
            <el-button link type="primary" size="small" @click="onChooseAddressClick(row)">绑定</el-button>
          </template> -->
        </el-table-column>
        <!-- <el-table-column label="是否默认" width="90" prop="isDefault"></el-table-column> -->
        <el-table-column label="状态" width="170">
          <template #default="{ row }">
            <el-button size="small" link @click="onRowEditClick(row)" >编辑</el-button>
            <el-button link  type="primary"  size="small" v-if="!row.isDefault" @click="onRowSetDefaultClick(row)" >设为默认</el-button>
            <!-- <el-button size="small" @click="onRowAddClick(row)" v-if="row.isStandard">创建自定义模版</el-button> -->
            <!-- <el-button size="small">设计</el-button> -->
            <el-popconfirm title="确定当前选中模版?" width="220" @confirm="onRowDeleteClick(row)" v-if="!row.isStandard">
              <template #reference>
                <el-button size="small" link type="danger">删除</el-button>
              </template>
              <template #actions="{ confirm, cancel }">
                <div class="margin-bottom" style="font-size:12px;color:var(--el-color-danger);">微信小店平台将同步删除该模版</div>
                <el-button size="small" @click="cancel">取消</el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click="confirm"
                >
                  删除
                </el-button>
              </template>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="margin-top-8 height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="1050" title="详情">
    <detail-page @success="onDetailSaveSuccessHandler" :standardTemplate="standardTpl" @cancel="isDetailVisible = false"
      :entity="currentRecord" v-if="isDetailVisible"></detail-page>
  </el-dialog>
  <!-- <el-dialog v-model="isWaybillAccountVisible" :close-on-click-modal="false" destroyOnClose width="1050px" title="面单账号列表">
    <waybill-account-selector @cancel="isWaybillAccountVisible = false" @choose="onWaybillAccountChange"
      v-if="isWaybillAccountVisible"></waybill-account-selector>
  </el-dialog> -->
  <el-dialog v-model="isAddressVisible" :close-on-click-modal="false" destroyOnClose width="800px" class="nt-resource-dialog" title="地址选择"
    @cancel="isAddressVisible = false">
    <AddressSelector @choose="onAddressChooseHandler" v-if="isAddressVisible" @cancel="isAddressVisible = false"></AddressSelector>
  </el-dialog>
  <el-dialog v-model="isChooseVisible" :close-on-click-modal="false" destroyOnClose width="800px" class="nt-resource-dialog" title="模版选择"
    @cancel="isAddressVisible = false">
    <PlatformTemplateSelector @choose="onTemplateChooseHandler" v-if="isChooseVisible" @cancel="isChooseVisible = false"></PlatformTemplateSelector>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
// import DeliveryList from './delivery'
import detailPage from './detail'
import { updateBindingAddress, removeBindingAddress,bindPrinter, deletePlatformWaybillTemplate,syncTemplate,setDefaultTemplate } from '@/service/print_order/platform_waybill_template'
import waybillAccountSelector from '../waybill_account'
import AddressSelector from '@/views/selector/address'
import PlatformTemplateSelector from '@/views/selector/platform_waybill_template'
// import addTemplatePage from './detail/add_template'
// import ntPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
import NtDictTree from '@/views/plugin/nt_dict_tree'
import NtPrinter from '@/views/plugin/nt_printer'
import {EnumDelivery} from '@/utils/enum_utils'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  name: "WaybillTemplateList",
  mixins: [DataListMixin],
  components: {
    NtPrinter,
    NtDictTree,
    waybillAccountSelector,
    PlatformTemplateSelector,
    detailPage,
    AddressSelector,
    NtFormItem
  },
  data() {
    return {
      isAddressVisible:false,
      isChooseVisible:false,// 选择平台模版弹窗
      standardTpl: null, // 所属标准模版
      isWaybillAccountVisible: false,
      isAddTemplateVisible: false,
      selectedTemplate: null, // 当前操作的模版行
      selectedPlatform:'WX_VIDEO'
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedPlatform) return
      return await this.getPageEntityList(this.EnumEntityType.FLATFORM_WAYBILL_TEMPLATE,
        Object.assign(params,{platform:this.selectedPlatform}, this.queryModel)
       )
    },
    async onPlatformChangeHandler(val){
      this.selectedPlatform = val
      this.loadList()
    },
    formatShopName(shopId) {
      let matchItem = LoginUserStore().shopList.find(item => item.shopId == shopId)
      return matchItem?.shopName || shopId
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    formatDelivery(deliveryId){
      return EnumDelivery[deliveryId] || deliveryId
    },

    async onRowDeleteClick(record) {
      let res = await deletePlatformWaybillTemplate({ id: record.id })

      if (res.code === 200) {
        this.$message.success("删除成功")
        this.loadList()
      } else {
        this.$message.error(res.msg || res.message || res.data)
      }
    },
    async onAddClick() {
      this.currentRecord = null
      this.isDetailVisible = true
    },
    onChooseClick(){
      this.isChooseVisible = true
    },
    onTemplateChooseHandler(tpl){
      console.log('onTemplateChooseHandler',tpl)
      this.currentRecord = tpl
      // 将选择的模版信息传给详情页=>> 进入编辑状态  TODO
      this.isChooseVisible =false
      this.isDetailVisible = true
    },
    
    onRowEditClick(record) {
      this.standardTpl = null
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },

    async onSyncClick(){
      let res = await syncTemplate({shopId:this.selectedShopId})
      if(res.code ==200){
        this.$message.success('同步成功')
        this.loadList()
      }  else {
        this.$message.error(res.msg || res.message || res.data)
      }
    },
    
    async onPrinterChangeHandler(val, row) {
      row.printerName = val
      let res = await bindPrinter({
        templateId: row.id,
        printerName: val
      })
      if (res.code === 200) {
        this.$message.success("更新成功")
      } else {
        this.$message.error(res.msg || res.message || res.data)
      }
    },
    async onAddressChooseHandler(address){
      let res = await updateBindingAddress({id:this.selectedTemplate.id,addressId:address.id})
      if (res.code == 200) {
        this.isAddressVisible =false
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
      
    },
    onChooseAddressClick(row){
      this.selectedTemplate = row
      this.isAddressVisible = true
    },
    async  onRemoveAddressClick(row){
      let res = await removeBindingAddress({id:row.id})
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    async onRowSetDefaultClick(record) {
      let res = await setDefaultTemplate( { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
  }
}
</script>