
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto display-flex space-between">
      <el-form :inline="true" @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <nt-form-item label="名称">
          <el-input placeholder="请输入名称查询" v-model:value="queryModel.accountName"></el-input>
        </nt-form-item>
        <nt-form-item label="编号">
          <el-input placeholder="请输入编号查询" v-model:value="queryModel.accountNo"></el-input>
        </nt-form-item>
        <nt-form-item label="备注">
          <el-input placeholder="请输入备注查询" v-model:value="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </nt-form-item>
      </el-form>
      <div>
        <el-button @click="onAddClick">新增</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="60" type="index">
        </el-table-column>
        
        <el-table-column label="编号" width="120" prop="accountNo">
        </el-table-column>
        <el-table-column label="名称" width="180" prop="accountName">
        </el-table-column>
        <el-table-column label="开户行" width="120" prop="bankName">
        </el-table-column>
        <el-table-column label="账号" width="120" prop="bankNo">
        </el-table-column>
        <el-table-column label="账号类型" width="120" prop="accountTypeName">
        </el-table-column>
        <el-table-column label="账户余额" width="120" prop="balance">
        </el-table-column>
        <el-table-column label="默认结算方式" width="120" prop="paymentMethodName">
        </el-table-column>
        <el-table-column label="备注" prop="remark">
        </el-table-column>
        <el-table-column label="状态" width="120" prop="enabled" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.isEnabled" type="success">启用</el-tag>
            <el-tag v-if="!scope.row.isEnabled" type="danger">禁用</el-tag>
          </template>

        </el-table-column>
        <el-table-column label="是否默认" width="120" prop="isDefault" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.isDefault">是</el-tag>
            <el-tag v-if="!scope.row.isDefault">否</el-tag>
          </template>
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?'}"
          :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <el-dialog v-model="isDetailVisible" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    :close-on-click-modal="false" @close="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "AccountList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.ACCOUNT, params);
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.ACCOUNT, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onRowSetDefaultClick(record) {
      let res = await this.setEntityDefault(this.EnumEntityType.ACCOUNT, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },

    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.ACCOUNT, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>
