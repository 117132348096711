
<template>
  <div class="nt-custom nt-width-100 border nt-bg-white">
    <div class="title display-flex nt-width-100"
      style="column-gap: 0px;background-color: var(--el-color-primary-light-5);">
      <div :style="{ flex: `0 0 ${widthInfo.checkbox}` }" class="border align-center">
        <el-checkbox v-model="checkAll" @change="onCheckAllChange"></el-checkbox>
      </div>
      <div :style="{ flex: `0 0 ${widthInfo.packageNum}` }" class="align-center">包裹#</div>
      <div :style="{ flex: `1 1 ${widthInfo.goodsName}` }" class="align-center">商品</div>
      <div :style="{ flex: `0 0 ${widthInfo.pickingCode}` }" class="align-center">拣货码</div>
      <div :style="{ flex: `0 0 ${widthInfo.pickingQuantity}` }" class="align-center">拣货数量</div>

      <div :style="{ flex: `0 0 ${widthInfo.status}` }" class="align-center">备货状态</div>
      <div :style="{ flex: `0 0 ${widthInfo.waybillNo}` }" class="align-center">运单号</div>
      <div :style="{ flex: `0 0 ${widthInfo.waybillStatus}` }" class="align-center">运单状态</div>
    </div>
    <el-checkbox-group v-model="checkList" style="font-size: 14px;line-height: initial;" @change="onPackageCheckChange">
      <div class="order-item" v-for="order in datasource" :key="order.id">
        <div class="padding-8">订单号:{{ order.orderNo }} {{ order.buyerName }} {{ order.buyerTel }} {{ order.buyerAddress }}</div>
        <!-- 根据包裹数进行循环 -->
        <div class="package display-flex border-top padding-top-4 padding-bottom-4 " style="column-gap: 0px;"
          v-for="pack in order.packageList" :key="pack">
          <div :style="{ flexBasis: widthInfo.checkbox }" class="align-center">
            <el-checkbox :value="pack" class="no-padding"></el-checkbox>
          </div>
          <div class="package-goods display-flex flex-auto" style="column-gap: 0px;">
            <div :style="{ flexBasis: widthInfo.packageNum }" class="align-center">#{{ pack.packageNum }}</div>
            <div class="flex-auto">
              <!-- 根据包裹商品进行循环 -->
              <div :class="`display-flex column-gap-0 goods-info ${child.id==lastOrderPackageId?'selected':''}`" v-for="child in pack.orderPackageList" :key="child.id">
                <div :style="{ flex: `1 1 ${widthInfo.goodsName}` }" class="display-flex">
                  <el-image style="width: 30px; height: 30px" :src="child.goodsThumb"></el-image>
                  <div class="flex-auto" style="font-size:12px;">
                    <div>{{ child.goodsName }}</div>
                    <div>{{ child.skuAttrs }}</div>
                  </div>
                </div>
                <div :style="{ flex: `0 0 ${widthInfo.pickingCode}` }" class="align-center relative">
                  <span>{{ child.pickingCode }}</span>
                </div>
                <div :style="{ flex: `0 0 ${widthInfo.pickingQuantity}`, justifyContent: 'end' }"
                  class="align-center display-flex">
                  <span>{{ child.isScan ? child.quantity : 0 }}/{{ child.quantity }}</span>
                  <div v-if="!pack.isDispatch">
                    <el-button link type="primary" v-if="child.isScan" size="small"
                      @click="onCancelScanClick(pack, child)"> 取消备货</el-button>
                    <el-button link type="primary" v-else="child.isScan" size="small"
                      @click="onManualScanClick(pack, child)"> 手动备货</el-button>
                  </div>

                </div>
              </div>
            </div>
            <div :style="{ flex: `0 0 ${widthInfo.status}` }" class="align-center">
              {{ pack.pickQuantity }}/{{ pack.quantity }}
            </div>
            <div :style="{ flex: `0 0 ${widthInfo.waybillNo}` }" class="align-center">
              <div v-if="pack.waybillNo">{{ pack.waybillNo }}</div>
              <div v-else>待获取运单</div>
            </div>
            <div :style="{ flex: `0 0 ${widthInfo.waybillStatus}`, fontSize: '12px' }" class="align-center">
              <div v-if="pack.processStatus && pack.processStatus!=-1 ">
                <el-icon class="is-loading">
                  <Loading />
                </el-icon>
                <span>{{ formatProcessStatus(pack.processStatus) }}</span>
              </div>
              <div v-else-if="pack.processStatus==-1 ">
                <el-tooltip effect="light"  placement="top-start" >
                  <template #content>
                    <div style="width:200px;">
                      {{pack.errorMsg}}
                    </div>
                  </template>
                  <el-icon color="var(--el-color-danger)"><CircleClose /></el-icon>
                </el-tooltip>
                失败
              </div>
              <div v-else> {{ formatPickingStatus(pack) }}</div>

            </div>
          </div>

        </div>
      </div>
    </el-checkbox-group>
  </div>
</template>
<script>

import { updateScanStatus } from '@/service/print_order/picking'
export default {
  name: "PickingOrderItem",
  mixins: [],
  components: {
  },
  data() {
    return {
      checkAll: false,
      datasource: this.modelValue,
      checkList: [],
      widthInfo: {
        checkbox: '30px',
        packageNum: '65px',
        pickingCode: '100px',
        goodsName: 'auto',
        waybillNo: '180px',
        waybillStatus: '120px',
        pickingQuantity: '100px',
        status: '100px'
      },
      lastOrderPackageId: this.orderPackageId
    }
  },
  props: {
    modelValue: {},
    orderPackageId:{}
  },
  watch: {
    modelValue: function () {
      this.datasource = this.modelValue
      if (this.datasource.length == 0) {
        this.checkAll = false
        this.checkList = []
      }
    },
    orderPackageId:function(){
      this.lastOrderPackageId = this.orderPackageId
    }
  },
  computed: {
    packageCount: function () {
      return this.datasource.reduce((acc, curr) => acc + curr.packageList.length, 0)
    }
  },
  methods: {
    getWaybillStatus() {
      // 获取面单状态：waybillNo=null 待生成 waybyNo!=null 面单已生成 printCount>0 面单已打印  isDispatch=1 已发货

    },
    getPickingStatus(pack) {
      if (pack.isDispatch) {
        return 0 // 已发货
      } else if(pack.waybillNo){
        return 1
      } else {
        if ((pack.pickQuantity || 0) == 0) {
          return 1; // 待备货
        }
        if (pack.quantity > pack.pickQuantity) {
          return 3;// 备货中,部分备货
        }
        return 4;// 已完成备货
      }
    },
    formatPickingStatus(pack) {
      let status = this.getPickingStatus(pack)
      let info = {
        '0': '已发货',
        '1': '待发货',
        '2': '待备货',
        '2': '备货中',
        '4': '已完成备货'
      }
      return info[status] || status
    },
    formatProcessStatus(status) {
      let info = {
        '1': '正在生成面单..',
        '2': '正在打印...',
        '3': '正在发货...'
      }
      return info[status] || status
    },
    needChecked(pack) {
      return this.checkList.includes(pack)
    },
    onCheckAllChange() {
      if (this.checkAll) {
        this.datasource.forEach(order => {
          this.checkList.push(...order.packageList)
        })
      } else {
        this.checkList = []
      }
      this.$emit('selectChange', this.checkList)
    },
    onPackageCheckChange() {
      this.checkAll = this.packageCount == this.checkList.length
      this.$emit('selectChange', this.checkList)
    },
    updatePackageSummaryInfo(packageInfo) {
      let quantity = 0 // 当前包裹总商品数量
      let pickQuantity = 0 // 当前包裹累计备货数量
      // 汇总包裹中已拣货的汇总信息  包裹商品总数量  ， 已备货数量
      packageInfo.orderPackageList.forEach(item => {
        quantity += item.quantity
        pickQuantity += (item.isScan ? item.quantity : 0)
      })
      packageInfo.quantity = quantity
      packageInfo.pickQuantity = pickQuantity
    },
    async onCancelScanClick(packageInfo, orderPackage) { // 取消备货
      let res = await updateScanStatus({ orderPackageId: orderPackage.id, status: false })
      if (res.code === 200) {
        // 弹窗显示批量操作结果
        orderPackage.isScan = 0
        // 重新计算包裹状态
        this.updatePackageSummaryInfo(packageInfo)
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '更新失败')
      }
    },
    async onManualScanClick(packageInfo, orderPackage) { // 手动备货
      console.log('xxxx', orderPackage)
      let res = await updateScanStatus({ orderPackageId: orderPackage.id, status: true })
      if (res.code === 200) {
        // 弹窗显示批量操作结果
        orderPackage.isScan = 1
        // 重新计算包裹状态
        this.updatePackageSummaryInfo(packageInfo)
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '更新失败')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.nt-custom {
  box-sizing: border-box;
}
.goods-info{
  color:var(--el-text-color-primary);
}
.goods-info:not(:last-of-type) {
  margin-bottom: 4px;
}
.goods-info.selected{
  background-color:var(--el-color-primary-light-7);
}
</style>