<template>
  <el-container class="padding" style="height:550px;" v-loading="isLoading">
    <el-main class="no-padding nt-box-panel">
      <el-tree ref="refTree" :props="{ label: 'menuName' }" :data="treeData" node-key="id" class="padding-8 nt-height-100 overflow-y-auto"
      @check-change="onTreeNodeCheckHandlder"
      default-expand-all show-checkbox>
        <template #default="{ node, data }">
          <el-row type="flex" justify="space-between" class="padding-top padding-bottom"
          :class="{'nt-node-operation':true,'selected':selectedCategoryId==data.id}" highlight-current
          style="align-items:center;width:100%;">
            <span>{{ node.label }}</span>
            <OperationFlag :ref="`ref_flag_${data.id}`" :operationFlag="data.operationFlag" v-model="data.rightFlag" @update:modelValue="val=>onFlagChangeHandler(val,data)" ></OperationFlag>
          </el-row>
        </template>
      </el-tree>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
  </el-container>
  
</template>
<script >

import { getEntityList, getEntity,saveEntity } from '@/service/resource/general'
import { EnumEntityType } from '@/utils/enum_utils'
import OperationFlag from './operation_flag'
export default {
  mixins: [],
  components: {  OperationFlag},
  data() {
    return {
      isLoading:true,
      isSubmitting:false,
      treeData:[],
    }
  },
  props:{
    roleId:{
      type:String,
      required:true
    }
  },
  async created(){
    // 读取当前role 权限配置，以key-value 形式存储
    let res = await getEntity(EnumEntityType.ROLE,{id:this.roleId});
    if(res.code !==200){
      this.$message.error("加载角色信息失败:" + res.message)
      this.isLoading =false
      return
    }

    // let accessRight = res '102|2#104|62#106|8' // 角色权限， #分隔功能 | 分隔 操作按钮
    let arFunction = res.data?.accessRight?.split('#') || []
    let funcInfo = {}
    arFunction.forEach(item=>{
      let ar = item.split('|')
      funcInfo[ar[0]] = ar[1] // {102:62}  // 102key 为功能id， 62值为操作按钮权限位集合
    })
    let getChildren = function(parentId,data){
      let children = data.filter(item=> (item.parentId || null) == parentId && !item.isList)
      children.forEach(item=>{
        item.rightFlag = funcInfo[item.id] || 0
        item.children = getChildren(item.id,data)
      })
      return children
    }
    res = await getEntityList(EnumEntityType.FUNCTION_MENU,{});
    if(res.code == 200){
      // 根据返回结果，构建前端树， 最多三级
      this.treeData = getChildren(null, res.data)
      // 根据funcInfo 的key 集合，设置树勾选
      this.$refs.refTree?.setCheckedKeys(Object.keys(funcInfo))
    }
    this.isLoading =false
    
  },
  methods: {
    onFlagChangeHandler(val,data){
      data.rightFlag = val
      this._lock_node_check_evt = true
      this.$refs.refTree?.setChecked(data.id, !!data.rightFlag,false) // 会触发事件 onTreeNodeCheckHandlder
      setTimeout(_=>{this._lock_node_check_evt = false},0)
      
    },
    onTreeNodeCheckHandlder(data,isChecked,childCheck){
      if(this._lock_node_check_evt){
        return
      }
      data.rightFlag = isChecked? data.operationFlag: 0 // 全选/全不选操作按钮
      let node = this.$refs.refTree?.getNode(data.id)
      this.$refs[`ref_flag_${data.id}`].rightFlag = data.rightFlag // 强制刷新 权限checkbox
    },
    async onSubmitClick(){
      // 提取所有node节点中checked = true 状态 且 rightFlag>0 的权限配置数据
      let allCheckedNodes =  this.$refs.refTree?.getCheckedNodes(true,false) // (leafOnly, includeHalfChecked) 
      let arRight = []
      allCheckedNodes.forEach(item=>{
        if(item.rightFlag>0){
          arRight.push(`${item.id}|${item.rightFlag}`)
        }
      })
      // 更新角色权限值  accessRight
      let res  = await saveEntity(EnumEntityType.ROLE,{id:this.roleId,accessRight:arRight.join('#')})
      if(res.code == 200){
        this.$message.success('保存成功')
      } else{
        this.$message.error('保存失败:' + (res.message || res.msg))
      }
      // console.log('allCheckedNodes',arRight.join('#'))
    },
    onCancelClick(){
      this.$emit('cancel')
    }
  }
}
</script>