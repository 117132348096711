import { axios as service } from '@/utils/request'



/**
 * 根据门店查找三方平台的支付方式默认与epr系统的结算方式的关联关系
 * @param {*} data 
 * @returns 
 */
export const getListWithPayment = (data) => {
  return service({
    url: '/platformShop/listWithPayment',
    method: 'post',
    data
  })
}

/**
 * 根据门店查找三方平台的支付方式默认与epr系统的资金结算账户的关联关系
 * @param {*} data 
 * @returns 
 */
export const getListWithAccount = (data) => {
  return service({
    url: '/platformShop/listWithAccount',
    method: 'post',
    data
  })
}

export const saveShopKv = (data) => {
  return service({
    url: '/platformShop/saveKvConfig',
    method: 'post',
    data
  })
}

export const getOneByPlatform = (data) => {
  return service({
    url: '/platformShop/getByPlatform',
    method: 'post',
    data
  })
}


/**
 * 所有绑定的门店含租户自己的主店
 * @param {*} data 
 * @returns 
 */
export const allBindingShop = (data) => {
  return service({
    url: '/platformShop/allBindingShop',
    method: 'post',
    data
  })
}


/**
 * 从微信平台获取地址
 * @param {*} data 
 * @returns 
 */
export const getAddressFromWxPlatform = (data) => {
  return service({
    url: '/platformShop/getAddressFromWxPlatform',
    method: 'post',
    data
  })
}


