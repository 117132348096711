<template>
  <el-popover
    v-bind="$attrs"
    :virtual-ref="targetElement"
    virtual-triggering
    :visible ="isVisible"
    trigger="click"
    placement="left"
    :width="200"
  >
  <el-menu class="nt-custom no-border"  style="--el-menu-item-height:36px;">
    <el-menu-item index="1" @click="onDeletePackageTemplateClick" class="no-padding" :disabled="this.pack.waybillId">
      <span class="padding-8">删除模版</span>
    </el-menu-item>
    <el-menu-item index="2" class="no-padding" :disabled="!this.pack.waybillId">
      <span class="padding-8" @click="onRecreateWaybillClick" >重新生成单号</span>
    </el-menu-item>
    <el-menu-item index="3" @click="onCancelWaybillClick" class="no-padding" :disabled="!this.pack.waybillId">
      <span class="padding-8">回收面单</span>
    </el-menu-item>
    <!-- <el-menu-item index="4">
      <el-icon><setting /></el-icon>
      <span>发货</span>
    </el-menu-item> -->
  </el-menu>
  </el-popover>
</template>
<script >
import {updateWaybillTemplate} from '@/service/print_order/order'
import { cancelWaybill,recreateWaybill} from '@/service/print_order/platform_waybill'
export default {
  data() {
    return {
      isVisible:false,
      targetElement:null,
      pack:{}
    }
  },
  emits:['templateRemove','cancelWaybill','refresh'],
  methods: {
    show(refElement,pack){
      this.targetElement = refElement
      this.pack = pack
      this.isVisible = true
    },
    hide(){
      this.isVisible =false
    },
    async onDeletePackageTemplateClick(){
      let res = await updateWaybillTemplate({list:[{orderId: this.pack.orderId,packageNum:this.pack.packageNum}]}) //批量更新接口
      if(res.code == 200){
        let resultItem = res.data.resultList[0]
        if(resultItem.success){
          this.pack.templateName = null
          this.$message.success('更新成功')
        } else {
          this.$message.error(resultItem.message)
        }
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
      
    },
    async onCancelWaybillClick(){
      let res = await cancelWaybill({id:this.pack.waybillId}) //批量更新接口
      if(res.code == 200){
        let resultItem = res.data.resultList[0]
        if(resultItem.success){
          this.pack.waybillId = null
          this.pack.waybillNo = null
          this.pack.printCnt=0
          this.pack.isDispatch=false
          this.pack.isPreDispatch=false
          this.$message.success('更新成功')
        } else {
          this.$message.error(resultItem.message)
        }
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    async onRecreateWaybillClick(){
      // console.log('xxxxx',this.$showLoading)
      this.$showLoading("正在重新生成单号");
      let res = await recreateWaybill({id:this.pack.waybillId}) // 重新取号
      this.$hideLoading();
      if(res.code == 200){
        let resultItem = res.data
        if(resultItem.success){
          // 除非列表刷新
          // this.pack.waybillId = null
          // this.pack.waybillNo = null
          // this.pack.printCnt=0
          // this.pack.isDispatch=false
          // this.pack.isPreDispatch=false
          this.$emit('refresh')
          this.$message.success('取号成功')
        } else {
          // 弹对话框
          this.$alert(resultItem.message)
        }
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
      
    }
  }
}
</script>
