import { axios as service } from '@/utils/request'

export const getWaybillTemplateList = (data) => {
  return service({
    url: '/platformWaybillTemplate/all',
    method: 'post',
    data
  })
}

export const getWaybillTemplateListFromApi = (data) => {
  return service({
    url: '/platformWaybillTemplate/templateListFromApi',
    method: 'post',
    data
  })
}

export const saveWaybillTemplate = (data) => {
  return service({
    url: '/platformWaybillTemplate/save',
    method: 'post',
    data
  })
}

export const getWaybillTemplate = (data) => {
  return service({
    url: '/platformWaybillTemplate/get',
    method: 'post',
    data
  })
}

export const getWaybillAccountList = (data) => {
  return service({
    url: '/platformWaybillTemplate/getWaybillAccountList',
    method: 'post',
    data
  })
}

export const deletePlatformWaybillTemplate = (data) => {
  return service({
    url: '/platformWaybillTemplate/delete',
    method: 'post',
    data
  })
}



export const bindWaybillAccount = (data) => {
  return service({
    url: '/platformWaybillTemplate/bandWaybillAccount',
    method: 'post',
    data
  })
}

export const bindPrinter = (data) => {
  return service({
    url: '/platformWaybillTemplate/bindPrinter',
    method: 'post',
    data
  })
}

export const syncTemplate = (data) => {
  return service({
    url: '/platformWaybillTemplate/syncWaybillTemplate',
    method: 'post',
    data
  })
}

export const deliveryList = (data) => {
  return service({
    url: '/platformWaybillTemplate/deliveryList',
    method: 'post',
    data
  })
}


/**
 * 查询特定物流公司下的标准模版列表
 * @param {*} data 
 * @returns 
 */
// export const standardTemplateList = (data) => {
//   return service({
//     url: '/platformWaybillTemplate/standardTemplateList',
//     method: 'post',
//     data
//   })
// }

/**
 * 查询特定物流公司下的指定标准模版详情
 * @param {*} data 
 * @returns 
 */
// export const templateDetail = (data) => {
//   return service({
//     url: '/platformWaybillTemplate/templateDetail',
//     method: 'post',
//     data
//   })
// }



/**
 * 获取价格区间与面单模版的映射关系
 * @param {*} data 
 * @returns 
 */
export const getDeliveryPrice = (data) => {
  return service({
    url: '/platformWaybillTemplate/getDeliveryPrice',
    method: 'post',
    data
  })
}

/**
 * 获取区域与面单模版的映射关系
 * @param {*} data 
 * @returns 
 */
export const getDeliveryArea = (data) => {
  return service({
    url: '/platformWaybillTemplate/getDeliveryArea',
    method: 'post',
    data
  })
}



/**
 * 保存价格区间与面单模版的映射关系
 * @param {*} data 
 * @returns 
 */
export const saveDeliveryPrice = (data) => {
  return service({
    url: '/platformWaybillTemplate/saveDeliveryPrice',
    method: 'post',
    data
  })
}

/**
 * 保存区域与面单模版的映射关系
 * @param {*} data 
 * @returns 
 */
export const saveDeliveryArea = (data) => {
  return service({
    url: '/platformWaybillTemplate/saveDeliveryArea',
    method: 'post',
    data
  })
}

/**
 * 保存价格区间与面单模版列表
 * @param {*} data 
 * @returns 
 */
export const getDeliveryPriceList = (data) => {
  return service({
    url: '/platformWaybillTemplate/deliveryPriceList',
    method: 'post',
    data
  })
}

/**
 * 保存区域与面单模版列表
 * @param {*} data 
 * @returns 
 */
export const getDeliveryAreaList = (data) => {
  return service({
    url: '/platformWaybillTemplate/deliveryAreaList',
    method: 'post',
    data
  })
}


/**
 *删除区域与面单模版列表
 * @param {*} data 
 * @returns 
 */
export const deleteDeliveryArea = (data) => {
  return service({
    url: '/platformWaybillTemplate/deleteDeliveryArea',
    method: 'post',
    data
  })
}

/**
 *删除价格区间与面单模版列表
 * @param {*} data 
 * @returns 
 */
 export const deleteDeliveryPrice = (data) => {
  return service({
    url: '/platformWaybillTemplate/deleteDeliveryPrice',
    method: 'post',
    data
  })
}



// 绑定模版默认发货地址
export const updateBindingAddress = (data) => {
  return service({
    url: '/platformWaybillTemplate/updateBindingAddress',
    method: 'post',
    data
  })
}


// 移除模版默认发货地址
export const removeBindingAddress = (data) => {
  return service({
    url: '/platformWaybillTemplate/removeBindingAddress',
    method: 'post',
    data
  })
}


/**
 * 设置默认模版
 * @param {} data 
 * @returns 
 */
export const setDefaultTemplate = (data) => {
  return service({
    url: '/platformWaybillTemplate/setDefault',
    method: 'post',
    data
  })
}