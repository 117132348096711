<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title>{{billType =='SHARE_PURCHASE'? '采购费用分摊':'销售费用分摊'}}</template>
          <template #extra>
            <el-button  class="el-menu-item" @click="jumpToPage('/financial/share_sale_list', '销售费用分摊列表')">单据列表</el-button>
            <el-button  class="el-menu-item" v-if="formModel.settleTotal > 0">付款历史</el-button>
            <!-- 直接生成付款单-预付款 -->
            <el-button  class="el-menu-item" v-if="formModel.status == 1 && formModel.billTotal > (formModel.depositTotal || 0)"
              @click="jumpToPage('/financial/payment_out', '付款单', { id: this.formModel.id, billType: this.billType, bizType: 'SHARE_SALE_FEE' })">付款</el-button>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row class="form-row">
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="分摊方式" :rules="formValidator('required', '分摊方式不能为空')">
              <el-select v-model="formModel.shareType" :disabled="viewOnly">
                <el-option value="TOTAL" label="按金额" />
                <el-option value="QUANTITY " label="按数量" />
              </el-select>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <nt-form-item prop="deptId" label="部门">
              <nt-dropdown-grid configKey="dept" v-model="formModel.deptId" style="width:100%;"
                v-model:text="formModel.deptName" :readonly="viewOnly" placeholder="请选择部门" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="handsPersonId" label="财务人员">
              <nt-dropdown-grid configKey="staff" v-model="formModel.handsPersonId"
                v-model:text="formModel.handsPersonName" :disabled="viewOnly" placeholder="请选择财务人员" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="remark" label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.remark" :readonly="viewOnly"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button @click="onShareClick" type="primary">分摊</el-button>
          </el-col>
        </el-row>
      </template>
      <template #body>
        <div style="height:calc(50% - 6px)" class="margin-bottom">
          <el-table class="nt-editable-table" :data="formModel.feeItemList" border height="100%" show-summary
            :summary-method="getSummaries" size="small">
            <el-table-column label="#" width="35" type="index">
            </el-table-column>
            <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
              <template #header v-if="!viewOnly">
                <el-icon @click="onAddFeeItemClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                  <Plus />
                </el-icon>
              </template>
              <template #default="scope">
                <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                  <template #reference>
                    <el-icon>
                      <Delete />
                    </el-icon>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
            <el-table-column label="单据号" prop="incomeHead.billNo" width="180">
            </el-table-column>
            <el-table-column label="单据日期" prop="incomeHead.billTime" width="120">
              <template #default="{row}">
                {{ formatShortDate(row.incomeHead.billTime) }}
              </template>
            </el-table-column>
            <el-table-column label="供应商" prop="incomeHead.companyName" width="80">
            </el-table-column>
            <el-table-column label="部门" prop="incomeHead.deptName" width="120">
            </el-table-column>
            <el-table-column label="经手人" prop="incomeHead.manName" width="120">
            </el-table-column>
            <el-table-column label="费用名称" prop="financialItemName" width="120">
            </el-table-column>
            <el-table-column label="可分摊金额"  width="120">
              <template #default="{row}">
                {{ row.total - (row.settleTotal||0) }}
              </template>
            </el-table-column>
            
            <el-table-column label="分摊金额" width="110" prop="total" :class-name="!viewOnly ? 'editor' : ''">
              <template #default="scope">
                <nt-form-item label-width="0" :prop="`feeItemList[${scope.$index}].total`" v-if="!viewOnly"
                  :rules="formValidator('required', '请输入分摊金额')">
                  <el-input v-model="scope.row.total" />
                </nt-form-item>
                <span v-else>{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column >
            </el-table-column>
          </el-table>
        </div>
        <div style="height:calc(50% - 6px)">
          <el-table class="nt-editable-table" :data="formModel.tradeItemList" border height="100%" show-summary
          :summary-method="getSummaries" size="small">
            <el-table-column label="#" width="35" type="index">
            </el-table-column>
            <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
              <template #header v-if="!viewOnly">
                <el-icon @click="onAddTradeItemClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                  <Plus />
                </el-icon>
              </template>
              <template #default="scope">
                <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                  <template #reference>
                    <el-icon>
                      <Delete />
                    </el-icon>
                    <!-- <el-button link type="danger" size="small">删除</el-button> -->
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
            
            
            <el-table-column label="单据号" prop="tradeHead.billNo" width="180">
            </el-table-column>
            <el-table-column label="单据日期" prop="tradeHead.billTime" width="120">
              <template #default="{row}">
                {{ formatShortDate(row.tradeHead.billTime) }}
              </template>
            </el-table-column>
            <el-table-column label="商品" prop="goods.goodsName" width="80">
            </el-table-column>
            <el-table-column label="规格" prop="goods.standard" width="120">
            </el-table-column>
            <el-table-column label="采购单位" prop="unitName" width="120">
            </el-table-column>
            <el-table-column label="数量" prop="quantity" width="120">
            </el-table-column>
            <el-table-column label="采购金额" prop="itemTotal" width="120">
            </el-table-column>
            
            <el-table-column label="费用分摊" width="110" prop="total" :class-name="!viewOnly ? 'editor' : ''">
              <template #default="scope">
                <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].total`" v-if="!viewOnly"
                  :rules="formValidator('required', '请输入分摊金额')">
                  <el-input v-model="scope.row.total" />
                </nt-form-item>
                <span v-else>{{ scope.row.total }}</span>
              </template>
            </el-table-column>
            <el-table-column >
            </el-table-column>
          </el-table>
        </div>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-dialog v-model="incomeItemSelectorVisible" destroyOnClose width="75%" append-to-body class="nt-resource-dialog" title="费用单据选择"
    :close-on-click-modal="false" @close="incomeItemSelectorVisible = false">
    <income-item-selector @choose="onIncomeItemSelectedHandler"  @cancel="incomeItemSelectorVisible = false"
      v-if="incomeItemSelectorVisible"></income-item-selector>
  </el-dialog>
  <el-dialog v-model="tradeItemSelectorVisible" destroyOnClose width="75%" append-to-body class="nt-resource-dialog" title="采购单据选择"
    :close-on-click-modal="false" @close="tradeItemSelectorVisible = false">
    <trade-item-selector @choose="onTradeSelectedHandler"  @cancel="tradeItemSelectorVisible = false"
      :billType="tradeBillType" v-if="tradeItemSelectorVisible"></trade-item-selector>
  </el-dialog>
</template>
<script>
import NtBillLayout from '@/views/layout/nt_bill_layout'
import { DataBillMixin } from '@/utils/data_bill_mixin'
import incomeItemSelector from '@views/financial/selector/income_item_selector'
import tradeItemSelector from '@views/financial/selector/trade_item_selector'
import NtFormItem from '@/views/plugin/nt_form_item'

import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'
export default {
  name: "Share_fee",
  mixins: [DataBillMixin],
  components: {
    incomeItemSelector,
    tradeItemSelector,
    NtFormItem,
    NtBillLayout,
    NtBillToolbar
  },
  data() {
    return {
      incomeItemSelectorVisible: false,
      tradeItemSelectorVisible: false,
      billType: this.$route.meta.billType, // 默认采购分摊 // 该参数通过functionMenu extra 进行配置
      entityType:EnumEntityType.SHARE_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  computed:{
    tradeBillType:function(){
      // 选择单据单据时，根据当前分摊单据的类型  采购分摊 或销售分摊，选择不同类型的单据
      return this.billType=='SHARE_PURCHASE'? 'PURCHASE_IN':'SALE_OUT'
    }
  },
  methods: {
    async _createNewEntity() {
      return {
        data: {
          feeItemList: [],
          tradeItemList: [],
          shareType:'TOTAL', // 默认按金额
          billTime: this.getDateTime()
        }
      }
    },
    _printPriview(){ // 打印预览
      this.printPreviewVisible = true
    },
    
    async _loadEntity(params) {
      if (this.entityId) { // 如果refBill 存在则不加载
        let res = await this.getEntity(this.entityType, { id: this.entityId })
        // 注意：由于 销售分摊 采购分摊共享页面，公共工具类型，不传billType，需通过加载页面数据后，自动失败
        this.billType = res.billType
        return res

      } else {
        this.fileList = []
        return this._createNewEntity()
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      if (this.formModel.tradeItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }

      return true
    },

    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, billType: this.billType}, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      postData.tradeItemList.forEach(item=> delete item.tradeHead)
      postData.feeItemList.forEach(item=> delete item.incomeHead)
      
      return this.saveEntity(this.entityType, postData)
    },

    _loadFinished() {
      this.$refs.frmEdit?.clearValidate()
    },

    onAddFeeItemClick(){
      this.incomeItemSelectorVisible = true
    },
    onAddTradeItemClick(){
      this.tradeItemSelectorVisible = true
    },
    onTradeRowDeleteClick(rowIndex) {
      this.formModel.tradeItemList.splice(rowIndex, 1)
    },
    onFeeRowDeleteClick(rowIndex) {
      this.formModel.feeItemList.splice(rowIndex, 1)
    },

    // 选择采购订单入库
    onIncomeItemSelectedHandler(selectBillDetailRows) {
      this.incomeItemSelectorVisible = false
      let listEx = []
      selectBillDetailRows?.forEach(item=>{
        let newItem = {}
        newItem.incomeHead = item.incomeHead
        newItem.financialItemName = item.financialItemName
        newItem.linkItemId = item.id
        newItem.linkId = item.billId
        newItem.linkType = 'INCOME'
        newItem.quantity = 0
        newItem.itemTotal = 0
        newItem.total = item.total - (item.settleTotal||0)
        // TODO去重
        listEx.push(newItem)
      })
      this.formModel.feeItemList.push(...listEx)
    },

    // 选择采购订单入库
    onTradeSelectedHandler(selectBillDetailRows) {
      this.tradeItemSelectorVisible = false
      let listEx = []
      selectBillDetailRows?.forEach(item=>{
        let newItem = {}
        newItem.tradeHead = item.tradeHead
        newItem.goods = item.goods
        newItem.unitName = item.unitName
        newItem.quantity = item.quantity
        newItem.itemTotal = item.total
        newItem.linkItemId = item.id
        newItem.linkId = item.billId
        newItem.linkType = 'PURCHASE_IN'
        // TODO去重
        listEx.push(newItem)
      })
      this.formModel.tradeItemList.push(...listEx)
    },
    onShareClick(){ // 按照类型进行分摊： 金额  数量
      let feeTotal = this.formModel.feeItemList.reduce((pre,cur)=>{
        return pre + cur.total
      },0)
      // 计算明细行，各行占比
      let allQty=0,allTotal=0
      this.formModel.tradeItemList.forEach(item=>{
        allQty += item.quantity
        allTotal += item.itemTotal
      })
      
      this.formModel.tradeItemList.forEach(item=>{
        let rate = 0 // 占比
        if(this.formModel.shareType =='TOTAL'){ // 按金额分摊
          rate = allTotal/item.itemTotal
        } else{ // 按数量分摊
          rate = allQty/item.quantity
        }
        item.total = feeTotal*rate
      })

    },
    getSummaries({ columns, data }) {
      let sumResult = []
      columns.forEach((column, index) => {
        if (index < 2) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }
}
</script>