<!-- 发货单预览
  1. 根据bodyHeight 高度， 除去 标题行高度，数据行总数 ，以及行高
      pageCount = int.floor((bodyHeight-titleHeight)/ dataCount)
 -->

<template>
  <el-container class="no-padding" >
    <el-main :class="{padding:true,printPage:true,preview:isPreview}" style="background:#ddd;width:100%;min-height:10cm;">
      <div :style="{margin:'0px auto',width:templateInfo.pageWidth+'mm',height:templateInfo.pageHeight+'mm'}" v-if="isTemplateLoaded">
        <div id="fking" class="padding-bottom-8" >
          <!-- 根据模板配置文件加载打印布局 -->
            <!-- pageCount 计算需要打印的页数 for， 计算body区每页可以展示的数据量 -->
            <div v-if="templateInfo" class="preview-page" v-for="(item,pageIndex) in new Array(bodyPageCount)"
            :style="{overflow:'hidden',width:templateInfo.pageWidth+'mm',background:'#fff',height:templateInfo.pageHeight+'mm'}">
              <div class="preview-header" :style="{height: templateInfo.headerHeight + 'mm'}">
                <div class="preview-item" :style="{ width:element.width +'pt',height: element.height+'pt',left:element.left+'pt',top:element.top+'pt'}" 
                  v-for="element in templateInfo.configJson.header">
                  <component :is="ctrl[element._t]" :config="element" :isDesignMode="false"/>
                </div>
              </div>
              <div class="preview-body" :style="{position:'relative',height: templateInfo.pageHeight - templateInfo.headerHeight - templateInfo.footerHeight + 'mm'}" >
                <!-- 根据页码，即按分页的方式提取商品列表 -->
                <resizeTable :config="templateInfo.configJson.body" :isDesignMode="false" :subtotalRows="getPageSubtotalRows(pageIndex)" :padding="templateInfo.pageSetting?.padding||{}"
                :datasource="getDataByPage(pageIndex)" :pageIndex="pageIndex" :pageSize="pageSize" ></resizeTable>
              </div>
              <div class="preview-footer" :style="{height: templateInfo.footerHeight + 'mm'}">
                <div class="preview-item" :style="{ width:element.width +'pt',height: element.height+'pt',left:element.left+'pt',top:element.top+'pt'}" 
                  v-for="element in templateInfo.configJson.footer">
                  <component :is="ctrl[element._t]" :config="element" :isDesignMode="false"/>
                </div>
              </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer style="height:auto;" class="align-center padding-top">
      <el-button v-print="printConfig" @click="onPrintClick" type="primary">打印</el-button> <span>共{{bodyPageCount}}张</span>
    </el-footer>
  </el-container>
  
</template>

<script >

import { getTemplate } from '@/service/print_order/template'
import {qr_code,barcode,text,v_line,h_line,rectangle,sys_text} from '@/views/designer/control/all'
import {getReflectValue,parseVarExpr} from '@/utils/reflect'
import '@/views/designer/index.less'
import resizeTable from '@/views/designer/control/table'
import print from 'vue3-print-nb'


export default {
  data () {
    return {
      isTemplateLoaded:false, //模版信息加载结束
      bodyPageCount:1, // 根据商品数量，动态计算单张单据需要的打印的张数
      pageSubtotalInfo:{}, // 用于存放每一页需要显示的合计+页小计的 的行数， 1或2
      isPreview:true,
      _summary:{}, // 汇总信息
      templateInfo:{
        configJson:{
          body:{}
        }
      },
      printDatasource:JSON.parse(JSON.stringify(this.printObject)).tradeItemList,
      printConfig:{
        id:'fking',
        beforeOpenCallback (){
          console.log('previewOpenCallback')
        }
      },
      ctrl: {barcode,qr_code,text,v_line,h_line,rectangle,sys_text}
    }
  },
  components:{
    resizeTable
  },
  props:{
    printObject:{
      type: Object
    },
    templateId:{
      type: String
    }
  },
  
  async mounted(){
    // console.log('remark',checkValidVar('=[remark]'))
    // 打印明细测试数据
    this.printDatasource = [
      {goodsName:'g1'},{goodsName:'g2',standard:'s1',originalPrice:1},{goodsName:'g3'},{goodsName:'g4'},{goodsName:'g5',originalPrice:2},
      {goodsName:'g6'},{goodsName:'g7'},{goodsName:'g8'},{goodsName:'g9'},{goodsName:'g10'},{goodsName:'g11'},
      {goodsName:'g12'},{goodsName:'g13'},{goodsName:'g14',originalPrice:3},{goodsName:'g15',standard:'s1'},{goodsName:'g16'},{goodsName:'g17'},{goodsName:'g18'}
    ]
    let res = await getTemplate({id: this.templateId})
    this.isTemplateLoaded = true
    // 根据变量，解析printObject 数据，获取变量表达式
    let obj = res.data||{configJson:'{}'}
    obj.configJson = JSON.parse(obj.configJson||'{}')
    obj.pageSetting = JSON.parse(obj.pageSetting|| '{}')
    // 打印数据时，获取整张单据的汇总业务数据
    if(res.code == 200){
      obj.configJson.header?.forEach(item=>{
        // 不区分组件，只要存在变量，则直接提取，提取不到，则按原文
        // console.log('pre:', checkValidVar(item.v),item.v)
        let fieldVar = parseVarExpr(item.v)
        if(fieldVar){ //判断是否为变量格式 =[...]
          item.v = getReflectValue(this.printObject,fieldVar)
        }
      })
      obj.configJson.footer?.forEach(item=>{
        let fieldVar = parseVarExpr(item.v)
        if(fieldVar){ //判断是否为变量格式 =[...]
          item.v = getReflectValue(this.printObject,fieldVar)
        }
      })
    }

    this.templateInfo = obj

    // 在开启合计开关的场景，根据配置信息，对所有的明细，按 列表的 汇总方式处理数据 this._summary
    this.createSummaryInfo()

    let gConfig = this.templateInfo.configJson.body || {contentHeight:1}
    
    this._rowSize = Math.floor(this.getContentHeight()/gConfig.contentHeight) // 最大可容纳行数

    this.initPageInfo()

  },
  methods:{
    createSummaryInfo(){ // 计算合计统计信息 
      let gridConfig = this.templateInfo.configJson.body ||{}
      if(!gridConfig.subtotal) return //不显示合计行
      
      // 提取需要合计统计列集合
      let arCols = gridConfig.columns?.filter(item=> item.subtotalType!='NONE')
      let allRows = this.printDatasource.length

      if(!arCols?.length || !allRows){
        return
      }
      
      this._summary = this.getDataSummary(this.printDatasource) // 合计汇总信息
    },
    getContentHeight(){ // 获取网格内容区高度
      let gridConfig = this.templateInfo.configJson.body ||{}
      let titleHeight = gridConfig.titleHeight || 0 // 标题行高
      let bodyHeight = this.templateInfo.pageHeight - this.templateInfo.headerHeight - this.templateInfo.footerHeight
      return (bodyHeight - titleHeight) 
    },
    getPageSubtotalRows(pageIndex){ // 获取当前页的合并行数： 合计1， 页小计1，最大2
      return this.pageSubtotalInfo[pageIndex]?.subtotalCount || 0
    },
    initPageInfo(){
      let gridConfig = this.templateInfo.configJson.body  || {}// 网格配置信息节点
      let subtotalSetting = gridConfig.subtotalSetting || {}

      let subtotalRowHeight = subtotalSetting.rowHeight || 8
      let subPageRowheight = subtotalSetting.subPageRowheight || 8
      let totalCount = this.printDatasource.length // 总记录数
      let currentPageSize = this._rowSize
      let pageCount = 0

      if(gridConfig.subtotal){ // 需要显示合计行
        /* 在开启合计的条件，至少每页需占一个合计
           如果开启分页小计，每页需要占2个合计行
        */
        let lineHeight = this.templateInfo.configJson.body.contentHeight // 行高mm
        let contentHeight = this.getContentHeight() - subtotalRowHeight // 按 本页需要合计+小计, 注意如果小计&合计行高 差异较大则出现计算误差
        let ocupyRows = 1
        if(subtotalSetting.subPage){ // 开启分页小计
          contentHeight -= subPageRowheight
          ocupyRows++
        }
        // 循环计算，直至没有记录
        let lastIndex = 0
        while(lastIndex<totalCount){
          currentPageSize = Math.floor(contentHeight/lineHeight) // 最小可容纳行数
          let remainderCount = totalCount - (lastIndex + currentPageSize) //当前页全部填充满时，剩余需要打印的计量数
          // ocupyRows  开启分页小计时，该值为2，表示 合计，分页小计各占一行，  1时：表示 不显示分页小计
          this.pageSubtotalInfo[pageCount] = {subtotalCount:ocupyRows} //合计及页小计行数
          if(remainderCount>=ocupyRows && subtotalSetting.lastPage){ // 即使本页显示 合计+页计， 仍然需要打印下一页
            // 仅存在下一页，即 剩余记录大于2，如合计仅在末页显示时，额外显示多一条
            currentPageSize ++ // 额外多显示一条记录
            this.pageSubtotalInfo[pageCount] = {subtotalCount:ocupyRows-1} // 合计及页小计行数
          }
          this.pageSubtotalInfo[pageCount].size = currentPageSize // 每月实际显示的单据明细行数
          this.pageSubtotalInfo[pageCount].start = lastIndex
          lastIndex += currentPageSize
          pageCount++
        }

      } else { // 不显示合计的场景下
        let goodsCount = this.printDatasource.length
        pageCount = Math.ceil(goodsCount/currentPageSize) // 根据单据商品行数，打印的张数
      }
      
      this.bodyPageCount = pageCount
    },


    getDataSummary(ds){
      let gridConfig = this.templateInfo.configJson.body ||{columns:[]} // 网格配置信息节点
      let arCols = gridConfig.columns?.filter(item=> item.subtotalType!='NONE')
      let summary = {}
      // 处理当前页小计
      arCols.forEach(col=>{
        summary[col.prop] = 0
      })
      let avg_count_map = {}
      let allRows = ds.length
      ds.forEach(row=>{
        arCols.forEach(col=>{
          // subtotalType  SUM 求和  AVG 平均值(数据为空，不作为计算) COUNT 记录数
          if(['SUM','AVG'].includes(col.subtotalType) && row[col.prop] != null && row[col.prop] != undefined && !Number.isNaN(row[col.prop])){
            summary[col.prop] += row[col.prop]
            if(col.subtotalType == 'AVG'){
              avg_count_map[col.prop] = (avg_count_map[col.prop]||0) +1
            }
          }
        })
      })
      arCols.filter(col=>col.subtotalType == 'AVG').forEach(col=>{
        if(avg_count_map[col.prop]){ // 存在有效的 统计行信息
          summary[col.prop] = summary[col.prop]/(avg_count_map[col.prop]*1.00)
        } else {
          summary[col.prop] = ''
        }
      })
      arCols.filter(col=>col.subtotalType == 'COUNT').forEach(col=>{
        summary[col.prop] = allRows
      })
      return summary
    },

    getDataByPage(pageIndex){
      let gridConfig = this.templateInfo.configJson.body ||{} // 网格配置信息节点
      let list = []
      if(gridConfig.subtotal){ // 显示合计
        let pInfo = this.pageSubtotalInfo[pageIndex]
        list = this.printDatasource.slice(pInfo.start,(pInfo.start + pInfo.size))
      } else {
        let start = this._rowSize * pageIndex
        list = this.printDatasource.slice(start,(start + this._rowSize))
      }

      let page_summary = this.getDataSummary(list) // 分页小计信息

      let diffCount = this._rowSize - list.length
      if(diffCount>0){
        let nData = new Array(diffCount)
        for(let idx =0;idx<nData.length;idx++){
          nData[idx]={} // null 数组，表格不渲染
        }
        list.push(...nData) // 网格列表空白处，填充空白行
      }

      if(gridConfig.subtotal){
        let pInfo = this.pageSubtotalInfo[pageIndex]

        let matchCol = this.templateInfo.configJson.body.columns?.find(item=>item.isSubtotalText)
        if(!matchCol){ // 未指定合计列，则默认第一列显示合计
          matchCol= this.templateInfo.configJson.body.columns[0] 
        }

        let subtotalSetting = gridConfig.subtotalSetting || {}
        if(pInfo.subtotalCount == 2){ // 合计+ 分页小计
          let sRow =  list[list.length-1]
          sRow[matchCol.prop]= '合计'
          Object.assign(sRow,this._summary)

          // 页小计行
          sRow =  list[list.length-2]
          sRow[matchCol.prop]= '小计(页)'
          Object.assign(sRow,page_summary)
        } else if(pInfo.subtotalCount == 1) {
          // 显示 合计  或 页小计 二选1
          if(subtotalSetting.subPage){ // 每页显示 显示页小计
            let sRow =  list[list.length-1]
            sRow[matchCol.prop]= '小计(页)'
            Object.assign(sRow,page_summary)
          }
          else {
            let sRow =  list[list.length-1]
            sRow[matchCol.prop]= '合计'
            Object.assign(sRow,this._summary)
          }
        }
      }
      return list
    },

    onPrintClick(){
      this.isPreview = false
      setTimeout(() => {
        this.isPreview =true
      }, 1500);
    }
  }
}

</script>

<style lang="less">
  .preview-page{
    position: relative;
    
  }
  .preview{
    .preview-page{
      margin-bottom:6px;
      box-shadow:var(--el-box-shadow-light);
      &:last-child{
        margin-bottom:12px;
      }
    }
  }
  .preview-item{
    position: absolute;
    overflow: hidden;
  }
  .preview-header{
    position: relative;
  }
  .preview-body{
    position: relative;
  }
  .preview-footer{
    position: relative;
  }
</style>