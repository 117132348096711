<!-- 业务单据预览
  1. 根据bodyHeight 高度， 除去 标题行高度，数据行总数 ，以及行高
      pageCount = int.floor((bodyHeight-titleHeight)/ dataCount)
 -->

<template>
  <el-dialog
    v-model="isVisible" destroyOnClose width="1050" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog"
    @close="isVisible=false"
  >
    <div style="max-height:85%">
      <el-container class="padding nt-height-100">
        <el-main :class="{ padding: true, printPage: true, preview: isPreview }" style="background:#ddd;width:100%;min-height:10cm;max-height:550px;">
          <div :style="{ margin: '0px auto', width: templateInfo.pageSetting.pageWidth + 'mm' }"
            v-if="isTemplateLoaded">
            <div id="fking" class="nt-bg-white" >
              <DocumentPage :printObject="printObject" :listNode="listNode" :templateInfo="templateInfo" v-for="(printObject,index) in printObjectList" :key="index" ></DocumentPage>
            </div>
          </div>
        </el-main>
        <el-footer  class="align-center height-auto no-margin padding-top">
          <el-button v-print="fking" @click="onPrintClick" type="primary">打印</el-button>
          <span>共{{ bodyPageCount }}张</span>
        </el-footer>
      </el-container>
    </div>
  </el-dialog>
  
</template>

<script >

import { getTemplate } from '@/service/print_order/template'
import { qr_code, barcode, text, v_line, h_line, rectangle, sys_text } from '@/views/designer/control/all'
import { getReflectValue, parseVarExpr } from '@/utils/reflect'
import PrintCreator from '@/utils/print_utils'
import '@/views/designer/index.less'
import resizeTable from '@/views/designer/control/table'
import DocumentPage from './document'
import print from 'vue3-print-nb'


export default {
  data() {
    return {
      isVisible:false,
      printObjectList:[],
      templateId:null,
      listNode:null,
      isTemplateLoaded: false, //模版信息加载结束
      isPreview: true,
      // _summary:{}, // 汇总信息
      templateInfo: {
        configJson: {
          body: {}
        }
      },
      printConfig: {
        id: 'fking',
        beforeOpenCallback() {
          console.log('previewOpenCallback')
        }
      },
      ctrl: { barcode, qr_code, text, v_line, h_line, rectangle, sys_text }
    }
  },
  components: {
    DocumentPage,
    resizeTable
  },
  computed:{
    isAutoHeight:function(){ // 页面单高度是否有 body区域的高度配置控制
      let autoHeight = false
      if(this.templateInfo.pageSetting.bodyContainer == 'block'){ // 自由容器模式
        autoHeight = this.templateInfo.configJson.freeContainer.autoHeight||false
      } else { //网格模式
        autoHeight = this.templateInfo.configJson.body.autoHeight||false
      }
      return autoHeight
    }
  },
  async mounted() {
  },
  methods: {
    
    async show(title,options){
      this.printObjectList = options.documents
      this.templateId = options.templateId
      this.listNode = options.listNode

      // console.log('xxxx',options)

      this.isTemplateLoaded = false
      let res = await getTemplate({ id: this.templateId })
      this.isTemplateLoaded = true
      if (res.code == 200) {
        let obj = res.data || { configJson: '{}' }
        obj.configJson = JSON.parse(obj.configJson || '{}')
        obj.pageSetting = JSON.parse(obj.pageSetting || '{}')
        this.templateInfo = obj
      }
      this.isVisible=true
    },
    onPrintClick() {
      this.isPreview = false
      setTimeout(() => {
        this.isPreview = true
      }, 1500);
    }
  }
}

</script>

<style lang="less">
.preview-page {
  position: relative;

}

.preview {
  .preview-page {
    margin-bottom: 6px;
    box-shadow: var(--el-box-shadow-light);

    &:last-child {
      margin-bottom: 12px;
    }
  }
}

.preview-item {
  position: absolute;
  overflow: hidden;
}

.preview-header {
  position: relative;
}

.preview-body {
  position: relative;
}

.preview-footer {
  position: relative;
}</style>