
<template>
  <el-container class="padding nt-bg-page nt-height-100">
    <el-header class="no-margin no-padding height-auto display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <nt-form-item label="仓库名称" >
          <el-input placeholder="请输入仓库名称查询" v-model="queryModel.storeName"></el-input>
        </nt-form-item>
        <nt-form-item label="备注" >
          <el-input placeholder="请输入备注查询" v-model="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </nt-form-item>
      </el-form>
      <div>
        <el-button  @click="onAddClick" type="primary">新增</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>

        <el-table-column label="仓库名称" width="220" prop="storeName"></el-table-column>
        <el-table-column label="仓库地址" width="220" prop="address"></el-table-column>
        <el-table-column label="仓储费" width="100" prop="warehousing"></el-table-column>
        <el-table-column label="搬运费" width="100" prop="truckage"></el-table-column>
        <el-table-column label="负责人" width="100" prop="principalName"></el-table-column>
        <el-table-column label="备注" width="220" prop="remark"></el-table-column>

        <el-table-column label="排序" width="100" prop="sort"></el-table-column>
        <el-table-column label="是否启用" width="100" prop="enabled">
          <template #default="scope">
            <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
            <el-tag v-if="!scope.row.enabled" type="warning">禁用</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="是否默认" width="100" prop="isDefault">
          <template #default="scope">
            <el-tag v-if="scope.row.isDefault" type="success">是</el-tag>
            <el-tag v-if="!scope.row.isDefault" type="warning">否</el-tag>
          </template>
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?'}"
          :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding margin-top">
      <el-row justify="end">
        <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
      </el-row>
      
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>

import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "StoreList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {

    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.STORE, params);
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.STORE, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    // modalFormOk(){},
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onRowSetDefaultClick(record) {
      let res = await this.setEntityDefault(this.EnumEntityType.STORE, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.STORE, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>
