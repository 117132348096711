<!-- 批量操作结果弹窗提示 -->
<template>
  <el-dialog  v-model="isVisible" destroyOnClose  width="600px" class="nt-resource-dialog" append-to-body :title="title"
    :close-on-click-modal="false" title="详情" @close="isVisible = false">
    <el-container class="padding" style="height:350px;">
      <el-header class="no-padding no-margin height-auto text-algin-center">
        <el-space>
          <span>操作记录数: {{ result.total }}</span>
          <span>成功: {{ result.successCount }}</span>
          <span>失败: {{ result.failCount }}</span>
        </el-space>
      </el-header>
      <el-main class="no-padding margin-top">
        <el-table border :data="result.resultList" height="100%" v-loading="isLoading">
          <el-table-column label="结果" prop="message" >
            <template #default="{row}">
              <div :style="{color:row.success?'var(--el-color-primary)':'var(--el-color-danger)'}">{{ row.message }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template #default="{row}">
              <el-tag type="success" v-if="row.success">成功</el-tag>
              <el-tag type="danger" v-else>失败</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="align-right height-auto no-margin no-padding margin-top">
        <el-button @click="isVisible =false" type="primary">确定</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>

export default {
  name: "batch_result_dialog",
  components: {
  },
  data() {
    return {
      result: {},
      title:null,
      isVisible: false
    }
  },
  props:{
  },
  methods: {
    async showDialog(title, batchResult) {// 显示结果弹窗
      this.title = title
      this.result = batchResult
      this.isVisible = true
    }
  }
}
</script>
