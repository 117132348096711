<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="no-padding" style="background:#fff;">
      <el-header class="no-margin no-padding" style="height:auto;padding-bottom:0px;">
        <nt-form-item prop="templateName" label="模版名称" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入模版名称" v-model.trim="formModel.templateName" />
        </nt-form-item>
        <nt-form-item prop="templateDesc" label="模版备注">
          <el-input placeholder="请输入模版备注" v-model.trim="formModel.templateDesc" />
        </nt-form-item>
        <nt-form-item prop="isDefault" label="设置为默认">
          <el-checkbox  v-model="formModel.isDefault" :true-label="1" />
        </nt-form-item>
      </el-header>
      <el-main class="no-padding">
        <el-main class="no-padding">
          <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="formModel.shopWaybillTemplateOptionList">
            <el-table-column label="#" width="50" type="index" fixed="left">
            </el-table-column>
            <el-table-column label="选项" prop="optionId">
              <template #default="{row}">
                {{ formatOptionName(row.optionId)}}
              </template>
            </el-table-column>
            <el-table-column label="字体大小" width="120" prop="fontSize">
              <template #default="{row}">
                <el-select  v-model="row.fontSize">
                  <el-option
                    v-for="item in fontSizeList"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="粗体" width="90" align="center" prop="isBold">
              <template #default="{row}">
                <el-checkbox  v-model="row.isBold" :true-label="1" />
              </template>
            </el-table-column>
            <el-table-column label="是否使用" width="90" prop="isOpen" align="center">
              <template #default="{row}">
                <el-checkbox  v-model="row.isOpen" :true-label="1" />
              </template>
            </el-table-column>
            
          </el-table>
        </el-main>
      </el-main>
      <el-footer class="no-padding padding-top" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
  
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
import {saveWaybillTemplate,getWaybillTemplate} from '@/service/print_order/platform_waybill_template'

export default {
  name: "WaybillTemplateDetail",
  mixins: [DataEditMixin],
  components: { NtFormItem },
  data() {
    return {
      igoreBillNo:true,
      fontSizeList:[{value:0, text:'正常'},{value:1,text:'加粗'}],
      trueFalseList:[{value:0, text:'否'},{value:1,text:'是'}],
    }
  },
  props: {
    entityId: {
      type: String
    },
    shopId:{
      type:String,
      required:true
    },
    standardTemplate:{}, // 所属标准模版
  },
  methods: {
    async _initialize() {
    },
    _loadFinished() {
    },
    async _loadEntity(params) {
      if (this.entityId) {
        return await getWaybillTemplate( { id: this.entityId })
      } else {
        let defaultOptions = [0,1,2,3,4,5,6,7].map(item=>{// 设置默认的8个选项
          return {optionId: item,fontSize: 0, isBold: false,isOpen: item>2}
        })
        return {data:{ 
          deliveryId: this.standardTemplate.deliveryId,
          platform: this.standardTemplate.platform,
          templateType: this.standardTemplate.templateType,
          templateDesc: this.standardTemplate.desc,
          shopWaybillTemplateOptionList:defaultOptions
        }}
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      return true
    },
    formatOptionName(optionId){
      let info={
        0:'商品总数量',
        1:'商品名称+规格+编码+数量',
        2:'商品名称+规格+数量',
        3:'商品名称+数量',
        4:'店铺名称',
        5:'订单号',
        6:'买家留言',
        7:'卖家备注',
      }
      return info[optionId] || optionId
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId,shopId:this.shopId}, this.formModel)
      return saveWaybillTemplate( postData)
    },
  }
}
</script>