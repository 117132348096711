
<template>
  
  <el-container class="nt-height-100">
    <!-- <el-header>分类层次，折叠方式</el-header> -->
    <el-header class="no-margin no-padding height-auto" style="height:auto;">
      <el-space style="justify-content:space-between;width:100%;">
        <el-form-item label="商品分类" class="no-margin" size="small">
          <el-select v-model="showConfig.showLevel" size="small" style="width:100px;">
            <el-option :label="`${lvl}级`" :value="lvl" v-for="lvl in [1,2,3,4,5]"></el-option>
          </el-select>
        </el-form-item>
        <div class="display-flex">
          <el-form-item size="small">
            <el-checkbox style="margin-right:4px;" label="全部展开" v-model="showConfig.expandAll" v-if="showConfig.showTree"></el-checkbox>
            <el-radio-group v-model="showConfig.showTree" size="small">
              <el-radio-button :label="true" >层级展示</el-radio-button>
              <el-radio-button :label="false">平级展示</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-space>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="refTbl" size="middle" height="100%" class="nt-rpt-table" row-key="categoryId" :row-style="{ height: '40px' }" :data="datasource"
        v-loading="isLoading" :default-expand-all="showConfig.expandAll">
        <el-table-column label="分类" prop="categoryName" width="220" v-if="showConfig.showTree">
          <template #default="{row}">
            {{ row.categoryName || '未分类' }}
          </template>
        </el-table-column>
        <template v-else>
          <el-table-column label="分类(1级)" prop="categoryName1" width="140">
          </el-table-column>
          <el-table-column label="分类(2级)" prop="categoryName2" width="140">
          </el-table-column>
          <el-table-column label="分类(3级)" prop="categoryName3" width="140">
          </el-table-column>
          <el-table-column label="分类(4级)" prop="categoryName4" width="140">
          </el-table-column>
          <el-table-column label="分类(5级)" prop="categoryName5" width="140">
          </el-table-column>
        </template>
        
        <el-table-column label="采购数量" prop="inBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN')" >
              {{ row.inBaseQuantity }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货数量" prop="backBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >
              {{ row.backBaseQuantity }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >
              {{ ((row.backTotal||0)).toFixed(2) }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购数量" prop="quantity" width="120" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >
              {{ row.inBaseQuantity - row.backBaseQuantity }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购金额" prop="total" width="120" align="right">
          <template #default="scope">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >
              {{ (((scope.row.inTotalWithTax||0) - (scope.row.backTotalWithTax||0))).toFixed(2) }}
            </el-link>
          </template>
        </el-table-column>
        <nt-chineseMoney-column prop="backTotal" width="160"></nt-chineseMoney-column>
        <el-table-column></el-table-column>
      </el-table>
    </el-main>
  </el-container>
  <el-drawer
    v-model="detailVisible" destroyOnClose size="85%"
    class="nt-inner-drawer"
    :with-header="false"
    :z-index="99"
    @close="detailVisible=false">
    <!-- 入参 ： 时间范围，商品， 商品分类， 往来单位， 付款状态 -->
    <trade-item-detail  @cancel="detailVisible =false"  :query="subQuery" v-if="detailVisible"></trade-item-detail>
    <i class="nt-drawer-close" @click="detailVisible = false">关闭</i>
  </el-drawer>
</template>
<script>
import { getPurchaseInTotalByCategory } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import tradeItemDetail from '@/views/report/inner_detail/purchase_sale/trade_item_detail_group_by_bill'
export default {
  name: "PurchaseTotalByCategory",
  mixins: [DataListMixin],
  components: {
    tradeItemDetail
  },
  data() {
    return {
      subQuery:{},
      detailVisible:false,
      showConfig:{
        showLevel:1, // 默认显示n级
        expandAll:true,
        showTree:true
      }
      
    }
  },
  watch:{
    'showConfig.expandAll':function(val){
      this.datasource.forEach(row => {
        this.$refs.refTbl.toggleRowExpansion(row, val); // 全部展开
      });
    },
    'showConfig.showTree':function(val){
      // 切换显示方式
      // this.loadList()
      this.changeLayout()
      // console.log('showtree', val)
    },
    'showConfig.showLevel':function(val){
      // 显示分类层级数
      // this.loadList()
      this.changeLayout()
      // console.log('showLevel', val)
    }
  },
  methods: {
    changeLayout(){
      if(this.showConfig.showTree){
        this.datasource = this.processData()
      } else{
        this.datasource= this._datasource
      }
      // console.log('xxxxxxx', this.datasource)
    },
    _loadFinished(){
      // this._datasource= this.datasource
      console.log('_loadFinish', this.showConfig)
      this.changeLayout()
    },
    async _loadList(params) {
      delete params.createTimeRange
      let res = await getPurchaseInTotalByCategory(Object.assign(params, {}))
      this._datasource = res.data // 备份数据
      return null
    },
    _query(params) {
      // console.log('category Page', params)
      this.loadList(params);
      console.log('_query', this.showConfig)
    },
    _exportXlsx(params) {
      console.log('to do exportXlsx')
    },
    processData() {
      let resData = this._datasource
      let categorySummary = {}
      /*
        根据每条记录，从第5分类到 往上溯跟到第1分类，按分类累加，按分组累加 
        5级为当前商品的分类
      */
      let increateCategoryQty = (item, lvl) => {
        lvl = lvl || 5 //默认直接分类
        let currentCategoryId = item[`categoryId${lvl}`]// 当前处理的分类按层次
        let category = categorySummary[currentCategoryId]//根据层级，构建动态key
        if (category) {
          category.inBaseQuantity += item.inBaseQuantity ||0
          
          category.backBaseQuantity += item.backBaseQuantity||0
          category.inTotal += item.inTotal||0
          category.inTotalWithTax += item.inTotalWithTax||0
          category.backTotal += item.backTotal||0
          category.backTotalWithTax += item.backTotalWithTax||0
          category.backCostTotal += item.backCostTotal||0
          category.outCostTotal += item.outCostTotal||0
        } else {
          category = { categoryId: currentCategoryId,categoryName:item[`categoryName${lvl}`], 
          inBaseQuantity: item.inBaseQuantity,
          backBaseQuantity: item.backBaseQuantity,
          inTotal: item.inTotal,
          inTotalWithTax: item.inTotalWithTax,
          backTotal: item.backTotal,
          backTotalWithTax: item.backTotalWithTax,
          backCostTotal: item.backCostTotal,
          outCostTotal: item.outCostTotal,
          parentId: item[`categoryId${lvl - 1}`] }
          categorySummary[currentCategoryId] = category
          if (item[`categoryId${lvl - 1}`]) { // 处理当前数据的下一个分类
            increateCategoryQty(item, --lvl) // --lv| 处理上一级
          }
        }
      }
      // 累加时，溯跟
      resData.forEach(item => {
        increateCategoryQty(item)
      })
      // console.log('categorySummary',categorySummary)
      // categorySummary据分类统计信息， 构建树parentld=null 为根
      let rootList = Object.values(categorySummary).filter(item => !item.parentId)// 寻根
      // 根据参数控制返回的层次，数据源为5级
      let buildChild = (item,lvl) => {
        lvl = lvl || 1
        if(lvl>= this.showConfig.showLevel){
          return
        }
        item.children = []
        Object.values(categorySummary).forEach(child => {
          if (item.categoryId && child.parentId == item.categoryId) {
            item.children.push(child)
            buildChild(child,lvl+1) // 递归下一级
          }
        })
      }
      rootList.forEach(item => {
        buildChild(item)
      })

      //结束后增加合计节点，以根为准
      return rootList
    },
    onViewDetailClick(row,billType){
      // 根据查询时间，当前往来单位查询交易详情
      // 构造查询参数： 时间范围， 商品id
      this.subQuery= {
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        categoryIds: row.categoryId, // 接口支持多个分类
        billType: billType
      }
      this.detailVisible = true
    }
  }
}
</script>
