<template>
  <div class=" nt_tag_select el-select__selected-item el-select__input-wrapper nt-bg-white" v-if="!readonly" @click="onFocus">
    <div class="el-select__selected-item" v-for="(tag,index) in tags" ref="buttonRef" :key="index">
      <el-tag ref="tagRef" @close="onTagClose(index)" :type="tagType" size="small" light round :closable="!readonly">
      {{tag}}
      </el-tag>
    </div>
    <input ref="txtlnput" v-model="input" @input="onInputChange" spellcheck="false" @keydown.enter.stop.prevent="onKeyEnter"
      class="el-select__input" :style="{width: `${width}px`}"/>
    <span ref="txtCalc" class="input-editor" v-text="input" ></span>
</div>
</template>
<script>
export default {
  data() {
    return {
      input: '' ,
      width:11,
      editTag:{},// 当前操作的tag
      tagValue: this.modelValue || ''
    }
  },
  model: {
    // 自定义v-model 双向绑定数据
    props: 'modelValue',
    emits:['update:modelValue'], //update:modelValue
  },
  props: {
    modelValue: {},
    tagType:{
      type:String,
      default:'info'
    },
    tagSplitter:{// 标签分隔
      type: String,
      default: ','
    }
  },
  computed:{
    tags:function(){// 监控标签的变化
    // 根据绑定的值，进行数据切割，切割内容分隔符，切割色值$,形成标签对象
      if(!this.tagValue){
        return []
      }
      // 根据对象，多选，单选，对象类型，id-key 映射配置显示数据
      return this.tagValue.split(this.tagSplitter).map(item=>item)
    }
  },
  watch:{
    modelValue:function(){
      this.tagValue = this.modelValue
    }
  },
  methods:{
    onFocus(){
      if(this.readonly){
        return
      }
      this.$refs.txtlnput.focus()
    },
    onInputChange(event){
      this.input=event.target.value
      // 根据已输入tag总宽度组件的总宽度 计算输入框的最大值
      this.width = this.$refs.txtCalc.getBoundingClientRect().width
    },
    onKeyEnter(e){
      if(this.input.trim()===''){
        return
      }
      if(!this.tagValue){
        this.tagValue = this.input.replaceAll(this.tagSplitter,'')
      }else {
        this.tagValue +=this.tagSplitter + this.input.replaceAll(this.tagSplitter,'')
      }
      this.input=''
      this.width=10
      this.tagValueChanged();
    },
    onTagClose(index){
      let ar = this.tagValue.split(this.tagSplitter)
      ar.splice(index,1)
      this.tagValue =ar.join(this.tagSplitter)
    
      this.tagValueChanged()
    },
    tagValueChanged(){
      this.$emit('update:modelValue',this.tagValue)
    }
  }
}
</script>

<style lang="less" scoped>
  .nt_tag_select{
    box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
    border-radius:var(--el-input-border-radius,var(--el-border-radius-base));
    padding:4px;
    flex-wrap:wrap;
    row-gap:4px;
    column-gap:4px;
    min-height:22px;
    display: flex;
    column-gap:4px;
    width:100%;
    align-items: center;
    .tag_wrapper{
      flex:1;
      display: flex;
      column-gap: 2px;
      flex-wrap: wrap;
      align-items: center;
      // height:20px; // 根据 small 调整高度 todo
    }
    :deep(.el-input__wrapper){
      padding:0px;
      border:none;
      box-shadow: none;
    }
    &.readonly{
      cursor: not-allowed;
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #c0c4cc;
    }
    .el-tag{
      border:none;
    }
    :deep(.el-tag__content){
      max-width:80px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    :deep(.el-select__input){
      margin-left:0px!important;
      min-width:11px !important;
      max-width:100%;
      // height: 0px;
    }
    :deep(.el-select__selected-item){
      line-height:18px;
    }
    .input-editor{
      position: absolute;
      left: 0;
      top: 0;
      max-width:100%;
      visibility: hidden;
      overflow: hidden;
      height:100%;
    }
  }
</style>