
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin margin-bottom" style="height:auto;padding-bottom:0px;">
      <el-form layout="inline" @keyup.enter.native="onSearchQueryClick">
        <el-row :gutter="24">
          <el-col :md="8" :sm="24">
            <el-form-item label="单据编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-form-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"></NTDateRange>
            </el-form-item>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
            <a @click="handleToggleSearch" style="margin-left: 8px">
              {{ toggleSearchStatus ? '收起' : '展开' }}
              <el-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
            </a>
          </el-col>
          <template v-if="toggleSearchStatus">
            <el-col :md="6" :sm="24">
              <el-form-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select placeholder="选择客户" showSearch optionFilterProp="children" v-model="queryModel.companyId">
                  <el-select-option v-for="(item,index) in cusList" :key="index" :value="item.id">
                    {{ item.supplier }}
                  </el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="仓库名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select placeholder="请选择仓库" showSearch optionFilterProp="children" v-model="queryModel.depotId">
                  <el-select-option v-for="(depot,index) in depotList" :value="depot.id" :key="index">
                    {{ depot.storeName }}
                  </el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="制单人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select placeholder="选择制单人" showSearch optionFilterProp="children" v-model="queryModel.creator">
                  <el-select-option v-for="(item,index) in userList" :key="index" :value="item.id">
                    {{ item.userName }}
                  </el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="关联单据" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-input placeholder="请输入关联单据" v-model="queryModel.linkNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="单据状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select placeholder="选择单据状态" v-model="queryModel.status">
                  <el-select-option value="0">未审核</el-select-option>
                  <el-select-option value="1">已审核</el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="单据备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-input placeholder="请输入单据备注" v-model="queryModel.remark"></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table
        border
        ref="table"
        size="middle"
        rowKey="goodsSkuId"
        :data="datasource"
        v-loading="isLoading"
        >
          <el-table-column label="单据类型" width="140" prop="billType">
            <template #default="scope">
              <!-- {{ this.EnumBillType }}
              {{this.EnumBillType[scope.row.billType] }} -->
              {{EnumBillType[scope.row.billType] || scope.row.billType}}
            </template>
            
          </el-table-column>
          <el-table-column label="单据编码" width="180" prop="billNo">
            <template #default="scope">
              <a @click="onViewBillDetailClick(scope.row)">{{scope.row.billNo}}</a>
            </template>
          </el-table-column>
          <el-table-column label="商品" prop="goodsName">
          </el-table-column>
          <el-table-column label="单位" width="80" prop="unitName">
          </el-table-column>
          <el-table-column label="仓库" width="80" prop="storeName">
          </el-table-column>
          <el-table-column label="单据日期" width="180" prop="billTime">
          </el-table-column>
          <el-table-column label="数量" width="80" prop="quantity">
          </el-table-column>
          <el-table-column label="金额合计"  prop="quantity">
            <template #default="scope">
              {{ scope.row.quantity*scope.row.price/100.00 }}
            </template>
          </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination
          :current-page="this.pageInfo.pageIndex"
          :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="this.pageInfo.total"
          background 
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="onPageIndexChange"
          @size-change="onPageSizeChange"
        />
      </el-row>
    </el-footer>
  </el-container>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>
  import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
  import {getPageEntityList} from '@/service/resource/general'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import NTDateRange from '@/views/plugin/nt_date_range'
  import {EnumBillType,EnumEntityType} from '@/utils/enum_utils'
  export default {
    name: "DepotFlowList",
    mixins:[DataListMixin],
    components: {
      BillViewerPage,NTDateRange
    },
    data () {
      return {
        currentRecord:{},
        toggleSearchStatus:false,
        detailVisible:false,
        selectedRowKeys:[],
        // 表头
        // columns: [
        //   { title: '单据类型', dataIndex: 'billType',width:60,
        //     customRender:(t,r,index)=>{
        //       return EnumBillType[r.billType] || r.billType
        //     }
        //   },
        //   {title: '单据编码', dataIndex: 'billNo', align:"center", width: 140,
        //     scopedSlots: { customRender: 'viewDetailAction' }
        //   },
        //   { title: '商品', width:80, ellipsis:true, dataIndex: 'goodsName'
        //   },
        //   { title: '单位', width:50, ellipsis:true, dataIndex: 'unitName'
        //   },
        //   { title: '仓库', width:50, ellipsis:true, dataIndex: 'storeName'
        //   },
        //   { title: '单据日期', dataIndex: 'billTime',width:105,
        //     customRender: (_,r,index) =>  this.formatDate(r.billTime)
        //   },
        //   { title: '数量', dataIndex: 'quantity',width:60,
        //     customRender:function(t,r,index){
        //       return r.quantity;
        //     }
        //   },
        //   { title: '金额合计', dataIndex: 'total',width:80,
        //     customRender:function(t,r,index){
        //       return r.quantity*r.price/100;
        //     }}
        // ]
      }
    },
    props:{
      materialId:{
        type:String
      }
    },
    methods: {
      async _loadList(params){
        return await getPageEntityList(EnumEntityType.STORE_FLOW, Object.assign({goodsId:this.materialId},params));
      },
      onDateOk(){},
      onSearchQueryClick(){
        this.loadList()
      },
      onSearchResetClick(){
        this.queryModel = {}
        this.resetPage = true
        this.loadList()
      },
      onDateChange: function (value, dateString) {
        this.queryModel.startTime=dateString[0];
        this.queryModel.endTime=dateString[1];
      },
      handleToggleSearch(){},
      
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectionRows = selectionRows;
      },
      onViewBillDetailClick(record){
        this.$refs.refViewer.showBill(record.billType,record.billId)
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less'
</style>