<template>
  <div class="nt-h-line">
    <div :style="textStyle"></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      
    }
  },
  computed:{
    textStyle:function(){
      let style = {}
      style.borderStyle = this.config.borderStyle
      style.borderWidth = this.config.borderWidth + 'px'
      return style
    }
  },
  props:{
    config:{
      type: Object
    }
  },
  methods:{
  }
}

</script>