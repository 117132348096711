
<!-- 
  根据收付款查询采购销售交易核销情况
-->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>{{queryType=='pay' ? '按付款查进货情况':'按收款查销售情况'}}</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="客户">
          <nt-dropdown-grid  configKey="supplier" v-model="queryModel.id"
          placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%" :data="datasource" v-loading="isLoading">
        
        <el-table-column label="单据日期" prop="accountBillTime" width="110">
          <template #default="{row}">
              {{  formatShortDate(row.accountBillTime) }}
          </template>
        </el-table-column>
        <el-table-column label="单据类型" prop="accountBillType" width="100">
          <template #default="{row}">
            {{ this.EnumBillType[row.accountBillType] }}
          </template>
        </el-table-column>
        <el-table-column label="业务类型" prop="accountBizType" width="90">
          <template #default="{row}">
            {{formatBizType(row.accountBizType)}}
          </template>
        </el-table-column>
        <nt-bill-no-column label="单据编号" width="165" idName="accountBillId" billTypeName="accountBillType" billNoName="accountBillNo"></nt-bill-no-column>
        <!-- <el-table-column label="单据编号" prop="accountBillNo" width="140"></el-table-column> -->
        <el-table-column label="往来单位" prop="companyName" width="160"></el-table-column>
        <el-table-column label="单据金额" prop="accountBillTotal" width="90">
        </el-table-column>
        <el-table-column label="已核销金额" prop="accountSettleTotal" width="110">
        </el-table-column>
        <el-table-column label="余额"  width="90">
          <template #default="{row}">
            {{ row.accountBillTotal-row.accountSettleTotal }}
          </template>
        </el-table-column>
        <el-table-column label="核销单据日期" prop="billTime" width="140">
          <template #default="{row}">
              {{  formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <nt-bill-no-column label="核销单据号" width="165" show-bill-type></nt-bill-no-column>
        <el-table-column label="核销金额" prop="accountItemTotal" width="120">
        </el-table-column>
        <el-table-column >
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPaymentCompareWidthTrade } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "PaymentCompareWithTrade",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      // 查询条件
      queryType:'pay', // 默认付款类型
      queryModel: {
        companyId: '',
        startTime: this.moment().subtract(1, 'month').format('YYYY-MM-01'),
        endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  created(){
    this.queryType = this.$route.meta.queryType || 'pay' // 默认查付款单
  },
  methods: {
    formatBizType(bizType) {
      let info = { 'FINANCIAL_TAKE': '应收款', 'FINANCIAL_PAY': '应付款','FINANCIAL_TAKE_PREPAY': '预收款单', 'FINANCIAL_PAY_PREPAY': '预付款单' }
      return info[bizType] || bizType
    },
    onDateChange: function (value, dateString) {
      // console.log(dateString[0],dateString[1]);
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getPaymentCompareWidthTrade(Object.assign(params, {billType:this.queryType=='pay'? 'FINANCIAL_PAY':'FINANCIAL_TAKE'}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    }
  }
}
</script>
