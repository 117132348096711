<template>
  <el-menu class="nt-tool-bar-2"  mode="horizontal" :ellipsis="false" 
  style="
  height:42px;
  --el-menu-hover-text-color:var(--el-color-primary-dark-2);
  --el-menu-base-level-padding:15px;--el-menu-icon-width:10px;"
  :close-on-click-outside="true"
   text-color="#666">
    <el-menu-item index="0">
      <div style="font-weight: bold;font-size:20px;"><slot name="title" >单据标题</slot></div>
    </el-menu-item>
    <div class="flex-auto" ></div>
    <el-menu-item index="1" v-if="!hidden.includes('add')" @click="onActionClick('ADD')"><i class="iconfont icon-chuangjian margin-right-4"></i>新增</el-menu-item>
    <el-menu-item index="2" v-if="!hidden.includes('export')" @click="onActionClick('EXPORT')"><i class="iconfont icon-export margin-right-4"></i>导出</el-menu-item>
    <el-menu-item index="3" v-if="!hidden.includes('verify')" @click="onActionClick('VERIFY')"><i class="iconfont icon-shenhe"></i>审核</el-menu-item>
    <el-menu-item index="4" v-if="!hidden.includes('unverfiy')" @click="onActionClick('UNVERIFY')"><i class="iconfont icon-fanshen"></i>弃审</el-menu-item>
    <!-- <el-menu-item index="5" v-if="!hidden.includes('print')"  @click="onActionClick('PRINT')"><i class="iconfont icon-weibiaoti-- margin-right-4"></i>打印</el-menu-item> -->
    <el-menu-item index="6" v-if="!hidden.includes('close')"  @click="onActionClick('CLOSE')"><i class="iconfont icon-zhongzhihetong margin-right-4"></i>终止</el-menu-item>
    <el-menu-item index="7" v-if="!hidden.includes('activate')" @click="onActionClick('ACTIVATE')">取消终止</el-menu-item>
  </el-menu>
  
</template>
<script >

export default {
  data(){
    return {
      printTemplateList:[]
    }
  },
  props:{
    
    billType:{
      type:String
    },
    hidden:{
      type: Array,
      default: function(){
        return []
      }
    }
  },
  computed:{
    
    firstPrintList:function(){
      let endIndex = 4
      if(this.printTemplateList.length<=endIndex){
        endIndex = this.printTemplateList.length - 1
      }

      return this.printTemplateList.slice(0,endIndex)

    },
    secondPrintList:function(){
      if(this.printTemplateList.length<=5){
        return []
      }
      return this.printTemplateList.slice(4,this.printTemplateList.length)
    },
  },
  methods:{
    onActionClick(cmd){
      this.$emit('command',cmd)
    }
  }
}
</script>
<style lang="less" >
  .nt-tool-bar-2{
    background-color: rgb(240,240,240);
    .el-sub-menu .el-sub-menu__icon-arrow{
      margin-right:-5px;
    }
    .el-menu-item.is-active, .el-sub-menu.is-active .el-sub-menu__title{
      border:0px !important;
    }
  }

</style>
