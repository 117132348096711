<!-- 打印机选择组件 -->
<template>
  <el-select v-model="printerName" v-bind="$attrs" @change="onSelectChange">
    <el-option v-for="item in printerList" :key="item.name" :label="item.name" :value="item.name" />
  </el-select>
</template>
<script>
// import {useWeixinPrintServiceStore} from '@/piana/weixin_printer_service.js'
import {LoginUserStore} from '@/piana/login_user_service'
  export default {
    name: 'NTDateRange',
    data(){
      return {
        printerName: this.modelValue,
        printerList: LoginUserStore().printerList
      }
    },
    props: {
      modelValue:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.printerName = this.modelValue
      }
    },
    methods: {
      onSelectChange(){
        this.$emit('update:modelValue',this.printerName)
      },
    }
    
  }
</script>
