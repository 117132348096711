<!-- 打印预览
  template:{
    pageWidth:111,
    pageHeight:111,
    offsetX:1,
    offsetY:2
  }
  printData:{
    orderInfo
  }
 -->

<template>
  <div>
    <div id="fking" v-if="templateInfo" class="preview-page" :style="{border:'1px solid red',overflow:'hidden',width:templateInfo.pageWidth+'mm',height:templateInfo.pageHeight+'mm'}">
      <!-- 根据模板配置文件加载打印布局 -->
      <div class="preview-item" :style="{ width:element.width +'pt',height: element.height+'pt',left:element.left+'pt',top:element.top+'pt'}" v-for="element in templateInfo.configJson.items">
        <component :is="ctrl[element._t]" :config="element"/>
      </div>
    </div>
    <div class="text-align-center padding" style="text-align: center;padding:8px;">
      <a-button v-print="'#fking'" type="primary">打印</a-button>
    </div>
    
  </div>
</template>

<script >

import { getTemplate } from '@/service/print_order/template'
import {qr_code,barcode,text,v_line,h_line,rectangle,sys_text} from '@/views/designer/control/all'
import {getReflectValue,parseVarExpr} from '@/utils/reflect'
import '@/views/designer/index.less'


export default {
  data () {
    return {
      templateInfo:{configJson:{}},
      ctrl: {barcode,qr_code,text,v_line,h_line,rectangle,sys_text}
    }
  },
  props:{
    printObject:{
      type: Object
    },
    templateId:{
      type: String
    }
  },
  async mounted(){
    let res = await getTemplate({id: this.templateId})
    // 根据变量，解析printObject 数据，获取变量表达式
    let obj = res.data
    obj.configJson = JSON.parse(obj.configJson)
    if(res.code == 200){
      obj.configJson.items.forEach(item=>{
        // 不区分组件，只要存在变量，则直接提取，提取不到，则按原文
        let fieldVar = parseVarExpr(item.v)
        if(fieldVar){ //判断是否为变量格式 =[...]
          item.v = getReflectValue(this.printObject,fieldVar)
        }
      })
    }

    this.templateInfo = obj 
    console.log(this.templateInfo)
  },
  methods:{
  }
}

</script>

<style lang="less">
  .preview-page{
    position: relative;
  }
  .preview-item{
    position: absolute;
    overflow: hidden;
  }
</style>