<template>
  <el-table-column v-bind="$attrs" class-name="no-padding">
    <template #header>
      <div class="display-flex align-left" style="column-gap:2px;">
        <div class="goods-name align-left" style="flex:1;">商品信息</div>
        <!-- <div class="goods-summary" :style="{ width: '90px' }"> 合计</div> -->
      </div>
    </template>
    <template #default="{ row }">
      <el-row>
        <el-col class="goods-name flex-auto">
          <div v-for="(item, index) in (row.orderItemList)" :key="`item_${item.id}`">
            <el-row :gutter="8">
              <el-col style="flex-basis:32px;">
                <el-avatar :size="32" shape="square" :src="item.goodsThumb" />
              </el-col>
              <el-col class="flex-auto">
                <div class="goods-title" style="line-height:16px;height:16px;font-size:14px;">
                  <el-text line-clamp="1">{{ item.goodsName }}</el-text>
                </div>
                <div class="goods-price">{{ item.price }} *{{ item.quantity }}</div>
              </el-col>
            </el-row>
          </div>
<!--           
          <el-popover trigger="click" :width="300" v-for="(item, index) in (row.orderItemList)" :key="`item_${item.id}`"
            placement="left-start"
            popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 8px;">
            <template #reference>
              <el-row :gutter="8">
                <el-col style="flex-basis:32px;">
                  <el-avatar :size="32" shape="square" :src="item.goodsThumb" />
                </el-col>
                <el-col class="flex-auto">
                  <div class="goods-title" style="line-height:16px;height:16px;font-size:14px;">
                    <el-text line-clamp="1">{{ item.goodsName }}</el-text>
                  </div>
                  <div class="goods-price">{{ item.price }} *{{ item.quantity }}</div>
                </el-col>
              </el-row>
            </template>
            <template #default>
              <div>
                <el-image width="100%" :src="item.goodsThumb"></el-image>
                <div>
                  <div style="font-weight: bold;">{{ item.goodsName }}</div>
                  <div>规格 {{ item.attrs }}</div>
                  <div class="display-flex space-between">
                    <span>单价￥{{ item.price }}</span>
                    <span>商品数量{{ item.quantity }}</span>
                  </div>
                  <div>商家编码{{ item.skuId }}</div>
                </div>
              </div>
            </template>
          </el-popover> -->
        </el-col>
      </el-row>


    </template>
  </el-table-column>
</template>
<script >

import { getCategoryList, bindSkuToErpSku } from '@/service/resource/platform_goods'
export default {
  data() {
    return {
    }
  },
  components: {},
  props: {
    // orderItemList:{
    //   type:Array,
    //   required:true
    // }
  },
  methods: {

  }
}
</script>
