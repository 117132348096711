
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>销售出库统计-商品</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="销售时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="名称" prop="goodsName" width="140">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="89">
        </el-table-column>
        <el-table-column label="主数量" prop="outBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <a @click="onViewDetailClick(row)" style="cursor:pointer;font-weight:bold;color:var(--el-color-primary-light-3)">{{row.outBaseQuantity}}</a>
          </template>
        </el-table-column>
        <el-table-column label="退货数量" prop="backBaseQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" width="90" align="right">
          <template #default="scope">
            {{ ((scope.row.backTotal)).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="实际销售数量" prop="quantity" width="120" align="right">
          <template #default="scope">
            {{ scope.row.outBaseQuantity - scope.row.backBaseQuantity }}
          </template>
        </el-table-column>
        <el-table-column label="实际销售金额" prop="total"  width="120" align="right">
          <template #default="scope">
            {{ ((scope.row.outTotal - scope.row.backTotal)).toFixed(2) }}
          </template>
        </el-table-column>
        
        <el-table-column label="毛利" width="120" align="right">
          <template #default="{row}">
            {{ getProfitTotal(row) }}
          </template>
        </el-table-column>
        <!-- 毛利率= 毛利/销售成本 -->
        <el-table-column label="毛利率" width="120" align="right">
          <template #default="{row}">
            {{ getProfitRate(row) }}
          </template>
        </el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%"  class="nt-inner-drawer" :with-header="false"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
    <i class="nt-drawer-close" @click="isDetailVisible = false">关闭</i>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getSaleOutTotalByGoods } from '@/service/report/rpt_sale'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "SaleOutTotalByGoods",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NtDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      },
      
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getSaleOutTotalByGoods(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    getProfitTotal(row){// 计算毛利  TODO 考虑 退货成本： 引单时，保持一致
      // 毛利计算规则：实销金额（销售数量-退货数量) - 实销成本(销售成本-退货成本)
      return (row.outTotal - row.backTotal) - (row.outCostTotal-row.backCostTotal)
    },
    getProfitRate(row){ // 计算毛率
      // 毛利率计算规则： 毛利/ 销售成本（实销成本）
      let rate = 1
      let profitTotal = this.getProfitTotal(row) // 毛利
      if(row.outCostTotal-row.backCostTotal!=0){
        rate = profitTotal/(row.outCostTotal-row.backCostTotal)
      }
      return `${(rate*100).toFixed(2)}%`  

    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'SALE_OUT,SALE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>
