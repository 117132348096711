<!-- 
  往来单位交易流水
 -->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>交易明细</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <div>
        <span>往来单位</span>
        <span>往来余额=总应收-总应付-总收款+总付款</span>
      </div>
      <el-form inline @keyup.native="onSearchQueryClick">
        
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle"  height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="业务日期" prop="billTime"  width="110">
          <template #default="{row}">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName"></el-table-column>
        <el-table-column label="单据类型" prop="billType" width="100">
          <template #default="{row}">
            {{ this.EnumBillType[row.billType] || row.billType }}
          </template>
        </el-table-column>
        <nt-bill-no-column label="单据编码" width="175" ></nt-bill-no-column>
        <!-- <el-table-column label="单据编码" prop="billNo" width="160"></el-table-column> -->
        <el-table-column label="单据总额" prop="billTotal" width="90">
        </el-table-column>
        <el-table-column label="应收增加" prop="takeIncreaseTotal" width="90">
          <template #default="{row}">
            {{ row.payMode == 1 ?  row.unsettleTotal:0 }}
          </template>
        </el-table-column>
        <el-table-column label="应收减少" prop="takeDecreaseTotal" width="90">
          <template #default="{row}">
            {{ row.payMode == -1 ?  row.unsettleTotal:0 }}
          </template>
        </el-table-column>
        <el-table-column label="往来余额" prop="balance"  width="90"></el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getTradeDetailList } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "SupplierPayment",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      tradeBillVisible: false,
      toggleSearchStatus: false,
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        paymentStatus:'0,1'
        // companyId: '',
        // startTime: getNowFormatYear() + '-01-01',
        // endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  props:{
    query:{
      type:Object
    }
  },
  methods: {

    onDateChange: function (value, dateString) {
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getTradeDetailList(Object.assign(params, this.query||{},this.queryModel))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    }
  }
}
</script>