<!-- 
  往来单位 超期应收应付业务--未结算结束
 -->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>超期应收应付业务</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      <el-table border ref="table" size="middle"  height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="业务日期" prop="billTime"  width="110">
          <template #default="{row}">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName"></el-table-column>
        <el-table-column label="单据类型" prop="billType" width="100">
          <template #default="{row}">
            {{ this.EnumBillType[row.billType] || row.billType }}
          </template>
        </el-table-column>
        <nt-bill-no-column label="单据编码" width="175" ></nt-bill-no-column>
        <!-- <el-table-column label="单据编码" prop="billNo" width="160"></el-table-column> -->
        <el-table-column label="单据总额" prop="billTotal" width="90">
        </el-table-column>
        <el-table-column label="付款时间" prop="paymentTime" width="110">
          <template #default="{row}">
            {{ formatShortDate(row.paymentTime) }}
          </template>
        </el-table-column>
        <el-table-column label="已结算" prop="settleTotal" width="90">
        </el-table-column>
        <el-table-column label="未结算" prop="unsettleTotal" width="90">
        </el-table-column>
        <el-table-column label="超期天数" prop="balance"  width="90">
          <template #default="{row}">
            {{formatDays(row.paymentTime)}}
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>

</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import * as dayjs from 'dayjs'
import { getOverduePayment } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "SupplierPayment",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      tradeBillVisible: false,
      toggleSearchStatus: false,
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        paymentStatus:'0,1'
        // companyId: '',
        // startTime: getNowFormatYear() + '-01-01',
        // endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  props:{
    query:{
      type:Object
    }
  },
  methods: {

    onDateChange: function (value, dateString) {
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getOverduePayment(Object.assign(params, this.query||{},this.queryModel))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    formatDays(paymentTime){
      if(!paymentTime){
        return 
      }
      let minDt = dayjs(paymentTime)
      let dt = dayjs(new Date())
      return Math.abs(dt.diff(minDt,'day'))
    },
  }
}
</script>