<template>
  <el-table-column v-bind="$attrs" class-name="chinese-money">
    <template #header>
      <div style="height:100%;display:flex;flex-direction:column;">
        <div class="flex-auto align-center padding-top-4">这是标题</div>
      <nt_chinese_money :input="false" :border="false" style="height:12px;inline-height:12px"></nt_chinese_money>
      </div> 
      
    </template>
    <template #default="{row}">
      <slot :row="row">
        <nt_chinese_money :yaxis="2"  :head="false" :border="false"  :value="row[$attrs.prop]" ></nt_chinese_money>
      </slot>
    </template>
  </el-table-column>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script >

import nt_chinese_money from '@/views/plugin/nt_chinese_money' // 业务单据查看统一入库页

export default {
  data() {
    return {
    }
  },
  components:{nt_chinese_money},
  props: {
    billNoName:{ // 单据号 字段名称
      type:String,
      default:"billNo"
    },
    idName:{ // 单据主键id 名称
      type:String,
      default:"billId"
    },
    billTypeName:{ // 单据类型 名称
      type:String,
      default:"billType"
    },
    viewOnly:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    async onBillNoClick(row) {
      console.log('xxxx', row,this.billTypeName,this.idName)
      this.$refs.refViewer.showBill(row[this.billTypeName],row[this.idName],this.viewOnly)
    },
  }
}
</script>

<style lang="less">
.chinese-money.el-table__cell{
  padding:0px !important;
  .cell{
    // position:absolute;
    top:0px;
    left:0px;
    padding:0px !important;
    line-height: initial;
    height:40px;
    overflow:visible;
  }
}
</style>