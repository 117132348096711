<template>
  <el-container class="nt-height-100 relative">
    <el-tooltip effect="dark" content="添加商品分类" placement="top-start">
      <el-button icon="Plus" @click="onAddClick" class="nt-float-button-add" circle></el-button>
    </el-tooltip>
    <el-main class="padding">
      <el-tree ref="refTree" :props="{ label: 'categoryName', isLeaf: 'isLeaf' }" nodeKey="id" :data="datasource" lazy
        :load="loadNode" class="nt-list-node" :expand-on-click-node="false">
        <template #default="{ node, data }">
          <el-row @click.stop="onCategorySelectedHandler(data)" type="flex" justify="space-between" class="nt-width-100"
            align="middle">
            <span>{{ node.label }}</span>
            <el-space :size="5" class="nt-operation" v-if="!readOnly && data.id > 0">
              <el-tooltip content="添加下级分类" placement="top-start">
                <el-icon size="large" @click.stop="onAddChildClick(data)">
                  <FolderAdd />
                </el-icon>
              </el-tooltip>
              <el-icon size="large" @click.stop="onEditClick(data)">
                <Edit />
              </el-icon>
              <el-popconfirm title="确定删除?" @confirm.stop="onRemoveClick(data)">
                <template #reference>
                  <el-icon size="large">
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </el-space>
          </el-row>
        </template>
      </el-tree>
    </el-main>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="550px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page ref="modalForm" @ok="isDetailVisible = false" :entityId="currentRecord.id"
      :parentCategory="parentCategory" @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script >
import { getGoodsCategoryListByParent } from '@/service/resource/goods_category'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail'
export default {
  mixins: [DataListMixin],
  components: { detailPage },
  data() {
    return {
      parentCategory: null,
      selectedCategoryId: null
    }
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async _loadList() {
    },
    _loadFinished() {
    },
    onCategorySelectedHandler(data) {
      this.selectedCategoryId = data.id
      this.$refs.refTree?.setCurrentNode(data)
      this.$emit("change", data)
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    async loadNode(node, resolve) {
      let parentId = node.data.id || '-1'
      let res = await getGoodsCategoryListByParent({ parentId: parentId })

      if (parentId == '-1') { // 首次加载全部，增加 未分类节点
        let allNode = { categoryName: '全部', id: null, isLeaf: true }
        res.data.splice(0, 0, allNode, { categoryName: '未分类', id: '-2', isLeaf: true })
        // 默认选择全部
        setTimeout(_ => {
          // this.$refs.refTree?.setCurrentNode(allNode)
          this.onCategorySelectedHandler(allNode)
        }, 0)
      }
      resolve(res.data)
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },
    onEditClick(data) {
      this.currentRecord = data
      this.parentCategory = null
      this.isDetailVisible = true
    },
    onAddChildClick(data) {
      this.currentRecord = {}
      this.parentCategory = { id: data.id, categoryName: data.categoryName }
      this.isDetailVisible = true
    },

    async onRemoveClick(node, data) {
      // 删除确认， 判断是否存在子类，或是存在关联的商品资料
      let res = await this.deleteEntity(this.EnumEntityType.GOODS_CATEGORY, { id: data.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }

    }
  }
}
</script>