<template>
  <el-container class="nt-bg-page nt-height-100 nt-print-age">
    <el-header class="padding  no-margin height-auto padding-bottom-8">
      <query @change="onQueryChange" @sort-change="onSortKeyChange" @refresh="onRefreshHandler"></query>
    </el-header>
    <el-main class="no-padding padding-left padding-right relative ">
      <el-table ref="refTable" :data="datasource" scrollbar-always-on class="nt-resource-table nt-print-order-list"
        size="small" height="100%" border :row-class-name="getRowClass" @row-click="onRowClick"
        @selection-change="onSelectionChange" v-loading="isLoading">
        <el-table-column type="selection" width="40" align="center" fixed="left"></el-table-column>
        <el-table-column width="50" align="center">
          <template #header>
            <el-button title="标记选中订单的旗帜" size="small" class="padding-8" @click="onShowOrderFlagClick"
              ref="refbtnOrderFlag">
              <el-icon>
                <Flag />
              </el-icon>
            </el-button>
          </template>
          <template #default="{ row }">
            <OrderTagList :order="row"></OrderTagList>
          </template>
        </el-table-column>
        <el-table-column width="130" align="center" label="订单时间">
          <template #default="{ row }">
            <div style="font-weight: bold;">{{ formatShortDate(row.createTime) }}</div>
            <div v-if="row.payTime">
              <span v-if="isDispatchOverDue(row.payTime)" style="color:var(--el-color-danger);font-weight:bold;">[超]</span> 
              <span v-else>剩</span>
              {{ formatPayTime(row.payTime) }}
            </div>
            <div v-else><el-tag type="danger">未付款?</el-tag></div>

            <div class="align-center">
              <checkableFlag :modelValue="row.orderExtra?.flags"
                @update:modelValue="val => onOrderFlagsChangeHandler(row, val)" />
            </div>
            <span class="left-top iconfont icon-shipinhaoxiaodian1"
              style="color:#FF6146;font-size:25px;opacity:.5;"></span>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" width="170">
          <template #header>
            <span class="margin-right-4">订单编号</span>
            <el-space space="4">
              <el-button title="锁定选中的订单" size="small" @click="onLockHandler" class="padding-8">
                <el-icon>
                  <Lock />
                </el-icon>
              </el-button>
              <el-button title="解锁选中的订单" size="small" @click="onUnlockHandler" class="padding-8">
                <el-icon>
                  <Unlock />
                </el-icon>
              </el-button>
              
            </el-space>

          </template>
          <template #default="{ row }">
            <div class="display-flex column-gap-4 _order_no" style="color:var(--el-color-primary);cursor: pointer;">
              <span @click.stop="onViewDetailClick(row)">{{ row.orderNo }}</span>
              <el-icon :title="`复制订单号:${row.orderNo}`" @click.stop="onCopyClick(row.orderNo)"><CopyDocument /></el-icon>
            </div>
            <div class="display-flex column-gap-4">
              <span>{{ formatShopName(row.shopId) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收货地址" width="240">
          <template #header>
            <div class="display-flex">
              <span>收货地址</span>
              <address-column-setting ></address-column-setting>
            </div>
          </template>
          <template #default="{ row }">
            <div>{{ row.buyerName }}({{ row.buyerTel }})<el-icon title="解密个人信息" @click.stop="onDecodeClick(row)"><View /></el-icon></div>
            <div style="font-size:14px;">{{ row.provinceName }}{{ row.cityName }}{{ row.countyName }}</div>
            <div><el-text line-clamp="2" v-highlight-directive="row.orderExtra?.highlightWords">{{
              row.buyerAddress }}</el-text></div>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" width="300">
          <template #header>
            <div class="display-flex">
              <span>商品信息</span>
              <GoodsColumnSetting>
              </GoodsColumnSetting>
            </div>
          </template>
          <template #default="scope">
            <!-- 根据配置显示商品： 行数， 超出n行隐藏 优先简称-->
            <div :style="{ columnCount: goodsDislaySetting.columns }" @click.stop>
              <goods-info :goods="item"
                v-for="(item, childIndex) in getRowsDispayGoodsListByConfig(scope.row.orderItemList)"
                @click="onViewGoodsClick(item, `refGoods_${scope.$index}_${childIndex}`)"
                v-click-outside="_ => this.$refs.refGoodsDeteail.hide()"
                :ref="`refGoods_${scope.$index}_${childIndex}`"></goods-info>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注/留言" prop="merchantRemark">
          <template #header>
            <div class="display-flex ">
              <span>备注/留言</span>
              <div>
                <span title="平台订单的买家留言" style=" padding:0px 2px;color:#fff;background-color:var(--el-color-success);margin-right:2px;font-size:10px;">买</span>
                <span title="平台订单的卖家备注" style="padding:0px 2px;color:#fff;background-color:var(--el-color-warning);margin-right:2px;font-size:10px;">卖</span>
                <span title="系统内使用，管理用的备注" style="padding:0px 2px;color:#fff;background-color:var(--el-color-primary);margin-right:2px;font-size:10px;">管</span>
              </div>
              <div>
                <el-button size="small" link type="primary" @click="onMgntRemarkHandler">系统备注</el-button> 
                <el-button size="small" link type="primary" @click="onMerchantRemarkHandler">卖家备注</el-button> 
              </div>
            </div>
          </template>
          <template #default="{ row }">
            <div>
              <span style="padding:0px 2px;color:#fff;background-color:var(--el-color-success);margin-right:2px;font-size:10px;">买</span>
              <span v-if="row.buyerRemark">{{ row.buyerRemark }}</span>
              <span v-else style="color:#5c5c5c;opacity: .3;">无</span>
            </div>
            <div >
              <span style="padding:0px 2px;color:#fff;background-color:var(--el-color-warning);margin-right:2px;font-size:10px;">卖</span>
              <span v-if="row.merchantRemark">{{ row.merchantRemark }}</span>
              <span v-else style="color:#5c5c5c;opacity: .3;">无</span>
            </div>
            <div>
              <span style="padding:0px 2px;color:#fff;background-color:var(--el-color-primary);margin-right:2px;font-size:10px;">管</span>
              <span v-if="row.orderExtra.mgmtNotes">{{ row.orderExtra.mgmtNotes }}</span>
              <span v-else style="color:#5c5c5c;opacity: .3;">无</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="快递模版/运单号" width="240">
          <template #header>
            <span>快递模版/运单号</span>
            <el-button link size="small" ref="refBtnTemplate" type="primary" @click="onSetWaybillTemplateHandler"
              v-click-outside="_ => this.$refs.refTemplatePopover.hide()">设置模版</el-button>
            <el-button link size="small" type="primary" @click="onClearWaybillTemplateHandler">清除模版</el-button>
          </template>
          <template #default="scope">
            <checkablePackage @waybillNoClick="onViewPackageDetailClick(pack)"
              v-for="(pack, childIndex) in scope.row.checkedPackageList"
              :checkable="scope.row.checkedPackageList.length > 1" :pack="pack">
              <el-icon @click.stop="onPackageActionMoreClick(pack, `refPackageMore_${scope.$index}_${childIndex}`)"
                v-click-outside="_ => this.$refs.refPackageAction.hide()"
                :ref="`refPackageMore_${scope.$index}_${childIndex}`"
                style="cursor: pointer; font-size: 12px;font-weight: bold;">
                <ArrowDown />
              </el-icon>
            </checkablePackage>
          </template>
        </el-table-column>

        <!-- <el-table-column label="付款方式" prop="paymentMethod" width="90"></el-table-column> -->
        <el-table-column label="操作" width="45" align="center" fixed="right">
          <template #default="scope">
            <el-icon @click.stop="onOrderActionMoreClick(scope.row, `refOrderMore_${scope.$index}`)"
              v-click-outside="_ => this.$refs.refOrderAction.hide()" :ref="`refOrderMore_${scope.$index}`"
              color="var(--el-color-primary)" style="cursor: pointer; font-size: 16px;font-weight: bold;">
              <More />
            </el-icon>
          </template>
        </el-table-column>

      </el-table>
      <div class="move-top" title="滚动至顶部" @click="_ => this.$refs.refTable.setScrollTop(0)"><el-icon>
          <Download />
        </el-icon></div>
    </el-main>
    <el-footer class="padding-8 height-auto" style="background-color: var(--el-color-primary-light-9);">
      <el-row type="flex" justify="space-between">
        <el-space :size="2">
          <span style="margin-right:12px;">已选{{ selectedRows.length }}条</span>
        </el-space>
        <toolbar-bottom @command="onToolbarCommandHandler"></toolbar-bottom>
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background layout="total, sizes, prev, pager, next"
          @current-change="onPageIndexChange" @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>

  </el-container>

  <!-- 合单或拆单原单据 -->
  <el-drawer v-model="isOrderListVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isOrderListVisible = false">
    <order-list-page @cancel="isOrderListVisible = false" :parentId="currentOrder.id"
      v-if="isOrderListVisible"></order-list-page>
  </el-drawer>

  <!-- 订单详情 -->
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail" />
  <!-- 动态包裹弹窗操作入口 -->
  <ProgressDialog ref="refProgressDialog"></ProgressDialog>
  <nt-result-dialog ref="refBatchResult"></nt-result-dialog>
  <NtWaybillChooseDialog ref="refChoosTemplate"></NtWaybillChooseDialog>
  <nt-picking-code-dialog ref="refPickingCode"></nt-picking-code-dialog>
  <LockOrderDialog ref="refLockDialog"></LockOrderDialog>
  <MerchantRemarkDialog ref="refMerchantRemarkDialog" @success="onBatchMerchantRemarkSuccssHandler"></MerchantRemarkDialog>

  <ManagementRemarkDialog ref="refManagementRemarkDialog" @success="onBatchMgmtRemarkSuccssHandler"></ManagementRemarkDialog>
  <OrderActionPopover ref="refOrderAction" @refresh="onRefreshHandler"></OrderActionPopover>
  <PackageActionPopover ref="refPackageAction" @refresh="onRefreshHandler"></PackageActionPopover>
  <GoodsDetailPopover ref="refGoodsDeteail"></GoodsDetailPopover>
  <OrderFlagPopover ref="refOrderFlagPopover" @success="onBatchFlagSuccssHandler"></OrderFlagPopover>
  <TemplatePopover ref="refTemplatePopover">设置模版</TemplatePopover>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import highlightDirective from '@/views/directive/highlight_directive'
import {
  ntDebug, queryList, unlockOrder, mergeOrder,
  cancelMergeOrder, updateFlag,decodeUserInfo,
  updateWaybillTemplate
} from '@/service/print_order/order'


import checkablePackage from './checkable_package'
import checkableFlag from './checkable_flag'
import OrderTagList from './order_tag_list'
import OrderDetailPage from './detail/order_detail'
import GoodsColumnSetting from './setting/goods_column_setting'
import AddressColumnSetting from './setting/address_column_setting'
import GoodsDetailPopover from './setting/goods_detail_popover'
import PackageActionPopover from './setting/package_action_popover'
import OrderActionPopover from './setting/order_action_popover'
import OrderFlagPopover from './setting/order_flag_popover'
import TemplatePopover from './setting/template_popover'
import GoodsInfo from './goods_info'
import OrderListPage from './list_detail'
import PackageDetailPage from './detail/package_detail' // 包裹详情
import NtPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
import ToolbarBottom from './toolbar_bottom'
import Query from './query'
import ProgressDialog from './progress_dialog'
import NtResultDialog from '@/views/plugin/nt_batch_result_dialog' //批量操作结果弹窗
import NtWaybillChooseDialog from './waybill_template_choose_dialog' // 模版选择弹窗
import NtPickingCodeDialog from './picking_code_print_dialog' // 模版选择弹窗

import LockOrderDialog from './dialog/lock_order_dialog' // 锁订单弹窗
import MerchantRemarkDialog from './dialog/merchant_remark_dialog' // 商家备注
import ManagementRemarkDialog from './dialog/management_remark_dialog' // 管理备注
import { ClickOutside } from 'element-plus'
import { LoginUserStore } from '@/piana/login_user_service'
import * as dayjs from 'dayjs'
import './index.css'
export default {
  name: "print-order-list",
  mixins: [DataListMixin],
  components: {
    Query, ProgressDialog, ToolbarBottom, OrderTagList, GoodsInfo,
    GoodsColumnSetting, AddressColumnSetting,
    PackageActionPopover, OrderActionPopover, TemplatePopover,
    OrderDetailPage, NtPlatformShopTree, OrderFlagPopover,
    checkablePackage, checkableFlag, OrderListPage, PackageDetailPage,
    NtWaybillChooseDialog, NtPickingCodeDialog, LockOrderDialog,
    MerchantRemarkDialog, ManagementRemarkDialog, GoodsDetailPopover
  },
  directives: { ClickOutside, highlightDirective },
  data() {
    return {
      isOrderListVisible: false,
      selecteWaybillTemplate: null, // 当前选中的面单模版
      sortKey: 'PAYMENT_TIME_ASC', // 默认排序方式
      printConfigList: [],
      currentOrder: null,
    }
  },
  computed: {
    goodsDislaySetting: function () {
      let matchItem = LoginUserStore().getConfig('GOODS_DISPALY') // this.printConfigList.find(item=>item.configKey == 'GOODS_DISPALY')
      
      return JSON.parse(matchItem?.configValue || null) || { columns: 1, maxRowLimit:2 }
    },
    abnormalAddressSetting: function () {
      let matchItem = LoginUserStore().getConfig('ABNORMAL_ADDRESS') //this.printConfigList.find(item=>item.configKey == 'ABNORMAL_ADDRESS')

      return JSON.parse(matchItem?.configValue || null) || { isOpen: 0 }
    },
    rejectAreaSetting: function () {
      let matchItem = LoginUserStore().getConfig('REJECT_AREA') //this.printConfigList.find(item=>item.configKey == 'REJECT_AREA')
      return JSON.parse(matchItem?.configValue || null) || { isOpen: 0 }
    }
  },
  created() {
    this.pageInfo.pageSize = 50 // 
  },
  methods: {
    async _initialize() {

    },
    onCopyClick(text){
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success('已复制')
      })
    },
    getRowClass({ row }) {
      return this.selectedRows.includes(row) ? 'highlight' : ''
    },
    onRowClick(row) {
      this.$refs.refTable.toggleRowSelection(row)
    },
    getRowsDispayGoodsListByConfig(orderItemList) { // 根据商品显示配置，每行显示个数，超过两行则隐藏
      if (this.goodsDislaySetting.maxRowLimit) {
        return orderItemList.slice(0, this.goodsDislaySetting.columns * 2)
      }
      return orderItemList
    },
    onUpdateGoodsDisplaySetting(config) {
      let matchItem = this.printConfigList.find(item => item.configKey == 'GOODS_DISPALY')
      if (!matchItem) {
        return
      }
      matchItem.configValue = JSON.stringify(config)
    },
    onOrderActionMoreClick(row, ref) { // 点击操作列表时，弹出公共操作菜单
      this.$refs.refOrderAction.show(this.$refs[ref], row)
    },
    onPackageActionMoreClick(pack, ref) { // 点击操作列表时，弹出公共操作菜单
      this.$refs.refPackageAction.show(this.$refs[ref][0], pack)
    },
    onViewGoodsClick(orderItem, ref) {
      this.$refs.refGoodsDeteail.show(this.$refs[ref][0], orderItem)
    },
    onQueryChange(searchConditionList) {
      // 延迟一个触发周期，避免初始化时，两次触发
      setTimeout(_=>{
        this._dynamicQuery = {dynamic:searchConditionList}
        this.loadList() //{ dynamic: searchConditionList }
      },0)
      
    },
    onSortKeyChange(sortKey) { // 切换排序方式
      this.sortKey = sortKey
      this.loadList()
    },
    onRefreshHandler() { //订单同步完成事件
      this.loadList()
    },
    formatShortDate(dt) {
      return dayjs(dt).format('YYYY-MM-DD')
    },
    isDispatchOverDue(dt){
      // 判断是否超时，已超时  n天n小时n分钟  距离发货超时还有  n天n小时n分钟
      let dtStart = new Date(dt)
      let dtNow = new Date()
      let diff = (dtNow.getTime() - (dtStart.getTime() + (1000 * 60 * 60 * 48))) / 1000 //相差秒数
      return diff>0
    },
    formatPayTime(dt) {
      // 判断是否超时，已超时  n天n小时n分钟  距离发货超时还有  n天n小时n分钟
      let dtStart = new Date(dt)
      let dtNow = new Date()
      let diff = (dtNow.getTime() - (dtStart.getTime() + (1000 * 60 * 60 * 48))) / 1000 //相差秒数
      const diffDays = Math.floor(Math.abs(diff / (60 * 60 * 24)))
      const diffHours = Math.floor(Math.abs((diff % (60 * 60 * 24)) / (60 * 60)))
      const diffMinutes = Math.floor(Math.abs((diff % (60 * 60)) / 60))
      return `${diffDays > 0 ? (diffDays + '天') : ''} ${diffHours > 0 ? (diffHours + '小时') : ''}`
    },
    formatShopName(shopId) {
      let matchItem = LoginUserStore().shopList.find(item => item.shopId == shopId)
      return matchItem?.shopName || shopId
    },
    async _loadList(params) {
      if (!this._dynamicQuery) {
        return // 首次加载页面不触发查询，根据查询面部，初始化完触发
      }
      
      let res = await queryList(Object.assign(params, this._dynamicQuery, { isMerged: false, orderBy: this.sortKey }));
      if (res.code === 200) {
        // 根据订单已存在的包裹， 或部分未生成包裹，未生成部分单独包裹
        res.data.list.forEach(order => {
          let packInfos = {}
          order.orderPackageList.forEach(el => { // 已生成的包裹数据

            if (!packInfos[el.packageNum]) {

              packInfos[el.packageNum] = { orderNo: order.orderNo, orderId: order.id, packageNum: el.packageNum, quantity: el.quantity, templateName: el.waybillTemplateName, checked: true }
              let waybill = order.waybillList.find(elWaybill => elWaybill.packageNum == el.packageNum && elWaybill.waybillStatus != 'CANCELED')
              if (waybill) { // 存在物流信息-非取消
                packInfos[el.packageNum].waybillNo = waybill.waybillNo
                packInfos[el.packageNum].waybillId = waybill.id
                packInfos[el.packageNum].isDispatch = waybill.isDispatch
                packInfos[el.packageNum].isPreDispatch = waybill.isPreDispatch // 是否预发货
                packInfos[el.packageNum].printCnt = waybill.printCnt
              }
            } else {
              packInfos[el.packageNum].quantity += el.quantity
            }
          })
          order.checkedPackageList = Object.values(packInfos) // 创建页面临时对象，存放默认已勾选的包裹, 包裹列表，未生成包裹部分归为虚拟的包裹

        })
      }
      return res
    },
    onToolbarCommandHandler(cmd, val) {
      switch (cmd) {
        case 'WAYBILL_TEMPLATE': // 面单模版， 默认模版
          this.selecteWaybillTemplate = val
          break;
        case 'MERAGE':
          this.onMergeHandler()
          break;
        case 'CREATE_PRINT_WAYBILL': // 生成并打印快递单
          this.onCreateWaybillHandler(true)
          break;
        case 'CREATE_WAYBILL': // 仅生成面单
          this.onCreateWaybillHandler(false)
          break;
        case 'DELIVERY_SEND':
          this.onDispatchHandler()
          break;
        case 'PRINT_DISPATCH':
          // 打印发货单，与平台无关，选择发货单模版，与erp系统打印的流程一致
          this.onPrintDispatchHandler()
          break;
        case 'CANCEL_MERAGE':
          this.onCancelMergeHandler()
          break;
        case 'PRE_DISPATCH': // 预发货
          this.onPreDispatchHandler(false)
          break;
        case 'CANCEL_PRE_DISPATCH': // 取消预发货
          this.onPreDispatchHandler(true)
          break;
        case 'PRINT_PICKING': // 打印拿货标签
          this.onPickingCodePrintHandler()
          break;
        case 'DEBUG': // 打印拿货标签
          this.onDebugHandler()
          break;
      }
    },
    async onMergeHandler() {
      if (this.selectedRows.length < 2) {
        this.$message.error("请选择操作至少2张单据")
        return;
      }

      let res = await mergeOrder({
        list: this.selectedRows.map(item => item.id),
      })

      if (res.code == 200) {
        this.$message.success("操作成功")
        this.loadList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async onCancelMergeHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }

      let res = await cancelMergeOrder({
        orderId: this.selectedRows[0].id,
      })

      if (res.code == 200) {
        this.$message.success("操作成功")
        this.loadList()
      } else {
        this.$message.error(res.msg)
      }
    },

    onLockHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      let orderIds = this.selectedRows.map(item => item.id)
      this.$refs.refLockDialog.show(orderIds)
    },
    async onUnlockHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      this.isSubmitting = true
      let res = await unlockOrder({
        list: this.selectedRows.map(item => item.id),
      })
      this.isSubmitting = false
      if (res.code === 200) {
        this.$message.success("操作成功")
        this.loadList()
      } else {
        this.$message.error(res.msg)
      }
    },
    onShowOrderFlagClick() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      let orderIds = this.selectedRows.map(item => item.id)
      this.$refs.refOrderFlagPopover.show(this.$refs.refbtnOrderFlag, orderIds)
    },
    onMgntRemarkHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      let orderIds = this.selectedRows.map(item => item.id)
      this.$refs.refManagementRemarkDialog.show(orderIds)
    },
    onMerchantRemarkHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      let orderIds = this.selectedRows.map(item => item.id)
      this.$refs.refMerchantRemarkDialog.show(orderIds)
    },
    
    async onClearWaybillTemplateHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      let isOk = await this.$confirm("是否确定清除选择包裹的的面单模板信息?").catch(_ => false)
      if (!isOk) {
        return
      }
      let pList = this.getAllSelectedPackageList()
      let res = await updateWaybillTemplate({ list: pList })
      if (res.code == 200) {
        this.$refs.refBatchResult.showDialog('操作结果', res.data)
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    onSetWaybillTemplateHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      let pList = this.getAllSelectedPackageList()
      this.$refs.refTemplatePopover.show(this.$refs.refBtnTemplate, pList)
    },

    onPickingCodePrintHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      let pList = this.getAllSelectedPackageList()

      this.$refs.refPickingCode.showDialog(pList, async template => { })
    },
    async onDebugHandler() {
      let res = await ntDebug()
      if (res.code == 200) {
        this.$message.success("操作成功")
      } else {
        this.$message.error(res.msg)
      }
    },
    async onBackCreateHandler() {
      let res = await createOrderBack()
      if (res.code == 200) {
        this.$message.success("操作成功")
        this.loadList()
      } else {
        this.$message.error(res.msg)
      }
    },
    onViewDetailClick(record) {
      this.$refs.refOrderDetail.show(record.id)
    },

    onViewPackageDetailClick(pack) {
      this.$refs.refPackageDetail.show(pack.orderId, pack.waybillNo)
    },

    onViewOrderListClick(record) {
      this.currentOrder = record
      this.isOrderListVisible = true
    },
    onDispatchHandler() { // 发货
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      let pList = this.getAllSelectedPackageList()
      this.$refs.refProgressDialog.showDialog('dispatch', { title: '', packageList: pList })
    },

    /**
     * 面单打印点击弹窗
     */
    onCreateWaybillHandler(print = false /*生成面单后是否打印 */) {
      if (!this.selecteWaybillTemplate) {
        this.$message.error("请选择面单模版")
        return;
      }

      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }

      let pList = this.getAllSelectedPackageList()

      this.$refs.refProgressDialog.showDialog('createWaybill', { title: print ? '取号并打印面单':'面单取号', processType: print ? 'CREATE_PRINT' : 'CREATE', needPrintData: true, template: this.selecteWaybillTemplate, packageList: pList })

    },
    onPreDispatchHandler(cancel = false) {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }

      let pList = this.getAllSelectedPackageList()
      this.$refs.refProgressDialog.showDialog('preDispatch', { title: '预发货', processType: cancel ? 'CANCEL' : 'UPDATE', packageList: pList })
    },

    getAllSelectedPackageList() {
      let pList = []
      this.selectedRows.forEach(order => {
        // 清除之前操作的状态
        order.checkedPackageList.forEach(item => {
          delete item.processStatus
          delete item.errorMsg
        })
        pList.push(...order.checkedPackageList.filter(item => item.checked))
      })
      return pList
    },
    onPrintDispatchHandler() {
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }

      let pList = this.getAllSelectedPackageList()
      this.$refs.refProgressDialog.showDialog('printDispatch', {title:'打印发货单', packageList: pList })

    },

    async onOrderFlagsChangeHandler(record, flags) {
      let res = await updateFlag({ list: [record.id], flags: flags })
      if (res.code == 200) {
        this.$message.success("更新成功")
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    onBatchFlagSuccssHandler(ids,flags){
      this.selectedRows.forEach(item=>item.orderExtra.flags = flags)
    },
    onBatchMgmtRemarkSuccssHandler(ids,remark){
      this.selectedRows.forEach(item=>item.orderExtra.mgmtNotes = remark)
    },
    onBatchMerchantRemarkSuccssHandler(batchResult,remark){
      // 成功部分直接更新ui，失败部分弹窗提示===》缓
      batchResult.resultList.forEach(item=>{
        let matchItem = this.selectedRows.find(el=>el.orderNo == item.businessNo) // 返回结果按平台订单号
        if(matchItem){
          matchItem.merchantRemark = remark
        }
      })
    },
    async onDecodeClick(row){
      let res  = await decodeUserInfo({orderId:row.id})
      if(res.code == 200){
        row.buyerName = res.data.userName
        row.buyerTel = res.data.telNumber
        row.provinceName = res.data.provinceName
        row.cityName = res.data.cityName
        row.countyName = res.data.countyName
        row.buyerAddress = res.data.detailInfo
      }
    }
  }
}
</script>