
<!-- 
 操作列： 
  编辑，修改 删除 审核，弃审，删除 ，启用，停用
  支持extra
 -->

<template>
  <el-table-column v-bind="$attrs">
    <template #default="{row}">
      <el-popover placement="right" ref="refPop" :width="100" trigger="click"  :show-arrow="false" popper-style="border-radius:0px;">
        <template #reference>
          <el-icon class="nt-custom-icon" ><Menu /></el-icon>
        </template>
        <el-space direction="vertical" class="nt-width-100 nt-operation-button"  fill style="--el-fill-color-light:var(--el-color-primary-light-9)">
          <el-button  text @click="onCommandClick('EDIT',row)" :disabled="shouldBeDisabled('EDIT',row)" v-if="!hidden.includes('EDIT')">编辑</el-button>
          <el-button  text @click="onCommandClick('DELETE',row)"  :disabled="shouldBeDisabled('DELETE',row)"  v-if="!hidden.includes('DELETE')">删除</el-button>
          <el-button  text @click="onCommandClick('VERIFY',row)"  :disabled="shouldBeDisabled('VERIFY',row)"  v-if="!hidden.includes('VERIFY')">审核</el-button>
          <el-button  text @click="onCommandClick('UNVERIFY',row)"  :disabled="shouldBeDisabled('UNVERIFY',row)"  v-if="!hidden.includes('UNVERIFY')">弃审</el-button>
          <el-button  text @click="onCommandClick('ENABLE',row)"  :disabled="shouldBeDisabled('ENABLE',row)"  v-if="!hidden.includes('ENABLE')">启用</el-button>
          <el-button  text @click="onCommandClick('DISABLE',row)"  :disabled="shouldBeDisabled('DISABLE',row)"  v-if="!hidden.includes('DISABLE')">停用</el-button>
          <el-button  text @click="onCommandClick('DEFAULT',row)"  :disabled="shouldBeDisabled('DEFAULT',row)"  v-if="!hidden.includes('DEFAULT')">设为默认</el-button>
          <slot name="extra"></slot>
        </el-space>
      </el-popover>
    </template>
  </el-table-column>
</template>
<script >

/**
 * 示例参考往来单位资料编辑列
 * 
 */
export default {
  data() {
    return {
    }
  },
  props: {
    hidden:{
      type: Array,
      default:_=>[]
    },
    disableExpr:{
      Type:Object,
      default:{}
    },
    confirm:{ // 指令数组，在范围内，则需弹窗确认，并附加确认消息， {EDIT:'请确认是否编辑'}  消息空时，默认提示
      type:Object,
      default:{}
    }
  },
  methods: {
    async onCommandClick(cmd,row){
      setTimeout(_=>this.$refs.refPop.hide(),0)
      let isOk = true
      if(this.confirm[cmd]!=undefined){
        let msg = this.confirm[cmd] || '您确定要执行此操作吗？'
        isOk = await this.$confirm(msg,{type:'warning'}).catch(_=>false)
      }
      isOk && this.$emit('command',cmd,row)
    },
    shouldBeDisabled(cmd,row){ // 通过表达式控制，是否需要置灰操作
      /* 公共默认统一规则，如有不同，通过传入参数覆盖默认方式 */
      const defaultSetting = {DEFAULT:'isDefault=1',ENABLE:'enabled=1',DISABLE:'enabled=0',EDIT:'status=1',DELETE:'status=1',VERIFY:'status=1',UNVERIFY:'status=0'}

      const disableInfo = Object.assign({},defaultSetting,this.disableExpr)
      
      let expr = disableInfo[cmd] || ''
      let ar = expr.split('=')
      if(ar.length != 2){
        return false
      }
      if(['ENABLE','DISABLE'].includes(cmd)){ // 启用状态目前存在两个命名 isEnabled  enabled  未统一前做兼容
        return row[ar[0]] == ar[1] || row.isEnabled == ar[1]
      } else {
        return row[ar[0]] == ar[1]
      }
      
    }
    /* 通过数组表达式 :disableExpr={ENABLE:'enable=1',DISABLE:'enable=0'} */
  }
}
</script>
<style lang="less" scoped>
  .nt-custom-icon{
    cursor: pointer;
    &:hover{
      color:var(--el-color-primary);
    }
  }
</style>