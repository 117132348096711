<template>
  <el-space class="padding">
    <el-checkbox disabled v-if="(operationFlag & 1)==1" :modelValue="hasRight_1" @change="val=>onFlagChangeHandler(val,1)">功能</el-checkbox>
    <el-checkbox v-if="(operationFlag & 2)==2" :modelValue="hasRight_2" @change="val=>onFlagChangeHandler(val,2)">编辑</el-checkbox>
    <el-checkbox v-if="(operationFlag & 4)==4" :modelValue="hasRight_4" @change="val=>onFlagChangeHandler(val,4)">删除</el-checkbox>
    <el-checkbox v-if="(operationFlag & 8)==8" :modelValue="hasRight_8" @change="val=>onFlagChangeHandler(val,8)">审核</el-checkbox>
    <el-checkbox v-if="(operationFlag & 16)==16" :modelValue="hasRight_16" @change="val=>onFlagChangeHandler(val,16)">反审</el-checkbox>
    <el-checkbox v-if="(operationFlag & 32)==32" :modelValue="hasRight_32" @change="val=>onFlagChangeHandler(val,32)">导出</el-checkbox>
  </el-space>
  
</template>
<script >
export default {
  data(){
    return {
      rightFlag: this.modelValue || 0
    }
  },
  emit: ['update:modelValue'],
  props:{
    modelValue:null,
    operationFlag:{ // 模块权限按钮列表
      type:Number,
      required:true
    }
  },
  computed:{
    hasRight_1:function(){
      return (this.rightFlag & 1) ==1
    },
    hasRight_2:function(){
      return (this.rightFlag & 2) ==2
    },
    hasRight_4:function(){
      return (this.rightFlag & 4) ==4
    },
    hasRight_8:function(){
      return (this.rightFlag & 8) == 8
    },
    hasRight_16:function(){
      return (this.rightFlag & 16) ==16
    },
    hasRight_32:function(){
      return (this.rightFlag & 32) ==32
    }
  },
  methods:{
    onFlagChangeHandler(isChecked,flag){
      console.log(isChecked,flag)
      if(isChecked){
        this.rightFlag = this.rightFlag | flag // 增加权限
      } else {
        this.rightFlag = this.rightFlag&~flag // 移除权限
      }
      this.$emit('update:modelValue',this.rightFlag)
    },
  }
  }
</script>

