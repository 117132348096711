<template>
  <el-container class="height-auto padding nt-height-100">
    <el-header class="no-margin height-auto display-flex space-between no-padding">
      <el-form inline @keyup.enter.native="onSearchClick" class="no-margin-bottom">
        <nt-form-item label="名称" >
          <el-input placeholder="请输入名称查询" v-model="queryParam.companyName"></el-input>
        </nt-form-item>
        <nt-form-item label="手机号码" >
          <el-input placeholder="请输入手机号码查询" v-model="queryParam.telephone"></el-input>
        </nt-form-item>
        <nt-form-item label="联系电话" >
          <el-input placeholder="请输入联系电话查询" v-model="queryParam.phonenum"></el-input>
        </nt-form-item>
        <el-button type="primary" @click="onSearchClick">查询</el-button>
      </el-form>
      <div>
        <el-dropdown split-button type="primary"  @command="handleCommand" @click="onAddClick(1)" v-if="!companyType">
          新增客户
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="2">新增供应商</el-dropdown-item>
              <el-dropdown-item :command="3">新增客户&供应商</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button v-else-if="companyType==1" style="margin-left: 8px" @click="onAddClick(1)">新增客户</el-button>
        <el-button v-else-if="companyType==2" style="margin-left: 8px" @click="onAddClick(2)">新增供应商</el-button>
        <el-button v-else-if="companyType==3" style="margin-left: 8px" @click="onAddClick(3)">新增客户&供应商</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border width="100%" ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="45" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="单位编码" prop="companyCode" width="100"></el-table-column>
        <el-table-column label="单位名称" prop="companyName" width="260">
          <template #default="{row}">
            {{ row.companyName }}
            <el-tag class="absolute right-top" type="info" size="small" v-if="!companyType"> {{ formatCompanyType(row.companyType) }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="分管人员" width="120" prop="handlerName"></el-table-column>
        <el-table-column label="分管部门" width="120" prop="handlerDeptName"></el-table-column>
        <el-table-column label="客户等级" width="110" prop="companyLevelName"></el-table-column>
        <el-table-column label="往来分类" width="120" prop="categoryName"></el-table-column>
        <el-table-column label="主联系人" width="120" prop="email"></el-table-column>
        <el-table-column label="收付款条件" width="180" prop="paymentConditionName"></el-table-column>
        <el-table-column label="应付总额" width="100" prop="debitPayTotal">
          <template #default="{row}">
            {{row.debitPayTotal}}
          </template>
        </el-table-column>
        <el-table-column label="预付余额" width="100" prop="prepayBalance">
          <template #default="{row}">
            {{row.prepayBalance}}
          </template>
        </el-table-column>
        <el-table-column label="税率(%)" width="100" prop="taxRate"></el-table-column>
        <el-table-column label="状态" width="70" prop="enabled">
          <template #default="scope">
            <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
            <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?'}"
          :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top-8 height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible" destroyOnClose width="950px"  class="nt-resource-dialog" title="往来单位详情" :close-on-click-modal="false" append-to-body
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :companyType="cType" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "CUSTOMER",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  inject:['categoryId'],
  data() {
    return {
      // 查询条件
      queryParam: {
        companyName: '',
        telephone: '',
        phonenum: ''
      },
      cType:null, // 新增时传参指定 往来单位性质
    }
  },
  props: {
    companyType:{
      type: Number
    },
  },
  watch:{
    categoryId:function(){
      // console.log('change inner', this.categoryId)
      this.loadList()
    }
  },
  methods: {
    async _loadList(params) {
      // 根据分类id 过滤数据 TODO
      let postData = Object.assign({ companyType: this.companyType}, params)
      if(this.categoryId){
        postData.cascadeCategoryId = this.categoryId // 迭入多层级查询数据
      }
      return await this.getPageEntityList(this.EnumEntityType.BIZ_COMPANY, postData);
    },
    onSearchClick() {
      this.resetPage = true
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    handleCommand(cType){
      this.onAddClick(cType)
    },
    // async onOperationAction(cmd,row){
    //   switch (cmd) {
    //     case 'EDIT': // 编辑
    //       this.onRowEditClick(row)
    //       break;
    //     case 'DELETE': // 删除
    //       this.onRowDeleteClick(row)
    //       break;
    //     case 'ENABLE': // 启用
    //       this.onRowEnabledClick(row,1)
    //       break;
    //     case 'DISABLE': // 停用
    //       this.onRowEnabledClick(row,0)
    //       break;
    //     default:
    //       break;
    //   }
    // },
    onAddClick(cType) {
      this.cType = cType
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.BIZ_COMPANY, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.BIZ_COMPANY, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    formatCompanyType(cType){
      let info={
        1:'客户',
        2:'供应商',
        3:'客户&供应商'
      }
      return info[cType] || cType
    }
  }
}
</script>
