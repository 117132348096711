<template>
  <el-popover 
    ref="refFking"
    :virtual-ref="targetElement"
    virtual-triggering
    :visible ="isVisible"
    placement="right"
    :width="260">
    <div v-click-outside="onClickOutside">
      <div :class="`flag-item  display-flex margin-top-8 ${flags.includes(1)? 'nt-bottom-right-check':''}`" @click="onFlagClick(1)">
        <div class="display-flex column-gap-4" >
          <span>旗帜</span>
          <el-icon style="color:#FF4D4A;" :size="18" ><Flag/></el-icon>
        </div>
        <div >{{ config['1'] }}</div>
      </div>
      <div :class="`flag-item  display-flex margin-top-8 ${flags.includes(2)? 'nt-bottom-right-check':''}`" @click="onFlagClick(2)">
        <div class="display-flex column-gap-4" >
          <span>旗帜</span>
          <el-icon style="color:#217EFF;" :size="18" ><Flag/></el-icon>
        </div>
        <div >{{ config['2'] }}</div>
      </div>
      <div :class="`flag-item  display-flex margin-top-8 ${flags.includes(4)? 'nt-bottom-right-check':''}`" @click="onFlagClick(4)">
        <div class="display-flex column-gap-4" >
          <span>旗帜</span>
          <el-icon style="color:#FFC619;" :size="18" ><Flag/></el-icon>
        </div>
        <div >{{ config['4'] }}</div>
      </div>
      <div :class="`flag-item  display-flex margin-top-8 ${flags.includes(8)? 'nt-bottom-right-check':''}`" @click="onFlagClick(8)">
        <div class="display-flex column-gap-4" >
          <span>旗帜</span>
          <el-icon style="color:#17BE6B;" :size="18" ><Flag/></el-icon>
        </div>
        
        <div >{{ config['8'] }}</div>
      </div>
      <!-- <checkableFlag v-model="flags" /> -->
      <div style="margin-top:12px;text-align: center;">
        <el-button @click="onConfirmClick" type="primary" size="small"> 确定</el-button>
      </div>
    </div>
  </el-popover>
</template>
<script >
import { updateFlag } from '@/service/print_order/order'
import checkableFlag from '../checkable_flag'
import { ClickOutside } from 'element-plus'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      isVisible: false,
      targetElement:null,
      flags: [],
      config:{}
    }
  },
  directives: { ClickOutside },
  components: { checkableFlag },
  emits: ['success'],
  created(){
    let configItem =  LoginUserStore().getConfig('FLAG_REMARK')
    if(configItem){
      this.config = JSON.parse(configItem.configValue||null) || {}
    }
  },
  methods: {
    show(refElement,orderIds){
      this.targetElement = refElement
      this.flags=[]
      this.orderIds = orderIds
      this.isVisible = true
    },
    onClickOutside(){
      this.hide()
    },
    onFlagClick(flag){
      let matchIndex = this.flags.indexOf(flag)
      if(matchIndex!=-1){
        this.flags.splice(matchIndex,1)
      } else{
        this.flags.push(flag)
      }
    },
    hide() {
      this.isVisible = false
    },
    preventClose(event){
      event.stopPropagation()
    },
    async onConfirmClick() {
      let flagResult = this.flags.reduce((cur,next)=> cur|next,0)
      let res = await updateFlag({ list: this.orderIds, flags: flagResult })
      if (res.code == 200) {
        // 成功保存数据后，清空
        this.hide()
        this.$emit('success', this.orderIds, flagResult)
        this.$message.success("更新成功")
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .flag-item{
    padding:4px 8px;
    cursor: pointer;
    border-radius: 5px;
    column-gap: 2px;
    border:2px solid transparent;
    &:hover{
      font-weight:bold;
      background-color: var(--el-color-primary-light-7);
    }
  }
</style>