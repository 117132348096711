
import { axios as service } from '@/utils/request'
/*
  订单管理api，示例代码
    查询列表
    查询详情
    更新订单
 */
export const queryList = (data) => {
  return service({
    url: '/order/list',
    method: 'post',
    data
  })
}
export const queryListByParentId = (data) => {
  return service({
    url: '/order/queryListByParentId',
    method: 'post',
    data
  })
}

export const saveOrder = (data) => {
  return service({
    url: '/order/save',
    method: 'post',
    data
  })
}
export const createOrder = () => {
  let data = {}
  return service({
    url: '/order/save',
    method: 'post',
    data
  })
}

export const getOrder = (data) => {
  return service({
    url: '/order/get?id='+ data,
    method: 'get',
    data
  })
}
export const deleteOrder = (data) => {
  return service({
    url: '/order/delete',
    method: 'post',
    data
  })
}
export const splitOrder = (data) => {
  return service({
    url: '/order/split',
    method: 'post',
    data
  })
}

export const cancelMergeOrder = (data) => {
  return service({
    url: '/order/cancelMergeOrder',
    method: 'post',
    data
  })
}

export const mergeOrder = (data) => {
  return service({
    url: '/order/merge',
    method: 'post',
    data
  })
}

export const createPackage = (data) => {
  return service({
    url: '/order/createPackage',
    method: 'post',
    data
  })
}


export const deletePackage = (data) => {
  return service({
    url: '/order/deletePackage',
    method: 'post',
    data
  })
}



/**
 * 包裹详情-按订单
 */
export const packageList = (data) => {
  return service({
    url: '/order/packageList',
    method: 'post',
    data
  })
}

export const mockCreateOrder = () => {
  return service({
    url: '/order/create',
    method: 'post'
  })
}


export const ntDebug = (data={}) => {
  return service({
    url: '/order/debug',
    method: 'post',
    data
  })
}


export const addFlag = (data) => {
  return service({
    url: '/order/appendFlag',
    method: 'post',
    data
  })
}


export const removeFlag = (data) => {
  return service({
    url: '/order/removeFlag',
    method: 'post',
    data
  })
}

export const updateFlag = (data) => {
  return service({
    url: '/order/updateFlag',
    method: 'post',
    data
  })
}



export const lockOrder = (data) => {
  return service({
    url: '/order/lock',
    method: 'post',
    data
  })
}


export const unlockOrder = (data) => {
  return service({
    url: '/order/unlock',
    method: 'post',
    data
  })
}


/**
 * 更新订单管理备注 orderExtra
 * @param {*} data 
 * @returns 
 */
export const updateNotes = (data) => {
  return service({
    url: '/order/updateNotes',
    method: 'post',
    data
  })
}

/**
 * 更新卖家备注，通过接口同步
 * @param {*} data 
 * @returns 
 */
export const updateMerchantNotes = (data) => {
  return service({
    url: '/order/updateMerchantNotes',
    method: 'post',
    data
  })
}

/**
 * 获取用户的脱敏信息
 * @param {*} data 
 * @returns 
 */
export const decodeUserInfo = (data) => {
  return service({
    url: '/order/decodeUserInfo',
    method: 'post',
    data
  })
}


// 按包裹获取订单信息
export const getListByPackageNum = (data) => {
  return service({
    url: '/order/listByPackageNum',
    method: 'post',
    data
  })
}

// 批量更新包裹面单模版
export const updateWaybillTemplate = (data) => {
  return service({
    url: '/order/updateWaybillTemplate',
    method: 'post',
    data
  })
}

// 提交订单同步请求，获取提交后的任务id
export const submitOrderSyncTask = (data) => {
  return service({
    url: '/order/syncOrder',
    method: 'post',
    data
  })
}

// 提交订单同步请求，获取提交后的任务id
export const queryTaskResult = (data) => {
  return service({
    url: '/order/queryTaskResult',
    method: 'post',
    data
  })
}


// 清除订单异常标记
export const clearAbnormal = (data) => {
  return service({
    url: '/order/clearAbnormal',
    method: 'post',
    data
  })
}

