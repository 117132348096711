<template>
  <el-container class="no-padding" style="height:100%;background:#fff;">
    <el-main class="no-padding">
      <el-table height="100%" border size="middle" default-expand-all  rowKey="id" :data="datasource" v-loading="isLoading">
        <el-table-column type="expand">
        <template #default="{row}">
          <el-table :data="row.tradeItemList" style="margin-left:80px;">
            <el-table-column label="商品" prop="goodsName" />
            <el-table-column label="SKU" prop="sku" />
            <el-table-column label="单位" prop="unitName" />
            <el-table-column label="数量" prop="quantity" />
            <el-table-column label="价格" prop="price" />
            <el-table-column label="备注" prop="remark" />
          </el-table>
        </template>
        </el-table-column>
        <el-table-column label="入库单号" prop="billNo" width="160"></el-table-column>
        <el-table-column label="单据日期" prop="billTime" width="140">
          <template #default="{ row }">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="总额" width="160" prop="billTotal">
          <template #default="{ row }">
            {{ formatFenTotal(row.total) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
// import { getAccountItemList } from '@/service/financial/account_head'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: 'AccountItemList',
  mixins: [DataListMixin],
  components: {

  },
  data() {
    return {
      billHeader: null,
    }
  },
  props: {
    billType: {
      type: String,
      required: true
    },
    billId: {
      type: String,
      required: true
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({},params, { linkType: this.billType, linkId: this.billId }))
    }
  }
}
</script>
