
<!-- 
   自由容器支持两种数据源： 主体 + 明细， 格式需特殊定义
 -->
 <template>
  <el-form ref="formRef" :model="config" label-position="top" size="small" @change="onPropertyChangeHandler">
    <div>自由容器</div>
    <el-form-item prop="rows" label="高度自适应">
      <el-switch
        v-model="config.autoHeight"
        active-text="高度自适应"
      />
      <div style="line-height:14px;">开启后，表体内容将显示所用的明细，页面高度，可能会超出打印机的页面设置，高度自适应适合于热敏纸打印</div>
    </el-form-item>
    <el-form-item prop="columns" label="每行显示个数">
      <el-select v-model="config.columns">
        <el-option :value="index+1" v-for="(_,index) in (new Array(5))" :key="index">{{ index+1 }}</el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="rows" label="每页行数(高度自适应模式将根据实际内容显示)">
      <el-select v-model="config.rows" >
        <el-option :value="index+1" v-for="(_,index) in (new Array(10))" :key="index">{{ index+1 }}</el-option>
      </el-select>
    </el-form-item>
    
    <el-form-item prop="columnGap" label="列间距">
      <el-select v-model="config.columnGap">
        <el-option :value="0" label="0px"></el-option>
        <el-option :value="2" label="2px"></el-option>
        <el-option :value="4" label="4px"></el-option>
        <el-option :value="6" label="6px"></el-option>
        <el-option :value="8" label="8px"></el-option>
        <el-option :value="10" label="10px"></el-option>
        <el-option :value="12" label="12px"></el-option>
        <el-option :value="14" label="14px"></el-option>
        <el-option :value="16" label="16px"></el-option>
        <el-option :value="18" label="18px"></el-option>
        <el-option :value="20" label="20px"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="rowGap" label="行间距">
      <el-select v-model="config.rowGap">
        <el-option :value="0" label="0px"></el-option>
        <el-option :value="2" label="2px"></el-option>
        <el-option :value="4" label="4px"></el-option>
        <el-option :value="6" label="6px"></el-option>
        <el-option :value="8" label="8px"></el-option>
        <el-option :value="10" label="10px"></el-option>
        <el-option :value="12" label="12px"></el-option>
        <el-option :value="14" label="14px"></el-option>
        <el-option :value="16" label="16px"></el-option>
        <el-option :value="18" label="18px"></el-option>
        <el-option :value="20" label="20px"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
// import {usePageDesignerStore} from '../usePageDesignerStore'
export default {
  data () {
    return {
      // carson:"xxx",
      // // :{'carson':'xxxxx'}
      // store: usePageDesignerStore()
    }
  },
  props:{
    config:{}
  },
  methods:{
  }
}
</script>