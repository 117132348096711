<template>
  <el-container class="nt-height-100">
    <!-- 单据工具栏 -->
    <el-header class="no-padding no-margin height-auto">
      <slot name="toolbar"></slot>
    </el-header>
    <el-main class="padding">
      <el-container class="nt-height-100">
        <el-header class="no-margin height-auto no-padding">
          <slot name="header"></slot>
        </el-header>
        <el-main class="no-padding">
          <slot name="body"></slot>
        </el-main>
      </el-container>
    </el-main>
    <slot></slot>
  </el-container>
</template>
<script >

export default {
  data(){
    return {
    }
  },
  components:{

  },
  methods:{

  }
}
</script>
