import { axios as service } from '@/utils/request'


export const getGoodsCategoryListByParent = (data) => {
  return service({
    url: '/goodsCategory/byParentId',
    method: 'post',
    data
  })
}
