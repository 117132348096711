<template>
  <el-container class="nt-height-100 nt-bg-page">
    <el-header class="no-margin padding-8 height-auto border-bottom">
      <div class="display-flex space-between">
        <div style="font-weight:bold;font-size:18px;">{{paperInfo?.pageSetting?.title}}
        </div>
        <div>
          <el-checkbox-button size="small" type="info" v-model="designerConfig.showGridLine">
            <i class="iconfont icon-jingtaiwangge"></i>
          </el-checkbox-button>
          <el-checkbox-button size="small" v-model="designerConfig.showPaddingLine">
            <i class="iconfont icon-fenge2"></i>
          </el-checkbox-button>
          <el-checkbox-button size="small" v-model="designerConfig.showSplitter">
            <i class="iconfont icon-zongfengexian"></i>
          </el-checkbox-button>
        </div>
        <div>
          <el-button @click="onSaveClick" :disabled="isLoading" ><i class="iconfont icon-baocun1"></i></el-button>
          <el-button @click="onPreviewClick" :disabled="isLoading" ><i class="iconfont icon-yulan"></i></el-button>
        </div>
      </div>
    </el-header>
    <el-main class="no-padding" >
      <el-container class="no-padding nt-height-100">
        <el-aside width="100px" class="padding-8 nt-bg-white">
          <control-list-panel :controlList="list"></control-list-panel>
        </el-aside>
        <el-main class="no-padding" style="position:relative;overflow:hidden;" 
          v-loading="isLoading"
          @click="onPageClick"
          element-loading-text="正在加载模版信息..."
          element-loading-background="rgba(122, 122, 122, 0.8)" >
          <designer-body v-if="!isLoading && paperInfo" 
            :class="{'design-grid-line': designerConfig.showGridLine,'hide-body-splitter':showBodyOnly,
          'print-padding-line': designerConfig.showPaddingLine,'designer-splitter':designerConfig.showSplitter}"
           :width="paperInfo.pageSetting.pageWidth" :height="paperInfo.pageSetting.pageHeight" :page-setting="this.paperInfo.pageSetting">
            <div style="height:100%;height:100%;" @click="onPageClick">
              <div class="bill-header" :style="{ height: paperInfo.pageSetting.headerHeight + 'mm',position:'relative' }">
                <!-- 单据头设计区  -->
                <drag-container v-model="store.page.header" :selected="store.current" @change="onDesignItemChange"></drag-container>
                <div class="bill-title">头部</div>
              </div>
              <div class="bill-body" @click.stop.prevent
                :style="{ height: paperInfo.pageSetting.bodyHeight + 'mm' }">
                <!-- 商品网格显示区 -->
                <VueDragResize class="dragging-top" :h="1" :resizable="false" axis="y" @dragging="onBodyDragging" v-if="!showBodyOnly"
                  @dragStop="onBodyDragstop" :onDragStart="onBodyDragStart">
                  <i class="iconfont icon-chaifen"></i>
                </VueDragResize>
                  <resizeTable v-if="paperInfo.pageSetting.bodyContainer=='grid'"  @click="onTableClick" :config="store.page.body" :body-height="paperInfo.pageSetting.bodyHeight" :padding="paperInfo.pageSetting?.padding||{}"></resizeTable>
                  <!-- 自由容器模式 -->
                  <FreeContainer v-model="store.page.freeContainer" :pageSetting="this.paperInfo.pageSetting" @click="onFreeContainerClick" v-else></FreeContainer>
                  
                <div class="bill-title">明细</div>
              </div>

              <div class="bill-footer" :style="{height: paperInfo.pageSetting.footerHeight + 'mm'}">
                <VueDragResize class="dragging-bottom" :x="-10" style="z-index:999;transform: none;cursor:n-resize" :resizable="false"
                  :h="1" axis="y" @dragging="onFooterDragging" @dragStop="onFooterDragstop" v-if="!showBodyOnly"
                  :onDragStart="onFooterDragStart">
                  <i class="iconfont icon-chaifen"></i>
                </VueDragResize>
                  <drag-container v-model="store.page.footer" :selected="store.current" @change="onDesignItemChange"></drag-container>
                  <div class="bill-title">尾部</div>
              </div>

            </div>
          </designer-body>
        </el-main>
        <!-- 组件属性编辑面板 -->
        <el-aside class=" margin-left" width="300px" style="background:#ddd;overflow:hidden;">
          <!-- 注意：目前单据明细配置信息需通过billType 进行读取 -->
          <property-setting :current="store.current"></property-setting>
        </el-aside>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import './index.less'
import VueDragResize from 'vue-draggable-resizable' // https://github.com/mauricius/vue-draggable-resizable
import { useDispatchDesignerStore } from './useDispatchDesignerStore'
import { saveTemplate, getTemplate } from '@/service/print_order/template'
import { ControlList } from './config/design_config'
import DragContainer from './control/drag_container'
import resizeTable from './control/table'
import PropertySetting from './control/property_setting'
import ControlListPanel from './control/control_list_panel'
import DesignerBody from './control/designer_body'
import FreeContainer from './control/free_container'
export default {
  data() {
    return {
      isLoading:false,
      editEntityId: this.entityId,
      store: useDispatchDesignerStore(),
      startHeaderY: 0,
      startFooterY: 0,
      DPI: { x: 1, y: 1 },
      designerConfig:{
        showGridLine:true, // 设计画布显示网格线
        showPaddingLine:true, // 是否显示打印边距线
        showSplitter:true // 单据明细分割线
      },
      paperInfo: this.defaultTemplate, // mm 为单位
      list: ControlList
    }
  },
  provide(){
    return{
      billType: this.billType
    }
  },
  components: { 
    VueDragResize, 
    resizeTable,
    DragContainer,
    DesignerBody,
    PropertySetting,
    ControlListPanel,
    FreeContainer
  },
  props: {
    entityId: {
      type: String
    },
    shopTemplate: {
      type: String
    },
    billType: {
      type: String,
      required: true
    },
    defaultTemplate:{ // 新增时默认配置
      type:Object,
      default: _=>({
        title:'',
        offsetLeft:0,
        offsetTop:0,
        pageSetting:{
          pageHeight: 140,
          pageWidth:241,
          headerHeight:30,
          bodyHeight:191,
          footerHeight:30,
          padding:{left:10,top:10,right:10,bottom:10},
          bodyMode:'auto',bodyContainer:'block' // 默认为单据模式
        } // 页面边距
      })
    }
  },
  watch:{
    'paperInfo.pageSetting.pageHeight':function(val){
      // this.paperInfo.pageHeight = val // 将内部json配置同步至外层字段
      if(!this.showBodyOnly){ // 非拣货贴纸模式，常规
        this._timer && clearTimeout(this._timer)
        // text 输入过程触发，导致连续多次，出现抖动， 加入防抖操作
        // 页面高度通过属性面单调整后台，重新调整footer 的高度
        this._timer = setTimeout(_=>{
          let newFooterHeight =  this.paperInfo.pageSetting.pageHeight - this.paperInfo.pageSetting.headerHeight - this.paperInfo.pageSetting.bodyHeight
          if(newFooterHeight>=0){
            this.paperInfo.pageSetting.footerHeight = newFooterHeight
          } else {
            this.paperInfo.pageSetting.footerHeight= 0 //直接置为0
            this.paperInfo.pageSetting.bodyHeight = this.paperInfo.pageSetting.pageHeight -this.paperInfo.pageSetting.headerHeight // 直接压缩body的高度
          }
        },300)
      } else {
        this.paperInfo.pageSetting.bodyHeight = this.paperInfo.pageSetting.pageHeight
      }
    },
  },
  computed:{
    showBodyOnly:function(){ // 仅显示body设计区，头部，脚部，隐藏
      return ['PICKUP_TEMPLATE'].includes(this.billType)
    }
  },
  created() {
    this.iniDPI()
  },
  mounted: async function () {
    //模板id 空时，新增
    let tplId =  this.entityId //|| router.currentRoute.value.params.tplId
    if (tplId) {
      this.isLoading = true
      let res = await getTemplate({ id: tplId })
      if (res.code == 200) {
        let pageConfig = {}
        let pageSetting = {}
        try {
          pageConfig = JSON.parse(res.data.configJson|| '{}')
          
          pageSetting = JSON.parse(res.data.pageSetting|| '{}')
          this.paperInfo = {
            templateType: res.data.templateType,
            billType: res.data.billType,
            pageSetting: Object.assign( {padding:{},bodyMode:'auto',bodyContainer:'grid'},pageSetting)
          }
        } catch { }
        console.log(this.paperInfo)
        this.store.page = pageConfig
        
        this.isLoading = false
      }

    } else { // 新增时，外部传入默认配置
      let pageSetting = this.defaultTemplate.pageSetting
      this.paperInfo = {
        templateType: this.defaultTemplate.templateType,
        billType: this.defaultTemplate.billType,
        pageSetting: Object.assign( {padding:{},bodyMode:'auto',bodyContainer:'grid'},pageSetting)
      }
      this.store.page= Object.assign(this.defaultTemplate.configJson, {})
    }
    this.onPageClick() // 默认进入页面设置面板
    // 根据当前电脑不同的物理像素，等比例调整
    this.store.page.header?.forEach(item => {
      item.left = item.left * (this.DPI.x / 72)
      item.top = item.top * (this.DPI.y / 72)
      item.width = item.width * (this.DPI.x / 72)
      item.height = item.height * (this.DPI.y / 72)
    })
    this.store.page.footer?.forEach(item => {
      item.left = item.left * (this.DPI.x / 72)
      item.top = item.top * (this.DPI.y / 72)
      item.width = item.width * (this.DPI.x / 72)
      item.height = item.height * (this.DPI.y / 72)
    })
  },
  
  methods: {
    onDesignItemChange(item,posX,posY){
      this.store.current=item;
      if(this.store.current!=null && posX !=undefined && posY!=undefined ){
        // 通过drag_container 添加元素后，根据事件传递的坐标，定位当前新元素的位置
        this.store.current.left = posX
        this.store.current.top = posY
      }
      
    },
    onPageClick(){
      this.paperInfo._t = 'page'
      this.store.current = this.paperInfo.pageSetting
    },
    onSaveClick: async function () {
      let postData = JSON.parse(JSON.stringify(this.store.page))
      postData.headerHeight = this.paperInfo.headerHeight
      postData.bodyHeight = this.paperInfo.bodyHeight
      postData.footerHeight = this.paperInfo.footerHeight
      postData.header?.forEach(item => {
        item.left = item.left / (this.DPI.x / 72)
        item.top = item.top / (this.DPI.y / 72)
        item.width = item.width / (this.DPI.x / 72)
        item.height = item.height / (this.DPI.y / 72)
      })
      postData.footer?.forEach(item => {
        item.left = item.left / (this.DPI.x / 72)
        item.top = item.top / (this.DPI.y / 72)
        item.width = item.width / (this.DPI.x / 72)
        item.height = item.height / (this.DPI.y / 72)
      })
      // todo ===> 自由容器的适配
      let pInfo = JSON.parse(JSON.stringify(this.paperInfo))
      pInfo.templateName = pInfo.pageSetting.title // 模版标题
      pInfo.pageSetting = JSON.stringify(pInfo.pageSetting)
      // let saveFn = props.shopTemplate ? saveShopTemplate: saveTemplate
      let res = await saveTemplate(Object.assign(pInfo, { id: this.editEntityId },{configJson:JSON.stringify(postData)}))
      if (res.code === 200) {
        // 获取信息实体id，以防每次都新建
        this.editEntityId = res.data
        this.$message.success("保存成功")
      } else {
        this.$message.error(res.msg || res.data)
      }
    },

    onPreviewClick: function () {
      // 弹出预览窗口-，支持直接打印
    },

    iniDPI: function () {
      if (window.screen.deviceXDPI) {
        this.DPI.x = window.screen.deviceXDPI;
        this.DPI.y = window.screen.deviceYDPI;
      }
      else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        this.DPI.x = parseInt(tmpNode.offsetWidth);
        this.DPI.y = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
      }
      // console.log('dip',this.DPI) // 每英寸包含的像素数： 如果96 ， 1in=25.4mm 1mm=1/25.4
      //  1mm = (1/15.4)*96 px
      // console.log('xxxxx 1mm = ?px', (1/25.4)*96)

    },
    onTableClick() {
      this.store.current = this.store.page.body
    },
    onFreeContainerClick(){
      this.store.current = this.store.page.freeContainer
    },
    onBodyDragStart(evt) {// 开始拖动
      this.startHeight = this.paperInfo.pageSetting.headerHeight //开始拖拽前，记录初始高度
      this.startFooterHeight = this.paperInfo.pageSetting.footerHeight
    },
    onBodyDragging(x, y) {// 内容顶部拖动
      
      let adjustHeight = (this.startHeaderY - y)/3.78 // 3.78 为px =》mm 转换比率
      this.paperInfo.pageSetting.headerHeight = this.startHeight - adjustHeight
      
    },
    onBodyDragstop(x, y) {
      window.dispatchEvent(new Event('resize')) // 必须加，组件dragresize 仅首次计算父容器大小，并监控window.resize 重新计算
      this.startHeaderY = y
      this.paperInfo.pageSetting.bodyHeight = this.bodyHeight
    },
    onFooterDragStart(evt) {
      this.startHeight = this.paperInfo.pageSetting.footerHeight //开始拖拽前，记录初始高度
      this.startY = evt.offsetY
      this.startBodyHeight = this.paperInfo.pageSetting.bodyHeight //开始拖拽前，记录初始高度
    },
    onFooterDragging(x, y) {

      let adjustHeight = (this.startY - y) /3.78 // 3.78 为px =》mm 转换比率
      // 判断调整后是否超出页面高度
      if((this.startHeight + adjustHeight)<0 ){
        return
      }
      // console.log('after', this.startHeight + adjustHeight)
      this.paperInfo.pageSetting.footerHeight = this.startHeight + adjustHeight
      this.paperInfo.pageSetting.bodyHeight = this.startBodyHeight - adjustHeight
    },
    onFooterDragstop(x, y) {
      window.dispatchEvent(new Event('resize')) // 必须加，组件dragresize 仅首次计算父容器大小，并监控window.resize 重新计算
    }
  }
}

</script>
