<template>
  <el-table-column v-bind="$attrs" class-name="no-padding nested-table">
    <template #header>
      <div class="display-flex align-center" style="column-gap:2px;font-weight:initial;">
        <div class="goods-sku" :style="{ width: '120px' }">商品编码</div>
        <div class="goods-sku" :style="{ width: '120px' }">SKU编码</div>
        <div class="sku-attr" style="flex:1;">SKU属性</div>
        <div :style="{ width: '140px' }" >简称</div>
        <div  :style="{ width: '140px' }" >重量(KG)</div>
      </div>
    </template>
    <template #default="scope">
      <el-table :data="scope.row.platformGoodsSkuList" size="small" :show-header="false" class="tbl-mapping">
          <el-table-column label="商品编码" prop="goodsId" width="120" >
          </el-table-column>
          <el-table-column label="商品编码" prop="skuCode"  width="120" >
          </el-table-column>
          <el-table-column label="sku">
            <template #default="{row}">
              <el-text truncated line-clamp="2" size="small">
                {{ formatSkuAttr(row.attrs) }}
              </el-text>
              
            </template>
          </el-table-column>
          <el-table-column label="简称" width="140">
            <template #default="{row}">
              <el-input size="small" v-model="row.shortName" v-if="scope.row.isEdit"></el-input>
              <div v-else>{{ row.shortName }}</div>
              </template>
          </el-table-column>
          <el-table-column label="重量" width="140">
            <template #default="{row}">
              <el-input size="small" v-model="row.weight" v-if="scope.row.isEdit"></el-input>
              <div v-else>{{ row.weight }}</div>
              </template>
          </el-table-column>
      </el-table>
    </template>
  </el-table-column>
</template>
<script >

import {bindSkuToErpSku} from '@/service/resource/platform_goods'
export default {
  data() {
    return {
      record:null,
      dialogVisible:false
    }
  },
  components:{
  },
  props: {
  },
  methods: {
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    
    onLinkClick(row){
      this.record = row
      this.dialogVisible = true
    },
    
  }
}
</script>
<style lang="less">
  .nested-table{
    &.el-table__cell{
      padding:0px !important;
      >.cell{
        padding:0px !important;
      }
    }
    .tbl-mapping {
      .el-table__cell{
        padding:0px;
      }
    }
  }
</style>
