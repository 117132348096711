<template>
  <el-tree-select ref="refTree" :data="datasource" v-model="value" default-expand-all  check-strictly @visible-change="onDropdownShow"
  :props="props" :cache-data="cacheData" @change="onTreeNodeChange" />
</template>
<script >

import {getBizCompanyCategoryList} from '@/service/resource/biz_company'
export default {
  
  data() {
    return {
      value: this.modelValue,
      cacheData: [{ value: this.modelValue, categoryName: this.text }],
      props: {
        label: 'categoryName',
        value:'id',
        children: 'children',
        disabled: (data,node)=>{ // 禁止选择自己-及其子孙节点, 即仅允许整个子树迁移，否则出现断层
          for(let dId of this.disabled){
            if(dId == data.id || (data.hierarchyInfo && data.hierarchyInfo.indexOf(dId)!=-1)){ // 当前节点，或其子孙节点都不能选择，即仅允许整个子树迁移，否则出现断层
              
              return true
            }
          }
          return false
        }
      },
      datasource: [],      
    }
  },
  emit: ['update:modelValue','update:text'],
  props: {
    modelValue: null,// 对象keytext:null,// key -value 用于文本框显示内容
    text: {
      type: String
    },
    disabled:{
      type:Array,
      default:_=>[]
    }
  },
  watch:{
    modelValue:function(){
      this.value = this.modelValue
      this.cacheData= [{ id: this.modelValue, categoryName: this.text }]
    },
  },
  methods: {
    async onDropdownShow(isVisible){
      if(isVisible && !this._loaded){ // 控制首次点击下拉显示时加载数据
        this._loaded = true
        let res =  await getBizCompanyCategoryList();
        let rootList = []
        if(res.data){
          // 构建层次数
          rootList = res.data.filter(item=>!item.parentId)
          let getChildren = function(id){
            let list = res.data.filter(item=>item.parentId == id)
            list.forEach(item=>{
              item.children = getChildren(item.id)
            })
            return list
          }
          rootList.forEach(item=>item.children = getChildren(item.id))
        }
        rootList.splice(0,0,{id:'',categoryName:'未分类'})
        this.datasource = rootList //[{categoryName:'全部',children:rootList}]
      }
    },
    onTreeNodeChange(val){
      let node = this.$refs.refTree.getCurrentNode()
      this.$emit('update:modelValue',node.id)
      this.$emit('update:text',node.categoryName)
    }
  }
}
</script>
