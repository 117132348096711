import { axios as service } from '@/utils/request'


/**
 * 收付款统计--按年月
 * @param {*} data 
 * @returns 
 */
export const getPaymentSummaryByYearMonth = (data) => {
  return service({
    url: '/report/payment/summaryByYearMonth',
    method: 'post',
    data
  })
}


/**
 * 费用收入统计统计--按年月+费用项目
 * @param {*} data 
 * @returns 
 */
export const getIncomeSummaryByYearMonth = (data) => {
  return service({
    url: '/report/payment/incomeSummaryByYearMonth',
    method: 'post',
    data
  })
}

/**
 * 应收应付款列表： 并显示其核销明细
 * @param {*} data 
 * @returns 
 */
export const getPaymentDetailList = (data) => {
  return service({
    url: '/report/payment/paymentDetail',
    method: 'post',
    data
  })
}
