import { axios as service } from '@/utils/request'


export const getAllMenuList = (data) => {
  return service({
    url: '/menu/allMenu',
    method: 'post',
    data
  })
}
