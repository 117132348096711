<template>
  <div class="display-inline-block">

    <el-popover ref="refDropdown" :width="320" placement="bottom-start" trigger="click"
      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px;">
      <template #reference>
        <el-button link type="primary"  class="margin-right"><el-icon><Sort /></el-icon>{{options[selectKey]||'请选择排序方式'}}</el-button>
      </template>
      <template #default>
        <div style="height:400px;overflow-y:auto;overflow-x: hidden;">
          <el-radio-group v-model="selectKey"  @change="onChange" style="display:inline;height:100%;">
            <el-radio v-for="item in list" :value="item.value" :key="item.value">{{ item.label }} </el-radio>
          </el-radio-group>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script>

import { saveTenantKv } from '@/service/resource/general'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      selectKey: null,
      options:{
        'PAYMENT_TIME_ASC':'先付款的在前面',
        'PAYMENT_TIME_DESC':'后付款的在前面',
        'CREATE_TIME_DESC':'先下单的在前面',
        'CREATE_TIME_ASC':'后下单的在前面',
        'PAYTOTAL_ASC':'付款金额大在前面',
        'PAYTOTAL_DESC':'付款金额金额小在前面',
        'DEADLINE_DESC':'超时发货在前',
        'DEADLINE_ASC':'超时发货在后',
        'SHOP_CREATE_TIME_ASC':'按店铺,先下单在前面',
        'SHOP_CREATE_TIME_DESC':'按店铺,后下单在前面',
        'GOODS_QUANTITY_ASC':'商品数量少在前面',
        'GOODS_QUANTITY_DESC':'商品数量多在前面',
        'GOODS_COUNT_DESC':'订单商品品种多在前面',
        'GOODS_COUNT_ASC':'订单商品品种少在前面',
        'GOODS_TITLE':'按商品标题',
        'GOODS_ATTR':'按商品销售属性',
        'GOODS_ID':'按商品编码'
      }
    }
  },
  emits:['change'],
  computed:{
    list:function(){
      return Object.keys(this.options).map(key=>({value:key,label:this.options[key]}))
    }
  },
  async created() {
    let config = LoginUserStore().getConfig('ORDER_SORT_MODE')
    if(config){
      this.selectKey = config.configValue
    }
    
  },
  methods: {
    async onChange(val) {
      this.$emit("change", val)
      this.$refs.refDropdown.hide()
      let postData = { parentKey: 'CUSTOM_CONFIG', configKey: 'PRINT_OPTION',configType:'ORDER_SORT_MODE', configValue: val }
      LoginUserStore().updateConfig('ORDER_SORT_MODE',postData)
      let res = await saveTenantKv(postData)
    }
  }
}
</script>