
<template>
  <el-container class="nt-height-100 nt-bg-page">
    <el-header class="no-margin height-auto no-padding">
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>弹窗明细</template>
      </nt-rpt-toolbar>
    </el-header>
    <el-container class="padding">
      <el-header class="height-auto no-padding no-margin">
        <el-form inline @keyup.enter.native="onSearchQueryClick">
          <el-form-item label="单据编号">
            <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
          </el-form-item>
          <el-form-item label="单据日期">
            <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"></NtDateRange>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          </el-form-item>
        </el-form>
      </el-header>
      <el-main class="no-padding margin-top-4">
        <el-table border ref="table" size="middle" height="100%" :data="datasource" v-loading="isLoading" class="nt-rpt-table">
          <el-table-column label="单据日期" width="110" align="center" prop="billTime">
            <template #default="scope">
              {{ formatShortDate(scope.row.billTime) }}
            </template>
          </el-table-column>
          <nt-bill-no-column label="单据编码" width="165" idName="headId"></nt-bill-no-column>
          <el-table-column label="单据类型" prop="billType" width="100">
            <template #default="scope">
              {{EnumBillType[scope.row.billType] || scope.row.billType}}
            </template>
          </el-table-column>
          <el-table-column label="往来单位" prop="companyName">
          </el-table-column>
          <el-table-column label="商品" prop="goodsName">
          </el-table-column>
          <el-table-column label="单位" prop="unitName" width="60">
          </el-table-column>

          <el-table-column label="仓库" prop="storeName" width="70">
          </el-table-column>
          
          <el-table-column label="数量" prop="quantity" width="80" align="right">
          </el-table-column>
          <el-table-column label="已执行数量" prop="finishQuantity" width="100" align="right">
          </el-table-column>
          <el-table-column label="单价" prop="price" align="right" width="80">
            <template #default="scope">
              {{ ((scope.row.price)).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="金额合计" prop="total" align="right" width="100">
            <template #default="scope">
              {{ ((scope.row.price*scope.row.quantity)).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="no-padding height-auto margin-top">
        <el-row type="flex" justify="end">
          <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" small
            :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
            layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
            @size-change="onPageSizeChange" />
        </el-row>
      </el-footer>
    </el-container>
    
  </el-container>

  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>

import NtDateRange from '@/views/plugin/nt_date_range'
import { getTradeItemList } from '@/service/report/rpt_trade'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { EnumBillType } from '@/utils/enum_utils'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
export default {
  name: "TradeItemList",
  mixins: [DataListMixin],
  components: {
    NtDateRange,
    NtRptToolbar
  },
  data() {
    return {
      currentRecord: {},
    }
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  methods: {
    async _loadList(params) {
      return await getTradeItemList(Object.assign({}, this.query, params));
    },
    onDateOk() { },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList()
    },
    onDateChange: function (value, dateString) {
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    onViewBillDetailClick(record) {
      this.$refs.refViewer.showBill(record.billType, record.headId)
    }
  }
}
</script>
