
<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      
    </el-header>
    <el-main class="no-padding">
      <el-table
        border
        ref="table"
        size="middle"
        rowKey="goodsSkuId"
        :data="datasource"
        v-loading="isLoading"
        >
          <el-table-column label="商品"  prop="goodsName">
            </el-table-column>
            <el-table-column label="单位" width="120" prop="unitName">
            </el-table-column>
            <el-table-column label="单价" width="80" prop="price">
              <template #default="scope">
                {{ scope.row.total/scope.row.quantity/100.00 }}
              </template>
            </el-table-column>
            <el-table-column label="仓库" width="80" prop="storeName">
            </el-table-column>
            <el-table-column label="库存" width="80" prop="quantity">
            </el-table-column>
            <el-table-column label="金额合计" width="80" prop="quantity">
              <template #default="scope">
                {{ scope.row.total/100.00 }}
              </template>
          </el-table-column>
      </el-table>
    </el-main>
    <!-- <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
      </el-row>
    </el-footer> -->
  </el-container>
</template>
<script>
  import {getMaterialExtendStockList} from '@/service/report/rpt_stock'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  
  export default {
    name: "MaterialExtendStock",
    mixins:[DataListMixin],
    components: {
    },
    data () {
      return {
        toggleSearchStatus:false,
      }
    },
    props:{
      materialId:{
        type:String
      }
    },
    methods: {
      async _loadList(params){
        let res = await getMaterialExtendStockList(Object.assign({goodsId:this.materialId},params))
        console.log('res', res)
        if(res.code ==200){
          res.data = {
            list: res.data
          }
        }
        return res
      },
      
      onSearchQueryClick() {
        this.loadList();
      },
      
    }
  }
</script>

<style scoped>
  @import '~@assets/less/common.less';
</style>