
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <a-form inline @keyup.enter.native="onSearchQueryClick" >
        <a-row :gutter="24">
          <a-col :md="5" :sm="24">
            <a-form-item label="仓库">
              <a-select mode="multiple" :maxTagCount="1" optionFilterProp="children" showSearch style="width: 100%"
                placeholder="请选择仓库" v-model="storeSelected">
                <a-select-option v-for="(store, index) in storeList" :value="store.id" :key="index">
                  {{ store.storeName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="商品信息">
              <a-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="onSearchQueryClick">查询</a-button>
            </span>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="5" :sm="24">
              <a-form-item label="类别" >
                <a-tree-select style="width:100%" :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }" allow-clear
                  :treeData="categoryTree" v-model="queryParam.categoryId" placeholder="请选择类别">
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="仓位货架" >
                <a-input style="width: 100%" placeholder="请输入仓位货架查询" v-model="queryParam.position"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="零库存" >
                <a-select v-model="queryParam.zeroStock">
                  <a-option value="0">隐藏</a-option>
                  <a-option value="1">显示</a-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource" v-loading="isLoading">

        <el-table-column label="商品" prop="goodsName">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <el-table-column label="型号" prop="model">
        </el-table-column>
        <el-table-column label="颜色" prop="color">
        </el-table-column>
        <el-table-column label="单位" prop="unitName">
        </el-table-column>


        <el-table-column label="数量" prop="quantity">
        </el-table-column>
        <el-table-column label="总额" prop="total">
          <template #default="scope">
            {{ ((scope.row.total) / 100).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column label="含税总额" prop="totalWithTax">
          <template #default="scope">
            {{ (scope.row.totalWithTax / 100).toFixed(2) }}
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
import { getInSummaryList } from '@/service/report/rpt_stock'
import MaterialInOutList from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
// import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import { queryMaterialCategoryTreeList } from '@/api/api'
// import { getMpListShort, openDownloadDialog, sheet2blob} from "@/utils/util"

// import moment from 'moment'
// import Vue from 'vue'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    MaterialInOutList
  },
  data() {
    return {
      detailVisible: false,
      toggleSearchStatus: false,
      // 查询条件
      queryParam: {
        categoryId: '',
        materialParam: '',
        position: '',
        zeroStock: '0',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      currentWeight: 0,
      storeSelected: [],
      storeList: [],
      categoryTree: [],
    }
  },
  async created() {
    let res = await this.getEntityList(this.EnumEntityType.STORE, {})
    if (res.code == 200) {
      this.storeList = res.data
    }
  },
  mounted() {
  },
  methods: {
    loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      queryMaterialCategoryTreeList(params).then((res) => {
        if (res) {
          that.categoryTree = [];
          for (let i = 0; i < res.length; i++) {
            let temp = res[i];
            that.categoryTree.push(temp);
          }
        }
      })
    },
    async _loadList(params) {
      return await getInSummaryList(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },

  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>