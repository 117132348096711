<template>
  <el-drawer v-model="isVisible" destroyOnClose size="850" title="订单详情" class="nt-inner-drawer" :z-index="99"
    @close="isVisible = false">
    <div class="padding nt-height-100 nt-bg-page" v-loading="isLoading">
      <el-descriptions title="订单详情" class="nt-custom padding margin-top nt-box-panel">
        <el-descriptions-item label="订单来源">{{ order?.platform }}</el-descriptions-item>
        <el-descriptions-item label="店铺名称">{{ order?.shopId }}</el-descriptions-item>
        <el-descriptions-item label="订单编号">{{ order?.orderNo }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{ order?.payTime }}</el-descriptions-item>
        <el-descriptions-item label="交易金额">{{ (order?.payTotal / 100.0).toFixed(2) }}</el-descriptions-item>
        <el-descriptions-item label="买家留言">{{ order?.buyerRemark }}</el-descriptions-item>
        <el-descriptions-item label="卖家备注">{{ order?.merchantRemark }}</el-descriptions-item>
      </el-descriptions>
      <el-table border ref="table" size="small" class="nt-resource-table margin-top" :data="order?.orderItemList">
        <el-table-column label="#" width="50" type="index">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName"></el-table-column>
        <el-table-column label="规格" with="100">
          <template #default="{ row }">
            {{ formatSkuAttr(row.skuAttrs) }}
          </template>
        </el-table-column>
        <el-table-column label="数量" width="120" prop="quantity"></el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>

import { getOrder } from '@/service/print_order/order'

export default {
  name: "platform_order_detail",
  data() {
    return {
      // isLoading: false,
      // datasource: [],
      order:null,
      isVisible: false
    }
  },
  props: {
    // order: {
    //   type: Object,
    //   required: true
    // }
  },
  async created() {

  },
  methods: {
    async show(orderId) {
      this.isVisible = true
      this.isLoading = true
      let res = await getOrder(orderId)
      this.isLoading = false
      if (res.code == 200) {
        this.order = res.data
      }
    },
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },

  }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    :deep(.el-descriptions__body){
      padding:8px;
    }
    
    
  }
  
</style>