/**
 * 枚举类型工具类型
 */
export const EnumBillType={
  FINANCIAL_PAYMENT_INIT: '期初应收/应付',
  FINANCIAL_PAY:'付款单',
  FINANCIAL_TAKE: '收款单',
  // FINANCIAL_PAY_PREPAY:'预付款',
  // FINANCIAL_TAKE_PREPAY: '预收款',
  FINANCIAL_WITHDRAW:'转账',
  FINANCIAL_INCOME:'其他收入',
  FINANCIAL_EXPENSE:'其他支出',
  FINANCIAL_SHARE_SALE:'销售费用分摊',
  FINANCIAL_SHARE_PURCHASE:'采购费用分摊',
  ACCOUNT_INIT:'账户初始化',
  TAKE_INIT: '期初应收',
  PAY_INIT: '期初应付',

  PURCHASE_ORDER:'采购订单',
  PURCHASE_IN:'采购入库单',
  PURCHASE_BACK:'采购退货单',
  SALE_ORDER:'销售订单',
  SALE_OUT:'销售出库单',
  ONLINE_SALE_OUT:'线上零售单',
  SALE_BACK:'销售退货单',
  REPOSITORY_ASSEMBLE:'组装单',
  REPOSITORY_ALLOCATE: '调拨单',
  REPOSITORY_DISASSEMBLE:'拆卸单',
  REPOSITORY_STORE_OUT:'其他出库单',
  REPOSITORY_STORE_IN:'其他入库单',
  REPOSITORY_STORE_INIT:'期初初始化'
}

export const EnumBizType={
  PAYMENT_INIT_IN: '期初应收',
  PAYMENT_INIT_OUT: '期初应付',
  FINANCIAL_PAY_PREPAY: '预付款',
  FINANCIAL_TAKE_PREPAY: '预收款',
}

/***********实体类型*************** */
export const EnumEntityType={
  ACCOUNT_HEAD : 'accountHead', // 收付款
  PREPAY_HEAD : 'prepayHead', // 预收预付款单
  TRADE_HEAD : 'tradeHead', // 采购  销售业务
  ONLINE_SALE_HEAD : 'onlineSaleHead', // 线上零售业务
  INCOME_HEAD : 'incomeHead', // 支出  收入
  WITHDRAW_HEAD : 'withdrawHead', // 转账
  SHARE_HEAD : 'shareHead', // 成本分摊
  STORE_HEAD : 'storeHead', // 其他出入库
  ADJUST_HEAD : 'adjustHead', // 成本调整单
  ALLOCATE_HEAD : 'allocateHead', // 调用业务
  ASSEMBLE_HEAD : 'assembleHead', // 拆卸业务
  PAYMENT_INIT : 'paymentInit', // 应收应付初始化
  ACCOUNT_INIT_HEAD : 'accountInitHead', // 银行账户初始化
  STORE_FLOW: 'storeFlow', // 存货流水实体

  BIZ_COMPANY : 'bizCompany', // 往来单位
  GOODS : 'goods', // 商品
  GOODS_SKU : 'goodsSku', // 商品SKU
  
  GOODS_CATEGORY : 'goodsCategory', // 商品分类
  GOODS_ATTR : 'goodsAttr', // 商品属性
  GOODS_UNIT:'goodsUnit',
  STAFF : 'staff', // 员工
  CLOUD_PRINTER : 'cloudPrinter', // 打印机表
  FEE_TYPE:'feeType', // 费用类型
  FINANCIAL_ITEM : 'financialItem', // 收支项目
  STORE:'store', // 仓库
  ACCOUNT : 'account', // 账户
  GOODS_SHORT_NAME:'goodsShortName', // 商品简称
  GOODS_MARKET:'goodsMarket', // 商品档口
  PLATFORM_SHOP:'platformShop', // 门店信息-三方授权
  PLATFORM_SHOP_ADDRESS:'platformShopAddress', // 门店收发货地址  废弃
  ADDRESS:'address', // 打印模块地址管理
  PLATFORM_ORDER:'platformOrder', // 电商平台订单
  PLATFORM_WAYBILL:'platformWaybill', // 电子面单列表
  THIRD_AUTH: 'thirdAuth', // 平台授权表
  FLATFORM_WAYBILL_TEMPLATE:'platformWaybillTemplate', // 店铺面单模版（通过电商平台同步）

  SHOP_DELIVERY:'shopDelivery', // 门店已开通物流列表
  USER: 'user', // 操作用户
  ROLE: 'role', // 权限角色
  DEPT:'department', // 部门
  FUNCTION_MENU:'functionMenu', //功能模块
  PAYMENT_METHOD: 'paymentMethod' // 结算方式
}

export const EnumToolBarCommand = {
  NEW_BILL:'NEW_BILL', // 新增
  SAVE_AND_NEW:'SAVE_AND_NEW', //保存并新增
  SAVE:'SAVE', // 保存
  VERIFY:'VERIFY', // 审核
  UNVERIFY:'UNVERIFY', // 反审
  CLOSE:'CLOSE', // 关闭
  UPLOAD_FILE:'UPLOAD_FILE', // 上传附件
  VIEW_FILE:'VIEW_FILE', // 查看附件
  PRINT:'PRINT', // 打印
  PRINT_PREVIEW:'PRINT_PREVIEW', // 打印预览
  PRINT_SETTING:'PRINT_SETTING', // 打印预览
  COPY:'COPY', // 复制单据
  FIRST:'FIRST', // 首张
  PREV:'PREV', // 上涨
  NEXT:'NEXT', // 下张
  LAST:'LAST', // 末张
  EXIST:'EXIST', // 退出
}

export const EnumDelivery = {
  ZTO:'中通',
  YTO:'圆通',
  YUNDA:'韵达',
  JTSD:'极兔',
  STO:'申通',
  SF:'顺丰',
  JD:'京东',
  EMS:'中国邮政',
  CNSD:'菜鸟速递(丹鸟)',
  DBKD:'德邦快递',
  SXJD:'顺心捷达',
  ZTOKY:'中通快运',
  YDKY:'韵达快运',
  ANEKY:'安能物流'
}