<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title >调拨单</template>
          <template #extra>
            <el-button @click="jumpToPage('/rps_allocate_list','调拨单列表')" >单据列表</el-button>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row class="form-row" :gutter="24">
            <el-col :lg="6" :md="12" :sm="24">
              <nt-form-item  prop="storeId" label="出库仓" label-width="80px">
                <nt-dropdown-grid  configKey="store" :rules="formValidator('required','选择出库仓')"
                  v-model="formModel.inStoreId" v-model:text="formModel.inStoreName" :disabled="viewOnly"
                  placeholder="请选择出库仓" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
            </el-col>
            <el-col :lg="6" :md="12" :sm="24">
              <nt-form-item  prop="storeId" label="调入仓" label-width="80px">
                <nt-dropdown-grid  configKey="store" :rules="formValidator('required','选择调入仓')"
                  v-model="formModel.outStoreId" v-model:text="formModel.outStoreName" :disabled="viewOnly"
                  placeholder="请选择调入仓" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
            </el-col>
            <el-col :lg="6" :md="12" :sm="24" >
              <nt-form-item  prop = "billTime" label="单据日期" label-width="80px" :rules="formValidator('required','选择选择日期')">
                <el-date-picker
                  v-model="formModel.billTime"
                  type="date" :disabled="viewOnly"
                />
              </nt-form-item>
            </el-col>
            <el-col :lg="6" :md="12" :sm="24">
              <nt-form-item  prop = "billNo" label="单据编号">
                <el-input placeholder="请输入单据编号" label-width="80px" v-model="formModel.billNo" readonly/>
              </nt-form-item>
            </el-col>
          </el-row>
          <el-row class="form-row" :gutter="24">
            <el-col :lg="6" :md="12" :sm="24">
              <el-form-item  prop = "remark" label="备注">
                <el-input  placeholder="请输入备注" v-model="formModel.remark"  style="width:100%"/>
              </el-form-item>
            </el-col>
          </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.allocateItemList" border  height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column  width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon><Delete /></el-icon>
                  <!-- <el-button link type="danger" size="small">删除</el-button> -->
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>


          <el-table-column label="商品" width="180" prop="goodsName" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <span v-if="viewOnly || scope.row.linkId">{{ scope.row.goods.goodsName }}</span>
              <nt-form-item   label-width="0" :prop="`allocateItemList[${scope.$index}].goodsId`" v-else
                :rules="formValidator('required', '请选择商品')">
                <nt-dropdown-grid configKey="goods" v-model:text="scope.row.goods.goodsName"
                :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
                @choose="(rows) => onRowGoodsChange([rows], scope.row, scope.$index)"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单位" width="110" prop="unitName">
            <template #default="scope">
              <span v-key="`'txt_unit_'${scope.$index}`" v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.unitName }}</span>
              <nt-form-item  v-key="`'unit_'${scope.$index}`" label-width="0" :prop="`allocateItemList[${scope.$index}].unitId`" v-else
                :rules="formValidator('required', '请选择计量单位')">
                <nt-dropdown-grid configKey="goodsUnit" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.unitId" v-model:text="scope.row.unitName"
                :disabled="viewOnly" placeholder="计量单位" :searchable="false"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="SKU" width="180" prop="sku">
            <template #default="scope">
              <span v-key="`'txt_sku_'${scope.$index}`" v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.sku }}</span>
              <nt-form-item  label-width="0" v-key="`'sku_'${scope.$index}`" :prop="`allocateItemList[${scope.$index}].goodsSkuId`" v-else>
                <nt-dropdown-grid configKey="goodsSku" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.goodsSkuId" v-model:text="scope.row.sku"
                :disabled="viewOnly" placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          
          
          <el-table-column label="价格" width="80" prop="price" :class-name="!viewOnly? 'editor':''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`allocateItemList[${scope.$index}].price`"  v-if="!viewOnly" :rules="formValidator('required','请输入价格')">
                <el-input v-model="scope.row.price"/>
              </nt-form-item>
              <span v-else>{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stockQuantity" width="80"></el-table-column>
          <el-table-column label="数量" width="80" prop="quantity" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item  label-width="0" v-model="scope.row.quantity" v-if="!viewOnly" :prop="`allocateItemList[${scope.$index}].quantity`"
                :rules="formValidator('required', '请输入数量')">
                <el-input v-model="scope.row.quantity" />
              </nt-form-item>
              <span v-else>{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" class-name="editor">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`allocateItemList[${scope.$index}].remark`"  v-if="!viewOnly" >
                <el-input v-model="scope.row.remark"/>
              </nt-form-item>
              <!-- @change="e=>onRowInputChange(val,scope.row,'remark')" -->
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>

  <el-dialog
    v-model="billSelectorVisible" destroyOnClose width="75%" append-to-body
    class="nt-resource-dialog"
    title="详情"
    :close-on-click-modal="false"
    @close="billSelectorVisible=false"
  >
    <bill-selector  @choose="onBillSelectedHandler" :companyId="formModel.companyId" @cancel="billSelectorVisible =false"  billType="PURCHASE_ORDER" v-if="billSelectorVisible"></bill-selector>
  </el-dialog>
</template>
<script>
import NtBillLayout from '@/views/layout/nt_bill_layout'
  import { DataBillMixin } from '@/utils/data_bill_mixin'
  import { getNowFormatDateTime } from '@/utils/util'
  
  import billSelector from '@/views/bill/selector/LinkBillList'
  import NtFormItem from '@/views/plugin/nt_form_item'
  
  import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
  import { EnumEntityType } from '@/utils/enum_utils'
  export default {
    name: "Allocate",
    mixins: [DataBillMixin],
    components: {
      NtBillLayout,
      NtFormItem,
      billSelector,
      
      NtBillToolbar
    },
    data () {
      return {
        billSelectorVisible:false,
        billType: 'REPOSITORY_ALLOCATE',
        entityType:EnumEntityType.ALLOCATE_HEAD  // 浏览特定位置单据时使用，bill_mixin
      }
    },
    props:{
      entityId:{
        type: String
      }
    },
    watch:{
      entityId: function(){
        this.loadEntity()
      },
      
    },
    methods: {
      async _initialize(){
        this.initStore()
      },
      async _createNewEntity() {
        // let billNo = await this.createBillNo('DBCK')
        return {
          data: {
            // billNo: billNo,
            allocateItemList: [{goods:{}}],
            discountTotal: 0,
            otherTotal: 0,
            total: 0,
            billTotal: 0,
            billTime: this.getDateTime()
          }
        }
      },
      async _loadEntity(params){
        if (!this.entityId) {
          this.fileList = []
          return this._createNewEntity()
          
        } else {
          let res = await this.getEntity(this.entityType, {id: this.entityId})
          return res
        }
      },
        

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        if(this.formModel.allocateItemList.length ==0){
          this.$message.error(`单据明细不能为空`)
          return false
        }
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,billType: this.billType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        

        if(this.fileList && this.fileList.length > 0) {
          postData.fileName = this.fileList
        }
        return this.saveEntity(this.entityType, postData)
      },

      _loadFinished(){
      },

      onFormChangeHandler(){
        // 根据当前编辑信息，重新计算：实付金额
        let total = 0
        this.formModel.allocateItemList.forEach(el=>{
          let itemTotal = (el.quantity||0)*(el.price||0)
          let taxTotal = (el.quantity||0)*(el.price||0)*(el.taxRate||0)
          el.totalWithTax = itemTotal+ taxTotal
          total += el.totalWithTax
        })

        this.formModel.total = total
        this.formModel.billTotal = total - (this.formModel.discountTotal||0) + (this.formModel.otherTotal||0)
        this.$forceUpdate()
      },

      onRowGoodsChange(rows,record,index){
        if(!rows || rows.length===0){
          return
        }
        let getAddItem = row => {
          return {
            goodsId: row.id,
            // goodsSkuId: row.id,
            // sku: row.sku,
            unitId:row.unitId,
            unitName: row.unitName,
            // goodsName: row.goodsName,
            goods:row,
            quantity: 1,
            price: row.purchaseDecimal, // TODO  采购价格计算逻辑
            taxRate: 0,
            taxMoney: 0,
            stockQuantity:0,
            totalWithTax: row.purchaseDecimal
          }
        }
        // 根据id判断是否已经添加
        rows.forEach((el,idx)=>{
          let matchItem = this.formModel.allocateItemList.find(item=>item.goodsSkuId == el.id)
          // console.log('matchItem', matchItem)
          if(!matchItem){
            let newItem = getAddItem(el)
            // newItem.key = "__"+(new Date()).getTime() + "_"+ idx
            if(idx==0){// 第一个选择的商品直接填充当前行
              // console.log('current row index', this.formModel.allocateItemList[index])
              let current = this.formModel.allocateItemList[index]
              newItem.quantity = current.quantity || 1
              newItem.storeId = current.storeId
              newItem.remark = current.remark
              newItem.taxRate = current.taxRate
              this.formModel.allocateItemList[index] = newItem // 替换地址所指向位置，内存地址
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(this.formModel.allocateItemList[index]) // 注意：直接采用newItem 作为入参，ui 未刷新
            } else {
              this.formModel.allocateItemList.push(newItem)
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(newItem)
            }
          }
        })
        
        this.$forceUpdate()
      },

      async onStoreChangeHandler(row){ // 切换仓库时，重新计算存货信息
        row.stockQuantity= await this.refreshStockInfo(row)
      },
      
      onRowDeleteClick(rowIndex){
        this.formModel.allocateItemList.splice(rowIndex,1)
      },
      
      onAddLineClick() {
        this.formModel.allocateItemList.push({ })
      },
    async _copyBill() { // 复制单据
      // let billNo = await this.createBillNo('DBCK')
      return {
        id: '',
        status: null,
        // billNo: billNo,
        billTime: this.getDateTime // getNowFormatDateTime()
      }
    },
      
      getSummaries({columns,data}){
        let sumResult = []
        columns.forEach((column, index) => {
          if (index <2 ) {
            sumResult[2] = '合计'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => Number.isNaN(value))) {
            sumResult[index] = `${values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!Number.isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)}`
          } else {
            // sumResult[index] = 'N/A'
          }
        })
        return sumResult
      }
    }
  }
</script>