
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="采购时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item style="width:200px;height:60px;">
          <nt_chinese_money></nt_chinese_money>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%" :row-style="{height:'40px'}" :data="datasource" v-loading="isLoading">
        <el-table-column label="操作" width="90">
          <template #default="scope">
            <el-space>
              <a @click="onViewDetailClick(scope.row)">查看详情</a>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="goodsName" width="140">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName">
        </el-table-column>
        <el-table-column label="采购数量" prop="inBaseQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="采购金额" prop="inTotalWithTax" width="90" align="right">
          <template #default="scope">
            {{ ((scope.row.inTotalWithTax)).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="退货数量" prop="backBaseQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotalWithTax" width="90" align="right">
          <template #default="scope">
            {{ ((scope.row.backTotalWithTax)).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="实际采购数量" prop="quantity" width="120" align="right">
          <template #default="scope">
            {{ scope.row.inBaseQuantity - scope.row.backBaseQuantity }}
          </template>
        </el-table-column>
        <el-table-column label="实际采购金额" prop="total"  width="120" align="right">
          <template #default="scope">
            {{ ((scope.row.inTotalWithTax - scope.row.backTotalWithTax)).toFixed(2) }}
          </template>
        </el-table-column>
        <nt-chineseMoney-column prop="backTotal" width="160"></nt-chineseMoney-column>
        <el-table-column></el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import nt_chinese_money from '@/views/plugin/nt_chinese_money' // 业务单据查看统一入库页
import { getPurchaseInTotalByGoods } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
import NtChineseMoneyColumn from '@/views/plugin/nt_chinese_money_column'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NtDateRange,
    NtChineseMoneyColumn,
    nt_chinese_money
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      },
      
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getPurchaseInTotalByGoods(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'PURCHASE_IN,PURCHASE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

