 <!-- 按订单，或按商品 两套方案执行查询 -->
 <template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>销售排行</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按商品 按业务员，按部门，按客户 统计毛利"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="排名" type="index" width="60">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName" width="160">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="70">
        </el-table-column>
        <el-table-column label="主数量" prop="baseQuantity" width="70">
        </el-table-column>
        <el-table-column label="金额(含税)" prop="totalWithTax" width="110" align="right">
        </el-table-column>
        <el-table-column label="销售占比" prop="outQuantity" width="110" align="right">
          <template #default="{row}">
            {{ (row.baseQuantity/summaryInfo.quantity*100).toFixed(2) + '%' }}
          </template>
        </el-table-column>
        <el-table-column label="毛利"  align="right">
          <!-- 无税金额-成本金额 -->
          <template #default="{row}">
            {{ row.profitTotal }}
          </template>
        </el-table-column>
        <el-table-column label="毛利率" align="right">
          <template #default="{row}">
            {{ getProfitRate(row) }}
          </template>
        </el-table-column>
        <el-table-column label="毛利率占比(%)" prop="backQuantity" align="right">
          <template #default="{row}">
            {{ (row.profitTotal/summaryInfo.profitTotal*100).toFixed(2) + '%' }}
          </template>
        </el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getGoodsSaleRank } from '@/service/report/rpt_sale'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      summaryInfo:{
        profitTotal:0,
        quantity:0
      },
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      let res = await getGoodsSaleRank(Object.assign(params, {}))
      if(res.data){
        // 计算 销售占比  参考维度： 总数量，
        // 计算 毛利占比  参考维度： 总毛利，
        let profitTotal = 0 // 总毛利
        let allQty = 0  // 总销量
        res.data.forEach(item=>{
          profitTotal += item.totalWithTax - item.costTotal
          item.profitTotal = item.totalWithTax - item.costTotal
          allQty += item.baseQuantity
        })
        this.summaryInfo = {
          profitTotal: profitTotal,
          quantity: allQty
        }
      }
      return res
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    getProfitRate(row){ // 计算毛率
      // 毛利率计算规则： 毛利/ 销售成本（实销成本）
      let rate = 1
      if(row.costTotal!=0){
        rate = row.profitTotal/row.costTotal
      }
      return `${(rate*100).toFixed(2)}%`
    },
  }
}
</script>

