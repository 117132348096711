<template>
  <el-container class="padding nt-height-100">
    <el-aside width="250px" class="margin-right nt-box-panel nt-bg-white">
      <type-list-page @change="onTypeSelectChange"/>
    </el-aside>
    <el-main class=" padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="no-margin height-auto no-padding display-flex space-between">
          <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
            <nt-form-item label="名称" prop="itemName">
              <el-input placeholder="请输入名称查询" v-model="queryModel.itemName"></el-input>
            </nt-form-item>
            <nt-form-item label="备注">
              <el-input placeholder="请输入备注查询" v-model="queryModel.remark"></el-input>
            </nt-form-item>
            <nt-form-item>
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            </nt-form-item>
          </el-form>
          <div>
            <el-button  @click="onAddClick" type="primary">新增</el-button>
          </div>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
            v-loading="isLoading">
            <el-table-column label="名称" prop="itemName"></el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
            <el-table-column label="是否启用" width="120" prop="enabled">
              <template #default="scope">
                <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
                <el-tag v-if="!scope.row.enabled" type="warning">禁用</el-tag>
              </template>
            </el-table-column>
            <nt-operation-column label="操作" fixed="right" width="55" align="center" 
              :confirm="{DELETE:'是否确认删除?'}"
              :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
              @command="onColumnOperationAction">
              <template #extra>
              </template>
            </nt-operation-column>
            
          </el-table>
        </el-main>
        <el-footer class="margin-top-8 height-auto">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible">
    </detail-page>
  </el-dialog>
</template>
<script>
import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import typeListPage from './fee_type/'

export default {
  name: "InOutItemList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    typeListPage,
    NtFormItem
  },
  data() {
    return {
      selectedFeeType:null,
      ioType: this.$route.meta.ioType, // 根据路由区分费用，收入
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedFeeType) return

      let postData = Object.assign({
        ioType:this.ioType,
        feeTypeId: this.selectedFeeType?.id // 根据选择的分类过滤数据
      },  params)
      return await this.getPageEntityList(this.EnumEntityType.FINANCIAL_ITEM, postData);
    },
    onSearchQueryClick() {
      this.loadList()
    },

    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.FINANCIAL_ITEM, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.FINANCIAL_ITEM, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onTypeSelectChange(fType){
      console.log('fType',fType)
      this.selectedFeeType = fType
      // 清空用户列表查询条件
      this.loadList()
    }
  }
}
</script>