
<template>
  <el-container class="padding nt-height-100">
    <el-header class="no-margin height-auto no-padding display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <nt-form-item label="打印机名称" >
          <el-input placeholder="请输入名称" v-model="queryModel.printerName"></el-input>
        </nt-form-item>
        
        <nt-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </nt-form-item>
      </el-form>
      <div>
        <el-button size="small" @click="onAddClick" type="primary">新增</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="打印机名称" width="200" prop="printerName"></el-table-column>
        <el-table-column label="打印机品牌" width="140" prop="brandTypeName"></el-table-column>
        <el-table-column label="流量卡号" width="160" prop="phoneNo" ></el-table-column>
        <el-table-column label="密钥" width="260" prop="secretKey"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?'}"
          :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible"  destroyOnClose width="650px" class="nt-resource-dialog" title="详情" :close-on-click-modal="false"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler"  @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>

import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "shopAddressList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {
    }
  },
  methods: {
    async _loadList(params) {
      // if(!this.selectedShopId) return
      return await this.getPageEntityList(this.EnumEntityType.CLOUD_PRINTER, Object.assign({},params));
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.CLOUD_PRINTER, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onRowSetDefaultClick(record) {
      let res = await this.setEntityDefault(this.EnumEntityType.CLOUD_PRINTER, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.setEntityDefault(this.EnumEntityType.CLOUD_PRINTER, { id: record.id, default: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>