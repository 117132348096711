import { axios as service } from '@/utils/request'

/* 商品存货： 仓库+商品*/
export const getGoodsStock = (data) => {
  return service({
    url: '/report/repository/goodsStock',
    method: 'post',
    data
  })
}

/* 进销存汇总:*/
export const getSummary = (data) => {
  return service({
    url: '/report/repository/summary',
    method: 'post',
    data
  })
}

/* 出入库汇总 */
export const getStoreSummary = (data) => {
  return service({
    url: '/report/repository/flowSummary',
    method: 'post',
    data
  })
}


/* 出入库明细 */
export const getFlowDetail = (data) => {
  return service({
    url: '/report/repository/flowDetail',
    method: 'post',
    data
  })
}

/* 超储预警 */
export const getStockOverLimit = (data) => {
  return service({
    url: '/report/repository/overLimit',
    method: 'post',
    data
  })
}


/* 缺货预警 */
export const getStockBelowLimit = (data) => {
  return service({
    url: '/report/repository/belowLimit',
    method: 'post',
    data
  })
}



/* 库存成本总账 */
export const getStockCostSummary = (data) => {
  return service({
    url: '/report/repository/costSummary',
    method: 'post',
    data
  })
}

/* 库存成本明细账 */
export const getStockCostDetail = async(data) => {
  let res = await service({
    url: '/report/repository/costDetail',
    method: 'post',
    data
  })
  if(res.code===200){
    res.data = res.data.reduce((pre,cur)=>{
      let preTotal = cur.initTotal||0 // 期初存货数量
      let preQuantity = cur.initBaseQuantity || 0// 期初存货数量
      cur.lastTotal =  preTotal
      cur.lastBaseQuantity = preQuantity
      
      let children = cur.children
      delete cur.children
      pre.push(cur)
      // 小计行 变量 --累计
      let inBaseQuantity = 0, outBaseQuantity=0, inCostTotal=0,outCostTotal=0
      children?.forEach(el=>{
        preTotal +=  (el.inCostTotal -el.outCostTotal) // 本期 入库成本
        preQuantity +=  (el.inBaseQuantity - el.outBaseQuantity) // 本期 入库成本
        el.lastTotal = preTotal
        el.lastBaseQuantity = preQuantity
        el.goodsName = cur.goodsName
        inBaseQuantity += el.inBaseQuantity
        outBaseQuantity += el.outBaseQuantity
        inCostTotal += el.inCostTotal
        outCostTotal += el.outCostTotal
        el.isDetail = true
        pre.push(el)
      })
      //  pre.push({goodsName:'小计',
      //   lastTotal:preTotal,
      //   lastBaseQuantity:preQuantity,
      //   inBaseQuantity, outBaseQuantity,
      //   inCostTotal,outCostTotal,
      //   isSubtotal:true
      //   }) // 增加小计
      return pre
    },[])
  }
  console.log('fking',res.data)
  return res
}