<template>
  <el-menu class="nt-tool-bar"  mode="horizontal" :ellipsis="false" 
  style="padding-right:12px;
  height:42px;
  --el-menu-hover-text-color:var(--el-color-primary-dark-2);
  --el-menu-base-level-padding:10px;--el-menu-icon-width:10px;"
  :close-on-click-outside="true"
   text-color="#666">
    <el-menu-item index="0">
      <div style="font-weight: bold;font-size:20px;"><slot name="title" >单据标题</slot></div>
      <el-tag type="success" v-if="entity.id && entity.status=='1'" size="small">已审核</el-tag>
      <el-tag type="danger" v-if="entity.id && entity.status=='0'" size="small">待审核</el-tag>
    </el-menu-item>
    <div class="flex-auto" ></div>
    <el-menu-item index="1" v-if="entity.id" @click="onAddClick"><i class="iconfont icon-chuangjian margin-right-4" style="font-size:18px;"></i>新增</el-menu-item>
    <el-dropdown class="el-menu-item" v-if="entity.status!=1" split-button :loading="isSubmitting" @command="onSubmitClick(true)" @click="onSubmitClick(false)">
      <i class="iconfont icon-baocun1 margin-right-4" style="font-size:14px;" ></i>保存
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :loading="isSubmitting">保存并新增</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <el-sub-menu index="2" v-if="entity.status!=1" >
      <template #title><i class="iconfont icon-baocun1 margin-right-4" style="font-size:14px;" ></i>保存</template>
      <el-menu-item index="2-1"   @click="onSubmitClick(true)">保存</el-menu-item>
      <el-menu-item index="2-2"   @click="onSubmitClick(false)">保存并新增</el-menu-item>
    </el-sub-menu> -->
    <el-menu-item index="3" v-if="entity.id && entity.status=='0'"  @click="onVerifyClick()">
      <i class="iconfont icon-shenhe"></i>
      审核</el-menu-item>
    <el-menu-item index="4" v-if="entity.id && entity.status=='1'"  @click="onUnVerifyClick()">
      <i class="iconfont icon-fanshen"></i>反审
    </el-menu-item>
    <el-menu-item index="5" v-if="entity.id" @click="onCopyClick">
      <i class="iconfont icon-fuzhi"></i>复制
    </el-menu-item>
     <!-- 自定义操作按钮： 如 生成入库单 付款历史 -->
    <slot name="extra"></slot>
    <el-dropdown  @command="onAttechmentClick">
      <el-button >
        <i class="iconfont icon-a-fujian margin-right-4" style="font-size:14px;"></i> 附件<el-icon class="el-icon--right"><arrow-down /></el-icon>
        <i class="badge absolute right-top">3</i>
      </el-button>
      
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="upload">上传</el-dropdown-item>
          <el-dropdown-item command="view">查看</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown  @command="onPrintClick" trigger="click"  placement="bottom-end"  @click="onPrintClick('preview')" split-button :hide-on-click="false">
      打印
      <!-- <el-button style="padding-right:2px;">
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button> -->
      <template #dropdown>
        <el-dropdown-menu >
          <!-- <div class="padding">当前模版：{{ defaultTemplate? defaultTemplate.templateName:'请选择打印模版' }}</div> -->
          <div>
            <el-dropdown-item class="display-flex" style="column-gap:0px;"  @click="onTemplateChoose(item)" v-for="(item,index) in printTemplateList">
              <el-icon v-if="item.isDefault" color="var(--el-color-primary)"><Select /></el-icon>
              <el-icon v-else></el-icon>
              <div>{{item.templateName}}</div>
            </el-dropdown-item>
            <div class="padding-4 border-top align-center">
              <el-button link>模版设置</el-button>
            </div>
          </div>
          <!-- <el-dropdown-item >
            <el-dropdown  :visible-arrow="false" trigger="click" :hide-on-click="false" placement="top-start">
              <div>当前模版：{{ defaultTemplate? defaultTemplate.templateName:'请选择打印模版' }}<el-icon class="el-icon--right"><arrow-right /></el-icon></div>
              <template #dropdown>
                <el-dropdown-menu :visible-arrow="false" :hide-on-click="false">
                  <el-dropdown-item  @click="onTemplateChoose(item)" v-for="(item,index) in printTemplateList">
                    <el-icon v-show="item.isDefault"><Select /></el-icon>{{item.templateName}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-dropdown-item> -->
          <!-- <el-dropdown-item command="setting">模版设置</el-dropdown-item>  -->
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <el-sub-menu index="7">
      <template #title><i class="iconfont icon-weibiaoti--"></i>打印</template>
      <el-menu-item index="7-1">模版名称</el-menu-item>
      <el-menu-item index="7-2">模版设置</el-menu-item>
    </el-sub-menu> -->
    <el-menu-item index="8" class="no-padding" @click="onFirstClick" :disabled="!pageInfo.first">
      <i class="margin-left margin-right iconfont icon-first_page" style="font-size:20px;"></i>
    </el-menu-item>
    <el-menu-item index="9" class="no-padding" @click="onPrevClick" :disabled="!entity?.id || !pageInfo.prev" >
      <i class="margin-left margin-right iconfont icon-chevron_left" style="font-size:20px;"></i>
    </el-menu-item>
    <el-menu-item index="10" class="no-padding" @click="onNextClick" :disabled="!entity?.id || !pageInfo.next">
      <i class="margin-left margin-right iconfont icon-chevron_right" style="font-size:20px;" ></i>
    </el-menu-item>
    <el-menu-item index="11" class="no-padding" @click="onLastClick" :disabled="!pageInfo.last">
      <i class="margin-left margin-right iconfont icon-last_page" style="font-size:20px;"></i>
    </el-menu-item>
    <el-menu-item index="12" class="no-padding" @click="onExistClick" v-if="showExist">
      <!-- 指定高度模式为弹窗模式，显示退出按钮 -->
      <i class="margin-left margin-right iconfont icon-tuichu" style="font-size:16px;"></i>
    </el-menu-item>
  </el-menu>
  <!-- <el-row type="flex" justify="space-between" v-if="1==2" style="column-gap:0px;border-bottom:1px solid #ecf5ff;background:#ecf5ff"
    class="padding-8 no-margin">
    <el-space>
      <span style="font-weight:bold;font-size:20px;">
        <slot name="title">单据标题</slot>
        <el-tag type="success" v-if="entity.id && entity.status=='1'">已审核</el-tag>
        <el-tag type="danger" v-if="entity.id && entity.status=='0'">待审核</el-tag>
      </span>
      <slot name="status"></slot>
    </el-space>
    <div class="display-flex" >
      <! -- 非新增状态允许新增操作 -- >
      <el-button v-if="entity.id" @click="onAddClick">新增</el-button>
      <! -- 新增按钮-已审核或查看状态下，支持点击新增按钮 -- >
      <el-dropdown v-if="entity.status!=1" split-button :loading="isSubmitting" @command="onSubmitClick(true)" @click="onSubmitClick(false)">
        保存
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :loading="isSubmitting">保存并新增</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      
      <el-button class="no-margin" type="success" @click="onVerifyClick()" v-if="entity.id && entity.status=='0'">审核</el-button>
      <el-button class="no-margin" type="danger" plain @click="onUnVerifyClick()" v-if="entity.id && entity.status == '1'">反审</el-button>
      
      <el-button class="no-margin" v-if="entity.id" @click="onCopyClick">复制</el-button>
       <! -- 自定义操作按钮： 如 生成入库单 付款历史 -- >
      <slot name="extra"></slot>
      <el-dropdown @command="onPrintClick" trigger="click">
        <el-button >
          打印<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="print"  v-for="(item,index) in firstPrintList" :key="index">{{item.templateName}}</el-dropdown-item>
            <el-dropdown-item command="preview" v-if="secondPrintList.length>0">
              <el-dropdown  :visible-arrow="false" @command="onPrintClick" placement="right-start" trigger="hover">
                <div>更多模版<el-icon class="el-icon--right"><arrow-right /></el-icon></div>
                <template #dropdown>
                  <el-dropdown-menu :visible-arrow="false">
                    <el-dropdown-item command="preview" v-for="(item,index) in secondPrintList">{{item.templateName}}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item command="setting">模版设置</el-dropdown-item> 
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown @command="onAttechmentClick">
        <el-badge :value="3" class="item">
          <el-button >
          附件<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        </el-badge>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="upload">上传附件</el-dropdown-item>
            <el-dropdown-item command="view">查看附件</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button circle    @click="onFirstClick" :disabled="!pageInfo.first" class="no-margin">
        <el-icon size="20" style="font-weight:bold;transform: rotate(90deg);"><Download /></el-icon>
      </el-button>
      <el-button circle @click="onPrevClick" :disabled="!entity?.id || !pageInfo.prev" class="no-margin">
        <el-icon size="20" style="font-weight:bold;"><Back /></el-icon>
      </el-button>
      <el-button circle @click="onNextClick" :disabled="!entity?.id || !pageInfo.next" class="no-margin">
        <el-icon size="20" style="font-weight:bold;"><Right /></el-icon>
      </el-button>
      <el-button circle @click="onLastClick" :disabled="!pageInfo.last" class="no-margin">
        <el-icon size="20" style="font-weight:bold;transform: rotate(-90deg);"><Download /></el-icon>
      </el-button>
    </div>
  </el-row> -->
</template>
<script >
import { EnumToolBarCommand } from '@/utils/enum_utils'
import {queryTemplateList,setDefaultTemplate} from '@/service/print_order/template'
export default {
  data(){
    return {
      printTemplateList:[]
    }
  },
  props:{
    pageInfo:{ //分页信息： 首页  末页  上页  下页

    },
    isSubmitting: false,
    entity:{ // 实体信息
      type:Object
    },
    billType:{
      type:String
    },
    showExist:{
      type: Boolean,
      default:false
    }
  },
  computed:{
    defaultTemplate:function(){
      // 默认模版
      let tpl = this.printTemplateList.find(item=>item.isDefault)
      return tpl
    }
  },
  async created(){
    // 加载单据模版
    if(this.billType){
      let res = await queryTemplateList('BILL_TEMPLATE',this.billType)
      if(res.data){
        this.printTemplateList = res.data
        // console.log('tpl list', this.printTemplateList)
      }
      
    }
  },
  methods:{
    onAddClick(){
      this.$emit('command',EnumToolBarCommand.NEW_BILL)
    },
    onSubmitClick(isNew){
      this.$emit('command', isNew? EnumToolBarCommand.SAVE_AND_NEW : EnumToolBarCommand.SAVE)
    },
    onVerifyClick(){
      this.$emit('command', EnumToolBarCommand.VERIFY)
    },
    onUnVerifyClick(){
      this.$emit('command', EnumToolBarCommand.UNVERIFY)
    },
    onAttechmentClick(command){
      if(command=='upload'){
        // 上传附件
        this.$emit('command', EnumToolBarCommand.UPLOAD_FILE)
      } else if(command=='view'){
        // 查看附件
        this.$emit('command', EnumToolBarCommand.VIEW_FILE)
      }
    },
    async onTemplateChoose(tpl){
      // 设置默认模版，并切换菜单选择
      this.printTemplateList.forEach(item=>item.isDefault=false)
      tpl.isDefault = true
      let res = await setDefaultTemplate({templateType:'BILL_TYPE_TEMPLATE',billType:this.billType,id:tpl.id})
      if(res.code != 200){
        this.$message.error(res.msg || '设置默认模版失败')
      }
    },
    onPrintClick(command){
      if(command=='print'){
        this.$emit('command', EnumToolBarCommand.PRINT)
      } else if(command=='preview'){
        if(!this.defaultTemplate){
          this.$message.error('请先选择打印模版')
          return
        }
        console.log('xxxxxxxxxxxxxxxxxx', this.defaultTemplate.id)
        this.$emit('command', EnumToolBarCommand.PRINT_PRVIEW,this.defaultTemplate.id)
      } if(command=='setting'){
        this.$emit('command', EnumToolBarCommand.PRINT_SETTING)
      }
    },
    onCopyClick(){ //复制
      this.$emit('command', EnumToolBarCommand.COPY)
    },
    onExistClick(){ //退出
      this.$emit('command', EnumToolBarCommand.EXIST)
    },
    onFirstClick(){
      this.$emit('command', EnumToolBarCommand.FIRST)
    },
    onPrevClick(){
      this.$emit('command', EnumToolBarCommand.PREV)
    },
    onNextClick(){
      this.$emit('command', EnumToolBarCommand.NEXT)
    },
    onLastClick(){
      this.$emit('command', EnumToolBarCommand.LAST)
    },
  }
}
</script>