<!--
  包裹操作弹窗动态页
 -->
<template>
  <el-dialog
    v-model="dynamicVisible" destroyOnClose width="850" :title="title" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog1"
    @close="onCloseHandler"
  >
    <!-- 动态组件加载.. -->
    <component :is="dynamicSelector" v-bind="options"  @close="onCloseHandler" v-if="dynamicVisible"></component>
  </el-dialog>
</template>
<script>
  
  import {createAdvanceSelecorComponent} from './component.js'
  export default {
    name: "progress_dialog",
    components: {
    },
    data () {
      return {
        dynamicVisible:false,
        dynamicSelector:null,
        options:null,
        title:null,
        // packageList:null
      }
    },
    methods: {
      async showDialog(pageName,options,callback){// 显示高级弹窗选择
        this._callback = callback // 回调方法
        this.options = options // 列表
        this.title= options?.title
        this.dynamicSelector = await createAdvanceSelecorComponent(pageName)
        if(!this.dynamicSelector){
          // 创建组件失败，组件不存在
          console.error('编辑组件配置信息有误，请检查:' + pageName)
        }
        this.dynamicVisible = true
      },
      onCloseHandler(){
        this._callback && this._callback()
        this.dynamicVisible = false
      }
    }
  }
</script>