<template>
  <el-form :model="formModel" ref="frmEdit" label-width="100px">
    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <el-form-item prop="storeId" label="仓库" :rules="formValidator('required', '选择仓库')" >
          <nt-dropdown-grid configKey="store"  v-model="formModel.storeId"
            v-model:text="formModel.storeName" :disabled="viewOnly" placeholder="请选择仓库" :searchable="true"
            :iconSearch="false"  style="width:100%;"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item prop="goodsId" label="商品" :rules="formValidator('required', '选择商品')">
          <nt-dropdown-grid configKey="goods" v-model="formModel.goodsId"  v-model:text="formModel.goodsName" style="width:100%;"
            :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
            @choose="(goods) => onGoodsChange(goods)"
            ></nt-dropdown-grid>
        </el-form-item>
        <el-form-item prop="quantity" label="商品单位" :rules="formValidator('required', '选择单位')">
          <nt-dropdown-grid configKey="goodsUnit" :request-param="{goodsId:formModel.goodsId}" v-model="formModel.unitId" v-model:text="formModel.unitName"
        :disabled="viewOnly" placeholder="计量单位" :searchable="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item prop="goodsSkuId" label="商品SKU"  >
          <nt-dropdown-grid configKey="goodsSku" :request-param="{goodsId:formModel.goodsId}" v-model="formModel.goodsSkuId" v-model:text="formModel.sku"
        :disabled="viewOnly" placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item prop="quantity" label="数量" :rules="formValidator('required', '选择输入数量')">
          <el-input v-model="formModel.quantity" :disabled="viewOnly"></el-input>
        </el-form-item>
        <el-form-item prop="price" label="单价" :rules="formValidator('required', '选择输入单据')">
          <el-input v-model="formModel.price" :disabled="viewOnly"></el-input>
        </el-form-item>
      </el-main>
      <el-footer class="no-margin no-padding padding-top-16 height-auto align-right">
        <el-button @click="onCancelClick">取消</el-button>
        <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
      </el-footer>
    </el-container>
  </el-form>
  
</template>
<script>

  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import { EnumEntityType } from '@/utils/enum_utils'
  export default {
    name: "Store_INIT",
    mixins: [DataEditMixin],
    data () {
      return {
        billType: "REPOSITORY_STORE_INIT",
        entityType:EnumEntityType.STORE_FLOW  // 浏览特定位置单据时使用，bill_mixin
      }
    },
    props:{
      entityId:{
        type:String
      }
    },
    created () {
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.entityType, {id: this.entityId})
        }
      },
      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,billType: this.billType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        postData.taxRate=0
        postData.taxPrice = postData.price
        postData.costPrice = postData.price
        postData.costTotal = postData.quantity * postData.costPrice
        postData.ioType=1
        return this.saveEntity(this.entityType, postData)
      },
      onGoodsChange(goods) {// 单据头商品
        this.formModel.goods = goods
        this.formModel.unitId = goods.unitId
        this.formModel.unitName = goods.unitName
        this.formModel.goodsSkuId = null
        this.formModel.sku=null
        this.$forceUpdate()
      }
    }
  }
</script>