
<template>
  <el-container class="no-padding nt-height-100 nt-bg-white">
    <el-main class="no-padding nt-height-100" style="background-color: #f5f5f5;">
      <el-tabs v-model="activeTabName"  class="nt-tab-page nt-width-100 nt-height-100" >
        <el-tab-pane label="电商平台授权" name="commerce" lazy >
          <platform-commerce></platform-commerce>
        </el-tab-pane>
        <el-tab-pane label="物流平台授权" name="delivery" lazy >
          <platform-delivery></platform-delivery>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>
<script>

import PlatformCommerce from './platform_commerce'
import PlatformDelivery from './platform_delivery'
// import CustomTemplate from './custom'
export default {
  name: "third-auth",
  components: {
    PlatformCommerce,
    PlatformDelivery
  },
  data() {
    return {
      activeTabName:'commerce'
    }
  },
  
  created() {
    
  },
  methods: {
    
  }
}
</script>