<template>
  <el-container class="nt-height-100 relative" style="overflow:hidden;">
    <el-tooltip effect="dark" content="添加权限分组" placement="top-start">
      <el-button icon="Plus" @click="onAddClick" class="nt-float-button-add" circle></el-button>
    </el-tooltip>
    <!-- <el-header class="no-marign height-auto padding-4 border-bottom">
      <el-input size="small" class="flex-auto margin-right-4"></el-input>
    </el-header> -->
    <el-main class="padding" style="overflow:hidden;">
      <el-tree ref="refTree" class="nt-height-100 nt-list-node nt-no-tree-icon" nodeKey="id" style="overflow-y:auto;overflow-x: hidden;"
        :data="datasource"
        default-expand-all
        :props="props"
        :indent="0"
        @node-click="onNodeClick">
        <template #default="{ node, data }">
          <el-row justify="space-between" class="nt-width-100" align="middle">
            <span>{{ node.label }}</span>
            <el-space :size="5" class="nt-operation" v-if="data.id">
              <el-tooltip  effect="dark" content="权限设置" placement="top">
                <el-icon size="large" @click.stop="onRowRightClick(data)"><Setting /></el-icon>
              </el-tooltip>
              
              <el-icon size="large" @click.stop="onRowEditClick(data)"><Edit /></el-icon>
              <el-popconfirm title="确定删除?" @confirm.stop="onRowDeleteClick(data)">
                <template #reference>
                  <el-icon size="large" ><Delete /></el-icon>
                </template>
              </el-popconfirm>
            </el-space>
          </el-row>
        </template>
      </el-tree>
    </el-main>
  </el-container>
  
  <el-dialog v-model="isRightVisible" :close-on-click-modal="false" destroyOnClose width="750px" class="nt-resource-dialog" title="权限设置"
    @cancel="isRightVisible = false">
    <right-setting-page ref="modalForm" :roleId="currentRecord.id" @cancel="isRightVisible = false"
      v-if="isRightVisible"></right-setting-page>
  </el-dialog>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import RightSettingPage from '../right'
import detailPage from './detail/'
export default {
  name: "UserList",
  mixins: [DataListMixin],
  components: {
    RightSettingPage,
    detailPage
  },
  data() {
    return {
      isRightVisible: false,
      currentRow:null,
      props: {
        label: 'roleName'
      }
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.ROLE, Object.assign({}, params));
    },
    _loadFinished(){ //列表加载结束后默认显示显示第一个
      let firstNode = {roleName:'全部',id:null}
      this.datasource.splice(0,0,firstNode)
      setTimeout(_=>{
        this.$refs.refTree?.setCurrentNode(firstNode)
        this.onNodeClick(firstNode)
      },0)
    },
    onNodeClick(row){
      if(this.currentRow == row){
        return
      }
      this.currentRow = row
      this.$emit('change',row)
    },
    
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.ROLE, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onRowRightClick(record) {
      this.currentRecord = record
      this.isRightVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.ROLE, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>
