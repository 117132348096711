/**
 * 
 * 业务单据可配置字段列表
 */

/** 采购订单 */
export const PURCHASE_ORDER=[
    {
        title:'基础字段',
        children:[
            {
                label:'单据日期', // 显示文本
                prop:'=[billTime]', // 对象显示属性
                prefix:'单据日期:',
                type:'sys_text'
            },
            {
                label:'单据编码', // 显示文本
                prefix:'单据编码:',
                prop:'=[billNo]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'供应商', // 显示文本
                prefix:'供应商:',
                prop:'=[companyName]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'备注', // 显示文本
                prefix:'备注:',
                prop:'=[remark]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'制单人', // 显示文本
                prefix:'制单人:',
                prop:'=[createBy]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'制单时间', // 显示文本
                prefix:'制单时间:',
                prop:'=[createTime]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'预付款', // 显示文本
                prefix:'预付款:',
                prop:'=[depositTotal]', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'冲销款', // 显示文本
                prefix:'冲销款:',
                prop:'=[settleTotal]', // 对象显示属性
                type:'sys_text'
            },
        ]
    },
    {
        title:'其他',
        children:[
            {
                label:'页码', // 显示文本
                prop:'PAGE_NO', // 对象显示属性
                type:'sys_text'
            },
            {
                label:'当前时间', // 显示文本
                prop:'SYSTEM_TIME', // 对象显示属性
                type:'sys_text'
            },
        ]
    }
]
export const purchase_orderx = {
    header:[
        {
            label:'单据日期:', // 显示文本
            prop:'billTime', // 对象显示属性
            prefix:'单据日期',
            type:'sys_text'
        },
        {
            label:'单据编码:', // 显示文本
            prefix:'单据编码',
            prop:'billNo', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'供应商:', // 显示文本
            prefix:'供应商',
            prop:'companyName', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'备注:', // 显示文本
            prefix:'备注',
            prop:'remark', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'制单人:', // 显示文本
            prefix:'制单人',
            prop:'createBy', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'制单时间:', // 显示文本
            prefix:'制单时间',
            prop:'createTime', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'预付款:', // 显示文本
            prefix:'预付款',
            prop:'depositTotal', // 对象显示属性
            type:'sys_text'
        },
        {
            label:'冲销款:', // 显示文本
            prefix:'冲销款',
            prop:'settleTotal', // 对象显示属性
            type:'sys_text'
        },
    ],
    details:[
        {
            label:'商品名称--', // 显示文本
            prop:'goodsName', // 对象显示属性
        },
        {
            label:'单位', // 显示文本
            prop:'unitName', // 对象显示属性
        },
        {
            label:'规格型号', // 显示文本
            prop:'standard', // 对象显示属性
        },
        {
            label:'SKU', // 显示文本
            prop:'sku', // 对象显示属性
        },
        {
            label:'数量', // 显示文本
            prop:'quantity', // 对象显示属性
        },
        {
            label:'原价', // 显示文本
            prop:'originalPrice', // 对象显示属性
        },
        {
            label:'折扣', // 显示文本
            prop:'discount', // 对象显示属性
        },
        {
            label:'价格', // 显示文本
            prop:'price', // 对象显示属性
        },
        {
            label:'税率', // 显示文本
            prop:'taxtRate', // 对象显示属性
        },
        {
            label:'含税价', // 显示文本
            prop:'taxPrice', // 对象显示属性
        },
        {
            label:'总额', // 显示文本
            prop:'total', // 对象显示属性
        },
        {
            label:'总额-含税', // 显示文本
            prop:'totalWithTax', // 对象显示属性
        },
        {
            label:'备注', // 显示文本
            prop:'remark', // 对象显示属性
        },
    ]
}

// 发货单模版
export const DISPATCH_TEMPLATE = {
    header:[
        {
            label:'基本信息',
            list:[
                {
                    label:'市场', // 显示文本
                    prop:'=[marketName]', // 对象显示属性
                    prefix:'市场:',
                    type:'sys_text'
                },
                {
                    label:'订单号', // 显示文本
                    prop:'=[orderNo]', // 对象显示属性
                    prefix:'订单号:',
                    type:'sys_text',
                    useMode:['barcode','qrcode'] // 允许用着条码或二维码上
                },
                {
                    label:'运单号', // 显示文本
                    prop:'=[waybillNo]', // 对象显示属性
                    prefix:'运单号:',
                    type:'sys_text',
                    useMode:['barcode','qrcode'] // 允许用着条码或二维码上
                },
                {
                    label:'档口', // 显示文本
                    prop:'=[position]', // 对象显示属性
                    prefix:'档口:',
                    type:'sys_text'
                },
                {
                    label:'品种数量', // 显示文本
                    prefix:'品种数量',
                    prop:'=[goodsCount]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'采购数量', // 显示文本
                    prefix:'采购数量:',
                    prop:'=[quantity]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'采购总额', // 显示文本
                    prefix:'采购总额:',
                    prop:'=[total]', // 对象显示属性
                    type:'sys_text'
                }
            ]
        },
        
    ],
    details:[
        {
            label:'商品名称', // 显示文本
            prop:'goodsName', // 对象显示属性
            isDefault:true,
            width: 'auto'
        },
        {
            label:'规格', // 显示文本
            prop:'skuAttrs', // 对象显示属性
            isDefault:true,
            width:220
        },
        {
            label:'数量', // 显示文本
            prop:'quantity', // 对象显示属性
            isDefault:true,
            width:85
        },
        {
            label:'采购价', // 显示文本
            prop:'marketPrice', // 对象显示属性
            isDefault:true,
            width:85
        },
        {
            label:'总额', // 显示文本
            prop:'total', // 对象显示属性
            isDefault:true,
            width:85
        },
        
    ]
}


// 备货单-模版数据源
export const PICK_GOODS_TEMPLATE = {
    header:[
        {
            label:'基本信息',
            list:[
                {
                    label:'市场', // 显示文本
                    prop:'=[marketName]', // 对象显示属性
                    prefix:'市场:',
                    type:'sys_text'
                },
                {
                    label:'档口', // 显示文本
                    prop:'=[position]', // 对象显示属性
                    prefix:'档口:',
                    type:'sys_text'
                },
                {
                    label:'品种数量', // 显示文本
                    prefix:'品种数量',
                    prop:'=[goodsCount]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'采购数量', // 显示文本
                    prefix:'采购数量:',
                    prop:'=[quantity]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'采购总额', // 显示文本
                    prefix:'采购总额:',
                    prop:'=[total]', // 对象显示属性
                    type:'sys_text'
                }
            ]
        },
        
    ],
    details:[
        {
            label:'商品名称', // 显示文本
            prop:'goodsName', // 对象显示属性
            isDefault:true,
            width: 'auto'
        },
        {
            label:'规格', // 显示文本
            prop:'skuAttrs', // 对象显示属性
            isDefault:true,
            width:220
        },
        {
            label:'数量', // 显示文本
            prop:'quantity', // 对象显示属性
            isDefault:true,
            width:85
        },
        {
            label:'采购价', // 显示文本
            prop:'marketPrice', // 对象显示属性
            isDefault:true,
            width:85
        },
        {
            label:'总额', // 显示文本
            prop:'total', // 对象显示属性
            isDefault:true,
            width:85
        },
        
    ]
}


// 拿货标签模版-
export const PICKUP_TEMPLATE = {
    header:[
        {
            label:'基本信息',
            list:[
                {
                    label:'订单号', // 显示文本
                    prop:'=[orderNo]', // 对象显示属性
                    prefix:'',
                    demoText:'订单号',
                    type:'sys_text',
                    useMode:['barcode','qrcode'] // 允许用着条码或二维码上
                },
                {
                    label:'拣货码', // 显示文本
                    prop:'=[pickingCode]', // 对象显示属性
                    prefix:'',
                    demoText:'拣货码',
                    type:'sys_text'
                },
                {
                    label:'订单日期', // 显示文本
                    prefix:'',
                    demoText:'日期',
                    prop:'=[createTime]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'拣货条码', // 显示文本
                    prefix:'',
                    demoText:'拣货条码',
                    prop:'=[orderPackageId]', // 对象显示属性
                    type:'sys_text',
                    useMode:['barcode','qrcode'] // 允许用着条码或二维码上
                },
                {
                    label:'单/多', // 显示文本
                    prefix:'',
                    demoText:'单', // 设计器显示的控件文本，代替变量表达
                    prop:'=[goodsMode]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'囤货标记', // 显示文本
                    prefix:'',
                    demoText:'屯',
                    prop:'=[scanName]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'市场', // 显示文本
                    prefix:'',
                    demoText:'市场',
                    prop:'=[marketName]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'档口', // 显示文本
                    prefix:'',
                    demoText:'档口',
                    prop:'=[position]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'市场档口', // 显示文本
                    prefix:'',
                    demoText:'市场档口',
                    prop:'=[marketPosition]', // 对象显示属性
                    type:'sys_text'
                },
                
            ]
        },
        {
            label:'商品信息',
            list:[
                
                {
                    label:'商品名称', // 显示文本
                    prefix:'=',
                    demoText:'商品名称',
                    prop:'=[goodsName]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'商品编码', // 显示文本
                    prefix:'',
                    demoText:'商品编码',
                    prop:'=[goodsCode]', // 对象显示属性
                    type:'sys_text',
                    useMode:['barcode','qrcode'] // 允许用着条码或二维码上
                },
                {
                    label:'商品简称', // 显示文本
                    prefix:'',
                    demoText:'商品简称',
                    prop:'=[shortName]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'规格', // 显示文本
                    prefix:'',
                    demoText:'规格',
                    prop:'=[skuAttrs]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'规格简称', // 显示文本
                    prefix:'',
                    demoText:'规格简称',
                    prop:'=[skuAttrs]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'商品数量', // 显示文本
                    prefix:'',
                    demoText:'数量',
                    prop:'=[quantity]', // 对象显示属性
                    type:'sys_text'
                },
                {
                    label:'采购价', // 显示文本
                    prefix:'',
                    demoText:'采购价',
                    prop:'=[marketPrice]', // 对象显示属性
                    type:'sys_text'
                }
            ]
        },
        
    ],
    details:[
        // {
        //     label:'商品名称', // 显示文本
        //     prop:'goodsName', // 对象显示属性
        //     isDefault:true,
        //     width: 'auto'
        // },
    ]
}
