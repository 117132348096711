

 <template>
  <el-container class="no-padding" style="height:100%;">
    <el-header class="no-padding no-margin" style="height:auto;">
      <div style="font-weight:bold;font-size:20px;" class="margin-bottom">单据明细</div>
    </el-header>
    <el-main class="no-padding">
      <el-form ref="formRef" :model="config" label-position="top" size="small" >
        <el-form-item  prop="headerTextStyle" label="标题文本样式" >
          <TextStyleEditor v-model="config.headerTextStyle" />
        </el-form-item>
        <el-form-item >
          <div class="display-flex">
            <div style="width:120px;color:#666;">标题行高</div>
            <el-input v-model="config.titleHeight" >
              <template #suffix>毫米</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item   label="" >
          <div class="display-flex">
            <div style="width:120px;color:#666;">内容行高</div>
            <el-input v-model="config.contentHeight" >
              <template #suffix>毫米</template>
            </el-input>
          </div>
        </el-form-item>

        <el-form-item prop="rows" label="">
          <el-switch @change="onAutoHeightModeChange"
            v-model="config.autoHeight"
            active-text="高度自适应"
          />
          <div>开启后，表体内容将显示所用的明细，页面高度，可能会超出打印机的页面设置，高度自适应适合于热敏纸打印</div>
        </el-form-item>
        
        <el-form-item label="">
          <div class="display-flex">
            <div style="width:80px;color:#666;">边框样式</div>
            <el-radio-group v-model="config.borderWidth" size="small">
              <el-radio-button label="0px">无边框</el-radio-button>
              <el-radio-button label="1px">细</el-radio-button>
              <el-radio-button label="2px">粗</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="">
          <div class="display-flex" style="align-items: center;">
            <div style="width:80px;color:#666;">显示合计</div>
            <el-switch v-model="config.subtotal" />
            <el-tooltip effect="dark" placement="top" content="合计行样式设置">
              <el-icon size="14" style="cursor: pointer;" @click="subtotalStyleDialogVisible=true" v-if="config.subtotal"><Setting /></el-icon>
            </el-tooltip>
          </div>
        </el-form-item>
      </el-form>
      <div class="margin-top margin-bottom-4" style="font-size: 14px;color:#666;">打印字段</div>
      <el-table :data="config.columns" size="small" class="margin-bottom" :show-header="false" border>
        <el-table-column label="#" width="32">
          <template #default="{row,$index}">
            <el-space direction="vertical" size="small">
              <el-button size="small" :disabled="$index==0" class="no-padding" style="width:15px;height:15px" :icon="Top" circle @click="onMoveUpClick($index)" ><el-icon><Top /></el-icon></el-button>
              <el-button size="small" :disabled="$index==config.columns.length-1" class="no-padding" style="width:15px;height:15px" circle @click="onMoveDownClick($index)" ><el-icon><Bottom /></el-icon></el-button>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="列名" prop="label" class-name="no-padding">
          <template #default="{row}" >
            <div class="display-flex space-between">
              <div>{{ row.label }}</div>
              <div v-if="config.subtotal && ['AVG','COUNT','SUM'].includes(row.subtotalType) ">
                <i class="iconfont icon-gongshi1" style="color:var(--el-color-primary);font-size:14px;"><span style="font-size:10px;font-weight: bold;">{{row.subtotalType}}</span></i>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
  <el-dialog title="高级样式设置" class="nt-resource-dialog"
      v-model="subtotalStyleDialogVisible" destroyOnClose width="500px"
      :close-on-click-modal="false"
      @close="subtotalStyleDialogVisible=false" append-to-body
    >
    <subtotal-style-editor @success="onSubtotalStyleChange" :config="config.subtotalSetting" @cancel="subtotalStyleDialogVisible =false"  v-if="subtotalStyleDialogVisible"></subtotal-style-editor>
  </el-dialog>
 
</template>

<script>

import SubtotalStyleEditor from '../selector/bill_subtotal_style'
import TextStyleEditor from './text_style.vue'
import { useDispatchDesignerStore } from '../useDispatchDesignerStore'
export default {
  data () {
    return {
      store: useDispatchDesignerStore(),
      subtotalStyleDialogVisible:false,
    }
  },
  components:{TextStyleEditor,SubtotalStyleEditor},
  props:{
    config:{}
  },
  created(){
    //初始化合计样式高级设置
    this.config.subtotalSetting = this.config.subtotalSetting||{/*todo*/}
  },
  methods:{
    onMoveUpClick(index){
      let item = this.config.columns.splice(index, 1)[0]
      this.config.columns.splice(index-1, 0, item)
      this.config.columns = [...this.config.columns] // 强制变更内存地址，触发table组件的watch 事件 watched: 'config.columns'
    },
    onMoveDownClick(index){
      let item = this.config.columns.splice(index, 1)[0]
      this.config.columns.splice(index+1, 0, item)
      this.config.columns = [...this.config.columns] // 强制变更内存地址，触发table组件的watch 事件 watched: 'config.columns'
    },
    onSubtotalStyleChange(config){
      this.config.subtotalSetting = config
      this.subtotalStyleDialogVisible =false
    },
    onAutoHeightModeChange(isAutoHeight){
      // console.log('onAutoHeightModeChange',)
      if(isAutoHeight){ // 切换为高度自适应时，强制将表体高度置为固定值，标题行+ 示意行
        // this.store.page.bodyHeight= 2 //mm
        // let gap = this.store.page.bodyHeight - 25
        // this.store.page.footerHeight += gap // 触发 dispath 监控事件
      }
    }
  }
}
</script>