<!-- 打单业务基础设置 -->
<template>
  <el-container class="padding nt-height-100">
    <el-aside width="200px" class="margin-right-8 nt-box-panel nt-bg-white">
      <el-menu
        :default-active="defaultMenu"
        class="nt-height-100 no-border"
      >
        <el-menu-item :index="item.key" v-for="item in options" @click="onPageChangeClick">
          <el-icon></el-icon>
          <span>{{item.label}}</span>
        </el-menu-item>
        
      </el-menu>
    </el-aside>
    <el-main class=" padding nt-box-panel">
      <component :is="dynamicPage"></component>
    </el-main>
  </el-container>
</template>
<script>
import {markRaw} from 'vue'
import BindingShopPage from './detail/binding_shop'
import PrintHistoryPage from './detail/print_history'
import SendHistoryPage from './detail/send_history'
import ScanSendPage from './detail/scan_send'
import AutoPrintPage from './detail/auto_print'
import AbnormalPage from './detail/abnormal_order'
import OrderLogPage from './detail/order_log'
import LoginLogPage from './detail/login_log'
import TaskHistoryPage from './detail/task_history'
import WaybillHistoryPage from './detail/waybill_history'
import ScanPicingCodePage from './detail/scan_picking_code'
import PreDispatchPage from './detail/pre_dispatch'
const _page_container = {
  'BindingShopPage': markRaw(BindingShopPage),
  'PrintHistoryPage': markRaw(PrintHistoryPage),
  'SendHistoryPage': markRaw(SendHistoryPage),
  'ScanSendPage': markRaw(ScanSendPage),
  'AutoPrintPage': markRaw(AutoPrintPage),
  'AbnormalPage': markRaw(AbnormalPage),
  'OrderLogPage': markRaw(OrderLogPage),
  'LoginLogPage': markRaw(LoginLogPage),
  'TaskHistoryPage': markRaw(TaskHistoryPage),
  'WaybillHistoryPage': markRaw(WaybillHistoryPage),
  'ScanPicingCodePage': markRaw(ScanPicingCodePage),
  'PreDispatchPage': markRaw(PreDispatchPage)
}
export default {
  name: "print_tool",
  components: {
  },

  data() {
    return {
      dynamicPage:null,
      defaultMenu:null,
      options:[
        {label:'预发货',key:'PreDispatchPage'},
        {label:'扫描发货',key:'ScanSendPage'},
        {label:'扫描拿货标签',key:'ScanPicingCodePage'},
        {label:'面单查询',key:'WaybillHistoryPage'},
        {label:'打印记录',key:'PrintHistoryPage'},
        {label:'发货记录',key:'SendHistoryPage'},
        {label:'异常订单',key:'AbnormalPage'},
        {label:'店铺绑定',key:'BindingShopPage'},
        // {label:'导入订单发货',key:'8'},
        // {label:'快递对账单',key:'9'},
        // {label:'单号共享',key:'10'},
        // {label:'任务中心',key:'TaskHistoryPage'},
        // {label:'商品报表汇总',key:'12'},
        // {label:'订单操作日志',key:'OrderLogPage'},
        // {label:'登录日志',key:'LoginLogPage'},
      ]

    }
  },
  mounted(){
    this.defaultMenu=this.$route.meta.key
    this.dynamicPage = _page_container[this.defaultMenu]
  },
  methods: {
    onPageChangeClick(cmd){
      this.dynamicPage = _page_container[cmd.index]
    }
  }
}
</script>
<style lang="less" scoped>
  :deep(.el-menu-item){
    padding:0px;
  }
  :deep(.el-menu-item.is-active){
    background:var(--el-color-primary-light-9);
    border-left:5px solid var(--el-color-primary);
  }
</style>