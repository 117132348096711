<!-- 订单排序规则设置 -->
<template>
  <el-container class="nt-height-100">
    
    <el-main class=" padding nt-box-panel">
      <div>
        <el-radio-group v-model="config.isOpen" style="display:inline-grid;">
          <el-radio value="0">不使用过滤词</el-radio>
          <el-radio value="1">使用过滤词</el-radio>
          
        </el-radio-group>
        <div>
          <nt-tag-input v-model="config.words"></nt-tag-input>
        </div>
        <div>
          <el-button type="primary" @click="onSaveClick">保存</el-button>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import {getTenantKvByConfigKey,saveTenantKv} from '@/service/resource/general'
import NtTagInput from '@/views/plugin/nt_tag_input'
export default {
  name: "IMPORT_SETTING",
  components:{NtTagInput},
  data() {
    return {
      config:{isOpen:false,words:null},
    }
  },
  async created(){
    let res = await getTenantKvByConfigKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'WORD_MASK'})
    if (res.code == 200) {
      if (res.data) {
        this.config = JSON.parse(res.data.configValue||'{}')
      }
    } else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    async onSaveClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'WORD_MASK', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>