<template>
  <div class="padding" style="height:450px;">
    <el-container class="padding nt-box-panel nt-bg-white nt-height-100">
      <el-header class="no-padding no-margin height-auto">
        <nt-select-shop placeholder="请选择店铺"  v-model="shop"  style="width:260px;" @defaultShop="onShopChange"
                    @change="onShopChange"></nt-select-shop>
      </el-header>
      <el-main class="no-padding margin-top-8">
        <el-table height="100%" border width="100%" highlight-current-row ref="table"  stripe class="nt-resource-table"
          :data="datasource" @row-click="onRowClick" v-loading="isLoading">
          <el-table-column label="#" width="45" type="index" fixed="left" />
          <el-table-column label="addressId" width="140" prop="addressId"></el-table-column>
          <el-table-column label="联系人" width="140" prop="userName"></el-table-column>
          <el-table-column label="联系电话" width="120" prop="telNumber"></el-table-column>
          <el-table-column label="地址" >
            <template #default="{row}">
              <div>{{ row.provinceName }}-{{ row.cityName }}</div>
              <div>{{ row.detailInfo }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="end">
          <div>
            <el-button @click="onCancelClick" type="info">取消</el-button>
            <el-button type="primary" :disabled="!selectedRow" @click="onConfirmClick">确定</el-button>
          </div>
        </el-row>
      </el-footer>
    </el-container>
  </div>


</template>
<script>
import NtSelectShop from '@/views/plugin/nt_select_shop'
import {getAddressFromWxPlatform} from '@/service/resource/platform_shop'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import {EnumDelivery} from '@/utils/enum_utils'
export default {
  name: "print_address_selector",
  mixins: [DataListMixin],
  components: {
    NtSelectShop
  },
  data() {
    return {
      selectedRow: null,
      shop:null,
    }
  },
  props: {
  },
  methods: {
    async _loadList(params) {
      if(!this.shop){
        return
      }
      let res =  await getAddressFromWxPlatform(Object.assign({shopId:this.shop.shopId},params))
      if(res.code == 200){
        
        return res
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
        return null
      }

    },
    onShopChange(shop){
      if(this.shop?.shopId == shop.shopId){
        return
      }
      this.shop = shop
      this.loadList()
    },
    formatDelivery(deliveryId){
      return EnumDelivery[deliveryId] || deliveryId
    },
    onCancelClick() {
      this.$emit('cancel')
    },
    onConfirmClick() {
      // 根据单选或复选 返回单个对象或集合
      this.$emit('choose', this.selectedRow)
    },
    onRowClick(row) {
      this.selectedRow = row
    }
  }
}
</script>
