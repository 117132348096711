
/*
    npm install vue@2 pinia  
    vue 2 pinia， 主要为了兼容，vue时使用pina
 */
import { defineStore } from 'pinia'
// import { ref, computed } from 'vue';

export const usePageDesignerStore = defineStore('pageDesigner',{
    state: _ => {
        const page = {
            items:[]
        }
        const current = null
        return {current,page}
    }
    // getters:{
    //     getPage:
    // },
    // actions:{
        
    // }
})
