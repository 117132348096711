<template>
  <el-dialog title="卖家备注" v-model="isVisible" :close-on-click-modal="false" width="350" :confirm-loading="isSubmitting" :destroyOnClose="true">
    <el-input type="textarea" v-model="remark" :rows="4"></el-input>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" :loading="isSubmitting">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script >

import { updateMerchantNotes} from '@/service/print_order/order'
export default {
  data() {
    return {
      isVisible:false,
      isSubmitting:false,
      remark:null,
    }
  },
  emits:['success'],
  methods: {
    show(orderIds){
      this.remark=null
      this.isVisible=true
      this.orderIds = orderIds
    },
    async onConfirmClick(){
      this.isSubmitting = true
      let res = await updateMerchantNotes({
        list: this.orderIds,
        remark: this.remark
      })

      this.isSubmitting = false
      if (res.code === 200) {
        this.$message.success("操作成功")
        this.$emit('success', res.data, this.remark)
        this.isVisible = false
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
