<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="height-auto margin-bottom no-padding" >
        <el-space>
          <el-button  type="danger" @click="stockPeriodVisible=true">存货结转</el-button>
        </el-space>
      </el-header>
      <el-main class="no-padding">
        <el-table :data="datasource" border height="100%" layout="auto" >
          <el-table-column label="#" width="35" type="index"></el-table-column>
          <el-table-column label="结转周期" width="120" prop="period"></el-table-column>
          <el-table-column label="开始时间" width="120" prop="startTime"></el-table-column>
          <el-table-column label="结束时间" width="120" prop="endTime"></el-table-column>
          <el-table-column label="操作时间" width="120" prop="createTime"></el-table-column>
          <el-table-column label="操作人" width="120" prop="createBy"></el-table-column>
          
          <el-table-column width="120"  align="center">
            <template #default="scope">
              <el-popconfirm   title="确定反结转当前选中周期?" @confirm="() => onUnsettleClick(record,false)" >
                <a  size="small" type="link">反结转</a>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="height-auto no-padding margin-top">
      <el-row justify="end">
        <el-pagination
          :current-page="this.pageInfo.pageIndex"
          :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="this.pageInfo.total"
          background 
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="onPageIndexChange"
          @size-change="onPageSizeChange"
        />
      </el-row>
    </el-footer>
      <el-dialog v-model="stockPeriodVisible" destroyOnClose width="500px"
        class="nt-resource-dialog" :close-on-click-modal="false"
        title="期末结转"
        @cancel="stockPeriodVisible=false"
      >
        <StockPeriodSettle  @success="onSettleSuccessHandler" @cancel="stockPeriodVisible =false" v-if="stockPeriodVisible"></StockPeriodSettle>
      </el-dialog>
    </el-container>
    
</template>

<script>
  import {getStockPeriodList,unsettle} from '@/service/stock_period'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import StockPeriodSettle from '@/views/financial/month_settle/settle'
  export default {
    name: 'monthSettleList',
    mixins:[DataListMixin],
    components: {
      StockPeriodSettle
    },
    data () {
      return {
        // 表头
        stockPeriodVisible:false,
      }
    },
    
    methods: {
      async _loadList(params){
        return await getStockPeriodList(Object.assign({},params))
      },
      async onUnsettleClick(record){
        let res = await unsettle({period:record.period})
        if(res.code ==200){
          this.$message.success("反结转成功")
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      },
      onSettleSuccessHandler(){
        this.stockPeriodVisible=false
        this.loadList()
      }
    }
  }
</script>