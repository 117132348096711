<!-- 订单排序规则设置 -->
<template>
  <el-container class="nt-height-100">

    <el-main class=" padding nt-box-panel">
      <div>
        <el-radio-group v-model="checkFlag" style="display:inline-grid;">
          <el-radio value="PAYMENT_TIME_ASC">按订单付款时间，先付款的在前边</el-radio>
          <el-radio value="PAYMENT_TIME_DESC">按订单付款时间，后付款的在前边</el-radio>
          <el-radio value="CREATE_TIME_ASC">按订单下单时间，先下单的在前边</el-radio>
          <el-radio value="CREATE_TIME_DESC">按订单下单时间，后下单的在前边</el-radio>
          <el-radio value="GOODS_COUNT_ASC">按订单商品总数量，数量少的在前边</el-radio>
          <el-radio value="GOODS_COUNT_DESC">按订单商品总数量，数量多的在前边</el-radio>
          <el-radio value="GOODS_TITLE">按订单商品标题，相同的商品排列在一起</el-radio>
          <el-radio value="GOODS_ATTR">按订单销售属性排序</el-radio>
          <el-radio value="GOODS_ID">按订单商品编码排序</el-radio>
          <el-radio value="GOODS_COUNT_DESC_AND_PAYMENT_TIME_ASC">按订单商品总数量，数量多的在前边；其次按订单付款时间，先付款的在前边</el-radio>
          <el-radio value="PAYTOTAL_DESC">按照付款金额排序，金额大的在前边</el-radio>
          <el-radio value="PAYTOTAL_ASC">按照付款金额排序，金额小的在前边</el-radio>
          <el-radio value="SHOP_CREATE_TIME_ASC">按店铺订单排序，先下单的在前边</el-radio>
          <el-radio value="SHOP_CREATE_TIME_DESC">按店铺订单排序，后下单的在前边</el-radio>

          <el-radio value="SHOP_NICKNAME">按店铺订单排序，其次按买家昵称排序</el-radio>
        </el-radio-group>
      </div>
      <div>
        <el-button type="primary" @click="onSaveClick">保存</el-button>
      </div>
    </el-main>
  </el-container>
</template>
<script>


import { getTenantKvByConfigKey, saveTenantKv } from '@/service/resource/general'
export default {
  name: "IMPORT_SETTING",
  components: {

  },

  data() {
    return {
      checkFlag: null
    }
  },
  async created() {
    let res = await getTenantKvByConfigKey({ parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'ORDER_SORT_MODE' })
    if (res.code == 200) {
      if (res.data) {
        this.checkFlag = res.data.configValue
      }
    } else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    async onSaveClick() {
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'ORDER_SORT_MODE', configValue: this.checkFlag }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>