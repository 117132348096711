<!-- 自动打印 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin">
      <div>
        使用说明：<br/>
        1.打印发货一体化：全程自动打印，无需手动勾选订单。<br/>
        2.支持设置打印完成后自动发货，提高效率解放您的双手。<br/>
        注意：使用自动打印功能需要一直停留在当前页面，如果离开，自动打印功能会自动关闭。
      </div>
      <div>
        <el-space>
          <el-switch
            v-model="value"
            size="large"
            active-text="开"
            inactive-text="关"
          />
          <span>快递单模版</span>
          <el-select placeholder="打印模版" style="width:120px;">
            <el-option value="1">模版1</el-option>
            <el-option value="2">模版2</el-option>
          </el-select>
          <el-select placeholder="打印机" style="width:120px;">
            <el-option value="1">打印机1</el-option>
            <el-option value="2">打印机2</el-option>
          </el-select>
          <el-checkbox> 打印后自动发货</el-checkbox>
        </el-space>
        <br/>
        <el-space>
          <el-switch
            v-model="value"
            size="large"
            active-text="开"
            inactive-text="关"
          />
          <span>发货单模版</span>
          <el-select placeholder="打印模版" style="width:120px;">
            <el-option value="1">模版1</el-option>
            <el-option value="2">模版2</el-option>
          </el-select>
          <el-select placeholder="打印机" style="width:120px;">
            <el-option value="1">打印机1</el-option>
            <el-option value="2">打印机2</el-option>
          </el-select>
          <el-checkbox> 打印后自动发货</el-checkbox>
        </el-space>
      </div>
      <div>
        <el-space>
          <span>发货人</span>
          <el-select placeholder="发货人地址" style="width:420px;">
            <el-option value="1">发货人地址1</el-option>
            <el-option value="2">发货人地址2</el-option>
          </el-select>
        </el-space>
      </div>
      <div>
        <div>设置条件（满足以下条件的订单，系统会自动打印）</div>
        <div>
          <el-select placeholder="订单来源" style="width:120px;">
            <el-option value="1">微信电</el-option>
            <el-option value="2">手工单</el-option>
          </el-select>
          <el-select placeholder="订单类型" style="width:120px;">
            <el-option value="1">普通订单</el-option>
            <el-option value="2">货到付款订单</el-option>
          </el-select>
          <el-select placeholder="下单时间" style="width:120px;">
            <el-option value="1">下单30分钟</el-option>
            <el-option value="2">下单5分钟</el-option>
            <el-option value="3">下单1小时</el-option>
            <el-option value="4">下单2小时</el-option>
            <el-option value="5">下单3小时</el-option>
          </el-select>
        </div>
        <div>
          留言备注 <el-checkbox>无留言备注</el-checkbox>
        </div>
        <div>
          旗帜  <el-checkbox>旗帜1</el-checkbox> <el-checkbox>旗帜2</el-checkbox>
        </div>
      </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel">
      <el-table :data="[{}]" height="100%">
        <el-table-column label="买家昵称"></el-table-column>
        <el-table-column label="数量"></el-table-column>
        <el-table-column label="收件人"></el-table-column>
        <el-table-column label="收件地址"></el-table-column>
        <el-table-column label="订单号"></el-table-column>
        <el-table-column label="订单来源"></el-table-column>
        <el-table-column label="快递模版"></el-table-column>
        <el-table-column label="快递单号" width="100">
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>
<script>


export default {
  name: "BIZ_COMPANY",
  components: {
    
  },

  data() {
    return {
      radio1:null,
      value:null
    }
  },
  methods: {
  }
}
</script>