
<template>
  <el-drawer v-model="isVisible" destroyOnClose size="850" title="包裹详情" class="nt-inner-drawer" :z-index="99"
    @close="isVisible = false">
    <el-container class="padding nt-height-100 nt-bg-page" v-loading="isLoading">
      <el-header class="no-margin no-padding height-auto">
        <div class="margin-bottom"> 快递:{{ waybill?.deliveryId }}  运单号{{waybill.waybillNo}}({{ waybill?.waybillStatus }}) </div>
        <div class="display-flex">
          <el-card style="flex:1" class="custom-card" header="发货人">
            <div>{{ waybill?.senderName }}-{{ waybill?.senderTel }}</div>
            <div>{{ waybill?.senderAddress }}</div>
          </el-card>
          <div style="width:20px;align-self:center;">
            <el-icon size="20" color="var(--el-color-primary)">
              <Right />
            </el-icon>
          </div>
          <el-card style="flex:1" class="custom-card" header="收件人">
            <div>{{ waybill?.buyerName }}-{{ waybill?.buyerTel }}</div>
            <div>{{ waybill?.buyerAddress }}</div>
          </el-card>
        </div>
      </el-header>
      <el-main class="no-padding margin-top">
        <el-table  border ref="table" size="small" class="nt-resource-table" :data="waybill?.platformOrderPackageList">
          <el-table-column label="#" width="50" type="index">
          </el-table-column>
          <el-table-column label="商品" prop="goodsName"></el-table-column>
          <el-table-column label="规格" with="100" >
            <template #default="{row}">
              {{ formatSkuAttr(row.orderItem.skuAttrs) }}
            </template>
          </el-table-column>
          <el-table-column label="数量" width="120" prop="quantity"></el-table-column>
        </el-table>
        <el-descriptions title="订单详情" class="nt-custom padding margin-top nt-box-panel" >
          <el-descriptions-item label="订单来源">{{waybill.platformOrder?.platform}}</el-descriptions-item>
          <el-descriptions-item label="店铺名称">{{waybill.platformOrder?.shopId}}</el-descriptions-item>
          <el-descriptions-item label="订单编号">{{waybill.platformOrder?.orderNo}}</el-descriptions-item>
          <el-descriptions-item label="支付时间">{{waybill.platformOrder?.payTime}}</el-descriptions-item>
          <el-descriptions-item label="交易金额">{{(waybill.platformOrder?.payTotal/100.0).toFixed(2)}}</el-descriptions-item>
          <el-descriptions-item label="买家留言">{{waybill.platformOrder?.buyerRemark}}</el-descriptions-item>
          <el-descriptions-item label="卖家备注">{{waybill.platformOrder?.merchantRemark}}</el-descriptions-item>
        </el-descriptions>

      </el-main>
    </el-container>
  </el-drawer>
  
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getWaybill, cancelWaybill } from '@/service/print_order/platform_waybill'
export default {
  name: "PackageDetail",
  mixins: [DataListMixin],
  components: {
  },
  data() {
    return {
      waybill: {},
      isVisible:false
    }
  },
  props: {
  },
  async mounted() {
    
  },
  methods: {
    async show(orderId,waybillNo) {

      this.isVisible = true
      this.isLoading = true
      let res = await getWaybill({ orderId: orderId, waybillNo: waybillNo });
      this.isLoading = false
      if (res.code == 200) {
        this.waybill = res.data
      }
    },
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    
    async onCancelWaybillClick() {
      let res = await cancelWaybill({ id: this.waybill.id }); // 批量接口
      if (res.code == 200) {
        if(res.data?.failCount==0){
          this.$message.success('回收成功')
        } else{
          this.$message.error(res.data.resultList[0].message)
        }
      } else {
        this.$message.error(res.msg || '回收失败')
       
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .nt-custom{
    :deep(.el-descriptions__body){
      padding:8px;
    }
    &.el-drawer{
      :deep(.el-drawer__header){
        margin-bottom: 4px !important;
      }
    }
    .el-drawer__header{
      margin-bottom: 4px !important;
    }
    
  }
  .custom-card{
    :deep(.el-card__header){
      padding:8px;
    }
    :deep(.el-card__body){
      padding:8px;
    }
  }
  
</style>