<template>
  <el-container class="no-padding" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="单据编号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <el-input placeholder="请输入单据编号查询" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
        </el-form-item>

      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" rowKey="id" 
      :data="datasource" v-loading="isLoading"
      @selection-change = "onSelectionChangeHandler"
      >
        <el-table-column type="selection" fixed="left" width="35"></el-table-column>
        <!-- <el-table-column label="单据类型" prop="billType" width="100">
          <template #default="{row}">
            {{EnumBillType[row.billType] || row.billType}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="单据编码" width="165" prop="billNo">
          <template #default="{row}">
            <el-space class="nt-custom left-top scale-8">
              <el-tag type="primary">{{EnumBillType[row.billType] || row.billType}}</el-tag>
              <el-tag type="info"  v-if="row.status == '1' && row.paymentStatus == 0" size="small"> 未付款</el-tag>
              <el-tag type="warning" v-if="row.status == '1' && row.paymentStatus == 1"> 部分付款</el-tag>
              <el-tag type="success"  v-if="row.status == '1' && row.paymentStatus == 2"> 已付款</el-tag>
            </el-space>
            <el-text truncated line-clamp="1" size="small">{{ row.billNo}}</el-text>
          </template>
        </el-table-column> -->
        <nt-bill-no-column label="单据编码" width="165" idName="id" show-bill-type>
          <template #extra="{row}">
            <!-- <el-tag type="primary">{{EnumBillType[row.billType] || row.billType}}</el-tag> -->
              <el-tag type="info"  v-if="row.status == '1' && row.paymentStatus == 0" size="small"> 未付款</el-tag>
              <el-tag type="warning" v-if="row.status == '1' && row.paymentStatus == 1"> 部分付款</el-tag>
              <el-tag type="success"  v-if="row.status == '1' && row.paymentStatus == 2"> 已付款</el-tag>
          </template>
        </nt-bill-no-column>
        <el-table-column label="单据日期" width="110" align="center" prop="billTime">
          <template #default="{row}">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="付款时间" width="110" align="center" prop="planTime">
          <template #default="{row}">
            <span v-if="row.planTime">{{ formatShortDate(row.planTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单据总额" prop="billTotal" width="90" align="right">
          <template #default="{row}">
            {{ row.billTotal }}<el-tag class="absolute left-top" size="small" type="danger" v-if="['PURCHASE_BACK','SALE_BACK'].includes(row.billType)">抵</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="折扣金额" prop="discountTotal" width="90" align="right">
        </el-table-column>
        <el-table-column label="原订单可核销预付款" prop="orderDepositTotal" width="120" header-align="center" align="right">
          <template #header>
            <span>可冲销预付</span>
              <el-tooltip content="采购入库引用原订单时，存在预付款时，此预付款可直接冲销采购入库单" style="width:160px;" placement="top-start" >
                <el-icon><QuestionFilled /></el-icon>
              </el-tooltip>
          </template>
          <template #default="{row}">
            {{ (row.orderDepositTotal||0) - (row.depositOffTotal||0) }}
            <el-tag v-if="row.orderDepositTotal" type="danger" size="small" class="absolute left-top">可核销预付</el-tag>
          </template>
          
        </el-table-column>
        <el-table-column label="已核销" prop="settleTotal" width="90" align="right">
        </el-table-column>
        <el-table-column label="未核销" width="90" align="right">
          <template #default="{ row }">
            <span v-if="['PURCHASE_BACK','SALE_BACK'].includes(row.billType)">-</span>{{ (row.billTotal - (row.settleTotal || 0)).toFixed(2) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="制单人" prop="userName" width="120"></el-table-column> -->
        <!-- <el-table-column label="付款状态" prop="paymentStatus" width="100" align="center">
          <template #default="{row}">
            <el-tag type="danger"  v-if="row.paymentStatus == 0"> 未付款</el-tag>
            <el-tag type="warning" v-if="row.paymentStatus == 1"> 部分付款</el-tag>
            <el-tag type="success"  v-if="row.paymentStatus == 2"> 已付款</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="备注" prop="remark">
          <template #default="{row}">
            <el-text truncated line-clamp="1">{{ row.remark}}</el-text>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top" style="margin-top:12px;height:auto;">
      <el-row type="flex" justify="space-between">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" size="small" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :disabled="!selectionRows.length"  @click="onConfirmClick">确定{{`(${selectionRows.length})`}}</el-button>
          </el-space>
      </el-row>
      
    </el-footer>
  </el-container>
</template>

<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: 'TradeBillList',
  mixins: [DataListMixin],
  components: {
    NtDateRange
  },
  data() {
    return {
      selectionRows: [],
    }
  },
  props: {
    billType: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: true
    }
  },
  created() {
  },
  emits:['choose','cancel'],
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({ companyId: this.companyId, paymentStatus:'0,1', status: "1", billType: this.billType }, params, this.queryModel))
    },
    _loadFinished() {
    },
    onCancelClick() {
      this.$emit('cancel');
    },
    
    onSelectionChangeHandler(rows) {
      this.selectionRows = rows;
    },
    onSearchQueryClick() { },
    onConfirmClick() {
      if(this.selectionRows.length<=0){
        this.$message.error('请选择单据')
        return
      }
      
      this.$emit('choose', this.selectionRows);
    },
    onSearchResetClick() {
      this.queryModel = {
        type: this.queryModel.type,
        subType: this.queryModel.subType
      }
      this.resetPage=true
      this.loadList();
    },
    
  }
}
</script>
