<template>
  <el-container :class="`nt-height-100  ${['PICKUP','DISPATCH'].includes(templateType)? 'nt-box-panel':''}`">
    <el-aside width="260px" class="nt-box-panel margin-right-12 padding-8 nt-bg-white" v-if="templateType=='BILL_TYPE_TEMPLATE'">
      <nt-dict-tree  @change="onBillTypeChange" parent-key="SYS_BILL_TYPE"></nt-dict-tree>
    </el-aside>
    <el-main class="padding nt-box-panel">
      <el-container class="no-padding nt-height-100">
        <el-header class="display-flex no-padding align-right height-auto space-between">
          <div style="font-weight:bold;font-size:14px;">{{ billType? billType:'请选择单据类型' }}</div>
          <div>
            <el-button :disabled="!billType" @click="onAddCllick" type="primary">新建模版</el-button>
            <el-button :disabled="!billType"  @click="onSystemTemplateChooseClick" type="primary">选择系统模版</el-button>
          </div>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table :data="datasource" height="100%" border stripe class="nt-resource-table" v-loading="isLoading">
            <el-table-column label="模版名称" prop="templateName">
              <template #default="{row}">
                <div>{{ row.templateName }}</div>
                <el-tag class="right-top" type="primary" v-if="row.isDefault">默认</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="业务类型" prop="billType" width="200" v-if="templateType=='BILL_TYPE_TEMPLATE'"></el-table-column>
            <el-table-column label="纸张类型" prop="pageHeight" width="200">
              <template #default="{row}">
                {{ row.pageWidth}}(mm) x {{ row.pageHeight }}(mm)
              </template>
            </el-table-column>
            <el-table-column label="默认打印机" width="200" prop="printerName">
              <template #default="{ row }">
                <nt-printer v-model="row.printerName" placeholder="请选择默认打印机"
                  @change="val => onPrinterChangeHandler(val, row)">
                </nt-printer>
              </template>
            </el-table-column>
            <!-- <el-table-column label="默认发货地址" width="160"></el-table-column> -->
            <el-table-column label="状态" prop="isEnabled" width="65">
              <template #default="{row}">
                <el-tag type="success" size="small" v-if="row.isEnabled">正常</el-tag>
                <el-tag type="danger" size="small" v-else>停用</el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column label="是否默认"  width="100" prop="isDefault">

            </el-table-column> -->
            <el-table-column label="操作" prop="title"  width="180">
              <template #default="{row}">
                <el-space :size="4" class="margin-top-4">
                  <el-button link type="primary" @click="onDesignClick(row)" size="small">设计</el-button>
                  <el-button link type="primary" @click="onRowEnabledClick(row,false)" size="small" v-if="row.isEnabled" >停用</el-button>
                  <el-button link type="primary" @click="onRowEnabledClick(row,true)" size="small" v-else >启用</el-button>
                  <el-button link  type="primary"  size="small" v-if="!row.isDefault" @click="onRowSetDefaultClick(row)" >设为默认</el-button>
                  <el-popconfirm title="是否确认删除当前模板?" width="220" @confirm="onDeleteClick(row)">
                    <template #reference>
                      <el-button link  type="danger"  size="small" >删除</el-button>
                    </template>
                  </el-popconfirm>
                  
                </el-space>
                
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="margin-top no-padding height-auto">
          <el-row type="flex" justify="end">
            <el-pagination
              :current-page="this.pageInfo.pageIndex"
              :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]"
              :total="this.pageInfo.total"
              size="small"
              background 
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="onPageIndexChange"
              @size-change="onPageSizeChange"
            />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
    
  <el-dialog v-model="isTemplateChooseVisible" :close-on-click-modal="false" title="模版选择" class="nt-resource-dialog" width="850px" :destroyOnClose="true">
    <!-- 过滤单据业务模版，按单据类型 -->
    <systemTemplate @cancel="isTemplateChooseVisible=false" @onChoose="onSystemTemplateChooseHandler" template-type="BILL_TYPE_TEMPLATE" :billType="billType" /> 
  </el-dialog>
  <el-drawer
    title="模版设计"
    :with-header="false"
    size="95%"
    v-model="isDesignlVisible"
    destroyOnClose
    append-to-body
    class="nt-designer nt-inner-drawer"
  >
    <designerPage :entityId="selectedRecord.id" :defaultTemplate="defaultTemplate" :billType="billType" v-if="isDesignlVisible"/>
    <i class="nt-drawer-close" @click="isDesignlVisible=false">关闭</i>
  </el-drawer>
</template>

<script>
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {queryPageTemplateList,createFromSys,deleteTemplate,bindPrinter,setDefaultTemplate,updateStatus} from '@/service/print_order/template'
  import systemTemplate from './sys_template_choose.vue'
  import designerPage from '@/views/designer/dispatch'
  import NtDictTree from '@/views/plugin/nt_dict_tree'
  import NtPrinter from '@/views/plugin/nt_printer'
  export default {
    name: "template-list",
    mixins:[DataListMixin],
    components:{systemTemplate,designerPage,NtDictTree,NtPrinter},
    data () {
      return {
        isDesignlVisible:false,
        isTemplateChooseVisible:false,
        selectedRecord:null,
        defaultTemplate:null, // 新建时，默认模版
        billType:this.$route.meta.templateType, // 通过路由指定，templateType 和billType，如发货单 ，拣货单
        templateType:this.$route.meta.templateType // 通过路由指定，操作特定业务类型的单据
      }
    },
    methods:{
      async _loadList(params){
        if(!this.billType) return
        return await queryPageTemplateList(Object.assign({templateType:this.templateType,billType:this.billType},params)); // 默认单据模版 TODO
      },
      _loadFinished(){

      },
      onSystemTemplateChooseClick(){
        this.isTemplateChooseVisible = true
      },
      async onSystemTemplateChooseHandler(sysTemplate){
        let res = await createFromSys({id:sysTemplate.id}) 
        if(res.code ===200){// TODO  封装统一方法，200是进行callback 调用
          this.isTemplateChooseVisible = false
          this.$message.success('操作成功') 
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      },
      onAddCllick(){
        // 模版类型 单据模版210*140  拣货A4  备货A4
        this.selectedRecord = {}
        if(this.templateType == 'PICKUP_TEMPLATE'){
          this.defaultTemplate = {
            templateType: this.templateType,
            billType: this.billType,
            offsetLeft:0,
            offsetTop:0,
            configJson:{
              freeContainer:{
                _t:'container',
                items:[],
                columnGap:8,
                columnGap:8,
                rows:1,
                columns:1
              }
            },
            pageSetting:{
              title:'这是新增的空白模版',
              pageHeight: 30,
              pageWidth:50,
              headerHeight:0,
              bodyHeight:30,
              footerHeight:0,
              bodyContainer:'block',
              padding:{left:0,top:0,right:0,bottom:0}
            } // 页面边距
          }
        } else{ // 单据-网格类型模版
          this.defaultTemplate = {
            templateType: this.templateType,
            billType: this.billType,
            offsetLeft:0,
            offsetTop:0,
            configJson:{
              body:{
                _t:'table',
                titleHeight:12,
                contentHeight:11,
                columns:[], // TODO 根据单据类型，获取默认的配置列
              },
              header:[], //必须设置属性，否则 响应式属性不起作用
              footer:[]
            },
            pageSetting:{
              title:'这是新增的空白模版',
              pageHeight: 140,
              pageWidth:241,
              headerHeight:30,
              bodyHeight:90,
              footerHeight:20,
              bodyContainer:'grid',
              padding:{left:10,top:10,right:10,bottom:10}
            } // 页面边距
          }
        }
        this.isDesignlVisible =true
      },
      onDesignClick(record){
        this.selectedRecord = record
        this.isDesignlVisible =true
      }, 

      async onDeleteClick(record){
        
        let res = await deleteTemplate({id:record.id}) 
        if(res.code ===200){
          this.isTemplateChooseVisible = false
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      onBillTypeChange(dict){
        this.billType = dict.configKey
        this.loadList()
      },
      async onPrinterChangeHandler(val, row) {
        row.printerName = val
        let res = await bindPrinter({
          templateId: row.id,
          printerName: val
        })
        if (res.code === 200) {
          this.$message.success("更新成功")
        } else {
          this.$message.error(res.msg || res.message || res.data)
        }
      },
      async onRowSetDefaultClick(record) {
        let res = await setDefaultTemplate( { id: record.id,templateType:record.templateType,billType:record.billType })
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      },
      async onRowEnabledClick(record, status) {
        let res = await updateStatus({ id: record.id, isEnabled: status })
        if (res.code == 200) {
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      }
    }
  }
</script>
