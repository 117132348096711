<!-- 商品简称  规格简称编辑弹窗 -->
<template>
  <el-dialog title="编辑简称" v-model="isVisible" :close-on-click-modal="false" width="650" :confirm-loading="isSubmitting" :destroyOnClose="true">
    <div>
      <el-radio-group v-model="editMode">
        <el-radio :value="1" size="large">覆盖现有简称</el-radio>
        <el-radio :value="2" size="large">在现有简称前插入</el-radio>
        <el-radio :value="3" size="large">在现有简称后添加</el-radio>
      </el-radio-group>
    </div>
    <el-input type="textarea" v-model="remark" :rows="4"></el-input>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" v-loading="isSubmitting">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script >

import { saveGoodsExtra } from '@/service/resource/platform_goods'
export default {
  data() {
    return {
      isVisible:false,
      editMode:1,// 1覆盖  2 前面增加  3 后补
      isSubmitting:false,
      remark:null,
    }
  },
  emits:['success'],
  methods: {
    show(goodsExtraList){
      this.goodsExtraList = goodsExtraList
      this.editMode = 1
      this.remark=null
      this.isVisible=true
    },
    async onConfirmClick(){
      
      this.isSubmitting = true
      
      let postData = []
      this.goodsExtraList.forEach(item=>{
        let extra = {
          shopId:item.shopId,
          goodsId:item.goodsId,
          skuId: item.skuId
        }
        // 1覆盖  2 前面增加  3 后补
        if(this.editMode == 1){
          extra.shortName = this.remark
        } else if(this.editMode ==2){
          extra.shortName = this.remark +  (item.goodsExtra?.shortName||item.skuExtra?.shortName)
        } else if(this.editMode ==3){
          extra.shortName = (item.goodsExtra?.shortName||item.skuExtra?.shortName) + this.remark
        }
        postData.push(extra)
      })
      let res = await saveGoodsExtra(postData)

      this.isSubmitting = false
      if (res.code === 200) {
        this.$message.success("操作成功")
        this.$emit('success')
        this.isVisible = false
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
