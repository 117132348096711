<template>
  <el-popover :width="300" trigger="click" placement="top" ref="refPopover">
    <template #reference>
      <el-button link type="primary" style="font-weight:bold;"> 扫描打印设置</el-button>
    </template>
    <div>
      <div style="font-weight:bold;" class="padding-bottom-8 border-bottom">扫描设置</div>
      <el-checkbox-group  v-model="config.scan">
        <el-checkbox value="auto_ready" >扫描成功后自动囤货</el-checkbox>
        <!-- <el-checkbox value="1">扫码其他订单，自动跳过当前订单</el-checkbox>
        <el-checkbox value="1">囤货完成后，自动备齐</el-checkbox> -->
      </el-checkbox-group>
      <div style="font-weight:bold;" class="margin-top-16 padding-bottom-8 border-bottom">自动打印</div>
      <el-checkbox-group  v-model="config.print" class="nt-custom-checkbox">
        <el-checkbox value="all_pick_ready">商品备齐后，自动打印（强烈推荐）</el-checkbox>
        <el-checkbox value="ignore_printed">已打印快递单的不自动打印</el-checkbox>
        <!-- <el-checkbox value="1">有退款的订单的不自动打印</el-checkbox> -->
        <el-checkbox value="ignore_custom_remark">有买家留言的不自动打印</el-checkbox>
        <el-checkbox value="ignore_merchant_remark">有卖家备注的不自动打印</el-checkbox>
        <!-- <el-checkbox value="1">选择省不自动打印</el-checkbox>
        <el-checkbox value="1">选择我打分类不自动打印</el-checkbox> -->
        <el-checkbox value="ignore_keyword">买家留言、卖家备注关键词不打印</el-checkbox>
      </el-checkbox-group>
      <el-input type="textarea" v-model="config.ignoreKeyword" v-if="config.print.includes('ignore_keyword')" 
      placeholder="请输入买家或卖家关键词，多个以逗号分隔"  :rows="2" />
      <div class="align-right border-top padding-top">
        <el-button size="small" @click="onCloseClick">关闭</el-button>
        <el-button type="primary" @click="onConfirmClick" size="small">保存</el-button>
      </div>
    </div>
  </el-popover>
</template>
<script >
import {LoginUserStore} from '@/piana/login_user_service'
import {saveTenantKv} from '@/service/resource/general'
export default {
  data() {
    return {
      config: {}
    }
  },
  props: {
  },
  watch:{
    
  },
  created(){
    let configObj = LoginUserStore().getConfig('PICKING_CODE')
    this.config = JSON.parse(configObj?.configValue||null) || {scan:['auto_ready'],print:['all_pick_ready']} // 默认扫码后囤货， 备齐后自动打印

  },
  methods: {
    onCloseClick(){
      this.$refs.refPopover.hide()
    },
    async onConfirmClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION',configKey: 'PICKING_CODE', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if(res.code == 200){
        this.$refs.refPopover.hide()
        LoginUserStore().updateConfig('PICKING_CODE',postData)
        this.$message.success("保存成功")
        
      } else{
        this.$message.error(res.msg || res.message || res.data || '保存失败')
      }
    }
  }
}
</script>
