
<template>
  
  <el-container class="nt-height-100">
    <el-main class="no-padding">
      <el-table border ref="table" size="middle"  height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="名称" prop="goodsName">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="120">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="90">
        </el-table-column>
        <el-table-column label="采购数量" prop="inBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN')" >{{ row.inBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货数量" prop="backBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >{{ row.backBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >{{ ((row.backTotal)).toFixed(2) }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购数量" prop="quantity" width="120" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >{{ row.inBaseQuantity - row.backBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购金额" prop="total"  width="120" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >{{ ((row.inTotalWithTax - row.backTotalWithTax)).toFixed(2) }}</el-link>
          </template>
        </el-table-column>
        <nt-chineseMoney-column prop="backTotal" width="160"></nt-chineseMoney-column>
        <el-table-column></el-table-column>
      </el-table>
    </el-main>
  </el-container>
  <el-drawer
    v-model="detailVisible" destroyOnClose size="85%"
    class="nt-inner-drawer"
    :with-header="false"
    :z-index="99"
    @close="detailVisible=false">
    <!-- 入参 ： 时间范围，商品， 商品分类， 往来单位， 付款状态 -->
    <trade-item-detail  @cancel="detailVisible =false"  :query="subQuery" v-if="detailVisible"></trade-item-detail>
    <i class="nt-drawer-close" @click="detailVisible = false">关闭</i>
  </el-drawer>

</template>
<script>
import { getPurchaseInTotalByGoods } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'

import tradeItemDetail from '@/views/report/inner_detail/purchase_sale/trade_item_detail'
export default {
  name: "PurchaseTotalByGoods",
  mixins: [DataListMixin],
  components: {
    tradeItemDetail
  },
  data() {
    return {
      detailVisible:false,
      _lastQuery :{},
      subQuery:{}
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getPurchaseInTotalByGoods(Object.assign(params, {}))
    },
    _query(params){
      // console.log('goods Page',params)
      this._lastQuery = params || {}
      this._loadList(params)
    },
    _exportXlsx(params){
      console.log('to do exportXlsx')
    },
    onViewDetailClick(row,billType){
      // 根据查询时间，当前商品，查询交易详情
      // 构造查询参数： 时间范围， 商品id
      this.subQuery= {
        startTime: this._lastQuery.startTime,
        endTime: this._lastQuery.endTime,
        goodsId: row.goodsId,
        billType: billType
      }
      this.detailVisible = true
    }
  }
}
</script>
