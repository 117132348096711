
// import { getAction } from '@/api/manage'
import { getQuantityByGoodsSkuId } from '@/service/store_flow'
import { getEntityByPosition } from '@/service/resource/general'
import { EnumBillType, EnumToolBarCommand } from '@/utils/enum_utils'
import { getNowFormatDateTime } from '@/utils/util'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import { createBillNo } from '@/service/resource/general'


export const DataBillMixin = {
  mixins: [DataEditMixin],
  data() {
    return {
      EnumBillType,
      EnumToolBarCommand,
      igoreBillNo:false,
      // billType:null, // 单据类型
      pageNavigator:{ // 单据游标  首张 末张  上张 下张
        first:true,
        prev:false,
        next:false,
        last:true
      }
    }
  },
  inject: ['dynamicRouterShow'],
  methods: {
    
    getDateTime(){
      return getNowFormatDateTime()
    },
    onToolbarCommand(command,extra) {
      if (command == this.EnumToolBarCommand.NEW_BILL) { // 新增
        this.onAddClick()
      } else if (command == this.EnumToolBarCommand.SAVE) { // 保存
        this.onSubmitClick(false)
      } else if (command == this.EnumToolBarCommand.SAVE_AND_NEW) { // 保存并新增
        this.onSubmitClick(true)
      } else if (command == this.EnumToolBarCommand.VERIFY) { // 审核
        this.onVerfiyClick(true)
      } else if (command == this.EnumToolBarCommand.UNVERIFY) { // 反审核
        this.onVerfiyClick(false)
      } else if (command == this.EnumToolBarCommand.COPY) { // 复制单据
        this.onCopyClick()
      } else if (command == this.EnumToolBarCommand.CLOSE) { // 关闭单据
        this.onClose()
      } else if (command == this.EnumToolBarCommand.EXIST) { // 关闭单据
        this.onCancelClick()
      } else if (command == this.EnumToolBarCommand.UPLOAD_FILE) { // 上传附件
        this.onUploadFileClick()
      } else if (command == this.EnumToolBarCommand.VIEW_FILE) { // 查看附件
        this.onViewFileClick()
      } else if (command == this.EnumToolBarCommand.PRINT) { // 打印
        this.onPrintClick()
      } else if (command == this.EnumToolBarCommand.PRINT_PRVIEW) { // 打印预览
        console.log('fking extra', extra)
        this.onPrintPreviewClick(extra)
      } else if (command == this.EnumToolBarCommand.PRINT_SETTING) { // 打印设置
        this.onPrintSettingClick()
      } else if (command == this.EnumToolBarCommand.FIRST) { // 首张
        this.onFirstEntityClick()
      } else if (command == this.EnumToolBarCommand.PREV) { // 上张
        this.onPrevEntityClick()
      } else if (command == this.EnumToolBarCommand.NEXT) { // 下张
        this.onNextEntityClick()
      } else if (command == this.EnumToolBarCommand.LAST) { // 末张
        this.onLastEntityClick()
      }
    },

    async onAddClick() { // 新增
      if (this._createNewEntity) {
        let newEntity = await this._createNewEntity()
        if(!this.igoreBillNo && !newEntity.data.billNo){ // 生成单据号
          let res = await createBillNo({billType:this.billType})
          if(res.code === 200){
            newEntity.data.billNo = res.data
          }
        }
        this.formModel = newEntity.data
        this.viewOnly = false
      } else {
        this.$message.error('保存并新增时未实现接口：_createNewEntity')
      }
    },
    async onVerfiyClick(isVerify = false) { // 审核  反审
      let res = null
      this.isSubmitting = true
      if (this._verifyBill) { // 检查是否有自定义审核实现接口
        res = await this._verifyBill(isVerify)
      } else {
        res = await this.updateEntityStatus(this.entityType, {status: isVerify? '1':'0', id: this.formModel.id})
      }
      if (res?.code === 200) {
        this.$message.success('操作成功')
        this.formModel.status = isVerify ? '1' : '0'
        this.viewOnly = isVerify
      } else {
        this.$message.error(res?.message || res?.msg || '审核/反审单据失败')
      }
      this.isSubmitting = false
    },
    async onCopyClick() { // 复制
      if (this._copyBill) {
        let billInfo = await this._copyBill() // 由具体单据实现具体清除关键id等信息
        if(!this.igoreBillNo && !billInfo.billNo){ // 生成单据号
          let res = await createBillNo({billType:this.billType})
          if(res.code === 200){
            billInfo.billNo = res.data
          }
        }
        Object.assign(this.formModel, billInfo)
        this.viewOnly = false
      } else {
        this.$message.error("未实现接口: _copyBill")
      }
    },
    async onClose() { // 关闭单据
      if (this._closeBill) {
        let res = await this._closeBill()
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.formModel.isClosed = 1
          // this.viewOnly = isVerfiy
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      } else {
        this.$message.error("未实现接口: _closeBill")
      }
    },

    onUploadFileClick() { // 上传附件

    },
    onViewFileClick() {  // 查看附件

    },

    onPrintPreviewClick(templateId) { // 打印预览
      this._printPriview && this._printPriview(templateId)
    },
    onPrintSettingClick() { // 打印设计者

    },

    async onFirstEntityClick() { // 首张
      let res = null
      this.isLoading = true
      if (this._getFirstEntity) { // 首张，存在自定义实现接口
        res = await this._getFirstEntity()
      } else { // 默认实现
        res = await getEntityByPosition(this.entityType,{billType:this.billType, position: 1 })
      }

      if (res?.code == 200) {
        if(res.data){
          this.formModel = res.data
          this.viewOnly = this.formModel.status !=0 // 非草稿单据，仅查看
          
          this._loadFinished && this._loadFinished()
        } else {
          this.$message('当前单据已是首张')
        }
        this.pageNavigator = {first:false,prev:false,next:true,last:true}
        
      }else {
        this.$message.error(res?.message || '获取单据失败，请重试')
      }
      setTimeout(_=>this.isLoading = false,0) // 进入执行队列，即等待当前方法执行结算后，执行timeout 方法
      
    },
    async onPrevEntityClick() { // 上张
      let res = null
      this.isLoading = true
      if (this._getPrevEntity) {
        res = await this._getPrevEntity()
      } else {
        res = await getEntityByPosition(this.entityType,{billType:this.billType, createTime:this.formModel.createTime,position:2})
      }

      if (res?.code == 200) {
        if(res.data){
          this.formModel = res.data
          this.viewOnly = this.formModel.status !=0 // 非草稿单据，仅查看
          this._loadFinished && this._loadFinished()
          this.pageNavigator = {first:true,prev:true,next:true,last:true}
        } else {
          this.pageNavigator = {first:false,prev:false,next:true,last:true}
          this.$message('当前单据已是首张')
        }
        
      } else {
        this.$message.error(res?.message || '获取单据失败，请重试')
      }
      setTimeout(_=>this.isLoading = false,0)
    },
    async onNextEntityClick() { // 下张
      let res = null
      this.isLoading = true
      if (this._getNextEntity) {
        res = await this._getNextEntity()
      } else {
        res = await getEntityByPosition(this.entityType,{billType:this.billType, createTime:this.formModel.createTime,position:3})
      }

      if (res?.code == 200) {
        if(res.data){
          this.formModel = res.data
          this.viewOnly = this.formModel.status !=0 // 非草稿单据，仅查看
          this.pageNavigator = {first:true,prev:true,next:true,last:true}
          this._loadFinished && this._loadFinished()
        } else {
          this.pageNavigator = {first:true,prev:true,next:false,last:false}
          this.$message('当前单据已是末张')
        }
      } else {
        this.$message.error(res?.message || '获取单据失败，请重试')
      }
      setTimeout(_=>this.isLoading = false,0)
    },
    async onLastEntityClick() { // 末张
      let res = null
      this.isLoading = true
      if (this._getLastEntity) {
        res = await this._getLastEntity()
      } else {
        res = await getEntityByPosition(this.entityType,{billType:this.billType, createTime:this.formModel.createTime,position:-1})
      }
      
      if (res?.code == 200) {
        if(res.data){
          this.formModel = res.data
          this.viewOnly = this.formModel.status !=0 // 非草稿单据，仅查看
          this._loadFinished && this._loadFinished()
        } else {
          this.$message('当前单据已是末张')
        }
        this.pageNavigator = {first:true,prev:true,next:false,last:false}
      } else {
        this.$message.error(res?.message || '获取单据失败，请重试')
      }
      setTimeout(_=>this.isLoading = false,0)
    },

    jumpToPage(urlPath,label,refBill){ // 跳转连接，根据tab 多页面跳转
      this.dynamicRouterShow(urlPath,null,label,null,refBill) // '/bill/trade/sale_order'
    },
    refreshStockInfo(row,stockFieldName='stockQuantity'){ // 批量获取存货信息，callback 模式
      if(!row.goodsSkuId){
        return
      }
      getQuantityByGoodsSkuId({storeId:row.storeId,goodsSkuId:row.goodsSkuId}).then(res=>{
        if(res?.code==200){
          row[stockFieldName] = res.data
        }
      })
    },
  }
}
