<!-- 自动发货规则设置 -->
<template>
  <el-container class="nt-height-100">
    
    <el-main class=" padding nt-box-panel">
      <div>根据您的发货习惯，可以设置不同的自动发货规则，另外可以在“实用工具-异常订单”查看自动发货异常订单～</div>
      <el-alert title="免责声明:" type="success" >
1.您知晓“自动发货”功能为商家发货的一项辅助功能，会受到服务器稳定性、接口波动等因素影响，存在少量订单出现延迟发货或发货失败的风险。
2.您知晓“自动发货”功能存在上述风险、无法完全替代手动发货及相关确认操作，使用过程中应及时确认自动发货订单的发货情况，易打单将不承担因未及时确认自动发货订单的发货情况造成的损失
      </el-alert>
      <div>
        <el-radio-group v-model="dispatchMode" style="display:inline-grid;">
          <el-radio value="SEND_AFTER_PRINT" class="display-block">
            <span>订单打印后</span>
            <el-select v-model="sendAfterPrint" :disabled="dispatchMode!='SEND_AFTER_PRINT'">
              <el-option value="0">立即发货</el-option>
              <el-option value="30">0.5小时后</el-option>
              <el-option value="540">9小时后</el-option>
            </el-select>
          </el-radio>
          <el-radio value="SEND_SCHEDULE_TIME">
            每天定时发货
            <el-select v-model="sendScheduleTime"  :disabled="dispatchMode!='SEND_SCHEDULE_TIME'">
              <el-option :value="index" v-for="(item,index) in (new Array(24))" :key="index">{{index}}</el-option>
            </el-select>
            将已打印待发货的订单自动发货
          </el-radio>
          <!-- <el-radio value="3">
            系统接收到电子面单路由信息后自动发货。（暂支持顺丰）
          </el-radio> -->
          <el-radio value="NONE">
            不使用自动发货功能
          </el-radio>
          
        </el-radio-group>
      </div>
      <div>
        <el-button type="primary" @click="onSaveClick">保存</el-button>
      </div>
    </el-main>
  </el-container>
</template>
<script>


import {getTenantKvByConfigKey,saveTenantKv} from '@/service/resource/general'
import NtTagInput from '@/views/plugin/nt_tag_input'
export default {
  name: "IMPORT_SETTING",
  components:{NtTagInput},
  data() {
    return {
      config:{
        dispatchMode:"NONE", //不使用自动发货
        sendAfterPrint: "30",// 打印后30分钟发货
        sendScheduleTime:"1" // 每天凌晨定时发货
      }
      
    }
  },
  async created(){
    let res = await getTenantKvByConfigKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'AUTO_DISPATCH'})
    if (res.code == 200) {
      if (res.data) {
        this.config = JSON.parse(res.data.configValue||'{}')
      }
    } else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    async onSaveClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'AUTO_DISPATCH', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>