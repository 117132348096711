<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom  display-flex ${config?'config':''}`" :style="{columnGap:'0px'}">
    <!-- <div class="config-label" v-if="config">{{ label }}</div> -->
    <el-select v-model="queryType" v-bind="props" :placeholder="placeholder" size="small" :style="{width:'100px'}" @change="onSelectChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">{{item.label}}</el-option>
    </el-select>
    <el-select v-model="value" :class="`${getHighlightClass()}`" v-bind="props" placeholder="请选择" size="small" :style="{width:'188px'}" @change="onSelectChange">
      <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">{{item.label}}</el-option>
    </el-select>
  </div>
  
</template>

<script>
  // import {defaultQueryModel} from '../config/query_editor'
  export default {
    data () {
      return {
        queryType: this.modelValue?.queryType,
        value: this.modelValue?.value,
        defaultValue: this.modelValue
      }
    },
    props:{
      modelValue:{},
      options:{
        type:Array,
        default:_=>[]
      },
      options2:{
        type:Array,
        default:_=>[]
      },
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.value = this.modelValue?.value
      }
    },
    created(){
      let matchItem = this.options.find(item=>item.value == this.queryType)
      this.tips = matchItem?.placeholder
    },
    methods:{
      getHighlightClass(){
        return this.defaultValue?.value != this.value? 'highlight':''
      },
      onSelectChange(){
        let matchItem = this.options.find(item=>item.value==this.queryType)
        this.tips = matchItem?.placeholder
        let data = {
          dynamic:true, // 动态计算
          valueType: 'DOUBLE', // 期间
          value:this.value, // 第二个值---存放变量， 用于计算 startValue  endValue
        }
        this.$emit('update:modelValue', Object.assign({},this.defaultValue, data))
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    &.highlight{
      :deep(.el-select__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-select){
        flex:1;
        .el-select__wrapper{
          box-shadow: none;
          
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
  }
</style>