<template>
  <div class="padding" style="height:450px;">
    <el-container class="padding nt-box-panel nt-bg-white nt-height-100">
      <el-header class="no-margin height-auto no-padding display-flex space-between">
        <el-form inline @keyup.enter.native="onSearchClick">
          <nt-form-item label="">
            <el-input placeholder="请输入手机号,联系人或联系电话" v-model="queryParam.keyword"></el-input>
          </nt-form-item>
          <nt-form-item>
            <el-button type="primary" @click="onSearchClick">查询</el-button>
          </nt-form-item>

        </el-form>
        <el-button @click="onAddClick" type="primary">新增</el-button>
      </el-header>
      <el-main class="no-padding">
        <el-table height="100%" border width="100%" highlight-current-row ref="table" size="small" stripe class="nt-resource-table"
          :data="datasource" @row-click="onRowClick" v-loading="isLoading">
          <el-table-column label="#" width="45" type="index" fixed="left" />
          <el-table-column label="发货人" width="140" prop="userName"></el-table-column>
          <el-table-column label="手机" width="140" prop="cellPhone"></el-table-column>
          <el-table-column label="联系电话" width="140" prop="phone"></el-table-column>
          <el-table-column label="地址">
            <template #default="{ row }">
              <div>{{ row.provinceName }}-{{ row.cityName }}-{{ row.districtName }}</div>
              <div>{{ row.addressDetail }}</div>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="160" prop="remark"></el-table-column>
          <el-table-column label="是否默认" width="85" prop="isDefault">
            <template #default="scope">
              <el-tag v-if="scope.row.isDefault" type="success">是</el-tag>
              <el-tag v-if="!scope.row.isDefault" type="warning">否</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="space-between">
          <el-pagination :current-page="this.pageInfo.pageIndex" size="small" :page-size="this.pageInfo.pageSize"
            :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
            layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
            @size-change="onPageSizeChange" />
          <div>
            <el-button @click="onCancelClick" type="info">取消</el-button>
            <el-button type="primary" :disabled="!selectedRow" @click="onConfirmClick">确定</el-button>
          </div>
        </el-row>
      </el-footer>
    </el-container>
  </div>


  <el-dialog v-model="isDetailVisible" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import detailPage from '@/views/address/detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "print_address_selector",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {
      selectedRow: null,
      isDetailVisible: false,
      // 查询条件
      queryParam: {
        keyword: ''
      }
    }
  },
  props: {
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.ADDRESS, params);
    },
    onSearchClick() {
      this.resetPage = true
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
    },
    handleCommand(cType) {
      this.onAddClick(cType)
    },
    onAddClick(cType) {
      this.currentRecord = {}
      this.isDetailVisible = true
    },

    onCancelClick() {
      this.$emit('cancel')
    },
    onConfirmClick() {
      // 根据单选或复选 返回单个对象或集合
      this.$emit('choose', this.selectedRow)
    },
    onRowClick(row) {
      this.selectedRow = row
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible=false
      this.loadList()
    }

  }
}
</script>
