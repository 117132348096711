<!-- 商品简称  规格简称编辑弹窗 -->
<template>
  <el-dialog title="批量更新" v-model="isVisible" :close-on-click-modal="false" width="250" :confirm-loading="isSubmitting" :destroyOnClose="true">
    
    <el-input v-model="remark" :rows="1"></el-input>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" v-loading="isSubmitting">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script >

import { saveGoodsExtra } from '@/service/resource/platform_goods'
export default {
  data() {
    return {
      isVisible:false,
      isSubmitting:false,
      remark:null,
    }
  },
  emits:['success'],
  methods: {
    show(fieldName,goodsExtraList){
      this.goodsExtraList = goodsExtraList
      this.remark=null
      this.fieldName = fieldName // 待更新的字段名称
      this.isVisible=true
    },
    async onConfirmClick(){
      this.isSubmitting = true
      
      let postData = []
      this.goodsExtraList.forEach(item=>{
        let extra = {
          shopId:item.shopId,
          goodsId:item.goodsId,
          skuId: item.skuId
          
        }
        extra[this.fieldName] = this.remark
        postData.push(extra)
      })
      let res = await saveGoodsExtra(postData)

      this.isSubmitting = false
      if (res.code === 200) {
        this.$message.success("操作成功")
        this.$emit('success')
        this.isVisible = false
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
