import { axios as service } from '@/utils/request'


/**
 * 现金银行统计
 * @param {*} data 
 * @returns 
 */
export const getAccountSummaryList = (data) => {
  return service({
    url: '/report/account/summary',
    method: 'post',
    data
  })
}

/**
 * 现金银行流水查询
 *  根据期初，计算每行的余额并增加小计
 * @param {*} data 
 * @returns 
 */
export const getAccountFlowDetailList = async (data) => {
  let res = await service({
    url: '/report/account/flowDetail',
    method: 'post',
    data
  })
  if(res.code===200){
    res.data = res.data.reduce((pre,cur)=>{
      let balance = cur.initTotal||0 // 账户期初余额
      cur.balance =  balance
      
      let children = cur.children
      delete cur.children
      pre.push(cur)
      let inTotal = 0, outTotal=0
      children?.forEach(el=>{
        
        balance += el.ioType * el.total // 明细的收入/支出金额
        el.balance = balance
        el.inTotal = el.ioType==1 ? el.total : 0
        el.outTotal = el.ioType==-1 ? el.total : 0
        inTotal += el.inTotal
        outTotal += el.outTotal
        el.isDetail = true
        pre.push(el)
      })
       pre.push({accountName:'小计',balance:balance,inTotal:inTotal,outTotal:outTotal,isDetail:true,isSubtotal:true}) // 增加账户小计
      return pre
    },[])
     
  }
  
  return res
}