<!-- 旗帜自定义文字说明 -->
<template>
  <el-popover :width="300" trigger="click" ref="refPopover" placement="left">
    <template #reference>
      <el-button link type="primary">旗帜设置</el-button>
    </template>
    <template #default>
      <div class="border-bottom padding-bottom-8" style="font-weight: bold;">自定义说明</div>
      <div class=" display-flex margin-top-8">
        <div class="display-flex column-gap-4" style="width:70px;">
          <span>旗帜</span>
          <el-icon style="color:#FF4D4A;" :size="18" ><Flag/></el-icon>
        </div>
        <el-input v-model="config['1']" size="small" placeholder="请输入旗帜自定义描述"></el-input>
      </div>
      <div class=" display-flex margin-top-8">
        <div class="display-flex column-gap-4" style="width:70px;">
          <span>旗帜</span>
          <el-icon style="color:#217EFF;" :size="18" ><Flag/></el-icon>
        </div>
        <el-input v-model="config['2']" size="small" placeholder="请输入旗帜自定义描述"></el-input>
      </div>
      <div class=" display-flex margin-top-8">
        <div class="display-flex column-gap-4" style="width:70px;">
          <span>旗帜</span>
          <el-icon style="color:#FFC619;" :size="18" ><Flag/></el-icon>
        </div>
        <el-input v-model="config['4']" size="small" placeholder="请输入旗帜自定义描述"></el-input>
      </div>
      <div class=" display-flex margin-top-8">
        <div class="display-flex column-gap-4" style="width:70px;">
          <span>旗帜</span>
          <el-icon style="color:#17BE6B;" :size="18" ><Flag/></el-icon>
        </div>
        
        <el-input v-model="config['8']" size="small" placeholder="请输入旗帜自定义描述"></el-input>
      </div>
      <div class="align-center margin-top-8">
        <el-button size="small" @click="onCancelClick">取消</el-button>
        <el-button size="small" type="primary" @click="onSaveClick">保存</el-button>
      </div>
    </template>
  </el-popover>
</template>
<script >

import {saveTenantKv} from '@/service/resource/general'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      config:{}
    }
  },
  created(){
    let configItem =  LoginUserStore().getConfig('FLAG_REMARK')
    if(configItem){
      this.config = JSON.parse(configItem.configValue||null) || {}
    }
    
  },
  methods: {
    onCancelClick(){
      this.$refs.refPopover.hide()
    },
    async onSaveClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'FLAG_REMARK', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        LoginUserStore().updateConfig('FLAG_REMARK',postData)
      } else {
        this.$message.error(res.msg || '操作失败')
      }
      this.$refs.refPopover.hide()
      // this.$emit('success', this.config) // 保存成功
    },
    
  }
}
</script>
