<!-- 打印记录 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin display-flex flex-wrap row-gap-8">
      <QueryFieldList :queryModel="queryForm" :editors="editors"></QueryFieldList>
        <div>
          <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
          <el-button style="width:64px;" size="small" @click="onResetQueryClick" type="primary">重置</el-button>
        </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top-8">
      <el-table :data="datasource" height="100%" v-loading="isLoading" stripe  scrollbar-always-on border class="nt-resource-table">
        <el-table-column label="运单信息" prop="buyerName">
          <template #default="{row}">
            <el-descriptions :column="9" direction="vertical" size="small"  border >
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="relative">
                    运单号({{ row.deliveryId }})
                    <div class="right-top">
                      <el-tag type="success" v-if="row.waybillStatus=='SUCCESS'">已生效</el-tag>
                      <el-tag type="danger" v-else-if="row.waybillStatus=='CANCELED'">已回收</el-tag>
                      <el-tag v-else>{{ row.waybillStatus }}</el-tag>
                    </div>
                  </div>
                </template>
                <el-link type="primary" @click="onViewPackageDetailClick(row)">{{ row.waybillNo }}</el-link>
                (重量:{{formatWeight(row)}})
              </el-descriptions-item>
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="cell-item">
                    面单创建时间
                  </div>
                </template>
                {{ row.createTime }}
              </el-descriptions-item>
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="cell-item">
                    面单模版
                  </div>
                </template>
                {{row.waybillTemplateId}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    订单号({{ formatShopName(row.shopId) }})
                  </div>
                </template>
                <el-link type="primary" @click="onViewOrderDetailClick(row)">{{ row.orderNo }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item >
                <template #label>
                  <div class="cell-item">
                    省份
                  </div>
                </template>
                {{row.province}}
              </el-descriptions-item>
              
              <el-descriptions-item :span="9">
                <template #label>
                  <div class="cell-item">
                    详细地址({{ row.buyerName }}{{ row.buyerTel }})
                  </div>
                </template>
                {{ row.buyerAddress }}
              </el-descriptions-item>
              
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column label="打印" prop="printCnt" width="85" align="center"></el-table-column>
        <el-table-column label="获取时间" prop="createTime" width="165"></el-table-column>
        <el-table-column label="回收时间" prop="cancelTime" width="165"></el-table-column>
        <el-table-column label="状态"  width="100">
          <template #default="{row}">
            {{ formatStatus(row) }}
          </template>
        </el-table-column>
        
        <el-table-column label="操作" width="80">
          <template #default="{row}">
            <el-popconfirm title="确定回收面单吗?" @confirm="onRowCancelWaybillClick(row)">
              <template #reference>
                <el-button link type="danger" size="small">回收</el-button>
              </template>
            </el-popconfirm>
          </template> 
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top">
      <el-row justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail"  />
</template>
<script>


import  QueryFieldList from '../../query/query_field_list'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import OrderDetailPage from '@/views/print_order/detail/order_detail'
import PackageDetailPage from '@/views/print_order/detail/package_detail' // 包裹详情
import {LoginUserStore} from '@/piana/login_user_service'
import {waybillPrintQuery} from '../../query/config/query_waybill_editor'
import {cancelWaybill} from '@/service/print_order/platform_waybill'
export default {
  name: "WAYBILL_PRINT_HISTORY",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    OrderDetailPage,
    PackageDetailPage,
    QueryFieldList
  },
  data() {
    return {
      queryForm: {},
      editors: waybillPrintQuery
    }
  },
  created(){
    this.onResetQueryClick()
    this._shopList = LoginUserStore().shopList
  },
  methods: {
    onResetQueryClick(){
      this.editors.forEach(item=>{
        this.queryForm[item.queryKey]= item.defaultValue || {}
      })
    },
    async _loadList(params) {
      let dynamicQuery = {dynamic:Object.values(this.queryForm)}
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_WAYBILL, Object.assign({},params,dynamicQuery));
    },
    formatShopName(shopId){
      let matchItem =  this._shopList.find(item=>item.shopId==shopId)
      return matchItem?.shopName || shopId
    },
    formatStatus(waybill) {
      if(waybill.waybillStatus == 'CANCELED'){
        return '-'
      }
      else if(waybill.isDispatch){
        return '已发货'
      } else if(waybill.isPreDispatch){
        return '预发货'
      }
      return '待发货'
    },
    onQueryClick() {
      this.resetPage = true
      this.loadList({dynamic:Object.values(this.queryForm)})
    },
    formatWeight(row){
      let total =  row.platformOrderPackageList.reduce((pre,cur)=>pre+(cur.weight||0),0)
      return total
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    onViewPackageDetailClick(row){
      this.$refs.refPackageDetail.show(row.orderId,row.waybillNo)
    },
    onViewOrderDetailClick(row){
      this.$refs.refOrderDetail.show(row.orderId)
    },
    async onRowCancelWaybillClick(row){
      let res = await cancelWaybill({id:row.id}) //批量更新接口
      if(res.code == 200){
        let resultItem = res.data.resultList[0]
        if(resultItem.success){
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(resultItem.message)
        }
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    }
  }
}
</script>