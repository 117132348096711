<template>
	<div class="padding-8" style="background:#fff;height:100%;box-shadow:var(--el-box-shadow-lighter);">
    <!-- 针对商品区域需传入billType，加载可选列表 -->
    <component  :is="PANEL_MAP[(current?._t||'page')]"  :config="current" v-if="current" />
  </div>
</template>

<script >

import text_property from '../property/text_property.vue'
import sys_text_property from '../property/sys_text_property.vue'
import qr_code_property from '../property/qr_code_property.vue'
import barcode_property from '../property/barcode_property.vue'
import v_line_property from '../property/v_line_property.vue'
import h_line_property from '../property/h_line_property.vue'
import rectangle_property from '../property/rectangle_property.vue'
import table_property from '../property/table_property.vue'
import page_property from '../property/page_property.vue'
import body_free_container_property from '../property/body_free_container_property.vue'

export default {
  data () {
    return {
      PANEL_MAP:{
        text: text_property,
        sys_text: sys_text_property,
        qr_code: qr_code_property,
        barcode: barcode_property,
        h_line: h_line_property,
        v_line: v_line_property,
        rectangle: rectangle_property,
        table: table_property,
        page: page_property,
        container:body_free_container_property //标题自由容器
      }
    }
  },
  components:{},
  props:{
    current:{ // 当前编辑的元素
      type: Object
    },
    
    // billType:{ // 单据明细组件使用该属性，加载单据可配置字段范围
    //   type:String
    // }
  },
  methods:{
    
  }
}

</script>