
<template>
  <nt-list-layout>
    <template #toolbar>
      <nt-bill-list-toolbar @command="onToolbarClick" :hidden="['close', 'activate']">
        <template #title>付款单列表</template>
      </nt-bill-list-toolbar>
    </template>
    <template #header>
      <el-form @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <el-form-item label="单据编号">
          <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <nt-form-item prop="companyId" label="供应商">
          <nt-dropdown-grid style="width:220px;display:inline-block;" configKey="supplier"
            v-model="queryModel.companyId" v-model:text="queryModel.companyName" placeholder="请选择供应商"
            :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </nt-form-item>
        
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        :data="datasource"
        height="100%"
        @selection-change="onSelectionChange"
        class="nt-bill-table-list"
        v-loading="isLoading">
        <el-table-column label="#" width="40" type="index" fixed="left"/>
        <el-table-column type="selection" width="40" align="center"/>
        <nt-bill-no-column label="单据编码" width="165" idName="id" show-status>
          <template #extra="{row}">
            <el-tag type="info">{{ formatBizType(row.bizType) }}</el-tag>
          </template>
        </nt-bill-no-column>
        <el-table-column label="供应商" width="260" prop="companyName">
        </el-table-column>
        <el-table-column label="单据日期" width="100" align="center" prop="billTime">
          <template #default="scope">
            {{ formatShortDate(scope.row.billTime) }}
          </template>
        </el-table-column>

        <el-table-column label="付款账户" prop="accountName" width="180">
          <template #default="{ row }">
            {{ row.accountName }}<el-tag size="small" type="primary">{{ row.paymentMethodName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="合计收款" width="100" align="right">
          <template #default="{ row }">
            {{ row.total.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="优惠金额" prop="" width="100" align="right">
          <template #default="{ row }">
            {{ (row.discountTotal || 0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="实际收款" prop="" width="100" align="right">
          <template #default="{ row }">
            {{ row.billTotal.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="制单人" prop="userName" width="120"></el-table-column>
        <el-table-column label="备注" prop="remark" ></el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['ENABLE','DISABLE','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-list-layout>
  
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="75%"  title="付款单" draggable
  class="nt-none-border-dialog" @cancel="isDetailVisible = false">
    <page-detail :height="550" @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></page-detail>
  </el-dialog>
  <nt-result-dialog ref="refResult"></nt-result-dialog>
</template>
<script>

import PageDetail from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'

import NtFormItem from '@/views/plugin/nt_form_item'

export default {
  name: "PaymentOutList",
  mixins: [DataListMixin],
  components: {
    PageDetail,
    
    NtDateRange,
    
    NtFormItem,
  },
  data() {
    return {
      currentRecord: {},
      isDetailVisible: false,
      stockPeriodVisible: false,
      toggleSearchStatus: false,

      // 查询条件
      queryParam: {
        billNo: "",
        billType: "FINANCIAL_PAY",
        companyId: "",
        creator: "",
        handsPersonId: "",
        accountId: "",
        status: "",
        remark: "",
      },

    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.ACCOUNT_HEAD, Object.assign({ billType: 'FINANCIAL_PAY' }, params));
    },

    formatBizType(bizType) {
      let info = { 'FINANCIAL_TAKE': '收款单', 'FINANCIAL_PAY': '付款单', 'FINANCIAL_TAKE_PREPAY': '预收款单', 'FINANCIAL_PAY_PREPAY': '预付款单' }
      return info[bizType] || bizType
    },
    
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },

    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.ACCOUNT_HEAD, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onBillNoClick(record) {
      this.currentRecord = record
      this.viewOnly = true
      this.isDetailVisible = true
    },

    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },

    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },

    onSearchQueryClick() {
      this.loadList()
    },

    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },


    async onVerifyClick(record, isVerify) {
      let res = await this.updateEntityStatus(this.EnumEntityType.ACCOUNT_HEAD, { status: isVerify ? '1' : '0', id: record.id })
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    async onBatchVerifyClick(isVerify){ // 批量审核-弃审操作
        let billIds = this.selectedRows.map(item=>item.id)
        if(billIds.length==0){
          this.$message.error('请选择需要操作的业务单据')
          return
        }
        let res = await this.batchUpdateEntityStatus(this.EnumEntityType.ACCOUNT_HEAD, {status: isVerify? '1':'0', ids: billIds})
        if(res.code===200){
          // 弹窗显示批量操作结果
          this.$refs.refResult.showDialog('审核结果',res.data)
          this.loadList()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      }
  }
}
</script>