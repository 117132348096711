<template>
  <div>
    <div class="table-page-search-wrapper">
      <!-- 搜索区域 -->
      <a-form layout="inline" @keyup.enter.native="onSearchQueryClick">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="单据编号" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
              <a-input placeholder="请输入单据编号查询" v-model="queryParam.number"></a-input>
            </a-form-item>
          </a-col>
          
          <a-col :md="6" :sm="24">
            <a-form-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-range-picker
                style="width: 100%"
                v-model="queryParam.createTimeRange"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                @change="onDateChange"
                @ok="onDateOk"
              />
            </a-form-item>
          </a-col>
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
            <a-col :md="6" :sm="24">
              <a-button type="primary" @click="onSearchQueryClick">查询</a-button>
              <a-button style="margin-left: 8px" @click="onSearchResetClick">重置</a-button>
            </a-col>
          </span>
        </a-row>
      </a-form>
    </div>
    <!-- table区域-begin -->
    
    <a-table
        bordered
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="datasource"
        :pagination="false"
        v-loading="isLoading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'checkbox'}"
        :customRow="rowAction">
        
      </a-table>
      <a-row type="flex" justify="end" class="padding">
        <a-pagination size="small"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :current="this.pageInfo.pageIndex"
          :pageSize="this.pageInfo.pageSize"
          :total="this.pageInfo.total"
          @change="onPageIndexChange"
          @showSizeChange="onPageSizeChange"
        />
      </a-row>
    <!-- table区域-end -->
    <a-row type="flex" justify="end">
      <a-space>
        <a-button @click="onCancelClick">取消</a-button>
        <a-button type="primary"  @click="handleOk">确定</a-button>
      </a-space>
    </a-row>
  </div>
  
</template>

<script>
  // import {getPaymentInitList} from '@/service/payment_init'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  export default {
    name: 'LinkBillList',
    mixins:[DataListMixin],
    components: {
      // BillDetail
    },
    data () {
      return {
        title: "操作",
        selectedRowKeys: [],
        selectBillRows: [],
        queryParam: {
          number: "",
          materialParam: "",
        },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
          { title: '往来单位', dataIndex: 'companyName',width:120, ellipsis:true},
          { title: '单据编号', dataIndex: 'billNo',width:140 },
          { title: '业务类型', dataIndex: 'bizType',width:140 },
          { title: '单据日期', dataIndex: 'billTime',width:145},
          { title: '制单人', dataIndex: 'userName',width:70},
          
          
          { title: '金额合计', dataIndex: 'total',width:80,
            customRender:function(t,r,index){
              return r.total/100;
            }},
            { title: '已结算', dataIndex: 'settleTotal',width:70},
          { title: '状态', dataIndex: 'status', width: 70, align: "center",
            scopedSlots: { customRender: 'customRenderStatus' }
          }
        ],
      }
    },
    computed: {
      getType: function () {
        return 'check';
      }
    },
    props:{
      companyId:{
        type:String,
        required:true
      }
    },
    created() {
    },
    methods: {
      async _loadList(params){
        return await this.getPageEntityList(this.EnumEntityType.PAYMENT_INIT, Object.assign({companyId: this.companyId,status:'1'},params));
      },
      
      onSearchQueryClick(){},
      onSearchResetClick(){},
      close () {
        this.$emit('cancle');
      },
      handleCancel () {
        this.close()
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      handleOk () {
        if(this.selectedRowKeys.length) {
          this.getSelectBillRows()
          this.$emit('choose', this.selectBillRows)
        } else {
          this.$message.warning('抱歉，请选择单据明细！')
        }
      },
      onDateChange: function (value, dateString) {
        this.queryParam.startTime=dateString[0];
        this.queryParam.endTime=dateString[1];
      },
      onDateOk(value) {
        console.log(value);
      },
      onSearchResetClick() {
        this.queryParam = {
          type: this.queryParam.type,
          subType: this.queryParam.subType,
          status: "1,3"
        }
        this.loadData(1);
      },
      getSelectBillRows() {
        let dataSource = this.datasource;
        this.selectBillRows = [];
        for (let i = 0, len = dataSource.length; i < len; i++) {
          if (this.selectedRowKeys.includes(dataSource[i].id)) {
            this.selectBillRows.push(dataSource[i]);
          }
        }
      },
      
      rowAction(record, index) {
        return {
          on: {
            click: () => {
              let arr = []
              arr.push(record.id)
              this.selectedRowKeys = arr
            },
            dblclick: () => {
              let arr = []
              arr.push(record.id)
              this.selectedRowKeys = arr
              this.handleOk()
            }
          }
        }
      },
      onCancelClick(){}
    }
  }
</script>

<style scoped>

</style>