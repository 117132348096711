<!-- 废弃 ********************************************* -->
<template>
  <el-container class="no-padding" style="height:400px;background:#fff;">
    <el-header class="no-margin no-padding" style="height:auto;padding-bottom:0px;">
      
    </el-header>
    <el-main class="no-padding">
      <el-table class="nt-editable-table" ref="refTable"
      @selection-change="onRowsSelectionChange"
      :data="tableData" border height="100%">
        <el-table-column label="" width="35" type="selection">
        </el-table-column>
        <!-- 根据属性列表，动态生成列表 -->
        <el-table-column :label="col.attributeName" :prop="`col_${index}`" v-for="(col,index) in attrList" v-key="col.id">
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="align-center padding">
      <el-button @click="onCancleClick">取消</el-button>
      <el-button type="primary" @click="onConfirmClick">确定</el-button>
    </el-footer>
  </el-container>
  
</template>

<script>

  /* 商品sku属性编辑面板
    1. 根据多个属性进行卡迪尔计算生成网格
    2. 根据入参 默认勾选---勾选列表
   */
  export default {
    name: 'spec_detail',
    data () {
      return {
        selectedRows:[]
        // tableData:[{}]
      }
    },
    props:{
      attrList:{ // 属性列表
        type: Array
      },
      skuList:{ // 已选中sku列表
        type:Array
      }
    },
    computed:{
      tableData:function(){
        // 卡迪尔计算属性列表
        // 设置默认勾选
        // let spec = [
        //   ["红","绿","蓝"],
        //   ["大","中","小"],
        //   ["m","xl","s"]
        // ]
        // 算法参考 https://www.jianshu.com/p/ffd9528c78f0
        let spec = this.attrList.filter(el=>el.attributeValue).map(el=> el.attributeValue.split('|'))
        let sku = spec.reduce((x,y) =>{
            let arr = [];
            x.forEach(x => y.forEach(y => arr.push(x.concat([y]))))
            return arr;
        },[[]])
        // 形成表格数据源
        sku =sku.map(el=>{
          let row = {}
          el.forEach((item,idx)=>row[`col_${idx}`] = el[idx])
          // 拼接key  分隔符 /
          row.key = el.join('/')
          return row
        })
        return sku
      }
    },
    created () {
      // 首次编辑sku 属性时，默认选中所有 skuList =[] 时
    },
    mounted(){
      // 根据已选择skuList,设置默认勾选项目
      // 根据属性值 sku1/sku2/sku3 拆分数组后进行匹配
      let rows = []
      this.skuList.forEach(el=>{
        let matchItem = this.tableData.find(item=>item.key == el.sku)
        if(matchItem){
          rows.push(matchItem)
          // 触发选中
          this.$refs.refTable.toggleRowSelection(matchItem)
        }
      })
      this.selectedRows = rows
    },
    methods: {
      onRowsSelectionChange(rows){
        this.selectedRows = rows
      },
      onConfirmClick(){
        this.$emit('choose',this.selectedRows.map(el=>el.key)) // 返回拼接后的key
      },
      onCancleClick(){
        this.$emit('cancel',[])
      }
    }
  }
</script>
