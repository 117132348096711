<template>
  <nt-list-layout>
    <template #toolbar>
      <nt-bill-list-toolbar @command="onToolbarClick" :hidden="['close','activate']">
        <template #title>其他收入单列表</template>
      </nt-bill-list-toolbar>
    </template>
    <template #header>
      <el-form @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <el-form-item label="单据编号">
          <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <nt-form-item prop="companyId" label="往来单位">
          <nt-dropdown-grid configKey="customer" v-model="queryModel.companyId" v-model:text="queryModel.companyName"
            :disabled="viewOnly" placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        :data="datasource"
        height="100%"
        size="large"
        @selection-change="onSelectionChange"
        class="nt-bill-table-list"
        v-loading="isLoading">
        <el-table-column label="#" width="40" type="index" fixed="left"/>
        <el-table-column type="selection" width="40" align="center"/>
        <nt-bill-no-column label="单据编码" width="165" idName="id"  show-status>
        </nt-bill-no-column>
        <el-table-column label="单据日期" width="100" align="center" prop="billTime">
          <template #default="scope">
            {{ formatShortDate(scope.row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName" width="220"></el-table-column>
        
        <el-table-column label="财务人员" prop="handsPersonName" width="120"></el-table-column>
        <el-table-column label="收入账户" prop="accountName" width="120"></el-table-column>
        <el-table-column label="收入金额" width="100" align="right">
          <template #default="scope">
            {{ Math.abs(scope.row.total) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="制单人" prop="userName" width="120"></el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['ENABLE','DISABLE','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-list-layout>
  
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="75%" class="nt-none-border-dialog" title="支出单"
    @cancel="isDetailVisible = false">
    <detail-page  @success="onDetailSaveSuccessHandler" :height="550" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
  <nt-result-dialog ref="refResult"></nt-result-dialog>
</template>
<script>


import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail'
import NtDateRange from '@/views/plugin/nt_date_range'

import NtFormItem from '@/views/plugin/nt_form_item'

export default {
  name: "ItemOutList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtDateRange,
    
    
    NtFormItem
  },
  data() {
    return {
      currentRecord: {},
      toggleSearchStatus: false,
      selectedRowKeys: [],
      // 查询条件
      queryParam: {
        billNo: "",
        companyId: "",
        handsPersonId: "",
        accountId: "",
        status: "",
        remark: "",
      }
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.INCOME_HEAD, Object.assign({ billType: 'FINANCIAL_INCOME' }, params));
    },
    
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.INCOME_HEAD, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    
    onBillNoClick(record){
      this.currentRecord = record
      this.viewOnly = true
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.viewOnly = false
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },
    async onVerifyClick(record, isVerify) {
      let res = await this.updateEntityStatus(this.EnumEntityType.INCOME_HEAD, { status: isVerify ? '1' : '0', id: record.id })
      if (res.code === 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    async onBatchVerifyClick(isVerify){ // 批量审核-弃审操作
      let billIds = this.selectedRows.map(item=>item.id)
      if(billIds.length==0){
        this.$message.error('请选择需要操作的业务单据')
        return
      }
      let res = await this.batchUpdateEntityStatus(this.EnumEntityType.INCOME_HEAD, {status: isVerify? '1':'0', ids: billIds})
      if(res.code===200){
        // 弹窗显示批量操作结果
        this.$refs.refResult.showDialog('审核结果',res.data)
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>
