/**
 * 打印管理器
 *  
 */
    import {useCainiaoPrintServiceStore} from '@/piana/cainiao_printer_service.js'
    import {useWeixinPrintServiceStore} from '@/piana/weixin_printer_service.js'
    
    class NtPrintManager {
      #monitorQueue =[] // 面单打印监控队列 requestID:xxxx, success:true ,done:false 
      #callback =null // 回调订阅
      constructor(callback) {
        this.#callback = callback
      }
      getQueue(){
        return this.#monitorQueue
      }
      checkExistByRequestID(requestID){
        return this.#monitorQueue.findIndex(item=>item.requestID == requestID) != -1
      }
      getQueueItemRequestID(requestID){
        return this.#monitorQueue.find(item=>item.requestID == requestID)
      }
      /*
        拣货码打印： 多个拣货码打印， 打印结束后，统一提交打印结果
          数组类型
      */
      print(printerName,isPreview,printObjectList){
        printObjectList?.forEach(printObject=>{
          if(this.checkExistByRequestID(printObject.requestID)){ // 已经在打印队列中，忽略重复打印
            return
          }
          this.#monitorQueue.push({requestID:printObject.requestID,done:false})//打印结果未返回
          // 视频号类型的电子面单采用视频号组件，其他系统内单据打印，使用菜鸟打印 TODO
          useWeixinPrintServiceStore().startPrint(printerName,printObject.requestID,printObject.printDataVo,isPreview, result=>this.onPrintFinishCallback(result))
        })
      }
      async onPrintFinishCallback(result){ // 打印结束回调， 所有的数据打印结束，通知后台已打印成功 printQueue
        // 回调打印结果
        let queueItem = this.getQueueItemRequestID(result.requestID)
        if(queueItem){
          queueItem.done = true // 标记打印完成
          queueItem.success = result.success //记录打印成功状态
        }
        
        this.#callback?.({
          type:'printed',
          success:result.success, //是否请求打印成功
          msg:result.msg //失败原因
        },queueItem)
        // 判断队列中是否全部打印完成
        
        let isWaiting =  this.#monitorQueue.some(item=>!item.done ) // 队列中不存在没有结果的数据
        if(!isWaiting){ // 打印队列已全部完成
          // 通过事件，触发，所有打印已完成，返回当前打印队列信息
          this.#callback?.({
            type:'done', //打印完成
            success:true, //是否请求打印成功
            msg:'success' //失败原因
          },this.#monitorQueue)
          this.#monitorQueue = [] // 打印完成，清空队列
        }
      }
    }
    
    export default NtPrintManager