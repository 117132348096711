
<template>
  <el-container class="padding nt-height-100">
    <el-aside width="250px" class="margin-right nt-box-panel nt-bg-white relative">
      <el-tooltip effect="dark" content="添加属性类型" placement="top-start">
        <el-button icon="Plus" @click="onAddClick(false/* 无上级 */)" class="nt-float-button-add" circle></el-button>
      </el-tooltip>
      <el-tree ref="refTree" class="nt-height-100 nt-list-node nt-no-tree-icon padding " nodeKey="id" style="overflow-y:auto;overflow-x: hidden;"
        :data="treeData"
        default-expand-all
        :props="{label:'attrName',children:null}"
        :indent="0"
        @node-click="onAttrTypeChange">
        <template #default="{ node, data }">
          <el-row justify="space-between" class="nt-width-100" align="middle">
            <span>{{ node.label }}</span>
            <el-space :size="5" class="nt-operation" v-if="data.id">
              <el-icon size="large" @click.stop="onRowEditClick(data,false)"><Edit /></el-icon>
              <el-popconfirm title="确定删除?" @confirm.stop="onRowDeleteClick(data)">
                <template #reference>
                  <el-icon size="large" ><Delete /></el-icon>
                </template>
              </el-popconfirm>
            </el-space>
          </el-row>
        </template>
      </el-tree>
    </el-aside>
    <el-main class=" padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="no-margin no-padding height-auto display-flex space-between">
          <el-form inline @keyup.enter.native="onSearchClick" class="no-margin-bottom">
            <el-form-item label="属性名">
              <el-input placeholder="请输入属性名查询" v-model="queryModel.attrName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick">查询</el-button>
            </el-form-item>

          </el-form>
          <div>
            <el-button @click="onAddClick(true/*默认当前上级 */)" type="primary">新增</el-button>
          </div>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table height="100%" border ref="table" class="nt-resource-table"  :data="datasource" v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left"> </el-table-column>
            <el-table-column label="属性名称" prop="attrName"></el-table-column>
            <!-- <el-table-column label="属性值" prop="attributeValue" >
              <template #default="{row}">
                <el-space :size="5">
                  <el-tag v-for="(item,index) in getTagList(row)" :key="index" :closable="false" type="primary">{{ item }}</el-tag>
                </el-space>
                
              </template>
            </el-table-column> -->
            <nt-operation-column label="操作" fixed="right" width="55" align="center" 
              :confirm="{DELETE:'是否确认删除?'}"
              :disableExpr="{DELETE:null}" :hidden="['VERIFY','ENABLE','DISABLE','UNVERIFY','DEFAULT']" 
              @command="onColumnOperationAction">
              <template #extra>
              </template>
            </nt-operation-column>
            
          </el-table>
        </el-main>
      </el-container>
    </el-main>
  </el-container>
  <!-- <el-container class="nt-height-100 nt-bg-page">
    <el-aside class="margin-right nt-box-panel">
      
    </el-aside>
    <el-main class="no-padding">
      
    </el-main>
    
    <el-footer class="height-auto no-padding margin-top">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
    
  </el-container> -->
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :parent="hasParent?currentType:null" :entityId="currentRecord?.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import detailPage from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "GoodsAttrList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {
      currentType:null,
      isDetailVisible:false,
      currentRecord:null,
      hasParent:false,
      treeData:[]
    }
  },
  async created(){
    let res = await this.getEntityList(this.EnumEntityType.GOODS_ATTR, {parentId:''}) // 过滤根
    if(res.data){
      this.treeData = res.data
      // 默认选择第一个
    }
    console.log(res)//
  },
  methods: {
    async _loadList(params) {
      if(!this.currentType){
        return
      }
      return await this.getPageEntityList(this.EnumEntityType.GOODS_ATTR, Object.assign({parentId:this.currentType.id},params));
    },
    onAttrTypeChange(row){
      if(this.currentType == row){
        return
      }
      this.currentType = row
      this.loadList()
    },
    onAddClick(hasParent){
      this.hasParent = hasParent
      this.currentRecord = null
      this.isDetailVisible = true
    },
    // getTagList(row){
    //   if(!row.attributeValue){
    //     return []
    //   }
    //   return row.attributeValue.split("|")
    // },
    onSearchClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onRowEditClick(record,hasParent=true) {
      console.log('has parent', hasParent)
      this.currentRecord = record
      this.hasParent = hasParent
      this.parent = hasParent? this.currentType: null
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.GOODS_ATTR, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>