
<!-- 
  横线配置面板
 -->
 <template>
  <el-form ref="formRef" :model="config" label-position="top" size="small" @change="onPropertyChangeHandler">
    
    <el-form-item prop="style" label="线条类型">
      <el-select v-model="config.borderStyle">
        <el-option value="solid" label="实心"></el-option>
        <el-option value="dashed" label="长虚线"></el-option>
        <el-option value="dotted" label="短虚线"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="">
      <span class="margin-right">是否打印</span><el-switch v-model="config.isHidden" />
    </el-form-item>
    <el-form-item label="线条高度">
      <el-input v-model="config.borderWidth"></el-input>
    </el-form-item>
    <el-form-item label="位置">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.left" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.top" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="大小">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.width" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.height" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    
  </el-form>
</template>

<script>
// import {ref} from 'vue'
// import {usePageDesignerStore} from '../usePageDesignerStore'
export default {
  data () {
    return {
      // store:usePageDesignerStore()
    }
  },
  props:{
    config:{}
  },
  created(){
    // console.log('xxxxxxx', this.store)
  },
  methods:{
  }
}
</script>