import { axios as service } from '@/utils/request'


export const queryList = (data) => {
  return service({
    url: '/dispatch/queryList',
    method: 'post',
    data
  })
}
export const getDispatch = (data) => {
  return service({
    url: '/dispatch/getById',
    method: 'post',
    data
  })
}
export const createDispatch = (data) => {
  return service({
    url: '/dispatch/create',
    method: 'post',
    data
  })
}

export const queryCreateResult = (data) => {
  return service({
    url: '/dispatch/queryCreateResult',
    method: 'post',
    data,
    donNotShowLoading:true,
    catchTimeout:true,//自定义参数
    timeout:15*1000
  })
}

