/* 拖拽分栏指令 */
export default {
  mounted:function(el,directiveBinding){
    // 在元素右侧植入拖拽元素
    // 生成拖拽组件包裹的元素
    let elSpliter = document.createElement("div")
    elSpliter.className='nt-spliter'
    elSpliter.style.left = (el.offsetWidth +16) + 'px'
    el.parentNode.classList.add('relative')
    el.parentNode.insertBefore(elSpliter,el)

    let lastWidth = el.offsetWidth
    // 展开按钮控制元素
    let elColapse = document.createElement("i")
    elColapse.className = "nt-spliter-colapse iconfont icon-zhankaigengduo"
    elColapse.style.left = (el.offsetWidth) + 'px'
    let isColapse = false
    elColapse.addEventListener('click',_=>{
      // 点击收起，展开，切换幻想
      // 收起 left=20px 
      console.log('last width', lastWidth)
      if(isColapse){ // 收起状态
        elColapse.classList.remove('close')
        el.style.width = lastWidth +'px';
        elColapse.style.left = lastWidth + 'px'
        
        elSpliter.style.left = (lastWidth+16) + 'px';
      } else {
        elColapse.classList.add('close')
        elColapse.style.left = '10px'
        elSpliter.style.left = (10+16) + 'px';
        el.style.width =  '0px';
      }

      isColapse = !isColapse
    })
    el.parentNode.insertBefore(elColapse,el)


    let dragging = false;
    let startX;
    let startWidth
    elSpliter.addEventListener('mousedown',startResize)
    function startResize(e) {
      dragging = true;
      startX = e.clientX;
      startWidth = el.offsetWidth;
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', stopResize);
    }

    function resize(e) {
      if (dragging) {
        el.parentNode.classList.add('unselectable')
        const diffX = e.clientX - startX;
        const newWidth = startWidth + diffX;
        el.style.width = newWidth + 'px';
        elColapse.style.left = newWidth + 'px'
        elSpliter.style.left = (newWidth+16) + 'px';
        // lastWidth = newWidth
      }
    }

    function stopResize() {
      dragging = false;
      lastWidth = el.offsetWidth
      console.log('stope', lastWidth)
      el.parentNode.classList.remove('unselectable')
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResize);
      // TODO  根据当前路由+name 属性 缓存拖拽后的宽度
    }
    
  },
  unmounted:function(el){
    // console.log('directive unmounted', el)
  }
}