import * as dayjs from 'dayjs'
import { getPageEntityList, getEntityList, deleteEntity, deleteEntityBatch, updateEntityStatus, batchUpdateEntityStatus, setEntityDefault } from '@/service/resource/general'
import { EnumEntityType, EnumBillType, EnumBizType } from '@/utils/enum_utils'
// import VueDraggableResizable from 'vue-draggable-resizable'
import NtResultDialog from '@/views/plugin/nt_batch_result_dialog' //批量操作结果弹窗
import NtBillListToolbar from '@/views/plugin/nt_bill_list_toolbar'
import NtListLayout from '@/views/layout/nt_list_layout'

import moment from 'moment'
export const DataListMixin = {
  components: {
    // VueDraggableResizable
    NtResultDialog,NtListLayout,NtBillListToolbar
  },
  data() {
    return {
      datasource: [],
      storeList:[],
      viewOnly: false,
      EnumEntityType,
      EnumBillType,
      EnumBizType,
      isLoading: false,
      queryModel: {},
      pageInfo: { pageIndex: 1, pageSize: 10, total: 0 },
      isDetailVisible: false,
      resetPage: false,
      selectedRows: [], 
    }
  },
  async mounted() {
    this._initialize && (await this._initialize()) // 触发初始化事件
    this.loadList()

  },
  methods: {
    moment,
    getPageEntityList, getEntityList, deleteEntity, deleteEntityBatch, updateEntityStatus, batchUpdateEntityStatus, setEntityDefault,// 公共查询分页列表
    async loadList(params) {
      this.resetPage && (this.pageInfo.pageIndex = 1)
      this.isLoading = true

      if (this._loadList) {
        // 查询条件过滤掉空值
        const queryCondition = Object.entries(this.queryModel).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        }, {});
        
        let res = await this._loadList(Object.assign({}, params, queryCondition, { pageIndex: this.pageInfo.pageIndex, pageSize: this.pageInfo.pageSize }))
        if (res && res.code === 200) {
          this.datasource = res.data.list || res.data
          this.pageInfo.total = res.data.total || 0
        } else if (res) {
          this.$message.error(res.msg || res.message || '请求异常')
        }
      }

      this._loadFinished && this._loadFinished()
      this.isLoading = false
    },
    formatFenTotal(total) { // 格式化金额：分
      return ((total || 0) / 100).toFixed(2)
    },

    onSelectionChange(rows){
      this.selectedRows = rows
    },
    onToolbarClick(cmd){
      switch (cmd) {
        case 'ADD': // 新增
          this.onRowEditClick && this.onRowEditClick()
          break;
        case 'EXPORT': // 导出
          break;
        case 'PRINT': // 打印
          break;
        case 'VERIFY': // 审核
          this.onBatchVerifyClick && this.onBatchVerifyClick(true)
          break;
        case 'UNVERIFY': // 弃审
          this.onBatchVerifyClick && this.onBatchVerifyClick(false)
          break;
        case 'CLOSE': // 关闭订单
          this.onBatchCloseClick && this.onBatchCloseClick()
          break;
        case 'ACTIVATE': // 撤销关闭-激活订单
          this.onBatchActivateClick && this.onBatchActivateClick()
          break;
        
        default:
          break;
      }
    },
    async onColumnOperationAction(cmd,row){
      switch (cmd) {
        case 'EDIT': // 编辑
          this.onRowEditClick && this.onRowEditClick(row)
          break;
        case 'DELETE': // 删除
          this.onRowDeleteClick && this.onRowDeleteClick(row)
          break;
        case 'VERIFY': // 审核
          this.onVerifyClick && this.onVerifyClick(row,1)
          break;
        case 'UNVERIFY': // 弃审
          this.onVerifyClick &&this.onVerifyClick(row,0)
          break;
        case 'ENABLE': // 启用
          this.onRowEnabledClick && this.onRowEnabledClick(row,1)
          break;
        case 'DISABLE': // 停用
          this.onRowEnabledClick && this.onRowEnabledClick(row,0)
          break;
        case 'DEFAULT':
          this.onRowSetDefaultClick && this.onRowSetDefaultClick(row)
          break
        default:
          this.$message.error("COMMAND" + cmd + "not suppot")
          break;
      }
    },
    onPageSizeChange(val) {
      this.pageInfo.pageSize = val
      this.loadList()
    },

    onPageIndexChange(val) {
      this.pageInfo.pageIndex = val
      this.loadList()
    },
    formatDate(dt, fmt = 'YYYY-MM-DD HH:mm:ss') {
      if(!dt){
        return
      }
      return dayjs(dt).format(fmt)
    },
    formatShortDate(dt) {
      return this.formatDate(dt,'YYYY-MM-DD')
    },
    
    onDetailSaveSuccessHandler() { // 详情保存触发方法
      this.isDetailVisible = false
      this.loadList()
    },
  
    async initStore() {
      let res = await getEntityList(EnumEntityType.STORE, {})
      this.storeList = res.data
    },
  }
}
