<!-- 可拖拽网格
  https://github.com/decoin1987/vue-table-resize
 -->
<template>
  <div class="table-wrapper" ref="refContainer" :style="{left:padding?.left+'px',right:padding?.right+'px'}">
    <el-table class="custom_bill_print_table" empty-text=" " border  @click="onTableClick" height="100%" :style="{borderWidth:config.borderWidth}"
    :header-cell-style="getHeaderTextStyle"
    :row-style = "getContentRowStyle"
    :cell-style="getContentCellStyle"
    :span-method="createColumnSpan"
     @header-dragend="onColumnWidthChange"  :data="mockTableData">
      <el-table-column  v-for="(col,index) in tableColumns" :label="col.label" :width="col.width" align="center">
        <template #header>
          <div>
            <span >{{ col.label }}</span>
            <span v-if="isDesignMode" style="height:10px;overflow: hidden;cursor: pointer;" @click="onHeaderClick(index)"><el-icon color="var(--el-color-primary)"><EditPen /></el-icon></span>
          </div>
          
          <el-popconfirm   title="确定移除当前列?" @confirm="onColumnRemoveClick(index)" >
            <template #reference>
              <el-icon v-if="isDesignMode" class="right-top" size="10" color="white" style="background:var(--el-color-danger);cursor: pointer;" ><Close></Close></el-icon>
            </template>
          </el-popconfirm>
          
        </template>
        <!-- 根据属性配置，嵌套对象解析， 需注意控制换行--内容超长字体缩小** -->
        <template #default="{row}">
          {{parseObjectValue(row,col.prop)}}
        </template>
      </el-table-column>
    </el-table>
    <el-button v-if="isDesignMode" style="position: absolute;top:0px;right:-40px;" circle type="info" @click="onAddCloumnClick"><el-icon><Plus /></el-icon></el-button>
  </div>
  <el-dialog
      v-model="dialogVisible" destroyOnClose width="500px"
      class="nt-resource-dialog"
      title="添加列"
      :close-on-click-modal="false"
      @close="dialogVisible=false"
    >
    <column-selector @select="onColumnItemsChanged"  @cancel="dialogVisible =false" :config="config" v-if="dialogVisible"></column-selector>
  </el-dialog>
  
  <el-dialog title="列样式设置"  class="nt-resource-dialog"
      v-model="columnDialogVisible" destroyOnClose width="500px"
      :close-on-click-modal="false"
      @close="dialogVisible=false" append-to-body
    >
    <column-style-editor @success="onColumnStyleChange" :config="editColumn" @cancel="columnDialogVisible =false"  v-if="columnDialogVisible"></column-style-editor>
  </el-dialog>
</template>

<script >
import {getReflectValue} from '@/utils/reflect'
import ColumnSelector from '../selector/bill_column_selector'
import ColumnStyleEditor from '../selector/bill_column_style'

export default {
  data () {
    return {
      mockTableData: this.datasource,
      tableColumns: this.config?.columns,
      dialogVisible:false,
      columnDialogVisible:false,
      editColumnIndex: null,
      editColumn:null
    }
  },
  components:{ColumnSelector,ColumnStyleEditor},
  props:{
    config:{
      type:Object,
      default:{}
    },
    isDesignMode:{ // 设计模式
      type: Boolean,
      default: true
    },
    subtotalRows:{ // 显示业务单据时，根据业务数据传入当前页需要显示的合计数（合计1，页小计1)
      type:Number,
      default:0
    },
    pageIndex:{
      type: Number,
      default:1
    },
    pageSize:{
      type: Number
    },
    datasource:{
    },
    bodyHeight:{ // 单据明细高度 mm
      type:Number
    },
    padding:{
      type:Object,// 页面编辑
    }
  },
  watch:{
    bodyHeight:function(){
      // 单据明细高度发生变化时，重新计算容纳的行数
      this.createMockTableData()
    },
    'config.columns':function(){
      this.tableColumns = [...this.config.columns] // 构建新对象，避免数据循环修改异常
      this.createMockTableData()
      
    },
    config:function(){
      this.createMockTableData()
    }
  },
  mounted(){
    // 根据合计配置信息，记录合并起始列索引 级 夸列表合并结束索引
    if(this.config.subtotal){
      let matchColIndex = this.config.columns.findIndex(item=>item.isSubtotalText)
      if(!matchColIndex == -1 && this.config.columns.length>0){
        matchColIndex = 0
      }
      if(matchColIndex>=0){
        let col = this.config.columns[matchColIndex]
        // 合计行，跨列合并范围
        this._merge_cell = {start: matchColIndex, end: matchColIndex + parseInt(col.colspan||2)}
      }
    }
    
    setTimeout(_=>{
      this.createMockTableData()
    },0)
  },
  methods:{
    parseObjectValue(row,prop){
      //嵌套属性解析--数组尚不支持
      if(!prop){
        return
      }
      return getReflectValue(row,prop)
    },
    createMockTableData(){ // 设计模式下，网格配置信息发生变化触发网格数据行自动重新计算
      if(!this.isDesignMode){
        return
      }
      if(!this.bodyHeight || this.bodyHeight<=0){
        return
      }
      
      // 注意： 在自适应高度模式下，仅显示两行，切bodyheight自动切换自动模式
      let rowCount = Math.floor((this.bodyHeight-(this.config.titleHeight||8))/(this.config.contentHeight||10))
      if(this.config.autoHeight){
        rowCount = 2 // 高度自适应模式下，仅显示标题行 + 1示意行
      }
      let nData = new Array(rowCount);
      for(let idx =0;idx<nData.length;idx++){
        nData[idx]={} // null 数组，表格不渲染
      }
      this.mockTableData = nData
      this.fillSubtotalText()
      
    },
    createColumnSpan({rowIndex,columnIndex}){ // 用户创建页末合计行，夸多列合并单元格
      if(!this._merge_cell){ // 无需合并
        return
      }
      let colspan = this._merge_cell.end - this._merge_cell.start // 跨列数
      if(this.isDesignMode){ // 设计模式下，按配置规则显示合计/小计行
        if(rowIndex==this.mockTableData.length-1){ // 网格明细总合计：仅末页显示
          if(columnIndex == this._merge_cell.start){
            return {rowspan:1,colspan: colspan} // [1,2]
          } else if(columnIndex>this._merge_cell.start && columnIndex<this._merge_cell.end){
            return [0,0]
          }
          
        }
        if(this.config.subtotalSetting?.subPage && rowIndex==this.mockTableData.length-2){ // 是否显示 页小计
          if(columnIndex == this._merge_cell.start){
            return {rowspan:1,colspan: colspan} // [1,2]
          } else if(columnIndex>this._merge_cell.start && columnIndex<this._merge_cell.end){
            return [0,0]
          }
        }

      } else { // 预览模式下
        if(this.subtotalRows > 0 && rowIndex==this.mockTableData.length-1){
          if(columnIndex == this._merge_cell.start){
            return {rowspan:1,colspan: colspan} // [1,2]
          } else if(columnIndex>this._merge_cell.start && columnIndex<this._merge_cell.end){
            return [0,0]
          }
        }
        if(this.subtotalRows > 1 && rowIndex==this.mockTableData.length-2){
          if(columnIndex == this._merge_cell.start){
            return {rowspan:1,colspan: colspan} // [1,2]
          } else if(columnIndex>this._merge_cell.start && columnIndex<this._merge_cell.end){
            return [0,0]
          }
        }
      }
    },
    fillSubtotalText(){ // 填充合计文本信息
      if(!this.isDesignMode) return

      if(this.mockTableData.length>0 && this.config.columns?.length>0){
        let matchCol = this.config.columns?.find(item=>item.isSubtotalText)
        if(!matchCol){
          matchCol = this.config.columns[0]
        }
        
        // 判断是否需要 当前页小计
        let lastRow = this.mockTableData[this.mockTableData.length-1]
        lastRow[matchCol.prop] = this.config.subtotal? '合计' :''
        if(this.config.subtotalSetting?.subPage){
          let subPageRow = this.mockTableData[this.mockTableData.length-2]
          subPageRow[matchCol.prop] = '当前页小计'
        }
      }
    },

    getHeaderTextStyle({columnIndex}){
      // 如果当前列没有自定义对象方式则以网格的标题配置样式
      let style = Object.assign({},this.config.headerTextStyle) // textSyle
      style.height = this.config.titleHeight + 'mm'
      style.lineHeight = this.config.titleHeight + 'mm'
      style.fontSize = style.fontSize + 'px'
      style.borderColor= '#000'
      style.borderRight='0px solid #000'
      style.borderWidth = this.config.borderWidth  // 0px 1px 细 粗2px
      let col = this.config.columns[columnIndex]
      if(col.titleStyle?.textAlign){
        style.textAlign = col.titleStyle.textAlign // 对齐方式，列有自定义按列的设置，否则以全局配置
      }
      return style
    },
    getContentRowStyle(){
      
      return { height: this.config.contentHeight + 'mm'}
    },
    getContentCellStyle({rowIndex,columnIndex}){
      let col = this.config.columns[columnIndex]
      let style = {
        borderRight:`${this.config.borderWidth} solid #000`,
        borderColor:'#000',
        borderWidth: this.config.borderWidth
      }

      let ocupyRows = 0 // 合计辅助行数
      if(this.isDesignMode){ // 设计模式下按配置信息
        if(this.config.subtotal){
          ocupyRows ++
        }
        if(this.config.subtotalSetting?.subPage){
          ocupyRows ++
        }
      } else { // 预览实际数据，根据传入参数动态计算
        ocupyRows = this.subtotalRows
      }
      
      let subtotalStyle = {}
      // 合计行，页小计行样式处理
      let bizDataRows =  this.mockTableData.length - ocupyRows // 实际业务数据行数（含空填充行）
      if(rowIndex >= bizDataRows){ // 超出业务数据行，则为合计辅助行
        if(this._merge_cell?.start == columnIndex){ // 判断当前列是否为合并起始列
          subtotalStyle = Object.assign({},this.config.subtotalSetting.textStyle) // 合计文本样式
        } else {
          subtotalStyle = Object.assign({},this.config.subtotalSetting.contentStyle) // 合计结果样式
        }
        if(subtotalStyle.fontSize){
          subtotalStyle.fontSize += 'px'
        }
      }

      return Object.assign(style,col.textStyle,subtotalStyle)
    },
    
    onTableClick(){
      this.$emit('click')
    },
    onAddCloumnClick(){
      this.dialogVisible=true
    },
    onHeaderClick(colIndex){
      // 点击标题栏时，弹出标题栏配置编辑窗口
      this.editColumn = this.config.columns[colIndex]
      this.editColumnIndex = colIndex
      this.columnDialogVisible = true
    },
    onColumnStyleChange(config){
      this.config.columns[this.editColumnIndex] = config // Object.assign({},config)
      this.tableColumns = []
      // 重新创建新对象，迫使页面刷
      setTimeout(_=>this.tableColumns = [...this.config.columns],0) // 将当前直接放入执行队列，再重新更新内存地址
      
      this.columnDialogVisible =false
    },
    onColumnWidthChange(newW,oldW,column){
      this.config.columns[column.getColumnIndex()].width = newW
    },
    onColumnRemoveClick(index){
      this.config.columns.splice(index, 1)
      this.tableColumns = [...this.config.columns]
    },
    parseText(row,expr){
      return getReflectValue(row,expr)
    },
    getPageData(){
      if(!this.datasource){
        return [{}]
      }
      let startIndex = this.pageIndex*this.pageSize
      let list = this.datasource.slice(startIndex, startIndex + this.pageSize)
      return list
    },
    onColumnItemsChanged(column){
      // 根据弹窗栏目列表选择的发生变化的列信息，由当前组件根据是否存在做切换操作
      console.log('fking column', column)
      let matchIndex = this.config.columns.findIndex(item=>item.prop == column.prop)
      if(matchIndex == -1){
        this.config.columns.push(column)
      } else {
        this.config.columns.splice(matchIndex,1)
      }
      // 注意：网格el-table 直接挂 config.columns ，导致直接修改columns时报死循环：当前使用中间变量
      this.tableColumns = [...this.config.columns]
    }
    
  }

}
</script>
<style lang="less" scoped>
  .table-wrapper{
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
  }
</style>