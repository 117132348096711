<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title>费用支出单</template>
          <template #extra>
            <el-button class="el-menu-item" @click="jumpToPage('/financial/expense_out_list', '支出单列表')">单据列表</el-button>
            <!-- 直接生成付款单-预付款 -->
            <el-dropdown split-button @command="onCommandClick" @click="onPaymentClick" v-if="formModel.status==1">付款
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :disabled="!formModel.settleTotal" command="PAYMENT_HISTORY">付款记录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row  >
          <el-col :span="6">
            <nt-form-item prop="bizType" label="业务类型" :rules="formValidator('required', '往来类型')">
              <el-select v-model="formModel.bizType" :disabled="viewOnly">
                <!-- 现金收入类型： 强制要求录入结算账户，直接核销完毕
                往来收入： 与供应商或客户的资金往来，可在费用输入结算账户，或通过 收付款单进行核销 （直接输入结算账户，视同结算完毕，不记录往来单位的应收应付款） -->
                <el-option value="FINANCIAL_FEE_INNER" label="现金收入" />
                <el-option value="FINANCIAL_FEE_COMPANY" label="往来收入" />
              </el-select>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="companyId" label="往来单位" :rules="formValidator('required', '往来单位')">
              <nt-dropdown-grid configKey="company" v-model="formModel.companyId"
                v-model:text="formModel.companyName" :disabled="viewOnly" placeholder="请选择往来单位" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly"  style="width:100%"/>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          
        </el-row>
        <el-row  >
          <el-col :span="6">
            <nt-form-item prop="paymentMethodId" label="结算方式" :rules="formValidator('required', '请选择结算方式')">
              <nt-dropdown-grid configKey="paymentMethod" style="width:100%" v-model="formModel.paymentMethodId" v-model:text="formModel.paymentMethodName"
                :disabled="viewOnly" placeholder="请选择结算方式" :searchable="true" :iconSearch="false" @choose="onPaymentMethodChoose"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="accountId" label="支出账户" >
              <nt-dropdown-grid configKey="account" v-model="formModel.accountId" v-model:text="formModel.accountName"
                :disabled="viewOnly" placeholder="请选择支出账户" :searchable="true" :iconSearch="false" @choose="onAccountChoose"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="total" label="支出金额">
              <el-input placeholder="请输入支出金额" v-model="formModel.total" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="handlerId" label="财务人员">
              <nt-dropdown-grid configKey="staff" v-model="formModel.handlerId" style="width:100%"
                v-model:text="formModel.handlerName" :disabled="viewOnly" placeholder="请选择财务人员" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="备注" prop="remark">
              <el-input placeholder="请输入备注" v-model="formModel.remark" />
            </nt-form-item>
          </el-col>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.incomeItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="支出项目" width="200" prop="financialItemId">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`incomeItemList[${scope.$index}].financialItemId`" v-if="!viewOnly"
                :rules="formValidator('required', '请选择支出项目')">
                <el-select placeholder="选择支出项目" v-model="scope.row.financialItemId">
                  <el-option v-for="(item, index) in inOutItemList" :key="index" :value="item.id"
                    :label="item.itemName">
                  </el-option>
                </el-select>
              </nt-form-item>
              <div v-else>{{ scope.row.financialItemName }}</div>
            </template>
          </el-table-column>

          <el-table-column label="金额" width="160" prop="total" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`incomeItemList[${scope.$index}].total`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入金额')">
                <el-input v-model="scope.row.total" />
              </nt-form-item>
              <span v-else>{{ scope.row.total }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`incomeItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>

        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-drawer v-model="accountItemVisible" destroyOnClose size="65%" class="nt-resource-dialog" title="付款历史" :z-index="99"
    @close="accountItemVisible = false">
    <account-item-list @cancel="accountItemVisible = false" :billType="billType" :billId="formModel.id"
      v-if="accountItemVisible"></account-item-list>
  </el-drawer>
</template>
<script>

import NtBillLayout from '@/views/layout/nt_bill_layout'
import { DataBillMixin } from '@/utils/data_bill_mixin'
import formValidator from '@/utils/formValidator' // 通过调用 formValidator('required|max(10)','自定义消息') 生成表单项校验规则
import NtFormItem from '@/views/plugin/nt_form_item'
import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'
// 付款历史
import AccountItemList from '@/views/financial/selector/account_item_list'

export default {
  name: "expense",
  mixins: [DataBillMixin],
  components: {
    NtFormItem,
    NtBillToolbar,
    NtBillLayout,
    AccountItemList
  },
  data() {
    return {
      fileList: [],
      inOutItemList: [],
      accountItemVisible: false,
      billType: 'FINANCIAL_EXPENSE',
      entityType:EnumEntityType.INCOME_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  methods: {
    
    onFormChangeHandler() {
      // 根据当前编辑信息，重新计算：实付金额
      let total = 0
      this.formModel.incomeItemList.forEach(el => total += parseInt(el.total))

      this.formModel.total = total
      this.$forceUpdate()
    },
    async _initialize() {
      let res = await this.getEntityList(this.EnumEntityType.FINANCIAL_ITEM, { ioType: 1 })
      if (res.code == 200) {
        this.inOutItemList = res.data
      }
    },

    async _createNewEntity() {
      return {
        data: {
          billType: this.billType,
          bizType:'FINANCIAL_FEE_INNER',
          incomeItemList:  [{ }],
          billTime: this.getDateTime()
        }
      }
    },
    async _loadEntity(params) {
      if (!this.entityId) {
        this.fileList = []
        return this._createNewEntity()

      } else {
        let res = await this.getEntity(this.entityType, { id: this.entityId })
        return res
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      if (this.formModel.incomeItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }
      // TODO  判断是否费用项目存在重复
      let matchItem = this.formModel.incomeItemList.find(el => !el.total)
      if (matchItem) {
        this.$message.error(`单据${matchItem.billNumber} 金额不能为0`)
        return false
      }

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, billType: this.billType }, this.formModel)

      postData = JSON.parse(JSON.stringify(postData))

      if (this.fileList && this.fileList.length > 0) {
        postData.fileName = this.fileList
      }

      return this.saveEntity(this.entityType, postData)
    },

    _loadFinished() {
    },

    onPaymentMethodChoose(val){
      if(!this.formModel.accountId &&  val.accountId){
        this.formModel.accountId = val.accountId
        this.formModel.accountName = val.accountName
      }
    },

    onAccountChoose(val){
      if(!this.formModel.paymentMethodId && val.paymentMethodId){
        this.formModel.paymentMethodId = val.paymentMethodId
        this.formModel.paymentMethodName = val.paymentMethodName
      }
    },

    onAddLineClick() {
      this.formModel.incomeItemList.push({})
    },
    async _copyBill() { // 复制单据
      // 引用单据时，清空明细关联信息
      return {
        id: '',
        status: null,
        billTime: this.getDateTime()
      }
    },

    onPaymentClick(){ // 生成付款单-预付款  参数refBill 通过meta 传递
      this.jumpToPage('/financial/pay', '付款单',{id:this.formModel.id,billType:this.billType,bizType:'FINANCIAL_PAY'})
    },

    onCommandClick(command){
      if(command == 'PAYMENT_HISTORY'){ // 付款历史
        this.accountItemVisible = true
      } 
    },

    getSummaries({columns,data}){
      let sumResult = []
      columns.forEach((column, index) => {
        if (index <2 ) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }
}
</script>