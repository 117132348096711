<!-- 计量单位及价格关联 -->
<template>
  
  <el-container class="nt-height-100 nt-bg-page">
    <el-aside class="nt-box-panel margin-right padding" width="65%">
      <div class="display-flex" >
        <el-form-item label="主单位" class="no-margin-bottom">
          <nt-dropdown-grid configKey="unit" v-model="formModel.unitId" v-model:text="formModel.unitName"
            :disabled="viewOnly" placeholder="请选择计量单位" :searchable="true" :iconSearch="false"
            @choose="onMiainUnitChangeHandler"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item  class="no-margin-bottom">
          <el-checkbox v-model="formModel.multipleUnit" :true-value="1" :false-value="0">开启多计量单位</el-checkbox>
          
        </el-form-item>
        <el-button type="primary" size="small" @click="onAddClick" v-if="formModel.multipleUnit">添加单位</el-button>
      </div>
      <el-table ref="refTable" style="--el-table-current-row-bg-color:var(--el-table-row-hover-bg-color);" :data="formModel.goodsUnitPriceList" border @current-change="onUnitPriceTableChangeHandler"
        highlight-current-row class="nt-resource-table margin-top-8">
        <el-table-column label="#" width="45" type="index">
        </el-table-column> 
        <el-table-column width="40" v-if="!viewOnly" align="center">
          <template #default="scope">
            <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
              <template #reference>
                <el-icon v-if="scope.$index > 0">
                  <Delete />
                </el-icon>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column width="70" align="center">
          <template #default="scope">
            <span>{{ scope.$index==0? '主单位':'辅单位' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="unitId" :class-name="!viewOnly ? 'editor' : ''">
          <template #default="scope">
            <span v-if="scope.$index == 0">{{ scope.row.unitName }}</span>
            <nt-form-item v-else label-width="0" :prop="`goodsUnitPriceList[${scope.$index}].unitId`"
              :rules="formValidator('required', '请选择单位')">
              <el-select placeholder="选择单位" v-model="scope.row.unitId" :disabled="scope.$index == 0">
                <el-option v-for="(item, index) in unitList" :key="index" :value="item.id"
                  :label="item.unitName"></el-option>
              </el-select>
            </nt-form-item>
          </template>

        </el-table-column>
        <el-table-column label="换算率" prop="exchangeRate" :class-name="!viewOnly ? 'editor' : ''">
          <template #default="scope">
            <span v-if="scope.$index==0"></span>
            <nt-form-item label-width="0" :prop="`goodsUnitPriceList[${scope.$index}].exchangeRate`"
              v-else-if="!viewOnly">
              <el-input v-model="scope.row.exchangeRate" />
            </nt-form-item>
            <span v-else>{{ scope.row.exchangeRate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="换算单位" width="85">
          <template #default="scope">
            {{ scope.$index==0? '': formModel.unitName }}
          </template>
        </el-table-column>
        <el-table-column label="包装条码" width="120">
          <template #default="scope">
            <nt-form-item label-width="0" :prop="`goodsUnitPriceList[${scope.$index}].barcode`"
              v-if="!viewOnly">
              <el-input v-model="scope.row.barcode" />
            </nt-form-item>
            <span v-else>{{ scope.row.barcode }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="16" class="margin-top">
        <el-col :span="12">
          <el-form-item label="采购单位" >
            <!-- <el-select placeholder="选择仓库" v-model="scope.row.storeId" @change="onStoreChangeHandler(scope.row)">
                  <el-option v-for="(item, index) in storeList" :key="index" :label="item.storeName" :value="item.id">
                    {{ item.storeName }}
                  </el-option>
                </el-select> -->
            <el-select v-model="formModel.purchaseUnitId">
              <el-option v-for="item in seletedUnitList" :value="item.id" :label="item.unitName" :key="`p_${item.id}`"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="销售单位" >
            <el-select v-model="formModel.saleUnitId">
              <el-option v-for="item in seletedUnitList" :value="item.id" :label="item.unitName" :key="`s_${item.id}`"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="零售单位">
            <el-select v-model="formModel.retailUnitId">
              <el-option v-for="item in seletedUnitList" :value="item.id" :label="item.unitName" :key="`r_${item.id}`"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="库存单位" >
            <el-select v-model="formModel.stockUnitId">
              <el-option v-for="item in seletedUnitList" :value="item.id" :label="item.unitName" :key="`s_${item.id}`"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报表辅助单位" label-width="110">
            <el-select v-model="formModel.rptUnitId">
              <el-option v-for="item in seletedUnitList" :value="item.id" :label="item.unitName" :key="`t_${item.id}`"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-aside>
    <el-main class="padding nt-box-panel">
      <el-table  border height="100%" :data="priceList"  class="nt-resource-table">
        <el-table-column label="价格类型" width="120" >
          <template #default="{row}">
            {{formatPriceTypeName(row.priceType)}}
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price">
          <template #default="{row}">
            <el-input v-model="row.price" @change="onPriceChangeHandler(row)"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
  import formValidator from '@/utils/formValidator' 
  import { getEntityList} from '@/service/resource/general'
  import { EnumEntityType } from '@/utils/enum_utils'

  const _PRICE_FIELD_LIST = [
    'retailPrice','vipPrice','normalVipPrice','salePrice','purchasePrice','purchasePriceLimit',
    'priceLevel1','priceLevel2','priceLevel3','priceLevel4','priceLevel5',
    'priceLevel6','priceLevel7','priceLevel8','priceLevel9','priceLevel10']
  const _PRICE_TYPE_INFO = {
    retailPrice:'零售价',vipPrice:'会员价',normalVipPrice:'普通会员价',salePrice:'批发价',purchasePrice:'采购价',purchasePriceLimit:'最高采购价',
    priceLevel1:'价格1',priceLevel2:'价格2',priceLevel3:'价格3',priceLevel4:'价格4',priceLevel5:'价格5',
    priceLevel6:'价格6',priceLevel7:'价格7',priceLevel8:'价格8',priceLevel9:'价格9',priceLevel10:'价格10'
  }
  export default {
    name: 'unit_price_detail',
    data () {
      return {
        currentUnitIndex:null,
        priceList:[],
        unitList:[],
        purchaseUnitId:null,
      }
    },
    props:{
      formModel:{
        type:Object,
        required:true,
        default:{}
      }
    },
    watch:{
      formModel:function(){
        if(this.formModel.goodsUnitPriceList?.length>0){
          this.$refs.refTable.setCurrentRow(this.formModel.goodsUnitPriceList[0])
        }
      }
    },
    computed:{
      seletedUnitList:function(){
        if(!this.formModel.goodsUnitPriceList){
          return []
        }
        let list = this.formModel.goodsUnitPriceList.map(item=>{
          return {id:item.unitId,unitName:item.unitName}
        })
        return list
      }
    },
    async created(){
      let res = await getEntityList(EnumEntityType.GOODS_UNIT, {})
      if (res.code == 200) {
        this.unitList = res.data
      }
    },
    mounted(){
      if(this.formModel.goodsUnitPriceList?.length>0){
        this.$refs.refTable.setCurrentRow(this.formModel.goodsUnitPriceList[0])
      }
    },
    methods: {
      formValidator,
      formatPriceTypeName(priceType){
        return _PRICE_TYPE_INFO[priceType] || priceType
      },
      onAddClick() { // 添加计量单位
        if (!this.formModel.unitId) {
          this.$message.error("请选择主单位")
          return
        }
        this.formModel.goodsUnitPriceList.push({}) //第一次添加，默认主单位
      },
      onMiainUnitChangeHandler(val){ // 主计量单位变化时, 价格列表的主单位同步更新
        console.log('onMiainUnitChangeHandler',val)

        this.formModel.goodsUnitPriceList[0].unitId = val.id
        this.formModel.goodsUnitPriceList[0].unitName = val.unitName
        // this.$forceUpdate()
      },
      onUnitPriceTableChangeHandler(row){
        if(!row) return  // 删除行触发null
        let rowIndex = this.formModel.goodsUnitPriceList.findIndex(item=>item.unitId==row.unitId)
        this.currentUnitIndex = rowIndex
        let unitInfo = this.formModel.goodsUnitPriceList[rowIndex]
        let lstResult = []
        _PRICE_FIELD_LIST.forEach(key=>{
          lstResult.push({priceType:key,price:unitInfo[key]||0})
        })
        this.priceList = lstResult
      },
      onPriceChangeHandler(row){ // 当前行价格编辑后回写数据源
        let unitInfo = this.formModel.goodsUnitPriceList[this.currentUnitIndex]
        unitInfo[row.priceType] = row.price
      },
      onRowDeleteClick(rowIndex) {
        let item = this.formModel.goodsUnitPriceList.splice(rowIndex, 1)[0]
        if(this.formModel.purchaseUnitId == item.unitId){
          this.formModel.purchaseUnitId = ''
        }
        if(this.formModel.retailUnitId == item.unitId){
          this.formModel.retailUnitId = ''
        }
        if(this.formModel.saleUnitId == item.unitId){
          this.formModel.saleUnitId = ''
        }
        if(this.formModel.stockUnitId == item.unitId){
          this.formModel.stockUnitId = ''
        }
        if(this.formModel.rptUnitId == item.unitId){
          this.formModel.rptUnitId = ''
        }
        //重新定位行
      }
    }
  }
</script>
<style lang="less">
  .el-table__row.current-row{
    position:relative;
    &::after{
      content:" ";
      top:0px;
      bottom:0px;
      right:0px;
      width:4px;
      background:var(--el-color-primary);
      position:absolute;
      z-index:9;
    }
  }
</style>