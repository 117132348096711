<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16" >
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <nt-form-item  label="名称" prop="itemName" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入名称" v-model="formModel.itemName" />
        </nt-form-item>
        <nt-form-item  label="费用类型" prop="feeTypeId" :rules="formValidator('required','请选择类型')">
          <nt-dropdown-grid configKey="feeType" v-model="formModel.feeTypeId" :requestParam="{ioType:ioType}"
                    v-model:text="formModel.feeTypeName" :disabled="viewOnly" placeholder="请选择类型" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item  label="备注" class="no-margin-bottom">
          <el-input placeholder="请输入备注" v-model="formModel.remark"  />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
  
</template>
<script>
  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import NtFormItem from '@/views/plugin/nt_form_item'
  export default {
    name: "InOutItemModal",
    mixins: [DataEditMixin],
    components:{NtFormItem},
    data () {
      return {
        ioType: this.$route.meta.ioType, // 根据路由区分费用，收入
      }
    },
    props:{
      entityId:{
        type:String
      }
    },
    created () {
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.EnumEntityType.FINANCIAL_ITEM, {id: this.entityId})
        }
      },
      _loadFinished(){
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        
        return this.saveEntity(this.EnumEntityType.FINANCIAL_ITEM, postData)
      }
    }
  }
</script>
<style scoped>

</style>