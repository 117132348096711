<!--
  拖拽容器，接收组件
-->
<template>
	<draggable v-model="list"  item-key="id" style="position: absolute;left:0px;top:0px;right:0px;bottom:0px;"
    :group="{ name: 'nt-printer-svc', pull: false, put: true }" @change="onListChanged"   
    @add="onItemsAddedHandler" ghost-class="nt-ghost-item" draggable="必须写不存在class，以此禁止拖拽">
    <template #item="{ element,index }">
      <VueDragResize class="design-item" 
        :key="`head_el_${element.id}`"
        @click.stop
        :isActive="element == current"
        :w="element.width" :h="element.height"
        :min-height="5" :min-width="5"
        :parent="true"
        :sticks="getResizeIcons(element._t)"  
        :x="element.left" 
        :y="element.top"  
        @resize-stop="(left,top,width,height) => {
          element.left = left
          element.top = top
          element.width =width
          element.height = height}" 
        @drag-stop="(left,top) => {
          element.left = left
          element.top = top
          }"
        @keydown="e=>onKeyDownHandler(e,element)" tabindex="0"
        @activated="_ => this.$emit('change',element)">
        <div class="print-item" >
          <component :is="ctrl[element._t]" :config="element" />
          <el-icon class="icon-close" size="10"
            @click.prevent="_ => onRemoveHandler(index)" ><Close></Close></el-icon>
        </div>
        <span class="print-item-label">{{element.label}}</span>
      </VueDragResize>
    </template>
  </draggable>
</template>

<script >
import draggable from 'vuedraggable'
import VueDragResize from 'vue-draggable-resizable' // https://github.com/mauricius/vue-draggable-resizable
import 'vue-draggable-resizable/style.css'
import { qr_code, barcode, text, v_line, h_line, rectangle, sys_text } from '../control/all'

export default {
  data () {
    return {
      ctrl: { barcode, qr_code, text, v_line, h_line, rectangle, sys_text },
      list: this.modelValue,
      current: this.selected,
    }
  },
  components: { draggable, VueDragResize },
  emits: ['update:modelValue'], // v-model  配套使用  modelValue
  props:{
    modelValue:{},
    selected:{
      type:Object
    }
  },
  watch:{
    modelValue(val){
      this.list = val
    },
    selected:function(){
      // console.log('selected change',this.selected)
      this.current = this.selected
    }
  },
  
  methods:{
    onKeyDownHandler(e,element){
      e.preventDefault()
      e.stopPropagation()
      // console.log('onKeyDownHandler',e.key, element)
      // return
      // if(!this.current){
      //   return
      // }
      if(e.key=='ArrowRight'){
        element.left++
      } else if(e.key=='ArrowLeft'){
        element.left--
      }
      else if(e.key=='ArrowUp'){
        element.top--
      } else if(e.key=='ArrowDown'){
        element.top++
      }
      console.log('onKeyDownHandler',e.key)
    },
    getResizeIcons: function (type) {
      let sticks = {
        v_line: ['tm', 'bm'],
        h_line: ['ml', 'mr'],
        qr_code: ['tl', 'tr', 'bl', 'br']
      }
      return sticks[type] || ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']
    },

    onItemsAddedHandler: function (evt) {
      let _event = event //一定要缓存，否则settime获取null
      let element = evt.item._underlying_vm_
      // console.log('xxxx', element)
      // element.left = _event.offsetX // 直接设置不会刷新UI 注意
      // element.top = _event.offsetY// 直接设置不会刷新UI 注意
      // 返回元素后，重新设置坐标----必须分开操作
      this.$emit('change', element,_event.offsetX,_event.offsetY)
    },

    onRemoveHandler: function (index) {
      this.list.splice(index, 1)
      this.$emit('change', null)
    },
    onListChanged(){
      console.log('list change', this.list)
      this.$emit('update:modelValue', this.list)
    }
  }
}

</script>
