import { axios as service } from '@/utils/request'


/**
 * 查询单据的付款历史
 * @param {*} data 
 * @returns 
 */
export const getAccountItemList = (data) => {
  return service({
    url: '/accountHead/itemList',
    method: 'post',
    data
  })
}

export const createRefundOrder = (data) => {
  return service({
    url: '/accountHead/refundOrder',
    method: 'post',
    data
  })
}



export const revokeRefundOrder = (data) => {
  return service({
    url: '/accountHead/revokeRefundOrder',
    method: 'post',
    data
  })
}

