
<template>
  <el-container class="padding nt-height-100 ">
    <el-aside class="margin-right-12 nt-box-panel nt-bg-white" v-spliter-directive>
      <categoryPage @change="onCategoryChangeHandler" />
    </el-aside>
    <el-main class="nt-box-panel padding">
      <el-container class="no-padding nt-height-100">
        <el-header class="no-margin no-padding height-auto display-flex space-between">
          <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
            <nt-form-item label="关键词">
              <el-input placeholder="请输入条码、名称、规格、型号查询" v-model="queryModel.materialParam"></el-input>
            </nt-form-item>
            <nt-form-item>
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            </nt-form-item>
          </el-form>
          <div>
            <el-button  @click="onAddClick" type="primary">新增</el-button>
          </div>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource" v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left"> </el-table-column>
            <el-table-column label="名称" width="220" prop="goodsName"></el-table-column>
            <el-table-column label="商品编码" width="220" prop="goodsCode"></el-table-column>
            <el-table-column label="条码" width="100" prop="barcode"></el-table-column>
            <el-table-column label="规格" width="100" prop="standard"></el-table-column>
            <el-table-column label="型号" width="100" prop="modelType"></el-table-column>
            <el-table-column label="参考成本" width="100" prop="costPrice"></el-table-column>
            <el-table-column label="所属分类" width="100" prop="categoryName"></el-table-column>
            <el-table-column label="主单位" width="100" prop="unitName"></el-table-column>
            <el-table-column label="基础重量" width="100" prop="weight"></el-table-column>
            
            <el-table-column label="仓位货架" width="100" prop="position"></el-table-column>
            <el-table-column label="备注" width="220" prop="remark"></el-table-column>
            <el-table-column label="状态" width="120" prop="enabled">
              <template #default="scope">
                <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
                <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
              </template>
            </el-table-column>
            <nt-operation-column label="操作" fixed="right" width="55" align="center" 
              :confirm="{DELETE:'是否确认删除?'}"
              :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
              @command="onColumnOperationAction">
              <template #extra>
              </template>
            </nt-operation-column>
            
          </el-table>
        </el-main>
        <el-footer class="height-auto margin-top no-padding">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>

    </el-main>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="75%" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page ref="modalForm" @ok="isDetailVisible = false" :entityId="currentRecord.id"
      @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>

import detailPage from './detail'
import { getGoodsCategoryListByParent } from '@/service/resource/goods_category'
import categoryPage from '../goods_category'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "GoodsList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    categoryPage,
    NtFormItem,
  },
  data() {
    return {
      categoryTree: [],
      currentRecord: {},
      categoryId:null
    }
  },
  created() {
    this.loadTreeData();
  },
  methods: {
    async _loadList(params) {
      if(this.categoryId){
        params.cascadeCategoryId = this.categoryId // 迭入多层级查询数据
      }
      return await this.getPageEntityList(this.EnumEntityType.GOODS, params);
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.GOODS, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onCategoryChangeHandler(category) {
      this.categoryId = category.id
      this.loadList()
    },

    async loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      let res = await getGoodsCategoryListByParent({ parentId: '-1' })
      this.categoryTree = res.data
    },

    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },
    onRowEditClick: function (record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadData();
    },
    async loadCategoryNode(node, resolve) {
      let parentId = '-1'

      if (node.level != 0) {
        parentId = node.data.id
      }
      let res = await getGoodsCategoryListByParent({ parentId: parentId })
      resolve(res.data)
    },
  }
}
</script>