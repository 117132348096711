import { axios as service } from '@/utils/request'


export const syncErpOrder = (data) => {
  return service({
    url: '/online/syncErpOrder',
    method: 'post',
    data
  })
}
