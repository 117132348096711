<template>
  <el-dialog v-model="isVisible" destroyOnClose width="850" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog1" @close="isVisible = false" title="打印拿货标签">
    <el-container class="padding nt-box-panel">
      <el-header class="height-auto no-padding no-margin ">
        <div class="display-flex ">
          <div style="flex-basis:33%"  :class="`relative padding-8 nt-bg-white  space-between ${selectedTpl==tpl?'nt-bottom-right-check':''}`" @click="onTemplateClick(tpl)" v-for="tpl in printTemplateList"
            :key="tpl.id">
            {{ tpl.templateName }}
          </div>
        </div>
      </el-header>
      <el-main class="no-padding margin-top" style="height:350px;">
        <el-table :data="datasource" border height="100%" class="nt-resource-table">
          <el-table-column type="index" width="45"></el-table-column>
          <el-table-column label="订单信息">
            <template #default="{ row }">
              <div>
                <div><span>订单号:</span><span>{{ row.orderNo }}</span></div>
                <div v-if="row.waybillNo"><span>运单号:{{ row.packageNum }}:</span>
                  <span>{{ row.waybillNo }}</span>
                </div>
                <div v-else>
                  <span>包裹#{{ row.packageNum }}:</span>
                  <span >待取号</span>
                </div>
                <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品数量" prop="quantity" width="110">
          </el-table-column>
          <el-table-column label="操作结果" width="140">
            <template #default="{ row }">
              <div>
                <span v-if="!processStatus">就绪</span>
                <span v-if="processStatus==1">正在获取..</span>
                <span v-if="[2,3].includes(processStatus)">共获取{{packageInfo[`${row.orderId}-${row.packageNum}`]}}个拣货码</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="height-auto no-padding no-margin">
        <!-- <div class="margin-top">共{{ datasource.length }}个运单</div> -->
        <div class="align-center margin-top">
          <nt-printer v-model="printerName" style="width:220px" placeholder="请选择打印机" class="margin-right"></nt-printer>
          <el-button type="primary" v-if="processStatus==3" @click="isVisible=false"> 已完成</el-button>
          <el-button v-else type="primary" :loading="[1,2].includes(processStatus)" @click="onConfirmClick">
            {{ formatProcessStatus() }}
          </el-button>
        </div>
      </el-footer>
    </el-container>
    <print-preview ref="refPreview"></print-preview>
  </el-dialog>
</template>

<script>
import {queryTemplateList,setDefaultTemplate} from '@/service/print_order/template'
import { createPickingCode } from '@/service/print_order/picking'
import NtPrintManager from '@/utils/print_manager' // 拣货码打印管理器
import NtWaybillTemplate from '@/views/plugin/nt_waybill_template'
import PrintPreview from '@/views/preview/dispatch'
import NtPrinter from '@/views/plugin/nt_printer'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      printTemplateList: [],
      selectedTpl: null,
      isVisible: false,
      printerName:null,
      datasource: [],
      packageInfo:{}, // 存放每个包裹的拣货码数量
      processStatus:null,
    }
  },
  components:{PrintPreview,NtPrinter,NtWaybillTemplate},
  async created() {
    // 扫码规则，控制是否打印市场档口
    let configObj = LoginUserStore().getConfig('PICKING_SETTING')
    this.configRule = JSON.parse(configObj?.configValue||null) || {sortMode:'POSITION',printPositionTag:true} 

    let res = await queryTemplateList({templateType:'PICKUP_TEMPLATE'})
    if(res.data){
      this.printTemplateList = res.data
      if(this.printTemplateList.length>0){
        this.selectedTpl = this.printTemplateList[0]
      }
    }
    this._printManager = new NtPrintManager((result,data /*打印结果：单个，全部完成为数组 */) => {
      // 一个包裹存在多个拣货码， 拣货码一个一页， 或是多个一页
      // 一个requestId 对应一个拣货码，据此找出对应的包裹是否全部打印完成 3/4 (失败：1) 显示进度 
      // 监控是否打印完成  （多个标签一页打印，目前暂无法统计每个标签的打印状态）
      if(result.type =='done'){// 全部打印完成，注意存在多次批次打印，如面单，每次可能只返回n面单生成结果，通过轮询不断触发
        this.processStatus = 3
      }
    })
  },
  methods: {
    showDialog(packageList, callback) {
      this.processStatus = null
      this._callback = callback
      this.datasource = packageList
      this.isVisible = true
    },
    formatProcessStatus() {
      if(this.processStatus == 1){
        return '正在获取拣货码...'
      }
      else if(this.processStatus == 2){
        return '正在打印...'
      }
      else if(this.processStatus == 3){
        return '已完成'
      }
      else{
        return '生成并打印拣货码'
      }
    },
    onTemplateClick(tpl) {
      this.selectedTpl = tpl
    },
    
    async onConfirmClick(){
      if(!this.printerName){
        this.$message.warning('请选择打印机')
        return
      }
      if(!this.selectedTpl){
        this.$message.warning('请选择打印模版')
        return
      }
      this.processStatus = 1
      let postData = this.datasource.map(el=>({orderId:el.orderId,packageNum:el.packageNum}))
      let res = await createPickingCode(postData) // order_package info
      if(res.code == 200){
        // 更新每个包裹的拣货码数量...
        let allList = []
        // 市场 与拣货码 主从关系
        res.data.forEach(el=>{
          if(this.configRule.printPositionTag){ // 配置规则，是否打印市场档口
            allList.push({
              _injected:true, // 加入特殊标记识别
              marketName:el.marketName,
              position:el.position
            })
          }

          el.children?.forEach(item=>{
            let key = `${item.orderId}-${item.packageNum}`
            if(this.packageInfo[key]){
              this.packageInfo[key] += 1
            } else {
              this.packageInfo[key] = 1
            }
            item.goodsMode= item.goodsCount>1? '多':'单'
            item.scanName= item.isScan? '屯' :''
            // item.shortName= item.goodsShortName // '商品简称'
            item.skuAttrs =  this.formatSkuAttr(item.skuAttrs) // sku 简称
            // item.skuShortName = item.skuShortName
            item.marketPosition = (item.marketName||'') + '-' + (item.position||'') // 数据为返回
          })

          allList.push(...el.children)
        })

        if(allList.length == 0){
          this.processStatus = null
          this.$alert('市场档口资料尚未配置，请先设置')
          return
        }

        // 进入打印状态
        this.processStatus = 2
        let printObjectList = []
        let requestID = (new Date()).getTime()
        
        // this.$refs.refPreview.show('打印预览',{
        //   documents: [{details:list}],
        //   templateId: this.selectedTpl.id,
        //   listNode:'details'
        // })
        // return
        // 每个标签每次发起一页打印
        // allList.forEach(item=>{
        //   // TODO  测试数据 ，配置拣货模版，直接使用模版打印
        //   let packInfo = {requestID: requestID++}
        //   packInfo.printDataVo = {
        //     templatePlatform:'SYSTEM',
        //     templateId: this.selectedTpl.id,
        //     listNode:'details', // 网格单据节点
            
        //     printData:{
        //       details:[item]
        //     }
        //   }
        //   printObjectList.push(packInfo)
        // })

        // 所有的标签一次打印
        let packInfo = {requestID: requestID++}
        packInfo.printDataVo = {
          templatePlatform:'SYSTEM',
          templateId: this.selectedTpl.id,
          listNode:'details', // 网格单据节点
          printData:{
            details:allList
          }
        }
        printObjectList.push(packInfo)
        
        // return
        this._printManager.print(this.printerName,false,printObjectList)

      } else {
        this.processStatus = null
        this.$message.error(res.msg || '操作失败')
      }
      
    },
    onPrintFinishCallback(result){
      // 统计打印结果
      console.log('onPrintFinishCallback',result)
    },
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
  }
}
</script>
<style lang="less" scoped>
.custom-waybill {
  flex-wrap: wrap;

  &>div {
    cursor: pointer;
    flex: 0 0 calc(50% - 8px);
    background-color: #f5f5f5;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 5px;

    .el-icon {
      font-size: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}
</style>