<!-- 拣货规则管理 -->
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto">
      <div class="nt-box-panel" style="color:#8c8c8c;font-size:14px;background-color:var(--el-color-primary-light-9);">
        <ul  style="list-style:auto;">
          <li>拿货标签是一种商品标签。您提前维护好商品档口信息，选择的订单的每1件商品会打印1个商品标签，并且自动按档口分类。</li>
          <li class="margin-top-4 margin-bottom-4">您只需要把拿货标签分别送到对应档口，让档口按标签配货，实现多档口同时拿货</li>
          <li>拿货后，扫描拿货标签即可识别到对应订单，一键打印快递单并发货 。</li>
        </ul>
      </div>
      <!-- <div>
        1. 合理的设置分拣码可以极大提高使用拿货标签商品的拣货效率<br/>
        2. 如订单符合多个分拣码规则，按序号取排序靠前的规则生成分拣码。鼠标按住可拖动排序<br/>
        3. 分拣码最终格式为 A-2-1；A为您设置的规则，表示对应的订单包含N件商品；2表示含有N件商品的所有订单中的第2个订单；1表示当前订单的第1件商品
      </div> -->
    </el-header>
    <el-main class="padding margin-top nt-box-panel nt-bg-white">
      <el-form   ref="frmEdit" class="nt-height-100">
        <el-table height="100%"  ref="table" class="nt-resource-table" :data="datasource" :show-header="false"
          v-loading="isLoading" >
          <el-table-column label="#" width="45" type="index">
          </el-table-column>
          <el-table-column label="分拣码名称" width="260">
            <template #default="scope">
              <nt-form-item label="分拣码名称" :prop="`datasource[${scope.$index}].ruleName`"
                :rules="formValidator('required', '请输入名称')">
                <el-input v-model="scope.row.ruleName"></el-input>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="匹配条件" width="220">
            <template #default="scope">
              <nt-form-item label="匹配条件" :prop="`datasource[${scope.$index}].ruleCondition`"
                :rules="formValidator('required', '请选择匹配条件')">
                <el-select  v-model="scope.row.ruleCondition" >
                  <el-option v-for="item in conditionList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="商品数量满足" width="220">
            <template #default="scope">
              <nt-form-item label="商品数量满足" :prop="`datasource[${scope.$index}].goodsQuantity`"
              :rules="formValidator('required', '请输入商品数量')">
              <el-input v-model="scope.row.goodsQuantity"></el-input>
            </nt-form-item>
            </template>
            
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template #default="scope">
              <el-button size="small" type="primary" @click="onSaveRowClick(scope.row,scope.$index)">保存</el-button>
              <el-button size="small" type="danger" @click="onDeleteRowClick(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
      </el-form>
      
    </el-main>
    <el-footer class="height-auto align-center margin-top-8">
      <SettingPage></SettingPage>
      <el-button @click="onAddClick" type="primary">添加规则</el-button>
    </el-footer>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import {getPickingRuleList,savePickingRule,deletePickingRule,updatePickingRuleSort} from '@/service/resource/general'
import NtFormItem from '@/views/plugin/nt_form_item'
import formValidator from '@/utils/formValidator' // 通过调用 formValidator('required|max(10)','自定义消息') 生成表单项校验规则
import SettingPage from './setting'
export default {
  name: "PickingRuleList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    SettingPage
  },
  data() {
    return {
      conditionList:[
        {label:'等于',value:'='},
        {label:'小于',value:'<'},
        {label:'小于等于',value:'<='},
        {label:'大于',value:'>'},
        {label:'大于等于',value:'>='},
        {label:'小于',value:'<'},
      ]
    }
  },
  methods: {
    formValidator,
    async _loadList(params) {
      return getPickingRuleList(Object.assign({},params))
    },
    onAddClick(){
      this.datasource.push({})
    },
    async onSaveRowClick(row,index){
      row.sortIndex = index;
      let res = await savePickingRule(row)
      if(res.code===200){
        row.id = res.data 
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg || '保存失败')
      }
    },
    async onDeleteRowClick(row,index){
      let res = await deletePickingRule({id:row.id})
      if(res.code===200){
        this.datasource.splice(index,1)
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg || '保存失败')
      }
    }
  }
}
</script>