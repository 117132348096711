/**
 * 
 * 设计器配置文件：
 *  1.配置组件拖拽后初始化数据
 *  2.左侧组件库
 */

export const Controls = {
    text:{//文本组件
        _t:'text',
        v:'请输入文本',
        width:100,
        height:25,
        left:100
    },
    sys_text:{//系统内置文本组件
        _t:'sys_text',
        v:'${1001}',
        width:200,
        height:25,
        left:100
    },
    
    qr_code:{//二维码组件
        _t:'qr_code',
        v:'123',
        width:100,
        height:100,
        aspectRatio:true
    },
    barcode:{//条码组件
        _t:'barcode',
        v:'123',
        width:200,
        height:60,
        aspectRatio:false
    },
    v_line:{//竖线
        _t:'v_line',
        v:'',
        width:50,
        height:100,
        style:"solid"
    },
    h_line:{//横线
        _t:'h_line',
        v:'',
        width:100,
        height:5,
        style:"solid"
    },
    image:{//图片组件
        _t:'image',
        v:'/xxxx',
        width:100,
        height:100
    },
    rectangle:{//矩形组件
        _t:'rectangle',
        v:'',
        width:100,
        height:100
    },
}

export const ControlList = [
    {
        key:'sys_text',
        icon:'icon-icon-customvar',
        label:'业务字段'
    },
    {
        key:'text',
        icon:'icon-wenben',
        label:'文本'
    },
    // {
    //     key:'image',
    //     icon:'icon-tupian_huaban',
    //     label:'图片'
    // },
    {
        key:'barcode',
        icon:'icon-tiaoma2',
        label:'条码'
    },
    {
        key:'qr_code',
        icon:'icon-erweima',
        label:'二维码'
    },
    {
        key:'h_line',
        icon:'icon-hengxian1',
        label:'横线'
    },
    {
        key:'v_line',
        icon:'icon-shuxian',
        label:'竖线'
    },
    {
        key:'rectangle',
        icon:'icon-juxing2',
        label:'矩形'
    }
]

/*
    组件配置项：
        等比例缩放
        仅横向缩放 横线
        仅纵向缩放  竖线
 */
export const ControlOption = [
    {
        key:'text',
        icon:'icon',
        text:'文本组件'
    },
    {
        key:'image',
        icon:'icon',
        text:'图片组件'
    },
    {
        key:'barcode',
        icon:'icon',
        text:'条码组件'
    },
    {
        key:'qrCode',
        icon:'icon',
        text:'二维码组件'
    }
]