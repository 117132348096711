<!-- 订单操作日志 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin">
      <div>任务中心： 现系统导出的文件，都在此处下载查看。下载链接仅保存3天，超过3天请去“来源”界面重新导出</div>
      <el-space wrap>
        <span>查询时间</span>
        <el-date-picker
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :size="size"
        />
        <el-button size="small">查询</el-button>
        
      </el-space>
      
    </el-header>
    <el-main class=" no-padding nt-box-panel">
      <el-table :data="[{}]" height="100%">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="来源"></el-table-column>
        <el-table-column label="状态"></el-table-column>
        <el-table-column label="操作人"></el-table-column>
        <el-table-column label="任务时间"></el-table-column>
        <el-table-column label="操作">
          <template #default="{row}">
            <el-button>点击下载</el-button>
            <el-button>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top">
      <el-row justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" small
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>


export default {
  name: "BIZ_COMPANY",
  components: {
    
  },

  data() {
    return {
      radio1:null,
      value:null
    }
  },
  methods: {
  }
}
</script>