
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form layout="inline" @keyup.enter.native="onSearchQueryClick" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <el-row :gutter="24">
          <el-col :md="5" :sm="24">
            <el-form-item label="仓库">
              <el-select
                mode="multiple" :maxTagCount="1"
                optionFilterProp="children"
                showSearch style="width: 100%"
                placeholder="请选择仓库"
                v-model="storeSelected">
                <el-option v-for="(store,index) in storeList" :value="store.id" :key="index">
                  {{ store.storeName }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="24">
            <el-form-item label="商品信息"  >
              <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24" >
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            </span>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-form-item>
              <span>总库存：{{currentStock}}，总库存金额：{{currentStockPrice}}，总重量：{{currentWeight}}</span>
            </el-form-item>
          </el-col>
          <template v-if="toggleSearchStatus">
            <el-col :md="5" :sm="24">
              <el-form-item label="类别" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-tree-select style="width:100%" :dropdownStyle="{maxHeight:'200px',overflow:'auto'}" allow-clear
                                :treeData="categoryTree" v-model="queryParam.categoryId" placeholder="请选择类别">
                </el-tree-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="仓位货架" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-input style="width: 100%" placeholder="请输入仓位货架查询" v-model="queryParam.position"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="零库存" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select v-model="queryParam.zeroStock">
                  <el-option value="0">隐藏</el-option>
                  <el-option value="1">显示</el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        ref="table"
        size="middle"
        rowKey="goodsSkuId"
        :data="datasource"
        v-loading="isLoading"
        >
          <el-table-column label="操作" width="120" >
            <template #default="scope">
              <el-space>
                <a @click="showMaterialInOutList(scope.row)">{{scope.row.goodsSkuId?'流水':''}}</a>
                <a @click="showStockDetailList(scope.row)">{{scope.row.goodsSkuId?'详情':''}}</a>
              </el-space>
            </template>
          </el-table-column>
          <el-table-column label="名称" width="220" prop="goodsName">
          </el-table-column>
          <el-table-column label="规格" width="120" prop="standard">
          </el-table-column>
          <el-table-column label="型号" width="120" prop="model">
          </el-table-column>
          <el-table-column label="颜色" width="80" prop="color">
          </el-table-column>
          <el-table-column label="类别" width="100" prop="categoryName">
          </el-table-column>
          <el-table-column label="仓位货架" width="120" prop="position">
          </el-table-column>
          <el-table-column label="主单位" width="120" prop="unitName">
          </el-table-column>
          <el-table-column label="库存" width="80" prop="quantity">
          </el-table-column>
          <el-table-column label="库存金额" width="90" prop="total">
            <template #default="scope">
              {{ (scope.row.price*scope.row.quantity)/100.00  }}
            </template>
          </el-table-column>
          <el-table-column label="重量" width="80" prop="currentWeight">
          </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-rpt-layout>
  
  <el-drawer
    v-model="detailVisible" destroyOnClose size="75%" append-to-body
    class="nt-resource-dialog"
    title="详情"
    :z-index="99"
    @close="detailVisible=false"
  >
    <material-in-out-list  @cancel="detailVisible =false"  :materialId="currentRecord.id" v-if="detailVisible"></material-in-out-list>
  </el-drawer>
  <el-drawer
    v-model="stockDetailVisible" destroyOnClose size="65%"
    class="nt-resource-dialog"
    title="存货分布详情"
    :z-index="99"
    @close="stockDetailVisible=false"
  >
    <stock-detail-list  @cancel="stockDetailVisible =false"  :materialId="currentRecord.id" v-if="stockDetailVisible"></stock-detail-list>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
  import {getMaterialStockList,getGoodsSkuStockList} from '@/service/report/rpt_stock'
  import {getEntityList} from '@/service/resource/general'
  import {EnumEntityType} from '@/utils/enum_utils'
  import MaterialInOutList from './flow'
  import StockDetailList from './detail'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import { getAction, getFileAccessHttpUrl } from '@/api/manage'
  import {queryMaterialCategoryTreeList} from '@/api/api'
  export default {
    name: "MaterialStock",
    mixins:[DataListMixin],
    components: {
      MaterialInOutList,
      StockDetailList
    },
    data () {
      return {
        detailVisible:false,
        stockDetailVisible:false,
        toggleSearchStatus:false,
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 18,
          offset: 1
        },
        // 查询条件
        queryParam: {
          categoryId:'',
          materialParam:'',
          position:'',
          zeroStock: '0',
        },
        currentWeight:0,
        storeSelected:[],
        storeList: [],
        categoryTree:[],
        currentStock: '',
        currentStockPrice: '',
        // 表头
        // columns: [
        //   {
        //     title: '#', dataIndex: 'rowIndex', width:40, align:"center",
        //     customRender:function (t,r,index) {
        //       return (t !== '合计') ? (parseInt(index) + 1) : t
        //     }
        //   },
        //   {title: '库存流水', dataIndex: 'action', align:"center", width: 120,
        //     scopedSlots: { customRender: 'action' }
        //   },
        //   // {title: 'id', dataIndex: 'id', width: 100},
        //   {title: '名称', dataIndex: 'goodsName', width: 140, ellipsis:true},
        //   {title: '规格', dataIndex: 'standard', width: 100, ellipsis:true},
        //   {title: '型号', dataIndex: 'model', width: 100, ellipsis:true},
        //   {title: '颜色', dataIndex: 'color', width: 60, ellipsis:true},
        //   {title: '类别', dataIndex: 'categoryName', width: 60, ellipsis:true},
        //   {title: '仓位货架', dataIndex: 'position', width: 60, ellipsis:true},
        //   {title: '主单位', dataIndex: 'unitName', width: 60, ellipsis:true},
        //   {title: '单价', dataIndex: 'price', width: 60,
        //     customRender:function(t,r,index){
        //       return ((r.price||0)/100).toFixed(2)
        //     }
        //   },
        //   // {title: '初始库存', dataIndex: 'initialStock', sorter: (a, b) => a.initialStock - b.initialStock, width: 60},
        //   {title: '库存', dataIndex: 'quantity', sorter: (a, b) => a.currentStock - b.currentStock, width: 60,
        //     customRender:function(t,r,index){
        //       return r.quantity
        //     }
        //   },
        //   {title: '库存金额', dataIndex: 'total', sorter: (a, b) => a.total - b.total, width: 80,
        //     customRender:function(t,r,index){
        //       return (r.price*r.quantity)/100.00
        //     }
        //   },
        //   {title: '重量', dataIndex: 'currentWeight', sorter: (a, b) => a.currentWeight - b.currentWeight, width: 60}
        // ]
      }
    },
    async created() {
      // this.getStoreData()
      let res = await getEntityList(EnumEntityType.STORE,{})
      if(res.code == 200){
        this.storeList = res.data
      }
    },
    methods: {
      loadTreeData(){
        let that = this;
        let params = {};
        params.id='';
        queryMaterialCategoryTreeList(params).then((res)=>{
          if(res){
            that.categoryTree = [];
            for (let i = 0; i < res.length; i++) {
              let temp = res[i];
              that.categoryTree.push(temp);
            }
          }
        })
      },
      async _loadList(params){
        return await await getMaterialStockList(Object.assign(params))
      },
      onSearchQueryClick() {
        this.loadList();
      },
      showMaterialInOutList(record) {
        this.currentRecord = record;
        this.detailVisible = true
      },
      showStockDetailList(record) {
        this.currentRecord = record;
        this.stockDetailVisible = true
      },
      
      exportExcel() {
        let aoa = [['条码', '名称', '规格', '型号', '颜色', '类别', '单位', '单价', '初始库存', '库存', '库存金额', '重量']]
        for (let i = 0; i < this.dataSource.length; i++) {
          let ds = this.dataSource[i]
          let item = [ds.mBarcode, ds.name, ds.standard, ds.model, ds.color, ds.categoryName, ds.unitName,
            ds.purchaseDecimal, ds.initialStock, ds.currentStock, ds.currentStockPrice, ds.currentWeight]
          aoa.push(item)
        }
        openDownloadDialog(sheet2blob(aoa), '商品库存')
      }
    }
  }
</script>

<style scoped>
  @import '~@assets/less/common.less'
</style>
<style scoped>
  .item-info {
    float:left;
    width:30px;
    height:30px;
    margin-left:8px
  }
  .item-img {
    cursor:pointer;
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>