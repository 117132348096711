
<template>
  <el-container class="padding-8 nt-height-100 nt-bg-page">
    <!-- <el-header class="height-auto align-right">
      <el-button type="primary" @click="onAddClick"> 添加</el-button>
    </el-header> -->
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading" @selection-change="onSelectionChange">
        <el-table-column label="价格区间">
          <template #default="{row}">
            <span>{{row.startPrice}}-{{ row.endPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="物流公司" width="160">
          <template #default="{row}">
            <span>{{formatDelivery(row.deliveryId)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="面单模版" width="160">
          <template #default="{row}">
            <span>{{row.templateName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="启用状态" width="85" align="center">
          <template #default="{row}">
            <span v-if="row.enabled">启用</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="{row}">
            <el-button size="small" type="primary" link  @click="onRowEditClick(row)">修改</el-button>
            <el-popconfirm title="确定当前选中数据?" width="220" @click="onRowDeleteClick(row)" >
              <template #reference>
                <el-button size="small" type="danger" link  >删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <price-range-detail @success="onDetailSaveSuccessHandler"  :platform="platform" @cancel="isDetailVisible = false" :entityId="currentRecord?.id"
      v-if="isDetailVisible"></price-range-detail>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getDeliveryPriceList,deleteDeliveryPrice} from '@/service/print_order/platform_waybill_template'
import PriceRangeDetail from './detail/price_range_detail'
import {EnumDelivery} from '@/utils/enum_utils'
export default {
  name: "MarketList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    PriceRangeDetail
  },
  data() {
    return {
      isDetailVisible:false,
      currentRecord:null
    }
  },
  props:{
    platform:{
      type:String
    }
  },
  watch:{
    platform:function(){
      this.loadList()
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.platform){
        return
      }
      return await getDeliveryPriceList(Object.assign({platform:this.platform},params));
    },
    onDetailSaveSuccessHandler(){
      this.isDetailVisible = false
      this.loadList()
    },
    onRowEditClick(row){
      this.currentRecord = row
      this.isDetailVisible = true
    },
    addDetail(){
      this.currentRecord = null
      this.isDetailVisible = true
    },
    formatDelivery(deliveryId){
      return EnumDelivery[deliveryId] || deliveryId
    },
    async onRowDeleteClick(row){
      let res = await deleteDeliveryPrice({id:row.id})
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>