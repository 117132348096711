/*
  动态组件生成器
*/
const _all_editor_list = {
  company: import('@/views/selector/company'), // erp系统商品资料
  goods: import('@/views/selector/erp_goods'), // erp系统商品资料
}

export const   createAdvanceSelecorComponent = async function(resourceName){
  // import 路径不支持变量，仅支持 字符串常量
  // import(/* webpackChunkName: "dashboard" */ '@/views/print_order').then(module=>{
  //   console.log(module.default)
  // })
  if(!_all_editor_list[resourceName]){
    return null
  }
  let vuePage = await _all_editor_list[resourceName]
  return vuePage.default
  // return  resolve => require([vuePath], resolve) // 不通
  // let fking = await asyncFn()
  
}