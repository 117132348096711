<template>
  <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading" >

    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white" style="overflow:hidden;">
        <nt-form-item  label="角色名称" prop="roleName" :rules="formValidator('required', '选择输入角色名称')">
          <el-input placeholder="请输入角色名称" v-model="formModel.roleName"  />
        </nt-form-item>
        <nt-form-item  label="排序">
          <el-input placeholder="请输入排序" v-model="formModel.sort"   />
        </nt-form-item>
        <nt-form-item  label="备注" class="no-margin-bottom">
          <el-input placeholder="请输入备注" v-model="formModel.description"  />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
  
</template>
<script>
  import {autoJumpNextInput} from "@/utils/util"
  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import NtFormItem from '@/views/plugin/nt_form_item'
  export default {
    name: "roleInfo",
    mixins: [DataEditMixin],
    components:{
      NtFormItem
    },
    data () {
      return {
        
      }
    },
    props:{
      entityId:{
        type:String
      }
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.EnumEntityType.ROLE, {id: this.entityId})
        }
      },
      _loadFinished(){
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        
        return this.saveEntity(this.EnumEntityType.ROLE, postData)
      },
    }
  }
</script>