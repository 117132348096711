<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom  display-flex `" :style="{columnGap:'0px'}">
    <!-- <div class="config-label" >{{ label }}</div> -->
    <el-select :class="`${getHighlightClass('queryType')}`" v-model="qType" v-bind="$attrs"  size="small" :style="{width:'100px'}" @change="onSelectChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" >{{item.label}}</el-option>
    </el-select>
    <el-input v-model="value" :class="`${getHighlightClass('value')}`" size="small" :placeholder="tips" clearable :style="{width:'188px'}" @change="onTextChange"></el-input>
  </div>
  
</template>

<script>

  export default {
    data () {
      return {
        value: this.modelValue?.value,
        qType:this.modelValue?.queryType,
        tips:this.placeholder,
        defaultValue: Object.assign({},this.modelValue)
      }
    },
    props:{
      modelValue:{},
      options:{
        type:Array,
        default:_=>[]
      },
      queryType:{},
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.value = this.modelValue?.value
        this.qType =this.modelValue?.queryType
      }
    },
    created(){
      let matchItem = this.options.find(item=>item.value == this.qType)
      this.tips = matchItem?.placeholder
    },
    methods:{
      getHighlightClass(type){
        if(type=='queryType'){
          return (this.defaultValue?.queryType||'') != (this.qType||'')? 'highlight':''
        } else {
          return (this.defaultValue?.value||'') != (this.value||'')? 'highlight':''
        }
        
      },
      _triggerChange(){
        let data = {
          queryType: this.qType,
          value:this.value
        }
        this.$emit('update:modelValue',data)
      },
      onTextChange(){
        this._triggerChange()
      },
      onSelectChange(){
        let matchItem = this.options.find(item=>item.value==this.qType)
        this.tips = matchItem?.placeholder
        this._triggerChange()
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    .highlight{
      :deep(.el-select__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
      :deep(.el-input__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    :deep(.el-select__wrapper){
      // box-sizing: border-box;
      box-shadow: none;
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 3px 4px;
      background-color: var(--el-fill-color) !important;
      &.is-focused{
        border-color:transparent;
        box-shadow:0 0 0 1px var(--el-input-focus-border-color) inset !important;
      }
      
    }
    :deep(.el-input__wrapper){
      padding:0px 4px;
      height: 24px;
      box-shadow: none;
      box-sizing: border-box;
      border:1px solid var(--el-border-color);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      &.is-focus{
        border:none;
        box-shadow:0 0 0 1px var(--el-input-focus-border-color) inset;
      }
    }
    
    
  }
</style>