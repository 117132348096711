
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>销售出库统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="操作" width="85">
          <template #default="scope">
            <el-space>
              <a @click="onViewDetailClick(scope.row)">查看详情</a>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="goodsName" width="160">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <el-table-column label="型号" prop="model">
        </el-table-column>
        <el-table-column label="颜色" prop="color">
        </el-table-column>
        <el-table-column label="单位" prop="unitName">
        </el-table-column>
        <el-table-column label="SKU" prop="sku">
        </el-table-column>
        <el-table-column label="商品分类" prop="categoryName">
        </el-table-column>
        <el-table-column label="销售数量" prop="outQuantity" align="right">
        </el-table-column>
        <el-table-column label="退货数量" prop="backQuantity" align="right">
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" align="right">
          <template #default="scope">
            {{ ((scope.row.backTotal) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="实际销售数量" prop="quantity" width="120" align="right">
          <template #default="scope">
            {{ scope.row.outQuantity - scope.row.backQuantity }}
          </template>
        </el-table-column>
        <el-table-column label="实际销售金额" prop="total" width="120" align="right">
          <template #default="scope">
            {{ ((scope.row.outTotal - scope.row.backTotal) / 100).toFixed(2) }}
          </template>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getSaleOutTotalByGoods } from '@/service/report/rpt_sale'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getSaleOutTotalByGoods(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'SALE_OUT,SALE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

