<!-- 
  门店分组显示，复选，显示已勾选数量

  注意：对象命名须 采用 label的方式，否则外部传入自定义名称 shopName ，下拉框无匹配的信息，无法正常显示店铺名称
  
  -->
<template>
  <div :class="`nt-custom display-flex ${config?'config':''}`" :style="{columnGap:'0px',width: config?'': (width||140)+'px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <nt-select-shop :placeholder="placeholder" :style="{width:(width||140)+'px'}" v-model="value" multiple size="small" @defaultShop="onDefaultHandler" @change="onSelectChange"></nt-select-shop>
  </div>
</template>

<script>

  import NtSelectShop from '@/views/plugin/nt_select_shop'
  export default {
    data () {
      return {
        value: this.modelValue?.value,
        defaultValue: this.modelValue
      }
    },
    components:{NtSelectShop},
    props:{
      modelValue:{
      },
      width:{
        type:Number
      },
      
      placeholder:{},
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    methods:{
      onDefaultHandler(shop){
        this.value = shop
        this.onSelectChange()
      },
      onSelectChange(){
        let data = {
          value: this.value?.map(item=>item.shopId)
        }
        this.$emit('update:modelValue', Object.assign({},this.defaultValue, data))
      }
    }
}
</script>

<style lang="less" scoped>
  
  .nt-custom{
    
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-select){
        flex:1;
        .el-select__wrapper{
          box-shadow: none;
          
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
    
  }
</style>