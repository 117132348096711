<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom display-flex ${config?'config':''}`" :style="{columnGap:'0px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <nt-dropdown-grid :title="placeholder" class="dropdown" v-bind="props" :configKey="configKey" v-model="startValue" :requestParam="{}"  :style="{width:(width||140)+'px'}"
    v-model:text="endValue" :placeholder="placeholder" :size="size" :searchable="true" @choose="onSelectChange"
    :iconSearch="false"></nt-dropdown-grid>
  </div>
  
</template>

<script>

  export default {
    data () {
      return {
        startValue: this.modelValue?.startValue,
        endValue: this.modelValue?.endValue,
      }
    },
    props:{
      modelValue:{},
      defaultValue:{},
      configKey:{
        type:String,
        required:true
      },
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      size:{
        type:String,
        default:'small'
      },
      queryKey:{
        type:String,
        required:true
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
          console.log('start change', this.modelValue)
          this.startValue = this.modelValue?.startValue
          this.endValue = this.modelValue?.endValue
      }
    },
    methods:{
      getHighlightClass(){
        return this.defaultValue?.startValue != this.startValue? 'highlight':''
      },
      onSelectChange(){
        let data = {
          queryKey: this.queryKey,//'QUERY_TIME_RANGE',
          queryType: this.queryKey,// 'ORDER_TIME/PAYMENT_TIME/SEND_TIME/',
          valueType: 'RANGE',
          startValue: this.startValue, // '区间开始值'
          endValue: this.endValue // '区间开始值'
        }
        this.$emit('update:modelValue',data)
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    .highlight{
      :deep(.el-input__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .dropdown{
        flex:1;
      }
      .config-label{
        display: block;
      }
      :deep(.el-select){
        .el-select__wrapper{
          box-shadow: none;
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
  }
</style>