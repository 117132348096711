<!-- 结算方式映射 -->
<template>
  <el-container class="padding nt-height-100">
    <el-main class="no-padding" >
      <el-container class="nt-height-100">
        <el-aside class="margin-right nt-box-panel padding-8 nt-bg-white" width="200px">
          <nt-platform-shop-tree @change="onShopNodeClick"></nt-platform-shop-tree>
        </el-aside>
        <el-main class="no-padding nt-box-panel" >
          <el-container class="nt-height-100">
            <el-main class="no-padding">
              <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="datasource" default-expand-all
                :tree-props="{ children: 'paymentTypeList' }" v-loading="isLoading">
                <el-table-column label="支付方式" width="200" prop="paymentTypeName"></el-table-column>
                <el-table-column label="结算类型"  prop="erpPaymentMethodId" >
                  <template #default="{row}" >
                    <nt-dropdown-grid v-if="!row.paymentTypeList" configKey="paymentMethod" v-model="row.erpPaymentMethodId" v-model:text="row.erpPaymentMethodName" placeholder="请选择结算方式" 
          :searchable="false" @choose="(account) => onRowpaymentMethodChange(row,account.id)"></nt-dropdown-grid>
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </el-main>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getListWithPayment, saveShopKv } from '@/service/resource/platform_shop'
import ntPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
export default {
  name: "shop-kv-config",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    ntPlatformShopTree
  },
  data() {
    return {
      selectedShopId:null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedShopId) return
      return await getListWithPayment(Object.assign({shopId:this.selectedShopId},params))
    },
    onShopNodeClick(shop){
      this.selectedShopId = shop.id;
      this.loadList()
    },

    async onRowpaymentMethodChange(row,erpPaymentMethodId){
      let res = await saveShopKv({shopId:this.selectedShopId,configType:'PLATFORM_PAYMENT_METHOD', configKey:row.paymentType,configValue:erpPaymentMethodId})
      if(res.code == 200){
        this.$message.success('保存成功')
      } else{
        this.$message.error(res.msg || '保存失败')
      }
    }
  }
}
</script>