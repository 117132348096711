<template>
  <el-space class="nt-space">
    <el-icon :title="remarkInfo['1']" :class="{ntFlag:true,selected:(flags&1) ==1}" style="cursor:pointer;color:#FF4D4A;" :size="18" ><Flag @click.stop="onValueChangeHandler(1)"/></el-icon>
    <el-icon :title="remarkInfo['2']" :class="{ntFlag:true,selected:(flags&2) ==2}" style="cursor:pointer;color:#217EFF;"  :size="18"><Flag @click.stop=" onValueChangeHandler(2)"/></el-icon>
    <el-icon :title="remarkInfo['4']" :class="{ntFlag:true,selected:(flags&4) ==4}" style="cursor:pointer;color:#FFC619;"  :size="18"><Flag @click.stop=" onValueChangeHandler(4)"/></el-icon>
    <el-icon :title="remarkInfo['8']" :class="{ntFlag:true,selected:(flags&8) ==8}" style="cursor:pointer;color:#17BE6B;"  :size="18"><Flag @click.stop=" onValueChangeHandler(8)" /></el-icon>
  </el-space>
  
</template>

<script>
  /**
   * 旗帜标记
   */
  import {LoginUserStore} from '@/piana/login_user_service'
  export default {
    data () {
      return {
        flags:this.modelValue,
        remarkInfo: null,
      }
    },
    emits: ['update:modelValue'], // v-model  配套使用  modelValue
    props:{
      modelValue:{
      }
    },
    watch:{
      modelValue:function(){
        this.flags = this.modelValue
      }
    },
    created(){
      let config = LoginUserStore().getConfig('FLAG_REMARK')
      this.remarkInfo = JSON.parse(config?.configValue||null) ||{}
    },
    methods:{
      onValueChangeHandler:function(val){
        // 根据选中的值进行状态切换
        if ((this.flags & val) == val){
          this.flags = this.flags &~ val // 移除
        } else {
          this.flags = this.flags | val
        }
        
        this.$emit('update:modelValue', this.flags) // vue2 触发input 事件
      }
    }
}
</script>
<style scoped lang="less">
@keyframes flagBlink {
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
}
.ntFlag{
  opacity:.1;
  &.selected{
    opacity:1;
    font-size: 24px !important;
    animation: flagBlink 1s infinite alternate;
  }
}

</style>
