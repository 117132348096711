<template>
  <el-date-picker
    v-bind="$attrs"
    type="daterange"
    style="width:100%"
    unlink-panels
    @change="onDateChange"
    v-model="dateRange"
  />
</template>
<script>
  import moment from 'moment'
  export default {
    name: 'NTDateRange',
    // model: {
    //   prop: "startTime",
    //   event: 'change'
    // },
    emit:['update:startTime','update:endTime'],
    props: {
      // modelValue:{

      // },
      startTime:{
        type: String,
        required: false
      },
      endTime:{
        type: String,
        required: false
      },
      dateFormat:{
        type: String,
        default: 'YYYY-MM-DD HH:mm:ss',
        required: false
      }
    },
    data () {
      // let dateStr = this.value;
      return {
        decorator:"",
        dateRange:[],
        // momVal:!dateStr?null:moment(dateStr,this.dateFormat)
      }
    },
    watch: {
      startTime(){
        this.dateRange = [this.startTime,this.endTime]
      },
      endTime(){
        this.dateRange = [this.startTime,this.endTime]
      }
      // value (val) {
      //   if(!val){
      //     this.momVal = null
      //   }else{
      //     this.momVal = moment(val,this.dateFormat)
      //   }
      // }
    },
    created(){
      this.dateRange = [];
      if(this.startTime){
        this.dateRange.push(new moment(this.startTime))
      }
      if(this.endTime){
        this.dateRange.push(new moment(this.endTime))
      }
    },
    methods: {
      moment,
      onDateChange(dateRange){
        if(dateRange){
          this.$emit('update:startTime', moment(dateRange[0]).format(this.dateFormat) );
          this.$emit('update:endTime', moment(dateRange[1]).format(this.dateFormat));
        } else{
          this.$emit('update:startTime', null );
          this.$emit('update:endTime',null);
        }
        
      }
    },
    
  }
</script>
