<template>
  <el-tree-select ref="refTree" :data="datasource" v-model="value" node-key="id" check-strictly
  :props="props" :cache-data="cacheData" lazy :load="loadNode" @change="onTreeNodeChange" />
</template>
<script >

import {  getEntityList } from '@/service/resource/general'
import { EnumEntityType } from '@/utils/enum_utils'
import { getGoodsCategoryListByParent } from '@/service/resource/goods_category'
export default {
  
  data() {
    return {
      value: this.modelValue,
      cacheData: [{ value: this.modelValue, categoryName: this.text }],
      props: {
        label: 'categoryName',
        value:'id',
        children: 'children',
        disabled: (data,node)=>{ // 禁止选择自己-及其子孙节点, 即仅允许整个子树迁移，否则出现断层
          for(let dId of this.disabled){
            if(dId == data.id || (data.hierarchyInfo && data.hierarchyInfo.indexOf(dId)!=-1)){ // 当前节点，或其子孙节点都不能选择，即仅允许整个子树迁移，否则出现断层
              
              return true
            }
          }
          return false
        }
      },
      datasource: [],      
    }
  },
  emit: ['update:modelValue','update:text'],
  props: {
    modelValue: null,// 对象keytext:null,// key -value 用于文本框显示内容
    text: {
      type: String
    },
    disabled:{
      type:Array,
      default:_=>[]
    }
  },
  watch:{
    modelValue:function(){
      this.value = this.modelValue
      this.cacheData= [{ id: this.modelValue, categoryName: this.text }]
    },
  },
  methods: {
    async loadNode(node,resolve){
      let parentId = '-1'

      if(node.level!=0){
        parentId = node.data.id
      }
      let res = await getGoodsCategoryListByParent({parentId: parentId})
      resolve(res.data)
    },
    onTreeNodeChange(val){
      let node = this.$refs.refTree.getCurrentNode()
      this.$emit('update:modelValue',node.id)
      this.$emit('update:text',node.categoryName)
    }
  }
}
</script>
