<!-- 打单业务基础设置 -->
<template>
  <el-container class="padding nt-height-100">
    <el-aside width="200px" class="margin-right-8 nt-box-panel nt-bg-white">
      <el-menu
        :default-active="defaultMenu"
        class="nt-height-100"
      >
        <el-menu-item :index="item.key" v-for="item in options" @click="onPageChangeClick">
          <el-icon></el-icon>
          <span>{{item.label}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main class=" no-padding nt-box-panel">
      <component :is="dynamicPage"></component>
    </el-main>
  </el-container>
</template>
<script>
import {markRaw} from 'vue'
import OrderSortPage from './detail/order_sort'
import SenderPage from '@/views/address/send_address'
import WordMaskPage from './detail/word_mask'
import AutoDispatchPage from './detail/auto_dispatch'
import AbnormalAddressPage from './detail/abnormal_address'
import DeliveryTemplateConfigPage from '@/views/print_order/delivery_config'
import ImportSettingPage from './detail/import_setting'
import PickingRulePage from '../picking_rule'
import ReceiverAddressPage from './detail/receiver_address' // 收件人信息，与基础资料的收货退货地址共用
import RejectAreaPage from '@/views/print_order/delivery_config/reject_area' // 与物流模版配置共用一套逻辑
import HighlightAreaPage from '@/views/print_order/delivery_config/highlight_area' // 与物流模版配置共用一套逻辑
const _page_container = {
  OrderSortPage:markRaw(OrderSortPage),
  WordMaskPage:markRaw(WordMaskPage),
  AutoDispatchPage:markRaw(AutoDispatchPage),
  AbnormalAddressPage:markRaw(AbnormalAddressPage),
  PickingRulePage:markRaw(PickingRulePage),
  DeliveryTemplateConfigPage:markRaw(DeliveryTemplateConfigPage),
  ImportSettingPage:markRaw(ImportSettingPage),
  ReceiverAddressPage:markRaw(ReceiverAddressPage),
  RejectAreaPage:markRaw(RejectAreaPage),
  HighlightAreaPage:markRaw(HighlightAreaPage),
  SenderPage:markRaw(SenderPage)
}
export default {
  name: "PRINT_SETTING_INDEX",
  components: {
    
  },

  data() {
    return {
      dynamicPage:null,
      defaultMenu:null,
      options:[
        // {label:'收件人信息',key:'ReceiverAddressPage'},
        {label:'发件人信息',key:'SenderPage'},
        // {label:'订单显示顺序',key:'OrderSortPage'},
        // {label:'快递单打印过滤词设置',key:'WordMaskPage'},
        // {label:'自动发货设置',key:'AutoDispatchPage'},
        {label:'拣货码规则',key:'PickingRulePage'},
        {label:'物流匹配设置',key:'DeliveryTemplateConfigPage'},
        {label:'偏远地区高亮',key:'HighlightAreaPage'},
        {label:'不可达区域设置',key:'RejectAreaPage'},
        {label:'异常地址校验设置',key:'AbnormalAddressPage'},
        {label:'子账号权限设置',key:'7'},
        // {label:'订单导入设置',key:'ImportSettingPage'},
        // {label:'小程序关联',key:'8'}
      ]

    }
  },
  mounted(){
    this.defaultMenu=this.$route.meta.key
    this.dynamicPage = _page_container[this.defaultMenu]
  },
  methods: {
    onPageChangeClick(cmd){
      this.dynamicPage = _page_container[cmd.index]
    }
  }
}
</script>
<style lang="less" scoped>
  :deep(.el-menu-item){
    padding:0px;
  }
  :deep(.el-menu-item.is-active){
    background:var(--el-color-primary-light-9);
    border-left:5px solid var(--el-color-primary);
  }
</style>