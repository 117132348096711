<template>
  <el-container class="padding-16">
    <el-main class="padding-16 nt-box-panel nt-bg-white">
      <el-form  :model="formModel" ref="frmEdit" label-width="80" class="nt-height-100">
        <nt-form-item prop="goodsId" label="商品" :rules="formValidator('required', '选择商品')">
          <nt-dropdown-grid configKey="goods" v-model="formModel.goodsId" v-model:text="formModel.goodsName"
            style="width:100%;"   placeholder="编码/助记码/名称" :searchable="true"
            @choose="(goods) => onGoodsChange(goods)"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item prop="unitId" label="商品单位" :rules="formValidator('required', '选择单位')">
          <nt-dropdown-grid configKey="goodsUnit" :request-param="{ goodsId: formModel.goodsId }" 
            v-model="formModel.unitId" v-model:text="formModel.unitName"  placeholder="计量单位"
            :searchable="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item prop="goodsSkuId" label="商品SKU" class="no-margin-bottom">
          <nt-dropdown-grid configKey="goodsSku" :request-param="{ goodsId: formModel.goodsId }" 
          v-model="formModel.goodsSkuId" v-model:text="sku"
          placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
        </nt-form-item>
      </el-form>
    </el-main>
    <el-footer class="height-auto align-center no-padding padding-top-16">
      <el-button size="small" type="info" @click="onCancelClick">取消</el-button>
      <el-button size="small" type="primary" @click="onConfirmClick">确定</el-button>
    </el-footer>
    
  </el-container>
</template>
<script>

import NtFormItem from '@/views/plugin/nt_form_item'
import formValidator from '@/utils/formValidator' // 通过调用 formValidator('required|max(10)','自定义消息') 生成表单项校验规则
export default {
  name: "platform_mapping",
  components: {
    NtFormItem
  },
  data() {
    return {
      formModel:{}
    }
  },

  created() {
  },
  methods: {
    formValidator,
    onGoodsChange(goods) {// 单据头商品
      this.formModel.goodsName = goods.goodsName
      this.formModel.unitId = goods.unitId
      this.formModel.unitName = goods.unitName
      this.formModel.goodsSkuId = null
      this.formModel.sku=null
    },
    async onConfirmClick(){
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      this.$emit('choose', this.formModel)
    },
    onCancelClick(){
      this.$emit('cancel')
    }
  }
}
</script>