<template>
  <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading">

    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white" style="overflow:hidden;">
        <el-row :gutter="24" style="overflow:hidden;">
          <el-col :span="12">
            <nt-form-item label="登录名称" prop="loginName" :rules="formValidator('required', '选择输入名称')">
              <el-input placeholder="请输入登录名称" v-model="formModel.loginName" />
            </nt-form-item>
          </el-col>
          <el-col :span="12">
            <nt-form-item label="用户名称" prop="userName" :rules="formValidator('required', '选择输入名称')">
              <el-input placeholder="请输入用户名称" v-model="formModel.userName" />
            </nt-form-item>
          </el-col>
          <el-col :span="12">
            <nt-form-item prop="roleId" label="角色类型" :rules="formValidator('required', '选择选择角色')">
              <nt-dropdown-grid configKey="role" v-model="formModel.roleId" v-model:text="formModel.roleName"
                :disabled="viewOnly" placeholder="请选择角色" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>

          <el-col :span="12">
            <nt-form-item label="联系电话">
              <el-input placeholder="请输入联系电话" v-model="formModel.phoneNum" />
            </nt-form-item>
          </el-col>
          <el-col :span="12">
            <nt-form-item label="职务">
              <el-input placeholder="请输入职务" v-model="formModel.position" />
            </nt-form-item>
          </el-col>
          <el-col :span="12">
            <nt-form-item label="电子邮箱">
              <el-input placeholder="请输入电子邮箱" v-model="formModel.email" />
            </nt-form-item>
          </el-col>

          <el-col :span="12">
            <nt-form-item label="排序">
              <el-input placeholder="请输入排序" v-model="formModel.sort" />
            </nt-form-item>
          </el-col>
          <el-col :span="12">
            <nt-form-item label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.description" />
            </nt-form-item>
          </el-col>
        </el-row>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>
import { autoJumpNextInput } from "@/utils/util"
import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'

export default {
  name: "userInfo",
  mixins: [DataEditMixin],
  components: {
    NtFormItem
  },
  data() {
    return {

    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  created() {
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxx')
  },
  methods: {
    async _loadEntity() {
      if (this.entityId) {
        return await this.getEntity(this.EnumEntityType.USER, { id: this.entityId })
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      return this.saveEntity(this.EnumEntityType.USER, postData)
    },
  }
}
</script>