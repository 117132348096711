import { axios as service } from '@/utils/request'


export const getPageTradeItemList = (data) => {
  return service({
    url: '/tradeItem/list',
    method: 'post',
    data
  })
}
