
<!-- 应付总账(按往来单位)，按业务员，按部门，按年月+往来单位-->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>应付总账</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="客户">
          <nt-dropdown-grid  configKey="supplier" v-model="queryModel.id"
          placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="公司" prop="companyName" width="290"></el-table-column>
        <el-table-column label="期初余额" prop="initTotal" width="90">
          <template #default="{row}">
            {{ (row.initPayTotal||0 - row.initSettleTotal||0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期应收" prop="currentTradeTotal" width="90">
          <template #default="{row}">
            {{ (row.takeBillTotal).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期已收" prop="currentPayTotal" width="90">
          <template #default="{row}">
            {{ ((row.takeSettleTotal)).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期应付" prop="currentTradeTotal" width="90">
          <template #default="{row}">
            {{ (row.payBillTotal).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期已付" prop="currentPayTotal" width="90">
          <template #default="{row}">
            {{ (row.paySettleTotal).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="期末余额" prop="total" width="90">
          <template #default="{row}">
            {{
              (row.initPayTotal ||0 - row.initSettleTotal||0 + (row.payBillTotal-row.paySettleTotal) - (row.takeBillTotal-row.takeSettleTotal)).toFixed(2)
            }}
          </template>
        </el-table-column>
        <el-table-column ></el-table-column>

      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPaymentSubtotalList } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
// import TradeBillList from '../bill_list/TradeBillList'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "SupplierPayment",
  mixins: [DataListMixin],
  components: {
    // TradeBillList,
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      tradeBillVisible: false,
      toggleSearchStatus: false,
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        companyId: '',
        startTime: getNowFormatYear() + '-01-01',
        endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  mounted() {
  },
  methods: {

    onDateChange: function (value, dateString) {
      // console.log(dateString[0],dateString[1]);
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getPaymentSubtotalList(Object.assign(params, { bizType: 'SUPPLIER' }))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      // 查看欠款详情：查看本地的采购入库，采购出库， 期初初始化单据
      this.tradeBillInfo.companyId = record.id
      this.tradeBillInfo.billType = 'PURCHASE_IN,PURCHASE_BACK'
      this.tradeBillVisible = true
    }

  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>