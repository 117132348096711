<template>
  <div class="nt-text"  :style="textStyle"  tabindex="0">{{config.prefix}}{{isDesignMode? (config.demoText||config.v):config.v}}
    <slot></slot>
  </div>
</template>

<script >


export default {
  data () {
    return {
      
    }
  },
  props:{
    config:{
      type: Object
    },
    isDesignMode:{ // 设计模式
      type: Boolean,
      default: true
    }
  },
  computed:{
    textStyle:function(){
      let style = Object.assign({},this.config.textStyle) // textSyle
      style.lineHeight = (style.fontSize||16) + 'px'
      style.fontSize = style.fontSize + 'px'
      
      return style
    }
  },
  methods:{
    // formatText(val){
    //   if (!val){
    //     return val
    //   }
    //   let m = val.match(/\$\{(.+?)\}/g)
    //   if (m){
    //     let key = m[0].match(/\$\{(.+?)\}/)[1]
    //     return VarExpr[key].text || str
    //   }
    //   return val
    // }
    onKeyDownHandler(e){
      e.preventDefault()
      e.stopPropagation()
      console.log('onKeyDownHandler',e)
    }
  }
}

</script>