<template>
  <el-drawer v-model="isVisible" destroyOnClose size="700px" title="显示设置" :z-index="99"
      @close="isVisible = false">
    <div>
      <div>
        <div>设置订单列表</div>
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="下单时间" value="1" />
            <el-checkbox label="店铺" value="2" />
            <el-checkbox label="商品" value="3" />
            <el-checkbox label="实付金额" value="4" />
            <el-checkbox label="运费" value="5" />
            <el-checkbox label="重量" value="6" />
            <el-checkbox label="体积" value="7" />
            <el-checkbox label="我打分类" value="8" />
            <el-checkbox label="保价金额" value="9" />
            <el-checkbox label="打印时间" value="10" />
            <el-checkbox label="发货人" value="11" />
            <el-checkbox label="打印批次" value="12" />
            <el-checkbox label="已付款时间" value="13" />
            <el-checkbox label="剩余发货时间" value="14" />
            <el-checkbox label="质检码打印状态" value="15" />
          </el-checkbox-group>

        </div>
      </div>
      <div>
        <div>设置订单显示</div>
        <div>
          <el-checkbox-group v-model="checkList1">
            <el-checkbox label="订单号/运单号搜索按照输入顺序排序" value="1" />
            <el-checkbox label="一行显示1个商品" value="2" />
            <el-checkbox label="一行显示2个商品" value="3" />
            <el-checkbox label="一行显示4个商品" value="4" />
            <el-checkbox label="商品超出2行后隐藏" value="5" />
            <el-checkbox label="隐藏已关闭商品" value="6" />
            <el-checkbox label="隐藏已发货商品" value="7" />
          </el-checkbox-group>

        </div>
      </div>

      <div>
        <div>设置商品排序</div>
        <div>
          <el-checkbox-group v-model="checkList2">
            <el-checkbox label="与平台一致" value="1" />
            <el-checkbox label="商家编码升序排序" value="2" />
            <el-checkbox label="商品名称升序排序" value="3" />
            <el-checkbox label="销售属性升序排序" value="4" />
          </el-checkbox-group>

        </div>
      </div>

      <div>
        <div>留言备注显示方式</div>
        <div>
          <el-checkbox-group v-model="checkList3">
            <el-checkbox label="合并显示" value="1" />
            <el-checkbox label="显示买家留言" value="2" />
            <el-checkbox label="显示卖家备注" value="3" />
            <el-checkbox label="显示我打备注" value="4" />
          </el-checkbox-group>

        </div>
      </div>
      
    </div>
  </el-drawer>
</template>

<script>


  export default {
    name:"columnSetting",
    data () {
      return {
        isVisible:false,
        checkList:null,
        checkList1:null,
        checkLis2:null,
        checkList3:null,
        checkList4:null,
        checkList5:null
      }
  },
  components:{},
  props:{
    
  },
  async created(){
    
  },
  methods:{
    showDialog(){
      this.isVisible= true
    }
  }
}
</script>
