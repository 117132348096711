<!-- 预发货操作： 预发货  取消预发货 -->
<template>
  <el-container class="padding nt-bg-page" style="height:450px;">
    <el-main class="no-padding nt-box-panel">
      <el-table :data="datasource" border height="100%" class="nt-resource-table">
        <el-table-column type="index" width="45"></el-table-column>
        <el-table-column label="订单信息">
          <template #default="{ row }">
            <div>
              <div><span>订单号:</span><span>{{ row.orderNo }}</span></div>
              <div v-if="row.waybillNo"><span>运单号:{{ row.packageNum }}:</span>
                <span>{{ row.waybillNo }}</span>
              </div>
              <div v-else>
                <span>包裹#{{ row.packageNum }}:</span>
                <span >待取号</span>
              </div>
              <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品数量" prop="quantity" width="90"></el-table-column>
        <el-table-column label="状态" align="center" width="160">
          <template #default="{ row }">
            <!-- 等待生成  正在获取面单 正在打印面单  打印完成 ==》有错误信息？ -->
            <el-icon class="is-loading" v-if="inProgress">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus">{{ formatProcessStatus(row.processStatus) }}</span>
            <span v-else>就绪</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding no-margin">
      <div class="margin-top">共{{ packageList.length }}个包裹</div>
      <div class="align-center">
        <el-button type="primary"  v-if="taskStatus=='READY'" :loading="inProgress" @click="onStartClick" >{{processType=='UPDATE'?'预发货':'取消预发货'}}</el-button>
        <el-button type="primary" v-if="taskStatus=='DONE'" @click="onFinishHandler">关闭</el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>

import { updatePreDispatch,cancelPreDispatch} from '@/service/print_order/platform_waybill'
export default {
  name: "createWaybill",
  data() {
    return {
      datasource: this.packageList || [],
      taskStatus: 'READY', // REDAY 就绪  EXECUTTING--正在进行  DONE 完成
      taskType: this.processType // 任务类型
    }
  },
  props: {
    packageList: { // 需要操作的包裹列表
      type: Array,
      required: true
    },
    
    processType:{
      type: String,
      default:'UPDATE' // UPDATE 更新预发货状态 CANCEL 取消预发货状态
    }
  },
  computed:{
    inProgress:function(){
      return this.datasource.some(item=>item.inProgress)
    },
    allFailed:function(){  // 所有的任务都失败
      let hasSucessItem = this.datasource.some(item=>item.processStatus!=-1)
      return !hasSucessItem
    }
  },
  methods: {
    formatProcessStatus(status) {
      let info = {
        'UPDATE_1': '正在预发货..',
        'UPDATE_2': '预发货完成',
        'UPDATE_-1': '预发货失败',
        'CANCEL_1': '正在取消预发货...',
        'CANCEL_2': '取消完成',
        'CANCEL_-1': '撤销失败'
      }
      let key = `${this.taskType}_${status}`
      return info[key] || status
    },
    // async onStartClick() {
    //   // this.taskStatus =  'EXECUTTING' //开始执行任务
    //   this.datasource.forEach(el => {
    //     el.inProgress = false
    //     el.processStatus = 1 // 正在获取面单
    //   })
    //   this._waybillManager.startCreateWaybill(this.datasource)

    // },
    
    async onStartClick(){
      // this.taskStatus = 'EXECUTTING'
      this.datasource.forEach(el => {
        el.done = false
        el.processStatus = 1 // 任务开始
      })
      let list = this.datasource.map(item =>({orderId:item.orderId,packageNum:item.packageNum}))
      let execFunc = this.taskType=='UPDATE'? updatePreDispatch :  cancelPreDispatch
      let res = await execFunc(list)
      this.taskStatus = 'DONE'
      if(res.code==200){
        res.data.resultList?.forEach(item=>{
          let arInfo = item.businessNo.split('-')

          let matchItem = this.datasource.find(el => el.orderId == arInfo[0] && el.packageNum == arInfo[1])

          if (matchItem) {
            delete matchItem.inProgress
            if (item.success) {
              matchItem.isPreDispatch= this.taskType=='UPDATE' 
              matchItem.processStatus = 2 // 预发货完成
            } else {
              matchItem.processStatus = -1 // 预发货失败
              matchItem.errorMsg = item.message
            }
            
          }
        })
        
      } else {
        this.$message.error(res.msg || '更新失败')
      }
    },
    onFinishHandler() {
      this.$emit("close") // 完成
    }
  }
}
</script>
