<!-- 订单操作日志 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin display-flex flex-wrap row-gap-8">
      <QueryFieldList :queryModel="queryForm" :editors="editors"></QueryFieldList>
        <div>
          <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
          <el-button style="width:64px;" size="small" @click="onResetQueryClick" type="primary">重置</el-button>
        </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top">
      <el-table :data="datasource" height="100%" v-loading="isLoading" 
      :cell-class-name="getCellClassName"
       scrollbar-always-on @selection-change="onSelectionChange" stripe  border class="nt-resource-table">
        <el-table-column type="selection"></el-table-column>
        <!-- <el-table-column label="所属店铺" prop="shopName" width="160">
          <template #default="{row}">
            <span>{{ formatShopName(row.shopId) }}</span>
            <span class="left-top iconfont icon-shipinhaoxiaodian1 margin-left-4 margin-top-4" style="color:#FF6146;font-size:25px;opacity:.5;"></span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="买家昵称" prop="buyerName" width="110"></el-table-column> -->
        <el-table-column label="运单信息" prop="buyerName" width="650">
          <template #default="{row}">
            <el-descriptions :column="9" direction="vertical" size="small"  border >
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="relative">
                    运单号({{ row.deliveryId }})
                    <div class="right-top">
                      <el-tag type="success" v-if="row.waybillStatus=='SUCCESS'">已生效</el-tag>
                      <el-tag type="danger" v-else-if="row.waybillStatus=='CANCELED'">已回收</el-tag>
                      <el-tag v-else>{{ row.waybillStatus }}</el-tag>
                    </div>
                  </div>
                </template>
                <el-link type="primary" @click="onViewPackageDetailClick(row)">{{ row.waybillNo }}</el-link>
                (重量:{{formatWeight(row)}})
              </el-descriptions-item>
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="cell-item">
                    面单创建时间
                  </div>
                </template>
                {{ row.createTime }}
              </el-descriptions-item>
              <el-descriptions-item :span="3">
                <template #label>
                  <div class="cell-item">
                    面单模版
                  </div>
                </template>
                {{row.waybillTemplateId}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    订单号({{ formatShopName(row.shopId) }})
                  </div>
                </template>
                <el-link type="primary" @click="onViewOrderDetailClick(row)">{{ row.orderNo }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item >
                <template #label>
                  <div class="cell-item">
                    省份
                  </div>
                </template>
                {{row.province}}
              </el-descriptions-item>
              
              <el-descriptions-item :span="9">
                <template #label>
                  <div class="cell-item">
                    详细地址({{ row.buyerName }}{{ row.buyerTel }})
                  </div>
                </template>
                {{ row.buyerAddress }}
              </el-descriptions-item>
              
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column label="商品">
          <template #default="{row}">
            <div class="display-flex" v-for="item in row.platformOrderPackageList">
              <div>{{ item.goodsName }}</div>
              <div>x{{ item.quantity }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="打印时间" prop="printTime" width="165"></el-table-column>
        <el-table-column label="状态" width="110" fixed="right">
          <template #default="{row}">
            <div v-if="row.inProgress">
              <el-icon class="is-loading">
                <Loading />
              </el-icon>
              <span>{{ formatProcessStatus(row.processStatus) }}</span>
            </div>
            <div v-else-if="row.processStatus!=-1"> {{ formatStatus(row) }}</div>
            <el-tooltip v-if="row.processStatus==-1" placement="top" width="250px" :content="row.errorMsg">
              <div class="display-flex" style="font-size:12px;">
                <el-icon size="14"  color="var(--el-color-danger)"><InfoFilled /></el-icon>
                <span>{{ formatProcessStatus(row.processStatus) }}</span>
              </div>
              
            </el-tooltip>
          </template>
          
        </el-table-column>
      </el-table>
      
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top align-center relative">
      <div>
        <NtPrinter v-model="printerName" placeholder="请选择打印机" style="width:220px;" class="margin-right-8"></NtPrinter>
        <el-button type="primary" @click="onPrintClick"> 打印面单</el-button>
        <el-button type="danger" @click="onCancelClick"> 回收面单</el-button>
      </div>
      <el-row justify="end" class="right-top">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail"  />
  <nt-result-dialog ref="refBatchResult"></nt-result-dialog>
</template>
<script>


import  QueryFieldList from '../../query/query_field_list'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtPrinter from '@/views/plugin/nt_printer'
import OrderDetailPage from '@/views/print_order/detail/order_detail'
import PackageDetailPage from '@/views/print_order/detail/package_detail' // 包裹详情

import {LoginUserStore} from '@/piana/login_user_service'
import {updatePrint, getPrintData,cancelWaybill} from '@/service/print_order/platform_waybill'
import {waybillQuery} from '../../query/config/query_waybill_editor'
import NtPrintManager from '@/utils/print_manager' // 打印管理器
import NtResultDialog from '@/views/plugin/nt_batch_result_dialog' //批量操作结果弹窗
export default {
  name: "WAYBILL_HISTORY",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    QueryFieldList,
    NtPrinter,
    OrderDetailPage,
    PackageDetailPage
  },

  data() {
    return {
      queryForm: {},
      editors: waybillQuery,
      printerName:null
    }
  },
  created(){
    this.onResetQueryClick()
    this._shopList = LoginUserStore().shopList

    this._printManager = new NtPrintManager(async (result,data /*打印结果：单个，全部完成为数组 */) => {
      if(result.type =='printed'){ // 单个文档打印完成
        let arPackInfo = data.requestID.split('-') // orderId  + packageNum
        let packageInfo = {orderId:arPackInfo[0],packageNum:arPackInfo[1]}
        let matchItem = this.datasource.find(item => item.orderId == packageInfo.orderId && item.packageNum == packageInfo.packageNum)
        if (!matchItem) {
          return
        }
        
        delete matchItem.inProgress // 清除进行中状态
        if(result.success){
          matchItem.printCnt=1
          matchItem.processStatus = 3 // 打印完成
        } else {
          matchItem.processStatus = -1
          matchItem.errorMsg = result.msg
        }
        
      } else if(result.type =='done'){// 全部打印完成，注意存在多次批次打印，如面单，每次可能只返回n面单生成结果，通过轮询不断触发
        let printSuccessList = []
        data?.forEach(item=>{
          if(item.success){ //进处理队列中已成功的打印数据
            let arPackInfo = item.requestID.split('-') // orderId  + packageNum
            printSuccessList.push({orderId:arPackInfo[0],packageNum:arPackInfo[1]})
          }
        })
        // 打印结束--- // 更新订单已打印成功
        if(printSuccessList.length>0){
          let res = await updatePrint(printSuccessList)
          if(res.code == 200 ){
            if(res.data.failCount>0){
              this.$refs.refBatchResult.showDialog('上报面单打印次数', res.data)
            }
          }
        }
      }
      
    })
  },
  methods: {
    onResetQueryClick(){
      this.editors.forEach(item=>{
        this.queryForm[item.queryKey]= item.defaultValue || {}
      })
    },
    async _loadList(params) {
      let dynamicQuery = {dynamic:Object.values(this.queryForm)}
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_WAYBILL, Object.assign(dynamicQuery, params));
    },
    getCellClassName({ row, columnIndex }) {
      if (columnIndex == 1) { // 运单信息
        return 'cell-waybill'
      }
    },
    formatWeight(row){
      let total =  row.platformOrderPackageList.reduce((pre,cur)=>pre+(cur.weight||0),0)
      return total
    },
    onQueryClick() {
      this.resetPage = true
      this.loadList({dynamic:Object.values(this.queryForm)})
    },
    formatShopName(shopId){
      let matchItem =  this._shopList.find(item=>item.shopId==shopId)
      return matchItem?.shopName || shopId
    },
    formatWaybillStatus(waybillStatus){
      let info={
        'SUCCESS':'已生效',
        'CANCELED':'已回收'
      }
      return info[waybillStatus] || waybillStatus
    },
    formatStatus(waybill) {
      if(waybill.waybillStatus == 'CANCELED'){
        return '-'
      }
      else if(waybill.isDispatch){
        return '已发货'
      } else if(waybill.isPreDispatch){
        return '预发货'
      }
      return '待发货'
    },
    formatProcessStatus(status) {
      let info = {
        '1': '正在获取数据..',
        '2': '正在打印...',
        '3': '打印完成',
        '-1': '获取数据异常'
      }
      return info[status] || status
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    async onCancelClick(){
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      let idList = this.selectedRows.map(item=>item.id)
      // TODO 弹窗显示进度
      let res = await cancelWaybill({id:idList.join(',')}) //批量更新接口
      if (res.code == 200) {
        this.loadList()
        this.$refs.refBatchResult.showDialog('操作结果', res.data)
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    async onPrintClick(){
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      if (!this.printerName) {
        this.$message.error("请选择打印机")
        return;
      }

      let packageList = []
      this.selectedRows.forEach(item=>{
        item.inProgress = true // 标记任务执行中
        item.processStatus = 1 // 正在获取打印数据
        packageList.push({orderId:item.orderId,packageNum:item.packageNum})
      })

      let res  = await getPrintData(packageList)
      if(res.code == 200){
        // 成功获取打印报文，进入打印准备阶段
        let printObjectList = []
        res.data.forEach(item=>{
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if(matchItem){
            if(item.success){
              matchItem.processStatus = 2
              printObjectList.push({requestID:`${item.orderId}-${item.packageNum}`, printDataVo:item})
            } else{
              matchItem.processStatus = -1
              matchItem.errorMsg = item.message
              delete matchItem.inProgress // 清除进行中状态
            }
          }
          
        })
        if(printObjectList.length>0){ // 成功返回报文
          this._printManager.print(this.printerName, false, printObjectList)
        }
        
      }  else{
        this.selectedRows.forEach(item=>{
          delete item.inProgress
          item.processStatus = -1 
          item.errorMsg = '网络异常'
        })
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
            
    },
    onViewPackageDetailClick(row){
      this.$refs.refPackageDetail.show(row.orderId,row.waybillNo)
    },
    onViewOrderDetailClick(row){
      this.$refs.refOrderDetail.show(row.orderId)
    }
  }
}
</script>
<style scoped lang="less">
:deep(.el-table) {
  .cell-waybill {
    padding:8px;
    .cell {
      padding:0px;
    }
    .el-descriptions__body{
      background-color: transparent;
      >.el-descriptions__table .tr{
        background-color: transparent !important;
      }
    }
  }

}</style>