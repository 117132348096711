
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto">
      <div class="nt-bg-page nt-box-panel padding">
        使用说明：<br/>
        1.设置物流公司不可送达区域，打印快递单时系统将自动判断提示不可送达；<br/>
        2.表格导入时请勿修改系统模板表头，名称需填写完整，精确到省、自治区等；例：新疆维吾尔自治区不能简写成新疆省；<br/>
        3.未设置物流公司不可达区域，将参照快递公司提供范围。
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading" @selection-change="onSelectionChange">
        
        <el-table-column label="区域">
          <template #default="{row}">
            <el-text truncated line-clamp="4" style="white-space:normal;">{{row.districtNames}}</el-text>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="{row}">
            <el-button size="small"  @click="onRowDeleteClick(row)">删除</el-button>
            <el-button size="small"  @click="onRowEditClick(row)">修改</el-button>
          </template>
        </el-table-column>
        
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <area-detail @success="onDetailSaveSuccessHandler" :configType="configType" @cancel="isDetailVisible = false" :entityId="currentRecord?.id"
      v-if="isDetailVisible"></area-detail>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getDeliveryAreaList,deleteDeliveryArea } from '@/service/print_order/platform_waybill_template'
import AreaDetail from '@/views/print_order/delivery_config/detail/area_detail'

export default {
  name: "MarketList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    AreaDetail
  },
  data() {
    return {
      isDetailVisible:false,
      currentRecord:null,
      
    }
  },
  props:{
    configType:{
      type:String,
      default:'REJECT_AREA'
    }
  },
  methods: {
    async _loadList(params) {
      return getDeliveryAreaList(Object.assign({configType:this.configType},params))
    },
    onDetailSaveSuccessHandler(){
      this.isDetailVisible = false
      this.loadList()
    },
    onAddClick(){
      this.currentRecord = null
      this.isDetailVisible = true
    },
    onRowEditClick(row){
      this.currentRecord = row
      this.isDetailVisible = true
    },
    async onRowDeleteClick(row){
      let res = await deleteDeliveryArea({id:row.id})
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>
