
import { axios as service } from '@/utils/request'


// 提交订单同步请求，获取提交后的任务id
export const createPickingCode = (data) => {
  return service({
    url: '/order/createPickingCode',
    method: 'post',
    data
  })
}

/**
 * 拣货时用，根据orderPackageId 获取完整订单信息，并根据参数更新orderPackage的备货状态
 * @param {*} data 
 * @returns 
 */
export const getOrderByOrderPackageId = (data) => {
  return service({
    url: '/order/getOrderByPackageId',
    method: 'post',
    data
  })
}

/**
 * 拣货时用，根据orderPackageId 获取 OrderPackage拣货信息，并根据参数更新orderPackage的备货状态
 * @param {*} data 
 * @returns 
 */
export const getOrderPackageByPackageId = (data) => {
  return service({
    url: '/order/getOrderPackageByPackageId',
    method: 'post',
    data
  })
}

/**
 * 手动更新orderPackage的备货状态
 * @param {*} data 
 * @returns 
 */
export const updateScanStatus = (data) => {
  return service({
    url: '/order/updateScanStatus',
    method: 'post',
    data
  })
}


/**
 * 按市场档口统计备货商品
 * @param {*} data 
 * @returns 
 */
export const pickGoodsByMarket = (data) => {
  return service({
    url: '/order/pickGoodsByMarket',
    method: 'post',
    data
  })
}


/**
 * 统计备货商品-按商品
 * @param {*} data 
 * @returns 
 */
export const pickGoodsSummary = (data) => {
  return service({
    url: '/order/pickGoodsSummary',
    method: 'post',
    data
  })
}