<template>
  <el-cascader ref="refArea"  class="nt-width-100" v-model="value" :options="areaData" :props="areaProps" @change="onAreaChooseHandler"></el-cascader>
</template>
<script>
import {pcaa} from 'area-data'
export default {
  data() {
    return {
      areaData:[],
      value: JSON.parse(this.modelValue|| '[]'),
      areaProps:{
          value:'code',
          label:'name',
          lazy: true,
          outLevel: this.level, // lazyLoad 访问的this 为组件的对象属性，需再此增加属性辅助读取
          lazyLoad(node, resolve) {
            let child = pcaa[node.value]
            if(!child){
              resolve(null)
              return
            }
            let childNodes = Object.keys(child).map(key=> {
              return {
                code:key,name:child[key], leaf:node.level>= this.outLevel // outLevel 组件辅助属性，接收自定义组件外部传入变量
              }
            })
            resolve(childNodes)
          }
        },
    }
  },
  model: {
    // 自定义v-model 双向绑定数据
    props: 'modelValue',
    emits:['update:modelValue'], //update:modelValue
  },
  props: {
    modelValue: {},
    level:{
      type: Number,
      default: 2 // 1级，省
    },
  },
  watch:{
    modelValue:function(){
      this.value = JSON.parse(this.modelValue|| '[]')
      // 重新构造初始化数据
      this.buildAreaData()
    }
  },
  mounted(){
    if(this.areaData.length==0){
      this.buildAreaData()
    }
  },
  methods:{
    getLevel(){
      return this.level
    },
    getChildAreaNodes(code){
      let child = pcaa[code]
      if(!child){
        return null
      }
      return Object.keys(child).map(key=> {
        return {
          code:key,name:child[key]
        }
      })
    },
    // 获取当前选中的，层次结果 
    getSelectPathObject(leafOnly){
      let all = this.$refs.refArea.getCheckedNodes(leafOnly)
      if(all.length ==0){
        return []
      }
      return all[0].pathNodes.map(el=>({code:el.data.code,name:el.data.name}))
    },
    buildAreaData(){
      let rootNodeList = this.getChildAreaNodes('86');
      let lastNode = null
      this.value.forEach((code,index) => {
        let matchNode = null
        if(!lastNode){
          matchNode = rootNodeList.find(item=> item.code == code)
        } else {
          matchNode = lastNode.children.find(item=> item.code == code)
        }
        if(this.value.length-1 == index && matchNode){
          lastNode.children.forEach(el=>el.leaf=true)
        }
        
        if(matchNode){
          matchNode.children = this.getChildAreaNodes(code)
          lastNode = matchNode
        }
      })
      this.areaData = rootNodeList
    },
    onAreaChooseHandler(){
      this.$emit('update:modelValue',JSON.stringify(this.value))
    }
  }
}
</script>
