<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title >银行存取款</template>
          <template #extra>
            <el-button  class="el-menu-item" @click="jumpToPage('/financial/withdraw_list','银行存取款列表')" >单据列表</el-button>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="handlerId" label="经手人">
              <nt-dropdown-grid configKey="staff" v-model="formModel.handlerId" style="width:100%;"
                v-model:text="formModel.handlerName" :readonly="viewOnly" placeholder="请选择经手人" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="remark" label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.remark" style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.withdrawItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
            <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
              <Plus />
            </el-icon>
          </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="转出账户" width="200" prop="outAccountId" >
            <template #default="scope">
              <span v-if="viewOnly">{{ scope.row.outAccountName }}</span>
              <nt-form-item   label-width="0" :prop="`withdrawItemList[${scope.$index}].outAccountId`" v-else
                :rules="formValidator('required', '请选择转出账户')">
                <nt-dropdown-grid key="ndg_account"  configKey="account"  v-model="scope.row.outAccountId"  v-model:text="scope.row.outAccountName"
                :disabled="viewOnly" placeholder="请选择转出账户" :searchable="true"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="转入账户" width="200" prop="inAccountId" >
            <template #default="scope">
              <span v-if="viewOnly">{{ scope.row.inAccountName }}</span>
              <nt-form-item   label-width="0" :prop="`withdrawItemList[${scope.$index}].inAccountId`" v-else
                :rules="formValidator('required', '请选择转入账户')">
                <nt-dropdown-grid key="ndg_account"  configKey="account"  v-model="scope.row.inAccountId"  v-model:text="scope.row.inAccountName"
                :disabled="viewOnly" placeholder="请选择转入账户" :searchable="true"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          
          <el-table-column label="金额" width="80" prop="total" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`withdrawItemList[${scope.$index}].total`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入转账金额')">
                <el-input v-model="scope.row.total" />
              </nt-form-item>
              <span v-else>{{ scope.row.total }}</span>
            </template>
          </el-table-column>
          
          <el-table-column label="备注" prop="remark" class-name="editor">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`withdrawItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  
</template>
<script>
import NtBillLayout from '@/views/layout/nt_bill_layout'
import { DataBillMixin } from '@/utils/data_bill_mixin'

import billSelector from '@/views/bill/selector/LinkBillList'
import NtFormItem from '@/views/plugin/nt_form_item'

import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'
export default {
  name: "withdraw_detail",
  mixins: [DataBillMixin],
  components: {
    NtFormItem,
    NtBillLayout,
    NtBillToolbar
  },
  data() {
    return {
      billType: 'FINANCIAL_WITHDRAW',
      entityType:EnumEntityType.WITHDRAW_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  methods: {
    async _createNewEntity() {
      // let billNo = await this.createBillNo('ZZ')
      return {
        data: {
          // billNo: billNo,
          withdrawItemList: [{}],
          billTime: this.getDateTime()
        }
      }
    },
    async _loadEntity(params){
      if (!this.entityId) {
        this.fileList = []
        return this._createNewEntity()
        
      } else {
        let res = await this.getEntity(this.entityType, {id: this.entityId})
        return res
      }
    },

    async _checkSubmit() {
      // console.log('fkdidd', this.formModel)
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      if (this.formModel.withdrawItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }
      for(let item of this.formModel.withdrawItemList){
        if(!item.total || item.total<0){
          this.$message.error(`请输入有效的转账金额`)
          return false
        }
        if(item.inAccountId == item.outAccountId){
          this.$message.error(`转出账户与转入账户不能相同`)
          return false
        }
      }

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, billType: this.billType }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      if (this.fileList && this.fileList.length > 0) {
        postData.fileName = this.fileList
      }
      return this.saveEntity(this.entityType, postData)
    },

    _loadFinished() {
    },

    onFormChangeHandler() {
    },
    
    onRowDeleteClick(rowIndex) {
      this.formModel.withdrawItemList.splice(rowIndex, 1)
    },

    onAddLineClick() {
      this.formModel.withdrawItemList.push({ })
    },
    async _copyBill() { // 复制单据
      // let billNo = await this.createBillNo('ZZ')
      return {
        id: '',
        status: null,
        // billNo: billNo,
        billTime: getNowFormatDateTime()
      }
    },

    getSummaries({ columns, data }) {
      let sumResult = []
      columns.forEach((column, index) => {
        if (index < 2) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }
}
</script>