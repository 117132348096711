<template>
  <el-container class="padding-16">
    <el-main class="no-padding" style="overflow:hidden;">
      <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading">
        <nt-form-item label="分类名称" prop="categoryName" :rules="formValidator('required', '选择输入分类名称')">
          <el-input placeholder="请输入类型名称" v-model="formModel.categoryName" />
        </nt-form-item>
        <nt-form-item label="上级分类" prop="parentId" class="no-margin-bottom">
          <nt-company-category-tree v-model="formModel.parentId" :disabled="[formModel.id]" v-model:text="formModel.parentName" ></nt-company-category-tree>
        </nt-form-item> 
      </el-form>
    </el-main>
    <el-footer class="no-padding height-auto margin-top align-right">
      <el-button @click="onCancelClick">取消</el-button>
      <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
    </el-footer>
  </el-container>
</template>
<script>
import {getBizCompanyCategoryById,saveBizCompanyCategory} from '@/service/resource/biz_company'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtCompanyCategoryTree from '@/views/plugin/nt_company_category_tree'
export default {
  name: "bizCompanyCategory",
  mixins: [DataEditMixin],
  components: {
    NtFormItem,
    NtCompanyCategoryTree
  },
  
  data() {
    return {

    }
  },
  props: {
    entityId: {
      type: String
    },
    parentCategory: {
      type: Object
    }
  },
  methods: {
    async _loadEntity() {
      if(!this.entityId){
        return {data:{parentId: this.parentCategory?.id,parentName: this.parentCategory?.categoryName}}
      }
      else {
        return await getBizCompanyCategoryById({ id: this.entityId })
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({}, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))
      // console.log('fking post', postData)
      return saveBizCompanyCategory( postData)
    },
  }
}
</script>