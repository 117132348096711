
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>销售出库统计-业务员</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按业务员，按订单（订单，客户）"></el-form-item>
        <el-form-item label="销售时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        
        <el-table-column label="业务员" prop="companyName" width="240">
        </el-table-column>
        <el-table-column label="最后交易日期" width="120">
        </el-table-column>
        <el-table-column label="交易次数" width="120">
        </el-table-column>
        <el-table-column label="主数量" prop="outQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="毛利" width="120" align="right"></el-table-column>
        <el-table-column label="毛利率" width="120" align="right"></el-table-column>
        <el-table-column label="未收金额" width="120" align="right"></el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getSaleOutTotalByCustomer } from '@/service/report/rpt_sale'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NtDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getSaleOutTotalByCustomer(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'PURCHASE_IN,PURCHASE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

