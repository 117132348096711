<template>
  <global-layout @dynamicRouterShow="dynamicRouterShow">
    <contextmenu :itemList="menuItemList" :visible.sync="menuVisible" style="z-index: 9999;" @select="onMenuSelect"/>
    <el-container  class="no-padding nt-height-100" style="overflow:hidden;">
      <el-header class="padding-left nt-nav-tab no-margin no-padding-right height-auto display-flex space-between border-bottom" style="align-items:center;box-shadow: 0 1px 4px rgba(0, 21, 41, .08);">
        <el-tabs
          style="height:38px; "
          class="margin-top-4 flex-auto"
          @contextmenu.native="e => onContextmenu(e)"
          v-model="activePage"
          @tab-remove="remove"
          type="card"
          closable>
          <el-tab-pane :id="page.fullPath" :label="page.meta.title" :name="page.fullPath" :key="page.fullPath" v-for="page in pageList">
            <!-- <span slot="tab" :pagekey="page.fullPath">{{ page.meta.title }}</span> -->
          </el-tab-pane>
        </el-tabs>
        <el-menu mode="horizontal" :ellipsis="false" style="height:38px;" class="no-border">
          <el-menu-item index="1">消息</el-menu-item>
          <el-sub-menu index="2-4">
            <template #title><el-icon><Avatar /></el-icon>楚林企业店</template>
            <div class="padding-left padding-right">
              <div><span>用户昵称：楚林企业店 </span><el-button link size="small" type="primary">编辑</el-button></div>
              <div>服务有效期：2022-10-10</div>
              <div class="border-top align-center">
                <el-button link type="primary" size="small">退出登录</el-button>
              </div>
            </div>
          </el-sub-menu>
          <!-- <el-menu-item index="2"><el-icon><Avatar /></el-icon>楚林企业店</el-menu-item> -->
        </el-menu>
        
      </el-header>
      <el-main :class="`nt-bg-page ${$route.meta.exclusive? 'no-padding':'padding'}`" style="overflow: hidden;">
        <div :class="`nt-height-100 route-container ${$route.meta.exclusive? 'no-shadow':''}`">
          <router-view  #default="{ Component }" >
            <transition>
              <!-- 特别注意，keep-alive 组件必须直接为component组件，如果包裹其他组件，将导致缓存不起作用 -->
              <KeepAlive :include="cacheList" >
                <component :is="Component" :key="$route.name" ></component>
              </KeepAlive>
            </transition>
          </router-view>
        </div>
      </el-main>
    </el-container>
  </global-layout>
</template>

<script>
  import GlobalLayout from '@/components/page/GlobalLayout'
  import Contextmenu from '@/components/menu/Contextmenu'
  const indexKey = '/dashboard/analysis'
  import { computed } from 'vue'
  import store from '../../store'
  import {useCainiaoPrintServiceStore} from '@/piana/cainiao_printer_service.js'

  import {useWeixinPrintServiceStore} from '@/piana/weixin_printer_service.js'

  export default {
    name: 'TabLayout',
    components: {
      GlobalLayout,
      Contextmenu
    },
    data() {
      return {
        pageList: [],
        linkList: [],
        activePage: '',
        menuVisible: false,
        printerList:[],
        menuItemList: [
          { key: '1', icon: 'arrow-left', text: '关闭左侧' },
          { key: '2', icon: 'arrow-right', text: '关闭右侧' },
          { key: '3', icon: 'close', text: '关闭其它' }
        ],
        // componentsArr: []
      }
    },
    provide(){
      return{
        closeCurrent:this.closeCurrent,
        dynamicRouterShow: this.dynamicRouterShow
      }
    },
    computed: {
      
      cacheList:function(){
        return this.pageList.map(item=>{
          if(item.cacheKey?.indexOf('page_')==0){
            return item.cacheKey
          }
          return 'page_' + item.cacheKey
        } )
        // return ['page-a','page-b','page_a1003','page_a1005','page_a1007']
      }
    },
    async created() {
      // 自动识别打印机组件--优先获取菜鸟打印服务
      // let isConnected = await useCainiaoPrintServiceStore().connect((val)=>{
      //   consoel.log('useCainiaoPrintServiceStore.connect',value)
      // },'first')
      let isConnected = await useWeixinPrintServiceStore().connect((val)=>{
        consoel.log('useCainiaoPrintServiceStore.connect',value)
      },'first')
      if(isConnected){
        // 成功连接 执行获取打印机 菜鸟
        // useCainiaoPrintServiceStore().send(JSON.stringify({
        //   "cmd": "getPrinters",
        //   "requestID": "123458976",
        //   "version": "1.0"
        // }))

        // 视频号组件--获取打印机指令
        useWeixinPrintServiceStore().send(JSON.stringify({
          requestID: '1234xxxx',
          command: 'getPrinterList'
        }))
        
      }
      
      if (this.$route.path != indexKey) {
        this.addIndexToFirst()
      }
      
      // let storeKey = 'route:title:' + this.$route.fullPath
      // let routeTitle = this.$ls.get(storeKey)
      // if (routeTitle) {
      //   this.$route.meta.title = routeTitle
      // }
       console.log('route',this.$route.meta)
      // 首次加载特定路由时，根据该路由对应的配置信息，生成页面缓存对象及tab标签页
      this.pageList.push({
        name: this.$route.meta.title,
        path: this.$route.path,
        fullPath: this.$route.fullPath,
        cacheKey: this.$route.meta.componentName,
        meta: Object.assign(this.$route.meta,{ // [特别注意，将路由的meta写入pageList中，该meta是由表的extra合并而来]
          id: this.$route.meta.componentName,
          // icon: key,
          title: this.$route.meta.title
        })
      })
      // this.pageList.push(this.$route)
      this.linkList.push(this.$route.fullPath)
      this.activePage = this.$route.fullPath
    },
    mounted() {
    },
    watch: {
      // '$route': function(newRoute) {
      //   console.log('route watch change',this.linkList,newRoute)
        
      //   if(indexKey==newRoute.fullPath) {
      //     //首页时 直接刷新
      //   }else if (!this.linkList.includes(newRoute.fullPath)) {
      //     console.log('fking route', newRoute)
      //     this.linkList.push(newRoute.fullPath)
      //     this.pageList.push(Object.assign({},newRoute))
      //   } else if (this.linkList.indexOf(newRoute.fullPath) >= 0) {
      //     // let oldIndex = this.linkList.indexOf(newRoute.fullPath)
      //     // let oldPositionRoute = this.pageList[oldIndex]
      //     // this.pageList.splice(oldIndex, 1, Object.assign({},newRoute,{meta:oldPositionRoute.meta}))
      //   }
      //   this.activePage = newRoute.fullPath
      //   // console.log('xxxx', this.pageList)
      // },
      'activePage': function(key) { // 点击tab组件时，触发属性activePage 属性变化
        let index = this.linkList.lastIndexOf(key)
        let waitRouter = this.pageList[index]
        // console.log('active page', waitRouter,this.linkList)
        let allRoutes = this.$router.getRoutes()
        let item = allRoutes.find(item=>item.path==key)

        
        if(item){ // 获取现存的路由，根据当前拉起特定页面，判断临时的中间 refBill 是否存在，如存在则将ref 临时传递给 router 的meta变量，一次性有效
          item.meta.refBill = waitRouter.meta.refBill // 清空上一次的 关联信息
        }
        this.$router.replace({path:waitRouter.path})
        console.log('xxx', this.$route)
        // waitRouter.meta.refBill = null // 清空临时路由元数据
        
        this.changeTitle(waitRouter.meta.title)
      },
    },
    methods: {
      // 将首页添加到第一位
      addIndexToFirst() {
        this.pageList.splice(0, 0, {
          name: '首页',
          path: indexKey,
          fullPath: indexKey,
          meta: {
            icon: 'dashboard',
            title: '首页'
          }
        })
        this.linkList.splice(0, 0, indexKey)
      },
      //动态更改页面标题
      changeTitle(title) {
        let projectTitle = window.SYS_TITLE
        // 首页特殊处理
        if (this.$route.path === indexKey) {
          document.title = projectTitle
        } else {
          document.title = title + ' · ' + projectTitle
        }
      },
      remove(key) {
        if (key == indexKey) {
          this.$message.warning('首页不能关闭!')
          return
        }
        if (this.pageList.length === 1) {
          this.$message.warning('这是最后一页，不能再关闭了啦')
          return
        }
        //console.log("this.pageList ",this.pageList );
        // let removeRoute = this.pageList.filter(item => item.fullPath == key)
        this.pageList = this.pageList.filter(item => item.fullPath !== key)
        let index = this.linkList.indexOf(key)
        this.linkList = this.linkList.filter(item => item !== key)
        index = index >= this.linkList.length ? this.linkList.length - 1 : index
        this.activePage = this.linkList[index]
        
      },
      onContextmenu(e) {
        const pagekey = this.getPageKey(e.target)
        if (pagekey !== null) {
          e.preventDefault()
          this.menuVisible = true
        }
      },
      getPageKey(target, depth) {
        depth = depth || 0
        if (depth > 2) {
          return null
        }
        let pageKey = target.getAttribute('pagekey')
        pageKey = pageKey || (target.previousElementSibling ? target.previousElementSibling.getAttribute('pagekey') : null)
        return pageKey || (target.firstElementChild ? this.getPageKey(target.firstElementChild, ++depth) : null)
      },
      onMenuSelect(key, target) {
        let pageKey = this.getPageKey(target)
        switch (key) {
          case '1':
            this.closeLeft(pageKey)
            break
          case '2':
            this.closeRight(pageKey)
            break
          case '3':
            this.closeOthers(pageKey)
            break
          default:
            break
        }
      },
      //关闭当前tab页，供子页面调用->望菜单能配置外链，直接弹出新页面而不是嵌入iframe
      closeCurrent(){
        this.remove(this.activePage);
      },
      closeOthers(pageKey) {
        let index = this.linkList.indexOf(pageKey)
        if (pageKey == indexKey || pageKey.indexOf('?ticke=')>=0) {
          this.linkList = this.linkList.slice(index, index + 1)
          this.pageList = this.pageList.slice(index, index + 1)
          this.activePage = this.linkList[0]
        } else {
          let indexContent = this.pageList.slice(0, 1)[0]
          this.linkList = this.linkList.slice(index, index + 1)
          this.pageList = this.pageList.slice(index, index + 1)
          this.linkList.unshift(indexContent.fullPath)
          this.pageList.unshift(indexContent)
          this.activePage = this.linkList[1]
        }
      },
      closeLeft(pageKey) {
        if (pageKey == indexKey) {
          return
        }
        let tempList = [...this.pageList]
        let indexContent = tempList.slice(0, 1)[0]
        let index = this.linkList.indexOf(pageKey)
        this.linkList = this.linkList.slice(index)
        this.pageList = this.pageList.slice(index)
        this.linkList.unshift(indexContent.fullPath)
        this.pageList.unshift(indexContent)
        if (this.linkList.indexOf(this.activePage) < 0) {
          this.activePage = this.linkList[0]
        }
      },
      closeRight(pageKey) {
        let index = this.linkList.indexOf(pageKey)
        this.linkList = this.linkList.slice(0, index + 1)
        this.pageList = this.pageList.slice(0, index + 1)
        if (this.linkList.indexOf(this.activePage < 0)) {
          this.activePage = this.linkList[this.linkList.length - 1]
        }
      },
      //动态路由title显示配置的菜单title而不是其对应路由的title
      dynamicRouterShow(key, id, title, component,refBill/*引单创建业务单据 */){ // 菜单点击事件
        // console.log('dynamicRouterShow',key,id,title,component,refBill)
        // console.log(key,id,title)
        let allRoutes = this.$router.getRoutes()
        // console.log('active page change', allRoutes)
        let item = allRoutes.find(item=>item.path==key)
        if(!item){
          console.error("路由信息不存在", key)
        }
        // console.log('fking item', item)
        let keyIndex = this.linkList.indexOf(key)
        if(keyIndex>=0){
          //切换历史页签
          let currRouter = this.pageList[keyIndex]
          delete currRouter.meta.refBill // 清除跳转额外参数
          let meta = Object.assign({},currRouter.meta,{title:title})
          meta.refBill = refBill
          this.pageList.splice(keyIndex, 1, Object.assign(currRouter,{meta:meta}))
          this.activePage = key
          // if (key === this.activePage) {
          //   this.changeTitle(title)
          // }
        } else {
          //打开新的页签
          // if(component) {
          //   let index = component.lastIndexOf("\/");
          //   component = component.substring(index + 1, component.length);
          // }
          // console.log('xxxxxxxxxxxxxxxxxxx',key, refBill)
          this.pageList.push({
            name: title,
            path: key,
            fullPath: key,
            cacheKey: item?.name, // 用于缓存控制，与组件命名一直，即表的id ---functionMenu  ,再permission 动态加载时，动态设置组件name属性
            meta: Object.assign(item.meta,{
              id: item?.name,
              icon: key,
              title: title,
              componentName: component,
              keepAlive: true,
              refBill:refBill
            })
          })
          this.linkList.push(key)
          this.activePage = key
        }
      },
    }
  }
</script>
<style lang="less" scoped>
  .route-container{
    border-radius: 3px;
    overflow: hidden;
    box-shadow: var(--el-box-shadow-dark);
    .no-showow{
      box-shadow:none;
    }
  }
</style>
