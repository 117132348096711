<template>
  <div class="padding nt-height-100" >
    <el-tree class="nt-height-100 nt-list-node" style="overflow-y: auto;"
      :data="datasource"
      :props="props"
      nodeKey="id"
      :expand-on-click-node="false"
      :indent="8"
      @node-click="onNodeClick"
      default-expand-all
      ref="refTree"
    />
  </div>
</template>
<script >

import {getSysKvListByParent} from '@/service/resource/general'
import {allBindingShop} from '@/service/resource/platform_shop'
import { DataListMixin } from '@/utils/data_list_mixin.js'
export default {
  mixins: [DataListMixin],
  data(){
    return {
      datasource:[],
      props: {
        label: 'shopName',
        class: function(node){
          return node.isShop? 'is-leaf':''
        }
      }
    }
  },
  methods:{
    async _loadList(){
      let res = await getSysKvListByParent({parentKey:'SYS_E_PLATFORM'}); // 获取数据字典类型列表
      let pInfo = {}
      res.data.forEach(item=>{
        pInfo[item.configKey] = {id:item.configKey,shopName:item.configValue,children:[]}
      })
     
      // 加载所有的门店--不区分平台
      res =  await allBindingShop({})
      let resultData = []
      if(res.data){
        res.data.forEach(item=>{
          let platformShop = pInfo[item.platform]
          item.isShop = true
          platformShop?.children.push(item)
        })
        Object.keys(pInfo).forEach(key=>{
          if(pInfo[key].children.length>0){ // 仅过滤存在店铺的平台根节点
            resultData.push(pInfo[key])
          }
        })
      }
      return {code:200,data:resultData}
    },
    _loadFinished(){
      if(this.datasource?.length>0){
        setTimeout(_=>{
          // 默认选择第一个平台下的店铺
          let firstNode = this.datasource[0].children[0]
          this.onNodeClick(firstNode)
        },0)
      }
    },
    onNodeClick(node){
      this.$refs.refTree?.setCurrentNode(node)
      if(node.isShop){ // 点击店铺节点
        this.$emit('change',node)
      }
    }

  }
}
</script>
