
<template>
  <el-container class="nt-height-100 nt-bg-page">
    <el-header class="no-margin height-auto no-padding">
      <nt-rpt-toolbar  :hidden="[]">
        <template #title>弹窗明细</template>
      </nt-rpt-toolbar>
    </el-header>
    <el-main class="padding">
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="单据日期" prop="billTime" width="105">
          <template #default="{row}">
            <span v-if="!row._summary">{{formatDate(row.billTime,'YYYY-MM-DD') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单据类型" width="100" prop="billType">
          <template #default="{row}">
            {{ this.EnumBillType[row.billType] }}
          </template>
        </el-table-column>
        <el-table-column label="供应商" width="180">
          <template #default="{row}">
            <span v-if="!row._summary">{{row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单据编号" prop="billNo" width="160">
          <template #default="{row}">
            <span>{{ row._summary?`${row.billNo||''}-小计`: row.billNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单据状态" prop="status" width="90">
        </el-table-column>
        <el-table-column label="付款状态" prop="executeStatus" width="90">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" >
        </el-table-column>
        <el-table-column label="采购单位" prop="unitName" width="85">
        </el-table-column>
        <el-table-column label="商品属性" prop="sku" width="90">
        </el-table-column>
        <el-table-column label="数量" prop="quantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="金额" prop="totalWithTax" width="90" align="right">
        </el-table-column>
        <!-- <el-table-column width="10%">
        </el-table-column> -->
      </el-table>
    </el-main>
  </el-container>

  
</template>
<script>

import { getTradeItemList } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
export default {
  name: "inner_trade_item_detail",
  mixins: [DataListMixin],
  components: {
    NtDateRange,
    NtRptToolbar
  },
  data() {
    return {
      // subQuery: null, //详情查询条件
      // 查询条件
      queryModel: this.query,
    }
  },
  props:{
    query:{
      type:Object, // 外部传入查询条件
    }
  },
  methods: {
    async _loadList(params) {
      let res = await getTradeItemList(Object.assign(params, this.queryModel))
      return res
    }
    // onSearchQueryClick() {
    //   this.loadList();
    // }
  }
}
</script>
