
<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="客户">
          <nt-dropdown-grid  configKey="customer" v-model="queryModel.id"
          placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="欠款详情" width="100">
          <template #default="scope">
            <el-space>
              <a @click="onViewDetailClick(scope.row)">{{ scope.row.id ? '详情' : '' }}</a>
            </el-space>
          </template>

        </el-table-column>
        <el-table-column label="客户" prop="companyName">
        </el-table-column>
        <el-table-column label="联系人" prop="contacts">
        </el-table-column>
        <el-table-column label="手机号码" prop="telephone">
        </el-table-column>
        <el-table-column label="联系电话" prop="phoneNum">
        </el-table-column>
        <el-table-column label="电子邮箱" prop="email" width="160">
        </el-table-column>
        <el-table-column label="期初应收" prop="initTotal" width="90">
          <template #default="scope">
            {{ ((scope.row.initTradeTotal - scope.row.initPayTotal) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期欠款" prop="currentTradeTotal" width="90">
          <template #default="scope">
            {{ ((scope.row.initTradeTotal - scope.row.initPayTotal) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期收款" prop="currentPayTotal" width="90">
          <template #default="scope">
            {{ ((scope.row.currentPayTotal) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="期末应收" prop="total" width="90">
          <template #default="scope">
            {{
              ((scope.row.initTradeTotal - scope.row.initPayTotal + scope.row.currentTradeTotal - scope.row.currentPayTotal)
                / 100).toFixed(2)
            }}
          </template>
        </el-table-column>

      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <el-drawer v-model="tradeBillVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="tradeBillVisible = false">
    <trade-bill-list ref="debtBillList" :companyId="tradeBillInfo.companyId" :billType="tradeBillInfo.billType"
      @cancel="tradeBillVisible = false"></trade-bill-list>
  </el-drawer>
</template>
<script>
import { getCustomerSummaryList } from '@/service/report/rpt_supplier'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
import TradeBillList from '../bill_list/TradeBillList'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "CustomerPayment",
  mixins: [DataListMixin],
  components: {
    TradeBillList,
    NTDateRange
  },
  data() {
    return {
      tradeBillVisible: false,
      toggleSearchStatus: false,
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        companyId: '',
        startTime: getNowFormatYear() + '-01-01',
        endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  methods: {

    async _loadList(params) {
      delete params.createTimeRange
      return await getCustomerSummaryList(Object.assign(params))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      // 查看欠款详情：查看本地的销售出库， 期初初始化单据
      this.tradeBillInfo.companyId = record.id
      this.tradeBillInfo.billType = 'SALE_OUT,SALE_BACK'
      this.tradeBillVisible = true
    }
  }
}
</script>

