<!-- 
 下拉选择框 + 时间范围
  
  -->
<template>
  <div :class="`nt-custom  display-flex ${config?'config':''}`" :style="{columnGap:'0px',width: config?'': (width||140)+'px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <el-input :title="label+':'+ startPlaceholder" v-model="startValue" :placeholder="startPlaceholder" clearable size="small" :class="`${getHighlightClass('start')} first flex-auto`" @change="onTextChange"></el-input>
    <span>-</span>
    <el-input :title="label+':'+endPlaceholder" v-model="endValue" :placeholder="endPlaceholder" clearable size="small" :class="`${getHighlightClass('end')} flex-auto `" @change="onTextChange"></el-input>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        startValue: this.modelValue?.startValue,
        endValue: this.modelValue?.endValue,
        defaultValue: Object.assign({}, this.modelValue)
      }
    },
    props:{
      modelValue:{},
      options:{
        type:Array,
        default:_=>[]
      },
      startPlaceholder:{
        type:String
      },
      endPlaceholder:{
        type:String
      },
      width:{
        type:Number
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.startValue = this.modelValue?.startValue
        this.endValue = this.modelValue?.endValue
        this.defaultValue = Object.assign({}, this.modelValue)
      }
    },
    methods:{
      getHighlightClass(type){
        if(type == 'start'){
          return (this.defaultValue?.startValue||'') != (this.startValue||'')? 'highlight':''
        } else{
          return (this.defaultValue?.endValue||'') != (this.endValue||'')? 'highlight':''
        }
      },
      onTextChange(){
        let data = {
          startValue: this.startValue, // '区间开始值'
          endValue: this.endValue // '区间开始值'
        }
        this.$emit('update:modelValue', Object.assign({},this.defaultValue, data))
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    .highlight{
      :deep(.el-input__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-input__wrapper){
        padding:0px 4px;
        height: 24px;
      }
      .first{
        :deep(.el-input__wrapper){
          box-shadow: none;
          box-sizing: border-box;
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          
        }
      }
      
    }
    
  }
</style>