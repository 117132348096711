<template>
  <div class="nt-qr-code" ref="refQrCode"></div>
</template>

<script >
// https://github.com/Binaryify/vue-qr
import QRCode from 'qrcodejs2-fix'
export default {
  data () {
    return {
      
    }
  },
  components:{},
  props:{
    config:{
      type: Object
    }
  },
  watch:{
    "config.v":function(){
      this.code?.clear()
      this.code?.makeCode(this.config.v)
    }
  },
  mounted(){
    this.code = new QRCode(this.$refs.refQrCode, {
      text: this.config.v,
      width: 100,
      height: 100,
      colorDark : "#000000",
      colorLight : "#ffffff",
      correctLevel : QRCode.CorrectLevel.H
    });
  },
  methods:{
    
  }
}
</script>