
<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="订单编号" width="160" prop="orderNo"></el-table-column>
        <el-table-column label="所属门店" width="120" prop="shopName"></el-table-column>
        <el-table-column label="订单来源" width="120" prop="platform"></el-table-column>
        <el-table-column label="买家" width="120" prop="nickName"></el-table-column>
        <el-table-column label="买家电话" width="120" prop="buyerTel"></el-table-column>
        <el-table-column label="收货地址" width="220" prop="buyerAddress"></el-table-column>
        <el-table-column label="付款状态" width="120" prop="qrCode"></el-table-column>
        <el-table-column label="付款方式" width="100" prop="appId"></el-table-column>
        <el-table-column label="买家备注" width="120" prop="buyerRemark"></el-table-column>
        <el-table-column label="运费" width="120" prop="freight"></el-table-column>
        <el-table-column label="商品总额" width="120" prop="platform"></el-table-column>
        <el-table-column label="商品信息" width="220" prop="platform"></el-table-column>
        <el-table-column label="折扣金额" width="100" prop="createTime"></el-table-column>
        <el-table-column label="实付金额" width="160" prop="createTime"></el-table-column>
        <el-table-column label="订单状态" width="160" prop="createTime"></el-table-column>
        <el-table-column label="支付状态" width="160" prop="createTime"></el-table-column>
        <el-table-column label="发货状态" width="160" prop="createTime"></el-table-column>
        <el-table-column label="物流状态" width="160" prop="createTime"></el-table-column>
        <el-table-column label="ERP单号" width="160" prop="erpOrderNo"></el-table-column>
        <el-table-column label="同步结果" width="160" prop="erpOrderNo"></el-table-column>
        
        
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "StoreList",
  mixins: [DataListMixin],
  components: {
    NtFormItem
  },
  data() {
    return {

    }
  },
  props:{
    parentId:{
      type:String,
      required:true
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_ORDER, Object.assign({parentId:this.parentId},params));
    },
    
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.PLATFORM_ORDER, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>