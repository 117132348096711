<!-- 订单操作日志 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin">
      <el-space wrap>
        <span>查询时间</span>
        <el-date-picker
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :size="size"
        />
        <el-button size="small">查询</el-button>
        
      </el-space>
      
    </el-header>
    <el-main class=" no-padding nt-box-panel">
      <el-table :data="[{}]" height="100%">
        <el-table-column label="操作人"></el-table-column>
        <el-table-column label="操作内容"></el-table-column>
        <el-table-column label="操作时间"></el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>
<script>


export default {
  name: "BIZ_COMPANY",
  components: {
    
  },

  data() {
    return {
      radio1:null,
      value:null
    }
  },
  methods: {
  }
}
</script>