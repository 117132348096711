<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
      <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
        
      </el-header>
      <el-main class="no-padding">
        <el-table
          border
          ref="table"
          size="middle"
          rowKey="goodsSkuId"
          :data="datasource"
          v-loading="isLoading"
          >
          <el-table-column label="往来单位" prop="companyName">
        </el-table-column>
        <el-table-column label="单据类型" prop="billType" width="120">
          <template #default="scope">
            {{this.EnumBillType[scope.row.billType] || scope.row.billType }}
          </template>
        </el-table-column>
        <el-table-column label="单据编号" prop="billNo" width="160">
        </el-table-column>
        <el-table-column label="单据日期" prop="billTime" width="170">
        </el-table-column>
        <el-table-column label="单据总额" prop="total" width="90" align="right">
          <template #default="scope">
            {{ (scope.row.total||0)/100.00 }}
          </template>
        </el-table-column>
        <el-table-column label="已结算" prop="settleTotal" width="90" align="right">
          <template #default="scope">
            {{ (scope.row.settleTotal||0)/100.00 }}
          </template>
        </el-table-column>
        <el-table-column label="待结算" prop="unsettelTotal" width="90" align="right">
          <template #default="scope">
            {{ (scope.row.total-(scope.row.settleTotal||0))/100.00 }}
          </template>
        </el-table-column>
        
        </el-table>
      </el-main>
      <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="end">
          <el-pagination
          :current-page="this.pageInfo.pageIndex"
          :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="this.pageInfo.total"
          background 
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="onPageIndexChange"
          @size-change="onPageSizeChange"
        />
        </el-row>
      </el-footer>
    </el-container>
  
</template>

<script>
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {getBillList} from '@/service/report/rpt_supplier'
  export default {
    name: 'DebtBillList',
    mixins:[ DataListMixin],
    components: {
    },
    data () {
      return {
        
        queryModel: {
          materialParam: "",
          billNo: "",
        },
        
      }
    },
    
    props:{
      billType:{
        type:String,
        required:true
      },
      companyId:{
        type:String,
        required:true
      }
    },
    created() {
    },
    methods: {
      async _loadList(params){
        return await getBillList( Object.assign({companyId:this.companyId},params,this.queryModel))
      },
      _loadFinished(){
      },
      close () {
        this.$emit('cancel');
        // this.visible = false;
      },
      onCancelClick(){
        this.close()
      },
      handleCancel () {
        this.close()
      },
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectionRows = selectionRows;
      },
      onSearchQueryClick(){},
      handleOk () {
        this.selectBillRows = this.datasource.filter(el=>{
          return this.selectedRowKeys.includes(el.id)
        })
        // this.getSelectBillRows();
        this.$emit('choose', this.selectBillRows);
        this.selectedRowKeys = []
        this.selectBillRows = []
      },
      onDateChange: function (value, dateString) {
        this.queryModel.startTime=dateString[0];
        this.queryModel.endTime=dateString[1];
      },
      onDateOk(value) {
        console.log(value);
      },
      onSearchResetClick() {
        this.queryModel = {
          type: this.queryModel.type,
          subType: this.queryModel.subType
        }
        this.loadData(1);
      }
    }
  }
</script>
