import { axios as service } from '@/utils/request'


export const getAllocateDetailList = (data) => {
  return service({
    url: '/report/allocate/detail',
    method: 'post',
    data
  })
}

