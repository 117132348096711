<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <nt-form-item prop="unitName" label="基本单位" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入基本单位(小单位)" v-model.trim="formModel.unitName" />
        </nt-form-item>
        <nt-form-item v-if="formModel.parentId !== '-1'" prop="parent_id" label="所属上级">
          <el-input placeholder="请选择上级" v-model.trim="formModel.parentName" readonly />
        </nt-form-item>
        <nt-form-item v-if="formModel.parentId !== '-1'" prop="exchangeRate" label="换算比例" class="no-margin-bottom"
          :rules="formValidator('required', '请输入换算比例')">
          <el-input placeholder="换算比例" v-model.number="formModel.exchangeRate" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
  
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'

export default {
  name: "UnitModal",
  mixins: [DataEditMixin],
  components: { NtFormItem },
  data() {
    return {

    }
  },
  props: {
    entityId: {
      type: String
    },
    parentUnit: {
      type: Object
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  methods: {
    async _initialize() {
    },
    _loadFinished() {
      this.formModel.exchangeRate = (this.formModel.exchangeRate || 0) / 100
      console.log('xxxxx', this.formModel)
    },
    async _loadEntity(params) {
      if (!this.entityId) {
        if (this.parentUnit) {
          return { data: { parentId: this.parentUnit.id, parentName: this.parentUnit.unitName, exchangeRate: 100 } }
        } else {
          return { data: { exchangeRate: 100 } }
        }
      } else {
        let res = await this.getEntity(this.EnumEntityType.GOODS_UNIT, { id: this.entityId })
        return res
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      postData.exchangeRate = (postData.exchangeRate || 0) * 100
      return this.saveEntity(this.EnumEntityType.GOODS_UNIT, postData)
    },
  }
}
</script>
<style scoped></style>