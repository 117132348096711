<template>
  <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading" style="height:530px;">
    <el-container class="nt-height-100 ">
      <el-main class="padding-16" style="overflow: hidden;">
        <el-tabs v-model="activeTabName"  class="nt-width-100 nt-height-100 nt-tab-page nt-box-panel" >
          <el-tab-pane label="基本信息" name="base" class="padding-16 nt-height-100 nt-bg-white ">
            <el-row :gutter="24" style="row-gap: 8px;">
              <el-col :span="12">
                <nt-form-item  label="单位编码" prop="companyCode" :rules="formValidator('required', '选择输入单位编码')">
                  <el-input placeholder="请输入单位编码" v-model="formModel.companyCode"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="单位名称" prop="companyName" :rules="formValidator('required', '选择输入单位名称')">
                  <el-input placeholder="请输入单位名称" v-model="formModel.companyName"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item label="单位性质" :rules="formValidator('required', '单位性质不能为空')">
                  <el-select v-model="formModel.companyType" :disabled="viewOnly">
                    <el-option :value="1" label="客户" />
                    <el-option :value="2" label="供应商" />
                    <el-option :value="3" label="客户&供应商" />
                  </el-select>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="经营形态">
                  <nt-dropdown-grid configKey="sysKv" v-model="formModel.businessForm" :requestParam="{parentKey:'SYS_BUSINESS_FORM'}"
                    v-model:text="formModel.businessFormName" :disabled="viewOnly" placeholder="请选择价格等级" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item label="客户分类" prop="categoryId" >
                  <nt-company-category-tree @change="fking" v-model="formModel.categoryId" v-model:text="formModel.categoryName" ></nt-company-category-tree>
                </nt-form-item> 
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="客户片区">
                  <el-input placeholder="请输入客户片区"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="定价方式">
                  <el-radio-group v-model="formModel.priceMode">
                    <el-radio label="DISCOUNT" >折扣</el-radio>
                    <el-radio label="LEVEL" >等级</el-radio>
                  </el-radio-group>
                </nt-form-item>
              </el-col>
              
              <el-col :span="12">
                <nt-form-item  label="客户价格等级">
                  <nt-dropdown-grid configKey="sysKv" v-model="formModel.priceLevel" :requestParam="{parentKey:'SYS_PRICE_LEVEL'}"
                    v-model:text="formModel.priceLevelName" :disabled="viewOnly" placeholder="请选择价格等级" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="客户等级">
                  <nt-dropdown-grid configKey="sysKv" v-model="formModel.companyLevel" :requestParam="{parentKey:'SYS_CUSTOMER_LEVEL'}"
                    v-model:text="formModel.companyLevelName" :disabled="viewOnly" placeholder="请选择等级" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="收款条件">
                  <nt-dropdown-grid configKey="sysKv" v-model="formModel.paymentCondition" :requestParam="{parentKey:'SYS_PAYMENT_CONDITION'}"
                    v-model:text="formModel.paymentConditionName" :disabled="viewOnly" placeholder="请选择条件" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                  </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="客户标签">
                  <nt-tag-input v-model="formModel.tags" tagType="primary"></nt-tag-input>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="所属区域" >
                  <nt-area-cascade v-model="formModel.regionInfo"></nt-area-cascade>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="分管人员">
                  <nt-dropdown-grid configKey="staff" v-model="formModel.handlerId" style="width:100%"
                v-model:text="formModel.handlerName" :disabled="viewOnly" placeholder="请选择分管人员" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="分管部门">
                  <nt-dropdown-grid configKey="dept" v-model="formModel.handlerDeptId" style="width:100%"
                v-model:text="formModel.handlerDeptName" :disabled="viewOnly" placeholder="请选择分管部门" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>
              
              <el-col :span="12">
                <nt-form-item  label="地址">
                  <el-input placeholder="请输入地址" v-model="formModel.address"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="备注">
                  <el-input placeholder="请输入备注" v-model="formModel.description"  />
                </nt-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="企业注册信息" name="supplier" class="padding-16 nt-bg-white">
            <el-row :gutter="24" style="row-gap: 8px;">
              <el-col :span="12">
                <nt-form-item  label="社会信用代码">
                  <el-input placeholder="请输入社会信用代码" v-model="formModel.taxNum" />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="法人">
                  <el-input placeholder="请输入法人" v-model="formModel.owner" />
                </nt-form-item>
              </el-col>
              
              <el-col :span="12">
                <nt-form-item  label="开户行">
                  <el-input placeholder="请输入开户行" v-model="formModel.bankName"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="账号">
                  <el-input placeholder="请输入账号" v-model="formModel.accountNumber"   />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="税率(%)">
                  <el-input-number style="width:100%" placeholder="请输入税率" v-model="formModel.taxRate"  />
                </nt-form-item>
              </el-col>
              <el-col :span="12">
                <nt-form-item  label="建档时间">
                  <el-input placeholder="请输入建档时间" v-model="formModel.maintanentDate"   />
                </nt-form-item>
              </el-col>
            </el-row>
            
          </el-tab-pane>
          <el-tab-pane label="联系人信息" name="customer" class="padding-16 nt-bg-white" >
            
            <el-table border  :data="formModel.bizCompanyContactorList" height="100%" size="large" class="nt-bill-table-list">
              <el-table-column label="#" type="index">
                <template #header>
                  <el-button @click="onAddContactorClick" size="small">add</el-button>
                </template>
              </el-table-column>
              <el-table-column label="#" type="selection"></el-table-column>
              <el-table-column label="联系人">
                <template #default="scope">
                  <nt-form-item label-width="0" :prop="`bizCompanyContactorList[${scope.$index}].contactor`">
                    <el-input v-model="scope.row.contactor" />
                  </nt-form-item>
                </template>
              </el-table-column>
              <el-table-column label="收发货地址" width="200"></el-table-column>
              <el-table-column label="电话"></el-table-column>
              <el-table-column label="传真"></el-table-column>
              <el-table-column label="手机"></el-table-column>
              <el-table-column label="qq"></el-table-column>
              <el-table-column label="邮箱"></el-table-column>
              <el-table-column label="微信"></el-table-column>
              <el-table-column label="所在地区"></el-table-column>
              <el-table-column label="操作" fixed="right"></el-table-column>
            </el-table>
          </el-tab-pane>
          
          <el-tab-pane label="图片+附件" name="ss" class="padding-16 nt-bg-white" >
            <div>客户图片(最多5张)</div>
            <div class="border">
              <el-space>
                <span>TODO</span><span>TODO</span><span>TODO</span><span>TODO</span>
              </el-space>
            </div>
            <div class="margin-top">附件 <el-button>上传</el-button></div>
            <div class="border">
              <el-space>
                <span>TODO</span><span>TODO</span><span>TODO</span><span>TODO</span>
              </el-space>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-main>
      <el-footer class=" height-auto no-margin padding-16" style="padding-top:0px!important;">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>
  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import NtFormItem from '@/views/plugin/nt_form_item'
  import NtTagInput from '@/views/plugin/nt_tag_input'
  import NtAreaCascade from '@/views/plugin/nt_area_cascade'
  import NtCompanyCategoryTree from '@/views/plugin/nt_company_category_tree'
  
  export default {
    name: "CustomerResource",
    mixins: [DataEditMixin],
    components:{
      NtFormItem,
      NtTagInput,
      NtCompanyCategoryTree,
      NtAreaCascade
    },
    data () {
      return {
        activeTabName:'base',
        formModel:{
          companyType: this.companyType,
          bizCompanyContactorList:[]
        }
      }
    },
    props:{
      entityId:{
        type:String
      },
      companyType:{
        type: Number
      }
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.EnumEntityType.BIZ_COMPANY, {id: this.entityId})
        }
      },
      
      _loadFinished(){
        
      },
      onAddContactorClick(){ // 添加联系人
        this.formModel.bizCompanyContactorList.push({ }) //第一次添加，默认主单位
      },
      onRegionChange(val){
        console.log('onRegionChange',val)
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,companyType:this.companyType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        // console.log('fkkddkd', postData)
        // return false
        return this.saveEntity(this.EnumEntityType.BIZ_COMPANY, postData)
      },
    }
  }
</script>