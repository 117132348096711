<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding nt-box-panel nt-bg-white">
        <nt-form-item label="区域" >
          <el-cascader-panel ref="refArea" v-model="chooseDistrictCodes" style="width: fit-content" :options="options" :props="areaProps"  />
        </nt-form-item>
        <!-- 不可到达区域，无需选择模版 -->
        <nt-form-item label="面单模版" prop="waybillTemplateId" :rules="formValidator('required', '请选择面单模版')" v-if="configType=='ACCEPT_AREA'">
          <nt-dropdown-grid configKey="waybillTemplate" v-model="formModel.waybillTemplateId" :requestParam="platformProp"
                    v-model:text="formModel.templateName" @change="onTemplateChange" placeholder="请选择面单模版" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item>
          <el-switch
              v-model="formModel.enabled"
              active-text="启用"
              :active-value="1"
              :inactive-value="0"
            />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>
import { DataEditMixin } from '@/utils/data_edit_mixin'
import { saveDeliveryArea, getDeliveryArea } from '@/service/print_order/platform_waybill_template'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtTagInput from '@/views/plugin/nt_tag_input'
import pcaa from '@/utils/pcaa'

import allLevel from  '@/json/level.json' //'province-city-china/dist/level.json' // https://github.com/uiwjs/province-city-china
export default {
  name: "delivery_config_detial",
  mixins: [DataEditMixin],
  components: { NtFormItem, NtTagInput },
  data() {
    return {
      areaProps:{
        value:'code',
        label:'name',
        multiple:true,
        emitPath:false,
      },
      chooseDistrictCodes:[],
      options: allLevel //[]
    }
  },
  props: {
    entityId: {
      type: String
    },
    configType:{
      type:String,
      default:'ACCEPT_AREA'
    },
    platform:{
      type:String,
      required:true
    }
  },
  computed:{
    platformProp:function(){
      return {platform:this.platform}
    },
  },
  created() {
    // this.createHierachyArea()
  },
  methods: {
    createHierachyArea(){
      
      let getChildren = function(key){
        let children = []
        let item = pcaa[key]
        if(!item){
          return null
        }
        Object.keys(item).forEach(key=>{
          let areaInfo = {code:key,name:item[key]}
          let tmpChild = getChildren(key)
          if(tmpChild){
            areaInfo.children = tmpChild
          } else {
            areaInfo.leaf = true
          }
          children.push(areaInfo)
        })
        return children
      }
      this.options = getChildren('86')
    },
    async _loadEntity() {
      if (this.entityId) {
        let res =  await getDeliveryArea({ id: this.entityId })
        if(res.data?.districtCodes){
          this.chooseDistrictCodes = res.data?.districtCodes.split(',')
        }
        return res
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    onTemplateChange(tpl){
      this.formModel.deliveryId = tpl.deliveryId
    },
    async _saveEntity() {
      let postData = Object.assign({platform:this.platform,configType:this.configType, id: this.entityId }, this.formModel)
      let allDistrictObjects = this.$refs.refArea.getCheckedNodes(true)
      let districtCodes = []
      let districtNames = []
      allDistrictObjects.forEach(el=>{
        districtCodes.push(el.data.code)
        districtNames.push(el.data.name)
      })
      postData = JSON.parse(JSON.stringify(postData))
      postData.districtCodes = districtCodes.join(',')
      postData.districtNames = districtNames.join(',')
      return saveDeliveryArea(postData)
    },
  }
}
</script>
<style scoped></style>