<!--
  选择下拉框组件，点击 【更多】时，弹出高级选择框
 -->
<template>
  <el-dialog
    v-model="dynamicVisible" destroyOnClose width="80%" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog"
    title="选择"
    @close="dynamicVisible=false"
  >
    <!-- 动态组件加载... 是否多选 -->
    <component :is="dynamicSelector" :multiple="multiple" @choose="onChooseHandler"  @cancel="dynamicVisible = false"></component>
  </el-dialog>
</template>
<script>
  
  import {createAdvanceSelecorComponent} from './component.js'
  export default {
    name: "advanceResourceEditor",
    components: {
    },
    data () {
      return {
        dynamicVisible:false,
        multiple:false,
        dynamicSelector:null
      }
    },
    methods: {
      async showSelector(configKey,multiple,callback){// 显示高级弹窗选择
        this._callback = callback // 回调方法
        this.multiple = multiple
        this.dynamicSelector = await createAdvanceSelecorComponent(configKey)
        if(!this.dynamicSelector){
          // 创建组件失败，组件不存在
          console.error('编辑组件配置信息有误，请检查:' + configKey)
        }
        this.dynamicVisible = true
      },
      onChooseHandler(result/*单选 或多选 的数组结果 */){
        this._callback && this._callback(result) // 新增结果通过回调返回
        
        this.dynamicVisible = false
      }
    }
  }
</script>