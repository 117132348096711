<template>
  <el-container class="padding nt-box-panel" style="height:450px;">
    <el-header class="height-auto no-padding no-margin display-flex" v-if="processType=='CREATE_PRINT'/*仅生成面单，无需选择打印机 */">
      <span style="font-weight:bold;font-size:18px;">打印机</span>
      <nt-printer v-model="printerName" style="width:220px"></nt-printer>
    </el-header>
    <el-main class="no-padding  margin-top">
      <el-table :data="datasource" border height="100%" class="nt-resource-table">
        <el-table-column type="index" width="45"></el-table-column>
        <el-table-column label="订单信息">
          <template #default="{ row }">
            <div style="font-size:12px;">
              <div  class="margin-bottom-4"><span>订单号:</span><span>{{ row.orderNo }}</span></div>
              <div v-if="row.waybillNo"><span>运单号:{{ row.packageNum }}:</span>
                <span>{{ row.waybillNo }}</span>
              </div>
              <div v-else>
                <span>包裹#{{ row.packageNum }}:</span>
                <span  style="color:var(--el-color-warning);" >待取号</span>
              </div>
              <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="面单模版" prop="templateName">
          <template #default="{row}">
            <div v-if="row.templateName">{{ row.templateName }}</div>
            <div v-else style="color:var(--el-color-primary);font-weight: bold;">{{ template.templateName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="商品数量" prop="quantity" width="90"></el-table-column>
        <el-table-column label="状态" align="center" width="140">
          <template #default="{ row }">
            <!-- 等待生成  正在获取面单 正在打印面单  打印完成 ==》有错误信息？ -->
            <el-icon class="is-loading" v-if="row.inProgress">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus">{{ formatProcessStatus(row.processStatus) }}</span>
            <span v-else>就绪</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding no-margin">
      <div style="font-size:12px;color:var(--el-color-warning);margin-top:4px;">
        <span>提示：已设置过模版的包裹，将按包裹指定的模版进行面单取号，未设置模版则按当前选中模版</span>
        <span style="font-weight: bold;font-size:14px;color:var(--el-color-primary);">【{{template.templateName}}】</span>
        <span>进行取号</span>
      </div>
      <!-- <div class="margin-top">共{{ packageList.length }}个包裹</div> -->
      <!--<el-button @click="onPrintTest">打印测试</el-button>-->
      <div class="align-center margin-top">
        <el-button type="primary" v-if="taskPhase == 'FIRST'" :loading="inProgress" @click="onStartClick">{{ processType
          == 'CREATE_PRINT' ? '生成并打印快递单' : '生成快递单' }}</el-button>
        <!-- <el-button type="primary" v-if="taskPhase == 'SECOND'" :loading="inProgress"
          @click="onPrintClick">打印快递单</el-button> -->
        <el-button type="primary" v-if="['SECOND', 'THIRD'].includes(taskPhase) && !allFailed" :loading="inProgress"
          @click="onDispatchClick">发货</el-button>
        <el-button type="primary" v-if="['SECOND', 'THIRD'].includes(taskPhase) && !allFailed" :loading="inProgress"
          @click="onPreDispatchClick">预发货</el-button>
        <el-button type="primary" v-if="[null, 'SECOND', 'THIRD'].includes(taskPhase) && !inProgress"
          @click="onFinishHandler">关闭</el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>

import NtWaybillCreatorManager from '@/utils/waybill_creator_manager' // 面单生成管理器
import NtPrintManager from '@/utils/print_manager' // 打印管理器
import NtWaybillDispatchManager from '@/utils/waybill_dispatch_manager' // 面单发货管理器
import NtPrinter from '@/views/plugin/nt_printer'
import { useWeixinPrintServiceStore } from '@/piana/weixin_printer_service.js'
import { useCainiaoPrintServiceStore } from '@/piana/cainiao_printer_service.js'
import { updatePrint, updatePreDispatch } from '@/service/print_order/platform_waybill'
export default {
  name: "createWaybill",
  data() {
    return {
      printerName: this.template?.printerName, // 打印机
      datasource: this.packageList || [],
      taskPhase: 'FIRST',// 第一阶段: 生成面单
      // taskStatus: 'READY', // REDAY 就绪  EXECUTTING--正在进行  DONE 完成
      taskType: this.processType // 任务类型
    }
  },
  components: { NtPrinter },
  props: {
    packageList: { // 需要操作的包裹列表
      type: Array,
      required: true
    },
    template: { // 面单模版
      type: Object,
      required: true
    },
    needPrintData: { //  是否在生成面单后，打印面单
      type: Boolean,
      default: true
    },
    processType: {
      type: String,
      default: 'CREATE_PRINT' // 默认为【生成面并打印】单场景：CREATE_PRINT  CREATE: 仅创建面单不打印
    }
  },
  computed: {
    inProgress: function () {
      return this.datasource.some(item => item.inProgress)
    },
    allFailed: function () {  // 所有的任务都失败
      let hasSucessItem = this.datasource.some(item => item.processStatus != -1)
      return !hasSucessItem
    }
  },
  async created() {
    this._disatchManager = new NtWaybillDispatchManager((resultList, result) => {

      if (result.type == 'success') {
        // 根据结果，判断是否满足打印, 根据list
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            delete matchItem.inProgress
            if (item.success) {
              matchItem.isDispatch = true
              matchItem.processStatus = 2 // 判断发货结果
            } else {
              matchItem.processStatus = -1
              matchItem.errorMsg = item.message // 记录异常消息
            }
          }
        })
        // 判断是否都已完成
        let allFinished = !this.datasource.some(item => item.inProgress != true)
        if (!allFinished) {
          // this.taskStatus = 'DONE'
          this.taskPhase = null //无任何任务信息
        }

      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        this.$message.error(result.msg)
      } else if (result.type == 'overTime') { // 查询[发货]结果超时
        let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
        if (matchItem) {
          delete matchItem.inProgress
        }
      } else if (result.type == 'query') {
        // 轮询网络异常 TODO
      }

    })

    this._printManager = new NtPrintManager((result, data /*打印结果：单个，全部完成为数组 */) => {
      if (result.type == 'printed') { // 单个文档打印完成
        let arPackInfo = data.requestID.split('-') // orderId  + packageNum
        let packageInfo = { orderId: arPackInfo[0], packageNum: arPackInfo[1] }
        let matchItem = this.datasource.find(item => item.orderId == packageInfo.orderId && item.packageNum == packageInfo.packageNum)
        if (!matchItem) {
          return
        }

        delete matchItem.inProgress

        if (result.success) {
          matchItem.printCnt = 1
          matchItem.processStatus = 3 // 打印完成
        } else { // 打印失败
          matchItem.processStatus = -1
          matchItem.errorMsg = result.msg
        }

      } else if (result.type == 'done') {// 全部打印完成，注意存在多次批次打印，如面单，每次可能只返回n面单生成结果，通过轮询不断触发
        let printSuccessList = []
        data?.forEach(item => {
          if (item.success) { //进处理队列中已成功的打印数据
            let arPackInfo = item.requestID.split('-') // orderId  + packageNum
            printSuccessList.push({ orderId: arPackInfo[0], packageNum: arPackInfo[1] })
          }
        })
        // 打印结束--- // 更新订单已打印成功
        if (printSuccessList.length > 0) {
          updatePrint(printSuccessList)
        }
        // 打印结束，即生成面单并打印结束， 等待用户选择 下一步操作  发货 / 预发货  /关闭刷新
        this.taskPhase = 'THIRD' // 第三阶段：发货 或预发货

      }

    })
    // 初始化面单生成基础信息
    this._waybillManager = new NtWaybillCreatorManager(true, (list, result) => {
      // list 为面单创建结果--含打印报文  或 包裹列表--异常时
      // 根据result.type 区分  create=面单创建请求 overTime="面单结果查询超时" success 面单创建成功
      if (result.type == 'success') {
        // 是否需要进入打印流程
        if (this.processType == 'CREATE_PRINT') { // 生成并打印
          // 根据结果，判断是否满足打印, 根据list
          let printObjectList = []
          list.forEach(item => {
            let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
            if (matchItem) {
              delete matchItem.inProgress // 任务结束
              delete matchItem.processStatus // 任务结束
              if(item.success && !matchItem.templateName){ // 未指定模版，生成后更新模版ui
                matchItem.templateName= this.template.templateName
              }
              // 判断面单是否生成成功
              if (item.success || matchItem.waybillNo) { // 成功或单号已存在
                matchItem.waybillNo = item.waybillNo
                matchItem.waybillId = item.waybillId // 主键id  回收时使用
                matchItem.processStatus = 2 // 生成面单完成==》准备打印
                matchItem.inProgress = true
                item.requestID = `${item.orderId}-${item.packageNum}`
                printObjectList.push(item)
              } else {
                matchItem.processStatus = -1 // 失败
                matchItem.errorMsg = item.message
              }
            }
          })
          // 判断所有的任务是否生成面单结束？，如存在任意成功的面单，则运行进入下一阶段=====
          if (this.allFailed) {
            this.taskPhase = null // 强制终止所有任务
          }
          if (printObjectList.length > 0) {
            this._printManager.print(this.printerName, false, printObjectList)
          }
        } else { // 仅生成面单--不打印
          list.forEach(item => {
            let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
            if (matchItem) {
              delete matchItem.inProgress // 任务结束
              if(item.success && !matchItem.templateName){ // 未指定模版，生成后更新模版ui
                matchItem.templateName= this.template.templateName
              }
              // 判断面单是否生成成功
              if (item.success || item.waybillNo) {
                matchItem.processStatus = 2
                matchItem.waybillNo = item.waybillNo
                matchItem.waybillId = item.waybillId // 主键id  回收时使用
              } else {
                matchItem.processStatus = -1 // 失败
                matchItem.errorMsg = item.message
              }
            }
          })

          // 判断是否都已完成
          let allFinished = !this.datasource.some(item => item.inProgress != true)
          if (!allFinished) {
            this.taskPhase = 'SECOND' // [仅生成面单] 全部完成后，进入第二阶段 打印  或 三阶段  发货/预发货   关闭
          }
        }

      } else if (result.type == 'create') { // 创建面单失败
        // 弹窗提示 错误信息
        this.$message.error(result.msg)
      } else if (result.type == 'overTime') { // 查询面单结果超时
        let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
        if (matchItem) {
          delete matchItem.inProgress
        }
      }
    })

  },
  methods: {
    formatProcessStatus(status) {
      let info = {
        'CREATE_PRINT_1': '正在获取面单..',
        'CREATE_PRINT_2': '正在打印...',
        'CREATE_PRINT_3': '打印已完成',
        'CREATE_PRINT_-1': '获取面单失败',
        'CREATE_1': '正在获取面单..', //仅生成面单
        'CREATE_2': '生成完成',
        'CREATE_-1': '获取面单失败',
        'PRINT_1': '正在打印..',
        'PRINT_2': '打印完成',
        'PRINT_-1': '打印失败',
        'DISPATCH_1': '正在发货',
        'DISPATCH_2': '发货完成',
        'DISPATCH_-1': '发货失败',
        'PRE_DISPATCH_1': '正在预发货',
        'PRE_DISPATCH_2': '预发货完成',
        'PRE_DISPATCH_-1': '预发货失败'
      }
      let key = `${this.taskType}_${status}`
      return info[key] || status
    },
    async onStartClick() {
      this.datasource.forEach(el => {
        el.inProgress = true
        el.processStatus = 1 // 正在获取面单
      })

      this._waybillManager.startCreateWaybill(this.template?.id, this.datasource)

    },
    async onDispatchClick() {
      // 开始发货
      this.taskType = 'DISPATCH'
      let list = this.datasource.filter(item => item.processStatus != -1) // 已失败的不执行下一步
      list.forEach(el => {
        el.inProgress = true
        el.processStatus = 1 // 正在发货
      })

      this._disatchManager.dispatchWaybill(list)
    },
    onPrintClick() {
      this.taskType = 'PRINT'
      let list = this.datasource.filter(item => item.processStatus != -1) // 已失败的不执行下一步
      list.forEach(el => {
        el.inProgress = true
        el.processStatus = 1 // 正在发货
      })

      // 获取打印报文，成功获取后再打印..
      let printObjectList = []
      list.forEach(item => {
        item.requestID = `${item.orderId}-${item.packageNum}`
        printObjectList.push(item)
      })
      this._printManager.print(this.printerName, false, printObjectList)
    },
    async onPreDispatchClick() {
      this.taskType = 'PRE_DISPATCH'
      let list = this.datasource.filter(item => item.processStatus != -1) // 已失败的不执行下一步
      list.forEach(el => {
        el.inProgress = true
        el.processStatus = 1 // 正在预发货
      })

      let pList = list.map(item => ({ orderId: item.orderId, packageNum: item.packageNum }))
      let res = await updatePreDispatch(pList)

      if (res.code == 200) {
        res.data.resultList?.forEach(item => {
          let arInfo = item.businessNo.split('-')

          let matchItem = this.datasource.find(el => el.orderId == arInfo[0] && el.packageNum == arInfo[1])
          if (matchItem) {
            delete matchItem.inProgress
            if (item.success) {
              matchItem.isPreDispatch = true
              matchItem.processStatus = 2 // 预发货完成
            } else {
              matchItem.processStatus = -1 // 预发货失败
              matchItem.errorMsg = item.message
            }

          }
        })

        this.taskPhase = null // 无下一阶段任务，准备退出
      } else {
        this.$message.error(res.msg || '更新失败')
      }
    },
    onFinishHandler() {
      this.$emit("close") // 完成
    },
    onPrintTest() {
      /*
        根据视频号平台的模板报文解析后，按照其格式重新定制自己的打印格式，
        特别注意: 经过测试 options 字段不能为空，否则打印组件直接空白
        今晚回家打印测试结果：
       */
      let printData = {
        "printData": {
          "waybillId": "S61192092099001"
        },
        "template": {
          "options": [ // 必须，否则空白
          ],
          "templateUrl": "http://localhost:3000/wx_print_tpl.html",
        }
      }
      // 根据原有的基础，生成完成的html 并将html 插入模板中 printData html 中
      let obj = {
        "command": "print", "version": "2.0", "printer": this.printerName, "requestID": "228E2473F-C1EF-4211-AFD4-E8E7172A3A06}",
        "taskList": [{
          "taskID": "589603=S61192092099001",
          "printInfo": Base64.encode(encodeURIComponent(JSON.stringify(printData)))
        }]
      }
      useWeixinPrintServiceStore().send(JSON.stringify(obj))
    },
  }
}
</script>
