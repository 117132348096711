<!-- 
  门店分组显示，复选，显示已勾选数量

  注意：对象命名须 通过插槽 #label的方式，显示自定义名称 shopName, 注意数组的处理（不采用插槽，显示的属性名称需固定为label）
  
  -->
<template>
  <div class="nt-custom display-flex" >
    <el-select :teleported="false" popper-class="nt-custom-select"  v-model="value" v-bind="$attrs" collapse-tags-tooltip collapse-tags value-key="shopId"
     @change="onSelectChange">
      <template #header v-if="$attrs.multiple=='' || $attrs.multiple">
        <div class="display-flex space-between nt-width-100">
          <el-checkbox size="small" v-model="checkAll" @change="onCheckAllChange">全选({{(this.value?.length||0)}}/{{shopList.length}})</el-checkbox>
          <el-button link size="small"  type="primary" @click="onRevertClick">反选</el-button>
        </div>
      </template>
      <template #label v-if="$attrs.multiple=='' || $attrs.multiple">{{ value.length>0? value[0].shopName:null }}
      </template>
      <template #label v-else>{{value.shopName }}</template>
      
      <el-option-group
        v-for="item in platformList"
        :key="item.platform"
        :label="formatPlatformName(item.platform)"
      >
        <el-option style="width:300px;"
          v-for="shop in item.children"
          :key="shop.shopId"
          :label="shop.shopName"
          :value="shop"
        />
      </el-option-group>
    </el-select>
  </div>
</template>

<script>

  import {LoginUserStore} from '@/piana/login_user_service'
  export default {
    data () {
      return {
        value: null,
        checkAll:false,
        shopList: LoginUserStore().shopList,
        platformInfo: LoginUserStore().platformInfo
      }
    },
    emits:['defaultShop'],
    props:{
      modelValue:{
      },
      showDefault:{
        type:Boolean,
        default:true
      }
    },
    computed:{
      platformList:function(){
        let platformInfo = {}
        this.shopList.forEach(shop=>{
          let obj = platformInfo[shop.shopPlatform]
          if(obj){
            obj.children.push(shop)
          } else {
            platformInfo[shop.shopPlatform] = {platform: shop.shopPlatform,children:[shop]}
          }
        })
        return Object.values(platformInfo)
      },
      defaultShop:function(){
        if(this.showDefault){
          let userStore = LoginUserStore()
          let shop = {shopId:userStore.shopId,shopName:userStore.shopName,shopPlatform:userStore.platform,shopTenantId:userStore.tenantId }
          return this.$attrs.multiple? [shop]:shop
        }
        return null
      }
    },
    watch:{
      modelValue:function(){
        this.value = this.modelValue
      },
      value:function(){
        this.checkAll = this.value?.length == this.shopList?.length
      },
    },
    created(){
      if(!this.modelValue && this.showDefault && this.defaultShop){ // 无绑定数据，且在初始化时存在默认店铺，触发时间通知
        this.$emit('defaultShop', (this.$attrs.multiple=='' || this.$attrs.multiple)? [this.defaultShop]: this.defaultShop)
      }
    },
    methods:{
      onSelectChange(){
        this.$emit('update:modelValue',this.value)
      },
      formatPlatformName(platform){
        return this.platformInfo[platform]||platform
      },
      onCheckAllChange(){//全选
        if(this.checkAll){
          this.value = this.shopList
        } else{
          this.value = []
        }
      },
      onRevertClick(){ //反选
        let checkedIds = this.value.map(el=>el.shopId) // 已选择
        this.value =this.shopList.filter(el=> !checkedIds.includes(el.shopId))
      }
    }
}
</script>

<style lang="less" scoped>
  :deep(.nt-custom-select){
    .el-select-dropdown__header{
      padding:2px 6px;
    }
    .el-select-group__title{
      line-height: 16px;
      padding-left: 8px;
      font-weight:bold;
      color:#666;
    }
    &.el-popper{
      margin-top:-12px;
    }
    .el-popper__arrow{
      display:none;
    }
  }
</style>