<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting" :bill-type="billType">
          <template #title >采购订单({{ formModel.billNo }})</template>
          <template #status v-if="formModel.status==1" >
            <el-tag v-if="formModel.isClosed" type="info" round>已关闭</el-tag>
            <template v-else>
              <el-tag v-if="formModel.executeStatus==0" type="warning" round>未执行</el-tag>
              <el-tag v-else-if="formModel.executeStatus==1" type="warning" round>部分执行</el-tag>
              <el-tag v-else-if="formModel.executeStatus==2" type="success" round>已执行</el-tag>
            </template>
            
          </template>
          <template #extra>
            <!-- 审核状态，且非关闭，系统完成关闭+ 人工关闭时，允许手动关闭 -->
            <el-button class="el-menu-item"  @click="onCloseClick" v-if="formModel.id && formModel.status == '1' && !['close','finish'].includes(executeStatus)" >关闭</el-button>
            <el-button class="el-menu-item" @click="jumpToPage('/purchase_order_list','采购订单列表')" >单据列表</el-button>
            <!-- 入库时直接进入采购入库单，并自动填充数据 -->
            <el-dropdown split-button @command="onCommandClick" @click="onPurchaseInClick" v-if="formModel.status==1">入库
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="PURCHASE_IN_HISTORY">入库记录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <!-- 直接生成付款单-预付款 -->
            <el-dropdown split-button @command="onCommandClick" @click="onPaymentClick" v-if="formModel.status==1">预付款
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :disabled="!formModel.depositTotal" command="PAYMENT_HISTORY">付款记录</el-dropdown-item>
                  <el-dropdown-item :disabled="!formModel.depositTotal" command="PAYMENT_REFUND">退款</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            
            <!-- <el-dropdown split-button @command="onCommandClick" @click="onPurchaseInClick" v-if="formModel.status==1">入库
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="PURCHASE_IN_HISTORY">入库记录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            < !-- 直接生成付款单-预付款 -- >
            <el-dropdown split-button @command="onCommandClick" @click="onPaymentClick" v-if="formModel.status==1">预付款
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :disabled="!formModel.depositTotal" command="PAYMENT_HISTORY">付款记录</el-dropdown-item>
                  <el-dropdown-item :disabled="!formModel.depositTotal" command="PAYMENT_REFUND">退款</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown> -->
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row class="form-row" gutter="20" >
          <el-col :span="6">
            <nt-form-item prop="companyId" label="供应商" :rules="formValidator('required', '选择供应商')">
              <nt-dropdown-grid configKey="supplier"  v-model="formModel.companyId" v-model:text="formModel.companyName"
                :readonly="viewOnly" placeholder="请选择供应商" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="计划到货时间">
              <el-date-picker v-model="formModel.planTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="deptId" label="部门">
              <nt-dropdown-grid configKey="dept" v-model="formModel.deptId" style="width:100%;"
                v-model:text="formModel.deptName" :readonly="viewOnly" placeholder="请选择部门" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="remark" label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.remark" :readonly="viewOnly" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end" v-if="formModel.depositTotal">
          <el-space>
            <span>已预付：{{ formModel.depositTotal }}</span>
            <span>已冲销：{{ formModel.settleTotal||0 }}</span>
          </el-space>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.tradeItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="商品" width="180" prop="goodsName" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <span v-if="viewOnly || scope.row.linkId">{{ scope.row.goods.goodsName }}</span>
              <nt-form-item   label-width="0" :prop="`tradeItemList[${scope.$index}].goodsId`" v-else
                :rules="formValidator('required', '请选择商品')">
                <nt-dropdown-grid key="goods" configKey="goods" v-model:text="scope.row.goods.goodsName"
                :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
                @choose="(rows) => onRowGoodsChange([rows], scope.row, scope.$index)"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单位" width="110" prop="unitName">
            <template #default="scope">
              <span v-key="`'txt_unit_'${scope.$index}`" v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.unitName }}</span>
              <nt-form-item   label-width="0" :prop="`tradeItemList[${scope.$index}].unitId`" v-else
                :rules="formValidator('required', '请选择计量单位')">
                <nt-dropdown-grid key="goodsUnit" configKey="goodsUnit" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.unitId" v-model:text="scope.row.unitName"
                :disabled="viewOnly" placeholder="计量单位" :searchable="false"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="SKU" width="180" prop="sku">
            <template #default="scope">
              <span v-key="`'txt_sku_'${scope.$index}`" v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.sku }}</span>
              <nt-form-item  label-width="0" :prop="`tradeItemList[${scope.$index}].goodsSkuId`" v-else>
                <nt-dropdown-grid key="goodsSku" configKey="goodsSku" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.goodsSkuId" v-model:text="scope.row.sku"
                :disabled="viewOnly" placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="赠品" prop="isGift" width="60" align="center">
            <template #default="scope">
              <nt-form-item  label-width="0" :prop="`tradeItemList[${scope.$index}].isGift`" v-if="!viewOnly">
                <el-checkbox v-model="scope.row.isGift" :true-value ="1" :false-value ="0" />
              </nt-form-item>
              <span v-else>{{ scope.row.isGift? '是':'否' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stockQuantity" width="80">
          </el-table-column>
          <el-table-column label="价格" width="80" prop="price" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item  label-width="0" :prop="`tradeItemList[${scope.$index}].price`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入价格')">
                <el-input v-model="scope.row.price" />
              </nt-form-item>
              <span v-else>{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="80" prop="quantity" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item  label-width="0" v-model="scope.row.quantity" v-if="!viewOnly" :prop="`tradeItemList[${scope.$index}].quantity`"
                :rules="formValidator('required', '请输入数量')">
                <el-input v-model="scope.row.quantity" />
              </nt-form-item>
              <span v-else>{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已执行" width="80" class-name="no-padding" prop="finishQuantity" v-if="formModel.status==1">
            <template #default="scope">
              <span>{{ scope.row.finishQuantity }}</span>
              <el-tag type="danger" class="right-top scale-8" v-if="!scope.row.finishQuantity" size="small">未执行</el-tag>
              <el-tag type="warning" class="right-top scale-8" v-else-if="scope.row.finishQuantity<scope.row.quantity" size="small">部分执行</el-tag>
              <el-tag type="success" class="right-top scale-8" v-else-if="scope.row.finishQuantity>= scope.row.quantity" size="small">已执行</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="税率" width="80" prop="taxRate" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].taxRate`" v-if="!viewOnly">
                <el-input v-model="scope.row.taxRate" />
              </nt-form-item>
              <!-- <el-input v-if="!viewOnly"  v-model="scope.row.taxRate" @change="e=>onRowInputChange(val,scope.row,'taxRate')"/> -->
              <span v-else>{{ scope.row.taxRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税价合计" width="90" prop="totalWithTax">
          </el-table-column>
          <el-table-column label="备注" prop="remark" class-name="editor">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <!-- @change="e=>onRowInputChange(val,scope.row,'remark')" -->
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-drawer
    v-model="accountItemVisible" destroyOnClose size="65%"
    class="nt-inner-drawer"
    :with-header="false"
    :z-index="99"
    @close="accountItemVisible=false">
    <account-item-list  @cancel="accountItemVisible =false"  billType="PURCHASE_ORDER" :billId="formModel.id" v-if="accountItemVisible"></account-item-list>
    <i class="nt-drawer-close" @click="accountItemVisible = false">关闭</i>
  </el-drawer>
  <el-drawer
    v-model="purcahseInHistoryVisible" destroyOnClose size="65%"
    class="nt-inner-drawer"
    :with-header="false"
    :z-index="99"
    @close="purcahseInHistoryVisible=false">
    <trade-head-list  @cancel="purcahseInHistoryVisible =false"  billType="PURCHASE_ORDER" :billId="formModel.id" v-if="purcahseInHistoryVisible"></trade-head-list>
    <i class="nt-drawer-close" @click="purcahseInHistoryVisible = false">关闭</i>
  </el-drawer>
  <el-dialog title="打印预览" v-model="printPreviewVisible"  :destroyOnClose="true" width="85%" :close-on-click-modal="false"
    @close="printPreviewVisible = false" class="preview-print" append-to-body lock-scroll>
    <preview-dispatch :printObject="this.formModel" :templateId="_templateId"></preview-dispatch>
  </el-dialog>
  <el-dialog title="预付款退款" v-model="refundVisible" class="nt-resource-dialog" :destroyOnClose="true" :close-on-click-modal="false"
    @close="refundVisible = false" >
    <order-refund billType="PURCHASE_ORDER" :order-id="formModel.id" @cancel="refundVisible=false" @success="refundVisible=false" ></order-refund>
  </el-dialog>
  
</template>
<script>
  import NtBillLayout from '@/views/layout/nt_bill_layout'
  import { closeTradeHead } from '@/service/trade_head'
  // import { createBillNo } from '@/service/resource/general'
  import { DataBillMixin } from '@/utils/data_bill_mixin'
  import billSelector from '@/views/bill/selector/LinkBillList'
  // 付款历史
  import AccountItemList from '@/views/financial/selector/account_item_list'
  import orderRefund from '@/views/financial/refund'
  // 入库记录
  import TradeHeadList from '@/views/bill/selector/TradeHeadList'

  import NtFormItem from '@/views/plugin/nt_form_item'
  
  import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
  import PreviewDispatch from '@/views/preview/dispatch'
  import { EnumEntityType } from '@/utils/enum_utils'
  export default {
    name: "PurchaseOrderModal",
    mixins: [DataBillMixin],
    components: {
      billSelector,
      AccountItemList,
      TradeHeadList,
      orderRefund,
      NtFormItem,
      NtBillToolbar,
      NtBillLayout,
      PreviewDispatch
    },
    data () {
      return {
        accountItemVisible:false,
        purcahseInHistoryVisible: false,
        printPreviewVisible: false,
        refundVisible:false,
        billType: 'PURCHASE_ORDER',
        entityType:EnumEntityType.TRADE_HEAD  // 浏览特定位置单据时使用，bill_mixin
      }
    },
    props:{
      entityId:{
        type: String
      }
    },
    watch:{
      entityId: function(){
        this.loadEntity()
      },
    },
    computed:{
      executeStatus:function(){
        // 订单执行状态
        if(this.formModel.isClosed){
          return 'close' // 手动关闭订单，不再引用
        } 
        let info={
          '0': 'none',
          '1': 'part',// 部分执行
          '2': 'finish'
        }
        return info[this.formModel.executeStatus]
      }
    },
    
    methods: {
      async _initialize(){
      },
      async _createNewEntity() {
        // let billNo = await this.createBillNo('CGDD')
        return {
          data: {
            billNo: '',
            tradeItemList: [{goods:{}}],
            discountTotal: 0,
            otherTotal: 0,
            total: 0,
            billTotal: 0,
            companyId: '',
            companyName: '',
            linkId: '',
            linkNo: '',
            billTime: this.getDateTime()
          }
        }
      },
      _printPriview(templateId){ // 打印预览
        console.log('fking id ', templateId)
        this._templateId= templateId
        this.printPreviewVisible = true
      },
      async _loadEntity(params){
        this.formModel.tradeItemList = []
        if (!this.entityId) {
          this.fileList = []
          return this._createNewEntity()
          
        } else {
          let res = await this.getEntity(this.entityType, {id: this.entityId})
          return res
        }
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        if(this.formModel.tradeItemList.length ==0){
          this.$message.error(`单据明细不能为空`)
          return false
        }
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,billType: this.billType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        
        if(this.fileList && this.fileList.length > 0) {
          postData.fileName = this.fileList
        }
        return this.saveEntity(this.entityType, postData)
      },

      _loadFinished(){
        this.$refs.frmEdit?.clearValidate()
      },
      
      onFormChangeHandler(){
        // 根据当前编辑信息，重新计算：实付金额
        let total = 0
        this.formModel.tradeItemList.forEach(el=>{
          let itemTotal = (el.quantity||0)*(el.price||0)
          let taxTotal = (el.quantity||0)*(el.price||0)*(el.taxRate||0)
          el.totalWithTax = itemTotal+ taxTotal
          total += el.totalWithTax
        })

        this.formModel.total = total
        this.formModel.billTotal = total - (this.formModel.discountTotal||0) + (this.formModel.otherTotal||0)
        this.$forceUpdate()
      },

      onRowGoodsChange(rows,record,index){
        
        if(!rows || rows.length===0){
          return
        }
        let getAddItem = row => {
          return {
            goodsId: row.id,
            unitId:row.unitId,
            unitName: row.unitName,
            goods:row,
            quantity: 1,
            price: row.purchaseDecimal, // TODO  采购价格计算逻辑
            taxRate: 0,
            taxMoney: 0,
            stockQuantity:0,
            totalWithTax: row.purchaseDecimal
          }
        }
        
        // 根据id判断是否已经添加
        rows.forEach(async (el,idx)=>{
          
          let matchItem = this.formModel.tradeItemList.find(item=>item.goodsSkuId == el.id)
          if(!matchItem){
            let newItem = getAddItem(el)
            // newItem.key = "__"+(new Date()).getTime() + "_"+ idx
            if(idx==0){// 第一个选择的商品直接填充当前行
              let current = this.formModel.tradeItemList[index]
              newItem.quantity = current.quantity || 1
              newItem.remark = current.remark
              newItem.taxRate = current.taxRate
              
              this.formModel.tradeItemList[index] = newItem // 替换地址所指向位置，内存地址
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(this.formModel.tradeItemList[index]) // 注意：直接采用newItem 作为入参，ui 未刷新
              // TODO  根据往来单位获取最近采购价
            } else {
              this.formModel.tradeItemList.push(newItem)
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(newItem)
              // TODO  根据往来单位获取最近采购价
            }
            
          }
        })
        
        this.$forceUpdate()
      },
      
      onRowDeleteClick(rowIndex){
        this.formModel.tradeItemList.splice(rowIndex,1)
      },
      
      onAddLineClick() {
        this.formModel.tradeItemList.push({ goods:{} })
      },
      async _copyBill() { // 复制单据
        // let billNo = await this.createBillNo('CGDD')
        // 引用单据时，清空明细关联信息
        this.formModel.tradeItemList?.forEach(item=>{
          item.linkType = null
          item.linkNo = null
          item.linkId = null
        })
        return {
          id: '',
          companyId: '',
          companyName: '',
          status: null,
          linkId: '',
          linkType:'',
          linkNo: '',
          billNo: '',
          billTime: this.getDateTime()
        }
      },

      async onCloseClick(){ // 关闭订单
        let res = await closeTradeHead({id:this.formModel.id})
        if(res.code == 200){
          this.formModel.closeFlag |= 4  // 更新状态
          this.$message.success("操作成功")
        } else{
          this.$message.error(res.msg || res.message || res.data || '操作失败')
        }

      },

      onPurchaseInClick(){ // 生成采购入库单
        if(this.formModel.isClosed){
          this.$message.error("当前订单已关闭，不允许继续操作入库")
          return
        }
        if(this.formModel.executeStatus == 2){
          this.$message.error("当前订单已执行完毕")
          return
        }
        this.jumpToPage('/purchase_in', '采购入库',{id:this.formModel.id,billType:this.billType})
      },

      onPaymentClick(){ // 生成付款单-预付款  参数refBill 通过meta 传递
        this.jumpToPage('/financial/pay', '付款单',{id:this.formModel.id,billType:this.billType,bizType:'FINANCIAL_PAY_PREPAY'})
      },

      onCommandClick(command){
        if(command == 'PURCHASE_IN_HISTORY'){ // 采购入库记录
          this.purcahseInHistoryVisible = true
        } else if(command == 'PAYMENT_HISTORY'){ // 付款历史
          this.accountItemVisible = true
        } else if(command == 'PAYMENT_REFUND'){ // 预付款退款
          this.refundVisible = true
        }
      },
      
      getSummaries({columns,data}){
        let sumResult = []
        columns.forEach((column, index) => {
          if (index <2 ) {
            sumResult[2] = '合计'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => Number.isNaN(value))) {
            sumResult[index] = `${values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!Number.isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)}`
          } else {
            // sumResult[index] = 'N/A'
          }
        })
        return sumResult
      }
    }
  }
</script>