<template>
  <el-container class="nt-bg-page nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <!-- 单据工具栏 -->
    <el-header class="no-padding no-margin height-auto">
      <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
        :isSubmitting="isSubmitting">
        <template #title >线上零售退货单</template>
        <template #extra>
          <el-button  class="el-menu-item" @click="jumpToPage('/bill/sale_back_online_list','线上零售退货单列表')" >单据列表</el-button>
        </template>
      </nt-bill-toolbar>
    </el-header>
    <el-main class="padding">
      <el-form @change="onFormChangeHandler"  :model="formModel" ref="frmEdit" label-width="90" class="nt-height-100" >
        <el-container class="no-padding nt-height-100">
          <el-header class="no-margin no-padding nt-height">
            <el-row class="form-row" >
              <el-col :span="6">
                <nt-form-item prop="shopId" label="门店" :rules="formValidator('required', '选择门店')">
                  <nt-dropdown-grid configKey="shop" v-model="formModel.shopId" v-model:text="formModel.shopName"
                    :disabled="viewOnly" placeholder="请选择门店" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
                  </nt-form-item>
              </el-col>
              <el-col :span="6">
                <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
                  <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%" />
                </nt-form-item>
              </el-col>
              <el-col :span="6">
                <nt-form-item prop="billNo" label="单据编号">
                  <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
                </nt-form-item>
              </el-col>
              <el-col :span="6">
                <nt-form-item prop="accountId" label="收款账户" :rules="formValidator('required', '请选择收款账户')">
                  <nt-dropdown-grid configKey="account" v-model="formModel.accountId" v-model:text="formModel.accountName"
                    :disabled="viewOnly" placeholder="请选择收款账户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item prop="remark" label="备注">
                  <el-input placeholder="请输入备注" v-model="formModel.remark" :readonly="viewOnly"/>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <nt-form-item label="付款优惠" datel-step="6" datel-title="付款优惠">
                  <el-input placeholder="请输入付款优惠" v-model.number="formModel.discountTotal" :readonly="viewOnly" />
                </nt-form-item>
              </el-col>
              <el-col :span="6">
                <nt-form-item label="优惠后金额" datel-step="7" datel-title="优惠后金额">
                  <el-input placeholder="请输入优惠后金额" v-model="formModel.billTotal" readonly />
                </nt-form-item>
              </el-col>
            </el-row>
          </el-header>
          <el-main class="no-padding margin-top-4">
            <el-table class="nt-editable-table" :data="formModel.onlineSaleItemList" border height="100%" show-summary :summary-method="getSummaries">
              <el-table-column label="#" width="35" type="index">
              </el-table-column>
              <el-table-column width="45"  v-if="!viewOnly" align="center">
                <template #header v-if="!viewOnly">
                  <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                    <Plus />
                  </el-icon>
                </template>
                <template #default="scope">
                  <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                    <template #reference>
                      <el-icon>
                        <Delete />
                      </el-icon>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
              <el-table-column label="仓库" width="160" prop="storeName" :class-name="!viewOnly ? 'editor' : ''">
                <template #default="scope">
                  <span v-if="viewOnly">{{ scope.row.storeName }}</span>
                  <nt-form-item label-width="0" :prop="`onlineSaleItemList[${scope.$index}].storeId`" v-else
                    :rules="formValidator('required', '请选择仓库')">
                    <el-select placeholder="选择仓库" v-model="scope.row.storeId" @change="onStoreChangeHandler(scope.row)">
                      <el-option v-for="(item, index) in storeList" :key="index" :label="item.storeName" :value="item.id">
                        {{ item.storeName }}
                      </el-option>
                    </el-select>
                  </nt-form-item>
                </template>
              </el-table-column>
              <el-table-column label="商品" width="200" prop="goodsName" :class-name="!viewOnly ? 'editor' : ''">
                <template #default="scope">
                  <span v-if="viewOnly || scope.row.linkId">{{ scope.row.goodsName }}</span>
                  <nt-form-item  label-width="0" :prop="`onlineSaleItemList[${scope.$index}].goodsSkuId`" v-else
                    :rules="formValidator('required', '请选择商品')">
                    <nt-dropdown-grid key="goods" configKey="goods" v-model:text="scope.row.goodsName"
                    :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
                    @choose="(rows) => onRowGoodsChange([rows], scope.row, scope.$index)"
                    ></nt-dropdown-grid>
                  </nt-form-item>
                </template>
              </el-table-column>
              <el-table-column label="SKU" width="100" prop="sku">
              </el-table-column>
              <el-table-column label="单位" width="80" prop="unitName">
                <template #default="scope">
                  <span>{{ scope.row.unitName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="库存" prop="stockQuantity" width="80">
              </el-table-column>
              <el-table-column label="价格" width="80" prop="price" :class-name="!viewOnly ? 'editor' : ''">
                <template #default="scope">
                  <nt-form-item  label-width="0" :prop="`onlineSaleItemList[${scope.$index}].price`" v-if="!viewOnly"
                    :rules="formValidator('required', '请输入价格')">
                    <el-input v-model="scope.row.price" />
                  </nt-form-item>
                  <span v-else>{{ scope.row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="80" prop="quantity" :class-name="!viewOnly ? 'editor' : ''">
                <template #default="scope">
                  <nt-form-item  label-width="0" v-model="scope.row.quantity" v-if="!viewOnly" :prop="`onlineSaleItemList[${scope.$index}].quantity`"
                    :rules="formValidator('required', '请输入数量')">
                    <el-input v-model="scope.row.quantity" />
                  </nt-form-item>
                  <span v-else>{{ scope.row.quantity }}</span>
                </template>
              </el-table-column>
              <el-table-column label="税率(%)" width="80" prop="taxRate" :class-name="!viewOnly ? 'editor' : ''">
                <template #default="scope">
                  <nt-form-item label-width="0" :prop="`onlineSaleItemList[${scope.$index}].taxRate`" v-if="!viewOnly">
                    <el-input v-model="scope.row.taxRate" />
                  </nt-form-item>
                  <!-- <el-input v-if="!viewOnly"  v-model="scope.row.taxRate" @change="e=>onRowInputChange(val,scope.row,'taxRate')"/> -->
                  <span v-else>{{ scope.row.taxRate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="税价合计" width="90" prop="totalWithTax">
              </el-table-column>
              <el-table-column label="备注" prop="remark" class-name="editor">
                <template #default="scope">
                  <nt-form-item label-width="0" :prop="`onlineSaleItemList[${scope.$index}].remark`" v-if="!viewOnly">
                    <el-input v-model="scope.row.remark" />
                  </nt-form-item>
                  <span v-else>{{ scope.row.remark }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-main>
        </el-container>
      </el-form>
    </el-main>
    
  </el-container>
</template>
<script>

  import { DataBillMixin } from '@/utils/data_bill_mixin'
  import { getNowFormatDateTime } from '@/utils/util'
  import NtFormItem from '@/views/plugin/nt_form_item'
  
  import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
  import { EnumEntityType } from '@/utils/enum_utils'
  export default {
    name: "OnlineSaleOut",
    mixins: [DataBillMixin],
    components: {
      NtFormItem,
      NtBillToolbar
    },
    data () {
      return {
        billType: 'SALE_BACK_ONLINE',
        entityType:EnumEntityType.ONLINE_SALE_HEAD  // 浏览特定位置单据时使用，bill_mixin
      }
    },
    props:{
      entityId:{
        type: String
      }
    },
    watch:{
      entityId: function(){
        this.loadEntity()
      },
      
    },
    methods: {
      async _initialize(){
        this.initStore()
      },
      async _createNewEntity() {
        let billNo = await this.createBillNo('LSTH')
        return {
          data: {
            billNo: billNo,
            onlineSaleItemList: [{ key: "__" + (new Date()).getTime() }],
            discountTotal: 0,
            otherTotal: 0,
            total: 0,
            billTotal: 0,
            companyId: '',
            companyName: '',
            linkId: '',
            linkNo: '',
            billTime: getNowFormatDateTime()
          }
        }
      },
      
      async _loadEntity(params){
        if (!this.entityId) {
          this.fileList = []
          return this._createNewEntity()
        } else {
          let res = await this.getEntity(this.entityType, {id: this.entityId})
          return res
        }
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        if(this.formModel.onlineSaleItemList.length ==0){
          this.$message.error(`单据明细不能为空`)
          return false
        }
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,billType: this.billType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        
        postData.discount = (postData.discount||0)*100
        postData.discountTotal = (postData.discountTotal||0)*100
        postData.otherTotal = (postData.otherTotal||0)*100
        postData.onlineSaleItemList = postData.onlineSaleItemList ||[]
        postData.onlineSaleItemList.forEach(el=>{
          el.price = (el.price || 0)*100
          el.totalWithTax = (el.totalWithTax || 0)*100
        })

        if(this.fileList && this.fileList.length > 0) {
          postData.fileName = this.fileList
        }
        return this.saveEntity(this.entityType, postData)
      },

      _loadFinished(){
        this.formModel.total = (this.formModel.total||0)/100
        this.formModel.billTotal = (this.formModel.billTotal||0)/100
        this.formModel.discount = (this.formModel.discount||0)/100
        this.formModel.discountTotal = (this.formModel.discountTotal||0)/100
        this.formModel.otherTotal = (this.formModel.otherTotal||0)/100
        this.formModel.onlineSaleItemList = this.formModel.onlineSaleItemList ||[]
        this.formModel.onlineSaleItemList.forEach(el=>{
          el.price = (el.price || 0)/100
          el.totalWithTax = (el.totalWithTax || 0)/100
          el.key=el.id
          // 计算库存
          this.refreshStockInfo(el)
        })
        this.$refs.frmEdit?.clearValidate()
      },
     
      onFormChangeHandler(){
        // 根据当前编辑信息，重新计算：实付金额
        let total = 0
        this.formModel.onlineSaleItemList.forEach(el=>{
          let itemTotal = (el.quantity||0)*(el.price||0)
          let taxTotal = (el.quantity||0)*(el.price||0)*(el.taxRate||0)/100.00
          el.totalWithTax = itemTotal+ taxTotal
          total += el.totalWithTax
        })

        this.formModel.total = total
        this.formModel.billTotal = total - (this.formModel.discountTotal||0) + (this.formModel.otherTotal||0)
        this.$forceUpdate()
      },

      onRowGoodsChange(rows,record,index){
        if(!rows || rows.length===0){
          return
        }
        let getAddItem = row => {
          return {
            goodsId: row.goodsId,
            goodsSkuId: row.id,
            sku: row.sku,
            unitName: row.unitName,
            goodsName: row.goodsName,
            quantity: 1,
            price: row.purchaseDecimal,
            taxRate: 0,
            taxMoney: 0,
            totalWithTax: row.purchaseDecimal
          }
        }
        // 根据id判断是否已经添加
        rows.forEach(async(el,idx)=>{
          let matchItem = this.formModel.onlineSaleItemList.find(item=>item.goodsSkuId == el.id)
          if(!matchItem){
            let newItem = getAddItem(el)
            newItem.key = "__"+(new Date()).getTime() + "_"+ idx
            if(idx==0){// 第一个选择的商品直接填充当前行
              let current = this.formModel.onlineSaleItemList[index]
              newItem.quantity = current.quantity || 1
              newItem.storeId = current.storeId
              newItem.remark = current.remark
              newItem.taxRate = current.taxRate
              this.formModel.onlineSaleItemList[index] = newItem // 替换地址所指向位置，内存地址
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(this.formModel.onlineSaleItemList[index]) // 注意：直接采用newItem 作为入参，ui 未刷新
            } else {
              this.formModel.onlineSaleItemList.push(newItem)
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(newItem)
            }
          }
        })
        this.$forceUpdate()
      },

      async onStoreChangeHandler(row){ // 切换仓库时，重新计算存货信息
        row.stockQuantity= await this.refreshStockInfo(row)
      },

      onRowDeleteClick(rowIndex) {
      this.formModel.onlineSaleItemList.splice(rowIndex, 1)
    },

      onAddLineClick() {
        this.formModel.onlineSaleItemList.push({ goods:{} })
      },
      async _copyBill() { // 复制单据
        // let billNo = await this.createBillNo('XSLS')
        
        return {
          id: '',
          companyId: '',
          companyName: '',
          status: null,
          // billNo: billNo,
          billTime: this.getDateTime() // getNowFormatDateTime()
        }
      },

      
      getSummaries({columns,data}){
        let sumResult = []
        columns.forEach((column, index) => {
          if (index <2 ) {
            sumResult[2] = '合计'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => Number.isNaN(value))) {
            sumResult[index] = `${values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!Number.isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)}`
          } else {
            // sumResult[index] = 'N/A'
          }
        })
        return sumResult
      }
    }
  }
</script>