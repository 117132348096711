<!-- 编辑快捷查询方法，保存时，根据形成查询条件？ -->
<template>
  <el-dialog v-model="isVisible" destroyOnClose width="900px" title="快捷查询设置" :z-index="99" @close="onCloseHandler">
    <el-container style="height:500px;">
      <el-main class="no-padding">
        <el-container class="nt-height-100">
          <el-aside class=" padding-8 display-flex flex-column overflow-hidden nt-box-panel"
            style="background-color: #f0f2f7;" width="240px">
            <div class="nt-width-100 display-flex space-between" style="font-size:12px;font-weight:bold;">
              <span>已配置方案</span>
              <el-button type="primary" size="small" @click="onAddClick">新建</el-button>  
            </div>
            <div class="flex-auto nt-width-100 padding-4">
              <div :class="`nt-custom plan display-flex space-between ${plan.id==currentPlan.id? 'selected':''}`" 
              v-for="plan in planList" :key="plan.id" @click="onPlanClick(plan)">
                <span>{{plan.planName}}</span>
                <el-popconfirm title="确定删除吗?" @confirm="onDeleteClick(plan)" v-if="planList.length>1">
                  <template #reference>
                    <el-icon ><CloseBold /></el-icon>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </el-aside>
          <el-main class="margin-left padding-8 nt-bg-white nt-box-panel">
            <el-form model="currentPlan">
              <!-- <el-row justify="space-between" class="nt-width-100 margin-bottom">
                <div class="flex-auto">
                  <el-space>
                    <span>方案名称</span>
                    
                    
                    <el-button type="danger" size="small">重置</el-button>
                  </el-space>
                </div>
                <el-button type="primary" size="small" @click="onAddClick">新建查询方案</el-button>
              </el-row> -->
              <div class="display-flex flex-wrap">
                <!-- <el-input size="small" :key="index" v-for="(item,index) in (new Array(20))" style="flex-basis: calc(33.33% - 8px);">
                  <template #prepend>字段名称{{index}}</template>
                </el-input> -->
                <component style="width:calc(50% - 8px);" config :label="editor.label"
                  v-model="currentPlan.configJson[editor.queryKey]" :queryKey="editor.queryKey" :is="editor.cType"
                  v-for="editor in editors" v-bind="editor.props"></component>

              </div>
              <div class="align-center">
                <el-input v-model="currentPlan.planName" class="margin-right" style="width: 250px;"></el-input>
                <el-button type="primary"  @click="onSubmitClick">保存方案</el-button>
              </div>
            </el-form>
          </el-main>

        </el-container>
      </el-main>
      <el-footer class="height-auto no-padding no-margin align-center margin-top-8 align-right">
        <el-button @click="isVisible = false">关闭</el-button>
      </el-footer>
    </el-container>

  </el-dialog>
</template>

<script>
import { getQuickSearchPlanList, saveQuickSearchPlan, deleteQuickSearchPlan } from '@/service/resource/general'
import {LoginUserStore} from '@/piana/login_user_service'
import {
    NtSelect,
    NtSelectWithText,
    NtDateRange,
    NtTextRange,
    NtTextSearch,
    NtSelectShop,
    NtSelectFlag,
    NtDropdownSelector,
    NtSelectDateRangeQuick
} from './component'
import editors from './config/query_editor'
export default {
  name: "query_plan",
  data() {
    return {
      isVisible: false,
      editors: editors.filter(el=>el.isMain!=true), // 排除仅主面板使用
      planList:[],
      currentPlan: { configJson: {} } // 当前编辑方案
    }
  },
  components: {
    NtSelect, NtDateRange, NtTextRange, NtDropdownSelector,NtSelectShop,NtSelectDateRangeQuick
  },
  props: {

  },
  methods: {
    showDialog(planList) {
      this.planList = planList
      if(planList.length>0){
        this.currentPlan = planList[0]
      }
      this.isVisible = true
    },
    onPlanClick(plan){
      this.currentPlan = plan
    },
    onAddClick() { // 新建查询方案，根据系统配置值，生成queryForm 默认值
      this.currentPlan ={ planName:'新查询方案' + (this.planList.length+1), configJson: {}} // 合并默认条件
    },
    async onDeleteClick(plan){
      let res = await deleteQuickSearchPlan({id:plan.id})
      if (res.code === 200) {
        let matchIndex = this.planList.findIndex(el=>el.id==plan.id)
        if(matchIndex!=-1){
          this.planList.splice(matchIndex,1)
        }
        if(plan.id == this.currentPlan.id){ // 删除当前选中的，删除后，定位到第一条
          this.currentPlan = this.planList[0]
        }
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg || '保存失败')
      }
    },
    onCloseHandler(){
      this.isVisible = false
      this.$emit('success',this.planList)
    },
    async onSubmitClick() {
      // 合并currentPlan + configJson 提交保存  saveQuickSearchPlan

      let postData = JSON.parse(JSON.stringify(this.currentPlan)) // 深拷贝
      postData.configJson = JSON.stringify(postData.configJson)
      postData.queryType ='PRINT_ORDER'
      let res = await saveQuickSearchPlan(postData)
      if (res.code === 200) {
        this.currentPlan.id = res.data // 新建后记录id，避免重复创建
        let matchIndex = this.planList.findIndex(el=>el.id==this.currentPlan.id)
        if(matchIndex == -1){
          this.planList.push(this.currentPlan)
        }
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg || '保存失败')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.nt-custom {
  &.plan {
    background-color: #f5f5f5;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 8px;
    box-sizing: border-box;
    border: 1px solid transparent;

    &:hover {
      background-color: var(--el-color-primary-light-9);
      border: 1px solid var(--el-color-primary-light-7);
    }

    margin-bottom: 8px;
  }

  &.selected {
    background-color: var(--el-color-primary-light-9);
    border: 1px solid var(--el-color-primary-light-7);
  }
}</style>
