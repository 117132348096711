import { UserLayout, TabLayout, RouteView, BlankLayout } from '@/components/layouts'
import PrintLayout from '@/components/layouts/PrintLayout'
import PrintOrderList from '@/views/print_order'
import PickingSummary from '@/views/print_order/picking'
import GoodsExtra from '@/views/platform/goods/extra'
import Template from '@/views/waybill_template'
// import PickingTemplate from '@/views/template'
import PrintTool from '@/views/print_order/print_tool'
import DeliveryConfig from '@/views/print_order/delivery_config'
// import PickingRule from '@/views/print_order/picking_rule'
// import SenderAddress from '@/views/address'
import PrintHome from '@/views/print_order/print_home'
import PrintSetting from '@/views/print_order/print_setting'
import TemplateManagePage from '@/views/print_order/template'

/**
 * 走菜单，走权限控制
 * @type {[null,null]}
 */
// export const asyncRouterMap = [

//   {
//     path: '/',
//     name: 'dashboard',
//     component: TabLayout,
//     meta: { title: '首页' },
//     redirect: '/dashboard/analysis',
//     children: [

//     ]
//   },
//   {
//     path: '*', redirect: '/404', hidden: true
//   }
// ]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      }
    ]
  },
  {
    path: '/dashboard',
    component: TabLayout,
    redirect: '/dashboard/analysis',
    children: [
      {
        path: 'analysis',
        name: 'analysis',
        meta: { title: '首页' },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Analysis')
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  },
  {
    path: '/print',
    name: 'print',
    hidden: true,
    // redirect: '/print/home',
    component: PrintLayout,
    children: [
      {
        path: '',
        name: 'home',
        meta: { title: '系统首页' },
        component:PrintHome
      },
      {
        path: 'index',
        name: 'index',
        meta: { title: '快速打单' },
        component:PrintOrderList
      },
      {
        path: '/print/pick',
        name: 'pick',
        meta: { title: '备货单' },
        component:PickingSummary
      },
      {
        path: '/print/extra',
        name: 'goodsExtra',
        meta: { title: '商品管理' },
        component:GoodsExtra
      },
      {
        path: '/print/waybillTemplate',
        name: 'template',
        meta: { title: '面单模板',key:'WaybillTemplatePage' },
        component:TemplateManagePage
      },
      {
        path: '/print/pickingCodeTemplate',
        name: 'pickTemplate',
        meta: { title: '拣货单模板',key:'PickingCodeTemplatePage',templateType:'PICKUP_TEMPLATE'},
        component:TemplateManagePage
        // component: () => import(/* webpackChunkName: "fail" */ '@/views/template')
      },
      {
        path: '/print/pickTemplate',
        name: 'pickGoodsTemplate',
        meta: { title: '备货单模板',key:'PickTemplatePage',templateType:'PICK_GOODS_TEMPLATE' },
        component:TemplateManagePage
        // component: () => import(/* webpackChunkName: "fail" */ '@/views/template')
      },
      {
        path: '/print/dispatchTemplate',
        name: 'dispatchTemplate',
        meta: { title: '发货单模板',key:'DispatchTemplatePage',templateType:'DISPATCH_TEMPLATE' },
        component:TemplateManagePage
        // component: () => import(/* webpackChunkName: "fail" */ '@/views/template')
      },
      {
        path: '/print/preDispatch',
        name: 'preDispatch',
        meta: { title: '预发货',key:'PreDispatchPage' },
        component:PrintTool
      },
      {
        path: '/print/waybillHistory',
        name: 'waybillHistory',
        meta: { title: '底单查询',key:'WaybillHistoryPage' },
        component:PrintTool
      },
      {
        path: '/print/abnormalOrder',
        name: 'abnormalOrder',
        meta: { title: '异常订单',key:'AbnormalPage' },
        component:PrintTool
      },
      {
        path: '/print/sendHistory',
        name: 'sendHistory',
        meta: { title: '发货记录',key:'SendHistoryPage' },
        component:PrintTool
      },
      
      {
        path: '/print/scanSend',
        name: 'scanSend',
        meta: { title: '扫描发货',key:'ScanSendPage' },
        component:PrintTool
      },
      {
        path: '/print/scanPickCode',
        name: 'scanPickCode',
        meta: { title: '扫描拣货标签',key:'ScanPicingCodePage' },
        component:PrintTool
      },
      {
        path: '/print/importSend',
        name: 'importSend',
        meta: { title: '导入发货',key:'ScanPicingCodePage' },
        component:PrintTool
      },
      {
        path: '/print/deliveryConfig',
        name: 'deliveryConfig',
        meta: { title: '物流模板配置',key:'DeliveryTemplateConfigPage'  },
        component:PrintSetting
      },
      {
        path: '/print/pickingRule',
        name: 'pickingRule',
        meta: { title: '拣货规则',key:'PickingRulePage'  },
        component:PrintSetting
      },
      {
        path: '/print/senderAddress',
        name: 'senderAddress',
        meta: { title: '发货人',key:'SenderPage' },
        component:PrintSetting
      },
      {
        path: '/print/farArea',
        name: 'farArea',
        meta: { title: '偏远地区',key:'HighlightAreaPage'  },
        component:PrintSetting
      },
      // {
      //   path: '/print/farArea',
      //   name: 'farArea',
      //   meta: { title: '偏远地区' },
      //   component:PrintSetting
      // },
      {
        path: '/print/rejectArea',
        name: 'rejectArea',
        meta: { title: '不可达地区' ,key:'RejectAreaPage' },
        component:PrintSetting
      },
      {
        path: '/print/abnormalAddress',
        name: 'abnormalAddress',
        meta: { title: '异常地址',key:'AbnormalAddressPage' },
        component:PrintSetting
      },
      {
        path: '/print/binding',
        name: 'binding',
        meta: { title: '店铺绑定',key:'BindingShopPage' },
        component:PrintTool
      }
      
    ]
  },
  // {
  //   path: '/designer',
  //   name: 'designer',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: 'aa',
  //       name: 'aa',
  //       meta: { title: '发货单设计' },
  //       component: () => import(/* webpackChunkName: "dashboard" */ '@/views/designer/dispatch')
  //     }
  //   ]
  // },
  // {
  //   path: '/template',
  //   name: 'template',
  //   component: BlankLayout,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'templateList',
  //       meta: { title: '模版列表' },
  //       component: () => import(/* webpackChunkName: "dashboard" */ '@/views/template')
  //     }
  //   ]
  // },

]
