<template>
  <div class="nt-height-100 nt-bg-white padding">
    <el-tree ref="refTree" class="nt-height-100 nt-list-node" style="overflow-y: auto;"
      :data="datasource"
      default-expand-all
      nodeKey="id"
      :props="props"
      :expand-on-click-node="false"
      :indent="8"
      @node-click="onNodeClick"
    />
  </div>
  
</template>
<script >

import {getDictNodeListByParent} from '@/service/resource/general'
import { DataListMixin } from '@/utils/data_list_mixin.js'
export default {
  mixins: [DataListMixin],
  data(){
    return {
      datasource:[],
      props: {
        label: 'configValue',
        // isLeaf: 'isLeaf',
      }
    }
  },
  props:{
    parentKey:{
      type:String
    },
    showAll:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    _loadList(params){
      return  getDictNodeListByParent({parentKey: this.parentKey}); // 获取数据字典类型列表
    },
    _loadFinished(){
      // 数据加载结束后，默认选择一个
      if(this.showAll){ // 添加全部节点
        this.datasource.splice(0,0,{configValue:'全部',id:null})
      }

      if(this.datasource?.length>0){
        setTimeout(_=>{
          let firstNode = this.datasource[0]
          this.$refs.refTree?.setCurrentNode(firstNode)
          this.$emit('change',firstNode)
        },0)
      }
    },
    onNodeClick(node){
      this.$emit('change',node)
    },
    // async loadNode(node,resolve,reject){
    //   if(node.level===0) return resolve([])
    //   let res =  await this.getEntityList(this.EnumEntityType.PLATFORM_SHOP, {platform:node.data.configKey});
    //   res.data?.forEach(item => {
    //     item.configValue = item.shopName
    //     item.leaf = true
    //   })
    //   return resolve(res.data)
    // }
  }
}
</script>
