<template>
  <el-container class="nt-height-100 padding">
    <el-aside class="margin-right nt-box-panel padding-8 nt-bg-white" width="200px">
      <nt-platform-shop-tree @change="onShopNodeClick"></nt-platform-shop-tree>
    </el-aside>
    <el-main class="no-padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="height-auto padding-8">
          <div class="margin-bottom" style="color:red;">由于该表数据由电商平台同步过来，两边只认categoryId，每次同步主键id可能会发生变化</div>
      
        </el-header>
        <el-main class="no-padding ">
          <el-tree ref="treeRef" class="nt-height-100 nt-list-node padding-8" style="box-sizing: border-box;" :expand-on-click-node="false" :data="categoryData" @node-click="onCategoryClick" :props="{ label: 'categoryName' }" default-expand-all >
        <template #default="{ node, data }">
        <el-row type="flex" justify="space-between"   style="align-items:center;width:100%;">
          <span>{{ node.label }}</span>
          <div>
            <nt-dropdown-grid configKey="store" v-model="data.storeId" v-model:text="data.storeName" placeholder="请选择默认仓库" 
      :searchable="false" @choose="(store) => onRowStoreChange(data.categoryId,store.id)"></nt-dropdown-grid>
          </div>
        </el-row>
      </template>
      </el-tree>
        </el-main>
      </el-container>
      
    </el-main>

  </el-container>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import {getCategoryList} from '@/service/resource/platform_goods'
import ntPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
import { saveShopKv } from '@/service/resource/platform_shop'
export default {
  name: "PlatformGoodsCategory",
  mixins: [DataListMixin],
  components: {
    ntPlatformShopTree
  },
  data() {
    return {
      categoryData:[],
      selectedShopId:null,
      selectedCategoryId:null
    }
  },
  
  methods: {
    async loadCategoryTree(){
      let res = await getCategoryList({shopId:this.selectedShopId})
      let getChildren = function(categoryId,data){
        let children = data.filter(item=> item.parentId == categoryId )
        children.forEach(item=>{
          item.children = getChildren(item.categoryId,data)
        })
        return children
      }
      if(res.code == 200){
        // 根据返回结果，构建前端树， 最多三级
        this.categoryData = getChildren(null, res.data)
      }
    },
    onShopNodeClick(shop){
      this.selectedShopId = shop.id
      this.selectedCategoryId = null
      this.loadCategoryTree()
      this.loadList() // 加载商品列表---全部
    },

    onCategoryClick(node){
      // 加载当前分类下所有的商品
      this.selectedCategoryId = node.categoryId
      this.loadList()
    },

    async onRowStoreChange(categoryId,storeId){
      let res = await saveShopKv({shopId:this.selectedShopId,configType:'PLATFORM_CATEGORY_STORE_MAPPING', configKey:categoryId,configValue:storeId})
      if(res.code == 200){
        this.$message.success('保存成功')
      } else{
        this.$message.error(res.msg || '保存失败')
      }
    }

  }
}
</script>