<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom ${getHighlightClass()} display-flex ${config?'config':''}`" :style="{columnGap:'0px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <el-select v-model="value" v-bind="$attrs" :placeholder="placeholder"  clearable size="small" :style="{width:(width||140)+'px'}" @change="onSelectChange">
      <el-option v-for="item in deliveryList" :key="item.deliveryId" :label="item.deliveryName" :value="item.deliveryId"></el-option>
    </el-select>
  </div>
  
</template>

<script>
  import {EnumDelivery} from '@/utils/enum_utils'
  export default {
    data () {
      return {
        value: this.modelValue?.value,
        defaultValue: this.modelValue
      }
    },
    computed:{
      deliveryList:function(){
        return Object.keys(EnumDelivery).map(key=>{
          return {deliveryId:key,deliveryName:EnumDelivery[key]}
        })
      }
    },
    props:{
      modelValue:{},
      
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.value = this.modelValue?.value
      }
    },
    
    methods:{
      getHighlightClass(){
        return this.defaultValue?.value != this.value? 'highlight':''
      },
      onSelectChange(){
        let data = {
          value: this.value
        }
        this.$emit('update:modelValue', Object.assign({},this.defaultValue, data))
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    &.highlight{
      :deep(.el-select__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-select){
        flex:1;
        .el-select__wrapper{
          box-shadow: none;
          
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
  }
</style>