export const template_config = {
  label: '现存量-库存分布表',
  titleStyle: {
    alignment: { horizontal: 'center', vertical: 'center' },
    font: { bold: true, sz: 16 }, fill: { fgColor: { rgb: "E9E9E9" } }
  },
  headerStyle: {
    alignment: {
      horizontal: 'center',
      vertical: 'center'
    },
    font: { bold: true,color:{rgb:'ffffff'}},
    border: {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' }
    },
    fill: { fgColor: { rgb: "79bbff" } }
  },
  columns: [
    {
      label: '#',
      dataIndex: (_, index) => {
        return index + 1
      },// 索引行
      width: 45,
      textStyle: {
        alignment: { horizontal: 'center', vertical: 'center' },
        font: { bold: true, sz: 16 },
        fill: { fgColor: { rgb: "E9E9E9" } }
      }
    },
    {
      label: '商品名称',
      dataIndex: 'goodsName',// 索引行
      width: 220
    },
    {
      label: '规格',
      dataIndex: 'standard',// 索引行
      width: 140
    },
    {
      label: '主单位',
      dataIndex: 'unitName',// 索引行
      width: 100
    },
    {
      isDynamic:true // 注意 动态列占位
    },
    {
      label: '小计',
      colSpan: 2,
      columns: [
        {
          label: '现存量',
          dataIndex: 'child.baseQuantity_summary',// 索引行
          width: 80,
          textStyle: {
            alignment: { horizontal: 'right', vertical: 'center' },
            numFmt:'#.##'
          },
          
        },
        {
          label: '结存金额1',
          dataIndex: 'child.costTotal_summary',// 索引行
          width: 80,
          textStyle: {
            alignment: { horizontal: 'right', vertical: 'center' },
            numFmt:'#.##'
          },
        },
      ],

    }
  ]
}