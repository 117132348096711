
/*
    npm install vue@2 pinia  
    vue 2 pinia， 主要为了兼容，vue时使用pina
 */
import { defineStore } from 'pinia'
// import { ref, computed } from 'vue';

export const useDispatchDesignerStore = defineStore('dispatchDesigner', {
    state: _ => {
        const page = {
            header: [],
            body: {
                _t: 'table',
                columns: [
                    // {
                    //     title: '商品编码',
                    //     field: 'xx.goods.name',
                    //     isShow: true
                    // },
                    // {
                    //     title: '商品名称',
                    //     field: 'xx.goods.name',
                    //     isShow: true
                    // }

                ] // {title,text,var} 设置网格标题 数据源对应的字段
            },
            footer: []
        }
        const current = null
        return { current, page }
    }
    // getters:{
    //     getPage:
    // },
    // actions:{

    // }
})
