
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="height-auto margin-bottom no-padding" >
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom" >
        <el-form-item label="单据编号" class="no-margin-bottom" >
          <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="商品信息" >
          <el-input placeholder="请输入条码、名称、规格、型号、颜色、扩展信息" v-model="queryModel.keyword"></el-input>
        </el-form-item>
        <el-form-item label="单据日期"  >
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" 
          start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD" ></nt-date-range>
        </el-form-item>
        <el-form-item  >
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
          <a @click="handleToggleSearch" style="margin-left: 8px">
            {{ toggleSearchStatus ? '收起' : '展开' }}
            <el-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
          </a>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table
        border
        :data="datasource"
        height="100%"
        size="large"
        class="nt-bill-table-list"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="单据编码" width="140" prop="status">
          <template #default="scope">
            <span style="position: absolute;left:-5px;top:-5px;transform: scale(.8);">
              <el-tag v-if="scope.row.status == '0'" type="danger" >未审核</el-tag>
              <el-tag v-if="scope.row.status == '1'" type="success">已审核</el-tag>
            </span>
            <a @click="onBillNoClick(scope.row)" class="margin-top">{{scope.row.billNo}}</a>
          </template>
        </el-table-column>
        <el-table-column label="所属门店" width="120" prop="shopName">
        </el-table-column>
        <el-table-column label="客户" width="220" prop="companyName">
        </el-table-column>
        <el-table-column label="单据日期" width="100" align="center" prop="billTime">
          <template #default="scope">
            {{formatDate(scope.row.billTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="商品总数" width="90" prop="quantity">
          <template #default="scope">
            {{ getQuantity(scope.row) }}
            <el-tag style="position: absolute;right:-19px;top:-15px;transform: scale(.8);" v-if="getFinishQuantity(scope.row)" color="red">[退]</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="金额合计" width="100" prop="total" align="right">
          <template #default="scope">
            {{ formatFenTotal(scope.row.total) }}
          </template>
        </el-table-column>
        <el-table-column label="已付款" width="100" prop="settleTotal" align="right">
          <template #default="scope">
            {{ formatFenTotal(scope.row.settleTotal) }}
          </template>
        </el-table-column>
        <el-table-column label="付款状态" prop="paymentStatus" width="80" align="center" class-name="no-padding">
          <template #default="{row}">
            <el-tag type="danger"  v-if="row.paymentStatus == 0"> 未付款</el-tag>
            <el-tag type="warning" v-if="row.paymentStatus == 1"> 部分付款</el-tag>
            <el-tag type="success"  v-if="row.paymentStatus == 2"> 已付款</el-tag>
          </template>
        </el-table-column>
        <nt-trade-item-column  width="320" :hideColumns="['finishQuantity','executeStatus']">
          <!-- <template #execute-status="data"></template> -->
        </nt-trade-item-column>
        <el-table-column label="备注" width="200"  prop="remark">
        </el-table-column>
        <el-table-column label="制单人" width="120" prop="userName">
        </el-table-column>
        <el-table-column label="制单时间" width="140" prop="createTime">
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['ENABLE','DISABLE','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </el-main>
    <el-footer class="display-flex no-padding padding-top" style="justify-content: flex-end; height:auto;">
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </el-footer>
  </el-container>

  <el-dialog
    v-model="isDetailVisible" destroyOnClose width="75%"
    class="nt-none-border-dialog"
    title="线上零售单"
    :close-on-click-modal="false"
    @close="isDetailVisible=false"
  >
    <detail-page :height="550" @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible =false" :readOnly="viewOnly"   :entityId="currentRecord?.id" v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>

  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import DetailPage from './detail'
  
  import NtDateRange from '@/views/plugin/nt_date_range'
  import NtTradeItemColumn from '@/views/plugin/nt_trade_item_column'
  export default {
    name: "OnlineSaleList",
    mixins:[DataListMixin],
    components: {
      DetailPage,
      
      NtDateRange,
      NtTradeItemColumn
    },
    data () {
      return {
        currentRecord:{},
        toggleSearchStatus:false,
      }
    },
    methods: {
      async _loadList(params){
        return await this.getPageEntityList(this.EnumEntityType.ONLINE_SALE_HEAD,Object.assign({billType:'SALE_BACK_ONLINE'},params));
      },
      formatGoodsName(row){
        let nameList = row.onlineSaleItemList.map(item=>item.goodsName)
        return nameList.join(",")
      },
      onAddClick(){
        this.currentRecord = {}
        this.viewOnly = false
        this.isDetailVisible = true
      },
      batchDel(){
        //批量删除
      },
      async onRowDeleteClick(record){
        let res = await this.deleteEntity(this.EnumEntityType.ONLINE_SALE_HEAD, {id:record.id})
        if(res.code==200){
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || '删除失败')
        }
      },
      //拖拽组件
    
      onRowEditClick(record){
        this.currentRecord = record
        this.viewOnly = false
        this.isDetailVisible = true
      },
      onBillNoClick(record){
        this.currentRecord = record
        this.viewOnly=true
        this.isDetailVisible = true
      },
      onSearchQueryClick(){
        this.loadList()
      },
      onSearchResetClick(){
        this.queryModel = {}
        this.resetPage = true
        this.loadList(true)
      },
      
      getFinishQuantity(record){
        let qty = 0
        record.onlineSaleItemList.forEach(el => qty+=el.finishQuantity)
        return qty;
      },
      getQuantity(record){
        let qty = 0
        record.onlineSaleItemList.forEach(el => qty+=el.quantity)
        return qty;
      },
      handleToggleSearch(){},
      onDetailSaveSuccessHandler(){
        this.isDetailVisible =false
        this.loadList()
      },
      async onVerifyClick(record,isVerify){
        let res = await this.updateEntityStatus(this.EnumEntityType.ONLINE_SALE_HEAD, {status: isVerify? '1':'0', id: record.id})
        if(res.code===200){
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      }
    }
  }
</script>