<template>
  <el-container class="no-padding" style="height:450px;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="单据编号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <el-input placeholder="请输入单据编号查询" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
        </el-form-item>

      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" :data="datasource" v-loading="isLoading"
        @selection-change="onSelectionChangeHandler">
        <el-table-column label="" width="40" type="selection" fixed="left"></el-table-column>
        <el-table-column label="单据编码" width="160" prop="tradeHead.billNo"></el-table-column>
        
        
        <el-table-column label="单据日期" width="110" align="center" prop="tradeHead.billTime">
          <template #default="{row}">
            {{ formatDate(row.tradeHead?.billTime, 'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        
        
        <el-table-column label="往来单位" prop="tradeHead.companyName" width="90">
        </el-table-column>
        <el-table-column label="部门" prop="tradeHead.deptName" width="90">
        </el-table-column>
        <el-table-column label="经手人" prop="tradeHead.manName" width="90">
        </el-table-column>
        <el-table-column label="商品名称" prop="goods.goodsName" width="120">
        </el-table-column>
        <el-table-column label="采购单位" prop="unitName" width="90">
        </el-table-column>
        <el-table-column label="商品属性" prop="sku" width="90">
        </el-table-column>
        <el-table-column label="采购数量" prop="quantity" width="110">
        </el-table-column>
        <el-table-column label="采购金额" prop="total" width="110">
        </el-table-column>
        <el-table-column label="累计分摊金额" prop="shareTotal" width="110">
        </el-table-column>
        
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top" style="margin-top:12px;height:auto;">
      <el-row type="flex" justify="space-between">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" size="small" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" :disabled="!selectionRows.length" @click="onConfirmClick">确定{{`(${selectionRows.length})`}}</el-button>
        </el-space>
      </el-row>

    </el-footer>
  </el-container>
</template>

<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'
import {getPageTradeItemList} from '@/service/trade_item'
export default {
  name: 'fee_item_selector',
  mixins: [DataListMixin],
  components: {
    NtDateRange
  },
  data() {
    return {
      selectionRows: [],
    }
  },
  props: {
    billType: { // 当前操作的业务类型：费用收入  费用支出
      type: String,
      required: true
    },
  },
  emits: ['choose', 'cancel'],
  created() {
  },
  methods: {
    async _loadList(params) {
      return await getPageTradeItemList(Object.assign({billType:this.billType,status: '1' }, params));
    },

    onCancelClick() {
      this.$emit('cancel');
    },

    onSelectionChangeHandler(rows) {
      this.selectionRows = rows;
    },
    onSearchQueryClick() { },
    onConfirmClick() {
      if (this.selectionRows.length <= 0) {
        this.$message.error('请选择单据')
        return
      }

      this.$emit('choose', this.selectionRows);
    },
    
    onSearchResetClick() {
      this.queryModel = {
        type: this.queryModel.type,
        subType: this.queryModel.subType
      }
      this.resetPage = true
      this.loadList();
    }
  }
}
</script>

<style {row}d></style>