
<template>
  <el-container class="padding-8" style="height:400px;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-row type="flex" justify="space-between">
        <el-form inline @keyup.enter.native="onSearchQueryClick">
          <nt-form-item label="名称">
            <el-input placeholder="请输入名称查询" v-model="queryModel.storeName"></el-input>
          </nt-form-item>
          <nt-form-item label="备注">
            <el-input placeholder="请输入备注查询" v-model="queryModel.remark"></el-input>
          </nt-form-item>
          <nt-form-item>
            <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
          </nt-form-item>
        </el-form>
      </el-row>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table"  rowKey="id" :data="datasource" v-loading="isLoading"
      @row-click="onRowClick"
      >
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="快递公司" width="120" prop="deliveryId"></el-table-column>
        <el-table-column label="快递公司类型" width="120" prop="companyType">
          <template #default="{ row }">
            {{ row.companyType == 1 ? '加盟型' : '直营型' }}
          </template>
        </el-table-column>
        <el-table-column label="面单账号" width="120" prop="accountId"></el-table-column>
        <el-table-column label="账号类型" width="120" prop="accountType">
          <template #default="{ row }">
            {{ row.accountType == 0 ? '普通账号' : '共享账号' }}
          </template>
        </el-table-column>
        <el-table-column label="面单状态" width="90" prop="status">
          <template #default="{row}">
            {{ formatStatus(row.status) }}
          </template>
        </el-table-column>
        <el-table-column label="面单余额" width="90" prop="available"></el-table-column>
        <el-table-column label="累计取单" width="90" prop="allocated"></el-table-column>
        <el-table-column label="累积已回收" width="110" prop="recycled"></el-table-column>
        <el-table-column label="累计已取消" width="110" prop="cancel"></el-table-column>
        <el-table-column label="网点信息" width="160" prop="siteInfo">
          <template #default="{ row }">
            <div>{{ row.siteCode }}-{{ row.siteName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="绑定的发货地址信息" width="320" prop="senderAddress">
          <template #default="{ row }">
            <div>{{ row.contactName }} -{{ row.contactMobile }}</div>
            <div>{{ row.detailAddress }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <!-- <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" /> -->
          <el-button  @click="onCancelClick">取消</el-button>
          <el-button type="primary"  @click="onConfirmClick" :disabled="!this.selectedRow">确定</el-button>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import { postAction } from '@api/manage'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getWaybillAccountList } from '@/service/print_order/platform_waybill_template'
export default {
  name: "StoreList",
  mixins: [DataListMixin],
  components: {
    NtFormItem
  },
  data() {
    return {
      selectedRow:null
    }
  },
  methods: {
    async _loadList(params) {
      return await getWaybillAccountList(Object.assign({}, params));
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },

    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    formatStatus(status) {
      let info = {
        1: "绑定审核中",
        2: "取消绑定审核中",
        3: "已绑定",
        4: "已解除绑定",
        5: "绑定未通过",
        6: "取消绑定未通过"
      }
      return info[status] || status
    },
    onConfirmClick(){
      this.$emit('choose',this.selectedRow)
    },
    onCancelClick(){
      this.$emit('cancel')
    },
    onRowClick(row){
      this.selectedRow = row
    }
  }
}
</script>