<template>
  <div class="border-radius display-flex">
    <div class="display-flex">
      <div class="display-flex no-column-gap" style="cursor: pointer;column-gap: 0px;" >
        <span @click="onQuickQueryClick" style="font-size:14px;font-weight: bold;color:var(--el-color-primary)">快捷查询</span>
        <el-icon  @click="onQuickQueryClick" color="var(--el-color-primary)">
          <Setting />
        </el-icon>：
        <el-tag :class="` ${plan.id==selectedPlan?.id? 'nt-bottom-right-check':''}`" :type="plan.id==selectedPlan?.id? 'primary':'info'" v-for="plan in firstThreeList" 
        style="cursor: pointer;margin-right:4px;" @click="onSwitchPlanClick(plan)" >{{ plan?.planName }}</el-tag>
      </div>
      <el-popover ref="refPlan"  placement="bottom-start" width="550px" trigger="hover" v-if="planList.length>5">
        <template #reference>
          <el-button link type="primary" size="small" >更多</el-button>
        </template>
        <template #default>
          <div class="custom-waybill display-flex">
            <div class=" relative display-flex space-between" @click="onPlanClick(plan,index)" v-for="(plan,index) in planListForPopover"
              :key="plan.id">
              <div class="display-flex" style="column-gap: 2px;"><el-icon>
                </el-icon>{{ plan.planName }}</div>
            </div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
  <QuickQueryPage ref="refQuickDialog" @success="onQuickChange"></QuickQueryPage>
</template>

<script>
import { getQuickSearchPlanList } from '@/service/resource/general'
import QuickQueryPage from './quick_query_dialog'
export default {
  data() {
    return {
      planList: [],
      selectedPlan: null
    }
  },
  emits:['change'],
  components: { QuickQueryPage },
  computed:{
    firstThreeList:function(){
      return this.planList?.slice(0,5)
    },
    planListForPopover:function(){
      return this.planList?.slice(5)
    }
  },
  async created() {
    let res = await getQuickSearchPlanList({queryType:'PRINT_ORDER'})
    if (res.code == 200) {
      this.planList = res.data
      this.planList.forEach(el=> el.configJson = JSON.parse(el.configJson||'{}'))
    }
  },
  methods: {
    onQuickQueryClick() {
      this.$refs.refQuickDialog.showDialog(this.planList)
    },
    clearSelection(){
      this.selectedPlan = null
    },
    onPlanClick(plan,index) { // 下拉框选择方案
      this.selectedPlan = plan
      // 当前选中的方案，放到第一位
      setTimeout(_=>this.$refs.refPlan?.hide(),100)
      
      let preLength = this.firstThreeList.length
      this.planList.splice(preLength + index,1)
      this.planList.splice(0,0,plan)
      
      // 触发查询事件 ，并标记当前正使用快捷查询
      this.$emit('change', Object.values(this.selectedPlan.configJson))
    },
    onSwitchPlanClick(plan){ // 前三位置切换
      this.selectedPlan = plan
      this.$emit('change', Object.values(this.selectedPlan.configJson))
    },
    onQuickChange(list){
      this.planList = list
      if(this.selectedPlan){
        let matchIndex = this.planList.findIndex(el=>el.id == this.selectedPlan.id)
        if(matchIndex==-1){
          this.selectedPlan = null
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>

.custom-waybill {
  flex-wrap: wrap;

  &>div {
    cursor: pointer;
    flex: 0 0 calc(50% - 8px);
    background-color: #f5f5f5;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 5px;

    .el-icon {
      font-size: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}</style>