/**
 * 发货管理器
    3. 查询结果，并根据获得的结果，移除队列中相应的数据，并将结果通过回调通知订阅者
 *  
 */
import { createDispatch,queryCreateResult} from '@/service/print_order/dispatch'

class NtWaybillDispatchManager {
  #monitorQueue =[] // 发货监控队列
  #callback =null // 回调订阅
  constructor(callback) {
    this.#callback = callback
  }
  
  /*
    接收包裹： orderId ，orderNo
      数组类型
  */
  async dispatchWaybill(deliveryList){
    //忽略已存在的包裹,避免多次重复请求
    deliveryList = deliveryList.filter(item=>{
      let matchIndex = this.#monitorQueue.findIndex(el=> el.orderId==item.orderId && el.packageNum == item.packageNum)
      return matchIndex == -1
    })

    // 发送面单创建请求
    let res = await createDispatch({list:deliveryList})
    if(res.code==200){
      if(this.#monitorQueue?.length ==0){ // 队列空时，即首次，开始发起轮询
        setTimeout(_=>this.onQueryResultHandler(), 1000) // 一旦出现异常，可能崩溃
      }

      deliveryList.forEach(item=>{
        item.createTime = (new Date()).getTime() // 增加入队时间
        this.#monitorQueue.push(item)  // 成功发起面单创建后，放入监控队列
      })

    } else {
      this.#callback?.(deliveryList,{
        type:'create', // 面单创建发起类型
        success:false, //是否请求成功
        msg:res.message || res.msg||res.data //失败原因
      })
    }
  }

  // 截断超时包裹查询
  truncateOverTimeQueue(){
    let currentTime = (new Date()).getTime()

    let overTimeList = this.#monitorQueue.filter(item=>{
      return (currentTime -item.createTime)> 1000*60*3 
    })

    if(overTimeList.length>0){
      this.#callback?.(overTimeList,{
        type:'overTime', // 查询超时的包裹
        success:false, //是否请求成功
        msg: '查询结果超时' //失败原因
      })
      //截断超时队列
      this.#monitorQueue = this.#monitorQueue.filter(item=>{
        let matchIndex = overTimeList.findIndex(el=>el.orderId == item.orderId && el.packageNum == item.packageNum)
        return matchIndex == -1 // 返回未超时的队列
      })
    }
  }
  /*
    轮询面单创建结果
  */
  async onQueryResultHandler(){
    // 如果监控队列中包裹信息，超过3分钟没有返回结果，则直接移出监控队列，并触发回调
    this.truncateOverTimeQueue()
    let res = await queryCreateResult({list:this.#monitorQueue}).catch(e=>{
      return "timeout" // 优化
    })

    if(res==='timeout' || res.code ===-9999){ // 请求超时，无结果，重新发起，前端请求超时异常
      // console.log('time out ??',res)
      await this.onQueryResultHandler() // 通过截断超时队列 控制最大查询时间
    } else if(res.code ==200){ // 获取到生成结果
      let resultList = res.data // 面单查询结果
      let list = this.#monitorQueue.filter(item=>{
        let matchIndex = resultList.findIndex(el=> el.orderId==item.orderId && el.packageNum == item.packageNum)
        return matchIndex == -1
      })
      this.#monitorQueue = list // 监控队列中移除已获得结果的包裹信息

      if(this.#monitorQueue.length>0){ // 扔存在部分结果没有返回 继续发起查询
        // 此处控制速度
        this._timer && clearTimeout(this._timer)
        this._timer = setTimeout(async _=> await this.onQueryResultHandler(),3000 )
      }
      // resultList 包含有打印报文信息，打印模版可直接打印报文
      this.#callback?.(resultList,{
        type:'success', // 面单创建结果类型
        success:true, //是否请求成功
        msg:'success' //失败原因
      })
      
    } else {// 网络异常-- 中断轮询 -- 尚未完成的，显示“网络异常，刷新后重试
      // 触发回调
      this.#callback?.(this.#monitorQueue,{
        type:'query', // 面单查询结果
        success:true, //是否请求成功
        msg: res.msg || res.data//失败原因
      })
    }
  }
}

export default NtWaybillDispatchManager