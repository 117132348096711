<template>
  <el-container class="padding-16" >
    <el-main class="padding-16 nt-box-panel nt-bg-white" style="overflow:hidden;">
      <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading">
        <nt-form-item label="类型名称" prop="feeName" :rules="formValidator('required', '选择输入类型名称')">
          <el-input placeholder="请输入类型名称" v-model="formModel.feeName" />
        </nt-form-item>
        <nt-form-item label="费用类型" prop="feeType" :rules="formValidator('required', '请选择类型')" v-if="ioType==1">
          <nt-dropdown-grid configKey="sysKv" v-model="formModel.feeType" :requestParam="{parentKey:'SYS_FEE_TYPE'}"
            v-model:text="formModel.feeTypeName" :disabled="viewOnly" placeholder="请选择类型" :searchable="true"
            :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
      </el-form>
    </el-main>
    <el-footer class="no-padding padding-top-16 height-auto">
      <el-row type="flex" justify="end">
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
        </el-space>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "deptInfo",
  mixins: [DataEditMixin],
  components: {
    NtFormItem
  },
  data() {
    return {

    }
  },
  props: {
    entityId: {
      type: String
    },
    ioType: {
      type: Number,
      required: true
    }
  },
  methods: {
    async _loadEntity() {
      if (this.entityId) {
        return await this.getEntity(this.EnumEntityType.FEE_TYPE, { id: this.entityId })
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, ioType: this.ioType }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))
      return this.saveEntity(this.EnumEntityType.FEE_TYPE, postData)
    },
  }
}
</script>