import { axios as service } from '@/utils/request'


/**
 * 获取打单首页统计信息
 * @param {*} data 
 * @returns 
 */
export const getHomeSummary = (data) => {
  return service({
    url: '/report/platformOrder/getOrderSummary',
    method: 'post',
    data
  })
}
