<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding nt-box-panel nt-bg-white">
        <nt-form-item label="起始价格" prop="startPrice" :rules="formValidator('required', '请输入价格')">
          <el-input placeholder="请输入价格" v-model="formModel.startPrice" />
        </nt-form-item>
        <nt-form-item label="结束价格" prop="endPrice" :rules="formValidator('required', '请输入价格')">
          <el-input placeholder="请输入价格" v-model="formModel.endPrice" />
        </nt-form-item>
        <nt-form-item label="面单模版" prop="waybillTemplateId" :rules="formValidator('required', '请选择面单模版')">
          <nt-dropdown-grid configKey="waybillTemplate" v-model="formModel.waybillTemplateId" :requestParam="platformProp"
                    v-model:text="formModel.templateName" @change="onTemplateChange" placeholder="请选择面单模版" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item>
          <el-switch
              v-model="formModel.enabled"
              active-text="启用"
              :active-value="1"
              :inactive-value="0"
            />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>
import { DataEditMixin } from '@/utils/data_edit_mixin'
import {saveDeliveryPrice,getDeliveryPrice} from '@/service/print_order/platform_waybill_template'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtTagInput from '@/views/plugin/nt_tag_input'
export default {
  name: "GoodsAttrModal",
  mixins: [DataEditMixin],
  components: { NtFormItem,NtTagInput },
  data() {
    return {

    }
  },
  props: {
    entityId: {
      type: String
    },
    platform:{
      type:String,
      required:true
    }
  },
  computed:{
    platformProp:function(){
      return {platform:this.platform}
    },
  },
  methods: {
    async _loadEntity() {
      if (this.entityId) {
        return await getDeliveryPrice({ id: this.entityId })
      }
    },
    _loadFinished() {
        // console.log('fkfdfsdf kfkfk', this.formModel)
    },
    onTemplateChange(tpl){
      console.log('fking change')
      this.formModel.deliveryId = tpl.deliveryId
    },
    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({platform:this.platform, id: this.entityId }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      return saveDeliveryPrice( postData)
    },
  }
}
</script>
<style scoped></style>