
<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-main class="no-padding" style="height:100%;">
      <el-tabs v-model="activeTabName" style="height:100%;width:100%;" class="bill-selector-tab" >
        <el-tab-pane label="平台面单" name="platform" lazy >
          <el-container style="height:100%;">
            <el-aside class="margin-right-8" width="150px">
              <el-menu default-active="WX_VIDEO" class="el-menu-vertical-demo" style="height:100%;">
                <el-menu-item :index="item.platform" class="no-padding" v-for="(item,index) in electronicPlatformList" 
                @click="onPlatformClick(item)"
                :disabled="item.disabled">
                  <el-icon><Eleme /></el-icon>
                  <span>{{item.platformName}}</span>
                </el-menu-item>
              </el-menu>
            </el-aside>
            <el-main class="no-padding" style="height:100%;">
              <el-container class="no-padding" style="height:100%;">
                <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
                  <el-row type="flex" justify="space-between">
                    <el-form inline @keyup.enter.native="onSearchQueryClick">
                      <nt-form-item label="店铺">
                        <nt-dropdown-grid configKey="shop" v-model="queryModel.shopId" style="width:200px;"
                          v-model:text="queryModel.shopName" placeholder="请选择店铺" :searchable="true"
                          :iconSearch="false"></nt-dropdown-grid>
                      </nt-form-item>
                      <nt-form-item prop="deliveryId" label="物流公司" >
                        <nt-dropdown-grid configKey="shopDelivery" v-model="queryModel.deliveryId"
                          v-model:text="queryModel.deliveryName" :requestParam="{platform:selectedPlatform}" :readonly="viewOnly" placeholder="请选择物流公司" :searchable="true"
                          :iconSearch="false"></nt-dropdown-grid>
                      </nt-form-item>
                    </el-form>
                    <div>
                      <el-button type="primary"> （按平台--是否支持）同步面单模版 </el-button>
                      <el-button type="primary" @click="isWaybillAccountVisible = true"> 面单账号管理 </el-button>
                    </div>

                  </el-row>

                </el-header>
                <el-main class="no-padding">
                  <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="datasource" default-expand-all
                    :tree-props="{ children: 'customWaybillTemplateList' }" v-loading="isLoading">
                    <el-table-column label="#" width="50" type="index" fixed="left">
                    </el-table-column>
                    <el-table-column label="快递编码" width="110" prop="deliveryId"></el-table-column>
                    <el-table-column label="物流公司" width="140" prop="deliveryName">
                      <template #default="{ row }">
                        <div v-if="!row.isStandard && row.isDefault"><el-tag type="warning">默认</el-tag></div>
                        <div v-else>{{ row.deliveryName }}</div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="模版类型" width="120" prop="templateType"></el-table-column> -->
                    <el-table-column label="模版名称" prop="templateName">
                      <template #default="{ row }">
                        <div v-if="row.isStandard"><el-tag type="primary">标准模版</el-tag></div>
                        <div v-else>{{ row.templateName }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="模版描述" width="160" prop="templateDesc">
                    </el-table-column>
                    <el-table-column label="纸张大小(mm)" width="120">
                      <template #default="{ row }">
                        {{ row.height }}*{{ row.width }}
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="面单账号" width="120" prop="waybillAccountId">
                      <template #default="{ row }">
                        <el-input readnly v-model="row.waybillAccountId">
                          <template #suffix>
                            <el-icon style="cursor:pointer;" @click="selectedTemplate = row; isWaybillAccountVisible = true">
                              <Tools />
                            </el-icon>
                          </template>
                        </el-input>
                      </template>
                    </el-table-column> -->
                    <el-table-column label="默认打印机" width="200" prop="printerName">
                      <template #default="{ row }">
                        <el-select v-model="row.printerName" placeholder="请选择默认打印机"
                          @change="val => onPrinterChangeHandler(val, row)">
                          <el-option v-for="item in printerList" :key="item" :label="item" :value="item" />
                        </el-select>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="是否默认" width="90" prop="isDefault"></el-table-column> -->
                    <el-table-column label="状态" width="220">
                      <template #default="{ row }">
                        <el-button size="small" @click="onRowEditClick(row)" v-if="!row.isStandard">编辑</el-button>
                        <el-button size="small" @click="onRowAddClick(row)" v-if="row.isStandard">创建自定义模版</el-button>
                        <el-button size="small">设计</el-button>
                        <el-popconfirm title="确定当前选中模版?" @confirm="onRowDeleteClick(row)" v-if="!row.isStandard">
                          <template #reference>
                            <el-button size="small" type="danger">删除</el-button>
                          </template>
                        </el-popconfirm>

                      </template>
                    </el-table-column>
                  </el-table>
                </el-main>
                <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
                  <el-row type="flex" justify="end">
                    <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
                      :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
                      layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
                      @size-change="onPageSizeChange" />
                  </el-row>
                </el-footer>
              </el-container>
            </el-main>
          </el-container>
        </el-tab-pane>
        <el-tab-pane label="自定义面单" name="customer" lazy >
          <div>自定义模版--通过选择---并支持设计模版</div>
          <div>选择账号的方式：</div>
          <div>1 通过三方平台授权  2：通过输入账号密码</div>
        </el-tab-pane>
        
      </el-tabs>
    </el-main>
  </el-container>

  <el-dialog v-model="isDetailVisible" destroyOnClose width="650px" title="详情" :close-on-click-modal="false">
    <detail-page @success="onDetailSaveSuccessHandler" :standardTemplate="standardTpl" @cancel="isDetailVisible = false"
      :entityId="currentRecord.id" :shopId="selectedShopId" v-if="isDetailVisible"></detail-page>
  </el-dialog>
  <el-dialog v-model="isWaybillAccountVisible" :close-on-click-modal="false" destroyOnClose width="1050px" title="面单账号列表">
    <waybill-account-selector @cancel="isWaybillAccountVisible = false" @choose="onWaybillAccountChange"
      v-if="isWaybillAccountVisible"></waybill-account-selector>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
// import DeliveryList from './delivery'
import detailPage from './detail'
import { bindWaybillAccount, bindPrinter, deletePlatformWaybillTemplate } from '@/service/print_order/platform_waybill_template'
import { getOneByPlatform } from '@/service/resource/platform_shop'
import waybillAccountSelector from './waybill_account'
export default {
  name: "WaybillTemplateList",
  mixins: [DataListMixin],
  components: {
    // DeliveryList,
    waybillAccountSelector,
    detailPage,
    NtFormItem
  },
  data() {
    return {
      activeTabName:'platform',
      standardTpl: null, // 所属标准模版
      printerList: [], // 打印机列表
      isWaybillAccountVisible: false,
      selectedTemplate: null, // 当前操作的模版行
      selectedPlatform:'',
      selectedShopId:'',
      electronicPlatformList:[ // 电子面单平台
        {
          platform:'WX_VIDEO',
          platformName:'视频号电子面单',
          limitPlatform:'WX_VIDEO', // 指定该属性，表示面单仅指定特定的电脑平台---即门店所属的平台
          disabled:false,
          icon:''
        },
        {
          platform:'CAI_NIAO',
          platformName:'菜鸟电子面单', // 无limitPlatform  对所有平台开放
          disabled:false,
          icon:''
        },
        {
          platform:'PDD',
          platformName:'拼多多电子面单',
          disabled:true,
          icon:''
        },
        {
          platform:'DOU_YIN',
          platformName:'抖音电子面单',
          disabled:true,
          icon:''
        }
      ]
    }
  },
  watch:{
    selectedPlatform:function(){
      //切换平台，过滤商铺列表===》已开通相应平台的面单的商铺
    }
  },
  created() {
    // 读取打印组件的打印机列表
    this.printerList = ["打印机1", "打印机2", "打印机3", "打印机4", "打印机5"]
    this.onPlatformClick(this.electronicPlatformList[0].platform) // 默认显示第一个面单平台的数据
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.SHOP_WAYBILL_TEMPLATE,
        Object.assign(params,{shopId:this.selectedShopId,platform: this.selectedPlatform}, this.queryModel)
       )
    },
    async onPlatformClick(item){
      this.selectedPlatform = item.platform;
      // 切换平台时，根据面单平台支持的电商类型，过滤门店下拉列表
      // 切换平台后台， 默认加载第一个平台的模版数据
      //根据指定平台类型范围获取第一个门店，用于默认查询模版数据
      let res = await getOneByPlatform({platform: item.limitPlatform})
      if(res.code == 200){
        this.selectedShopId = res.data?.id
        this.queryModel.shopId = res.data?.id
        this.queryModel.shopName = res.data?.shopName
        this.loadList()
      }
      
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },

    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    async onRowDeleteClick(record) {
      let res = await deletePlatformWaybillTemplate({ id: record.id })

      if (res.code === 200) {
        this.$message.success("删除成功")
        this.loadList()
      } else {
        this.$message.error(res.msg || res.message || res.data)
      }
    },
    onRowEditClick(record) {
      this.standardTpl = null
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onRowAddClick(record) {
      this.standardTpl = record
      // 创建自定义模版，基于当前标准模版, deliveryId  templateType 
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },

    async onWaybillAccountChange(accountInfo) { // 设置面单账号
      // console.log('fking', accountInfo)
      this.selectedTemplate.waybillAccountId = accountInfo.accountId
      let res = await bindWaybillAccount({
        templateId: this.selectedTemplate.system ? this.selectedTemplate.shopTemplateId : this.selectedTemplate.id,
        templateType: this.selectedTemplate.templateType,
        deliveryId: this.selectedTemplate.deliveryId,
        deliveryType: this.selectedTemplate.deliveryType,
        accountId: accountInfo.accountId,
        shopId: this.selectedShopId
      })
      if (res.code === 200) {
        this.$message.success("更新成功")
      } else {
        this.$message.error(res.msg || res.message || res.data)
      }
      this.isWaybillAccountVisible = false
    },
    async onPrinterChangeHandler(val, row) {
      row.printerName = val
      let res = await bindPrinter({
        templateId: row.system ? row.shopTemplateId : row.id,
        templateType: row.templateType,
        deliveryId: row.deliveryId,
        deliveryType: row.deliveryType,

        printerName: val,
        shopId: this.selectedShopId
      })
      if (res.code === 200) {
        this.$message.success("更新成功")
      } else {
        this.$message.error(res.msg || res.message || res.data)
      }
    }
  }
}
</script>
<style scoped lang="less">
  .bill-selector-tab{
    display:flex;
    :deep(.el-tabs__content){
      flex:1 1 100%;
      .el-tab-pane{
        height:100%;
      }
    }
  }
</style>