<!-- 废弃？？ -->
<template>
  <el-container style="height:650px;">
    <el-main class="no-padding">
      <el-container class="nt-height-100" >
        <el-main class="no-padding">
          <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading" class="nt-height-100">
            <el-container class="nt-height-100">
              <el-header class="no-margin no-padding" style="height:auto;padding-bottom:0px;">
                <nt-form-item prop="deliveryId" label="物流快递" :rules="formValidator('required', '请选择快递公司')">
                  <el-select v-model.trim="formModel.deliveryId" @change="onDeliveryChange">
                    <el-option v-for="item in allDelivery" :key="item.deliveryId" :label="item.deliveryName" :value="item.deliveryId" />
                  </el-select>
                </nt-form-item>
                <nt-form-item prop="templateType" label="模版样式" :rules="formValidator('required', '请选择模版样式')">
                  <el-select v-model.trim="formModel.templateType" @change="onTemplateTypeChange">
                    <el-option v-for="item in templateTypeList" :key="item.templateType" :label="item.templateType" :value="item.templateType" />
                  </el-select>
                </nt-form-item>
                <nt-form-item prop="templateName" label="模版名称" :rules="formValidator('required', '请输入名称')">
                  <el-input placeholder="请输入模版名称" v-model.trim="formModel.templateName" />
                </nt-form-item>
                <div class="display-flex padding-4">
                  <div style="flex-basis:33%"  :class="`relative padding-8 nt-bg-white  space-between ${formModel.waybillAccountTenantId==shop.tenantId?'nt-bottom-right-check':''}`" @click="onShopClick(shop)" v-for="shop in shopList"
                    :key="shop.id">
                    {{ shop.shopName }}
                  </div>
                </div>
                <!-- <nt-form-item prop="waybillAccountTenantId" label="所属租户" :rules="formValidator('required', '请输入名称')">
                  <el-input placeholder="请选择租户" v-model.trim="formModel.waybillAccountTenantId" />
                </nt-form-item> -->
                
                <nt-form-item prop="waybillAccountId" label="面单账号" :rules="formValidator('required', '请选择面单账号')">
                  <nt-dropdown-grid configKey="waybillAccount" v-model="formModel.waybillAccountId" :requestParam="{}"
                    v-model:text="formModel.waybillAccountId" :disabled="viewOnly" placeholder="请选择面单账号" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
                <nt-form-item prop="printerName" label="默认打印机" :rules="formValidator('required', '请选择面单账号')">
                  <el-select v-model="formModel.printerName" placeholder="请选择默认打印机">
                      <el-option v-for="item in printerList" :key="item.name" :label="item.name" :value="item.name" />
                    </el-select>
                </nt-form-item>
                
                <nt-form-item prop="templateDesc" label="模版备注">
                  <el-input placeholder="请输入模版备注" v-model.trim="formModel.templateDesc" />
                </nt-form-item>

                
              </el-header>
              <el-main class="no-padding">
                <el-table height="100%" border ref="table" size="small"
                  :data="formModel.shopWaybillTemplateOptionList">
                  <el-table-column label="#" width="50" type="index" fixed="left">
                  </el-table-column>
                  <el-table-column label="自定义信息" prop="optionId">
                    <template #default="{ row }">
                      {{ formatOptionName(row.optionId) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="字体大小" width="120" prop="fontSize">
                    <template #default="{ row }">
                      <el-select v-model="row.fontSize">
                        <el-option v-for="item in fontSizeList" :key="item.value" :label="item.text" :value="item.value" />
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="粗体" width="90" align="center" prop="isBold">
                    <template #default="{ row }">
                      <el-checkbox v-model="row.isBold" :true-label="1" />
                    </template>
                  </el-table-column>
                  <el-table-column label="是否使用" width="90" prop="isOpen" align="center">
                    <template #default="{ row }">
                      <el-checkbox v-model="row.isOpen" :true-label="1" />
                    </template>
                  </el-table-column>

                </el-table>
              </el-main>
            </el-container>
          </el-form>
        </el-main>
        <el-aside width="420px" class="margin-left">
          这是可视化设计器
        </el-aside>
      </el-container>
    </el-main>
    <el-footer class="no-padding padding-top height-auto border-top">
      <el-row type="flex" justify="space-between">
        <nt-form-item prop="isDefault" label="设置为默认">
          <el-checkbox v-model="formModel.isDefault" :true-value="1" />
        </nt-form-item>
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
        </el-space>
      </el-row>
    </el-footer>
  </el-container>
  
</template>
  
<script>
import {allBindingShop} from '@/service/resource/platform_shop'
import NtFormItem from '@/views/plugin/nt_form_item'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import { saveWaybillTemplate,deliveryList } from '@/service/print_order/platform_waybill_template'
import NtSelectShop from '@/views/plugin/nt_select_shop'
export default {
  name: "WaybillTemplateDetail",
  components: { NtFormItem,NtSelectShop },
  mixins: [DataEditMixin],
  inject:['printerList'],
  data() {
    return {
      shopList:[],
      allDelivery:[],
      selectedDelivery:null,
      templateTypeList:[],
      fontSizeList: [{ value: 0, text: '正常' }, { value: 1, text: '加粗' }],
      trueFalseList: [{ value: 0, text: '否' }, { value: 1, text: '是' }],
    }
  },
  props: {
    entityId: {
      type: String
    },
    shopId:{
      type:String,
      required:true
    },
  },
  async created(){
    let res = await deliveryList({shopId:1})

    if(res.code ==200){
      this.allDelivery = res.data
    }

    res = await allBindingShop({})
    if(res.code ==200){
      this.shopList = res.data
    }
    
  },
  methods: {
    onDeliveryChange(deliveryId){
      this.selectedDelivery = this.allDelivery.find(el=>el.deliveryId == deliveryId)
      this.templateTypeList = this.selectedDelivery?.standardTemplateTypeVoList // 物流公司的 标准模版列表
    },
    onShopClick(shop){ // 切换不同门店的租户
      this.formModel.waybillAccountTenantId = shop.tenantId
      // 重新加载面单账户，并清空
    },
    onTemplateTypeChange(){
      // 模版样式变化后，更加templateUrl 加载预览效果 TODO
    },
    async _loadEntity(params) {
      if (this.entityId) {
        return await getWaybillTemplate( { id: this.entityId })
      } else {
        let defaultOptions = [0,1,2,3,4,5,6,7].map(item=>{// 设置默认的8个选项
          return {optionId: item,fontSize: 0, isBold: false,isOpen: item>2}
        })
        return {data:{ 
          // deliveryId: this.standardTemplate.deliveryId,
          // platform: this.standardTemplate.platform,
          // templateType: this.standardTemplate.templateType,
          // templateDesc: this.standardTemplate.desc,
          shopWaybillTemplateOptionList:defaultOptions
        }}
      }
    },
    formatOptionName(optionId) {
      let info = {
        0: '商品总数量',
        1: '商品名称+规格+编码+数量',
        2: '商品名称+规格+数量',
        3: '商品名称+数量',
        4: '店铺名称',
        5: '订单号',
        6: '买家留言',
        7: '卖家备注',
      }
      return info[optionId] || optionId
    },
    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, shopId: this.shopId }, this.formModel)
      console.log('fking data', postData)
      // return false
      return saveWaybillTemplate(postData)
    },
  }
}
</script>