<template>
  <div style="width:100%;">
    <el-tooltip ref="popoverRef" effect="light" :id="$attrs.id" :visible="suggestionVisible" :placement="configInfo.placement || ''"
      :virtual-ref="$refs.inputRef" popper-class="no-padding" trigger="click">
      <template #content>
        <el-table :height="`${configInfo.height || 200}px`"
          :style="{ width: configInfo.width + 'px', background: '#f5f5f5' }" v-loading="isLoading" :data="suggestions"
          ref="refTable" :border="[1, true, '1', 'true', null, undefined].includes(configInfo.border)"
          :showHeader="[1, true, '1', 'true', null, undefined].includes(configInfo.showHeader)" highlight-current-row
          @rowClick="onRowItemSelected">
          <el-table-column v-for="(column, index) in configInfo.columns" :key="column.prop" :width="column.width"
            :prop="column.prop" :label="column.label" />
        </el-table>
        <el-row type="flex" justify="space-between" style="margin-top:0px;align-items:center;padding:4px;">
          <el-button size="small" type="primary" @click="onAddClick">新增</el-button>
          <el-pagination v-if="configInfo.showPager" :current-page="this.pageInfo.pageIndex"
            :page-size="this.pageInfo.pageSize" :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" small
            background layout="total, prev, next" @current-change="onPageIndexChange" @size-change="onPageSizeChange" />
        </el-row>
      </template>
      <el-input ref="inputRef" v-click-outside="onClickOutside" v-model="displayText" :placeholder="placeholder" autocomplete="off"
        :readnly="readonly" @blur="handleBlur" @focus="onTextFocus" @input="onTextChange"
        @keydown.up.prevent="highlight(highlightedIndex - 1)" @keydown.down.prevent="highlight(highlightedIndex + 1)"
        @keydown.enter="onKeyEnterHandler">
        <template #suffix>
          <el-icon :class="{ 'el-select__caret': true, 'el-select__icon': true, 'is-reverse': suggestionVisible }"
            v-if="!readonly" @click="suggestionVisible = !suggestionVisible">
            <ArrowDown />
          </el-icon>
          <el-icon @click.stop="onSearchIconClick" style="cursor:pointer;" v-if="iconSearch && !readonly">
            <Search />
          </el-icon>
        </template>
      </el-input>

    </el-tooltip>
    <DynamicResourceEdtor ref="refEditor"></DynamicResourceEdtor>
  </div>
</template>
<script>
import { ClickOutside } from 'element-plus'
import { DropdownGridConfig } from './dropdown_config'
import DynamicResourceEdtor from './dynamic_resource_editor'

export default {
  data() {
    return {
      detailVisible: false,
      detailPage: null, // 动态添加资料页
      pageInfo: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      configInfo: DropdownGridConfig[this.configKey], // 加载配置文件信息

      suggestionVisible: false,
      lastValidText: this.text || '', //最后选择的有效文本
      displayText: this.text || '',
      highlightedIndex: -1,// 网络中默认聚焦行
      isLoading: false,
      suggestions: [],
      lastSearchText: null
    }
  },
  components: { DynamicResourceEdtor },
  directives: { ClickOutside },
  emit: ['update:modelValue'],
  props: {
    modelValue: null,// 对象keytext:null,// key -value 用于文本框显示内容
    searchable: {// 是否支持搜索
      type: Boolean,
      default: true,
    },
    clearable: { // 清除操作
      type: Boolean,
      default: false
    },
    triggerOnFocus: { // 组件获焦时，显示下拉框
      type: Boolean,
      default: true
    },
    iconSearch: {// 显示放大镜，高级搜索
      type: Boolean,
      default: true
    },
    configKey: {
      type: String, // 配置项key
      required: true
    },
    requestParam: { // 组件传入额外查询参数
      type: Object
    },
    text: {
      type: String
    }
  },
  computed: {
    placeholder: function () {
      return this.lastValidText || this.$attrs.placeholder
    },
    readonly: {
      get() { return !this.searchable || this.$attrs.readonly }
    }
  },
  watch: {
    suggestionVisible: function () {
      if (this.suggestionVisible && !this.firstLoadData) { // 首次显示下拉框时，加载数据
        this._timer = setTimeout(_ => this.loadDataList(), 100)
      }
    },
    text: function (newVal) {
      this.lastValidText = this.text
      this.dispalyText = this.text // 会触发获取焦点，然后触发失焦事件??
      this._lock = true
      setTimeout(_ => this._lock = false, 100) // 禁止失焦事件
    },
  },
  created() {
  },
  methods: {
    onClickOutside(e) {
      if (this.$refs.popoverRef?.isFocusInsideContent()) { // 下拉框点击点击焦点，属于
        return
      }
      this.suggestionVisible = false
    },
    onRowItemSelected(row) { // 触发v-model 回写this. lastSearchText =this.displayText // 选择数据后，以此次作为最后查询
      this.displayText = row[this.configInfo.text]
      this.lastValidText = row[this.configInfo.text] // 最后一次选择的有效text
      this.highlightedIndex = -1
      this.suggestionVisible = false
      this.ignoreFocusEvent = true
      this.$emit('update:modelValue', row[this.configInfo.value])
      this.$emit('update:text', row[this.configInfo.text])
      this.$emit('choose', row) // TODO 返回行对象或对象集合

      setTimeout(_ => this.$refs.inputRef?.blur())
    },
    onSearchIconClick() {
      console.log('onSearchIconClick') // 弹窗
    },
    highlight(rowIndex) { // 设置选中行状态
      if (this.suggestionVisible && rowIndex >= 0 && rowIndex < this.suggestions.length) {
        this.highlightedIndex = rowIndex
        this.$refs.refTable?.setCurrentRow(this.suggestions[rowIndex], true)
      }
    },
    onKeyEnterHandler() { // 输入框中，回车时，如下拉框已显示，且网格中列表存在数据，单选情况 直接选择复选则进行多选操作
      if (this.suggestionVisible && this.highlightedIndex >= 0 &&
        this.highlightedIndex < this.suggestions.length) {
        this.onRowItemSelected(this.suggestions[this.highlightedIndex])
      }
      else if (this.selectWhenUnmatched) {//没有数据时，则回传原来传入的数据
        this.$emit('select', { value: this.modelValue })
        suggestions = []
        this.highlightedIndex = -1
      }
    },
    handleBlur(evt) {
      if (this._lock) return // 忽略通过watch text 事件触发的blur 事件

      setTimeout(() => {
        // validate currentfocus event is insideel-tooltip-content
        // if so, ignore the blurevent and the next focusevent
        if (this.$refs.popoverRef?.isFocusInsideContent()) {
          this.ignoreFocusEvent = true
          return false
        }
        this.suggestionVisible = false
        if (this.lastValidText != this.displayText) {// 失焦后，判断输入的文本是否为最后一次选择的值，否则恢复至最后一次有效值
          this.displayText = this.lastValidText || ''
          this._lastSearchText = null

          this.ignoreFocusEvent = false
          this.$emit('blur', evt)
        }
      })
    },
    onTextFocus() {// 根据条件判断是否获取
      // 根据牵体判断是否获取集点时，显示下拉框
      if (['', 'true', 1, true].includes(this.$attrs.readonly)) {
        return
      }
      this.displayText = '' // 清空文本,显示占位符最后的值

      if (this.suggestionVisible || this.ignoreFocusEvent) {
        return
      }
      if (this.triggerOnFocus) {
        this.suggestionVisible = true
        !this.firstLoadData && this.loadDataList()
      }
    },
    onTextChange(val) {
      // 开始过滤数据，本地 远程loading， 缓存首次查询的结里乃&ev 防拉过程中可不正确，
      if (this.suggestionVisible) {
        this.suggestionVisible = true
        this._timer && clearInterval(this._timer)
        this._timer = setTimeout(_ => this.loadDataList(val), 500)
      }
    },
    async loadDataList(keyword = "") {
      if (keyword == this.lastSearchText) {
        return
      }
      this._lastSearchText = keyword
      this.isLoading = true
      if (this.configInfo.remote) { // 远程搜索
        let arParams = []
        if (this.configInfo.entityType) {
          arParams.push(this.configInfo.entityType)
        }
        
        let requestParam = Object.assign({}, this.configInfo.requestParam, { pageIndex: this.pageInfo.pageIndex, pageSize: this.pageInfo.pageSize }, this.requestParam)
        if (this.configInfo.search) { // 搜索字段
          requestParam[this.configInfo.search] = keyword
        }
        arParams.push(requestParam)
        let res = await this.configInfo.postMethod(...arParams) // 动态构建参数, 支持2个
        this.isLoading = false
        if (res.code == 200) {
          this.suggestions = res.data.list || res.data/*不带分页的配置*/
          this.pageInfo.total = res.data.total
        } else {
          this.$message.error(res.msg || res.data)
        }
      } else { // 本地搜索
        this._timer = setTimeout(_ => {
          this.suggestions = this.configInfo?.localData?.filter(item => item[this.configInfo.text].toLowerCase().indexOf(keyword.toLowerCase()) != -1)
          this.isLoading = false
        }, 200)
      }
      if (!this.firstLoadData) {
        this.firstLoadData = true
      }
    },
    // 组合式函数可以随时更改其状态。
    onPageSizeChange(current, val) {
      this.pageInfo.pageSize = val
      this.loadDataList()
    },

    onPageIndexChange(val) {
      this.pageInfo.pageIndex = val
      this.loadDataList()
    },
    onAddClick() { // 新增资料
      // 根据configKey 调用工具类，弹窗编辑资料，并接收保存后结果
      // 新增资料后，直接填充组件
      this.suggestionVisible = false
      this.$refs.refEditor.showEditor(this.configKey, this.onRowItemSelected)
    }
  }
}
</script>