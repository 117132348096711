
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>缺货预警</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        
        <el-table-column label="商品名称" prop="goodsName" width="220">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="120">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="100">
        </el-table-column>
        <el-table-column label="库存量" prop="baseQuantity"  width="100">
        </el-table-column>
        <el-table-column label="超储量"  width="100">
          <template #default="{row}">
            {{ row.baseQuantity - row.highStockQty }}
          </template>
        </el-table-column>
        <el-table-column label="资金占用"  align="right"  width="100">
          <template #default="{row}">
            {{ (row.baseQuantity - row.highStockQty) * (row.costTotal /row.baseQuantity)}}
          </template>
        </el-table-column>
        <el-table-column label="平均成本"  align="right"  width="100">
          <template #default="{row}">
            {{ (row.costTotal /row.baseQuantity)}}
          </template>
        </el-table-column>
        <el-table-column label="最高库存" prop="highStockQty" align="right"  width="100">
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getStockBelowLimit } from '@/service/report/rpt_repository'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "getStockBelowLimit",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getStockBelowLimit(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'SALE_OUT,SALE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

