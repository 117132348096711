
<template>
  <el-container class="padding-8 nt-height-100 nt-bg-page">
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading" @selection-change="onSelectionChange">
        <el-table-column label="区域">
          <template #default="{row}">
            <div v-html="renderAreaContent(row.districtCodes)"></div>
          </template>
        </el-table-column>
        <el-table-column label="物流公司" width="160">
          <template #default="{row}">
            <span>{{formatDelivery(row.deliveryId)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="面单模版" width="160">
          <template #default="{row}">
            <span>{{row.templateName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="启用状态" width="85" align="center">
          <template #default="{row}">
            <span v-if="row.enabled">启用</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="{row}">
            <el-button size="small" type="primary" link  @click="onRowEditClick(row)">修改</el-button>
            <el-popconfirm title="确定当前选中数据?" width="220" @confirm="onRowDeleteClick(row)" >
              <template #reference>
                <el-button size="small" type="danger" link  >删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <area-detail @success="onDetailSaveSuccessHandler" :platform="platform" :configType="configType" @cancel="isDetailVisible = false" :entityId="currentRecord?.id"
      v-if="isDetailVisible"></area-detail>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getDeliveryAreaList,deleteDeliveryArea } from '@/service/print_order/platform_waybill_template'
import AreaDetail from './detail/area_detail'
import {EnumDelivery} from '@/utils/enum_utils'
// import pcaa from '@/utils/pcaa'
import provinceData from 'province-city-china/dist/province.json'
import hongkongData from 'province-city-china/dist/hongkong.json'
import macauData from 'province-city-china/dist/macau.json'
import allLevel from '@/json/level.json' //'province-city-china/dist/level.json' // https://github.com/uiwjs/province-city-china
import areaData from '@/json/area.json' //'province-city-china/dist/area.json'

export default {
  name: "MarketList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    AreaDetail
  },
  data() {
    return {
      isDetailVisible:false,
      currentRecord:null,
    }
  },
  props:{
    configType:{
      type:String,
      default:'ACCEPT_AREA'
    },
    platform:{
      type:String,
      required:true
    }
  },
  watch:{
    platform:function(){
      this.loadList()
    }
  },
  methods: {
    renderAreaContent(districtCodes){
      let ar = districtCodes.split(',')
      function getMergeArea(areaCode){
        let matchItem = areaData.find(el=>el.code == areaCode) // 国内区域
        if(!matchItem){
          matchItem = hongkongData.find(el=>el.code == areaCode)
        }
        if(!matchItem){
          matchItem = macauData.find(el=>el.code == areaCode)
        }
        if(!matchItem){ // 仅一级省份--台湾
          matchItem = provinceData.find(el=>el.code == areaCode)
        }

        // 非区域，仅市一级  如海南省--儋州市
        if(!matchItem){
          matchItem = allLevel.find(el=>el.code == '460000')
          matchItem = matchItem.children.find(el=>el.code==areaCode)
        }
        return matchItem
      }
      let provinceJson = {}
      ar.forEach(code=>{
        let matchItem = getMergeArea(code) 
        
        let chinaProvince = allLevel.find(el=>el.province==matchItem.province)
        // {code: '131128', name: '阜城县', province: '13', city: '11', area: '28'}
        
        let pInfo = provinceJson[matchItem.province]
        
        if(!pInfo){
          pInfo = {code:chinaProvince.code ,name:chinaProvince.name,cityList:[]}
          provinceJson[matchItem.province] = pInfo
        }
        // 处理城市
        let chinaCity = chinaProvince.children.find(el=>el.city == matchItem.city && el.children) // 城市节点 一定有区域， 没有区域节点，说明为直辖市
        let cInfo = pInfo.cityList.find(el=>el.city == matchItem.city)
        if(!cInfo){
          if(!chinaCity){
            cInfo = {city:matchItem.city,name:null,count:0, areaList:[]} // 北京直辖市，没有城市一级

          } else{
            cInfo = {city:chinaCity.city,name:chinaCity.name,count:chinaCity.children?.length, areaList:[]}
          }
          pInfo.cityList.push(cInfo)
        }

        if(chinaCity){ // 如北京，下面只有区，跳过城市一级
          let chinaArea =chinaCity.children.find(el=>el.code == code)
          // 海南省 詹州市 --仅市，处理时按最后一级，不在chinaArea里面
          chinaArea && cInfo.areaList.push(chinaArea.name)
        } else {
          cInfo.areaList.push(matchItem.name)
        }
        
        
      })
      let resultList = []
      
      Object.values(provinceJson).forEach(el=>{
        el.cityList.forEach(city=>{
          let cityName = ''
          if(city.name){ // 北京直辖市列席，城市名称null，特殊处理
            cityName = '-'+city.name
          }
          if(city.count == city.areaList.length){
            resultList.push(`<div style='font-weight:bold;'>${el.name}${cityName}:全部区域(${city.areaList.length}个)</div> `)
          } else{
            resultList.push(`<div ><span style='font-weight:bold;'>${el.name}${cityName}(${city.areaList.length}个区域):</span>
                             <span>${city.areaList.join(',')}</span></div>`)
          }
          
        })
      })
      return resultList.join('')
    },

    async _loadList(params) {
      if(!this.platform){
        return
      }
      return getDeliveryAreaList(Object.assign({platform:this.platform,configType:this.configType},params))
    },
    onDetailSaveSuccessHandler(){
      this.isDetailVisible = false
      this.loadList()
    },
    addDetail(){
      this.currentRecord = null
      this.isDetailVisible = true
    },
    formatDelivery(deliveryId){
      return EnumDelivery[deliveryId] || deliveryId
    },
    onRowEditClick(row){
      this.currentRecord = row
      this.isDetailVisible = true
    },
    async onRowDeleteClick(row){
      let res = await deleteDeliveryArea({id:row.id})
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>
