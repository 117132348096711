
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto">
      <el-space wrap :size="12">
        <nt-select-shop placeholder="请选择店铺" v-model="shop" size="small" style="width:180px;" @defaultShop="onDefaultShopChange"
          @change="onShopChange"></nt-select-shop>
        <el-input placeholder="请输入商品标题，简称" size="small" v-model="queryModel.keyword"></el-input>
        <el-input placeholder="请输入商品规格属性" size="small" v-model="queryModel.attrs"></el-input>
        <el-select v-model="queryModel.status" placeholder="销售状态" size="small" style="width:110px;">
          <el-option label="销售中" :value="1"></el-option>
          <el-option label="已下架" :value="2"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasShortName" size="small" clearable placeholder="商品简称设置" style="width:110px;">
          <el-option label="有简称" :value="true"></el-option>
          <el-option label="无简称" :value="false"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasWeight" size="small" clearable placeholder="商品重量设置" style="width:110px;">
          <el-option label="有重量" :value="true"></el-option>
          <el-option label="无重量" :value="false"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasVolume" size="small" clearable placeholder="商品体积设置" style="width:110px;">
          <el-option label="有体积" :value="true"></el-option>
          <el-option label="无体积" :value="false"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasParketName" size="small" clearable placeholder="市场设置" style="width:110px;">
          <el-option label="有市场" :value="true"></el-option>
          <el-option label="无市场" :value="false"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasPosition" size="small" clearable placeholder="档口设置" style="width:110px;">
          <el-option label="有档口" :value="true"></el-option>
          <el-option label="无档口" :value="false"></el-option>
        </el-select>
        <el-select v-model="queryModel.hasMarketPrice" size="small" clearable placeholder="成本价设置" style="width:110px;">
          <el-option label="有成本价" :value="true"></el-option>
          <el-option label="无成本价" :value="false"></el-option>
        </el-select>
        <el-button type="primary" @click="onSearchQueryClick"  size="small">查询</el-button>
      </el-space>
      
    </el-header>
    <el-main class="no-padding margin-top-8">
      <el-container class="nt-height-100  nt-bg-page">
        <!-- <el-aside width="180px" class="padding-8 nt-bg-white border-right nt-box-panel " v-spliter-directive>
          <el-tree ref="refTree" nodeKey="categoryId" :data="categoryData" class="nt-height-100 nt-list-node"
            @node-click="onCategoryClick" :expand-on-click-node="false" :props="{ label: 'categoryName' }"
            default-expand-all />
        </el-aside> -->
        <el-main class="no-padding nt-box-panel overflow-hiden">
          <el-table height="100%" border ref="table" :indent="70" class="nt-resource-table" scrollbar-always-on
            style="border:0px;" stripe :data="datasource" :row-key="getRowKey" default-expand-all :tree-props="treeProps"
            :cell-class-name="getCellClassName" v-loading="isLoading" @selection-change="onSelectionChange">
            <el-table-column label="#" width="45" type="selection">
            </el-table-column>
            <el-table-column label="商品标题" prop="title">
              <template #header>
                <div class="display-flex space-between">
                  <div>商品标题</div>
                  <el-switch v-model="hideSku" size="small" active-text="不显示商品规格" />
                </div>
              </template>
              <template #default="{ row }">
                <div v-if="row.goodsExtra" class="display-flex">
                  <el-image :src="row.iconUrl" style="width:50px;height:50px;"></el-image>
                  <div class="flex-auto">
                    <div style="font-weight:bold;">{{ row.title }}</div>
                    <div>商品ID:{{ row.goodsId }}</div>
                  </div>
                </div>
                <div v-else>
                  <div>{{ formatSkuAttr(row.attrs) }}</div>
                  <div>规格ID:{{ row.skuId }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="简称" width="180">
              <template #header>
                <div class="display-flex">
                  <span>简称</span>
                  <el-dropdown>
                    <el-icon color="var(--el-color-primary)">
                      <Edit />
                    </el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="onShortNameEditClick(3)" v-if="!hideSku">编辑商品简称+规格简称</el-dropdown-item>
                        <el-dropdown-item @click="onShortNameEditClick(1)">编辑商品简称</el-dropdown-item>
                        <el-dropdown-item @click="onShortNameEditClick(2)" v-if="!hideSku">编辑规格简称</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
              <template #default="{ row }">
                <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.shortName"></el-input>
                <el-input v-else size="small" v-model="row.skuExtra.shortName"></el-input>

              </template>
            </el-table-column>
            <el-table-column label="条码" width="140" v-if="displayColumns.includes('barcode')">
              <template #default="{ row }">
                <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.barcode"></el-input>
                <el-input v-else size="small" v-model="row.skuExtra.barcode"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="成本价" width="100" v-if="displayColumns.includes('marketPrice')">
              <template #header>
                <div class="display-flex">
                  <span>成本价</span>
                  <el-dropdown>
                    <el-icon color="var(--el-color-primary)">
                      <Edit />
                    </el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="onFieldNameEditClick(3, 'marketPrice')"
                          v-if="!hideSku">编辑商品+规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(1, 'marketPrice')">编辑商品</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(2, 'marketPrice')"
                          v-if="!hideSku">编辑规格</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
              <template #default="{ row }">
                <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.marketPrice"></el-input>
                <el-input v-else size="small" v-model="row.skuExtra.marketPrice"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="重量kg/体积m³" width="155" v-if="displayColumns.includes('weight')">
              <template #header>
                <div class="display-flex">
                  <span>重量kg/体积m³</span>
                  <el-dropdown>
                    <el-icon color="var(--el-color-primary)">
                      <Edit />
                    </el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="onFieldNameEditClick(3, 'weight')"
                          v-if="!hideSku">重量:编辑商品+规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(1, 'weight')">重量:编辑商品</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(2, 'weight')"
                          v-if="!hideSku">重量:编辑规格</el-dropdown-item>

                        <el-dropdown-item @click="onFieldNameEditClick(3, 'volume')" divided
                          v-if="!hideSku">体积:编辑商品+规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(1, 'volume')"
                          :divided="hideSku">体积:编辑商品</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(2, 'volume')"
                          v-if="!hideSku">体积:编辑规格</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
              <template #default="{ row }">
                <div>
                  <div class="display-flex">
                    <span style="flex-basis:50px;font-size:12px;">重量:</span>
                    <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.weight"></el-input>
                    <el-input v-else size="small" v-model="row.skuExtra.weight"></el-input>
                  </div>
                  <div class="display-flex margin-top-8">
                    <span style="flex-basis:50px;font-size:12px;">体积:</span>
                    <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.volume"></el-input>
                    <el-input v-else size="small" v-model="row.skuExtra.volume"></el-input>
                  </div>
                </div>

              </template>
            </el-table-column>
            <el-table-column label="市场/档口" width="260" v-if="displayColumns.includes('marketName')">
              <template #header>
                <div class="display-flex">
                  <span>市场/档口</span>
                  <el-dropdown>
                    <el-icon color="var(--el-color-primary)">
                      <Edit />
                    </el-icon>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="onFieldNameEditClick(3, 'marketName')"
                          v-if="!hideSku">市场:编辑商品+规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(1, 'marketName')">市场:编辑商品</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(2, 'marketName')"
                          v-if="!hideSku">市场:编辑规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(3, 'position')" divided
                          v-if="!hideSku">档口:编辑商品+规格</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(1, 'position')"
                          :divided="hideSku">档口:编辑商品</el-dropdown-item>
                        <el-dropdown-item @click="onFieldNameEditClick(2, 'position')"
                          v-if="!hideSku">档口:编辑规格</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
              <template #default="{ row }">
                <div>
                  <div class="display-flex">
                    <span style="flex-basis:50px;font-size:12px;">市场:</span>
                    <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.marketName"></el-input>
                    <el-input v-else size="small" v-model="row.skuExtra.marketName"></el-input>
                  </div>
                  <div class="display-flex margin-top-8">
                    <span style="flex-basis:50px;font-size:12px;">档口:</span>
                    <el-input v-if="row.goodsExtra" size="small" v-model="row.goodsExtra.position"></el-input>
                    <el-input v-else size="small" v-model="row.skuExtra.position"></el-input>
                  </div>
                </div>

              </template>
            </el-table-column>

            <el-table-column label="操作" width="75">
              <template #header>
                <el-space>
                  <div>操作</div>
                  <el-dropdown>
                    <el-icon color="var(--el-color-primary)">
                      <Finished />
                    </el-icon>
                    <template #dropdown>
                      <el-dropdown-menu class="padding-8">
                        <el-checkbox-group v-model="displayColumns">
                          <el-checkbox value="barcode">显示条码</el-checkbox>
                          <el-checkbox value="marketPrice">显示成本</el-checkbox>
                          <el-checkbox value="weight">显示重量/体积</el-checkbox>
                          <el-checkbox value="marketName">显示市场/档口</el-checkbox>
                        </el-checkbox-group>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>

                </el-space>
              </template>
              <template #default="{ row }">
                <el-button v-if="row.goodsExtra" size="small" @click="onRowSaveClick(row)">保存</el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-main>
      </el-container>

    </el-main>
    <el-footer class="no-padding margin-top height-auto align-center relative">
      <el-button size="large" type="success" :loading="isSync" :disabled="isSync" @click="onStartSyncClick">{{ isSync ?
        '正在同步商品...' : '同步商品资料' }}</el-button>
      <el-button size="large" type="primary">批量保存</el-button>
      <el-pagination class="right-top margin-top" :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize" size="small" :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total"
        background layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </el-footer>
  </el-container>
  <ShortNameDialog ref="refShortNameDialog"></ShortNameDialog>
  <UpdateFieldDialog ref="refUpdateFieldDialog"></UpdateFieldDialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtSelectShop from '@/views/plugin/nt_select_shop'
import { getCategoryList, getGoodsList, saveGoodsExtra, submitSyncTask, queryTaskResult } from '@/service/resource/platform_goods'
import ShortNameDialog from './short_name_dialog'
import UpdateFieldDialog from './update_field_dialog'
export default {
  name: "Goods_Extra_List",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    NtSelectShop,
    ShortNameDialog,
    UpdateFieldDialog
  },
  data() {
    return {
      categoryData: [],
      shop: null,
      hideSku: false,
      isSync: false, // 是否正在执行同步任务
      displayColumns: ['barcode', 'marketPrice', 'weight', 'marketName']
    }
  },
  computed: {
    treeProps: function () {
      if (this.hideSku) {
        return {}
      }
      return { children: 'platformGoodsSkuList' }

    }
  },

  methods: {
    getRowKey(row) {
      return row.goodsExtra ? row.id : row.skuId
    },
    getCellClassName({ row, columnIndex }) {
      if (columnIndex == 1) { // 商品行
        return 'cell-goods'
      }
    },
    // async loadCategoryTree() { // 每次切换店铺时，重新加载分类树，并默认选择全部分类
    //   let res = await getCategoryList({ shopId: this.shop.shopId })
    //   let getChildren = function (parentId, data) {
    //     let children = data.filter(item => item.parentId == parentId)
    //     children.forEach(item => {
    //       item.children = getChildren(item.categoryId, data)
    //     })
    //     return children
    //   }
    //   if (res.code == 200) {
    //     this._cacheCategoryList = res.data // 用户网格列表分类多名称显示
    //     // 根据返回结果，构建前端树， 最多三级
    //     let nodeList = getChildren(null, res.data)
    //     let allNode = { categoryName: '全部', categoryId: null }
    //     nodeList.splice(0, 0, allNode)
    //     this.categoryData = nodeList
    //     // 默认选择--全部节点
    //     setTimeout(_ => {
    //       this.onCategoryClick(allNode)
    //     }, 0)
    //   }
    // },
    // onCategoryClick(node) {
    //   // 加载当前分类下所有的商品
    //   this.selectedCategoryId = node.categoryId
    //   this.$refs.refTree?.setCurrentNode(node)
    //   this.loadList()
    // },
    async _loadList(params) {
      if (!this.shop?.shopId) { // 强制选择门店
        return
      }

      let res = await getGoodsList(Object.assign({ shopId: this.shop.shopId }, this.queryModel, params));
      res.data?.list.forEach(item => {
        item.goodsExtra = item.goodsExtra || {}
        item.platformGoodsSkuList.forEach(el => {
          el.skuExtra = el.skuExtra || {}
        })
      })

      return res;
    },
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    onShopChange(val) {
      this.shop = val
      // this.loadCategoryTree()
      this.loadList()
    },
    onDefaultShopChange(val){
      this.shop = val
    },
    getListByType(type) {
      let list = []
      if (type == 1) {
        list = this.selectedRows.filter(item => item.goodsExtra)
      } else if (type == 2) {
        list = this.selectedRows.filter(item => item.skuExtra)
      } else {
        list = this.selectedRows
      }
      return list
    },
    onShortNameEditClick(type/*1: 商品 2 规格 3 商品+规格 */) {
      let list = this.getListByType(type)
      if (list.length == 0) {
        this.$message.error('请选择商品或规格')
        return
      }
      this.$refs.refShortNameDialog.show(list)
    },

    onFieldNameEditClick(type, fieldName) {
      let list = this.getListByType(type)
      if (list.length == 0) {
        this.$message.error('请选择商品或规格')
        return
      }
      this.$refs.refUpdateFieldDialog.show(fieldName, list)
    },
    async onRowSaveClick(row) {

      let goodsExtra = row.goodsExtra || {}
      let list = [{
        shopId: row.shopId,
        goodsId: row.goodsId,
        shortName: goodsExtra.shortName,
        barcode: goodsExtra.barcode,
        weight: goodsExtra.weight,
        volume: goodsExtra.volume,

        position: goodsExtra.position,
        marketName: goodsExtra.marketName,
        marketPrice: goodsExtra.marketPrice
      }]

      row.platformGoodsSkuList.forEach(el => {
        let skuExtra = el.skuExtra || {}
        list.push({
          shopId: row.shopId,
          goodsId: row.goodsId,
          skuId: el.skuId,
          shortName: skuExtra.shortName,
          barcode: skuExtra.barcode,
          weight: skuExtra.weight,
          volume: skuExtra.volume,
          position: skuExtra.position,
          marketName: skuExtra.marketName,
          marketPrice: skuExtra.marketPrice
        })
      })
      this.saveData(list)
      row.isEdit = false
    },

    async saveData(list) {
      let res = await saveGoodsExtra(list)
      if (res.code == 200) {

        this.$message.success("操作成功")
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    async onSaveClick() {
      let list = []

      this.selectedRows.forEach(el => {
        if (el.goodsExtra) {
          list.push({
            shopId: el.shopId,
            goodsId: el.goodsId,
            shortName: el.goodsExtra.shortName,
            barcode: el.goodsExtra.barcode,
            weight: el.goodsExtra.weight,
            volume: el.goodsExtra.volume,
            position: el.goodsExtra.position,
            marketName: el.goodsExtra.marketName,
            marketPrice: el.goodsExtra.marketPrice
          })
        } else {
          list.push({
            shopId: el.shopId,
            goodsId: el.goodsId,
            skuId: el.skuId,
            shortName: el.skuExtra.shortName,
            barcode: el.skuExtra.barcode,
            weight: el.skuExtra.weight,
            volume: el.skuExtra.volume,
            position: el.skuExtra.position,
            marketName: el.skuExtra.marketName,
            marketPrice: el.skuExtra.marketPrice
          })
        }
      })
      // 批量保存
      this.saveData(list)
    },
    async onStartSyncClick() {
      this.isSync = true
      this.counting = 1
      let queryVo = {
        shopId: this.shop.shopId
      }
      let res = await submitSyncTask(queryVo)
      if (res.code == 200) {
        this._timer = setInterval(_ => this.counting++, 1000) // 倒计时
        // 触发结果查询
        let taskId = res.data
        setTimeout(async _ => this.onQueryResultHandler(taskId), 1000) // 延迟1s 开始查询结果 一旦出现异常，可能崩溃
      } else {
        this.isSync = false
        this.counting = 0
        this.$message.error(res.msg || res.data || '同步失败')
      }

    },
    async onQueryResultHandler(taskId) {
      let res = await queryTaskResult({ taskId: taskId }).catch(e => {
        return "timeout" // 优化
      })

      if (res === 'timeout' || res.code === -9999) { // 请求超时，无结果，重新发起，前端请求超时异常
        this.onQueryResultHandler(taskId) // TODO 控制最大查询时间，直接弹窗提示-请刷新列表
      } else if (res.code == 200) { // 获取到生成结果
        this.isSync = false
        this.showRefresh = true // 结果获取后，显示刷新按钮
        this.counting = 0
        this._timer && clearInterval(this._timer)
        let resultJson = res.data // 任务执行结果
        this.lastSyncInfo = {
          message: resultJson.msg,
          syncTime: '2020-10-10',
          success: true
        }
        this.$notify({
          title: '同步已完成',
          type: 'success',
          duration: 30 * 1000,
          dangerouslyUseHTMLString: true,
          message: this.lastSyncInfo.message,
          // position: 'buttom-left'
        })

      } // TODO 网络异常-- 中断轮询 -- 尚未完成的，显示“网络异常，刷新后重试
    }

  }

}
</script>
<style scoped lang="less">:deep(.el-table) {
  .cell-goods {
    .cell {
      display: flex;

      .el-table__expand-icon {
        align-self: center;

        .el-icon {
          font-size: 18px;
          font-weight: bold;
        }

        color:var(--el-color-primary);
      }
    }
  }

}</style>