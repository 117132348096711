<template>
  <div ref="container" v-loading ="isLoading" class="padding">
    <el-form :model="formModel" ref="frmEdit" label-width="110px">
      <el-form-item   label="名称" prop = "accountName" :rules="formValidator('required')">
        <el-input placeholder="请输入名称" v-model ="formModel.accountName" />
      </el-form-item>
      <el-form-item  label="编号" prop = "accountNo" :rules="formValidator('required')">
        <el-input placeholder="请输入编号" v-model="formModel.accountNo"/>
      </el-form-item>
      <el-form-item label="开户行" prop = "bankName">
        <el-input placeholder="请输入开户行" v-model ="formModel.bankName" />
      </el-form-item>
      <el-form-item label="账号" prop = "bankNo">
        <el-input placeholder="请输入账号" v-model ="formModel.bankNo" />
      </el-form-item>
      <el-form-item prop="accountType" label="账户类型" :rules="formValidator('required', '请选择账户类型')">
        <nt-dropdown-grid configKey="sysKv" style="width:100%" v-model="formModel.accountType" v-model:text="formModel.accountTypeName"
        :requestParam="{parentKey:'SYS_ACCOUNT_TYPE'}" :disabled="viewOnly" placeholder="请选择账户类型" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
      </el-form-item>
      <el-form-item prop="paymentMethodId" label="默认结算方式" :rules="formValidator('required', '请选择结算方式')">
        <nt-dropdown-grid configKey="paymentMethod" style="width:100%" v-model="formModel.paymentMethodId" v-model:text="formModel.paymentMethodName"
          :disabled="viewOnly" placeholder="请选择结算方式" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
      </el-form-item>
      <el-form-item   label="排序" prop = "sort">
        <el-input placeholder="请输入排序" v-model ="formModel.sort" />
      </el-form-item>
      <el-form-item   label="备注" prop = "remark">
        <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model ="formModel.remark" />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-space>
        <el-button @click="onCancelClick">取消</el-button>
        <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
      </el-space>
    </el-row>
  </div>
</template>
<script>

  import { DataEditMixin } from '@/utils/data_edit_mixin'
  export default {
    name: "AccountModal",
    mixins: [DataEditMixin],
    data () {
      return {
      }
    },
    props:{
      entityId:{
        type:String
      }
    },
    created () {
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.EnumEntityType.ACCOUNT, {id: this.entityId})
        }
      },
      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        
        return this.saveEntity(this.EnumEntityType.ACCOUNT, postData)
      },
    }
  }
</script>
<style scoped>

</style>