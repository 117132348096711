
import { getPageEntityList, getEntityList,getSysKvListByParent } from '@/service/resource/general'
import { getGoodsUnitList, } from '@/service/resource/goods'
import { getBizCompanyCategoryList } from '@/service/resource/biz_company'
import { getWaybillAccountList } from '@/service/print_order/platform_waybill_template'
import {queryTemplateList} from '@/service/print_order/template'

import { EnumEntityType } from '@/utils/enum_utils'
export const DropdownConfig = {
    // 废弃
}

/**
 * 下拉网格组件配置脚本
 */
export const DropdownGridConfig = {
    
    supplier: { //往来单位
        remote: true, // 远程获取数据
        text: 'companyName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'companyName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.BIZ_COMPANY, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { companyType: 2 }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '供应商',// 网格标题
                prop: 'companyName', // 数据源
                // width: 'auto' // 列宽度,
            }
        ]
    },
    customer: { //客户
        remote: true, // 远程获取数据
        text: 'companyName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'companyName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.BIZ_COMPANY, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { companyType: 1 }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '客户',// 网格标题
                prop: 'companyName', // 数据源
            }
        ]
    },
    company: { // 往来单位： 客户，供应商
        remote: true, // 远程获取数据
        text: 'companyName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'companyName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.BIZ_COMPANY, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: {}, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '往来单位',// 网格标题
                prop: 'companyName', // 数据源
            }
        ]
    },
    companyCategory: { // 往来单位： 客户，供应商
        remote: true, // 远程获取数据
        text: 'categoryName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'categoryName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        postMethod: getBizCompanyCategoryList,
        detailPage: null, // 新增页面组件
        requestParam: {}, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '分类名称',// 网格标题
                prop: 'categoryName', // 数据源
            }
        ]
    },
    store: { //仓库
        remote: true, // 远程获取数据
        text: 'storeName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'storeName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.STORE, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '仓库',// 网格标题
                prop: 'storeName', // 数据源
            }
        ]
    },
    role: { //角色
        remote: true, // 远程获取数据
        text: 'roleName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'roleName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.ROLE, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '角色',// 网格标题
                prop: 'roleName', // 数据源
            }
        ]
    },
    feeType: { // 费用类型
        remote: true, // 远程获取数据
        text: 'feeName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'feeName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.FEE_TYPE, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '类型名称',// 网格标题
                prop: 'feeName', // 数据源
            }
        ]
    },
    account: { //银行账号
        remote: true, // 远程获取数据
        text: 'accountName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'accountName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.ACCOUNT, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '账户',// 网格标题
                prop: 'accountName', // 数据源
            }
        ]
    },
    attr: { // 属性类型
        remote: true, // 远程获取数据
        text: 'attrName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'attrName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.GOODS_ATTR, // 实体类型
        postMethod: getEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '属性名称',// 网格标题
                prop: 'attrName', // 数据源
            }
        ]
    },
    staff: { //员工
        remote: true, // 远程获取数据
        text: 'staffName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'staffName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.STAFF, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '人员',// 网格标题
                prop: 'staffName', // 数据源
            }
        ]
    },
    dept: { //部门
        remote: true, // 远程获取数据
        text: 'deptName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'deptName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.DEPT, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '部门名称',// 网格标题
                prop: 'deptName', // 数据源
            }
        ]
    },
    unit: { //计量单位资料列表
        remote: true, // 远程获取数据
        text: 'unitName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'unitName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.GOODS_UNIT, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '计量单位',// 网格标题
                prop: 'unitName', // 数据源
            }
        ]
    },
    goodsUnit: { //特定商品的计量单位列表 --- 商品id 额外传入
        remote: true, // 远程获取数据
        text: 'unitName', // 列表显示文本
        value: 'unitId', // 选择后返回的值  商品可选计量单位通过表t_goods_unit_price 提取，对应的字段为unitId
        search:'unitName', // 搜索字段名,考虑支持多个字段
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        entityType: null, // 实体类型
        postMethod: getGoodsUnitList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '计量单位',// 网格标题
                prop: 'unitName', // 数据源
                width:'auto'
            }
        ]
    },
    goods: { //商品资料
        remote: true, // 远程获取数据
        text: 'goodsName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'goodsName', // 搜索字段名,考虑支持多个字段  商品名称  编码 助记码
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.GOODS, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:850,
        advanceChoose:null, //告警搜索，点击更多时，弹窗选择资料
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '条码',// 网格标题
                prop: 'barcode', // 数据源
                width: 80
            },
            {
                label: '名称',// 网格标题
                prop: 'goodsName', // 数据源
            },
            {
                label: '规格型号',// 网格标题
                prop: 'standard', // 数据源
                width: 100
            },
            {
                label: '产品分类',// 网格标题
                prop: 'categoryName', // 数据源
                width: 100
            },
            {
                label: '主单位',// 网格标题
                prop: 'unitName', // 数据源
                width:80,
            },
            {
                label: '库存',// 网格标题
                prop: 'stockQuantity', // 数据源
                width: 80,
            },
            {
                label: '助记码',// 网格标题
                prop: 'asistCode', // 数据源
                width:100
            }
        ]
    },
    goodsSku: { //特定商品sku  存货量根据 计量单位换算---后台根据unitId 自动结算
        remote: true, // 远程获取数据
        text: 'sku', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'sku', // 搜索字段名,考虑支持多个字段  商品名称  编码 助记码
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        entityType: EnumEntityType.GOODS_SKU, // 实体类型---自定义类型 
        postMethod: getPageEntityList, // 定义请求方式--方法  或 url
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:250,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: 'SKU',// 网格标题
                prop: 'sku', // 数据源
                width:180
            },
            {
                label: '库存',// 网格标题
                prop: 'stockQuantity', // 数据源
                width:'auto'
            }
        ]
    },
    
    shop: { //员工
        remote: true, // 远程获取数据
        text: 'shopName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'shopName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.PLATFORM_SHOP, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '店铺名称',// 网格标题
                prop: 'shopName', // 数据源
            }
        ]
    },
    shopDelivery: { // 快递公司
        remote: true, // 远程获取数据
        text: 'deliveryName', // 列表显示文本
        value: 'deliveryId', // 选择后返回的值
        search:'deliveryName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.SHOP_DELIVERY, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '物流公司',// 网格标题
                prop: 'deliveryName', // 数据源
            }
        ]
    },
    sysKv: { // 系统key-value 配置项
        remote: true, // 远程获取数据
        text: 'configValue', // 列表显示文本
        value: 'configKey', // 选择后返回的值
        search:'configValue', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        // entityType: EnumEntityType.SHOP_DELIVERY, // 实体类型
        postMethod: getSysKvListByParent,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '数据项',// 网格标题
                prop: 'configValue', // 数据源
            }
        ]
    },
    paymentMethod: { // 系统key-value 配置项
        remote: true, // 远程获取数据
        text: 'paymentName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'paymentName', // 搜索字段名,考虑支持多个字段
        showPager: true, // 是否显示分页
        showAddButton: true, // 显示新增按钮
        entityType: EnumEntityType.PAYMENT_METHOD, // 实体类型
        postMethod: getPageEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '付款方式',// 网格标题
                prop: 'paymentName', // 数据源
            }
        ]
    },
    thirdAuthDeliver: { // 三方平台物流授权列表 配置项
        remote: true, // 远程获取数据
        text: 'companyName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'companyName', // 搜索字段名,考虑支持多个字段
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        entityType: EnumEntityType.THIRD_AUTH, // 实体类型
        postMethod: getEntityList,
        detailPage: null, // 新增页面组件
        requestParam: {authType:'DELIVERY' }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:300,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '公司名称',// 网格标题
                prop: 'companyName', // 数据源
            }
        ]
    },
    waybillAccount: { // 面单账号列表
        remote: true, // 远程获取数据
        text: 'accountId', // 列表显示文本
        value: 'accountId', // 选择后返回的值
        search:'accountId', // 搜索字段名,考虑支持多个字段
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        // entityType: EnumEntityType.THIRD_AUTH, // 实体类型
        postMethod: getWaybillAccountList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:650,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '面单账号',// 网格标题
                prop: 'accountId', // 数据源
                width:140
            },
            {
                label: '网点',// 网格标题
                prop: 'siteName', // 数据源
                width:160
            },
            {
                label: '网点地址',// 网格标题
                prop: 'detailAddress', // 数据源
            }
        ]
    },
    waybillTemplate: { // 面单模版
        remote: true, // 远程获取数据
        text: 'templateName', // 列表显示文本
        value: 'templateId', // 选择后返回的值
        search:'templateName', // 搜索字段名,考虑支持多个字段
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        entityType: EnumEntityType.FLATFORM_WAYBILL_TEMPLATE, // 实体类型
        postMethod: getEntityList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:450,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '模版Id',// 网格标题
                prop: 'templateId', // 数据源
                width:140
            },
            {
                label: '模版名称',// 网格标题
                prop: 'templateName', // 数据源
                width:160
            },
            {
                label: '物流公司',// 网格标题
                prop: 'deliveryName', // 数据源
            }
        ]
    },
    template: { // 单据模版
        remote: true, // 远程获取数据
        text: 'templateName', // 列表显示文本
        value: 'id', // 选择后返回的值
        search:'templateName', // 搜索字段名,考虑支持多个字段
        showPager: false, // 是否显示分页
        showAddButton: false, // 显示新增按钮
        postMethod: queryTemplateList,
        detailPage: null, // 新增页面组件
        requestParam: { }, // 额外请求参数
        showHeader: true, // 显示网格标题行
        border:false,
        height:250,
        width:250,
        placement:'top-start', // 下拉框显示位置
        columns: [ // 网格列表
            {
                label: '模版名称',// 网格标题
                prop: 'templateName', // 数据源
            }
        ]
    },

}