<!-- 拣货规则管理 -->
<template>
  <el-popover placement="top" :width="400" trigger="click">
      <template #reference>
        <el-button link type="primary">打印设置</el-button>
      </template>
      <el-radio-group v-model="config.sortMode" @change="onSettingChange">
        <el-radio  v-for="item in options" :label="item.label" :value="item.value"></el-radio>
      </el-radio-group>
      <el-checkbox v-model="config.printPositionTag" :true-value="1" :false-value="0" @change="onSettingChange">是否打印档口标签</el-checkbox>
    </el-popover>
  
</template>
<script>
import { getTenantKvByConfigKey, saveTenantKv } from '@/service/resource/general'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  name: "PickingRuleSetting",
  mixins: [],
  components: {

  },
  data() {
    return {
      config:{
        sortMode:'POSITION',
        printPositionTag:true
      },
      options:[
        {label:'按档口排序',value:'POSITION'},
        {label:'先档口排序，然后按照商品简称排序',value:'GOODS_SHORT_NAME'},
        {label:'先档口排序，然后按照商家编码排序',value:'GOODS_CODE'},
        {label:'先档口排序，然后按照规格简称排序',value:'SKU_SHORT_NAME'},
        {label:'先档口排序，然后按照先多件再单件排序',value:'QUANTITY'},
        {label:'先档口排序，然后按照先囤货再正常排序',value:'IS_SCAN'},
        {label:'先档口排序，然后按付款时间排序（先付款订单在前）',value:'PAY_TIME'},
      ]
    }
  },
  async created() {
    let configObj = LoginUserStore().getConfig('PICKING_SETTING')
    this.config = JSON.parse(configObj?.configValue||null) || {sortMode:'POSITION',printPositionTag:true} 
  },
  methods: {
    async onSettingChange() {
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'PICKING_SETTING', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        LoginUserStore().updateConfig('PICKING_SETTING',postData)
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>