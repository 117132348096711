<template>
  <el-container class="nt-height-100 nt-bg-page">
    <el-header class="no-margin padding-8 height-auto border-bottom">
      <div class="display-flex space-between">
        <div style="font-weight:bold;font-size:18px;">电子面单打印模版</div>
        <div>
          <el-checkbox-button size="small" type="info" v-model="designerConfig.showGridLine">
            <i class="iconfont icon-jingtaiwangge"></i>
          </el-checkbox-button>
          <el-checkbox-button size="small" v-model="designerConfig.showPaddingLine">
            <i class="iconfont icon-fenge2"></i>
          </el-checkbox-button>
        </div>
        <div>
          <el-button @click="onSaveClick" ><i class="iconfont icon-baocun1"></i></el-button>
          <el-button @click="onPreviewClick" ><i class="iconfont icon-yulan"></i></el-button>
        </div>
      </div>
    </el-header>
    <el-main class="no-padding" >
      <el-container class="no-padding nt-height-100">
        <el-aside width="100px" class="padding-8 nt-bg-white">
          <control-list-panel :controlList="list"></control-list-panel>
        </el-aside>
        <el-main class="no-padding" style="position:relative;overflow:hidden;">
          <designer-body :class="{'design-grid-line': designerConfig.showGridLine,'print-padding-line': designerConfig.showPaddingLine,}"
           :width="paperInfo.pageWidth" :height="paperInfo.pageHeight" @click="onPageClick" :page-setting="this.paperInfo.pageSetting">
            <drag-container v-model="store.page.items" @change="onDesignItemChange"></drag-container>
          </designer-body>
        </el-main>
        <!-- 组件属性编辑面板 -->
        <el-aside class=" margin-left" width="250px" style="background:#ddd;overflow:hidden;">
          <!-- 注意：目前单据明细配置信息需通过billType 进行读取 -->
          <property-setting :billType="billType" :current="store.current"></property-setting>
        </el-aside>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import './index.less'
import VueDragResize from 'vue-draggable-resizable' // https://github.com/mauricius/vue-draggable-resizable
import {usePageDesignerStore} from './usePageDesignerStore'
import { saveTemplate, getTemplate } from '@/service/print_order/template'
import { ControlList } from './config/design_config'
// import { saveTemplate as saveShopTemplate,getTemplate as getShopTemplate } from '@/service/shop_template'
import DragContainer from './control/drag_container'
import resizeTable from './control/table'
import PropertySetting from './control/property_setting'
import ControlListPanel from './control/control_list_panel'
import DesignerBody from './control/designer_body'

export default {
  data() {
    return {
      store: usePageDesignerStore(),
      DPI: { x: 1, y: 1 },
      designerConfig:{
        showGridLine:true, // 设计画布显示网格线
        showPaddingLine:true // 是否显示打印边距线
      },
      paperInfo:{
        title:'',
        pageHeight: 0,
        pageWidth:0,
        offsetLeft:0,
        offsetTop:0,
        pageSetting:{padding:{}} // 页面边距
      }, // mm 为单位
      list: ControlList
    }
  },
  components: { 
    VueDragResize, 
    resizeTable,
    DragContainer,
    DesignerBody,
    PropertySetting,
    ControlListPanel
  },
  props: {
    entityId: {
      type: String
    },
    shopTemplate: {
      type: String
    },
    billType: {
      type: String,
      required: true
    }
  },
  watch:{
    'paperInfo.pageSetting.pageWidth':function(val){
      this.paperInfo.pageWidth = val // 将内部json配置同步至外层字段
    },
    'paperInfo.pageSetting.pageHeight':function(val){
      this.paperInfo.pageHeight = val // 将内部json配置同步至外层字段
    }
  },
  created() {
    this.iniDPI()
  },
  mounted: async function () {
    //模板id 空时，新增
    let tplId =  this.entityId //|| router.currentRoute.value.params.tplId
    if (tplId) {
      let res = await getTemplate({ id: tplId })
      if (res.code == 200) {
        let pageConfig = {}
        let pageSetting = {}
        try {
          pageConfig = JSON.parse(res.data.configJson|| '{}')
          pageSetting = JSON.parse(res.data.pageSetting|| '{}')
          this.paperInfo = {
            pageWidth: res.data.pageWidth,
            pageHeight:  res.data.pageHeight,
            pageSetting: Object.assign( {padding:{}},pageSetting)
          }
        } catch { }

        this.store.page = Object.assign({items:[]},pageConfig)

        this.onPageClick() // 默认进入页面设置面板
      }

    }
    // 根据当前电脑不同的物理像素，等比例调整
    this.store.page.items?.forEach(item => {
      item.left = item.left * (this.DPI.x / 72)
      item.top = item.top * (this.DPI.y / 72)
      item.width = item.width * (this.DPI.x / 72)
      item.height = item.height * (this.DPI.y / 72)
    })
  },
  
  methods: {
    onDesignItemChange(item,posX,posY){
      this.store.current=item;
      if(this.store.current!=null && posX !=undefined && posY!=undefined ){
        // 通过drag_container 添加元素后，根据事件传递的坐标，定位当前新元素的位置
        this.store.current.left = posX
        this.store.current.top = posY
      }
    },
    onPageClick(){
      this.paperInfo._t = 'page'
      this.store.current = this.paperInfo.pageSetting
    },
    onSaveClick: async function () {
      let postData = JSON.parse(JSON.stringify(this.store.page))
      
      postData.items.forEach(item => {
        item.left = item.left / (this.DPI.x / 72)
        item.top = item.top / (this.DPI.y / 72)
        item.width = item.width / (this.DPI.x / 72)
        item.height = item.height / (this.DPI.y / 72)
      })

      let pInfo = JSON.parse(JSON.stringify(this.paperInfo))
      pInfo.pageSetting = JSON.stringify(pInfo.pageSetting)
      pInfo.title = pInfo.pageSetting.title // 模版标题
      // let saveFn = props.shopTemplate ? saveShopTemplate: saveTemplate
      let res = await saveTemplate(Object.assign(pInfo, { id: this.entityId },{configJson:JSON.stringify(postData)}))
      if (res.code === 200) {
        this.$message.success("保存成功")
      } else {
        this.$message.error(res.msg || res.data)
      }
    },

    onPreviewClick: function () {
      // 弹出预览窗口-，支持直接打印
    },

    iniDPI: function () {
      if (window.screen.deviceXDPI) {
        this.DPI.x = window.screen.deviceXDPI;
        this.DPI.y = window.screen.deviceYDPI;
      }
      else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        this.DPI.x = parseInt(tmpNode.offsetWidth);
        this.DPI.y = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
      }
    }
  }
}

</script>

<style lang="less" scoped>
.el-drawer__body{
  padding:0px 12px;
}


</style>