<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title>银行账户初始化</template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row  >
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%"/>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="handsPersonId" label="财务人员">
              <nt-dropdown-grid configKey="staff" v-model="formModel.handsPersonId"
                v-model:text="formModel.handsPersonName" :disabled="viewOnly" placeholder="请选择财务人员" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="备注" prop="remark">
              <el-input placeholder="请输入备注" v-model="formModel.remark" />
            </nt-form-item>
          </el-col>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.accountInitItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="银行账户" width="200" prop="financialItemId" >
            <template #default="scope">
              <span v-if="viewOnly">{{ scope.row.accountName }}</span>
              <nt-form-item   label-width="0" :prop="`accountInitItemList[${scope.$index}].accountId`" v-else
                :rules="formValidator('required', '请选择账户')">
                <nt-dropdown-grid key="ndg_account"  configKey="account"  v-model="scope.row.accountId"  v-model:text="scope.row.accountName"
                :disabled="viewOnly" placeholder="请选择账户" :searchable="true"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>

          <el-table-column label="金额" width="160" prop="total" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`accountInitItemList[${scope.$index}].total`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入金额')">
                <el-input v-model="scope.row.total" />
              </nt-form-item>
              <span v-else>{{ scope.row.total }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`accountInitItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>

        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
</template>
<script>

import NtBillLayout from '@/views/layout/nt_bill_layout'

import { DataBillMixin } from '@/utils/data_bill_mixin'
import formValidator from '@/utils/formValidator' // 通过调用 formValidator('required|max(10)','自定义消息') 生成表单项校验规则

import NtFormItem from '@/views/plugin/nt_form_item'

import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'
export default {
  name: "AccountInit",
  mixins: [DataBillMixin],
  components: {
    NtBillLayout,
    NtFormItem,
    NtBillToolbar
  },
  data() {
    return {
      billType: 'FINANCIAL_ACCOUNT_INIT',
      entityType:EnumEntityType.ACCOUNT_INIT_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  methods: {
    onFormChangeHandler() {
      // 根据当前编辑信息，重新计算：实付金额
      let total = 0
      this.formModel.accountInitItemList.forEach(el => total += parseInt(el.total))

      this.formModel.total = total
      this.$forceUpdate()
    },
    async _initialize() {
      let res = await this.getEntityList(this.entityType, { })
      if (res.code == 200) {
        this.inOutItemList = res.data
      }
    },
    async _createNewEntity() {
      // let billNo = await this.createBillNo('ZHQC')
      return {
        data: {
          // billNo: billNo,
          billType: this.billType,
          accountInitItemList:  [{}],
          billTime: this.getDateTime()
        }
      }
    },
    async _loadEntity(params) {
      if (!this.entityId) {
        this.fileList = []
        return this._createNewEntity()

      } else {
        let res = await this.getEntity(this.entityType, { id: this.entityId })
        return res
      }
    },
    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      if (this.formModel.accountInitItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }
      // TODO  判断是否费用项目存在重复
      let matchItem = this.formModel.accountInitItemList.find(el => !el.total)
      if (matchItem) {
        this.$message.error(`账户${el.accountName} 金额不能为0`)
        return false
      }

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)

      postData = JSON.parse(JSON.stringify(postData))
      
      return this.saveEntity(this.entityType, postData)
    },
    _loadFinished() {
    },
    onAddLineClick() {
      this.formModel.accountInitItemList.push({ })
    },
    async _copyBill() { // 复制单据
      // let billNo = await this.createBillNo('ZHQC')
      // 引用单据时，清空明细关联信息
      return {
        id: '',
        status: null,
        // billNo: billNo,
        billTime: this.getDateTime() // getNowFormatDateTime()
      }
    },
    getSummaries({columns,data}){
      let sumResult = []
      columns.forEach((column, index) => {
        if (index <2 ) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }
}
</script>