<template>
  <!-- <div id="app"> -->
    <router-view/>
  <!-- </div> -->
</template>

<style>

  @import "//at.alicdn.com/t/c/font_4627526_emuqog6rq1.css";
  #app {
    height: 100%;
  }
  body{
    overflow: hidden;
  }
</style>
