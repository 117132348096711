import { axios as service } from '@/utils/request'


export const getSaleOutTotalByGoods = (data) => {
  return service({
    url: '/report/sale/totalByGoods',
    method: 'post',
    data
  })
}

export const getSaleOutTotalByCustomer = (data) => {
  return service({
    url: '/report/sale/totalByCustomer',
    method: 'post',
    data
  })
}


export const getOrderTotalByGoods = (data) => {
  return service({
    url: '/report/sale/orderTotalByGoods',
    method: 'post',
    data
  })
}


export const getOrderDetailByCustomer = async (data) => {
  // 根据供应商进行小计数据统计
  let res = await service({
    url: '/report/purchase/orderDetailByCustomer',
    method: 'post',
    data
  })
  if(res.code ==200){
    // 分组小计数据处理
    res.data = summaryTableUtils(res.data,"companyId,companyName,billId,billNo",{quantity:'SUM',total:'SUM'})
  }
  return res
}

/*
  按单据统计商品明细 --销退冲抵
 */
export const getOrderDetailByBill = (data) => {
  return service({
    url: '/report/sale/orderDetailByBill',
    method: 'post',
    data
  })
}

/*
  按商品统计商品明细 --销退冲抵
 */
export const getOrderDetailByGoods = (data) => {
  return service({
    url: '/report/sale/orderDetailByGoods',
    method: 'post',
    data
  })
}

/*
  按商品统计商品明细 --销退冲抵
 */
  export const getOrderTotalByCustomerMerge = (data) => {
    return service({
      url: '/report/sale/orderTotalByCustomerMerge',
      method: 'post',
      data
    })
  }

  /*
  按商品销售排名
 */
  export const getGoodsSaleRank = (data) => {
    return service({
      url: '/report/sale/goodsSaleRank',
      method: 'post',
      data
    })
  }

  /*
  销售订单执行表
 */
  export const getOrderExecuteSummary = (data) => {
    return service({
      url: '/report/sale/orderExecuteSummary',
      method: 'post',
      data
    })
  }