
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>销售订单执行表</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <el-form-item label="销售时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="日期" prop="billTime" width="110">
          <template #default="{row}">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="销售订单" prop="billNo" width="170">
          <template #default="{row}">
            <el-text truncated  line-cape="1" size="small">{{ row.billNo }}</el-text>
          </template>
        </el-table-column>
        <el-table-column label="预付订金" prop="depositTotal" width="90">
        </el-table-column>
        <el-table-column label="收款状态" prop="paymentStatus" width="85">
          <template #default="{row}">{{ formatPaymentStatus(row.paymentStatus) }}</template>
        </el-table-column>
        <el-table-column label="执行状态" prop="executeStatus" width="85">
          <template #default="{row}">{{ formatExecuteStatus(row.executeStatus) }}</template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName" width="180">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName" width="120">
        </el-table-column>
        <el-table-column label="商品规格" prop="standard" width="100">
        </el-table-column>
        <el-table-column label="销售单位" prop="unitName" width="85">
        </el-table-column>
        <el-table-column label="商品属性" prop="sku" width="85">
        </el-table-column>
        <el-table-column label="订单数量" prop="quantity" width="85">
        </el-table-column>
        <el-table-column label="单价" prop="price" width="65" align="right">
        </el-table-column>
        <el-table-column label="订单总额" prop="billTotal" width="85" align="right">
        </el-table-column>
        <el-table-column label="累计收款" prop="outSettleTotal" width="90" align="right">
          <template #default="{row}">
            {{ (row.prepayTotal)+(row.outSettleTotal||0)-(row.backSettleTotal||0)}}
          </template>
        </el-table-column>
        <el-table-column label="收款比例" prop="backQuantity" width="90" align="right">
          <template #default="{row}">{{ (((row.prepayTotal)+(row.outSettleTotal||0)-(row.backSettleTotal||0))/row.billTotal*100).toFixed(2)}}%</template>
        </el-table-column>
        <el-table-column label="还差多少天" prop="planTime" width="100" align="right">
          <template #default="{row}">{{ formatDays(row) }}</template>
        </el-table-column>
        <el-table-column label="销售出库数量" prop="outQuantity" width="110" align="right">
        </el-table-column>
        <el-table-column label="销售退货数量" prop="backQuantity" width="110" align="right">
        </el-table-column>
        <el-table-column label="销货比例" prop="backQuantity" width="90" align="right">
          <template #default="{row}">
            {{ (((row.outQuantity||0)-(row.backQuantity||0))/row.quantity*100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column label="赠品数量" prop="isGift" width="90" align="right">
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>

</template>
<script>

import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import * as dayjs from 'dayjs'
import { getOrderExecuteSummary } from '@/service/report/rpt_sale'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NtDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      },
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getOrderExecuteSummary(Object.assign(params, {}))
    },
    formatExecuteStatus(status){
      let info={
        '0': '未执行',
        '1': '部分执行',
        '2': '已执行'
      }
      return info[status] || status
    },
    formatPaymentStatus(status){
      let info={
        '0': '未收款',
        '1': '部分收款',
        '2': '已收款'
      }
      return info[status] || status
    },
    formatDays(row){
      if(!row.planTime){
        return 
      }
      let minDt = dayjs(row.planTime)
      let dt = dayjs(new Date())
      return dt.diff(minDt,'day')
    },
    onSearchQueryClick() {
      this.loadList();
    }
  }
}
</script>