<template>
  <div class="border-radius display-inline-block" style="cursor: pointer;">

    <el-popover ref="refTemplate" :width="550" placement="bottom-start" trigger="click"
      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px;">
      <template #reference>
        <div class="display-flex">
          <div v-if="selectedTpl">
            <span style="font-weight: bold;">打印模版:</span>{{ selectedTpl.templateName }}
          </div>
          <div v-else style="color:#666;">请选择面单模版</div>
          <el-icon ><Switch /></el-icon>
        </div>
        
      </template>
      <template #default>
        <div class="custom-waybill display-flex">
          <div class=" relative display-flex space-between" @click="onTemplateClick(tpl)"
            v-for="tpl in waybillTemplateList" :key="tpl.id">
            <div class="display-flex" style="column-gap: 2px;"><el-icon>
                <ElemeFilled />
              </el-icon>{{ tpl.templateName }}</div>
            <el-button link size="small" type="primary" v-if="defaultTplId != tpl.id" @click.stop="onDefaultClick(tpl.id)">
              设为默认</el-button>
              <el-tag v-else type="warning">默认</el-tag>
          </div>

        </div>
        <el-row justify="end" class="margin-top">
          <el-button type="primary" size="small" @click="onGotoClick">添加模版</el-button>
        </el-row>

      </template>
    </el-popover>
  </div>
</template>

<script>

import {LoginUserStore} from '@/piana/login_user_service'
import {setDefaultTemplate } from '@/service/print_order/platform_waybill_template'

export default {
  data() {
    return {
      waybillTemplateList: LoginUserStore().templateList,
      selectedTpl: null,
      defaultTplId: null // 默认模版
    }
  },

  async created() {
    this.selectedTpl = LoginUserStore().getDefaultTemplate()
    // 找不到则默认第一个
    if(!this.selectedTpl){
      this.selectedTpl = this.waybillTemplateList[0]
    }
    this.defaultTplId =  this.selectedTpl?.id
    this.$emit('choose', this.selectedTpl)
  },
  methods: {
    onTemplateClick(tpl) {
      this.selectedTpl = tpl
      this.$refs.refTemplate.hide()
      this.$emit('choose', tpl)
    },
    async onDefaultClick(tplId) {
      let res = await setDefaultTemplate( { id: tplId })
      if (res.code == 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onGotoClick(){
      this.$router.push('/print/waybillTemplate');
    },
  }
}
</script>
<style lang="less" scoped>
.custom-waybill {
  flex-wrap: wrap;

  &>div {
    cursor: pointer;
    flex: 0 0 calc(50% - 8px);
    background-color: #f5f5f5;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 5px;

    .el-icon {
      font-size: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}</style>