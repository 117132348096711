<template>
	<div class="nt-bar-code">
		<img  ref="refBarcode" style="width: 100%;height:100%;"/>
	</div>
</template>

<script >
// https://lindell.me/JsBarcode/
import JsBarcode from 'jsbarcode';

export default {
  data () {
    return {
      
    }
  },
  components:{},
  props:{
    config:{
      type: Object
    }
  },
  watch:{
    "config.v":function(){
		  this.renderQrCode()
    }
  },
  mounted(){
	  this.renderQrCode()
  },
  methods:{
    renderQrCode(){
      if(this.$refs.refBarcode){
        this.qrcode = new JsBarcode(this.$refs.refBarcode,this.config.v, {
        //format: "pharmacode",
        lineColor: "#000",
        // width:4,
        margin:0,
        textMargin:0,
        fontSize:30,
        //height:40,
        displayValue: true})
      }
      
    }
  }
}

</script>