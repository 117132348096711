<!-- 打印机选择组件 -->
<template>
  <el-select v-model="currentTemplate" v-bind="$attrs" @change="onSelectChange" value-key="templateId">
    <el-option v-for="template in templateList" :key="template.templateId" :label="template.templateName" :value="template" />
  </el-select>
</template>
<script>
import {LoginUserStore} from '@/piana/login_user_service'
  export default {
    name: 'NTDateRange',
    data(){
      return {
        currentTemplate: this.modelValue,
        templateList: LoginUserStore().templateList
      }
    },
    props: {
      modelValue:{
        type:Object
      }
    },
    watch:{
      modelValue:function(){
        this.currentTemplate = this.modelValue
      }
    },
    created() { 
      this.currentTemplate = this.templateList.find(item=>item.isDefault)  // 设置默认模板
    },
    mounted(){
      if(this.currentTemplate){
        this.$emit('update:modelValue',this.currentTemplate)
      }
    },
    methods: {
      onSelectChange(){
        this.$emit('update:modelValue',this.currentTemplate)
      },
    }
    
  }
</script>
