<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading" style="height:650px;">
    <el-container class="nt-height-100">
      <el-main class="padding">
        <el-tabs v-model="activeTabName"  class="nt-width-100 nt-height-100 nt-tab-page nt-box-panel" >
          <el-tab-pane label="基本信息" name="base" class="padding-16 nt-height-100overflow-y-auto ">
            <el-container class="nt-height-100">
              <el-header class="height-auto no-padding no-margin">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="商品编码">
                      <el-input placeholder="请输入商品名称" autocomplete="off" v-model="formModel.goodsCode" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商品条码">
                      <el-input placeholder="请输入商品条码" autocomplete="off" v-model="formModel.barcode" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商品名称">
                      <el-input placeholder="请输入商品名称" autocomplete="off" v-model="formModel.goodsName" />
                    </el-form-item>
                  </el-col>
                  
                  <el-col :span="8">
                    <el-form-item label="商品简称">
                      <el-input placeholder="请输入商品简称" autocomplete="off" v-model="formModel.shortName" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="规格">
                      <el-input placeholder="请输入规格" autocomplete="off" v-model="formModel.standard" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="型号">
                      <el-input placeholder="请输入型号" autocomplete="off" v-model="formModel.modelType" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="参考成本">
                      <el-input placeholder="请输入参考成本" autocomplete="off" v-model="formModel.costPrice" />
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="8">
                    <el-form-item label="基础重量" datel-step="6" datel-title="基础重量"
                      datel-intro="请填写基本单位对应的重量，用于计算按重量分摊费用时单据中各行商品分摊的费用成本">
                      <el-input-number style="width: 100%" placeholder="请输入基础重量(kg)" v-model="formModel.weight" />
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="8">
                    <el-form-item label="商品分类" >
                      <nt-goods-category-tree v-model="formModel.categoryId" v-model:text="formModel.categoryName"></nt-goods-category-tree>
                    </el-form-item>
                  </el-col>
                  
                  <el-col :span="8">
                    <el-form-item :labelCol="labelCol" label="备注">
                      <el-input placeholder="请输入备注" v-model="formModel.remark" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-header>
              <el-main class="no-padding">
                <UnitPricePage :formModel="formModel" ></UnitPricePage>
              </el-main>
            </el-container>
          </el-tab-pane>
          <el-tab-pane label="SKU属性管理" name="attribute" class="padding-16 nt-height-100 ">
            <SkuSettingPage :formModel="formModel"></SkuSettingPage>
          </el-tab-pane>
          <!-- <el-tab-pane label="价格管理" name="price" class="padding-16 nt-height-100 ">
            
          </el-tab-pane> -->
          <el-tab-pane label="库存&其他信息" name="alarm" class="padding-16 nt-height-100 nt-bg-white ">
            <el-row class="margin-top nt-box-panel padding">
              <el-col :span="8">
                <el-form-item label="最低库存">
                  <el-input placeholder="请输入最低库存" autocomplete="off" v-model="formModel.lowStockQty" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="最高库存">
                  <el-input placeholder="请输入最高库存" autocomplete="off" v-model="formModel.highStockQty" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="安全库存">
                  <el-input placeholder="请输入安全库存" autocomplete="off" v-model="formModel.safeStockQty" />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="仓位货架" >
                  <el-input placeholder="请输入仓位货架" v-model="formModel.position" />
                </el-form-item>
              </el-col>
              
              <el-col :span="8">
                <el-form-item label="重量">
                  <el-input placeholder="请输入重量" autocomplete="off" v-model="formModel.weight" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="体积">
                  <el-input placeholder="请输入体积" autocomplete="off" v-model="formModel.volume" />
                </el-form-item>
              </el-col>
              
            </el-row>
            <el-row class="margin-top nt-box-panel padding">
              <el-col :span="8">
                <el-form-item label="品牌">
                  <el-input placeholder="请输入品牌" autocomplete="off" v-model="formModel.brand" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产地">
                  <el-input placeholder="请输入产地" autocomplete="off" v-model="formModel.originArea" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="税率">
                  <el-input placeholder="请输入税率" autocomplete="off" v-model="formModel.taxRate" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主供应商">
                  <el-input placeholder="请输入主供应商" autocomplete="off" v-model="formModel.supplierId" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="默认仓库">
                  <el-input placeholder="请输入默认仓库" autocomplete="off" v-model="formModel.storeId" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="其他信息">
                  <el-input placeholder="请输入其他信息" autocomplete="off" v-model="formModel.desc" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="标签">
                  <el-input placeholder="请输入标签" autocomplete="off" v-model="formModel.tags" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <!-- <el-tab-pane label="其他信息" name="supplier" class="padding-16 nt-height-100 nt-bg-white "></el-tab-pane> -->
        </el-tabs>
        
      </el-main>
      <el-footer class="padding height-auto" style="padding-top:0px !important;">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
  <!-- <el-dialog v-model="skuDetailVisible" destroyOnClose width="750px" class="nt-resource-dialog" title="SKU属性配置"
    :close-on-click-modal="false" @close="skuDetailVisible = false">
    <SpecDetailPage @choose="onSkuListChooseHandler" @cancel="skuDetailVisible = false" :attrList="selectedAtrrList"
      :skuList="formModel.goodsSkuList" v-if="skuDetailVisible"></SpecDetailPage>
  </el-dialog> -->
</template>
<script>

import { getGoodsCategoryListByParent } from '@/service/resource/goods_category'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import SpecDetailPage from './spec_detail' // 属性选择配置面板
import UnitPricePage from './unit_price' // 计量单位及价格
import SkuSettingPage from './sku_setting' // 计量单位及价格
import NtFormItem from '@/views/plugin/nt_form_item'
import NtGoodsCategoryTree from '@/views/plugin/nt_goods_category_tree'


export default {
  name: "goods_detail",
  components: {
    SpecDetailPage,
    UnitPricePage,
    SkuSettingPage,
    NtFormItem,
    NtGoodsCategoryTree
  },
  mixins: [DataEditMixin],
  data() {
    return {
      activeTabName:'base',
      // skuDetailVisible: false,
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    },
  },
  methods: {
    async _loadEntity(params) {
      if (!this.entityId) {
        return {
          data: {
            goodsUnitPriceList: [{ exchangeRate: 100 }], // 默认主单位价格信息
            goodsSkuList: []
          }
        }
      } else {
        let res = await this.getEntity(this.EnumEntityType.GOODS, { id: this.entityId })
        return res
      }
    },
    _loadFinished(){
      // 将sku属性的信息转为json数组
      this.formModel.attrJson = JSON.parse(this.formModel.attrJson|| '[]')
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      /*
        TODO 
        计量单位 不允许为空
        sku编码不能为空
        主单位必须出现在首行
        主单位+sku 不能重复
      */
      // if(this.formModel.goodsSkuList.length ==0){
      //   this.$message.error(`单据明细不能为空`)
      //   return false
      // }

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      return this.saveEntity(this.EnumEntityType.GOODS, postData)
    },
  }
}
</script>