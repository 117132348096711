/**
  登录用户基本信息：
    1.主店铺
    2.基础配置
      
*/

import { defineStore } from 'pinia'
import {getConfig} from '@/service/user'
import {getTenantKvByParentKey} from '@/service/resource/general'
import { getWaybillTemplateList } from '@/service/print_order/platform_waybill_template'
export const LoginUserStore = defineStore('loginUserService', {
  state: () => ({
    shopId:null, //主店铺id
    shopName:null, // 主店铺名称
    platform:null, // 主店铺所属平台
    shopList:[],// 绑定的店铺列表+自己
    config:{ // 基本配置信息

    },
    platformInfo:{
      'WX_VIDEO':'视频号'
    },
    printConfigList:[],
    templateList:[],
    printerList:[]
  }),
  actions: {
    async loadTenentShop(){ // 加载主店铺信息
      if(this.shopId){
        return
      }
      // 加载配置参数： 列显示， 地址显示，列显示设置
      let res = await getTenantKvByParentKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION'})
      if(res.code = 200){
        this.printConfigList = res.data
      }
      res = await getConfig({})
      if(res.code == 200){
        this.shopId = res.data.shopId
        this.shopName = res.data.shopName
        this.platform = res.data.platform
        this.tenantId = res.data.tenantId
        this.shopList = res.data.shopList
        // this.shopList.unshift({shopId:this.shopId,shopName:this.shopName,shopPlatform:this.platform,shopTenantId:this.tenantId})
      }

      res = await getWaybillTemplateList({})
      if (res.code == 200) {
        this.templateList = res.data
      }
    },
    getConfig(configKey){
      return this.printConfigList.find(item=>item.configKey == configKey)
    },
    getShopById(shopId){
      return this.shopList.find(item=>item.shopId==shopId)
    },
    updateConfig(configKey,config){
      let matchIndex = this.printConfigList.findIndex(item=>item.configKey == configKey)
      if(matchIndex == -1){
        this.printConfigList.push(config)
      } else {
        this.printConfigList[matchIndex] = config
      }
    },
    getDefaultTemplate(){
      return this.templateList.find(item=>item.isDefault)
    },
    setPrinterList(list){
      this.printerList = list
    }

  }
})

