
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>现存量库存分布</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按商品-仓库1 仓库2"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          <el-button type="primary" @click="onExportClick">导出</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="商品名称" prop="goodsName" width="260">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="160">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="100">
        </el-table-column>
        <el-table-column :label="item.storeName" align="center" v-for="item in crossCols" v-key="item.storeId">
          <el-table-column label="现存量(主)" prop="sku" width="110">
            <template #default="{row}">
              {{ row.child[`baseQuantity_${item.storeId}`] }}
            </template>
          </el-table-column>
          <el-table-column label="结存金额" width="120" align="right">
            <template #default="{row}">
              {{ row.child[`costTotal_${item.storeId}`] }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="小计" align="center">
          <el-table-column label="现存量(主)" prop="sku" width="110" align="right">
            <template #default="{row}">
              {{ row.child[`baseQuantity_summary`] }}
            </template>
          </el-table-column>
          <el-table-column label="结存金额" width="120" align="right">
            <template #default="{row}">
              {{ row.child[`costTotal_summary`] }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getGoodsStock } from '@/service/report/rpt_repository'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
import { crossTableUtils } from '@/utils/table_summary'
import * as XLSX from 'xlsx-js-style'
import { createWorksheet } from '@/utils/excel_utils'
export default {
  name: "stock_summary_store",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      crossCols:[],// 横向动态仓库列集合
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      let res = await getGoodsStock(Object.assign(params, {}))
      // 将仓库+ 商品 转为交叉形式的数据
      let result = crossTableUtils(res.data,'goodsId,goodsName','baseQuantity,costTotal','storeId,storeName')
      this.crossCols = result.cols
      res.data = result.data
      return res
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onToolbarClick(cmd){
      if(cmd == 'EXPORT'){
        this.onExportClick()
      }
    },
    onExportClick(){
      const workbook = XLSX.utils.book_new();
      let dynamicCols = this.crossCols.map(item=>{
        return {
          label:item.storeName,
          colSpan:2,
          // dataIndex:'',
          columns: [
          {
            label: '现存量',
            dataIndex: `child.baseQuantity_${item.storeId}`,
            width: 80,
            textStyle: {
              alignment: { horizontal: 'right', vertical: 'center' },
              numFmt:'#.00'
            }
          },
          {
            label: '结存金额',
            dataIndex: `child.costTotal_${item.storeId}`,
            width: 80,
            textStyle: {
              alignment: { horizontal: 'right', vertical: 'center' },
              numFmt:'#.00'
            }
          },
        ],
        }
      })
      // return
      let worksheet = createWorksheet('xxxx',this.datasource,dynamicCols)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, "myexcelfile.xlsx", { compression: true });
    },
  }
}
</script>
