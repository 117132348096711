<template>
  <div>
    <div>订单详情</div>
    <div>买家信息</div>
    <div>拆分信息</div>
    <div>包裹信息</div>
  </div>
  
</template>

<script>

  import {getOrder} from '@/service/print_order/order'
  
  export default {
    name:"splitOrder",
    data () {
      return {
        isLoading:false,
        isSubmitting:false,
        datasource:[],
        columns: [
          {
            title: '编码',dataIndex: 'goodsId', key: 'goodsId',
            scopedSlots: {customRender: 'customRender'}
          },
          {
            title: '名称',dataIndex: 'goodsName', key: 'goodsName',
            scopedSlots: {customRender: 'customRender'}
          },
          {
            title: '已拆出数量', dataIndex: 'splitQuantity', key: 'age',
            scopedSlots: {customRender: 'customRender'},
            
          },
          {
            title: '数量',dataIndex: 'quantity', key: 'nam3e',width:60,
            scopedSlots: {customRender: 'customRender'}
          },
          {
            title: '已打包数量',
            dataIndex: 'packageCount',
            key: 'package',
            scopedSlots: { customRender: 'customRender'}
          },
          {
            title: '分包数量',
            dataIndex: 'orderExtra.isLocked',
            key: 'sNum',
            scopedSlots: { customRender: 'packageRender'}
          },
        ],
        packageInfos:{}
      }
    },
    props:{
      order:{
        type: Object,
        required: true
      }
    },
    async created(){
      
    },
    methods:{
      
  }
}
</script>
