
import {createVNode, render, cloneVNode} from "vue"
import MyLoading from "./nt_loading_dialog.vue"
export default {
    install(app) {
        // 使用vue底层的createVNode方法将组件渲染为虚拟节点
        const  vNode  = createVNode(MyLoading)
        vNode.appContext = app._context; // 加载此段，方能引用main上的其他组件element
        // 使用render函数将组件挂载到body中
        render(vNode, document.body)
        // 定义全局方法设置组件的显示和隐藏
        app.config.globalProperties.$showLoading = vNode.component.proxy.show
        app.config.globalProperties.$hideLoading = vNode.component.proxy.hide
    }
}
