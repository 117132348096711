<template>
  <el-container>
    <el-header class="no-margin no-padding height-auto">
      <el-alert title="结转周期按业务发生自然月计算,结转后，将按全月进行一次加权平均计算采购及销售成本" :closable="false" class="margin-bottom" />
    </el-header>
    <el-main class="no-padding">
      <el-form :model="formModel">
        <nt-form-item label="结转周期">
          <el-input placeholder="无结转周期信息" v-model="formModel.period" disabled></el-input>
        </nt-form-item>
        <nt-form-item label="结转时间">
          <NTDateRange v-model="formModel.startTime" :endTime.sync="formModel.endTime" format="YYYY-MM-DD" disabled
            :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </nt-form-item>
        <el-alert v-if="!this.formModel.period" title="系统当前未发生出库业务，无需操作结转" banner />
      </el-form>
    </el-main>
    <el-footer class="no-padding no-margin height-auto align-center margin-top">
      <el-button @click="onCancelClick">取消</el-button>
      <el-button type="primary" :loading="isSubmitting" @click="onConfirmClick"
        :disabled="!this.formModel.period">确定</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { getNewStockPeriod, startSettle } from '@/service/stock_period'
import NTDateRange from '@/views/plugin/nt_date_range'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: 'StockPeriodSettle',
  components: {
    NTDateRange,
    NtFormItem
  },
  data() {
    return {
      isSubmitting: false,
      formModel: {
      }
    }
  },
  async created() {
    let res = await getNewStockPeriod();
    if (res.code == 200) {
      this.formModel = res.data || {}
    } else {
      this.$message.error(res.msg || res.data)
    }
  },
  methods: {
    onCancelClick() {
      this.$emit('cancel')
    },
    async onConfirmClick() {
      let postData = Object.assign({}, this.formModel);
      postData.startTime += " 00:00:00"
      postData.endTime += " 00:00:00"
      this.isSubmitting = true

      let res = await startSettle(postData);
      this.isSubmitting = false
      if (res.code == 200) {
        this.formModel = res.data
        this.$message.success("结算成功")
        this.$emit('success')
      } else {
        this.$message.error(res.msg || res.data)
      }
    }
  }
}
</script>