<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title >销售出库单</template>
          <template #extra>
            <el-button class="el-menu-item" @click="jumpToPage('/sale_out_list','销售出库单列表')" >单据列表</el-button>
            <el-button class="el-menu-item" v-if="formModel.status == 1"
              @click="jumpToPage('/sale_back', '销售退货', { id: this.formModel.id, billType: this.billType })">退货</el-button>
            <!-- <el-button v-if="formModel.settleTotal>0" >付款历史</el-button> -->
            <!-- 直接生成付款单-预付款 -->
            <el-dropdown split-button @command="onCommandClick" @click="onPaymentClick" v-if="formModel.status==1">收款
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :disabled="!formModel.settleTotal" command="PAYMENT_HISTORY">收款记录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-button v-if="formModel.status == 1 && formModel.billTotal > (formModel.depositTotal || 0)"
              @click="jumpToPage('/financial/pay', '付款单', { id: this.formModel.id, billType: this.billType, bizType: 'FINANCIAL_PAY' })">付款</el-button> -->
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row class="form-row" >
          <el-col :span="6">
            <nt-form-item prop="companyId" label="客户" :rules="formValidator('required', '选择客户')">
              <nt-dropdown-grid configKey="customer" v-model="formModel.companyId" v-model:text="formModel.companyName"
                :disabled="viewOnly" placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
              <!-- <NTDropdownSelector placeholder="选择供应商"  configKey="supplier" v-model="formModel.companyId" :text="formModel.companyName" :disabled="viewOnly"></NTDropdownSelector> -->
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="planTime" label="预计发货时间" >
              <el-date-picker v-model="formModel.planTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="paymentTime" label="收款时间" >
              <el-date-picker v-model="formModel.paymentTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="deptId" label="部门">
              <nt-dropdown-grid configKey="dept" v-model="formModel.deptId" style="width:100%;"
                v-model:text="formModel.deptName" :readonly="viewOnly" placeholder="请选择部门" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="关联订单">
              <el-input placeholder="请选择关联订单" v-model="formModel.linkNo" readonly>
                <template #append v-if="!viewOnly">
                  <el-icon class="el-input__icon" @click.stop="onSearchLinkNumber" style="cursor: pointer;">
                    <search />
                  </el-icon>
                </template>
              </el-input>
            </nt-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item prop="remark" label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.remark" :readonly="viewOnly"/>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <nt-form-item label="付款优惠" datel-step="6" datel-title="付款优惠">
              <el-input placeholder="请输入付款优惠" v-model.number="formModel.discountTotal" :readonly="viewOnly" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="优惠后金额" datel-step="7" datel-title="优惠后金额">
              <el-input placeholder="请输入优惠后金额" v-model="formModel.billTotal" readonly />
            </nt-form-item>
          </el-col>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.tradeItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45"  v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                  <!-- <el-button link type="danger" size="small">删除</el-button> -->
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="仓库" width="160" prop="storeName" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <span v-if="viewOnly">{{ scope.row.storeName }}</span>
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].storeId`" v-else
                :rules="formValidator('required', '请选择仓库')">
                <el-select placeholder="选择仓库" v-model="scope.row.storeId" @change="onStoreChangeHandler(scope.row)">
                  <el-option v-for="(item, index) in storeList" :key="index" :label="item.storeName" :value="item.id">
                    {{ item.storeName }}
                  </el-option>
                </el-select>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="商品" width="200" prop="goodsName" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <span v-if="viewOnly || scope.row.linkId">{{ scope.row.goods.goodsName }}</span>
              <nt-form-item   label-width="0" :prop="`tradeItemList[${scope.$index}].goodsId`" v-else
                :rules="formValidator('required', '请选择商品')">
                <nt-dropdown-grid key="goods" configKey="goods" v-model:text="scope.row.goods.goodsName"
                :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
                @choose="(rows) => onRowGoodsChange([rows], scope.row, scope.$index)"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单位" width="110" prop="unitName">
            <template #default="scope">
              <span   v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.unitName }}</span>
              <nt-form-item  label-width="0" :prop="`tradeItemList[${scope.$index}].unitId`" v-else
                :rules="formValidator('required', '请选择计量单位')">
                <nt-dropdown-grid key="goodsUnit" configKey="goodsUnit" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.unitId" v-model:text="scope.row.unitName"
                :disabled="viewOnly" placeholder="计量单位" :searchable="false"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="SKU" width="180" prop="sku">
            <template #default="scope">
              <span   v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.sku }}</span>
              <nt-form-item  label-width="0"  :prop="`tradeItemList[${scope.$index}].goodsSkuId`" v-else>
                <nt-dropdown-grid key="goodsSku" configKey="goodsSku" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.goodsSkuId" v-model:text="scope.row.sku"
                :disabled="viewOnly" placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stockQuantity" width="80">
          </el-table-column>
          <el-table-column label="价格" width="80" prop="price" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item  label-width="0" :prop="`tradeItemList[${scope.$index}].price`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入价格')">
                <el-input v-model="scope.row.price" />
              </nt-form-item>
              <span v-else>{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="80" prop="quantity" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item  label-width="0" v-model="scope.row.quantity" v-if="!viewOnly" :prop="`tradeItemList[${scope.$index}].quantity`"
                :rules="formValidator('required', '请输入数量')">
                <el-input v-model="scope.row.quantity" />
              </nt-form-item>
              <span v-else>{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税率(%)" width="80" prop="taxRate" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].taxRate`" v-if="!viewOnly">
                <el-input v-model="scope.row.taxRate" />
              </nt-form-item>
              <!-- <el-input v-if="!viewOnly"  v-model="scope.row.taxRate" @change="e=>onRowInputChange(val,scope.row,'taxRate')"/> -->
              <span v-else>{{ scope.row.taxRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税价合计" width="90" prop="totalWithTax">
          </el-table-column>
          <el-table-column label="备注" prop="remark" class-name="editor">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <!-- @change="e=>onRowInputChange(val,scope.row,'remark')" -->
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-drawer
    v-model="accountItemVisible" destroyOnClose size="65%"
    class="nt-resource-dialog"
    title="付款历史"
    :z-index="99"
    @close="accountItemVisible=false">
    <account-item-list  @cancel="accountItemVisible =false"  billType="SALE_OUT" :billId="formModel.id" v-if="accountItemVisible"></account-item-list>
  </el-drawer>
  
  <el-dialog
    v-model="billSelectorVisible" destroyOnClose width="75%" append-to-body
    class="nt-resource-dialog"
    title="销售订单"
    :close-on-click-modal="false"
    @close="billSelectorVisible=false"
  >
    <bill-selector  @choose="onBillSelectedHandler" :companyId="formModel.companyId" @cancel="billSelectorVisible =false"  billType="SALE_ORDER" v-if="billSelectorVisible"></bill-selector>
  </el-dialog>
</template>
<script>

import NtBillLayout from '@/views/layout/nt_bill_layout'
  import { DataBillMixin } from '@/utils/data_bill_mixin'
  import { getNowFormatDateTime } from '@/utils/util'
  
  import billSelector from '@/views/bill/selector/LinkBillList'
  // 付款历史
  import AccountItemList from '@/views/financial/selector/account_item_list'
  import NtFormItem from '@/views/plugin/nt_form_item'
  
  import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
  import { EnumEntityType } from '@/utils/enum_utils'
  export default {
    name: "SaleOutModal",
    mixins: [DataBillMixin],
    components: {
      billSelector,
      AccountItemList,
      NtBillLayout,
      NtFormItem,
      NtBillToolbar
    },
    data () {
      return {
        billSelectorVisible:false,
        accountItemVisible: false,
        billType: 'SALE_OUT',
        entityType:EnumEntityType.TRADE_HEAD  // 浏览特定位置单据时使用，bill_mixin
      }
    },
    props:{
      entityId:{
        type: String
      }
    },
    watch:{
      entityId: function(){
        this.loadEntity()
      },
      "formModel.companyId":function(){
        // 切换供应商时，清空列表
        if(!this.isLoading && this.formModel.linkId){
          this.formModel.tradeItemList=[]
          this.formModel.linkId = ''
          this.formModel.linkType = ''
          this.formModel.linkNo = ''
        }
      }
    },
    methods: {
      async _initialize(){
        this.initStore()
      },
      async _createNewEntity() {
        // let billNo = await this.createBillNo('XSDD')
        return {
          data: {
            // billNo: billNo,
            tradeItemList: [{goods:{} }],
            discountTotal: 0,
            otherTotal: 0,
            total: 0,
            billTotal: 0,
            companyId: '',
            companyName: '',
            linkId: '',
            linkNo: '',
            billTime: this.getDateTime()
          }
        }
      },
      async _createByRefBill(refBill){ // 引用订单直接生成入库单
        if(refBill?.id){
          this.isLoading = true
          let res = await this.getEntity(this.entityType,{id:refBill.id})
          if(res?.code == 200){
            
            let details = []
            res.data.tradeItemList.forEach(item =>{
              if(item.quantity>(item.finishQuantity||0)){
                details.push({
                  goodsId:item.goodsId,
                  // goodsName:item.goodsName,
                  goodsSkuId: item.goodsSkuId,
                  unitId: item.unitId,
                  unitName: item.unitName,
                  goods:item.goods,
                  sku: item.sku,
                  quantity: item.quantity-(item.finishQuantity||0),
                  price: item.price,
                  remark: item.remark,
                  taxRate: item.taxRate || 0,
                  linkId: item.id, // 引用明细id
                  linkType: refBill.billType,
                  total:item.price * (item.quantity-(item.finishQuantity||0))
                })
              }
            })
            // let billNo = await this.createBillNo('XSCK')
            this.formModel = {
              // billNo: billNo,
              billTime: this.getDateTime(), // getNowFormatDateTime(),
              linkId: refBill.id,
              linkType: refBill.billType,
              linkNo: res.data.billNo,
              companyId: res.data.companyId,
              companyName: res.data.companyName,
              planTime: res.data.planTime,
              remark: res.data.remark,
              tradeItemList: details
            }
            // 进入新增状态
            this.viewOnly = false
            setTimeout(_=> this.isLoading = false,0) // this.formModel 赋值后触发formModel.companyId 监控时间，锁定变量，触发后再执行timeout
            
            this._loadFinished()
          }
        }
      },
      async _loadEntity(params){
        if (!this.entityId) {
          this.fileList = []
          return this._createNewEntity()
        } else {
          let res = await this.getEntity(this.entityType, {id: this.entityId})
          return res
        }
      },

      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        if(this.formModel.tradeItemList.length ==0){
          this.$message.error(`单据明细不能为空`)
          return false
        }
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,billType: this.billType},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        if(this.fileList && this.fileList.length > 0) {
          postData.fileName = this.fileList
        }
        return this.saveEntity(this.EnumEntityType.TRADE_HEAD, postData)
      },

      _loadFinished(){
        
        this.$refs.frmEdit?.clearValidate()
      },
     
      onFormChangeHandler(){
        // 根据当前编辑信息，重新计算：实付金额
        let total = 0
        this.formModel.tradeItemList.forEach(el=>{
          let itemTotal = (el.quantity||0)*(el.price||0)
          let taxTotal = (el.quantity||0)*(el.price||0)*(el.taxRate||0)
          el.totalWithTax = itemTotal+ taxTotal
          total += el.totalWithTax
        })

        this.formModel.total = total
        this.formModel.billTotal = total - (this.formModel.discountTotal||0) + (this.formModel.otherTotal||0)
        this.$forceUpdate()
      },

      onRowGoodsChange(rows,record,index){
        if(!rows || rows.length===0){
          return
        }
        let getAddItem = row => {
          return {
            goodsId: row.id,
            unitId:row.unitId,
            unitName: row.unitName,
            goods:row,
            quantity: 1,
            price: row.purchaseDecimal, // TODO  采购价格计算逻辑
            taxRate: 0,
            taxMoney: 0,
            stockQuantity:0,
            totalWithTax: row.purchaseDecimal
          }
        }
        // 根据id判断是否已经添加   TODO 逻辑变化  key goodsId + unitId+ skuId  确定唯一性
        rows.forEach(async(el,idx)=>{
          let matchItem = this.formModel.tradeItemList.find(item=>item.goodsSkuId == el.id)
          if(!matchItem){
            let newItem = getAddItem(el)
            // newItem.key = "__"+(new Date()).getTime() + "_"+ idx
            if(idx==0){// 第一个选择的商品直接填充当前行
              let current = this.formModel.tradeItemList[index]
              newItem.quantity = current.quantity || 1
              newItem.storeId = current.storeId
              newItem.remark = current.remark
              newItem.taxRate = current.taxRate
              this.formModel.tradeItemList[index] = newItem // 替换地址所指向位置，内存地址
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(this.formModel.tradeItemList[index]) // 注意：直接采用newItem 作为入参，ui 未刷新
            } else {
              this.formModel.tradeItemList.push(newItem)
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(newItem)
            }
          }
        })
        this.onFormChangeHandler()
      },

      async onStoreChangeHandler(row){ // 切换仓库时，重新计算存货信息
        row.stockQuantity= await this.refreshStockInfo(row)
      },

      onRowDeleteClick(rowIndex) {
      this.formModel.tradeItemList.splice(rowIndex, 1)
    },

      onAddLineClick() {
        this.formModel.tradeItemList.push({ goods:{} })
      },
      async _copyBill() { // 复制单据
        // let billNo = await this.createBillNo('XSCK')
        // 引用单据时，清空明细关联信息
        this.formModel.tradeItemList?.forEach(item=>{
          item.linkType = null
          item.linkNo = null
          item.linkId = null
        })
        return {
          id: '',
          companyId: '',
          companyName: '',
          status: null,
          linkId: '',
          linkType:'',
          linkNo: '',
          // billNo: billNo,
          billTime: this.getDateTime() // getNowFormatDateTime()
        }
      },

      onSearchLinkNumber() {
        if(!this.formModel.companyId){
          this.$message.info("请选择客户")
          return
        }
        this.billSelectorVisible= true
      },
      // 选择采购订单入库
      onBillSelectedHandler(selectBillDetailRows, billHeader) {
        this.billSelectorVisible=false
        if(selectBillDetailRows && selectBillDetailRows.length>0) {
          let listEx = []
          for(let j=0; j<selectBillDetailRows.length; j++) {
            let newItem = {}
            let info = selectBillDetailRows[j];
            newItem.goodsId = info.goodsId
            newItem.unitId=info.unitId
            newItem.goodsSkuId = info.goodsSkuId
            newItem.sku= info.sku
            newItem.unitName=info.unitName
            newItem.goods = info.goods
            
            newItem.price = (info.price||0)
            newItem.linkId = info.id
            newItem.linkType = billHeader.billType
            newItem.taxRate = info.taxRate||0
            newItem.taxPrice = (info.taxPrice||0)
            newItem.quantity = info.quantity - (info.finishQuantity||0)
            // newItem.key= "__"+ ((new Date()).getTime()+j)
            listEx.push(newItem)
          }
          this.formModel.linkType = billHeader.billType
          this.formModel.linkNo = billHeader.billNo
          this.formModel.linkId = billHeader.id
          this.formModel.tradeItemList = listEx
          this.onFormChangeHandler()
        }
      },

      onPaymentClick(){ // 生成付款单-预付款  参数refBill 通过meta 传递
        this.jumpToPage('/financial/take', '收款单',{id:this.formModel.id,billType:this.billType,bizType:'FINANCIAL_TAKE'})
      },

      onCommandClick(command){
        if(command == 'PAYMENT_HISTORY'){ // 付款历史
          this.accountItemVisible = true
        } 
      },
      
      getSummaries({columns,data}){
        let sumResult = []
        columns.forEach((column, index) => {
          if (index <2 ) {
            sumResult[2] = '合计'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => Number.isNaN(value))) {
            sumResult[index] = `${values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!Number.isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)}`
          } else {
            // sumResult[index] = 'N/A'
          }
        })
        return sumResult
      }
    }
  }
</script>