<template>
  <el-container class="nt-height-100 relative">
    <el-tooltip effect="dark" content="添加费用类型" placement="top-start">
      <el-button icon="Plus" @click="onAddClick" class="nt-float-button-add" circle></el-button>
    </el-tooltip>
    <el-main class="padding">
      <el-tree ref="refTree" class="nt-height-100 nt-list-node nt-no-tree-icon " nodeKey="id" style="overflow-y:auto;overflow-x: hidden;"
        :data="datasource"
        default-expand-all
        :props="props"
        :indent="0"
        @node-click="onFeeTypeChange">
        <template #default="{ node, data }">
          <el-row justify="space-between" class="nt-width-100" align="middle">
            <span>{{ node.label }}</span>
            <el-space :size="5" class="nt-operation" v-if="data.id">
              <a @click="onRowEnabledClick(data, 1)" v-if="!data.enabled">启用</a>
              <a @click="onRowEnabledClick(data, 0)" v-if="data.enabled">禁用</a>
              
              <el-icon size="large" @click.stop="onRowEditClick(data)"><Edit /></el-icon>
              <el-popconfirm title="确定删除?" @confirm.stop="onRowDeleteClick(data)">
                <template #reference>
                  <el-icon size="large" ><Delete /></el-icon>
                </template>
              </el-popconfirm>
            </el-space>
          </el-row>
        </template>
      </el-tree>
    </el-main>
  </el-container>
  
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :ioType="ioType" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail/'
import NtFeeTypeTree from '@/views/plugin/nt_fee_type_tree'
export default {
  name: "feeTypeList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFeeTypeTree
  },
  data() {
    return {
      ioType: this.$route.meta.ioType, // 根据路由区分费用，收入
      currentRow:null,
      props: {
        label: 'feeName'
      }
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.FEE_TYPE, Object.assign({ioType:this.ioType}, params));
    },
    _loadFinished(){ //列表加载结束后默认显示显示第一个
      let firstNode = {feeName:'全部',id:null}
      this.datasource.splice(0,0,firstNode)
      setTimeout(_=>{
        this.$refs.refTree?.setCurrentNode(firstNode)
        this.onFeeTypeChange(firstNode)
      },0)
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList();
    },
    onFeeTypeChange(row){
      if(this.currentRow == row){
        return
      }
      this.currentRow = row
      this.$emit('change',row)
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.FEE_TYPE, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onRowRightClick(record) {
      this.currentRecord = record
      this.isRightVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.FEE_TYPE, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }
  }
}
</script>