/*
    处理逻辑：
        将配置的mustache 的模版信息，以js的字符串格式，存放在js文件中（直接导出mustache模版，需要结合webpack的方式，需要各种配置）
        模版中，网格定义 createCell 的属性，用于生成二次渲染业务的数据 mustache 模版变量配置新

 */
import mustache  from 'mustache'
import {parseVarExpr} from '@/utils/reflect'
import wx_print_mustache from '@/views/mustache/template/wx_video_tpl.js'
import { Base64 } from 'js-base64'

// 辅助方法，将设计配置信息的驼峰写法转为css命名标准格式
function camelToCss(camelCase) {
    return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

// 将设计器定义的变量 =[属性名称] 转为 mustache 的变量名称 {{属性名称}}
function formatValue(val){
    //将符合系统变量的的值，提取变量后，转为matchache 标记语言
    let fieldVar = parseVarExpr(val)
    if(fieldVar){
        return '{{'+ fieldVar +'}}'
    }
    return val
}

// 生成style 样式 ： style="left:0px,top:0px..."
function formatHtmlStyle(item) { // 根据组件类型，生成style属性
    let sList = []
    // draggablesize  pt  正常的情况  1pt = 1.33px
    /*
    pt（磅）在分辨率为 96dpi（每英寸点数）的情况下约等于 0.352778mm（毫米）。
    计算过程如下：
    1 英寸等于 72 磅，而 1 英寸等于 25.4 毫米。在 96dpi 下，1 英寸等于 96 像素。
    1pt = 1/72 英寸。
    1 英寸在 96dpi 下等于 25.4 毫米，所以 1pt = (1/72)×25.4 ≈ 0.352778 毫米。
    */
    let eRate = 2.8  // 1mm= 10px
    for(let key of ['left','top','height','width']){
        item[key] != undefined &&  sList.push(`${key}:${item[key]*eRate}px`)
    }
    item.textStyle = item.textStyle || {}
    item.textStyle.lineHeight =  (item.textStyle.fontSize||16)*eRate + 'px'
    Object.keys(item.textStyle).forEach(key=>{
        let val = item.textStyle[key]
        // item.textStyle.lineHeight =  (item.textStyle.fontSize||16)*eRate + 'px'
        if(key=='fontSize'){
            val = (val*eRate) + 'px'
        }
        sList.push(camelToCss(key) +':'+ val)
    })
    return `style="${sList.join(";")}"`
}

 const renderClass=  {
    renderHeader:function(){
      return !!this.configJson.header
    },
    renderFreeContainer:function(){ // 自由容器模式
      // pageConfigInfo 模版配置信息
      return pageConfigInfo.pageSetting?.bodyContainer=='block'
    },
    renderBody:function(){ // 网格模式
      // return !!this.configJson.body
      return pageConfigInfo.pageSetting?.bodyContainer!='block'
    },
    renderFooter:function(){
      return !!this.configJson.footer
    },
    renderWaybill:function(){ // 面单节点
      return !!this.configJson.items
    },
    createCell: function(){
      return function(text,render){
        // TODO 生成文本显示样式 style , 考虑按列名生成样式
        return `{{#createCell}}${this.prop}{{/createCell}}
        `
      }
      
    },// 生成模版时，提取预制占位符，用于渲染网格数据时，计算合并行
    
    formatCellStyle:function(){
      let textStyle = Object.assign({fontSize:16,textAlign:'center'},this.textStyle||{})
      return function (text, render) {
        return formatHtmlStyle({textStyle: textStyle})
      }
    },
    createElement: function(){
      let item = this
      let elHtml = ''
      //根据上下文，元素配置信息，生成组件元素
      //根据组件类型，创建不同的节点
      // console.log('fking item in createElement',item)
      // https://www.jianshu.com/p/4d0e6a05fbb2  使用{{<prop}}  输出小片段
      if(item._t == 'text'){
        elHtml = `
        <div class="nt-print-item nt-print-text" ${formatHtmlStyle(item)}>${item.v}</div>
        `
      } else if(item._t == 'sys_text'){
        // formatValue 将系统配置变量=[propName] 转换为 mastache 变量{{propName}}
        elHtml = `<div class="nt-print-item nt-print-text" ${formatHtmlStyle(item)}>${item.prefix}${formatValue(item.v)}</div>`
      } else if(item._t == 'rect'){
        elHtml = `<div class="nt-print-item nt-print-rect" ${formatHtmlStyle(item)}></div>`
      } else if(item._t == 'h_line'){
        elHtml = `<div class="nt-print-item nt-print-line" ${formatHtmlStyle(item)}></div>`
      } else if(item._t == 'v_line'){
        elHtml = `<div class="nt-print-item nt-print-line" ${formatHtmlStyle(item)}></div>`
      }
       else if(item._t == 'image'){
        elHtml = `<img src="${formatValue(item.v)}" class="nt-print-item nt-print-image" ${formatHtmlStyle(item)}></img>`
      } else if(item._t == 'barcode'){
        // 条码有配置信息，待核对开发文档
        elHtml = `<bar-code class="nt-print-item" ${formatHtmlStyle(item)} config='{"margin":0,"textMargin":0,"fontOptions":"bold", "displayValue": true, "fontSize":30}' content="${formatValue(item.v)}" width="${item.width}" height="${item.height}" ></bar-code>`
      } else if(item._t == 'qr_code'){
        elHtml = `<qr-code class="nt-print-item" ${formatHtmlStyle(item)} content="${formatValue(item.v)}" width="${item.width}" height="${item.height}" ></qr-code>`
      }

      return function (text, render) {
        return elHtml
      }
    },
    // createContainerElement:function(){ // 二次渲染占位
    //   let item = this
    //   let elHtml = ''
    //   //根据上下文，元素配置信息，生成组件元素
    //   //根据组件类型，创建不同的节点
      
    //   // 二次渲染时，迭代过程，创建元素  ： 渲染逻辑=》 数据源数量=》自由容器的设计元素*****

    //   // 

    //   // https://www.jianshu.com/p/4d0e6a05fbb2  使用{{<prop}}  输出小片段
    //   if(item._t == 'text'){
    //     elHtml = `
    //     <div class="nt-print-item nt-print-text" ${formatHtmlStyle(item)}>{{#parseValue}}${item.v}{{/parseValue}}</div>
    //     `
    //   } else if(item._t == 'sys_text'){
    //     elHtml = `<div class="nt-print-item nt-print-text" ${formatHtmlStyle(item)}>{{#parseValue}}${item.v}{{/parseValue}}</div>`
    //   } else if(item._t == 'rect'){
    //     elHtml = `<div class="nt-print-item nt-print-rect" ${formatHtmlStyle(item)}></div>`
    //   } else if(item._t == 'h_line'){
    //     elHtml = `<div class="nt-print-item nt-print-line" ${formatHtmlStyle(item)}></div>`
    //   } else if(item._t == 'v_line'){
    //     elHtml = `<div class="nt-print-item nt-print-line" ${formatHtmlStyle(item)}></div>`
    //   }
    //    else if(item._t == 'image'){
    //     elHtml = `<img src="${formatValue(item.v)}" class="nt-print-item nt-print-image" ${formatHtmlStyle(item)}></img>`
    //   } else if(item._t == 'barcode'){
    //     // 条码有配置信息，待核对开发文档
    //     elHtml = `<bar-code class="nt-print-item" ${formatHtmlStyle(item)} content="${formatValue(item.v)}" width="${item.width}" height="${item.height}" ></bar-code>`
    //   } else if(item._t == 'qr_code'){
    //     elHtml = `<qr-code class="nt-print-item" ${formatHtmlStyle(item)} content="${formatValue(item.v)}" width="${item.width}" height="${item.height}" ></qr-code>`
    //   }

    //   return function (text, render) {
    //     return elHtml
    //   }
      
    // },
    dsStart:'{{#tableList}}', // TODO 验证直接通过模版输出
    dsEnd:'{{/tableList}}',
    freeContainerPlaceholderStart:'{{#containerItems}}',
    freeContainerPlaceholderEnd:'{{/containerItems}}',
    createElementStart:'{{#createElement}}',
    createElementEnd:'{{/createElement}}',
    // setting:obj.pageSetting, // 页面配置信息
    // configJson:obj.configJson
  }

  let columnIndexMapInfo = {} // 用于渲染业务单据时buildPrintData，根据设计模版配置的列名，转换为对应的列索引，用于控制合并单元格
  let pageConfigInfo = {}
  export function buildDesignerTemplate(pageDesignInfo){
    pageConfigInfo = pageDesignInfo
    // 根据设计器配置信息，生成打印渲染模版
    pageDesignInfo.configJson.body?.columns.forEach((col,index)=>{
        columnIndexMapInfo[col.prop]= index
    })

    // 自由容器的高宽
    let freeContainerWidth = '0px'
    let freeContainerHeight ='0px'
    let fContainer = pageDesignInfo.configJson.freeContainer
    // 提前计算结果
    if(fContainer){ // 自由容器模式
      let gap = (fContainer.columns-1)*fContainer.columnGap/((fContainer.columns))
      freeContainerWidth = `calc(${100.00/(fContainer.columns||1)}% - ${gap}px)`
      gap = (fContainer.rows-1)*fContainer.rowGap/((fContainer.rows))
      freeContainerHeight = `calc(${100.00/(fContainer.rows||1)}% - ${gap}px)`
    }
    
    return  mustache.render(wx_print_mustache,
       Object.assign({},renderClass,{
        pageHeight : pageDesignInfo.pageHeight *10,// 72mm == 203   1mm= 203/72 * (1/25.4)*96*1.3
        pageWidth : pageDesignInfo.pageWidth *10, // 打印机安装72分别率，电脑96，两者差异 1.3，使用该系数进行换算？？
        setting : pageDesignInfo.pageSetting, // 页面配置信息
        configJson : pageDesignInfo.configJson,
        freeContainerWidth,freeContainerHeight
       })
    )
     
  }

  export function buildPrintData(printTemplate, billInfo/*业务单据数据*/, pageData){ // 生成打印数据， 微信视频号，需要进行转码
    let mergeInfo = pageData._printList.mergeInfo
    pageData._printList.data.forEach((item,index)=>item.__index=index) // 创建临时数据行索引
    let freeContainer = pageConfigInfo.configJson.freeContainer||{} // 自由容器
    let outHtml = mustache.render(printTemplate, {
        ...billInfo,
        // details:pageData._printList, // 当前页打印数据
        containerItems: freeContainer.items, //自由容器的设计信息 TODO， 如果为拣货码类型，增加特殊变量_injectItems 植入系统固定模版 TODO
        createCell:function(){
          return function(prop/*text */,render){
            // 通过设计配置信息生成的模版，使用{{#createCell}}列名{{#createCell}} 作为二次业务数据渲染的的模版
            // 参数中prop 即为列名称
            let columnIndex = columnIndexMapInfo[prop] // 通过列名转换列索引，用于判断是否需要合并
            let rowIndex = this.__index
            let rowsCount = pageData._printList.data.length
            
            // 根据列索引，转为列属性，提取值
            let cellValue = this[prop] || ''
            // 根据合计列配置信息，动态计算需要合并的单元格
            let cellStyle = Object.assign({fontSize:'16',textAlign:'center'},this.textStyle||{}) // this=column
            
            if(!mergeInfo?.rows ){ // 无合并信息
                return '<td ' + style +'>'+ cellValue +'</td>'
            }

            let colspan = mergeInfo.end - mergeInfo.start// 跨列数
            
            // 判断合计行，页小计
            let subtotalType = 0 // 0 非合计行 1合计行 2小计行
            let sSetting = pageConfigInfo.configJson.body.subtotalSetting || {} // 合计行配置样式
            
            if(mergeInfo.rows>0 && rowIndex>= rowsCount-2 ){ // 当前页，存在合计信息，仅判断倒数两行
              if(mergeInfo.rows == 1){ // 小计或合计
                subtotalType = sSetting.subPage? 2:1
              } else { // 页小计+合计
                if(rowIndex == rowsCount-1){
                  subtotalType = 1 // 合计行
                } else if(rowIndex == rowsCount-2){
                  subtotalType = 2 // 页小计
                }
              }
            }
            
            if(subtotalType != 0){ // 合计行
              if(mergeInfo.start == columnIndex){ // 合计文本列
                cellStyle = Object.assign(cellStyle,sSetting.textStyle||{}) // 合并文本样式： 页小计+合计保持一致
              } else{ // 合计行内容单元格
                if(subtotalType == 1){ // 合计
                  cellStyle = Object.assign(cellStyle,sSetting.contentStyle||{})
                } else{ // 页小计
                  cellStyle = Object.assign(cellStyle,sSetting.subPageStyle||{})
                }

              }
            }
            let style = formatHtmlStyle({textStyle:cellStyle})
            if(mergeInfo.rows >0 && rowIndex== rowsCount-1){
              if(columnIndex == mergeInfo.start){
                return  '<td colspan="'+ colspan +'"'+ style +'>'+ cellValue +'</td>'
              } else if(columnIndex>mergeInfo.start && columnIndex<mergeInfo.end){
                return  ''
              }
            }
            if(mergeInfo.rows > 1 && rowIndex== rowsCount-2){
              if(columnIndex == mergeInfo.start){
                return  '<td colspan="'+ colspan +'"'+ style + '>'+ cellValue +'</td>'
              } else if(columnIndex>mergeInfo.start && columnIndex<mergeInfo.end){
                return ''
              }
            }
            return '<td ' + style +'>'+ cellValue +'</td>'
          }
        },
        createElement: renderClass.createElement, // 二次渲染创建自由容器的元素
        tableList: function(){
          return pageData._printList.data
        }
      });
      // 后台返回的模版内容存在中文内容，需经过 encodeURIComponent 对中文进行转码，否则直接base64 或 btoa 原生base64 会出现乱码
      console.log('html', outHtml)

      /*
        根据视频号平台的模板报文解析后，按照其格式重新定制自己的打印格式，
        特别注意: 经过测试 options 字段不能为空，否则打印组件直接空白
        模板 https://open.yto.net.cn/linkInterFace/electronicTemplate
        快递单长度换算10px == 1mm
        1pt=0.35146mm
      */
      // 方案二
      // outHtml = '<div class="nt-print-page" style="width:500px;height:300px;border:1px solid #000;">xxxxxx</div>'
      outHtml = JSON.stringify({
        "printData": {
          "innerHtml": outHtml,
        },
        "template": {
          "options": [ // 必须，否则空白
          ],
          "templateUrl": "http://localhost:3000/wx_print_tpl.html",
        }
      })
        
      return Base64.encode(encodeURIComponent(outHtml)) // Base64.encode(output)  btoa(encodeURIComponent(output))
  }
