/**
     1 由于菜鸟组件 模版是通过url指定，必须对外公共获取模版的url 接口
        a. 模版的配置信息来源于设计器，需在返回时，将配置信息注入到模版中,将配置的字段，网格列表显示格式动态生成模版
          生成后的模版配置信息，写入缓存---7天有效，模版发生变更时，清除缓存
        b. 在打印请求时，将数据源通过websocket 传入
        c. 通过模版支持的js 明细，渲染数据（同视频号打印组件的mastcahe的方式）


    ************************************************************************************************************

      2. 将模版配置信息，已数据源的方式传入，通过js 生成打印模版信息，并合业务数据，实现打印效果----优先使用此方式
        优点： 模版无需通过后端动态生成，及缓存管理，通过脚本，交由云打印组件渲染，减少服务器压力
              1. 直接打印配置模版通过七牛云储存，实现cdn加速，服务器压力减少至0 ---（在出现异常时，切换至自己的服务器）
        需验证
          1: 根据设计与配置属性，通过内嵌js 方法 生成配置属性，动态列
          2: 通过vue 样式涉及到的方法，逐一将需要用到的功能 转换为js代码
          4: 通过data 节点，将数据源和模版配置信息，一起传入
              data:{
                ds:{billId:1,details:[]}, //数据源
                tpl:{padding:{},body:{},header:{},footer:{}}, // 模版配置信息---本地缓存
              }
      
*/

import { defineStore } from 'pinia'
// import { printStatusApi } from '@/api/xxx'
// TODO  支持远程打印
export const WS_URL = 'ws://127.0.0.1:13528'
// import { RefNumEnum, WS_URL } from '@/utils/constant'
import { ElMessageBox } from 'element-plus'
import PrintCreator from '@/utils/print_utils'

export const useCainiaoPrintServiceStore = defineStore('cainiaoService', {
  state: () => ({
    ws: null, // 全局存储一个 websoket ，避免重复创建
    isConnected: false, // 判断菜鸟组件是否以及启动
    reconnectInterval: 5000,
    reconnectTimer: null,
    callbackEventObj: {},
    isShowErrBox: false,
    printerList:[] // 打印机列表
  }),
  actions: {
    connect(cb, callbackType) {
      this.callbackEventObj[callbackType] = cb // 打印成功或则失败的回调函数，场景不同用callbackType 来判断对于的回调
      return new Promise((resolve,reject)=>{
        if (this.ws && this.ws.readyState === 1)  return
        this.ws = new WebSocket(WS_URL)
        this.ws.onopen = () => {
          console.log('WebSocket connected')
          
          this.isConnected = true
          resolve(this.isConnected)
        }
        this.ws.onmessage = (event) => {
          console.log('WebSocket message received:', event.data)
          // 处理收到的消息
          this.subscribe(JSON.parse(event.data || ''), cb)
        }
        this.ws.onclose = () => {
          console.log('WebSocket disconnected')
          this.isConnected = false
          this.reconnect(cb, callbackType)
          reject(false) // 连接失败
        }
        this.ws.onerror = (error) => {
          this.isConnected = false
          console.log('连接菜鸟打印组件失败，请检查是否启动:', error)
          // this.reconnect(cb, callbackType)
          reject(false) // 连接失败
        }
      })
      
      
    },
    send(message) {
      if (this.isConnected) {
        this.ws.send(message)
      } else {
        console.warn('WebSocket is not connected')
      }
    },
    close() {
      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
    },
    subscribe(data, callBackFn) {
      switch (data.cmd) {
        case 'getPrinters':
          console.log('获取打印机列表', data.printers)
          if (data.printers) {
            data.printers = data.printers.filter((i) => {
              return i.status == 'enable'
            })
          }
          // 缓存起来
          // localStorage.setItem('PRINTERS', JSON.stringify(data.printers || []))
          this.printerList = data.printers
          break
        case 'getPrinterConfig':
          console.log(data.printer)
          break
        case 'print':
         // console.log(data)
          if (data?.status === 'failed' && !this.isShowErrBox) {
            this.isShowErrBox = true
            ElMessageBox({
              title: 'Warning',
              message: `${data?.msg}`,
              confirmButtonText: 'I know',
              callback: () => {
                this.isShowErrBox = false
              }
            })
          }
          break
        case 'notifyPrintResult':
          console.log('打印通知----',data)
          // if (['printed', 'failed'].includes(data.taskStatus)) {
          //   // 打印成功/失败 回调记录状态Api接口
          //   this.printStatus(data)
          // }
          this.callbackEventObj['print_result']?.(data) // 触发打印回调
          break
        case 'setPrinterConfig':
          console.log('设置打印机配置')
          console.log(data)
          break
      }
    },
    // 这里的data是发送给菜鸟组件的数据按文档要求来，一般有requestID，TaskID等，把自定义的拼接进去
    // async printStatus(data) { // TODO check
    //   // 传参
    //   let curId = data?.requestID.split('#') && data?.requestID.split('#')[0]
    //   console.log('print status : ', data?.requestID)
    //   // await  printStatusApi(curId )
    //   // this.callbackEventObj[markType]()
    // },
    // 这里的重连可以设置一个次数后，停止重连
    reconnect(cb, callbackType) {
      if (!this.reconnectTimer) {
        console.log(`WebSocket will reconnect in ${this.reconnectInterval} ms`)
        this.reconnectTimer = setTimeout(() => {
          console.log('WebSocket reconnecting...')
          this.connect(cb, callbackType).catch(_=>false)
          this.reconnectTimer = null
        }, this.reconnectInterval)
      }
    },
    stopReconnect() {
      if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer)
        this.reconnectTimer = null
      }
    },
    startPrint(printerName,requestID,printConfig, isPreview=fase,printCallback){ // 开始打印  isPreview 预览模式， 此方式，需要配合自定义设计模版进行
      
      if(printCallback){ // 订阅打印结果回调
        this.callbackEventObj['print_result'] = printCallback  //一次有效？
      }
      if(printConfig.templatePlatform == 'SYSTEM'){ // 自定义模版--即咋们自己的系统提供的模版
        this.printCustomData(printerName,requestID,printConfig, isPreview)
      } else { // 电商平台的模版, 直接打印报文
        // 待支持: 针对视频号小店， 返回print info 是，增加返回 自定义 信息--覆盖商品自定义区域 customInfo(需指定模版？自行设计), 面单补充信息 extraData 可覆盖寄件人信息
        let obj = {
          command: isPreview? 'preview':'print', // 预览方式 preview 打印 print
          version: '2.0', // 文档要求必填，注意
          requestID: requestID, 
          printType: 1, // 正式使用视频号打印必须 1  
          printer: printerName,
          taskList: [{
            taskID: requestID, // String, 调用方保证唯一
            printInfo: printConfig.printData.replaceAll('"',''), // String, [获取打印报文]接口返回的print_info
          }]
        }      
        this.send(JSON.stringify(obj))
      }
      
    },
    async printCustomData(printerName,requestID,printConfig, isPreview){ // 自定义打印，加载自定义模版+合并业务数据

      let printCreator = new PrintCreator()
      let detailDatasource = []
      if(printConfig.listNode){ // 指定打印对象的网络列表数据源
        detailDatasource  = printConfig.printData[printConfig.listNode] || [] // 提取打印数据中网络列表指定数据源
      }
     
      let printData = await printCreator.createPrintPageData(printConfig.templateId,printConfig.printData/*业务数据*/,detailDatasource/*网格列表数据源 */)
      

      let documentList = []
      let mainData = {}
      Object.keys(printData).filter(key=> !key.startsWith('_')).forEach(key=>{
        mainData[key] = printData[key]
      })
      console.log(printData._tpl)
      printData._pages.forEach((pageData,index)=>{
        // 拣货标签，设置特殊标记，强制将模版tpl，替换为 系统级内嵌模版, tpl 直接替换为 系统内置模版？？？
        // 针对一页打印多个数据时，模版是通一个，无法区分
        // freeContainer.items 在模版中，通过识别特殊打印内容，强制 增加特殊_injectItems 实现内置固定模版---拣货
        if(printData._tpl.configJson.freeContainer){
          // 根据特殊表里 替换内嵌模版
          printData._tpl.configJson.freeContainer.injectItems = [{"_t":"sys_text","v":"=[marketName]","width":188,"height":45,"left":0,"isPrinted":true,"id":1727927471213,"top":8,"label":"市场","prefix":"","demoText":"市场","textStyle":{"textAlign":"center","fontSize":32,"fontWeight":"bold"}},{"_t":"sys_text","v":"=[position]","width":186,"height":37,"left":2,"isPrinted":true,"id":1727927671021,"top":60,"label":"档口","prefix":"","demoText":"档口","textStyle":{"textAlign":"center","fontWeight":"bold","fontSize":28}}]
        }
        let currentPageData = {...mainData,_printList:pageData,_tpl: printData._tpl}
        // 菜鸟直接通过远程模版生成数据
        documentList.push({
          "documentID": requestID + '-' + index,
          "contents": [
            {
            "data": {
              ...currentPageData
            },
            "templateURL": "http://wx-shop.saas-plus.cn/cainiao_test.xml" // "http://www.ntxx.com:9999/jshERP-boot/template/cnTesting" //TODO 拼接模版id
          }]
        })
      })

      let obj = {
        "cmd": "print",
        "requestID": requestID,
        "version": "1.0",
        "task": {
          "taskID": requestID,
          "preview": isPreview, // 预览 套打模式
          "printer": printerName, // "Microsoft Print to PDF", // "Microsoft XPS Document Writer",
          "previewType": "pdf",
          // "firstDocumentNumber": 1,
          // "totalDocumentCount": pageCount,
          "documents": documentList
        }
      }
      this.send(JSON.stringify(obj))
    }
  
    // startPrint(printerName,orderId,printData, isPreview=fase ){ // 开始打印  isPreview 预览模式
    //   let documentList = []
    //   let mainData = {}
    //   Object.keys(printData).filter(key=> !key.startsWith('_')).forEach(key=>{
    //     mainData[key] = printData[key]
    //   })
    //   printData._pages.forEach((pageData,index)=>{
        
    //     let currentPageData = {...mainData,_printList:pageData,_tpl: printData._tpl}
    //     console.log('currentPageData', currentPageData,index)
    //     documentList.push({
    //       "documentID": orderId + '-' + index,
    //       "contents": [
    //         {
    //         "data": {
    //           ...currentPageData
    //         },
    //         "templateURL": "http://www.ntxx.com:9999/jshERP-boot/template/cnTesting"
    //       }]
    //     })
    //   })
    //   // return
    //   let obj = {
    //     "cmd": "print",
    //     "requestID": orderId,
    //     "version": "1.0",
    //     "task": {
    //       "taskID": orderId,
    //       "preview": isPreview, // 预览 套打模式
    //       "printer": printerName, // "Microsoft Print to PDF", // "Microsoft XPS Document Writer",
    //       "previewType": "pdf",
    //       // "firstDocumentNumber": 1,
    //       // "totalDocumentCount": pageCount,
    //       "documents": documentList
    //     }
    //   }
    //   console.log('fdfdkffing', obj)
    //   this.send(JSON.stringify(obj))
    // }
  }
})

