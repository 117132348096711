<!-- 
  订单同步操作区域
    1.首次进入： 显示同步按钮
    2.点击同步：显示正在同步中（进行秒数）
    3. 订单同步完成： 显示订单 已同步完成+ ！ 提示查看同步结果==》自动弹窗， + 提示刷新列表
    4. 同步完成后，5s后，切回 同步 按钮  + ！提示上次同步结果

    // 发起同步后，轮询本次同步结果， 按时间  按订单号列表
    // 弹窗显示最近商店同步时间
  -->
<template>
  <div class="" style="display: inline-flex; column-gap: 2px;align-items: center;">
    <el-button link type="primary"  v-if="!isSync" @click="onStartClick" >订单同步
      <span v-if="showRefresh" style="color:var(--el-color-danger)" @click.stop="onRefreshClick">(刷新)</span>
    </el-button>
    <div v-if="isSync" class="display-flex" style="font-size:12px;column-gap: 2px;">
      <el-icon class="is-loading" ><Loading /></el-icon><span>订单同步中{{ counting }}</span>
    </div>
    <!-- 同步已完成，倒计时5s自动关闭 -->
    <!-- <div v-if="!isSync && counting>0" style="font-size:12px;">同步已完成({{ counting }}) <el-button link type="primary">刷新</el-button></div> -->
    <!-- 非同步阶段，且存在最近一次同步结果显示提示icon  网络异常提示：警告icon WarningFilled  -->
    <el-popover ref="refTips" placement="top" :width="250" trigger="click" v-if="!isSync && lastSyncInfo">
      <template #reference>
        <div style="line-height: 14px;margin-left:-2px;"><el-icon><CircleCheckFilled color="var(--el-color-success)" v-if ="lastSyncInfo.success" /> <WarningFilled color="var(--el-color-danger)" v-else/> </el-icon></div>
      </template>
      <div class="padding">
        <div>{{ lastSyncInfo.message }}</div>
        <div>{{ lastSyncInfo.syncTime }}</div>
      </div>
    </el-popover>
    
  </div>
  <el-dialog
    v-model="isVisible" destroyOnClose width="500" append-to-body
    class="nt-resource-dialog"
    title="订单同步"
    :close-on-click-modal="false"
    @close="isVisible=false"
  >
    <setting-page v-if="isVisible" @cancel="isVisible=false" @success="onSuccessHandler"></setting-page>
  </el-dialog>
</template>

<script>

import {submitOrderSyncTask,queryTaskResult} from '@/service/print_order/order'
import SettingPage from './sync_setting'
export default {
  data() {
    return {
      isSync:false, // 同步进行中
      showRefresh:false, // 成功同步后，显示刷新按钮
      lastSyncInfo: null, // 最近一次同步结果
      counting:0, // 同步计时
      isVisible:false
    }
  },
  emits:['refresh','change'],
  components: {SettingPage},
  methods: {
    onStartClick(){
      this.isVisible = true
    },
    async onSuccessHandler(queryVo){
      this.isVisible =false
      this.isSync = true
      this.counting = 1
      let res = await submitOrderSyncTask(queryVo)
      if(res.code == 200){
        this._timer =  setInterval(_ =>this.counting++,1000) // 倒计时
        // 触发结果查询
        let taskList = res.data
        setTimeout(async _=> this.onQueryResultHandler(taskList), 2000) // 延迟2s 开始查询结果 一旦出现异常，可能崩溃
      } else {
        this.isSync = false
        this.counting = 0
        this.$message.error(res.msg || res.data || '同步失败')
      }
      
    },
    onRefreshClick(){
      this.showRefresh=false
      this.$emit('refresh') // 通知宿主刷新列表
    },
    async onQueryResultHandler(taskList){
      let res = await queryTaskResult({taskIds:taskList.join(',')}).catch(e=>{
        return "timeout" // 优化
      })

      if(res==='timeout' || res.code ===-9999){ // 请求超时，无结果，重新发起，前端请求超时异常
        this.onQueryResultHandler(taskList) // TODO 控制最大查询时间，直接弹窗提示-请刷新列表
      } else if(res.code ==200){ // 获取到生成结果
        res.data.forEach(item=>{
          let matchIndex = taskList.indexOf(item.taskId)
          if(matchIndex !=-1){
            taskList.splice(matchIndex,1) // 移除返回的task
          }
        })
        if(taskList.length>0){ // 部分任务尚未返回，进入下一轮
          this.onQueryResultHandler(taskList)
          return
        }
        this.isSync =false
        this.showRefresh = true // 结果获取后，显示刷新按钮
        this.counting = 0
        this._timer && clearInterval(this._timer)
        let resultJson = res.data // 任务执行结果
        this.lastSyncInfo={
          message: resultJson.msg,
          syncTime:'2020-10-10',
          success:true
        }
        this.$notify({
          title: '订单同步已完成',
          type:'success',
          duration:30*1000,
          dangerouslyUseHTMLString: true,
          message: this.lastSyncInfo.message,
          position: 'top-right'
        })
          
      } // TODO 网络异常-- 中断轮询 -- 尚未完成的，显示“网络异常，刷新后重试
    },
  }
}
</script>