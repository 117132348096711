

import { getEntity, getEntityByPosition, getPageEntityList, getEntityList, saveEntity, updateEntityStatus } from '@/service/resource/general'
import { EnumEntityType } from '@/utils/enum_utils'
import formValidator from '@/utils/formValidator' // 通过调用 formValidator('required|max(10)','自定义消息') 生成表单项校验规则
import * as dayjs from "dayjs"
import { createBillNo } from '@/service/resource/general'
export const DataEditMixin = {
  data() {
    return {
      EnumEntityType,
      viewOnly: this.readOnly,
      billType: null,
      storeList: [],
      formModel: {},
      isLoading: false,
      isSubmitting: false,
      igoreBillNo:true // 是否忽略单据号
    }
  },
  emits: ['cancel', 'success'],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    height:{ // 查看单据时，弹窗高度
      type: Number
    }
  },
  async mounted() { // 缓存后，第一次创建时，mounted 跟created 只执行一次
    this._initialize && (await this._initialize()) // 触发初始化事件
    if(this.$route.meta.refBill?.billType){
      // console.log('mouted refIbll',this.$route.meta.refBill?.billType)
      this.isLoading = true
      this._createByRefBill && (await this._createByRefBill(this.$route.meta.refBill)) // await 等待同步结果，再解除loading状态
      if(!this.igoreBillNo && !this.formModel.billNo){ // 生成单据号
        let res = await createBillNo({billType:this.billType})
        if(res.code === 200){
          this.formModel.billNo = res.data
        }
      }
      this.$route.meta.refBill = null // 处理完成后清空引用信息，避免routeview切换时，再次触发
      
      this.viewOnly = false // 进入新增状态
      // 使用timeout， 进入执行队列，等待当前方法执行结束，主要目的保证页面实现的activated 优先执行
      setTimeout(_=>this.isLoading = false)
    }
    else if(!this.$route.meta.refBill?.id){ // 非引单生成模式进入页面组件时，按主菜单模式进入 新增，或 加载特定单据页面
      this.loadEntity()
    }
  },
  async activated(){ // 当前业务单据打开并已缓存时，触发该事件
    // console.log('activated', this.$route)
    if(this.$route.meta.refBill?.id){
      // 页面创建，再次进入激活，并传入指定参数时， 如在打开采购入库后，通过订单详情-》进行入库操作时，
      // 再次激活页面，并以新增的方式，重新加载引用单据,
      this.isLoading = true
      this._createByRefBill && (await this._createByRefBill(this.$route.meta.refBill)) // await 等待同步结果，再解除loading状态
      if(!this.igoreBillNo && !this.formModel.billNo){ // 生成单据号
        let res = await createBillNo({billType:this.billType})
        if(res.code === 200){
          this.formModel.billNo = res.data
        }
      }
      this.$route.meta.refBill = null // 处理完成后清空引用信息，避免routeview切换时，再次触发
      
      this.viewOnly = false // 进入新增状态
      // 使用timeout， 进入执行队列，等待当前方法执行结束，主要目的保证页面实现的activated 优先执行
      setTimeout(_=>this.isLoading = false) 
    }
  },
  methods: {
    formValidator,
    getEntity, getEntityByPosition,
    saveEntity, getPageEntityList,
    getEntityList, updateEntityStatus,//公共查询实体，保存实体范围
    
    formatFenTotal(total) { // 格式化金额：分
      return ((total || 0) / 100).toFixed(2)
    },
    formatDate(dt, fmt = 'YYYY-MM-DD HH:mm:ss') {
      return dayjs(dt).format(fmt)
    },
    formatShortDate(dt) {
      return this.formatDate(dt,'YYYY-MM-DD')
    },
    async loadEntity(params) {
      this.isLoading = true

      if (this._loadEntity) {
        let res = await this._loadEntity(Object.assign({}, params))
        if (res) {
          // 判断是否需要创建单据号
          this.formModel = res.data
          if(!this.igoreBillNo && !this.formModel.billNo){ // 生成单据号
            res = await createBillNo({billType:this.billType})
            if(res.code === 200){
              this.formModel.billNo = res.data
            }
          }
        }
      }
      this._loadFinished && this._loadFinished()
      setTimeout(_ => this.isLoading = false, 0)
    },
    async onSubmitClick(newAfterSave = false/*保存后进入新增状态 */) { // 保存
      if (this._checkSubmit && ! await this._checkSubmit()) {// 数据校验失败
        this.$message.error("数据校验失败，请检查")
        return
      }
      this.isSubmitting = true
      if (this._saveEntity) {
        let res = await this._saveEntity()
        if (res && res.code == 200) {
          this.$message.success("保存成功")
          if (!newAfterSave) {
            this.formModel = res.data // 重新加载保存后详情信息
            this._loadFinished && this._loadFinished()
            this.$emit("success", res.data)
          } else { // 进入新增状态
            this.onAddClick && this.onAddClick()
          }
        } else {
          this.$message.error(res.msg || res.data)
        }
      } else {
        this.$message.error("详情页尚未实现接口方法_saveEntity");
      }
      this.isSubmitting = false
    },
    onCancelClick() {
      this.$emit("cancel")
    },

    async initStore() {
      let res = await getEntityList(EnumEntityType.STORE, {})
      this.storeList = res.data
    },
  }
}
