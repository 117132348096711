
<!-- 
  文本样式编辑组件：
    字体大小，粗细，位置，斜体 下划线，删除线
 -->
<template>
  <div class="display-flex space-between" style="width:100%">
    <el-select v-model="this.formData.fontSize" @change="this.onItemChangeHandler" style="flex: 1 1 10px" v-if="!hidden.includes('size')">
      <el-option :label="item" :value="item" v-for="item in [10,11,12,13,14,15,16,18,20,22,24,28,32,40,48,70]" :key="'size_'+item"></el-option>
    </el-select>
    <el-checkbox-button fill="#999" text-color="#666" v-model="formData.fontStyle" true-value="italic" false-value="normal"  
      @change="onItemChangeHandler" v-if="!hidden.includes('italic')">
      <i class="iconfont icon-italic" style="font-size:10px;" ></i>
    </el-checkbox-button>
    <el-checkbox-button fill="#999" text-color="#666" v-model="formData.fontWeight" true-value="bold" false-value="normal"  
      @change="onItemChangeHandler" v-if="!hidden.includes('weight')">
      <i class="iconfont icon-cuti" style="font-size:10px;" ></i>
    </el-checkbox-button>
    <el-radio-group fill="#999" v-model="formData.textAlign" v-if="!hidden.includes('align')"  @change="onItemChangeHandler" type="info">
      <el-radio-button label="left"  ><i class="iconfont icon-zuoduiqi" style="font-size:10px;" ></i></el-radio-button>
      <el-radio-button label="center"  ><i class="iconfont icon-juzhongduiqi" style="font-size:10px;" ></i></el-radio-button>
      <el-radio-button label="right" ><i class="iconfont icon-youduiqi"  style="font-size:10px;"></i></el-radio-button>
    </el-radio-group>
    <!-- <el-space class="margin-top">
      <el-radio-group v-model="formData.textDecoration" size="small" @change="onItemChangeHandler">
        <el-radio-button label="none" >无</el-radio-button>
        <el-radio-button label="underline" >下划</el-radio-button>
        <el-radio-button label="line-through" >删除</el-radio-button>
      </el-radio-group>
    </el-space> -->
  </div>
</template>

<script >

export default {
  data () {
    return {
      formData: Object.assign({},this.modelValue)
    }
  },
  emits: ['update:modelValue'], // v-model  配套使用  modelValue
  props:{
    modelValue:{},
    hidden:{
      type: Array,
      default:_=>[]
    }
  },
  watch:{
    modelValue:function() {
      this.formData =Object.assign({},this.modelValue)
    }
  },
  methods:{
    // onAlignChange(pos){
    //   this.formData.textAlign = pos
    //   this.$emit('update:modelValue',this.formData)
    // },
    onItemChangeHandler(val){
      this.$emit('update:modelValue',this.formData)
    }
  }
}
</script>