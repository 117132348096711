<!-- 模板管理 -->
<template>
  <el-container class="padding nt-height-100">
    <el-aside width="200px" class="margin-right-8 nt-box-panel nt-bg-white">
      <el-menu
        :default-active="defaultMenu"
        class="nt-height-100"
      >
        <el-menu-item :index="item.key" v-for="item in options" @click="onPageChangeClick">
          <el-icon></el-icon>
          <span>{{item.label}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main class=" no-padding" >
      <component :is="dynamicPage" :key="defaultMenu"></component>
    </el-main>
  </el-container>
</template>
<script>
import {markRaw} from 'vue'
import PickingCodeTemplatePage from '@/views/template'
import PickTemplatePage from '@/views/template'
import DispatchTemplatePage from '@/views/template'
import WaybillTemplatePage from '@/views/waybill_template/platform'
const _page_container = {
  WaybillTemplatePage:markRaw(WaybillTemplatePage),
  PickingCodeTemplatePage:markRaw(PickingCodeTemplatePage),
  DispatchTemplatePage:markRaw(DispatchTemplatePage),
  PickTemplatePage:markRaw(PickTemplatePage)
}
export default {
  name: "TEMPLATE_INDEX",
  components: {
  },

  data() {
    return {
      dynamicPage:null,
      defaultMenu:null,
      options:[
        {label:'平台面单模板',key:'WaybillTemplatePage'},
        {label:'拣货标签模板',key:'PickingCodeTemplatePage'},
        {label:'备货模板',key:'PickTemplatePage'},
        {label:'发货单模板',key:'DispatchTemplatePage'},
        // {label:'自定义面单',key:'RejectAreaPage'},
      ]

    }
  },
  mounted(){
    this.defaultMenu=this.$route.meta.key
    this.dynamicPage = _page_container[this.defaultMenu]
  },
  methods: {
    onPageChangeClick(cmd){
      this.defaultMenu = cmd.index
      let templateType = {
        PickingCodeTemplatePage:'PICKUP_TEMPLATE',
        DispatchTemplatePage:'DISPATCH_TEMPLATE',
        PickTemplatePage:'PICK_GOODS_TEMPLATE'
      }
      // 由于模板template.vue 根据路由的 this.$route.meta.templateType 加载不同类型,此处特殊处理,强制修改路由的模板类型
      this.$route.meta.templateType = templateType[cmd.index]

      this.dynamicPage = _page_container[cmd.index]
    }
  }
}
</script>
<style lang="less" scoped>
  :deep(.el-menu-item){
    padding:0px;
  }
  :deep(.el-menu-item.is-active){
    background:var(--el-color-primary-light-9);
    border-left:5px solid var(--el-color-primary);
  }
  .fking>section{
    padding:0px !important;
  }
</style>