import * as XLSX from 'xlsx-js-style' // https://github.com/aibujin/xlsx-js-style
// https://www.npmjs.com/package/xlsx-js-style
import { template_config } from './excel_config'

let borderStyle = {// 边框样式
  border: {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  }
}

export const createWorksheet = function (configKey, datasource,dynamicCols){
  if(dynamicCols &&dynamicCols.length>0){ // 传入动态列表
    //替换动态行位置，找到特定标记的列
    let matchCol = template_config.columns.find((col,index)=>{
      if(col.isDynamic){
        col._index = index //用于判断所在的索引
        return true
      }
    })
    if(matchCol){
      // 替换 dynamicCols
      template_config.columns.splice(matchCol._index,1,...dynamicCols)
    }
  }

  let colunmLength = template_config.columns.reduce((pre,cur) => pre + (cur.colSpan || 1), 0)
  let headerRowsCount = getMaxDepth(template_config.columns)//计算最深的节点层
  let headerGroup = new Array(headerRowsCount)// 默认一个标题行
  for (let index = 0; index < headerGroup.length; index++) {// 对标题行的标题进行初始化
    headerGroup[index] = new Array(colunmLength)
    // 自动填充所有的边框样式 (采用f 所有的对指向同一个对象，导致数据会全部指向相同的内容)
    for (let childIndex = 0; childIndex < colunmLength; childIndex++) {
      headerGroup[index][childIndex] = { v: '', s: template_config.headerStyle } // 逐一初始化:注意v 为空时，样式不生效
    }
  }
  
  const worksheet = XLSX.utils.aoa_to_sheet([]);///XLSX.utils.json to sheet(rows);// aoa_to_sheet 纯数组内容
  // 合并单元格，先创建合并属性
  worksheet["!merges"] = []
  worksheet["!cols"] = new Array(colunmLength)

  // 写入标题:
  let cellTitle = { v: template_config.label, s: template_config.titleStyle }
  XLSX.utils.sheet_add_aoa(worksheet, [[cellTitle]], { origin: 'A1' });// r:0 首行，{c:0,r:0}
  worksheet["!merges"].push({ s: { c: 0, r: 0 }, e: { r: 0, c: colunmLength - 1 } })// 合并标题

  let lastRowIndex = headerGroup.length // 标题行后的起点
  let initExcelHeader = function (columns, rowIndex, colIndex) {
    rowIndex = rowIndex || 0 // 默认第一个标题行
    colIndex = colIndex || 0
    let rowHeader = headerGroup[rowIndex]

    columns.forEach(col => {
      rowHeader[colIndex].v = col.label
      worksheet["!cols"][colIndex] = { wpx: (col.width || 70) }// 设置列宽  wch  字符数  wpx 像素

      if (col.colSpan > 1) { // +1 标题行占一行 rowIndex + 1
        worksheet["!merges"].push({ s: { c: colIndex, r: rowIndex + 1 }, e: { r: rowIndex + 1, c: colIndex + (col.colSpan||1) - 1 } })// 根据colSpan进行标题合并
      } else if (headerGroup.length > 1) { //多标题，合并行
        worksheet["!merges"].push({ s: { c:colIndex, r: rowIndex +1 }, e: { r: lastRowIndex, c:colIndex } })
      }

      if ((col.colSpan || 1) > 1 && col.columns?.length) {// 当前列需合并单元格-横向，且定义子列表集合
        initExcelHeader(col.columns, rowIndex + 1, colIndex)// 每次处理子columns，进入下一行标新
      }
      
      colIndex += (col.colSpan ||1)// 处理下一列
    })
  }

  initExcelHeader(template_config.columns)
  XLSX.utils.sheet_add_aoa(worksheet, headerGroup, { origin: { c: 0, r: 1 } });// r:0 标题行1开始
  
  // 根据列表数据源属性映射，将结果集转为 纯数据数组
  let arColums = flatAllNestedColumn(template_config.columns)
  //提取subtotal，summary小计，合计，行索引集合，用户标准背景色
  let excelData = []
  let arDataIndex = arColums.map(item => item.dataIndex)
  datasource.forEach((item, rowIndex) => {
    let rowData = new Array(arDataIndex.length)
    // 根据行特殊标记，失败subtotal summary 合计行提取后用于标注行字体，背景色
    arDataIndex.forEach((key,index)=> {
      let columnConfig = arColums[index]
      let cellValue = ''
      if (typeof columnConfig.dataIndex === 'function') {
        cellValue = columnConfig.dataIndex(item, rowIndex)// 函数类型 调用含税转换结果
      } else {// 字符串，校验嵌套结果调用
        cellValue = getTargetValue(item, key)
      }
      // 判断是否需要格式化，数据格式化:字符串，或格式化函数 TODO
      let style = Object.assign({}, borderStyle, columnConfig.textStyle)// 读取列上定义的样式对象:
      rowData[index] = Object.assign({}, { v: cellValue || '', s: style })// 普通内容单元格样式
    })

    excelData.push(rowData)
    
  })
  XLSX.utils.sheet_add_aoa(worksheet, excelData, { origin: { c: 0, r: lastRowIndex + 1 } })// r:0 首行
  //根据subtotal summary设置合计行背景色 TODO
  return worksheet
}

const getTargetValue = function (obj, fieldName) {
  if (!fieldName) {
    return
  }
  return fieldName.split('.').reduce((pre, cur) => {
    if (!pre) {
      return null
    }
    return pre[cur]
  }, obj)
}

// 将最底层的列表，转化为一维列集合
const flatAllNestedColumn = function (columns) {
  if (!columns || columns?.length == 0) {
    return []
  }

  let list = columns.reduce((pre, cur) => {
    if ((cur.colSpan || 1) > 1) {
      pre.push(...flatAllNestedColumn(cur.columns))
    } else {
      pre.push(cur)
    }
    return pre
  }, [])

  return list
}

const getMaxDepth = function (columns) {
  let maxDepth = 0;
  function traverse(cols, depth) {
    if (!cols || cols.length == 0) {
      return
    }
    if (depth > maxDepth) {
      maxDepth = depth;
    }
    cols.forEach(col => {
      traverse(col.columns, depth + 1)
    })
  }
  traverse(columns, 1);
  return maxDepth
}