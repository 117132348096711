
<template>
  <el-container class=" nt-height-100 nt-custom">
    <el-aside width="300px" class="margin-right-8 padding-right-8">
      <el-form label-width="70px">
        <nt-form-item label="面单模板">
          <NtWaybillTemplate @change="onTemplateChange" v-model="waybillTemplate"></NtWaybillTemplate>
        </nt-form-item>
        <nt-form-item label="打印机">
          <nt-printer placeholder="请选择打印机" v-model="printerName"></nt-printer>
        </nt-form-item>
        <nt-form-item label="标签条码">
          <el-input :disabled="!waybillTemplate && !printerName" @keyup.enter="onPickCodeScanEnter" placeholder="请扫描拣货标签条码" v-model="orderPackageId"></el-input>
        </nt-form-item>
        <nt-form-item>
          <el-checkbox v-model="autoDispatch" >打印后自动发货</el-checkbox>
          
        </nt-form-item>
      </el-form>
      <el-descriptions v-if="scanPickInfo"
        title="当前扫描结果" border  style="margin-top: 20px" :column="1" >
        <el-descriptions-item  label="订单号">
          <div style="width:170px;">
            {{ scanPickInfo?.orderNo}}
          </div>
        </el-descriptions-item>
        <el-descriptions-item  label="标签条码">{{ scanPickInfo?.id }}</el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <el-image style="width: 65px; height: 65px;" :src="scanPickInfo?.goodsThumb"/>
          </template>
          <div style="width:170px;">
            <div><span>{{ scanPickInfo?.goodsName }}</span></div>
            <div><span>{{ scanPickInfo?.skuAttrs }}</span></div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="商品ID">{{ scanPickInfo?.goodsId }}</el-descriptions-item>
        <el-descriptions-item label="商品条码">{{ scanPickInfo?.barcode }}</el-descriptions-item>
      </el-descriptions>
    </el-aside>
    <el-main class="no-padding">
      <el-container class="nt-height-100">
        <el-main class="no-padding">
          <PickingOrder v-model="datasource" :orderPackageId="scanPickInfo?.id" class="nt-height-100" @select-change="onPackageSelectChange"></PickingOrder>
        </el-main>
        <el-footer class="no-padding no-margin height-auto align-center margin-top-8">
          <SettingPage></SettingPage>
          <el-button type="primary" @click="onWaybillPrintClick">打印快递单</el-button>
          <el-button type="success" @click="onDispatchClick">发货</el-button>
          <el-button type="danger" @click="onCleanClick">清空</el-button>
        </el-footer>
      </el-container>

    </el-main>
  </el-container>
</template>
<script>
/**
         前端处理： 将订单包裹转为相当合理的格式
  * packList:[ // 拣货列表  --虚拟vo-----------------OrderWaybillPackageVo===订单包裹虚拟类
    {
      packageNum:1, // 
      waybillNo:'xxxxx', // 面单号
      waybllStatus:'success' // 面单状态
      isDispatch:1 // 是否已发货
      printCount:1 // 打印次数
      quantity:10 // 当前报告总数量
      pickQuantity: 5// 当前包裹已备货数量
      orderPackageList:[ // 包裹中包含的商品明细 
        {
          goodsName:'xxx',
          sku:'xxxx',
          quantity: 1 // 商品数量
          pickingCode:'xxxx'
          isScan :1
          printCount: 1 // 拿货标签打印次数
        }
      ]
    }
  ]
  */
import WaybillTemplateSelector from '@/views/print_order/waybill_template_selector'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtPrinter from '@/views/plugin/nt_printer'
import NtWaybillTemplate from '@/views/plugin/nt_waybill_template'
import { getOrderByOrderPackageId, getOrderPackageByPackageId } from '@/service/print_order/picking'
import PickingOrder from './picking_order'
import NtWaybillCreatorManager from '@/utils/waybill_creator_manager' // 面单生成管理器
import NtPrintManager from '@/utils/print_manager' // 打印管理器
import NtWaybillDispatchManager from '@/utils/waybill_dispatch_manager' // 面单发货管理器
import SettingPage from './picking_code_setting'
import { updatePrint} from '@/service/print_order/platform_waybill'
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  name: "scan_picking_code",
  mixins: [DataListMixin],
  components: {
    WaybillTemplateSelector,
    NtFormItem,
    NtPrinter,
    NtWaybillTemplate,
    PickingOrder,
    SettingPage
  },
  data() {
    return {
      selectedPackageList: [], // 已勾选包裹
      printerName:null,
      waybillTemplate:null,
      orderPackageId:null,
      autoDispatch:true, // 打印后自动发货
      scanPickInfo:null,// 扫描后，当前包裹信息
    }
  },
  computed: {
    scanSetting: function () {
      let config = LoginUserStore().getConfig('PICKING_CODE')
      return JSON.parse(config?.configValue||null) || {scan:['auto_ready'],print:['all_pick_ready']} // 默认扫码后囤货， 备齐后自动打印
    },
  },
  created() {
    this._dispatchManager = new NtWaybillDispatchManager((list, result) => {
      // 注意：发货参数为  orderId  waybillNo  
      if (result.type == 'success') { // 发货成功
        list.forEach(item => {
          let packageInfo = this.getPackageInDatasource(item.orderId, item.packageNum)
          if (packageInfo) {
            if(item.success){
              packageInfo.processStatus = null // 结束正在进行中状态
              packageInfo.isDispatch = 1 // 已发货成功
            } else{
              packageInfo.processStatus = -1 // 发货失败
              packageInfo.errorMsg = item.message
            }
            
          }
        })
      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        this.$message.error(result.msg)
      } else if (result.type == 'overTime') {
        // 查询发货结果超时
      }
    })

    this._printManager = new NtPrintManager((result,data /*打印结果：单个，全部完成为数组 */) => {
      if(result.type =='printed'){ // 单个文档打印完成
        let arPackInfo = data.requestID.split('-') // orderId  + packageNum
        let packageInfo = {orderId:arPackInfo[0],packageNum:arPackInfo[1]}
        let matchItem = this.getPackageInDatasource(packageInfo.orderId, packageInfo.packageNum)
        if (!matchItem) {
          return
        }
        packageInfo.processStatus = null // 打印完成， 判断是否异常，显示异常消息
        // 判断是否打印后自动发货，如true，进入发货流程
        if (this.autoDispatch) {
          packageInfo.processStatus = 3 // 正在发货
          this._dispatchManager.dispatchWaybill([{ orderId: packageInfo.orderId, packageNum: packageInfo.packageNum, waybillNo: packageInfo.waybillNo }])
        }
      } else if(result.type =='done'){// 全部打印完成，注意存在多次批次打印，如面单，每次可能只返回n面单生成结果，通过轮询不断触发
        let printSuccessList = []
        data?.forEach(item=>{
          if(item.success){ //进处理队列中已成功的打印数据
            let arPackInfo = item.requestID.split('-') // orderId  + packageNum
            printSuccessList.push({orderId:arPackInfo[0],packageNum:arPackInfo[1]})
          }
        })
        // 打印结束--- // 更新订单已打印成功
        if(printSuccessList.length>0){
          updatePrint(printSuccessList)
        }
      }
      
    })
    // true ==是否需要返回打印报文
    this._waybillManager = new NtWaybillCreatorManager( true, (list, result) => {
      // list 为面单创建结果--含打印报文  或 包裹列表--异常时
      // 根据result.type 区分  create=面单创建请求 overTime="面单结果查询超时" success 面单创建成功
      if (result.type == 'success') {
        // 根据结果，判断是否满足打印, 根据list
        let printObjectList = []
        list.forEach(item => {
          let packageInfo = this.getPackageInDatasource(item.orderId, item.packageNum)
          if (packageInfo) {
            if(result.success){
              packageInfo.processStatus = 2 // 进入打印流程
              packageInfo.waybillNo = item.waybillNo  // 获取面单号
            } else{
              packageInfo.processStatus= -1 // 操作失败
              matchItem.errorMsg = item.message
            }
            item.requestID = `${item.orderId}-${item.packageNum}`
            printObjectList.push(item)
          }
          this._printManager.print(this.printerName, false, printObjectList)
        })

      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        this.$message.error(result.msg)
      } else if (result.type == 'overTime') { // 查询面单结果超时

      }
    })
  },
  mounted(){
    if(this.waybillTemplate){ // 默认打印机
      this.printerName= this.waybillTemplate.printerName
    }
  },
  methods: {
    /*订单数据转为页面支持的格式
      将完整的订单信息中的包裹信息，重新封装
        1. 以支持包裹商品及图片的长势
        2. 包裹商品关联waybill信息
    */
    converToOrderWithPackageList(order) {
      let packInfos = {}
      order.orderPackageList.forEach(el => {
        let match = order.orderItemList.find(child => child.id == el.orderItemId)
        if (match) {
          el.goodsName = match.goodsName
          el.sku = match.sku
          el.goodsThumb = match.goodsThumb
          el.skuAttrs = JSON.parse(match.skuAttrs || '[]').map(el => el.attr_key + ":" + el.attr_value).join(',')
        }
        if (packInfos[el.packageNum]) {
          packInfos[el.packageNum].list.push(el)
        } else {
          packInfos[el.packageNum] = { list: [el] }
        }

      })
      let packageList = []
      Object.keys(packInfos).forEach(packNum => {
        let waybill = order.waybillList?.find(waybill => waybill.packageNum == packNum)
        let quantity = 0 // 当前包裹总商品数量
        let pickQuantity = 0 // 当前包裹累计备货数量
        let orderPackageList = packInfos[packNum].list
        // 汇总包裹中已拣货的汇总信息  包裹商品总数量  ， 已备货数量
        orderPackageList.forEach(item => {
          quantity += item.quantity
          pickQuantity += (item.isScan ? item.quantity : 0)
        })
        let packInfo = Object.assign(
          { 
            orderId: order.id, 
            buyerRemark: order.buyerRemark, // 记录包裹的卖家，卖家 备注
            merchantRemark: order.merchantRemark, // 记录包裹的卖家，卖家 备注
            packageNum: packNum, 
            processStatus: null, 
            quantity: quantity, 
            pickQuantity: pickQuantity,
            orderPackageList: orderPackageList,
            waybill:waybill
          }
        )
        

        packageList.push(packInfo)
      })

      delete order.orderPackageList
      order.packageList = packageList
      return order
    },
    //在数据源中查找是否存在拣货信息
    getPickItemInDatasource(orderPackageId) {
      let matchPickItem = null
      forOrder: for (let order of this.datasource) {
        for (let packItem of order.packageList) {
          matchPickItem = packItem.orderPackageList.find(item => item.id == orderPackageId)
          if (matchPickItem) {
            break forOrder
          }
        }
      }
      return matchPickItem
    },
    getPackageInDatasource(orderId, packageNum) {
      let matchOrder = this.datasource.find(item => item.id == orderId)
      if (!matchOrder) {
        return
      }
      return matchOrder.packageList.find(item => item.packageNum == packageNum)
    },
    async onPickCodeScanEnter(){
      let isAutoReady = this.scanSetting.scan.includes('auto_ready') // 是否自动囤货
      let exitPickItem = this.getPickItemInDatasource(this.orderPackageId)
      if (exitPickItem) { // 网格数据源已存在对应的拣货信息：orderPackageId，扫码后，根据是否更新囤货状态，并返回拣货信息
        //根据拣货信息，重新汇总计算包裹的拣货状态
        let res = await getOrderPackageByPackageId({ orderPackageId: this.orderPackageId, updateScan: isAutoReady })
        if (res.code == 200) {
          // 更新信息
          exitPickItem.isScan = res.data.isScan
        } else {
          this.$message.error(res.msg || res.data || '获取数据失败')
          return
        }
      } else { // 不存在，需将整个订单的包裹信息全量拉取
        let res = await getOrderByOrderPackageId({ orderPackageId: this.orderPackageId, updateScan: isAutoReady })
        if (res.code == 200) {
          // 判断网格是否存在相同的订单，存在则忽略（出现场景： 网络卡顿，连续发出多个请求，先后返回）
          let order = this.converToOrderWithPackageList(res.data)
          
          // 当前扫描的包裹(一个订单存在多个包裹，逐一查找)
          for (let packItem of order.packageList) {
            exitPickItem = packItem.orderPackageList.find(item => item.id == this.orderPackageId)
            if (exitPickItem) {
              break
            }
          }
          exitPickItem.orderNo = order.orderNo
          this.datasource.push(order)
        } else {
          this.$message.error(res.msg || res.data || '获取数据失败')
          return
        }
      }
      this.scanPickInfo = exitPickItem // 提取当前拣货商品进行
      this.orderPackageId=""
      
      if(!this.scanSetting.print.includes('all_pick_ready')){ // 未打开  【备齐后自动打印】，直接结束
        return
      }
      // 拣货信息处理，根据拣货信息所属的包裹，判断是否满足打印状态： 进行运单号获取
      // 整个包裹的信息，含packageList 商品明细，waybill 面单信息
      let packageInfo = this.getPackageInDatasource(exitPickItem.orderId, exitPickItem.packageNum)

      if(this.ignorePrintBySetting(packageInfo)){ // 根据规则校验是否跳过打印
        return
      }
      
      if (packageInfo) {
        // 更新包裹的拣货汇总信息
        let quantity = 0 // 当前包裹总商品数量
        let pickQuantity = 0 // 当前包裹累计备货数量
        // 汇总包裹中已拣货的汇总信息  包裹商品总数量  ， 已备货数量
        packageInfo.orderPackageList.forEach(item => {
          quantity += item.quantity
          pickQuantity += (item.isScan ? item.quantity : 0)
        })

        packageInfo.quantity = quantity
        packageInfo.pickQuantity = pickQuantity
        if (packageInfo.quantity <= packageInfo.pickQuantity) {
          // 备货完毕===》 判断是否存在运单号，不存在，则开始生成面单，并监控面单创建结果
          if (!packageInfo.waybillNo) {
            // 配置开关，开启 退款，买家备注， 卖家备注， 管理备注， 备注关键词拦截
            packageInfo.processStatus=1
            this._waybillManager.startCreateWaybill(this.waybillTemplate.id, [packageInfo])
          } else if(!packageInfo.isDispatch && this.autoDispatch){
            // 已生成面单，且未发货，根据开关，自动进行发货
            packageInfo.processStatus = 3 // 正在发货
            this._dispatchManager.dispatchWaybill([{ orderId: packageInfo.orderId, packageNum: packageInfo.packageNum, waybillNo: packageInfo.waybillNo }])
          }

        }

      }
    },
    /* 打印规则校验： 
      已打印快递单的不自动打印-ignore_printed
      有买家留言的不自动打印-ignore_custom_remark
      有卖家备注的不自动打印-ignore_merchant_remark
      买家留言、卖家备注关键词不打印-ignore_keyword
    */
    ignorePrintBySetting(packageInfo){ // 根据规则校验是否跳过打印
      let ignorePrinted = this.scanSetting.print.includes('ignore_printed')
      if(ignorePrinted && packageInfo.waybill?.printCnt>0){ // 校验是否已打印  waybillList
        return true // 忽略已打印面单
      }
      let ignoreCustomRemark = this.scanSetting.print.includes('ignore_custom_remark')
      if(ignoreCustomRemark && packageInfo.buyerRemark){ // 校验是否 有买家备注不打印
        return true
      }
      let ignoreMerchantRemark = this.scanSetting.print.includes('ignore_merchant_remark')
      if(ignoreMerchantRemark && packageInfo.merchantRemark){ // 校验是否 有卖家备注不打印
        return true
      }

      let ignoreKeyword = this.scanSetting.print.includes('ignore_keyword')
      let ignoreKeywordText = this.scanSetting.ignoreKeyword || ''
      if(ignoreKeyword){ // 校验是否  卖家  买家备注是否存在特定关键词
        let arKey = ignoreKeywordText.split(',')
        // 任意备注满足则忽略打印
        for(let key of arKey){
          if(packageInfo.buyerRemark.indexOf(key) != -1 || packageInfo.merchantRemark.indexOf(key) != -1){
            return true
          }
        }
      }
      return false
    },
    async onScanClick() {
      this.onPickCodeScanEnter()
    },
    onPackageSelectChange(rows) {
      // 已勾选包裹
      this.selectedPackageList = rows

    },
    onTemplateChange(template){
      this.printerName = template.printerName
    },
    onWaybillPrintClick() {
      if (this.selectedPackageList.length == 0) {
        this.$message.error('请勾选需要操作的运单(包裹)')
        return
      }
      /*
       根据勾选的包裹： 1 已备齐货,且未生成面单
      */
      let list = this.selectedPackageList.filter(item => item.quantity == item.pickQuantity)
      // 触发面单生成
      list.forEach(item => {
        item.processStatus = 1
      }) // 标记正在生成面单
      // TODO  根据参数，是否 【已打印的快递单不打印】
      this._waybillManager.startCreateWaybill(this.waybillTemplate.id, list) // 即使面单已创建，仍走创建流程，目的通过查询获取打印报文
    },
    onDispatchClick() {
      if (this.selectedPackageList.length == 0) {
        this.$message.error('请勾选需要操作的运单(包裹)')
        return
      }
      
      /*
       根据勾选的包裹： 1 面单已生成，且为发货
      */
      let list = this.selectedPackageList.filter(item => item.waybillNo && !item.isDispatch)
      // 更新进度： 正在发货
      list.forEach(item => {
        item.processStatus = 3
      })
      this._dispatchManager.dispatchWaybill(list)
      
    },
    onCleanClick() { // 清空列表
      this.datasource = []
    }
  }
}
</script>
