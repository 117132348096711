<template>
  <div class="nt-text" :style="textStyle">{{config.v}}
    <slot></slot>
  </div>
</template>

<script >


export default {
  data () {
    return {
      
    }
  },
  props:{
    config:{
      type: Object
    }
  },
  computed:{
    textStyle:function(){
      let style = Object.assign({},this.config.textStyle) // textSyle
      style.lineHeight = (style.fontSize||16) + 'px'
      style.fontSize = style.fontSize + 'px'
      return style
    }
  },
  methods:{
    
  }
}

</script>