
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-aside class="margin-right nt-box-panel padding-8 nt-bg-white" width="200px">
      <nt-dict-tree @change="onPlatformClick" parent-key="SYS_E_PLATFORM"></nt-dict-tree>
    </el-aside>
    <el-main class="no-padding nt-box-panel">
      <el-container class="no-padding nt-height-100" >
        <el-header class="no-margin no-padding align-right height-auto">
          
        </el-header>
        <el-main class="no-padding">
          <el-table height="100%" border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource"
            v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left">
            </el-table-column>
            <el-table-column label="店铺id" width="100" prop="id" key="shopid"></el-table-column>
            <el-table-column label="店铺名称" width="220" prop="shopName" key="shopName"></el-table-column>
            <el-table-column label="默认仓库"  prop="storeId" key="storeID">
              <template #default="{row}" >
                <nt-dropdown-grid configKey="store" v-model="row.storeId" v-model:text="row.storeName" placeholder="请选择默认仓库" 
      :searchable="false" @choose="(store) => onRowStoreChange(row.id,store.id)"></nt-dropdown-grid>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="padding margin-top-8" style="height:auto;">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" small
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtDictTree from '@/views/plugin/nt_dict_tree'
import { saveShopKv } from '@/service/resource/platform_shop'
export default {
  name: "shop-store-mapping",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    NtDictTree
  },
  data() {
    return {
      selectedPlatform: null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedPlatform) return
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_SHOP, Object.assign({},{platform:this.selectedPlatform}, params));
    },
    async onPlatformClick(dict){
      this.selectedPlatform = dict.configKey
      this.loadList()
    },
    async onRowStoreChange(shopId,storeId){
      let res = await saveShopKv({shopId:shopId,configType:'PLATFORM_SHOP_STORE_MAPPING', configKey:shopId,configValue:storeId})
      if(res.code == 200){
        this.$message.success('保存成功')
      } else{
        this.$message.error(res.msg || '保存失败')
      }
    }
  }
}
</script>