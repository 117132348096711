<template>
  <el-container class="nt-height-100">
    <el-main class="padding">
      <el-tree
        :data="datasource"
        default-expand-all
        :props="{label:'configValue'}"
        class="nt-list-node nt-height-100 overflow-y-auto"
        @node-click="onNodeClick"
        
      />
    </el-main>
  </el-container>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import {getSysKvListWithoutLeaf} from '@/service/resource/general'
export default {
  name: "deptList",
  mixins: [DataListMixin],
  components: {
    
  },
  data() {
    return {
      data:[],
      currentRow:null
    }
  },
  methods: {
    async _loadList(params) {
      let res = await getSysKvListWithoutLeaf({}); // 获取数据字典类型列表
      let ds = res.data || []
      let rootList = ds.filter(item=> !item.parentKey)
      rootList.forEach(item=>{
        item.children = ds.filter(el=> item.configKey == el.parentKey)
      })
      this.datasource = rootList
    },

    _loadFinish(){ //列表加载结束后默认显示显示第一个
      this.onRowClick(this.datasource.length>0 ? this.datasource[0]:null)
    },
    
    onNodeClick(node){
      if(this.currentRow == node){
        return
      }
      this.currentRow = node
      this.$emit('change',node)
    },
    getRowClass({row}){
      return this.currentRow == row? 'selected': ''
    },
    
  }
}
</script>
