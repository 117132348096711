/*
  查看业务单据工具类
*/
const _all_bill_list = {
  PURCHASE_ORDER: import('@/views/bill/trade/purchase_order/detail/index.vue'), // 采购订单
  PURCHASE_IN:import('@/views/bill/trade/purchase_in/detail/index.vue'), // 采购入库
  PURCHASE_BACK:import('@/views/bill/trade/purchase_back/detail/index.vue'), // 采购退货
  SALE_ORDER:import('@/views/bill/trade/sale_order/detail/index.vue'), // 销售订单
  SALE_OUT:import('@/views/bill/trade/sale_out/detail/index.vue'), // 销售出库
  ONLINE_SALE_OUT:import('@/views/bill/trade/sale_out_online/detail/index.vue'), // 线上零售单
  SALE_BACK:import('@/views/bill/trade/sale_back/detail/index.vue'), // 销售退货
  REPOSITORY_STORE_IN:import('@/views/bill/store/other_in/detail/index.vue'), // 其他入库
  REPOSITORY_STORE_OUT:import('@/views/bill/store/other_out/detail/index.vue'), // 其他出库
  FINANCIAL_INCOME:import('@/views/financial/income/detail/index.vue'), // 其他收入
  FINANCIAL_EXPENSE:import('@/views/financial/expense/detail/index.vue'), // 其他支出
  FINANCIAL_PAY:import('@/views/financial/payment_out/detail/index.vue'), // 付款单
  FINANCIAL_TAKE:import('@/views/financial/payment_in/detail/index.vue'), // 收入单
  FINANCIAL_WITHDRAW:import('@/views/financial/withdraw/detail/index.vue'), // 现金银行
  FINANCIAL_ACCOUNT_INIT:import('@/views/financial/account_init/detail/index.vue'), // 现金银行
  SHARE_PURCHASE:import('@/views/financial/share/detail/index.vue'), // 采购分摊
  SHARE_SALE:import('@/views/financial/share/detail/index.vue'), // 销售分摊
  PAYMENT_INIT:import('@/views/financial/payment_init/detail/index.vue'), // 期初应收应付
  REPOSITORY_ALLOCATE:import('@/views/bill/store/allocate/detail/index.vue'), // 调拨单
  REPOSITORY_ASSEMBLE:import('@/views/bill/store/assemble/detail/index.vue'), // 组装单
  REPOSITORY_DISASSEMBLE:import('@/views/bill/store/disassemble/detail/index.vue'), // 拆卸单
  REPOSITORY_COST_ADJUST:import('@/views/bill/store/cost_adjust/detail/index.vue'), // 成本调整
  
}

export const  createBillComponent = async function(billName){
  // import 路径不支持变量，仅支持 字符串常量
  // import(/* webpackChunkName: "dashboard" */ '@/views/print_order').then(module=>{
  //   console.log(module.default)
  // })
  if(!_all_bill_list[billName]){
    return null
  }
  let vuePage = await _all_bill_list[billName]
  return vuePage.default
  // let  vuePath = _all_bill_list[billName]
  // return  resolve => require([vuePath], resolve) // 不通
  // let fking = await asyncFn()
  
}