<!-- 扫码发货 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin">
      <el-space wrap>
        <el-checkbox v-model="autoSend"> 扫描后立即发货</el-checkbox>
        <span>快递单号</span>
        <el-input @keyup.enter="onDispatchClick" v-model="waybillNo"></el-input>
        <el-button size="small" type="primary" @click="onDispatchClick">确认</el-button>
      </el-space>
      
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top-8">
      <el-table :data="datasource" height="100%" border  scrollbar-always-on class="nt-resource-table">
        <el-table-column label="序号" type="index" width="55"></el-table-column>
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="收件人">
          <template #default="{row}">
            <div>{{ row.buyerName }}-{{ row.buyerTel }}</div>
            <div style="font-size:14px;">{{ row.provinceName }}{{ row.cityName }}{{ row.countyName }}</div>
            <div>{{ row.buyerAddress }}</div>
          </template>
        </el-table-column>
        <el-table-column label="运单信息" width="190" prop="waybillNo">
          <template #default="{row}">
            <el-link type="primary" @click="onViewPackageDetailClick(row)">{{ row.waybillNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo" width="190">
          <template #default="{row}">
            <el-link type="primary" @click="onViewOrderDetailClick(row)">{{ row.orderNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column width="200" label="状态">
          <template #default="{row}">
            <!-- 监控实施发货状态及结果 -->
            <el-icon class="is-loading" v-if="row.processStatus==1">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus==1" style="font-size: 12px;">正在发货...</span>
            <span v-if="row.processStatus==2" style="font-size: 12px;"><el-icon color="var(--el-color-success)"><CircleCheck /></el-icon>发货完成(绿)</span>
            <span v-if="row.processStatus==3" style="font-size: 12px;">
              <el-tooltip effect="light"  placement="top-start" >
                <template #content>
                  <div style="width:200px;">
                    {{row.errorMsg}}
                  </div>
                </template>
                <el-icon color="var(--el-color-danger)"><CircleClose /></el-icon>
              </el-tooltip>
              发货失败
            </span>
            <el-button v-if="!row.processStatus" link type="primary" size="small" @click="onRowDispatchClick(row)">发货</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto align-center margin-top-8">
      <el-button type="primary" @click="onSendAllClick">全部发货</el-button>
      <el-button type="danger" @click="onClearAllClick">全部清空</el-button>
    </el-footer>
  </el-container>
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail"  />
</template>
<script>

import {  getEntityList } from '@/service/resource/general'
import { EnumEntityType} from '@/utils/enum_utils'
import NtWaybillDispatchManager from '@/utils/waybill_dispatch_manager' // 面单发货管理器
import OrderDetailPage from '@/views/print_order/detail/order_detail'
import PackageDetailPage from '@/views/print_order/detail/package_detail' // 包裹详情
export default {
  name: "SCAN_DISPATCH",
  components: {
    OrderDetailPage,
    PackageDetailPage
  },

  data() {
    return {
      waybillNo:null,
      autoSend:true,
      datasource:[],
      selectedRows:[]
    }
  },
  created(){
    this._dispatchManager = new NtWaybillDispatchManager((resultList,result ) => {
      if (result.type == 'success') {
        // 根据结果，判断是否满足打印, 根据list
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.processStatus = item.success?2:3 // 判断发货结果 2 发货成功  3发货失败
            if(item.success){
              matchItem.isDispatch = true // 已成功发货，禁止勾选，如已勾选，则取消
            } else{
              matchItem.errorMsg = item.message // 记录异常消息 如发货异常，则显示 红色 失败提示信息-over
            }
          }
        })
      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.done=true
          }
        })
      } else if (result.type == 'overTime') { // 查询[发货]结果超时
        // 标记失败，提示重试
        
        // let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
        // if (matchItem) {
        //   matchItem.done=true
        // }
      } else if(result.type == 'query'){
        // 轮询网络异常 TODO
      }
      
    })
  },
  methods: {
    formatProcessStatus(status){
      let info={
        '1':'正在发货',
        '2': '已发货'
      }
      return info[status]|| status
    },
    
    onRowDispatchClick(row){
      //单个预发货
      let list = [{
        orderId:row.orderId,
        packageNum: row.packageNum
      }]
      row.processStatus = 1 // 正在发货
      this._dispatchManager.dispatchWaybill(list)
    },
    async onDispatchClick(){
      if(!this.waybillNo){
        this.$message.error("请输入运单号")
        return
      }
      let res = await getEntityList(EnumEntityType.PLATFORM_WAYBILL,{waybillNo:this.waybillNo})
      if (res.code == 200) {
          res.data.forEach(item=>{
            // 判断订单id是否存在，存在则忽略
            let matchIndex = this.datasource.findIndex(el=>el.orderId == item.orderId)
            if(matchIndex == -1){
              this.datasource.push(item)
              // 扫完，直接发货开关
              if(this.autoSend){
                item.processStatus = 1 // 进入发货状态
                this._dispatchManager.dispatchWaybill([{ orderId: item.orderId, packageNum: item.packageNum, waybillNo: item.waybillNo }])
              }
            }
          })
      } else {
        this.$message.error(res.msg || res.data)
      }
      this.waybillNo = null
    },
    onViewPackageDetailClick(row){
      this.$refs.refPackageDetail.show(row.orderId,row.waybillNo)
    },
    onViewOrderDetailClick(row){
      this.$refs.refOrderDetail.show(row.orderId)
    },
    onSendAllClick(){
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return;
      }
      this.selectedRows.forEach(item=>item.processStatus =1)
      let packageList = this.selectedRows.map(item=>({
        orderId: item.orderId, packageNum: item.packageNum, waybillNo: item.waybillNo
      }))
      this._dispatchManager.dispatchWaybill(packageList)
    },
    onClearAllClick(){
      this.selectedRows=[]
      this.datasource=[]
    }
  }
}
</script>