<!-- 打印记录 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin display-flex flex-wrap row-gap-8">
      <QueryFieldList :queryModel="queryForm" :editors="editors"></QueryFieldList>
        <div>
          <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
          <el-button style="width:64px;" size="small" type="primary">重置</el-button>
        </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top-8">
      <el-table :data="datasource" @selection-change="onSelectionChange" height="100%" v-loading="isLoading"  scrollbar-always-on class="nt-resource-table"  border>
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="所属店铺" prop="shopName" width="160">
          <template #default="{row}">
            <span>{{ formatShopName(row.shopId) }}</span>
            <span class="left-top iconfont icon-shipinhaoxiaodian1 margin-left-4 margin-top-4" style="color:#FF6146;font-size:25px;opacity:.5;"></span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="买家昵称" prop="buyerName" width="110"></el-table-column> -->
        <el-table-column label="收件人" prop="buyerName">
          <template #default="{row}">
            <div>
              <span>{{ row.buyerName }}-{{ row.buyerTel }}</span>
            </div>
            <div style="font-size:14px;">{{ row.provinceName }}{{ row.cityName }}{{ row.countyName }}</div>
            <div>
              {{ row.buyerAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="运单信息" width="180" >
          <template #default="{row}">
            <div v-for="waybill in row.waybillList">
              <el-link type="primary" @click="onViewPackageDetailClick(row.id,waybill.waybillNo)">{{ waybill.waybillNo }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo" width="170">
          <template #default="{row}">
            <el-link type="primary" @click="onViewOrderDetailClick(row.id)">{{ row.orderNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="异常原因" width="120">
          <template #default="{row}">
            <el-space wrap>
              <el-tag type="danger" v-for="item in formatAbnormalText(row.orderExtra)">{{ item }}</el-tag>
            </el-space>
            
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="85" >
          <template #default="{row}">
            {{ formatOrderStatus(row.platform,row.platformOrderStatus) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template #default="{row}">
            <el-button link type="primary" size="small" @click="onRowIgnoreClick(row)">忽略异常</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top align-center relative">
      <el-button @click="onClearAllClick" type="primary">批量清除异常</el-button>
      <el-pagination class="right-top" :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
    </el-footer>
  </el-container>
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail"  />
</template>
<script>


import  QueryFieldList from '../../query/query_field_list'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import OrderDetailPage from '@/views/print_order/detail/order_detail'
import PackageDetailPage from '@/views/print_order/detail/package_detail' // 包裹详情

import { clearAbnormal } from '@/service/print_order/order'
import {LoginUserStore} from '@/piana/login_user_service'
import {abnormalQuery} from '../../query/config/query_order_editor'
export default {
  name: "ABNORMAL_HISTORY",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    OrderDetailPage,
    PackageDetailPage,
    QueryFieldList
  },
  data() {
    return {
      queryForm: {},
      editors: abnormalQuery
    }
  },
  created(){
    this.editors.forEach(item=>{
      if(item.defaultValue){
        this.queryForm[item.queryKey]= item.defaultValue
      }
    })
    this._shopList = LoginUserStore().shopList
  },
  methods: {
    async _loadList(params) {
      let dynamicQuery = {dynamic:Object.values(this.queryForm)}
      dynamicQuery.dynamic.push({queryType:'INNER_ABNORMAL'}) // 固定查询异常订单
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_ORDER, Object.assign({},params,dynamicQuery))
    },
    formatWaybillInfo(row){
      let list = row.waybillList?.map(el=>el.waybillNo)
      return list.join(',')
    },
    formatShopName(shopId){
      let matchItem =  this._shopList.find(item=>item.shopId==shopId)
      return matchItem?.shopName || shopId
    },
    formatOrderStatus(platform,orderStatus){
      let info= {
        'WX_VIDEO_10':'待付款',
        'WX_VIDEO_20':'待发货',
        'WX_VIDEO_21':'部分发货',
        'WX_VIDEO_30':'代收货',
        'WX_VIDEO_100':'已完成',
        'WX_VIDEO_200':'已取消', // 全部商品售后之后，订单取消
        'WX_VIDEO_250':'已取消', // 未付款用户主动取消或超时未付款订单自动取消

      }
      return info[`${platform}_${orderStatus}`] || orderStatus
    },
    formatAbnormalText(orderExtra){
      // 格式化异常原因 1： 高亮偏远地区--含自定义  2 快递不可达 4 异常地址
      let info={
        '1': '偏远地区',
        '2': '快递不可达',
        '4': '异常地址'
      }
      
      let result = []
      if((orderExtra.abnormalFlag & 1) == 1){
        result.push(info['1'])
      } 
      if((orderExtra.abnormalFlag & 2) == 2){
        result.push(info['2'])
      } 
      if((orderExtra.abnormalFlag) & 4 == 4){
        result.push(info['4'])
      }
      
      return result
    },
    onQueryClick() {
      this.resetPage = true
      // 固定异常查询条件
      this.loadList()
    },
    onViewPackageDetailClick(orderId,waybillNo){
      this.$refs.refPackageDetail.show(orderId,waybillNo)
    },
    onViewOrderDetailClick(orderId){
      this.$refs.refOrderDetail.show(orderId)
    },
    async onRowIgnoreClick(row){
      let res = await clearAbnormal([row.id])
      if (res.code == 200) {
        this.$message.success('更新成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    async onClearAllClick(){
      if(this.selectedRows.length==0){
        this.$message.error('请勾选需要操作的订单')
        return 
      }
      
      let idList = this.selectedRows.map(item=>item.id)
      let res = await clearAbnormal(idList)
      if (res.code == 200) {
        this.$message.success('更新成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    }
  }
}
</script>