<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding nt-box-panel nt-bg-white">
        <nt-form-item prop="categoryName" label="名称" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入名称" v-model="formModel.categoryName" />
        </nt-form-item>
        <nt-form-item  prop="parent_id" label="所属上级" class="no-margin-bottom">
          <nt-goods-category-tree v-model="formModel.parentId" v-model:text="formModel.parentName"></nt-goods-category-tree>
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
  
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtGoodsCategoryTree from '@/views/plugin/nt_goods_category_tree'
export default {
  name: "goods_category",
  mixins: [DataEditMixin],
  components: { NtFormItem,NtGoodsCategoryTree },
  data() {
    return {
    }
  },
  props: {
    entityId: {
      type: String
    },
    parentCategory: {
      type: Object
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  methods: {
    
    async _loadEntity(params) {
      if (!this.entityId) {
        if (this.parentCategory) {
          return { data: { parentId: this.parentCategory.id, parentName: this.parentCategory.categoryName } }
        } else {
          return { data: { parentId: '-1' } }
        }
      } else {
        let res = await this.getEntity(this.EnumEntityType.GOODS_CATEGORY, { id: this.entityId })
        return res
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      
      return this.saveEntity(this.EnumEntityType.GOODS_CATEGORY, postData)
    },
  }
}
</script>