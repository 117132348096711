<template>
  <el-container class="padding nt-bg-page nt-box-panel" style="height:430px;">
    <el-header class="height-auto no-padding no-margin ">
      <div class="display-flex">
        <div style="flex-basis:33%"  :class="`relative padding-8 nt-bg-white  space-between ${selectedTpl==tpl?'nt-bottom-right-check':''}`" @click="onTemplateClick(tpl)" v-for="tpl in printTemplateList"
          :key="tpl.id">
          {{ tpl.templateName }}
        </div>
      </div>
    </el-header>
    <el-main class="no-padding  margin-top">
      <el-table :data="datasource" border height="100%" class="nt-resource-table">
        <el-table-column type="index" width="45"></el-table-column>
        <el-table-column label="订单信息">
          <template #default="{ row }">
            <div>
              <div><span>订单号:</span><span>{{ row.orderNo }}</span></div>
              <div v-if="row.waybillNo"><span>运单号:{{ row.packageNum }}:</span>
                <span>{{ row.waybillNo }}</span>
              </div>
              <div v-else>
                <span>包裹#{{ row.packageNum }}:</span>
                <span >待取号</span>
              </div>
              <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品数量" prop="quantity" width="90"></el-table-column>
        <el-table-column label="操作结果" width="160">
          <template #default="{ row }">
            <!-- 等待生成  正在获取面单 正在打印面单  打印完成 ==》有错误信息？ -->
            <el-icon class="is-loading" v-if="taskStatus == 'EXECUTTING'">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus">{{ formatProcessStatus(row.processStatus) }}</span>
            <span v-else>就绪</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding margin-top">
      <!-- <div class="margin-top">共{{ packageList.length }}个包裹</div> -->
      <div class="align-center">
        <nt-printer class="margin-right" v-model="printerName" style="width:220px" placeholder="请选择打印机"></nt-printer>
        <el-button type="primary" v-if="taskStatus!='DONE'" :loading="taskStatus=='EXECUTTING'" @click="onStartClick">开始打印</el-button>
        <el-button type="primary" @click="onPreviewClick">预览</el-button>
        <el-button type="primary" v-if="taskStatus=='DONE'" @click="onFinishHandler">关闭</el-button>
      </div>
    </el-footer>
  </el-container>
  <print-preview ref="refPreview"></print-preview>
</template>

<script>

import { getListByPackageNum } from '@/service/print_order/order'
import { queryTemplateList } from '@/service/print_order/template'
// import previewDispatch from '@/views/preview/dispatch'

import { updateDispatchPrint} from '@/service/print_order/platform_waybill'
import NtPrintManager from '@/utils/print_manager' // 打印管理器
import PrintPreview from '@/views/preview/dispatch'
import NtPrinter from '@/views/plugin/nt_printer'
export default {
  name: "createWaybill",
  data() {
    return {
      printerName:null,
      selectedTpl: null,
      printTemplateList: [], // 发货单模版列表
      taskStatus: 'READY', // REDAY 就绪  EXECUTTING--正在进行  DONE 完成
      datasource: this.packageList || []
    }
  },
  components: { PrintPreview,NtPrinter },
  props: {
    packageList: { // 需要操作的包裹列表
      type: Array,
      required: true
    },
    waybillTemplateId: { // 面单模版
      type: String,
      required: true
    },
    // printerName: {
    //   type: String,
    //   default: 'Microsoft Print to PDF'
    // },
  },
  
  async created() {
    let res = await queryTemplateList({templateType:'DISPATCH_TEMPLATE'})
    if (res.code == 200) {
      this.printTemplateList = res.data
      let matchItem = this.printTemplateList.find(el => el.isDefault)
      if (matchItem) {
        this.selectedTpl = matchItem
      } else if (this.printTemplateList.length > 0) {
        this.selectedTpl = this.printTemplateList[0]
      }
    }

    this._printManager = new NtPrintManager((result, data /*打印结果：单个，全部完成为数组 */) => {

      if(result.type =='printed'){ // 单个文档打印完成
        let arPackInfo = data.requestID.split('-') // orderId  + packageNum
        let packageInfo = {orderId:arPackInfo[0],packageNum:arPackInfo[1]}
        let matchItem = this.datasource.find(item => item.orderId == packageInfo.orderId && item.packageNum == packageInfo.packageNum)
        if (!matchItem) {
          return
        }
        matchItem.processStatus = 3 // 打印完成
        matchItem.done = true
        matchItem.errorMsg = result.msg

        
      } else if(result.type =='done'){// 全部打印完成，注意存在多次批次打印，如面单，每次可能只返回n面单生成结果，通过轮询不断触发
        this.taskStatus =  'DONE' // 打印结束
        let printSuccessList = []
        data?.forEach(item=>{
          if(item.success){ //进处理队列中已成功的打印数据
            let arPackInfo = item.requestID.split('-') // orderId  + packageNum
            printSuccessList.push({orderId:arPackInfo[0],packageNum:arPackInfo[1]})
          }
        })
        // 打印结束--- // 发货单是记录打印次数？？
        if(printSuccessList.length>0){
          updateDispatchPrint(printSuccessList)
        }
      }
    })
  },
  methods: {
    formatProcessStatus(status) {
      let info = {
        '1': '正在获取打印数据...',
        '2': '正在打印...',
        '3': '打印完成'
      }
      return info[status] || status
    },
    async onStartClick() { // 开始打印单据
      if(!this.printerName){
        this.$message.warning('请选择打印机')
        return
      }
      if(!this.selectedTpl){
        this.$message.warning('请选择打印模版')
        return
      }
      this.taskStatus =  'EXECUTTING' //开始执行任务
      this.datasource.forEach(el => {
        el.done = false
        el.processStatus = 1 // 正在获取面单
      })

      // 加载包裹信息 ==订单信息
      let res = await getListByPackageNum(this.datasource)

      if (res.code == 200) {
        let printObjectList = []
        res.data?.forEach(item=>{
          // 提取订单所属的包裹
          let matchItem = this.datasource.find(el => el.orderId == item.id/*返回订单的表id==orderId */ && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.processStatus = 2 // 进入打印
          }
          item.orderItemList?.forEach(el=>{
            el.skuAttrs = this.formatSkuAttr(el.skuAttrs) // 将数据源的规格json字符串转为 描述
          })
          
          // 开始整理打印文档数据
          let printDocument = ''
          printDocument.printDataVo = {
            templatePlatform:'SYSTEM',
            templateId: this.selectedTpl.id,
            listNode:'orderItemList', // 网格单据节点
            printData: item
          }
          printObjectList.push(printDocument)
        })
        this._printManager.print(this.printerName, false, printObjectList)
      }

    },
    onTemplateClick(tpl) {
      this.selectedTpl = tpl
    },
    formatSkuAttr(attrs){
      let arAttrs = JSON.parse(attrs||null) || []
      let ar = arAttrs.map(item=>{
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    async onPreviewClick() {
      if(!this.selectedTpl){
        this.$message.warning('请选择打印模版')
        return
      }

      let res = await getListByPackageNum(this.datasource)

      if (res.code == 200) {
        res.data?.forEach(item=>{
          item.orderItemList?.forEach(el=>{
            el.skuAttrs = this.formatSkuAttr(el.skuAttrs) // 将数据源的规格json字符串转为 描述
          })
        })
        this.$refs.refPreview.show('打印预览',{
          documents: res.data,
          templateId: this.selectedTpl.id,
          listNode:'orderItemList'
        })
      }
    },

    onFinishHandler() {
      this.datasource=[]
      this.$emit("close") // 完成
    }
  }
}
</script>
