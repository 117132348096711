
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form layout="inline" @keyup.enter.native="onSearchQueryClick">
        <el-row :gutter="24">
          <el-col :md="6" :sm="24">
            <el-form-item label="单据编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <el-input placeholder="请输入单据编号" v-model="queryParam.number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <el-form-item label="商品信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <el-input placeholder="条码/名称/规格/型号" v-model="queryParam.materialParam"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <el-form-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <NTDateRange v-model:startTime="queryParam.startTime" v-model:endTime="queryParam.endTime" format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"></NTDateRange>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
              <el-button style="margin-left: 8px" v-print="'#reportPrint'" icon="printer">打印</el-button>
              <el-button style="margin-left: 8px" @click="exportExcel" icon="download">导出</el-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <el-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </el-col>
          <template v-if="toggleSearchStatus">
            <el-col :md="6" :sm="24">
              <el-form-item label="调出仓库" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select optionFilterProp="children" :dropdownMatchSelectWidth="false" showSearch allow-clear
                  style="width: 100%" placeholder="请选择仓库" v-model="queryParam.storeIdF">
                  <el-select-option v-for="(store, index) in storeList" :value="store.id" :key="index">
                    {{ store.storeName }}
                  </el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="调入仓库" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-select optionFilterProp="children" :dropdownMatchSelectWidth="false" showSearch allow-clear
                  style="width: 100%" placeholder="请选择仓库" v-model="queryParam.storeId">
                  <el-select-option v-for="(store, index) in storeList" :value="store.id" :key="index">
                    {{ store.storeName }}
                  </el-select-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <el-input placeholder="请输入备注" v-model="queryParam.remark"></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource" v-loading="isLoading">
        <el-table-column label="单据编号" width="160">
          <template #default="scope">
            <el-space>
              <a @click="onViewBillDetailClick(scope.row)">{{ scope.row.billNo }}</a>
            </el-space>
          </template>
        </el-table-column>

        <el-table-column label="商品" prop="goodsName"></el-table-column>
        <el-table-column label="单位" prop="unitName"  width="80"></el-table-column>
        <el-table-column label="数量" prop="quantity"  width="80"></el-table-column>
        <el-table-column label="单价" prop="price"  width="80">
          <template #default="scope">
            {{ ((scope.row.price) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="total" width="80">
          <template #default="scope">
            {{ ((scope.row.price * scope.row.quantity) / 100).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column label="调入仓" prop="inStoreName"></el-table-column>
        <el-table-column label="调出仓" prop="outStoreName"></el-table-column>
        <el-table-column label="调拨日期" prop="billTime" width="160"> </el-table-column>
        <el-table-column label="备注" prop="remark"> </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>
import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
import { getAllocateDetailList } from '@/service/report/rpt_allocate'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
import NTDateRange from '@/views/plugin/nt_date_range'

import moment from 'moment'
export default {
  name: "AllocationDetail",
  mixins: [DataListMixin],
  components: {
    NTDateRange,
    BillViewerPage
  },
  data() {
    return {
      toggleSearchStatus: false,

      // 查询条件
      queryParam: {
        companyId: '',
        number: '',
        materialParam: '',
        storeId: '',
        storeIdF: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
        remark: ''
      },
      storeList: [],
      
    }
  },
  async created() {
    let res = await this.getEntityList(this.EnumEntityType.STORE, {})
    if (res.code == 200) {
      this.storeList = res.data
    }
  },
  methods: {
    async _loadList(params) {
      return await getAllocateDetailList(Object.assign(params, {}))
    },

    handleToggleSearch() {
    },
    onSearchQueryClick() {
      if (this.queryParam.startTime == '' || this.queryParam.endTime == '') {
        this.$message.warning('请选择单据日期！')
      } else {
        this.loadList();
      }
    },
    exportExcel() {
      let aoa = [['单据编号', '条码', '名称', '规格', '型号', '单位', '数量', '单价', '金额', '调出仓库', '调入仓库', '调拨日期', '备注']]
      for (let i = 0; i < this.dataSource.length; i++) {
        let ds = this.dataSource[i]
        let item = [ds.number, ds.barcode, ds.mname, ds.standard, ds.model, ds.mUnit, ds.operNumber,
        ds.unitPrice, ds.allPrice, ds.dname, ds.sname, ds.operTime, ds.newRemark]
        aoa.push(item)
      }
      openDownloadDialog(sheet2blob(aoa), '调拨明细')
    },
    onViewBillDetailClick(record) {
      this.$refs.refViewer.showBill('REPOSITORY_ALLOCATE', record.id)
    }
  }
}
</script>
