<!-- 业务单据预览
    每个预览文档，根据模版及配置生成完整的预览页
 -->

<template>
  <div v-if="bodyPageCount>0" class="preview-page" v-for="(item, pageIndex) in new Array(bodyPageCount)"
    :style="{ overflow: 'hidden', width: templateConfig.pageWidth + 'mm', height:isAutoHeight?'auto': templateConfig.pageHeight + 'mm' }">
    <div class="preview-header" :style="{ height: templateConfig.pageSetting.headerHeight + 'mm' }">
      <div class="preview-item"
        :style="{ width: element.width + 'px', height: element.height + 'px', left: element.left + 'px', top: element.top + 'px' }"
        v-for="element in templateConfig.configJson.header">
        <component :is="ctrl[element._t]" :config="element" :isDesignMode="false" />
      </div>
    </div>
    <div class="preview-body"
      :style="{ position: 'relative', height: templateConfig.pageSetting.bodyHeight + 'mm' }">
      <!-- 根据页码，即按分页的方式提取商品列表  增加自由容器组件-->
      <resizeTable :config="templateConfig.configJson.body" :isDesignMode="false" v-if="templateConfig.pageSetting.bodyContainer=='grid'"
        :subtotalRows="getPageSubtotalRows(pageIndex)" :padding="templateConfig.pageSetting?.padding || {}"
        :datasource="getDataByPage(pageIndex)" :pageIndex="pageIndex" :pageSize="pageSize"></resizeTable>
        <FreeContainer :config="templateConfig.configJson.freeContainer" :datasource="getFreeContainerDataByPage(pageIndex)"  :pageSetting="templateConfig.pageSetting" v-else></FreeContainer>
    </div>
    <div class="preview-footer" :style="{ height: templateConfig.pageSetting.footerHeight + 'mm' }">
      <div class="preview-item"
        :style="{ width: element.width + 'px', height: element.height + 'px', left: element.left + 'px', top: element.top + 'px' }"
        v-for="element in templateConfig.configJson.footer">
        <component :is="ctrl[element._t]" :config="element" :isDesignMode="false" />
      </div>
    </div>
  </div>
</template>

<script >

import { getTemplate } from '@/service/print_order/template'
import { qr_code, barcode, text, v_line, h_line, rectangle, sys_text } from '@/views/designer/control/all'
import { getReflectValue, parseVarExpr } from '@/utils/reflect'
import PrintCreator from '@/utils/print_utils'
import '@/views/designer/index.less'
import resizeTable from '@/views/designer/control/table'
import FreeContainer from './free_container'
// import print from 'vue3-print-nb'


export default {
  data() {
    return {
      templateConfig: this.templateInfo,
      bodyPageCount:0,
      ctrl: { barcode, qr_code, text, v_line, h_line, rectangle, sys_text }
    }
  },
  components: {
    resizeTable,FreeContainer
  },
  props: {
    printObject: {
      type: Object
    },
    templateInfo: {
      type: Object,
      required:true
    },
    listNode: { // 属于用于body网络列表的节点属性
      type: String
    }
  },
  watch:{
    templateInfo:function () {
      this.templateConfig = this.templateInfo
      this.preparePrintData()
    }
  },
  computed:{
    isAutoHeight:function(){ // 页面单高度是否有 body区域的高度配置控制
      let autoHeight = false
      if(this.templateConfig.pageSetting.bodyContainer == 'block'){ // 自由容器模式
        autoHeight = this.templateConfig.configJson.freeContainer.autoHeight||false
      } else { //网格模式
        autoHeight = this.templateConfig.configJson.body.autoHeight||false
      }
      console.log('authheight', this.templateConfig)
      return autoHeight
    }
  },
  async created() {
    // console.log('xxxx', this.printObject,this.templateInfo)
    this.templateConfig = JSON.parse(JSON.stringify(this.templateInfo))
    this._printCreator = new PrintCreator()
    this.preparePrintData()
  },
  mounted(){
    
  },
  methods: {
    preparePrintData(){
      // 根据变量，解析printObject 数据，获取变量表达式
      this.templateConfig.configJson.header?.forEach(item => {
        // 不区分组件，只要存在变量，则直接提取，提取不到，则按原文
        // console.log('pre:', checkValidVar(item.v),item.v)
        let fieldVar = parseVarExpr(item.v)
        if (fieldVar) { //判断是否为变量格式 =[...]
          item.v = getReflectValue(this.printObject, fieldVar)
        }
      })
      this.templateConfig.configJson.footer?.forEach(item => {
        let fieldVar = parseVarExpr(item.v)
        if (fieldVar) { //判断是否为变量格式 =[...]
          item.v = getReflectValue(this.printObject, fieldVar)
        }
      })

      // 根据模版，及数据源，构建分页，合计 小计信息
      
      this._printCreator.createPrintPageData(this.templateConfig, this.printObject, this.printObject[this.listNode] || [])
      this.bodyPageCount = this._printCreator?.getPageCount()
      console.log('xxxxxxxxxxxxxx', this.bodyPageCount)
    },
    getDataByPage(pageIndex) {
      return this._printCreator.getDataByPage(pageIndex)
    },
    getFreeContainerDataByPage(pageIndex) {
      return this._printCreator.getFreeContainerDataByPage(pageIndex)
    },
    getPageSubtotalRows(pageIndex) { // 获取当前页的合并行数： 合计1， 页小计1，最大2
      return this._printCreator.getPageSubtotalRows(pageIndex)
    },
    
  }
}

</script>
