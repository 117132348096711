
<template>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <a-form inline @keyup.entescope.rownative="onSearchQueryClick">
        <a-row :gutter="24">
          <a-col :md="5" :sm="24">
            <a-form-item label="仓库">
              <a-select mode="multiple" :maxTagCount="1" optionFilterProp="children" showSearch style="width: 100%"
                placeholder="请选择仓库" v-model="storeSelected">
                <a-select-option v-for="(store, index) in storeList" :value="store.id" :key="index">
                  {{ store.storeName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="商品信息">
              <a-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="5">
            <NTDateRange v-model="queryModel.startTime" :endTime.sync="queryModel.endTime" format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
          </a-col>
          <a-col :md="6" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="onSearchQueryClick">查询</a-button>
            </span>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :md="5" :sm="24">
              <a-form-item label="类别">
                <a-tree-select style="width:100%" :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }" allow-clear
                  :treeData="categoryTree" v-model="queryParam.categoryId" placeholder="请选择类别">
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="仓位货架">
                <a-input style="width: 100%" placeholder="请输入仓位货架查询" v-model="queryParam.position"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="零库存">
                <a-select v-model="queryParam.zeroStock">
                  <a-select-option value="0">隐藏</a-select-option>
                  <a-select-option value="1">显示</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource" v-loading="isLoading">
        <el-table-column label="商品" prop="goodsName">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <el-table-column label="型号" prop="model">
        </el-table-column>
        <el-table-column label="颜色" prop="color">
        </el-table-column>
        <el-table-column label="单位" prop="unitName" width="60">
        </el-table-column>
        <el-table-column label="均价" prop="price" width="60">
          <template #default="scope">
            {{ ((scope.row.price || 0) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="期初结存" prop="initQuantity" width="60">
          <el-table-column label="数量" prop="initQuantity" width="60">
            <template #default="scope">
              {{ (scope.row.initQuantity || 0) }}
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="initTotal" width="60">
            <template #default="scope">
              {{ (((scope.row.initQuantity || 0) * (scope.row.price || 0)) / 100).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="本期" prop="inQuantity" >
          <el-table-column label="入库数量" prop="inQuantity" width="120">
          </el-table-column>
          <el-table-column label="出库数量" prop="outQuantity" width="120">
          </el-table-column>
        </el-table-column>

        <el-table-column label="期末" prop="quantity">
          <el-table-column label="数量" prop="quantity" width="60">
            <template #default="scope">
              {{ ((scope.row.initQuantity || 0) + (scope.row.inQuantity || 0) - (scope.row.outQuantity || 0)).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="total" width="60">
            <template #default="scope">
              {{ ((scope.row.price || 0) * ((scope.row.initQuantity || 0) +
                (scope.row.inQuantity || 0) - (scope.row.outQuantity || 0)) / 100).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>


      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
import { getInOutSummaryList } from '@/service/report/rpt_stock'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import { queryMaterialCategoryTreeList } from '@/api/api'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'
export default {
  name: "InOutStockStaticis",
  mixins: [DataListMixin],
  components: {
    NTDateRange
  },
  data() {
    return {
      detailVisible: false,
      toggleSearchStatus: false,

      // 查询条件
      queryModel: {
        companyId: '',
        materialParam: '',
        startTime: getNowFormatYear() + '-01-01',
        endTime: moment().format('YYYY-MM-DD'),
      },
      storeSelected: [],
      storeList: []
    }
  },
  async created() {
    // this.getStoreData()
    let res = await this.getEntityList(this.EnumEntityType.STORE, {})
    if (res.code == 200) {
      this.storeList = res.data
    }
  },
  mounted() {
  },
  methods: {

    loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      queryMaterialCategoryTreeList(params).then((res) => {
        if (res) {
          that.categoryTree = [];
          for (let i = 0; i < res.length; i++) {
            let temp = res[i];
            that.categoryTree.push(temp);
          }
        }
      })
    },
    async _loadList(params) {
      return await getInOutSummaryList(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },

  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>