
<template>
  <el-container class=" nt-height-100 nt-bg-white">
    <el-main class="no-padding">
      <el-tabs v-model="activeTabName"  class="nt-width-100 nt-height-100 nt-tab-page" >
        <el-tab-pane label="期初应付" name="pay_init" lazy style="height:100%">
          <pay-init-page></pay-init-page>
        </el-tab-pane>
        <el-tab-pane label="期初应收" name="take_init" lazy  style="height:100%">
          <take-init-page></take-init-page>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    
  </el-container>
  </template>
<script>

import payInitPage from './pay_init_list'
import takeInitPage from './take_init_list'
export default {
  name: "Payment_INIT",
  components: {
    payInitPage,
    takeInitPage
  },
  data() {
    return {
      activeTabName: 'pay_init'
    }
  },
  methods: {
  }
}
</script>
