
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购统计-按供应商</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="采购时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%"  class="nt-rpt-table":data="datasource" v-loading="isLoading">
        <el-table-column label="#" type="index" width="50" fixed="left"></el-table-column>
        <el-table-column label="供应商" prop="companyName" width="240">
        </el-table-column>
        <el-table-column label="采购数量" prop="inBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN')" >{{ row.inBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货数量" prop="backBaseQuantity" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >{{ row.backBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" width="90" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_BACK')" >{{ ((row.backTotal)).toFixed(2) }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购数量"  width="120" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >{{ row.inBaseQuantity - row.backBaseQuantity }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="实际采购金额" prop="total"  width="120" align="right">
          <template #default="{row}">
            <el-link underline @click="onViewDetailClick(row,'PURCHASE_IN,PURCHASE_BACK')" >{{ ((row.inTotalWithTax - row.backTotalWithTax)).toFixed(2) }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="折扣金额" prop="discountTotal" width="110" align="right">
          <template #default="{row}">
            {{ (((row.inDiscountTotal||0) - (row.outDiscountTotal||0))).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="累计付款金额" prop="settleTotal" width="110" align="right">
          <template #default="{row}">
            {{ (((row.inSettleTotal||0) - (row.outSettleTotal||0))).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="待付款金额" prop="backBaseQuantity" width="110" align="right">
          <template #default="{row}">
            {{ (((row.inBillTotal||0) - (row.outBillTotal||0)) - ((row.inSettleTotal||0) - (row.outSettleTotal||0))).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  <el-drawer
    v-model="detailVisible" destroyOnClose size="85%"
    class="nt-inner-drawer" :with-header="false" :z-index="99"
    @close="detailVisible=false">
    <!-- 入参 ： 时间范围， 往来单位， 付款状态 -->
    <trade-item-detail  @cancel="detailVisible =false"  :query="subQuery" v-if="detailVisible"></trade-item-detail>
    <i class="nt-drawer-close" @click="detailVisible = false">关闭</i>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPurchaseInTotalBySupplier } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
// import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
import tradeItemDetail from '@/views/report/inner_detail/purchase_sale/trade_item_detail_group_by_bill'
export default {
  name: "purchase_total_supplier",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    tradeItemDetail,
    NtDateRange
  },
  data() {
    return {
      detailVisible: false,
      subQuery: {}, //详情查询条件
      // 查询条件
      queryModel: {
        startTime: this.moment().subtract(1,'month').format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getPurchaseInTotalBySupplier(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(row,billType){
      // 根据查询时间，当前往来单位查询交易详情
      // 构造查询参数： 时间范围， 商品id
      this.subQuery= {
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        companyId: row.companyId,
        billType: billType
      }
      this.detailVisible = true
    }
  }
}
</script>