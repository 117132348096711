<template>
    <el-table-column  v-bind="$attrs" class-name="no-padding">
    <template #header>
      <div class="display-flex align-center" style="column-gap:2px;font-weight:initial;" >
        <div class="goods-name" style="flex:1;">商品名称</div>
        <div class="unit-name" :style="{width:'50px'}" v-if="!hideColumns?.includes('unitName')"  >单位</div>
        <div class="goods-sku" :style="{width:'70px'}" v-if="!hideColumns?.includes('sku')"  >SKU</div>
        <div class="quantity" :style="{width:'50px'}" v-if="!hideColumns?.includes('quantity')" >数量</div>
        <div class="finish-quantity" :style="{width:'50px', textAlign:'center'}" v-if="!hideColumns?.includes('finishQuantity')" >
          <slot name="finishQuantityTitle">已入库</slot>
        </div>
        <div class="execute-status" :style="{width:'50px', textAlign:'center'}" v-if="!hideColumns?.includes('executeStatus')">
          <slot name="executeStatusTitle">行状态</slot>
        </div>
      </div>
    </template>
    <template #default="{row}"> 
      <div class="display-flex padding-left-4 padding-right-4" style="column-gap:2px;font-size:12px;" v-for="(item,index) in (row.tradeItemList || row.storeItemList || row.assembleItemList || row.allocateItemList || row.onlineSaleItemList)">
        <!-- 限制最大显示10行，超过隐藏，显示省略号。。。 -->
        <el-text class="goods-name " style="flex:1;font-size:12px;" line-clamp="1">{{item.goodsName || item.goods?.goodsName}}</el-text>
        <div class="unit-name" :style="{width:'50px',textAlign:'center',paddingRight:'8px;'}" v-if="!hideColumns?.includes('unitName')">{{ item.unitName }}</div>
        <el-text  class="goods-sku"  :style="{width:'70px',fontSize:'12px',textAlign:'right',paddingRight:'8px'}" v-if="!hideColumns?.includes('sku')">{{ item.sku }}</el-text>
        <div class="quantity" :style="{width:'50px',textAlign:'right',paddingRight:'8px;'}" v-if="!hideColumns?.includes('quantity')">{{ item.quantity }}</div>
        <div class="finish-quantity" :style="{width:'50px',textAlign:'right',paddingRight:'4px;'}" v-if="!hideColumns?.includes('finishQuantity')">{{item.finishQuantity}}</div>
        <div class="execute-status scale-8" :style="{width:'50px',textAlign:'center'}" v-if="!hideColumns?.includes('executeStatus')">
          <slot name="execute-status"  :data="item">
            <el-tag type="info"  v-if="!item.finishQuantity" size="small">未执行</el-tag>
            <el-tag type="warning" v-else-if="item.finishQuantity<item.quantity" size="small">部分执行</el-tag>
            <el-tag type="success"  v-else-if="item.finishQuantity>= item.quantity" size="small">已执行</el-tag>
          </slot>
        </div>
      </div> 
    </template>
  </el-table-column>
  
</template>
<script >
export default {
  data(){
    return {
    }
  },
  props:{
    hideColumns:{
      type:Array,
      default:function(){
        return []
      }
    }
  },
  methods:{}
  }
</script>
