
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <nt-form-item label="所属店铺">
          <el-input placeholder="请输入名称查询" v-model:value="queryModel.accountName"></el-input>
        </nt-form-item>
        <nt-form-item label="编号">
          <el-input placeholder="请输入商品标题,属性，SKU" v-model:value="queryModel.accountNo"></el-input>
        </nt-form-item>
        <nt-form-item label="商品简称/有-无">
          <el-input placeholder="请输入备注查询" v-model:value="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item label="商品重量/已-未设置-">
          <el-input placeholder="请输入备注查询" v-model:value="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item>
          <el-button type="primary" size="small" @click="onSearchQueryClick">查询</el-button>
          <el-button size="small" @click="onAddClick">新增</el-button>
        </nt-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading" @selection-change="onSelectionChange">
        <el-table-column label="#" width="45" type="selection">
        </el-table-column>
        
        <el-table-column label="商品图片" width="90" prop="iconUrl">
          <template #default="{row}">
            <el-image :src="row.iconUrl" width="50" height="50"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="商品标题"  prop="title">
        </el-table-column>
        <nt-short-name-column width="650"></nt-short-name-column>
        <el-table-column label="操作" width="140">
          <template #default="{row}">
            <el-button size="small" v-if="!row.isEdit" @click="row.isEdit=true">修改</el-button>
            <el-button size="small" v-if="row.isEdit" @click="onRowSaveClick(row)">保存</el-button>
            <el-button size="small" v-if="row.isEdit" @click="row.isEdit=false">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="space-between">
        <div>
          <el-button> 批量修改简称</el-button>
          <el-button> 批量修改重量</el-button>
          <el-button @click="onSaveClick"> 批量保存</el-button>
        </div>
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getGoodsList } from '@/service/resource/platform_goods'
import NtShortNameColumn from '../nt_short_name_column'
export default {
  name: "ShortNameList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    NtShortNameColumn
  },
  data() {
    return {
    }
  },
  methods: {
    async _loadList(params) {
      return await getGoodsList(Object.assign({},params));
    },
    async onRowSaveClick(row){
      
      let list = row.platformGoodsSkuList.map(el=>({
        shopId:el.shopId,
        goodsId:el.goodsId,
        skuId:el.skuId,
        shortName:el.shortName,
        weight:el.weight
      }))
      this.saveData(list)
      row.isEdit=false
    },
    async saveData(list){
      let res = {} //await saveShortName(list)
      if(res.code == 200){
        
        this.$message.success("操作成功")
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    // onSelectChange(selectedRowKeys, selectionRows) {
    //   this.selectedRowKeys = selectedRowKeys;
    //   this.selectionRows = selectionRows;
    // },
    onSaveClick(){
      let list = []
      this.selectedRows.forEach(el=>{
        let goodsSkuList = el.platformGoodsSkuList.map(el=>({
          shopId:el.shopId,
          goodsId:el.goodsId,
          skuId:el.skuId,
          shortName:el.shortName,
          weight:el.weight
        }))
        list.push(...goodsSkuList);
      })
      // 批量保存
      this.saveData(list)
      // console.log('xxxxx', this.selectedRows)
    }

    // async onRowSetDefaultClick(record) {
    //   let res = await this.setEntityDefault(this.EnumEntityType.GOODS_SHORT_NAME, { id: record.id })
    //   if (res.code == 200) {
    //     this.$message.success('操作成功')
    //     this.loadList()
    //   } else {
    //     this.$message.error(res.msg || '操作失败')
    //   }
    // },

    // async onRowEnabledClick(record, status) {
    //   let res = await this.updateEntityStatus(this.EnumEntityType.GOODS_SHORT_NAME, { id: record.id, status: status })
    //   if (res.code == 200) {
    //     this.$message.success('操作成功')
    //     this.loadList()
    //   } else {
    //     this.$message.error(res.msg || res.data)
    //   }
    // }
  }
}
</script>
