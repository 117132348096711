<!-- 备货单 -->
<template>
  <el-container class=" padding nt-height-100">
    <el-header class="height-auto no-padding no-margin display-flex flex-wrap row-gap-8">
      <QueryFieldList :queryModel="dynamicForm" :editors="editors"></QueryFieldList>
      <div>
        <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
        <el-button style="width:64px;" size="small" @click="onResetQueryClick" type="primary">重置</el-button>
      </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top-8">
      <el-tabs v-model="queryModel.summaryType" @tab-change="onQueryTypeChange"  class="nt-width-100 nt-tab-page nt-height-100 nt-box-panel">
        <!-- <template #add-icon>
          <el-button size="small" type="primary" @click="">xx</el-button>
        </template> -->
        <el-tab-pane label="按市场档口" name="marketSummary" lazy>
          <el-table  :data="datasource" height="100%" :row-key="getRowKey" default-expand-all  v-loading="isLoading"
          :span-method="marketNameSpanMethod" :indent="0" :cell-class-name="getCellClassName"
          border class="nt-resource-table padding-8" v-if="queryModel.summaryType=='marketSummary'">
            <el-table-column label="序号" type="index" width="55"></el-table-column>
            <el-table-column label="商品名称">
              <template #default="{row}">
                <span v-if="!row.goodsId" style="font-weight:bold;">
                  市场--{{ row.marketName }} 档口--{{ row.position }}  共{{row.quantity}}件商品， 品种数{{row.children.length}}， 采购成本{{ row.total }}
                </span>
                <div v-else class="display-flex">
                  <el-image :src="row.goodsThumb" style="width:45px;height:45px;"></el-image>
                  <div>
                    <div>{{ row.goodsName }}</div>
                    <div>商品ID:{{ row.goodsId }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="规格" width="220" prop="skuAttrs">
            </el-table-column>
            <el-table-column label="数量" prop="quantity" width="140">
            </el-table-column>
            <el-table-column label="成本" width="140" prop="marketPrice"></el-table-column>
            <el-table-column label="金额" width="140" prop="total"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="按商品汇总" name="summary" lazy>
          <el-table  :data="datasource" height="100%" border class="nt-resource-table padding-8" v-if="queryModel.summaryType!='marketSummary'" v-loading="isLoading">
          <el-table-column label="序号" type="index" width="60"></el-table-column>
          <el-table-column label="商品名称">
            <template #default="{row}">
              <div class="display-flex">
                <el-image :src="row.goodsThumb" style="width:45px;height:45px;"></el-image>
                <div>
                  <div>{{ row.goodsName }}</div>
                  <div>商品ID:{{ row.goodsId }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" width="220" prop="skuAttrs"></el-table-column>
          <el-table-column label="数量" width="140" prop="quantity"></el-table-column>
          <el-table-column label="成本价" width="140" prop="marketPrice">
            <template #default="{row}">
              {{ row.marketPrice?.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="总额" width="140" prop="total">
          </el-table-column>
        </el-table>
        </el-tab-pane>
      </el-tabs>
      
      
    </el-main>
    <el-footer class="margin-top-8 no-margin height-auto no-padding align-center">
      <el-space>
        <el-select placeholder="请选择打印模板" v-model="selecteTemplate"  value-key="id" @change="onTemplateChange" style="width:250px;">
          <el-option :label="item.templateName" :value="item" v-for="item in printTemplateList"></el-option>
        </el-select>
        <nt-printer v-model="printerName" placeholder="请选择打印机" style="width:220px"></nt-printer>

        <el-dropdown  @click="onPrintClick" trigger="click" type="primary"  split-button :hide-on-click="true">
          打印
          <template #dropdown>
            <el-dropdown-menu >
              <el-dropdown-item @click="onPreviewClick">预览</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button>导出</el-button>
      </el-space>
      
    </el-footer>
  </el-container>
  <print-preview ref="refPreview"></print-preview>
</template>
<script>

import  QueryFieldList from '../query/query_field_list'
import { pickGoodsByMarket, pickGoodsSummary } from '@/service/print_order/picking'
import NtPrinter from '@/views/plugin/nt_printer'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import {queryTemplateList,setDefaultTemplate} from '@/service/print_order/template'
import NtPrintManager from '@/utils/print_manager' //打印管理器
import PrintPreview from '@/views/preview/dispatch'
import {LoginUserStore} from '@/piana/login_user_service'
import {pickUpQuery} from '../query/config/query_order_editor'
export default {
  name: "pick_goods_summary_by_market",
  mixins: [DataListMixin],
  components: {
    PrintPreview,
    NtPrinter,
    QueryFieldList
  },

  data() {
    return {
      // activeTabName:'market',
      isPreviewVisible:false,
      selecteTemplate:null,
      printerName:null,
      previewData:null,
      printTemplateList:[],
      queryModel:{summaryType:"marketSummary"},
      dynamicForm: {}, 
      editors: pickUpQuery
    }
  },
  async created(){
    // 加载单据模版
    this.onResetQueryClick()
    let res = await queryTemplateList({templateType:'PICK_GOODS_TEMPLATE'})
    if(res.data){
      this.printTemplateList = res.data
      let matchItem = this.printTemplateList.find(item=>item.isDefault)
      if(matchItem){
        this.onTemplateChange(matchItem)
      } else if(this.printTemplateList.length>0){
        this.onTemplateChange(this.printTemplateList[0])
      }
    }

    this._printManager = new NtPrintManager((result, data) => {
    })
  },
  methods: {
    onResetQueryClick(){
      this.editors.forEach(item=>{
        this.dynamicForm[item.queryKey]= item.defaultValue || {}
      })
    },
    getRowKey(row){
      if(row.goodsId){
        return row.goodsId
      }
      return row.marketName + '_' + row.position
    },
    getCellClassName({row,columnIndex}){
      if(columnIndex ==1 && row.goodsId){ // 商品行
        return 'cell-goods'
      }
    },
    onTemplateChange(tpl){
      this.selecteTemplate = tpl
      if(this.selecteTemplate.printerName){
        this.printerName= this.selecteTemplate.printerName
      }
    },
    async _loadList(params){
      if(this.queryModel.summaryType == 'marketSummary'){
        return this.loadSummaryByMarket(params)
      } else {
        return this.loadGoodsSummary(params)
      }
    },
    marketNameSpanMethod({row,rowIndex,columnIndex }){
      if(!row.goodsId ){ // 市场档口行
        if(columnIndex ==1){
          return {
            rowspan:1,
            colspan:5
          }
        } else if(columnIndex>1){
          return {
            rowspan:1,
            colspan:0
          }
        }
        
      }
    },
    async loadSummaryByMarket(params){
      let res = await pickGoodsByMarket(Object.assign(params,this.queryModel,{dynamic:Object.values(this.dynamicForm)}))
      if(res.code == 200){
        res.data?.forEach(item=>{
          // 按市场 汇总总成本  总数量
          let eachTotal = 0
          let eachQty = 0
          item.children?.forEach(el=>{
            el.total= (el.marketPrice||0) * el.quantity
            eachTotal += el.total
            eachQty += el.quantity
            el.skuAttrs = this.formatSkuAttr(el.skuAttrs) // 将数据源的规格json字符串转为 描述
          })
          item.total = eachTotal
          item.quantity = eachQty
        })
      }
      return res
    },
    async loadGoodsSummary(params){
      let res = await pickGoodsSummary(Object.assign(params,this.queryModel,{dynamic:Object.values(this.dynamicForm)}))
      if(res.code == 200){
        res.data?.forEach(item=>{
          item.marketPrice = ((item.total||0)/item.quantity)*(1.00)
          item.skuAttrs = this.formatSkuAttr(item.skuAttrs) // 将数据源的规格json字符串转为 描述
        })
      }
      return res
    },
    formatSkuAttr(attrs){
      let arAttrs = JSON.parse(attrs||null) || []
      let ar = arAttrs.map(item=>{
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    onQueryTypeChange(){

      this.loadList()
    },
    onPrintClick(){
      if(!this.selecteTemplate){
        this.$message.info('请选择打印模板')
        return
      }
      if(!this.printerName){
        this.$message.info('请选择打印机器')
        return
      }
      let printObjectList = [] // 打印列表
      // 每个档口打印一份， 如果汇总方式，则整个details 将作为数据源
      let requestID = (new Date()).getTime()
      if(this.queryModel.summaryType=='summary'){
        let packInfo = {requestID: requestID++}
        packInfo.printDataVo = {
          templatePlatform:'SYSTEM',
          templateId: this.selecteTemplate.id,
          listNode:'children', // 网格单据节点
          printData: {children: this.datasource}
        }
        printObjectList.push(packInfo)
      }else{
        this.datasource.forEach((item)=>{
          let packInfo = {requestID: requestID++}
          packInfo.printDataVo = {
            templatePlatform:'SYSTEM',
            templateId: this.selecteTemplate.id,
            listNode:'children', // 网格单据节点
            printData: item
          }
          printObjectList.push(packInfo)
        })
      }
      
      this._printManager.print(this.printerName,false,printObjectList)
    },
    onPreviewClick(){
      if(!this.selecteTemplate){
        this.$message.info('请选择打印模板')
        return
      }
      this.$refs.refPreview.show('打印预览',{
        documents: this.queryModel.summaryType=='summary'? [{children:this.datasource}] : this.datasource,
        templateId: this.selecteTemplate.id,
        listNode:'children'
      })
    },
    onQueryClick(){
      this.loadList()
    }
  }
}
</script>
