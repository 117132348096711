<!-- 订单操作日志 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin">
      <el-space wrap>
        <el-select placeholder="订单编号" style="width:120px;">
          <el-option value="1">订单编号</el-option>
          <el-option value="2">运单号</el-option>
        </el-select>
        <el-input></el-input>
        <el-button size="small">查询</el-button>
        
      </el-space>
      
    </el-header>
    <el-main class=" no-padding nt-box-panel">
      <el-table :data="[{}]" height="100%">
        <el-table-column label="操作人"></el-table-column>
        <el-table-column label="操作内容"></el-table-column>
        <el-table-column label="操作时间"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top">
      <el-row justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" small
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>


export default {
  name: "BIZ_COMPANY",
  components: {
    
  },

  data() {
    return {
      radio1:null,
      value:null
    }
  },
  methods: {
  }
}
</script>