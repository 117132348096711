
<template>
  <el-container class="padding nt-height-100">
    <el-aside class="margin-right nt-box-panel nt-bg-white" width="250px" >
      <nt-dict-tree @change="onPlatformClick" parent-key="SYS_E_PLATFORM"></nt-dict-tree>
    </el-aside>
    <el-main class="padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="no-margin align-right height-auto no-padding-bottom">
          <span style="color:red;">授权方式：1通过应用市场;2独立使用系统后进行授权</span>
          <el-button type="primary" v-if="selectedPlatform"> 去授权</el-button>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table height="100%" border ref="table" size="middle" :data="datasource" v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left">
            </el-table-column>
            <el-table-column label="电商平台" width="110" prop="platform"></el-table-column>
            <el-table-column label="授权账号" width="140" prop="shopName"></el-table-column>
            <el-table-column label="店铺图标" width="120" prop="shop_icon"></el-table-column>
            <el-table-column label="OpenId" prop="openId"></el-table-column>
            <el-table-column label="公司名称" width="160" prop="companyName"></el-table-column>
            <el-table-column label="公司简介" width="120" prop="companyDesc"></el-table-column>
            <el-table-column label="授权时间" width="200" prop="printerName"></el-table-column>
            <el-table-column label="操作" width="100" >
              <template #default="{row}">
                <el-button size="small" type="primary">重新授权</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="margin-top height-auto no-padding">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>

</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtDictTree from '@/views/plugin/nt_dict_tree'
export default {
  name: "EcommercePlatform",
  mixins: [DataListMixin],
  components: {
    NtDictTree,
    NtFormItem
  },
  data() {
    return {
      selectedPlatform: null
    }
  },
  
  methods: {
    async _loadList(params) {
      if(!this.selectedPlatform) return
      return await this.getPageEntityList(this.EnumEntityType.THIRD_AUTH,
        Object.assign(params,{authType:'COMMERCE',platform:this.selectedPlatform}, this.queryModel)
       )
    },
    async onPlatformClick(dict){
      this.selectedPlatform = dict.configKey
      this.loadList()
    }
  }
}
</script>