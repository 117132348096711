
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchQueryClick" >
        <el-form-item label="仓库">
          <nt-dropdown-grid configKey="store" v-model="queryModel.storeId"
                 placeholder="请选择仓库" :searchable="true"
                :iconSearch="false"  style="width:100%;"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" rowKey="goodsSkuId" :data="datasource" v-loading="isLoading">
        <el-table-column label="商品编码" prop="goodsCode" width="120">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" width="200">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="100">
        </el-table-column>
        <el-table-column label="型号" prop="model" width="100">
        </el-table-column>
        <el-table-column label="主计量单位" prop="unitName"  width="120">
        </el-table-column>
        <el-table-column label="现存量(主)" prop="quantity" width="120">
        </el-table-column>
        <el-table-column label="现存量(包装)" width="140">
          <template #default="{row}">
            <span>*箱*件*包</span>
          </template>
        </el-table-column>
        <el-table-column label="结存金额" prop="total"  width="120">
          <template #default="{row}">
            {{ (row.total).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
import { getOutSummaryList } from '@/service/report/rpt_stock'
import MaterialInOutList from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import { queryMaterialCategoryTreeList } from '@/api/api'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    MaterialInOutList
  },
  data() {
    return {
      detailVisible: false,
      toggleSearchStatus: false,

      // 查询条件
      queryParam: {
        categoryId: '',
        materialParam: '',
        position: '',
        zeroStock: '0',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      currentWeight: 0,
      storeSelected: [],
      storeList: [],
      categoryTree: [],

    }
  },
  async created() {
    // this.getStoreData()
    let res = await this.getEntityList(this.EnumEntityType.STORE, {})
    if (res.code == 200) {
      this.storeList = res.data
    }
  },
  methods: {
    loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      queryMaterialCategoryTreeList(params).then((res) => {
        if (res) {
          that.categoryTree = [];
          for (let i = 0; i < res.length; i++) {
            let temp = res[i];
            that.categoryTree.push(temp);
          }
        }
      })
    },
    async _loadList(params) {
      return await getOutSummaryList(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },

  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>