// import Vue from 'vue'
import { mapState } from "vuex";
import store from '@/store/'

// const mixinsComputed = Vue.config.optionMergeStrategies.computed
// const mixinsMethods = Vue.config.optionMergeStrategies.methods
// console.log('xxxxx',mapState(store,['layoutMode','navTheme','primaryColor','colorWeak','multipage','fixedHeader','contentWidth','autoHideHeader','sidebarOpened']))

const mixin = {
  computed: {
    // ...mapState(store,['layoutMode','navTheme','primaryColor','colorWeak','multipage','fixedHeader','contentWidth','autoHideHeader','sidebarOpened'])
      
    // ...mapState({
    //   layoutMode: state => state.app.layout,
    //   navTheme: state => state.app.theme,
    //   primaryColor: state => state.app.color,
    //   colorWeak: state => state.app.weak,
    //   multipage: state => state.app.multipage,//多页签设置
    //   fixedHeader: state => state.app.fixedHeader,
    //   fixSiderbar: state => state.app.fixSiderbar,
    //   contentWidth: state => state.app.contentWidth,
    //   autoHideHeader: state => state.app.autoHideHeader,
    //   sidebarOpened: state => state.app.sidebar.opened
    // })
  }
}

const mixinDevice = {
  computed: {
    // ...mapState({
    //   device: state => state.app.device,
    // })
  },
  methods: {
    isMobile () {
      return false // this.device === 'mobile'
    },
    isDesktop () {
      return true // this.device === 'desktop'
    },
    wrapClassNameInfo () {
      if(this.device === 'desktop') {
        return 'ant-modal-cust-warp depot-mask'
      } else {
        return 'ant-modal-cust-warp'
      }
    }
  }
}

export { mixin, mixinDevice }