
<template>
  <nt-list-layout>
    <template #toolbar>
      <nt-bill-list-toolbar :hidden="['close','activate']" @command="onToolbarClick">
        <template #title>销售出库单列表</template>
      </nt-bill-list-toolbar>
    </template>
    <template #header>
      <el-form  @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom" >
        <el-form-item label="单据编号" class="no-margin-bottom" >
          <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期"  >
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" 
          start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD" ></nt-date-range>
        </el-form-item>
        <el-form-item label="商品信息" >
          <el-input placeholder="请输入条码、名称、型号" v-model="queryModel.keyword"></el-input>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        :data="datasource"
        height="100%"
        size="large"
        @selection-change="onSelectionChange"
        class="nt-bill-table-list"
        v-loading="isLoading">
        <el-table-column label="#" width="40" type="index" fixed="left"/>
        <el-table-column type="selection" width="40" align="center"/>
        <nt-bill-no-column label="单据编码" width="165" idName="id"  show-status>
          <template #extra="{row}">
            <el-tag type="info"  v-if="row.status == '1' && row.paymentStatus == 0" size="small"> 未付款</el-tag>
            <el-tag type="warning" v-if="row.status == '1' && row.paymentStatus == 1"> 部分付款</el-tag>
            <el-tag type="success"  v-if="row.status == '1' && row.paymentStatus == 2"> 已付款</el-tag>
            <el-tag  v-if="hasFinishQuantity(row)" type="danger">[退]</el-tag>
          </template>
        </nt-bill-no-column>
        
        <el-table-column label="客户" width="220" prop="companyName">
        </el-table-column>
        <el-table-column label="单据日期" width="100" align="center" prop="billTime">
          <template #default="scope">
            {{formatShortDate(scope.row.billTime) }}
          </template>
        </el-table-column>
        <el-table-column label="商品总数" width="90">
          <template #default="scope">
            {{ scope.row.baseQuantitySummary }}
            <!-- <el-tag style="position: absolute;right:-19px;top:-15px;transform: scale(.8);" v-if="getFinishQuantity(scope.row)" color="red">[退]</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column label="金额合计" width="100" prop="total" align="right">
          <!-- <template #default="scope">
            {{ formatFenTotal(scope.row.total) }}
          </template> -->
        </el-table-column>
        <el-table-column label="已付款" width="100" prop="settleTotal" align="right">
          <!-- <template #default="scope">
            {{ formatFenTotal(scope.row.settleTotal) }}
          </template> -->
        </el-table-column>
        <el-table-column label="收款日期" width="100" align="center" prop="planTime">
          <template #default="scope">
            {{formatDate(scope.row.planTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <nt-trade-item-column  width="320" :hideColumns="['finishQuantity','executeStatus']">
          <!-- <template #execute-status="data"></template> -->
        </nt-trade-item-column>
        <el-table-column label="源销售订单" width="155"  prop="linkNo">
          <template #default="{row}">
            <el-text truncated line-clamp="1" size="small">{{ row.linkNo }}</el-text>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="200"  prop="remark">
        </el-table-column>
        <el-table-column label="制单人" width="120" prop="userName">
        </el-table-column>
        <el-table-column label="制单时间" width="160" prop="createTime">
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['ENABLE','DISABLE','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-list-layout>
  
  <el-dialog
    v-model="isDetailVisible" destroyOnClose width="75%"
    class="nt-none-border-dialog"
    title="销售出库单"
    :close-on-click-modal="false"
    @close="isDetailVisible=false"
  >
    <purchase-in-modal  @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible =false" :readOnly="viewOnly"   :entityId="currentRecord?.id" v-if="isDetailVisible"></purchase-in-modal>
  </el-dialog>
  <nt-result-dialog ref="refResult"></nt-result-dialog>
</template>
<script>
  
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import PurchaseInModal from './detail'
  
  import NtDateRange from '@/views/plugin/nt_date_range'
  import NtTradeItemColumn from '@/views/plugin/nt_trade_item_column'
  import NtQueryPanel from '@/views/plugin/nt_query_panel'
  
  export default {
    name: "PurchaseOrderList",
    mixins:[DataListMixin],
    components: {
      PurchaseInModal,
      NtQueryPanel,
      NtDateRange,
      NtTradeItemColumn,
      
    },
    data () {
      return {
        currentRecord:{},
        toggleSearchStatus:false,
        // 查询条件
        queryModel: {
          billNo: "",
          searchMaterial: "",
          companyId: "",
          creator: "",
          handsPersonId: "",
          status: "",
          remark: "",
          startTime: this.moment().format('YYYY-01-01'),
          endTime: this.moment().format('YYYY-MM-DD'),
        }
      }
    },
    methods: {
      async _loadList(params){
        return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({billType:'SALE_OUT'},params));
      },
      formatGoodsName(row){
        let nameList = row.tradeItemList.map(item=>item.goodsName)
        return nameList.join(",")
      },
      
      onAddClick(){
        this.currentRecord = {}
        this.viewOnly = false
        this.isDetailVisible = true
      },
      
      async onRowDeleteClick(record){
        let res = await this.deleteEntity(this.EnumEntityType.TRADE_HEAD, {id:record.id})
        if(res.code==200){
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || '删除失败')
        }
      },
      
      onRowEditClick(record){
        this.currentRecord = record
        this.viewOnly = false
        this.isDetailVisible = true
      },
      onBillNoClick(record){
        this.currentRecord = record
        this.viewOnly=true
        this.isDetailVisible = true
      },
      onSearchQueryClick(){
        this.loadList()
      },
      onSearchResetClick(){
        this.queryModel = {}
        this.resetPage = true
        this.loadList(true)
      },
      
      hasFinishQuantity(record){
        let qty = 0
        record.tradeItemList.forEach(el => qty+=el.finishQuantity)
        return qty;
      },
      getQuantity(record){
        let qty = 0
        record.tradeItemList.forEach(el => qty+=el.quantity)
        return qty;
      },
      
      onDetailSaveSuccessHandler(){
        this.isDetailVisible =false
        this.loadList()
      },
      async onVerifyClick(record,isVerify){
        let res = await this.updateEntityStatus(this.EnumEntityType.TRADE_HEAD, {status: isVerify? '1':'0', id: record.id})
        if(res.code===200){
          this.$message.success('操作成功')
          this.loadList()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      },
      async onBatchVerifyClick(isVerify){ // 批量审核-弃审操作
        let billIds = this.selectedRows.map(item=>item.id)
        if(billIds.length==0){
          this.$message.error('请选择需要操作的业务单据')
          return
        }
        let res = await this.batchUpdateEntityStatus(this.EnumEntityType.TRADE_HEAD, {status: isVerify? '1':'0', ids: billIds})
        if(res.code===200){
          // 弹窗显示批量操作结果
          this.$refs.refResult.showDialog('审核结果',res.data)
          this.loadList()
        } else {
          this.$message.error(res.msg || '操作失败')
        }
      }
    }
  }
</script>