
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form layout="inline" @keyup.enter.native="onSearchQueryClick">
        <el-row :gutter="24">
          <el-col :md="6" :sm="24">
            <el-form-item label="单据时间">
              <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" 
          start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD" ></nt-date-range>
            </el-form-item>
          </el-col>

          <el-col :md="5" :sm="24">
            <el-form-item label="仓库">
              <el-select mode="multiple" :maxTagCount="1" optionFilterProp="children" showSearch style="width: 100%"
                placeholder="请选择仓库" v-model="storeSelected">
                <el-select-option v-for="(store, index) in storeList" :value="store.id" :key="index">
                  {{ store.storeName }}
                </el-select-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="24">
            <el-form-item label="商品信息">
              <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            </span>
          </el-col>
          <template v-if="toggleSearchStatus">
            <el-col :md="5" :sm="24">
              <el-form-item label="类别">
                <el-tree-select style="width:100%" :dropdownStyle="{ maxHeight: '200px', overflow: 'auto' }" allow-clear
                  :treeData="categoryTree" v-model="queryParam.categoryId" placeholder="请选择类别">
                </el-tree-select>
              </el-form-item>
            </el-col>
            <el-col :md="5" :sm="24">
              <el-form-item label="仓位货架">
                <el-input style="width: 100%" placeholder="请输入仓位货架查询" v-model="queryParam.position"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="24">
              <el-form-item label="零库存">
                <el-select v-model="queryParam.zeroStock">
                  <el-option value="0">隐藏</el-option>
                  <el-option value="1">显示</el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" height="100%" rowKey="goodsSkuId" :data="datasource" v-loading="isLoading">
        <el-table-column label="单据编号" width="160">
          <template #default="scope">
            <el-space>
              <a @click="onViewBillDetailClick(scope.row)">{{ scope.row.billNo }}</a>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="单据类型" prop="billType" width="120">
          <template #default="scope">
            {{ this.EnumBillType[scope.row.billType] }}
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName" width="180">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName" width="160">
        </el-table-column>
        <el-table-column label="单位" prop="unitName">
        </el-table-column>

        <el-table-column label="仓库" prop="storeName">
        </el-table-column>
        <el-table-column label="单据日期" width="120" align="center" prop="billTime">
          <template #default="scope">
            {{formatDate(scope.row.billTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="quantity">
        </el-table-column>
        <el-table-column label="单价" prop="price">
          <template #default="scope">
            {{ ((scope.row.price) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="税率" prop="taxRate">
          <template #default="scope">
            {{ (scope.row.taxRate / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="税额" prop="taxTotal">
          <template #default="scope">
            {{ (scope.row.price * scope.row.taxRate / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="入库时间" prop="createTime" width="180">
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>
import { getInDetailList } from '@/service/report/rpt_stock'
import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getEntityList } from '@/service/resource/general'
import { EnumEntityType, EnumBillType } from '@/utils/enum_utils'
import { queryMaterialCategoryTreeList } from '@/api/api'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "inDetail",
  mixins: [DataListMixin],
  components: {
    BillViewerPage, NtDateRange
  },
  data() {
    return {
      EnumBillType,
      detailVisible: false,
      toggleSearchStatus: false,
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      currentWeight: 0,
      storeSelected: [],
      storeList: [],
      categoryTree: [],
    }
  },
  async created() {
    let res = await getEntityList(EnumEntityType.STORE, {})
    if (res.code == 200) {
      this.storeList = res.data
    }
  },
  mounted() {
  },
  methods: {
    loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      queryMaterialCategoryTreeList(params).then((res) => {
        if (res) {
          that.categoryTree = [];
          for (let i = 0; i < res.length; i++) {
            let temp = res[i];
            that.categoryTree.push(temp);
          }
        }
      })
    },
    async _loadList(params) {
      return await getInDetailList(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewBillDetailClick(record) {
      this.$refs.refViewer.showBill(record.billType, record.billId)
    }
  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>