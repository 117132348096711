<template>
  <el-container class="padding nt-bg-page" style="height:450px;">
    <el-header class="height-auto no-padding no-margin">
      <span style="font-weight:bold;font-size:18px;">物流发货</span>
    </el-header>
    <el-main class="no-padding nt-box-panel margin-top">
      <el-table :data="datasource" border height="100%" class="nt-resource-table">
        <el-table-column type="index" width="45"></el-table-column>
        <el-table-column label="订单信息">
          <template #default="{ row }">
            <div>
              <div><span>订单号:</span><span>{{ row.orderNo }}</span></div>
              <div v-if="row.waybillNo"><span>运单号:{{ row.packageNum }}:</span>
                <span>{{ row.waybillNo }}</span>
              </div>
              <div v-else>
                <span>包裹#{{ row.packageNum }}:</span>
                <span >待取号</span>
              </div>
              <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品数量" prop="quantity" width="90"></el-table-column>
        <el-table-column label="操作结果" width="160">
          <template #default="{ row }">
            <!-- 等待生成  正在获取面单 正在打印面单  打印完成 ==》有错误信息？ -->
            <!-- <div v-if="row.errorMsg" style="color:var(--el-color-danger);">{{ row.errorMsg }}</div> -->
            <el-icon class="is-loading" v-if="[1].includes(row.processStatus)">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus">{{ formatProcessStatus(row.processStatus) }}</span>
            <span v-else>就绪</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding no-margin">
      <div class="margin-top">共{{ packageList.length }}个包裹</div>
      <div class="align-center">
        <el-button type="primary" v-if="taskStatus!='DONE'" :loading="taskStatus=='EXECUTTING'" @click="onStartClick" >开始发货</el-button>
        <el-button type="primary" v-if="taskStatus=='DONE'" @click="onFinishHandler">关闭</el-button>
      </div>
    </el-footer>
  </el-container>

</template>

<script>

  import { createDispatch,queryCreateResult} from '@/service/print_order/dispatch'
  import NtWaybillDispatchManager from '@/utils/waybill_dispatch_manager' // 面单发货管理器
  export default {
    data () {
      return {
        taskStatus: 'READY', // REDAY 就绪  EXECUTTING--正在进行  DONE 完成
        datasource: this.packageList || []
      }
  },
  props:{
    packageList:{ // 需要操作的包裹列表
      type: Array,
      required: true
    }
  },
  mounted(){
    // this.datasource = []
  },
  async created(){
    
    this._disatchManager = new NtWaybillDispatchManager((resultList,result ) => {
      console.log('fking list',resultList)
      if (result.type == 'success') {
        // 根据结果，判断是否满足打印, 根据list
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            if (item.success) {
              matchItem.isDispatch=true
              matchItem.processStatus = 2 // 发货完成
            } else {
              matchItem.processStatus = -1 // 预发货失败
              matchItem.errorMsg = item.message
            }
            
          }
          
        })
        // 判断是否都已完成
        let allFinished =  !this.datasource.some(item=>item.done!=true)
        if(!allFinished){
          this.taskStatus =  'DONE'
          this.taskPhase = null //无任何任务信息
        }
        
      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        list.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.done=true
          }
        })
      } else if (result.type == 'overTime') { // 查询[发货]结果超时
        let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
        if (matchItem) {
          matchItem.done=true
        }
      } else if(result.type == 'query'){
        // 轮询网络异常 TODO
      }
      
    })
  },
  methods:{
    formatProcessStatus(status) {
      let info = {
        '1': '正在发货..',
        '2': '发货已完成',
        '-1': '发货失败'
      }
      return info[status] || status
    },
    async onStartClick(){
      this.taskStatus =  'EXECUTTING' //开始执行任务
      this.datasource.forEach(el => {
        el.done = false
        el.processStatus = 1 // 正在获取面单
      })

      this._disatchManager.dispatchWaybill(this.datasource)
    },
    
    onFinishHandler(){
      this.$emit("close") // 完成
    }
  }
}
</script>
