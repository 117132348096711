<template>
  <el-form :model="formModel" ref="frmEdit" label-width="110" v-loading="isLoading" class="nt-height-100">
    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <nt-form-item label="打印机名称" prop="printerName" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入打印机名称" v-model="formModel.printerName" />
        </nt-form-item>
        <nt-form-item label="打印机编码">
          <el-input placeholder="请输入打印机编码" v-model="formModel.printerNo" />
        </nt-form-item>
        <nt-form-item label="打印机品牌" prop="brandType" :rules="formValidator('required', '请选择品牌')">
          <nt-dropdown-grid configKey="sysKv" v-model="formModel.brandType" :requestParam="{parentKey:'SYS_PRINTER_BRAND'}"
            v-model:text="formModel.brandTypeName" :disabled="viewOnly" placeholder="请选择品牌" :searchable="true"
            :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item label="流量卡号">
          <el-input placeholder="请输入流量卡号" v-model="formModel.phoneNo" />
        </nt-form-item>
        <nt-form-item label="密钥" prop="secretKey" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入密钥" v-model="formModel.secretKey" />
        </nt-form-item>
        <nt-form-item label="备注" class="no-margin-bottom">
          <el-input :rows="2" placeholder="请输入备注" v-model="formModel.remark" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding height-auto padding-top-16">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "StoreModal",
  mixins: [DataEditMixin],
  components: { NtFormItem },
  data() {
    return {
    }
  },
  props: {
    entityId: {
      type: String
    },
    shopId:{
      type: String,
      required:true
    },
    addressType:{
      type:String,
      required:true
    }
  },
  created() {
  },
  methods: {
    async _loadEntity() {
      if (this.entityId) {
        return await this.getEntity(this.EnumEntityType.CLOUD_PRINTER, { id: this.entityId })
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId}, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))
      return this.saveEntity(this.EnumEntityType.CLOUD_PRINTER, postData)
    }
  }
}
</script>