<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding nt-box-panel nt-bg-white">
        <nt-form-item  label="姓名" prop="staffName" :rules="formValidator('required','请输入姓名')">
          <el-input placeholder="请输入姓名" v-model="formModel.staffName"  />
        </nt-form-item>
        
        <nt-form-item  label="部门" prop="deptId" :rules="formValidator('required','请选择部门')">
          <nt-dropdown-grid configKey="dept" v-model="formModel.deptId"
                    v-model:text="formModel.deptName" :disabled="viewOnly" placeholder="请选择部门" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item  label="类型" prop="staffType" :rules="formValidator('required','请选择类型')">
          <nt-dropdown-grid configKey="sysKv" v-model="formModel.staffType" :requestParam="{parentKey:'SYS_STAFF_TYPE'}"
                    v-model:text="formModel.staffTypeName" :disabled="viewOnly" placeholder="请选择员工类型" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item  label="备注" prop="remark">
          <el-input placeholder="请输入备注说明" v-model="formModel.remark"  />
        </nt-form-item>
        
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
  
</template>
<script>

  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import NtFormItem from '@/views/plugin/nt_form_item'
  export default {
    name: "StaffModal",
    mixins: [DataEditMixin],
    components:{NtFormItem},
    data () {
      return {
        
      }
    },
    props:{
      entityId:{
        type:String
      }
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await this.getEntity(this.EnumEntityType.STAFF, {id: this.entityId})
        }
      },
      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        return this.saveEntity(this.EnumEntityType.STAFF, postData)
      },
    }
  }
</script>