
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>收发存汇总</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按仓库小计"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        
        <el-table-column label="仓库" prop="storeName" width="160">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" width="220">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="100">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="100">
        </el-table-column>
        <el-table-column label="期初库存" align="right" header-align="center">
          <el-table-column label="数量" prop="initBaseQuantity"  align="right" width="90" header-align="center">
            <template #default="{row}">
              {{ (row.initBaseQuantity||0).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="单价" prop="sku" width="90"  align="right" header-align="center">
            <template #default="{row}">
              <span v-if="row.initBaseQuantity">{{ ((row.initTotal||0)/row.initBaseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="initTotal" width="90" align="right" header-align="center">
            <template #default="{row}">
              {{ (row.initTotal||0).toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="本期入库" align="center" header-align="center">
          <el-table-column label="数量" prop="inBaseQuantity" width="90"  align="right" header-align="center">
          </el-table-column>
          <el-table-column label="单价"  width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.inBaseQuantity!=0">{{ (row.inCostTotal/row.inBaseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="inCostTotal" align="right" header-align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="本期出库" align="center">
          <el-table-column label="数量" prop="outBaseQuantity"  align="right" width="90" header-align="center">
          </el-table-column>
          <el-table-column label="单价"  width="90"  align="right" header-align="center">
            <template #default="{row}">
              <span v-if="row.outBaseQuantity!=0">{{ (row.outCostTotal/row.outBaseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="outCostTotal" align="right" width="90" header-align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="期末结存" align="center">
          <el-table-column label="数量"  width="90"  align="right" header-align="center">
            <template #default="{row}">
              {{ getBaseQuantitySubtotal(row) }}
            </template>
          </el-table-column>
          <el-table-column label="单价"  width="90"  align="right" header-align="center">
            <template #default="{row}">
              {{ (getSubtotal(row)/getBaseQuantitySubtotal(row)).toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" align="right" header-align="center">
            <template #default="{row}">
              {{ getSubtotal(row) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getSummary } from '@/service/report/rpt_repository'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getSummary(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    getBaseQuantitySubtotal(row){
      return (row.initBaseQuantity||0)  + (row.inBaseQuantity||0)  - (row.outBaseQuantity||0) 
    },
    getSubtotal(row){
      return (row.initTotal||0)  + (row.inCostTotal||0)  - (row.outCostTotal||0) 
    },
  }
}
</script>

