/**
 * 面单查询条件
 */


let  abnormalQuery= [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_PRINT', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型', 
            
            options:[// 针对el-select 有效
                {label:'打印时间',value:'TIME_RANGE_PRINT'},
                {label:'面单获取时间',value:'TIME_RANGE_CREATE'},
                {label:'面单回收时间',value:'TIME_RANGE_CANCEL'}
            ]
        }
    },
    {
        queryKey:'lockStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'异常类型', 
            options:[
                {label:'有异常',value:'INNER_ABNORMAL'},
                {label:'无异常',value:'INNER_NO_ABNORMAL'},
                {label:'偏远地区',value:'INNER_FAR_AREA'}
            ]
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型',
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{
            queryType:'RECEIVER_NAME', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    }
]

let  pickUpQuery= [ // 备货单
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        defaultValue:{
            queryType:'TIME_RANGE_CREATE', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型', 
            options:[// 针对el-select 有效
                {label:'按下单时间',value:'TIME_RANGE_CREATE'},
                {label:'按付款时间',value:'TIME_RANGE_PAY'},
            ],
            defaultStartValue:'$day7',
            defaultEndValue:'$today'
        }
    },
    
    
    {
        queryKey:'template', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'快递单打印状态', 
            options:[
                {label:'已打印',value:'WAYBILL_PRINT'},
                {label:'未打印',value:'WAYBILL_NOT_PRINT'},
            ]
        }
    },
    {
        queryKey:'flagType', // 用于确定查询的字段
        cType: 'NtSelectFlag', //组件类型
        label:'旗帜标注', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType:'FLAGS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'旗帜标注', 
        }
    },
    {
        queryKey:'dispatchStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        props:{ // 组件属性
            placeholder:'发货状态', 
            options:[
                {label:'未发货',value:'SUCCESS'},
                {label:'待发货',value:'CANCELED'},
                {label:'已发货',value:'CANCELED'},
                {label:'预发货',value:'CANCELED'},
            ]
        }
    },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        props:{ // 组件属性
            placeholder:'留言备注类型', 
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{
            queryType:'WAYBILL_NO', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    }
]


export {abnormalQuery,pickUpQuery} 