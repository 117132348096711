<!-- 动态基础资料详情页,用于弹窗组件，快捷新增资料 -->
<template>
  <div>
    <el-dialog
      v-model="dynamicVisible" destroyOnClose width="80%" append-to-body :close-on-click-modal="false"
      class="nt-resource-dialog"
      title="详情"
      @close="dynamicVisible=false"
    >
      <!-- 动态组件加载... -->
      <component :is="dynamicEditor" @success="onSaveSuccessHandler" @cancel="dynamicVisible = false"></component>
    </el-dialog>
  </div>
</template>
<script>
  
  import {createEditorComponent} from './component.js'
  export default {
    name: "dynamicResourceEditor",
    components: {
    },
    data () {
      return {
        dynamicVisible:false,
        dynamicEditor:null
      }
    },
    methods: {
      async showEditor(configKey,callback){// 显示详情弹窗
        this._callback = callback // 回调方法
        this.dynamicEditor = await createEditorComponent(configKey)
        if(!this.dynamicEditor){
          // 创建组件失败，组件不存在
          console.error('编辑组件配置信息有误，请检查:' + configKey)
        }
        this.dynamicVisible = true
      },
      onSaveSuccessHandler(entity){
        this._callback && this._callback(entity) // 新增结果通过回调返回
        this.dynamicVisible = false
      }
    }
  }
</script>