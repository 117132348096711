<!-- 
  
  -->
<template>
  <div class="padding nt-bg-white">
    <div  class="padding nt-box-panel">
      
      <el-form :inline="false" :model="formModel" ref="frmEdit" class="margin-top" label-width="100px">
        <nt-form-item label="店铺" prop="tenantId" :rules="formValidator('required')">
          <NtSelectShop @defaultShop="defaultShopHandler" v-model="currentShop" @change="onShopChangeHandler"
            class="nt-width-100"></NtSelectShop>
        </nt-form-item>

        <nt-form-item label="开始时间" prop="startDate" :rules="formValidator('required')">
          <el-date-picker  v-model="formModel.startDate" type="date" placeholder="请选择开始时间" style="width:100%;"/>
        </nt-form-item>
        <nt-form-item label="截止时间" prop="endDate" :rules="formValidator('required')">
          <el-date-picker  v-model="formModel.endDate" :disabled-date="disabledDateHandler" type="date" placeholder="请选择截止时间"  style="width:100%;"/>
        </nt-form-item>
        <nt-form-item>
          <div style="color:var(--el-color-warning)">同步时间跨度不能超过30天</div>
        </nt-form-item>
      </el-form>
    </div>
    <el-row justify="center" class="margin-top">
      <el-button type="info" @click="onCancelClick"> 取消</el-button>
      <el-button type="primary" @click="onConfirmClick"> 确定</el-button>
    </el-row>
  </div>
</template>

<script>

import formValidator from '@/utils/formValidator'
import NtSelectShop from '@/views/plugin/nt_select_shop'
import NtFormItem from '@/views/plugin/nt_form_item'
import {getSyncInfo} from '@/service/resource/tenant'
import * as dayjs from 'dayjs'
export default {
  data() {
    return {
      formModel: {},
      currentShop: null
    }
  },

  components: { NtSelectShop,NtFormItem },
  async created(){
  },
  methods: {
    formValidator,
    async onShopChangeHandler(shop) {
      this.currentShop = shop
      this.formModel.tenantId = this.currentShop.shopTenantId
      this.formModel.startDate = dayjs().$d //dayjs().subtract(6, 'day').$d
      this.formModel.endDate = dayjs().$d
    },
    disabledDateHandler(dt){
      // todo
    },
    defaultShopHandler(shop) { // 接收组件初始化后的默认门店
      this.onShopChangeHandler(shop)
    },
    async onConfirmClick() {
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      let data = Object.assign({}, this.formModel)
      this.$emit('success', data)
    },
    onCancelClick() {
      this.$emit('cancel')
    },

  }
}
</script>