<template>
  <el-drawer v-model="isVisible" destroyOnClose size="700px" title="高级设置" :z-index="99"
      @close="isVisible = false">
    <div>
      <div>
        <div>订单合并</div>
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="同步订单时自动合并" value="1" />
            <el-checkbox label="手工拆分订单自动合并" value="2" />
            <el-checkbox label="预售订单自动合并" value="3" />

            <el-checkbox label="提示手工合并订单" value="4" />
            <el-checkbox label="订单商品自动拆分" value="5" />
            <el-checkbox label="合并相同备注/留言 " value="6" />
            <el-checkbox label="智能合并订单" value="7" />
            <el-checkbox label="设置规则" value="8" />
            <el-checkbox label="未付款不提示可手工合并" value="9" />
          </el-checkbox-group>

        </div>
      </div>
      <div>
        <div>同步设置</div>
        <div>
          <el-checkbox-group v-model="checkList1">
            <el-checkbox label="登录时自动同步订单" value="1" />
            <el-checkbox label="同步时选择店铺同步" value="2" />
            <el-checkbox label="自动同步" value="3" />
          </el-checkbox-group>

        </div>
      </div>

      <div>
        <div>发货设置</div>
        <div>
          <el-checkbox-group v-model="checkList2">
            <el-checkbox label="开启预发货功能" value="1" />
            <el-checkbox label="发货后刷新" value="2" />
            <el-checkbox label="发货后不刷新" value="3" />
            <el-checkbox label="发货后询问是否刷新" value="4" />
            <el-checkbox label="多单号自动追加到卖家备注" value="5" />
          </el-checkbox-group>

        </div>
      </div>

      <div>
        <div>打印提醒设置</div>
        <div>
          <el-checkbox-group v-model="checkList3">
            <el-checkbox label="快递单打印后提醒发货" value="1" />
            <el-checkbox label="快递单打印后自动发货" value="2" />
            <el-checkbox label="快递单打印后提醒打印发货单" value="3" />
            <el-checkbox label="快递单打印完成后刷新" value="4" />
            <el-checkbox label="发货单打印后提醒打印快递单" value="5" />
          </el-checkbox-group>

        </div>
      </div>
      <div>
        <div>其他设置</div>
        <div>
          <el-checkbox-group v-model="checkList3">
            <el-checkbox label="显示包裹备注" value="1" />
            <el-checkbox label="【包裹备注】自动复制订单的【我打备注】" value="2" />
            <el-checkbox label="拆分订单后自动刷新" value="3" />
          </el-checkbox-group>

        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>


  export default {
    name:"createWaybill",
    data () {
      return {
        isVisible:false,
        checkList:null,
        checkList1:null,
        checkLis2:null,
        checkList3:null,
        checkList4:null,
        checkList5:null
      }
  },
  components:{},
  props:{
    
  },
  async created(){
    
  },
  methods:{
    showDialog(){
      this.isVisible= true
    }
  }
}
</script>
