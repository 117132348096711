
<!-- 应收应付余额查询(按往来单位)，按业务员 -->
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>应收应付余额查询</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="往来单位">
          <nt-dropdown-grid  configKey="company" v-model="queryModel.id"
          placeholder="请选择往来单位" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="往来单位" prop="companyName" width="220">
        </el-table-column>
        <el-table-column label="应收" header-align="center">
          <el-table-column label="应收金额" prop="unTakeTotal" header-align="center">
          </el-table-column>
          <el-table-column label="应收笔数" prop="unTakeCount" header-align="center">
          </el-table-column>
          <el-table-column label="应收款占比" header-align="center">
            <template #default="{row}">
              {{ takeTotal && ((row.unTakeTotal/takeTotal*100).toFixed(2)+'%') }}
            </template>
          </el-table-column>
          <el-table-column label="预收余额" prop="pretakeBalance" header-align="center">
            
          </el-table-column>
        </el-table-column>
        <el-table-column label="应付" header-align="center">
          <el-table-column label="应付金额" prop="unPayTotal" header-align="center">
          </el-table-column>
          <el-table-column label="应付笔数" prop="unPayCount" header-align="center">
          </el-table-column>
          <el-table-column label="应付款占比" header-align="center">
            <template #default="{row}">
              {{ payTotal && ((row.unPayTotal/payTotal*100).toFixed(2) +'%') }}
            </template>
          </el-table-column>
          <el-table-column label="预付余额" prop="prepayBalance"header-align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="往来余额" header-align="center">
          <template #default="{row}">
            {{ (row.debtPayTotal -row.prepayBalance) - (row.debtTakeTotal-row.pretakeBalance) }}
          </template>
        </el-table-column>
        <el-table-column ></el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPaymentBalanceList } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getNowFormatYear } from "@/utils/util"
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "payment_summary",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      payTotal:0, // 总应付款
      takeTotal:0, // 总应收款
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        companyId: '',
        startTime: getNowFormatYear() + '-01-01',
        endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  methods: {
    onDateChange: function (value, dateString) {
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      let res = await getPaymentBalanceList(Object.assign(params, { }))
      if(res.code){
        res.data.forEach(item=>{
          this.payTotal += item.unPayTotal
          this.takeTotal += item.unTakeTotal
        })
      }
      return res
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    }

  }
}
</script>