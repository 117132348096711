import { createApp } from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import store from './store/'
// import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/index.css'  // 引入 ElementPlus 组件样式
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/permission'
import { createPinia } from 'pinia'
import '@/assets/less/common.less'
import config from '@/defaultSettings'
import '@/assets/less/common.less'
import NtDropdownGrid from '@/views/plugin/dropdown_grid'
import NtBillNoColumn from '@/views/plugin/nt_bill_no_column'
import NtOperationColumn from '@/views/plugin/nt_operation_column'
import NtLoadingDialog from '@/views/plugin/nt_loading_dialog.js'
import spliterDirective from '@/views/directive/spliter_directive'
// import {LoginUserStore} from '@/piana/login_user_service'

// import '@/assets/iconfont.css' // 引入iconfont 图标
// import ntLoading from '@/views/plugin.'

const pinia  = createPinia()
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component("NtDropdownGrid",NtDropdownGrid)
app.component("NtBillNoColumn",NtBillNoColumn)
app.component("NtOperationColumn",NtOperationColumn)
// app.component("NtLoadingDialog",NtLoadingDialog)
app.directive('spliterDirective',spliterDirective)
window.$app=app
window.$store = store
window.$pinia = pinia
window.$router = router
// window.$ntLoading = NtLoadingDialog
// LoginUserStore()
// setupFilter(app)
app.config.productionTip = false
app.use(Storage, config.storageOptions)
app.use(ElementPlus, { locale: zhCn })
app.use(pinia)
app.use(router)
app.use(NtLoadingDialog)

window.ResizeObserver = class _NewResizeObserver extends ResizeObserver {
  constructor(callback) {
  super(() => window.requestAnimationFrame(() => callback.apply(this, arguments)));
  }
}

app.mount('#app')