<template>
  <el-container  class="padding nt-height-100">
    <el-aside width="250px" class="margin-right nt-box-panel nt-bg-white">
      <nt-dict-tree show-all @change="onDeliveryChange" parent-key="SYS_DELIVERY"></nt-dict-tree>
    </el-aside>
    <el-main class="no-padding nt-box-panel">
      <el-container class="padding nt-height-100">
        <el-header class="no-padding align-right no-margin margin-bottom height-auto">
          <span style="color:red;">自定义模版添加后，需关联授权账户--三方授权，或使用账户密码--部分三方平台</span>
          <el-button v-if="deliveryId"  type="primary">新建模版</el-button>
          <el-button v-if="deliveryId" @click="onSystemTemplateChooseClick" type="primary">选择系统模版</el-button>
        </el-header>
        <el-main class="no-padding">
          <el-table :data="datasource" height="100%" border class="nt-resource-table">
            <el-table-column label="模版名称" prop="templateName"></el-table-column>
            <el-table-column label="物流公司" prop="deliveryId"></el-table-column>
            <el-table-column label="高度" prop="pageHeight"></el-table-column>
            <el-table-column label="宽度" prop="pageWidth"></el-table-column>
            <el-table-column label="绑定授面单账号">
              <template #header>
                <span>绑定授面单账号</span>
                <el-tooltip placement="top"
                   content="支持绑定三方物流平台或物流公司账号，可通过【系统管理】->【三方平台授权管理】添加物流平台信息">
                  <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
              </template>
              <template #default="{row}">
                <!-- 自定义模版关联特定的物流平台，选择账号时，按平台过滤 -->
                  <nt-dropdown-grid configKey="thirdAuthDeliver" v-model="row.thirdAuthId" v-model:text="row.thirdAuthName" 
                    placeholder="请选择物流平台或账号" :searchable="false" 
                    @choose="(account) => onRowWaybillAccountChange(account.id,row)"></nt-dropdown-grid>
              </template>
            </el-table-column>
            <el-table-column label="默认打印机" width="200" prop="printerName">
              <template #header>
                <span>默认打印机</span>
                <el-tooltip placement="top"
                   content="目前系统支持菜鸟打印组件及视频号小店打印组件，如果无打印机信息，请先确保三方打印组件已安装并运行">
                  <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <el-select v-model="row.printerName" placeholder="请选择默认打印机"
                  @change="val => onPrinterChangeHandler(val, row)">
                  <el-option v-for="item in printerList" :key="item.name" :label="item.name" :value="item.name" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="默认发货地址" width="100"></el-table-column>
            <el-table-column label="操作" width="180" prop="title">
              <template #default="{row}">
                <el-button @click="_=>onDesignClick(row)" size="small" style="margin-right:12px;">设计模版</el-button>
                <el-button @click="_=>onDeleteClick(row)" size="small" style="margin-right:12px;">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="margin-top-8 height-auto">
          <el-row type="flex" justify="end">
            <el-pagination
              :current-page="this.pageInfo.pageIndex"
              :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]"
              :total="this.pageInfo.total"
              background 
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="onPageIndexChange"
              @size-change="onPageSizeChange"
            />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
    <el-dialog v-model="isTemplateChooseVisible" :close-on-click-modal="false" title="模版选择" class="nt-resource-dialog" width="850px" :destroyOnClose="true">
      <systemTemplate @cancel="isTemplateChooseVisible=false" @onChoose="onSystemTemplateChooseHandler" :template-type="billType" :delivery-id="deliveryId" /> 
    </el-dialog>
    <el-drawer
      title="模版设计"
      :with-header="false"
      size="85%"
      v-model="isDesignlVisible"
      destroyOnClose
      append-to-body
      class="nt-designer nt-inner-drawer"
    >
      <designerPage :entityId="selectedRecord.id" :billType="billType"  v-if="isDesignlVisible"/>
      <i class="nt-drawer-close" @click="isDesignlVisible=false">关闭</i>
    </el-drawer>
</template>

<script>
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {queryPageTemplateList,createFromSys,deleteTemplate,bindWaybillAccount, bindPrinter} from '@/service/print_order/template'
  import systemTemplate from './sys_template_choose.vue'
  import designerPage from '@/views/designer'
  import NtDictTree from '@/views/plugin/nt_dict_tree'
  
  export default {
    name: "template-list",
    mixins:[DataListMixin],
    components:{systemTemplate,designerPage,NtDictTree},
    data () {
      return {
        isDesignlVisible:false,
        isTemplateChooseVisible:false,
        selectedRecord:null,
        deliveryId:null,
        templateType:'WAYBILL_TEMPLATE',
        
      }
    },
    inject:['printerList'],
    methods:{
      async _loadList(params){
        // if(!this.deliveryId) return
        return await queryPageTemplateList(Object.assign({deliveryId:this.deliveryId,templateType:this.templateType},params));
      },

      _loadFinished(){
        
      },

      onSystemTemplateChooseClick(){
        this.isTemplateChooseVisible = true
      },

      async onSystemTemplateChooseHandler(sysTemplate){
        let res = await createFromSys({id:sysTemplate.id}) 
        if(res.code ===200){// TODO  封装统一方法，200是进行callback 调用
          this.isTemplateChooseVisible = false
          this.$message.success('操作成功') 
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      },

      onDesignClick(record){
        this.selectedRecord = record
        this.isDesignlVisible =true
      }, 

      async onDeleteClick(record){
        
        let res = await deleteTemplate({id:record.id}) 
        if(res.code ===200){
          this.isTemplateChooseVisible = false
          this.loadList()
        } else {
          this.$message.error(res.msg || res.data)
        }
      },

      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },

      onDeliveryChange(dict){
        this.deliveryId = dict.configKey
        this.loadList()
      },
      async onPrinterChangeHandler(val, row) {
        row.printerName = val
        let res = await bindPrinter({
          templateId: row.id,
          printerName: row.printerName
        })
        if (res.code === 200) {
          this.$message.success("更新成功")
        } else {
          this.$message.error(res.msg || res.message || res.data)
        }
      },
      async onRowWaybillAccountChange(accountId,row) { // 设置面单账号
        row.waybillAccountId = accountId
        let res = await bindWaybillAccount({
          templateId: row.id,
          accountId: accountId
        })
        if (res.code === 200) {
          this.$message.success("更新成功")
        } else {
          this.$message.error(res.msg || res.message || res.data)
        }
      },
    }
  }
</script>

