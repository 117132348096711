import { axios as service } from '@/utils/request'


export const createBillNo = (data) => {
  return service({
    url: '/sequence/createBillNo',
    method: 'post',
    data
  })
}


/*
  基本操作（所有的页面）：分页列表，增加 删除，修改
 */

export const getPageEntityList = (entityType,data) => {
  return service({
    url: `/${entityType}/list`,
    method: 'post',
    data
  })
}

// 不带分页数据
export const getEntityList = (entityType,data) => {
  return service({
    url: `/${entityType}/all`,
    method: 'post',
    data
  })
}

export const getEntity = (entityType,data) => {
  return service({
    url: `/${entityType}/get`,
    method: 'post',
    data
  })
}

export const getEntityByPosition = (entityType,data) => {
  return service({
    url: `/${entityType}/getByPosition`,
    method: 'post',
    data
  })
}

export const saveEntity = (entityType,data) => {
  return service({
    url: `/${entityType}/save`,
    method: 'post',
    data
  })
}


export const deleteEntity = (entityType,data) => {
  return service({
    url: `/${entityType}/delete`,
    method: 'post',
    data
  })
}
export const deleteEntityBatch = (entityType,data) => {
  return service({
    url: `/${entityType}/deleteBatch`,
    method: 'post',
    data
  })
}
export const updateEntityStatus = (entityType,data) => {
  return service({
    url: `/${entityType}/updateStatus`,
    method: 'post',
    data
  })
}

export const batchUpdateEntityStatus = (entityType,data) => {
  return service({
    url: `/${entityType}/batchUpdateStatus`,
    method: 'post',
    data
  })
}

export const checkIsNameExist = (entityType,data) => {
  return service({
    url: `/${entityType}/checkIsNameExist`,
    method: 'post',
    data
  })
}

export const setEntityDefault = (entityType,data) => {
  return service({
    url: `/${entityType}/default`,
    method: 'post',
    data
  })
}

/** 废弃 */
export const getSysKvList = (data) => {
  return service({
    url: '/sys/kvList',
    method: 'post',
    data
  })
}

/* 根据parentKey 获取数据字典列表 parentKey =null 时获取数据分类 */
export const getSysKvListByParent = (data) => {
  return service({
    url: '/sys/kvListByParent',
    method: 'post',
    data
  })
}

/* 获取所有非叶子节点配置项，用于构建右侧树 */
export const getSysKvListWithoutLeaf = (data) => {
  return service({
    url: '/sys/kvListWithoutLeaf',
    method: 'post',
    data
  })
}

/* 获取用于构建右侧树，系统级数据字典 */
export const getDictNodeListByParent = (data) => {
  return service({
    url: '/sys/kvListWidthChildren',
    method: 'post',
    data
  })
}



/* 保存租户数据字典 */
export const saveTenantKv = (data) => {
  return service({
    url: '/sys/saveTenantKv',
    method: 'post',
    data
  })
}

/* 批量保存租户数据字典 */
export const saveBatchTenantKv = (data) => {
  return service({
    url: '/sys/saveBatchTenantKv',
    method: 'post',
    data
  })
}

/* 查询租户数据字典 */
export const getTenantKv = (data) => {
  return service({
    url: '/sys/getTenantKv',
    method: 'post',
    data
  })
}

/* parentKey + configKey 查询租户数据字典 */
export const getTenantKvByConfigKey = (data) => {
  return service({
    url: '/sys/getTenantKvByConfigKey',
    method: 'post',
    data
  })
}


/*根据parentKey 查询租户数据字典 */
export const getTenantKvByParentKey = (data) => {
  return service({
    url: '/sys/getTenantKvByParentKey',
    method: 'post',
    data
  })
}



/* 保存系统数据字典的别名 */
export const saveSysKvAlias = (data) => {
  return service({
    url: '/sys/saveSysKvAlias',
    method: 'post',
    data
  })
}

/* 快捷查询方案列表 */
export const getQuickSearchPlanList = (data) => {
  return service({
    url: '/sys/quickSearchPlanList',
    method: 'post',
    data
  })
}

/* 保存查询方案 */
export const saveQuickSearchPlan = (data) => {
  return service({
    url: '/sys/saveQuickSearchPlan',
    method: 'post',
    data
  })
}


/* 删除快捷查询方案*/
export const deleteQuickSearchPlan = (data) => {
  return service({
    url: '/sys/deleteQuickSearchPlan',
    method: 'post',
    data
  })
}






/* 查询拣货码列表 */
export const getPickingRuleList = (data) => {
  return service({
    url: '/sys/pickingRuleList',
    method: 'post',
    data
  })
}

/* 保存拣货码 */
export const savePickingRule = (data) => {
  return service({
    url: '/sys/savePickingRule',
    method: 'post',
    data
  })
}


/* 删除拣货码规则*/
export const deletePickingRule = (data) => {
  return service({
    url: '/sys/deletePickingRule',
    method: 'post',
    data
  })
}

/* 设置拣货码排序*/
export const updatePickingRuleSort = (data) => {
  return service({
    url: '/sys/savePickingRuleSort',
    method: 'post',
    data
  })
}

/* 查询队列运行历史*/
export const getSystemQueueHistoryList = (data) => {
  return service({
    url: '/sys/getSystemQueueHistoryList',
    method: 'post',
    data
  })
}


/* 查询队列运行计划*/
export const getQueueList = (data) => {
  return service({
    url: '/sys/getQueueList',
    method: 'post',
    data
  })
}
