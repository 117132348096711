<template>
  <el-container class="padding nt-height-100">
    <el-aside class="margin-right nt-box-panel nt-bg-white" width="180px" v-spliter-directive>
      <nt-platform-shop-tree @change="onShopNodeClick"></nt-platform-shop-tree>
    </el-aside>
    <el-main class="no-padding nt-box-panel" style="overflow: hidden;">
      <el-container class="nt-height-100 ">
        <el-aside width="200px" class="padding nt-bg-white border-right " v-spliter-directive>
          <el-tree ref="refTree" nodeKey="categoryId" :data="categoryData" class="nt-list-node"
          @node-click="onCategoryClick" :expand-on-click-node="false" :props="{ label: 'categoryName' }"
            default-expand-all />
        </el-aside>
        <el-main class="padding">
          <el-container class="nt-height-100">
            <el-header class="height-auto">
              <el-row type="flex" justify="space-between">
                <el-form inline>
                  <el-form-item label="商品名称">
                    <el-input placeholder="输入登录名称模糊查询"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-checkbox label="显示未关联"></el-checkbox>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary">同步商品信息</el-button>
                  </el-form-item>
                </el-form>
              </el-row>
            </el-header>
            <el-main class="no-padding">
              <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource" v-loading="isLoading">
                <!-- <el-table-column label="所属门店" width="120" prop="shopName"></el-table-column> -->
                <el-table-column label="商品编码" width="120" prop="goodsId"></el-table-column>
                <el-table-column label="商品标题" prop="title"></el-table-column>
                <el-table-column label="商品分类" prop="parentIds">
                  <template #default="{row}">
                    {{formatCategoryName(row)}}
                  </template>
                </el-table-column>
                <sku-column prop="platformGoodsSkuList" width="500" class="xxx"></sku-column>
                <!-- <el-table-column label="状态" width="100" prop="status">
                  <template #default="scope">
                    <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
                    <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
                  </template>
                </el-table-column> -->

              </el-table>
            </el-main>
            <el-footer class="no-padding height-auto margin-top">
              <el-row type="flex" justify="end">
                <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
                  :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
                  layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
                  @size-change="onPageSizeChange" />
              </el-row>
            </el-footer>
          </el-container>
        </el-main>
        
      </el-container>

    </el-main>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail/'
import SkuColumn from './nt_sku_column'
import { getCategoryList, getGoodsList } from '@/service/resource/platform_goods'
import NtPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
export default {
  name: "PlatformGoodsCategory",
  mixins: [DataListMixin],
  components: {
    NtPlatformShopTree,
    detailPage,
    SkuColumn
  },
  data() {
    return {
      categoryData: [],
      selectedShopId: null,
      selectedCategoryId: null
    }
  },

  methods: {
    async _loadList(params) {
      if (!this.selectedShopId) return // 不选择店铺，不显示商品列表
      let res = await getGoodsList(Object.assign({ shopId: this.selectedShopId, cascadeCategoryId: this.selectedCategoryId }, params));
      return res
    },
    async loadCategoryTree() { // 每次切换店铺时，重新加载分类树，并默认选择全部分类
      let res = await getCategoryList({ shopId: this.selectedShopId })
      let getChildren = function (parentId, data) {
        let children = data.filter(item => item.parentId == parentId)
        children.forEach(item => {
          item.children = getChildren(item.categoryId, data)
        })
        return children
      }
      if (res.code == 200) {
        this._cacheCategoryList = res.data // 用户网格列表分类多名称显示
        // 根据返回结果，构建前端树， 最多三级
        let nodeList = getChildren(null, res.data)
        let allNode =  {categoryName:'全部',categoryId:null}
        nodeList.splice(0,0, allNode)
        this.categoryData = nodeList
        // 默认选择--全部节点
        setTimeout(_=>{
          this.onCategoryClick(allNode) 
        },0)
      }
    },
    onShopNodeClick(shop) {
      this.selectedShopId = shop.id
      this.selectedCategoryId = null
      this.loadCategoryTree()
    },

    onCategoryClick(node) {
      // 加载当前分类下所有的商品
      this.selectedCategoryId = node.categoryId
      this.$refs.refTree?.setCurrentNode(node)
      this.loadList()
    },
    formatCategoryName(row){
      if(!row.categoryIds){
        return
      }
      let categoryNames = []
      row.categoryIds.split(',').forEach(catId=>{
        let category = this._cacheCategoryList.find(el=>el.categoryId == catId)
        if(category){
          categoryNames.push(category.categoryName)
        }
      })
      return categoryNames.join(';')
    }

  }
}
</script>