/**
     1 由于菜鸟组件 模版是通过url指定，必须对外公共获取模版的url 接口
        a. 模版的配置信息来源于设计器，需在返回时，将配置信息注入到模版中,将配置的字段，网格列表显示格式动态生成模版
          生成后的模版配置信息，写入缓存---7天有效，模版发生变更时，清除缓存
        b. 在打印请求时，将数据源通过websocket 传入
        c. 通过模版支持的js 明细，渲染数据（同视频号打印组件的mastcahe的方式）


    ************************************************************************************************************

      2. 将模版配置信息，已数据源的方式传入，通过js 生成打印模版信息，并合业务数据，实现打印效果----优先使用此方式
        优点： 模版无需通过后端动态生成，及缓存管理，通过脚本，交由云打印组件渲染，减少服务器压力
              1. 直接打印配置模版通过七牛云储存，实现cdn加速，服务器压力减少至0 ---（在出现异常时，切换至自己的服务器）
        需验证
          1: 根据设计与配置属性，通过内嵌js 方法 生成配置属性，动态列
          2: 通过vue 样式涉及到的方法，逐一将需要用到的功能 转换为js代码
          4: 通过data 节点，将数据源和模版配置信息，一起传入
              data:{
                ds:{billId:1,details:[]}, //数据源
                tpl:{padding:{},body:{},header:{},footer:{}}, // 模版配置信息---本地缓存
              }
      
*/

import { defineStore } from 'pinia'
import {buildPrintData,buildDesignerTemplate} from '@/views/mustache/wx_video_creator.js' // 微信视频号打印组件生成工具
import PrintCreator from '@/utils/print_utils'
// TODO  支持远程打印
export const WS_URL = 'ws://127.0.0.1:12705'
// import { RefNumEnum, WS_URL } from '@/utils/constant'
import { ElMessageBox } from 'element-plus'

export const useWeixinPrintServiceStore = defineStore('wexinPrintService', {
  state: () => ({
    ws: null, // 全局存储一个 websoket ，避免重复创建
    isConnected: false, // 判断菜鸟组件是否以及启动
    reconnectInterval: 5000,
    reconnectTimer: null,
    callbackEventObj: {},
    isShowErrBox: false,
    printerList:[] // 打印机列表
  }),
  actions: {
    connect(cb, callbackType) {
      this.callbackEventObj[callbackType] = cb // 打印成功或则失败的回调函数，场景不同用callbackType 来判断对于的回调
      return new Promise((resolve,reject)=>{
        if (this.ws && this.ws.readyState === 1)  return

        this.ws = new WebSocket(WS_URL)
        
        this.ws.onopen = () => {
          console.log('WebSocket connected')
          
          this.isConnected = true
          resolve(this.isConnected)
        }
        this.ws.onmessage = (event) => {
          console.log('WebSocket message received:', event.data)
          // 处理收到的消息
          this.subscribe(JSON.parse(event.data || ''), cb)
        }
        this.ws.onclose = () => {
          console.log('WebSocket disconnected')
          this.isConnected = false
          this.reconnect(cb, callbackType)
          reject(false) // 连接失败
        }
        this.ws.onerror = (error) => {
          this.isConnected = false
          console.log('连接视频号打印组件失败，请检查是否启动:', error)
          // this.reconnect(cb, callbackType)
          reject(false) // 连接失败
        }
      })
      
      
    },
    send(message) {
      if (this.isConnected) {
        this.ws.send(message)
      } else {
        console.warn('WebSocket is not connected')
      }
    },
    close() {
      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
    },
    subscribe(data, callBackFn) {
      
      switch (data.command) {
        case 'getPrinterList':
          if (data.printerList) {
            // TODO
            // data.printerList = data.printerList.filter((i) => {
            //   return i.status == 'enable'
            // })
          }
          // 缓存起来
          localStorage.setItem('PRINTERS', JSON.stringify(data.printerList || []))
          console.log('获取打印机列表', data.printerList)
          this.printerList = data.printerList
          break
        case 'getPrinterConfig':
          // console.log('获取打印机配置')
          console.log(data.printer)
          break
        case 'print':
          // 将不同平台的打印机结果转为统一的标准格式
          // console.log('print', data)
          data.success = data.results[0].success
          data.msg = data.results[0].failureReason
          this.callbackEventObj['print_result']?.(data) // 触发打印回调
          break
        
        case 'setPrinterConfig':
          console.log('设置打印机配置')
          console.log(data)
          break
      }
    },
    // 这里的重连可以设置一个次数后，停止重连
    reconnect(cb, callbackType) {
      if (!this.reconnectTimer) {
        console.log(`WebSocket will reconnect in ${this.reconnectInterval} ms`)
        this.reconnectTimer = setTimeout(() => {
          console.log('WebSocket reconnecting...')
          this.connect(cb, callbackType).catch(_=>false)
          this.reconnectTimer = null
        }, this.reconnectInterval)
      }
    },
    stopReconnect() {
      if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer)
        this.reconnectTimer = null
      }
    },
    startPrint(printerName,requestID,printConfig, isPreview=fase,printCallback){ // 开始打印  isPreview 预览模式， 此方式，需要配合自定义设计模版进行
      
      if(printCallback){ // 订阅打印结果回调
        this.callbackEventObj['print_result'] = printCallback  //一次有效？
      }
      if(printConfig.templatePlatform == 'SYSTEM'){ // 自定义模版--即咋们自己的系统提供的模版
        this.printCustomData(printerName,requestID,printConfig, isPreview)
      } else { // 电商平台的模版, 直接打印报文
        // 待支持: 针对视频号小店， 返回print info 是，增加返回 自定义 信息--覆盖商品自定义区域 customInfo(需指定模版？自行设计), 面单补充信息 extraData 可覆盖寄件人信息
        let obj = {
          command: isPreview? 'preview':'print', // 预览方式 preview 打印 print
          version: '2.0', // 文档要求必填，注意
          requestID: requestID, 
          printType: 1, // 正式使用视频号打印必须 1  
          printer: printerName,
          taskList: [{
            taskID: requestID, // String, 调用方保证唯一
            printInfo: printConfig.printData // String, [获取打印报文]接口返回的print_info
          }]
        }      
        this.send(JSON.stringify(obj))
      }
      
    },
    async printCustomData(printerName,requestID,printConfig, isPreview){ // 自定义打印，加载自定义模版+合并业务数据

      let printCreator = new PrintCreator()
      let detailDatasource = []
      if(printConfig.listNode){ // 指定打印对象的网络列表数据源
        detailDatasource  = printConfig.printData[printConfig.listNode] || [] // 提取打印数据中网络列表指定数据源
      }

      // TODO 测试 分页逻辑，自由容器 需要分页？
      let printData = await printCreator.createPrintPageData(printConfig.templateId,printConfig.printData/*业务数据*/,detailDatasource/*网格列表数据源 */)
      
      let documentList = []
      let mainData = {}
      Object.keys(printData).filter(key=> !key.startsWith('_')).forEach(key=>{
        mainData[key] = printData[key]
      })

      let printTemplate = buildDesignerTemplate(printData._tpl) // 根据打印单据配置信息，生成业务数据渲染前的打印模版

      printData._pages.forEach((pageData,index)=>{
        let currentPageData = {_printList:pageData,_tpl: printData._tpl}
        // 根据打印模版，结合当前需要打印的数据，通过mustache，生成最后的打印数据，即转码后的html
        let encodedHmtl = buildPrintData(printTemplate,mainData,currentPageData)
        documentList.push({
          taskID:  'Page:' + (index+1), //String, 调用方保证唯一
          printInfo: encodedHmtl,// buildPrintData(printTemplate,{},pageData,mergeInfo), // String, [获取打印报文]接口返回的print_info
        })
        
      })
      // return
      // 方案一： 自定义打印，当前无法 页面实际高宽
      // let obj = {
      //   command: isPreview? 'preview':'print', // 预览方式 preview 打印 print
      //   requestID: requestID, //调用方保证唯一
      //   "version": "2.0", 
      //   printType: 2, // Number 打印类型，默认为 1，打印固定高度的面单；如果为2，则打印任意自定义内容，需要传递 size 参数指定纸张尺寸，printInfo 改为传递 base64 格式的 html
      //   size: {
      //     width: printData._tpl.pageWidth, // 纸张尺寸，单位毫米，printType 为 2 时必传
      //     height:printData._tpl.pageHeight
      //   },
      //   printer: printerName,
      //   taskList: documentList
      // }
      // 方案二  ，默认官方模板打印格式
      let obj = {
        "command": isPreview? 'preview':'print', 
        "version": "2.0", 
        "printer": printerName, 
        "requestID": requestID,
        "taskList": documentList
      }
      this.send(JSON.stringify(obj))
    }
  }
})

