
<!-- 付款单核销详情
    收款核销执行(按收款)， 收款单为主，核销明细作为子集
    收款核销执行(按应收)  销售单为主，付款单作为子集
-->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按商品-仓库1 仓库2"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" rowKey="billId" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="单据日期" width="120" align="center" prop="billTime">
          <template #default="scope">
            {{formatDate(scope.row.billTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="单据类型" prop="billType" width="120">
        </el-table-column>
        <el-table-column label="业务类型" prop="bizType" width="100">
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName" width="260">
        </el-table-column>
        <el-table-column label="付款金额" prop="billTotal" width="90">
        </el-table-column>
        <el-table-column label="优惠金额" prop="discountTotal" width="90">
        </el-table-column>
        <el-table-column label="核销金额" prop="total" width="90">
        </el-table-column>
        <el-table-column label="核销类型" prop="" width="100">
        </el-table-column>
        <el-table-column label="核销单据类型" prop="itemBillType" width="130">
        </el-table-column>
        <el-table-column label="核销单据日期" prop="itemBillTime" width="120">
          <template #default="scope">
            {{formatDate(scope.row.itemBillTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="核销单据号" prop="itemBillNo" width="160">
        </el-table-column>
        <el-table-column label="核销金额" prop="itemTotal" width="90">
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>

  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPaymentDetailList } from '@/service/report/rpt_payment'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NTDateRange from '@/views/plugin/nt_date_range'
import { crossTableUtils } from '@/utils/table_summary'
export default {
  name: "stock_summary_store",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      crossCols:[],// 横向动态仓库列集合
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      let res = await getPaymentDetailList(Object.assign(params, {}))
      // 将仓库+ 商品 转为交叉形式的数据
      // let result = crossTableUtils(res.data,'companyId,companyName','billTotal,total,discountTotal','yearMonth')
      // this.crossCols = result.cols
      // res.data = result.data
      return res
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    }
  }
}
</script>
