
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="采购时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="操作" width="90">
          <template #default="scope">
            <el-space>
              <a @click="onViewDetailClick(scope.row)">查看详情</a>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="goodsName" width="140">
        </el-table-column>
        <el-table-column label="规格" prop="standard">
        </el-table-column>
        <!-- <el-table-column label="型号" prop="model">
        </el-table-column>
        <el-table-column label="颜色" prop="color">
        </el-table-column> -->
        <el-table-column label="主单位" prop="unitName">
        </el-table-column>
        <el-table-column label="SKU" prop="sku">
        </el-table-column>
        <el-table-column label="商品分类" prop="categoryName" width="100" align="right">
        </el-table-column>
        <el-table-column label="采购数量" prop="inQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="退货数量" prop="backQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="退货金额" prop="backTotal" width="90" align="right">
          <template #default="scope">
            {{ ((scope.row.backTotal)).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="实际采购数量" prop="quantity" width="120" align="right">
          <template #default="scope">
            {{ scope.row.inQuantity - scope.row.backQuantity }}
          </template>
        </el-table-column>
        <el-table-column label="实际采购金额" prop="total"  width="120" align="right">
          <template #default="scope">
            {{ ((scope.row.inTotal - scope.row.backTotal)).toFixed(2) }}
          </template>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPurchaseInTotalByGoods } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NtDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      },
      // defaultTimeStr:'',
      depotSelected: [],
      depotList: [],
      // 表头
      columns: [
        {
          title: '#', dataIndex: 'rowIndex', width: 40, align: "center",
          customRender: function (t, r, index) {
            return (t !== '合计') ? (parseInt(index) + 1) : t
          }
        },
        {
          title: '详情', dataIndex: 'action', align: "center", width: 120,
          scopedSlots: { customRender: 'action' }
        },

        { title: '名称', dataIndex: 'goodsName', width: 140, ellipsis: true },
        { title: '规格', dataIndex: 'standard', width: 100, ellipsis: true },
        // {title: '型号', dataIndex: 'model', width: 100, ellipsis:true},
        // {title: '颜色', dataIndex: 'color', width: 60, ellipsis:true},
        { title: '单位', dataIndex: 'unitName', width: 60, ellipsis: true },
        { title: 'SKU', dataIndex: 'sku', width: 60, ellipsis: true },
        { title: '商品分类', dataIndex: 'categoryName', width: 60, ellipsis: true },
        // {title: '仓位货架', dataIndex: 'position', width: 60, ellipsis:true},
        { title: '采购数量', dataIndex: 'inQuantity', width: 60, ellipsis: true },
        {
          title: '采购金额', dataIndex: 'inTotal', width: 60, ellipsis: true,
          customRender: function (t, r, index) {
            return (r.inTotal / 100).toFixed(2)
          }
        },
        { title: '退货数量', dataIndex: 'backQuantity', width: 60, ellipsis: true },
        {
          title: '退货金额', dataIndex: 'backTotal', width: 60, ellipsis: true,
          customRender: function (t, r, index) {
            return (r.backTotal / 100).toFixed(2)
          }
        },
        {
          title: '实际采购数量', dataIndex: 'quantity', width: 60, ellipsis: true,
          customRender: function (t, r, index) {
            return (r.inQuantity - r.backQuantity).toFixed(2)
          }
        },
        {
          title: '实际采购金额', dataIndex: 'total', width: 60, ellipsis: true,
          customRender: function (t, r, index) {
            return ((r.inTotal - r.backTotal) / 100).toFixed(2)
          }
        },

      ]
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getPurchaseInTotalByGoods(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'PURCHASE_IN,PURCHASE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

