<template>
  <div class="nt-package" :class="{ checked: pack.checked, relative: true }" >
    <div class="nt-box-panel padding-4 relative display-flex space-between padding-right-0 padding-left-8 column-gap-4">
      <div>
        <el-checkbox v-model="pack.checked" :disabled="!checkable"></el-checkbox>
      </div>
      <div class="flex-auto">
        <div style="line-height:16px;">
          <div style="font-weight:bold;">运单号:#{{ pack.packageNum }}</div>
          <el-link type="primary" @click.stop="onWaybillNoClick" v-if="pack.waybillNo">{{ pack.waybillNo }}</el-link>
          <span v-else style="color:var(--el-color-warning)">待取号</span>
        </div>
        <el-row justify="space-between" class="column-gap-4">
          <div style="line-height:16px;color:#999;width:calc(100% - 15px);">
            <span :title="pack.templateName" class=" el-text is-line-clamp" style="font-size: 12px;-webkit-line-clamp: 1;" v-if="pack.templateName">{{ pack.templateName }}</span>
            <span v-else>未设置面单模版</span>
          </div>
          <slot></slot>
        </el-row>
      </div>
      <div class="display-flex flex-column row-gap-4">
        <div :class="`package-status ${pack.waybillNo ? 'nt-waybill' : ''}`">运</div>
        <div :class="`package-status ${pack.printCnt ? 'nt-print' : ''}`">打</div>
        <div v-if="pack.isPreDispatch" class="package-status  nt-pre-send">预</div>
        <div v-else :class="`package-status ${pack.isDispatch ? 'nt-send' : ''}`">发</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  emits:['waybillNoClick'],
  props: {
    pack: {
      type: Object,
      default: _ => ({})
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onWaybillNoClick(){
      this.$emit('waybillNoClick')
    }
  }
}
</script>