<template>
  <el-popover 
  :virtual-ref="targetElement"
  virtual-triggering
  :visible ="isVisible"
  trigger="click"
  placement="left"
  :width="420">
    <div class="custom-waybill" style="column-count:2">
      <div  @click="onTemplateClick(tpl)"
        v-for="tpl in waybillTemplateList" :key="tpl.id">
        <div class="display-flex" style="column-gap: 2px;"><el-icon>
            <ElemeFilled />
          </el-icon>{{ tpl.templateName }}</div>
      </div>

    </div>
  </el-popover>

  <nt-result-dialog ref="refBatchResult"></nt-result-dialog>
</template>

<script>

import {updateWaybillTemplate} from '@/service/print_order/order'

import NtResultDialog from '@/views/plugin/nt_batch_result_dialog' //批量操作结果弹窗
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      waybillTemplateList: LoginUserStore().templateList,
      selectedTpl: null,
      isVisible: false,
      targetElement:null,
      packageList:[]
    }
  },
  components:{NtResultDialog},
  async created() {
  },
  methods: {
    show(refElement,packageList){
      this.targetElement = refElement
      this.packageList = packageList
      this.isVisible = true
    },
    hide() {
      this.isVisible = false
    },
    
    async onTemplateClick(template){
      this.isVisible = false
      
      let res = await updateWaybillTemplate({platform:template.platform,waybillTemplateId:template.templateId,waybillTemplateName:template.templateName, list:this.packageList})
        if(res.code == 200){
          res.data.resultList.forEach(item=>{
            if(item.success && item.businessNo){
              let ar = item.businessNo.split('_')
              let matchItem = this.packageList.find(el=>el.orderId==ar[0] && el.packageNum==ar[1])
              matchItem && (matchItem.templateName = template.templateName)
            }
          })
          this.$refs.refBatchResult.showDialog('操作结果',res.data)
        } else{
          this.$message.error(res.msg || res.message || res.data || '操作失败')
        }
    },
  }
}
</script>
<style lang="less" scoped>
.custom-waybill {
  flex-wrap: wrap;

  &>div {
    cursor: pointer;
    flex: 0 0 calc(50% - 8px);
    background-color: #f5f5f5;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 5px;

    .el-icon {
      font-size: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}</style>