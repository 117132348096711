
<template>
  <el-container class="nt-height-100 nt-bg-white">
    <el-main class="no-padding nt-bg-page">
      <el-tabs v-model="activeTabName" class="nt-tab-page nt-width-100 nt-height-100" >
        <el-tab-pane label="发货地址" name="send" lazy >
          <send-address></send-address>
        </el-tab-pane>
        <el-tab-pane label="退货地址" name="back" lazy >
          <back-address></back-address>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>
<script>

import SendAddress from './send_address'
import BackAddress from './back_address'
export default {
  name: "AddressList",
  components: {
    SendAddress,
    BackAddress
  },
  data() {
    return {
      activeTabName:'send'
    }
  },
  
  created() {
    
  },
  methods: {
    
  }
}
</script>
<style scoped lang="less">
  .bill-selector-tab{
    display:flex;
    :deep(.el-tabs__content){
      flex:1 1 100%;
      .el-tab-pane{
        height:100%;
      }
    }
  }
</style>