<template>
  <el-container class="no-padding" style="height:550px;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchClick">
        <el-row :gutter="24">
          <el-col :md="6" :sm="24">
            <nt-form-item label="名称" >
              <el-input placeholder="请输入名称查询" v-model="queryParam.companyName"></el-input>
            </nt-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <nt-form-item label="手机号码" >
              <el-input placeholder="请输入手机号码查询" v-model="queryParam.telephone"></el-input>
            </nt-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <nt-form-item label="联系电话" >
              <el-input placeholder="请输入联系电话查询" v-model="queryParam.phonenum"></el-input>
            </nt-form-item>
          </el-col>
          <el-col :md="6" :sm="24">
            <el-button type="primary" @click="onSearchClick">查询</el-button>
            <el-button  @click="onAddClick" type="danger">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border width="100%" ref="table" size="middle" :data="datasource"
      @selection-change="onSelectionChangeHandler"
        v-loading="isLoading">
        <el-table-column label="#" width="45" type="index" fixed="left"/>
        <el-table-column type="selection" v-if="multiple"></el-table-column>
        <el-table-column label="名称" prop="companyName">
          <template #default="{row}">
            {{ row.companyName }}
            <el-tag class="absolute right-top" type="info" size="small" v-if="!companyType"> {{ formatCompanyType(row.companyType) }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="联系人" width="120" prop="contacts"></el-table-column>
        <el-table-column label="手机号码" width="120" prop="telephone"></el-table-column>
        <el-table-column label="联系电话" width="110" prop="phoneNum"></el-table-column>
        <el-table-column label="电子邮箱" width="120" prop="email"></el-table-column>
        <el-table-column label="应付总额" width="100" prop="debitPayTotal">
          <template #default="{row}">
            {{row.debitPayTotal}}
          </template>
        </el-table-column>
        <el-table-column label="预付余额" width="100" prop="prepayBalance">
          <template #default="{row}">
            {{row.prepayBalance}}
          </template>
        </el-table-column>
        <el-table-column label="税率(%)" width="100" prop="taxRate"></el-table-column>
        <!-- <el-table-column label="排序" width="80" prop="sort"></el-table-column> -->
        <el-table-column label="状态" width="70" prop="enabled">
          <template #default="scope">
            <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
            <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="space-between">
        <el-pagination :current-page="this.pageInfo.pageIndex" size="small" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
          <div>
            <el-button @click="onCancelClick" type="info" size="small">取消</el-button> 
            <el-button type="primary" size="small" :disabled="!selectedRows.length" @click="onConfirmClick">确定(已选{{ selectedRows.length }})</el-button>
          </div>
      </el-row>
    </el-footer>
  </el-container>

  <!-- <el-dialog v-model="isDetailVisible" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :companyType="cType" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog> -->
</template>
<script>
// import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "CUSTOMER-LIST",
  mixins: [DataListMixin],
  components: {
    // detailPage,
    NtFormItem
  },
  data() {
    return {
      // 查询条件
      queryParam: {
        companyName: '',
        telephone: '',
        phonenum: ''
      },
      cType:null, // 新增时传参指定 往来单位性质
    }
  },
  props: {
    companyType:{
      type: Number
    },
    multiple:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.BIZ_COMPANY, Object.assign({ companyType: this.companyType}, params));
    },
    onSearchClick() {
      this.resetPage = true
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    handleCommand(cType){
      this.onAddClick(cType)
    },
    onAddClick(cType) {
      this.cType = cType
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    formatCompanyType(cType){
      let info={
        1:'客户',
        2:'供应商',
        3:'客户&供应商'
      }
      return info[cType] || cType
    },
    onCancelClick(){
      this.$emit('cancel')
    },
    onConfirmClick(){
      // 根据单选或复选 返回单个对象或集合
      this.$emit('choose', this.multiple? this.selectedRows: this.selectedRows[0])
    },
    onSelectionChangeHandler(selection) {
      this.selectedRows = selection
    },
  }
}
</script>
