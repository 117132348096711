
<template>
  <div class="nt-height-100" 
  :style="{
    background:'#fff'
    }" >
    <div class="display-flex" :style="{ alignContent:'start', background:'#ddd',
    flexWrap:'wrap', alignItems: 'start',columnGap:config.columnGap+'px',rowGap:config.rowGap+'px'}">
      <div v-for="(data,index) in datasource" :key="index" class="nt-bg-white"
        :style="{
          width: getElementWidth(),
          height: ((config.height||200)+'px'),
          paddingLeft: (pageSetting?.padding?.left||0)+'px',
          paddingRight: (pageSetting?.padding?.right||0)+'px',
          boxSizing:'border-box'}">
        <div :style="{
          width: '100%',
          height: '100%',
          position: 'relative',
          boxSizing:'border-box'}">
          <div class="preview-item"
          :style="{ width: element.width + 'px', height: element.height + 'px', left: element.left + 'px', top: element.top + 'px' }"
          v-for="element in config.items">
          <component :is="ctrl[element._t]" :config="getDisplayElement(element,data)" :isDesignMode="false" />
        </div>
        </div>
        
      </div>
    </div>
    
    
  </div>
</template>

<script >

import { qr_code, barcode, text, v_line, h_line, rectangle, sys_text } from '@/views/designer/control/all'
import { getReflectValue, parseVarExpr } from '@/utils/reflect'
export default {
  data () {
    return {
      ctrl: { barcode, qr_code, text, v_line, h_line, rectangle, sys_text },
    }
  },
  props:{
    config:{},
    pageSetting:{},
    datasource:{} //数据源
  },
  
  methods:{
    getDisplayElement(ctrl/*组件设计信息 */,data/*数据源*/){
      let clone = JSON.parse(JSON.stringify(ctrl)) // 复制配置信息，避免修改后影响其他数据
      let fieldVar = parseVarExpr(clone.v)
      if (fieldVar) { //判断是否为变量格式 =[...]
        clone.v = getReflectValue(data, fieldVar)
      }
      return clone
    },
    getElementWidth(){
      //根据每行设置的显示个数，自动计算宽度
      // 总间隔宽度 / 显示元素个数
      let gap = (this.config.columns-1)*this.config.columnGap/((this.config.columns))
      return `calc(${100.00/(this.config.columns||1)}% - ${gap}px)` 
    },
    getElementHeight(){
      //根据每行设置的显示个数，自动计算宽度
      let rows = this.config.rows||1
      if(this.config.autoHeight){ //自由容器在高度自适应的模式下，设计时，仅显示一行，高度由bodyheight 控制
        rows=1
      }
      let gap = (rows-1)*this.config.rowGap/((rows))
      return `calc(${100.00/(rows)}% - ${gap}px)`
    },
  }
}

</script>
