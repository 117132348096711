import { axios as service } from '@/utils/request'

// 根据商品获取特定商品计量单位列表
export const getGoodsUnitList = (data) => {
  return service({
    url: '/goods/unitList',
    method: 'post',
    data
  })
}

// 根据商品获取特定商品sku列表
export const getGoodsSkuList = (data) => {
  return service({
    url: '/goods/skuList',
    method: 'post',
    data
  })
}
