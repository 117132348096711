<!-- 订单排序规则设置 -->
<template>
  <el-container class="nt-height-100">
    <el-main class=" padding nt-box-panel nt-page-white">
      
      <div class="nt-box-panel padding" style="color:#8c8c8c;font-size:14px;background-color:var(--el-color-primary-light-9);">
        <div style="font-weight:bold;color:#000;font-size:18px;">异常地址校验设置</div>
        <div class="margin-top">
          <div>示例:</div>
          <div>当订单收件人详情地址出现设置的关键词，会提示：例如“广东省深圳市宝安区新疆维吾尔族（订单可能存在异常地址）”   </div>
        </div>
           
      </div>
      <!-- <div>异常地址校验设置： 开启异常地址功能，设置关键词</div> -->
      <div class="display-flex">
        <el-switch v-model="config.isOpen" size="large" :active-value="1" :inactive-value="0" active-text="开启异常地址检测" />
        <span style="font-size:14px;color:#666;">由于平台地址加密显示，无法精确识别异常地址，建议使用【偏远地区高亮】</span>
      </div>
      <div><span style="font-weight:bold;">关键词设置</span>（多个请用英文逗号“,”隔开，最多可设置100个关键词）</div>
      <div class="margin-top-8">
        <el-input type="textarea" v-model="config.text" :rows="6" style="width:800px;" ></el-input>
      </div>
      <div class="margin-top" style="padding-left:350px;">
        <el-button type="primary" @click="onSaveClick">保存</el-button>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import {getTenantKvByConfigKey,saveTenantKv} from '@/service/resource/general'
export default {
  name: "IMPORT_SETTING",

  data() {
    return {
      config:{isOpen:false,text:null},
    }
  },
  async created(){
    let res = await getTenantKvByConfigKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'ABNORMAL_ADDRESS'})
    if (res.code == 200) {
      if (res.data) {
        this.config = JSON.parse(res.data.configValue||'{}')
      }
    } else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    async onSaveClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'ABNORMAL_ADDRESS', configValue: JSON.stringify(this.config) }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>