<!-- 
 查询条件查询面板，分两部分：固定+用户自定义合并为完整的查询条件
 根据 cType 组件类型生成不同的组件 
-->

<template>
  <div >
    <div class="display-flex flex-wrap row-gap-8 relative" :style="{height:isCollcapse? '0px':'auto',overflow:isCollcapse? 'hidden':'visible'}">
      <!-- 固定查询条件 -->
      <QueryFieldList :queryModel="queryForm" :editors="fixedEditorList"></QueryFieldList>
      <!-- 自定义查询条件 -->
      <QueryFieldList :queryModel="queryForm" :editors="customEditors"></QueryFieldList>
      <div>
        <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
        <el-button @click="onResetQueryClick" style="width:64px;" size="small" type="primary">重置</el-button>
      </div>
      
    </div>
    <div :class="`margin-top-8 border-top padding-top-8 relative ${isCollcapse?'close':''}`" >
      <div class="nt-expand absolute display-flex column-gap-4" @click="_=>isCollcapse=!isCollcapse" >
        <i  :class="`iconfont icon-zhankaigengduo ${isCollcapse? 'is-up':''}`" ></i>
        <span>{{isCollcapse?'展开查询条件':'收起查询条件'}}</span>
      </div>
      <el-row justify="space-between">
        <QuickSearchSelector ref="refQuick" @change="onQuickQueryChange"></QuickSearchSelector>
        <div>
          <OrderSyncPanel @refresh="onOrderSyncSuccessHandler"></OrderSyncPanel>
          <el-button link  type="primary" @click="onQuerySettingClick"><el-icon><Tools /></el-icon>定制查询条件</el-button>
          <OrderSortSelector @change="onSortChange"></OrderSortSelector>
          <FlagRemarkSetting></FlagRemarkSetting>
          <OrderTagTips></OrderTagTips>
          <!-- <el-button link type="primary" size="small" @click="onColumnSettingClick">
            <el-icon><Operation /></el-icon>显示设置</el-button>
          <el-button link type="primary" size="small" @click="onSettingClick">
            <el-icon><Setting /></el-icon>高级设置</el-button> -->
            
        </div>
      </el-row>
    </div>
  </div>
  <SettingPage ref="refSettingDialog"></SettingPage>
  <ColumnSettingPage ref="refColunmDialog"></ColumnSettingPage>
  <QuerySettingPage ref="refQuerySettingDialog" @success="onQuerySettingChange"></QuerySettingPage>
</template>

<script>
import OrderSortSelector from './order_sort_selector'
import SettingPage from './setting_dialog'
import ColumnSettingPage from './setting_column_dialog'
import QuerySettingPage from './query_setting_dialog'
import QuickSearchSelector from './quick_search_selector'
import OrderSyncPanel from '../sync/order_sync_panel' // 订单同步区
import OrderTagTips from '../order_tag_tips'
import  QueryFieldList from './query_field_list'
import editors from './config/query_editor'
import FlagRemarkSetting from '../setting/flag_remark_setting'
import * as dayjs from 'dayjs'
import {LoginUserStore} from '@/piana/login_user_service'

export default {
  data() {
    return {
      queryForm: {},
      isCollcapse:false, // 是否收起
      editors: editors.filter(el=>el.isQuick!=true), // 排除 快捷方案使用
      customEditors:[] // 自定义定制查询条件
    }
  },
  emits:['change','refresh','sortChange'],
  components: {
    QueryFieldList,
    OrderSortSelector, 
    SettingPage,
    ColumnSettingPage,
    QuerySettingPage,
    QuickSearchSelector,
    OrderSyncPanel,
    OrderTagTips,
    FlagRemarkSetting
  },
  computed:{
    fixedEditorList:function(){ // 固定查询条件
      return this.editors.filter(el=>el.isFixed)
    }
  },
  async created() { 
    // 查询自定义字段
    let config = LoginUserStore().getConfig('PRINT_QUERY_FIELD')
    if(config){
      let arFeilds = (config.configValue||'').split(',') 
      arFeilds.forEach(key=>{
        let matchItem = this.editors.find(el=>el.queryKey == key)
        if(matchItem){
          this.customEditors.push(matchItem)
        }
      })
    }
    // 将editor中的默认值，放入queryModel
    this.onResetQueryClick()
  },
  mounted(){
    this.onQueryClick() // 条件加载结束，触发查询
  },
  methods: {
    onResetQueryClick(){
      this.editors.forEach(item=>{
        this.queryForm[item.queryKey]= item.defaultValue || {}
      })
    },
    onQueryClick(){ //主面板查询条件
      this.$refs.refQuick.clearSelection()
      this.$emit('change', Object.values(this.queryForm))
    },
    onQuerySettingChange(fieldKeys){ // 配置定制条件后，刷新主查询面板
      this.customEditors=[]
      fieldKeys?.forEach(key=>{
        let matchItem = this.editors.find(el=>el.queryKey == key)
        if(matchItem){
          this.customEditors.push(matchItem)
        }
      })
    },
    onQuickQueryChange(queryList){ //快捷查询
      // 快捷查询方案，设计到部分需要计算时间，针对特定变量进行继续
      let list = queryList.filter(item=>item.dynamic)
      list.forEach(item=>{
        // 根据类型进行计算
        if(item.value == 'TODAY'){ //今天

        } else if(item.value == 'YESTERDAY'){  // 昨天
          let yesterday = dayjs().subtract(1, 'day');
          item.startValue = yesterday.format('YYYY-MM-DD')
          item.endValue = yesterday.format('YYYY-MM-DD')
          
        } else if(item.value == 'LAST7DAYS'){ // 最近7天
          let dtStart = dayjs().subtract(7, 'day');
          item.startValue = dtStart.format('YYYY-MM-DD')
          item.endValue = dayjs().format('YYYY-MM-DD')
          
        } else if(item.value == 'LAST30DAYS'){ // LAST30DAYS
          let dtStart = dayjs().subtract(30, 'day');
          item.startValue = dtStart.format('YYYY-MM-DD')
          item.endValue = dayjs().format('YYYY-MM-DD')
          
        } else if(item.value == 'LASTMONTH'){ // 上月
          const dtStart = dayjs().subtract(1, 'month').startOf('month');
          const dtEnd = dayjs().subtract(1, 'month').endOf('month');
          item.startValue = dtStart.format('YYYY-MM-DD')
          item.endValue = dtEnd.format('YYYY-MM-DD')
        } 
      })
      this.$emit('change', queryList,true)
    },
    onSortChange(val){
      // 切换排序方式
      this.$emit('sortChange',val)
    },
    onSettingClick: function () {
      this.$refs.refSettingDialog.showDialog()
    },
    onColumnSettingClick: function () {
      this.$refs.refColunmDialog.showDialog()
    },
    onQuerySettingClick: function () {
      this.$refs.refQuerySettingDialog.showDialog()
    },
    onOrderSyncSuccessHandler(){ //同步成功后触发
      this.$emit('refresh')
    }
    
  }
}
</script>
<style lang="less" scoped>
  .close{
    margin-top:0px !important;
    padding-top:0px !important;
    border-top: none;
  }
  .nt-expand{
    top:0px;
    left:50%;
    transform: translateX(-50%);
    color:var(--el-color-primary);
    font-size: 12px;
    cursor: pointer;
    z-index: 10;
    &:hover{
      font-weight: bold;
    }
    i{
      font-size: 10px;
      display: inline-block;
      transform: rotate(180deg);
      margin-top:0px;
      &.is-up{
        
        transform: rotate(0deg);
      }
    }
    
  }
</style>