<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <nt-form-item label="仓库名称" prop="storeName" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入仓库名称" v-model="formModel.storeName" />
        </nt-form-item>
        <nt-form-item label="仓库地址">
          <el-input placeholder="请输入仓库地址" v-model="formModel.address" />
        </nt-form-item>
        <nt-form-item label="仓储费">
          <el-input placeholder="请输入仓储费" v-model="formModel.warehousing" />
        </nt-form-item>
        <nt-form-item label="搬运费">
          <el-input placeholder="请输入搬运费" v-model="formModel.truckage" suffix="元" />
        </nt-form-item>
        <nt-form-item label="负责人">
          <el-select placeholder="选择负责人" v-model="formModel.principal" :dropdownMatchSelectWidth="false">
            <el-option v-for="(item, index) in userList" :key="index" :value="item.id" :label="item.userName"></el-option>
          </el-select>
        </nt-form-item>
        <nt-form-item label="排序">
          <el-input placeholder="请输入排序" v-model="formModel.sort" />
        </nt-form-item>
        <nt-form-item label="备注" class="no-margin-bottom">
          <el-input :rows="2" placeholder="请输入备注" v-model="formModel.remark" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>

import { getUserList } from '@/api/api'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "StoreModal",
  mixins: [DataEditMixin],
  components: { NtFormItem },
  data() {
    return {

      userList: [],


    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  created() {
    this.initUser()
  },
  methods: {
    async _loadEntity() {
      if (this.entityId) {
        return await this.getEntity(this.EnumEntityType.STORE, { id: this.entityId })
      }
    },
    _loadFinished() {
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))


      return this.saveEntity(this.EnumEntityType.STORE, postData)
    },
    initUser() {
      getUserList({}).then((res) => {
        if (res) {
          this.userList = res;
        }
      });
    }
  }
}
</script>