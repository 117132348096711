<template>
  <div style="margin: 12px 12px 0px;">
    <!-- <index-chart></index-chart> -->
  </div>
</template>

<script>
  // import IndexChart from './IndexChart'

  export default {
    name: "Analysis",
    components: {
      // IndexChart
    },
    data() {
      return {

      }
    },
    created() {

    },
    methods: {

    }
  }
</script>