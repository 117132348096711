<template>
  <el-container class="padding-16"  v-loading="isLoading || isSubmitting">
    <el-main class="padding-16 nt-box-panel nt-bg-white">
      <el-form @change="onFormChangeHandler"  :model="formModel" ref="frmEdit" label-width="80" >
        <nt-form-item prop="companyId" label="往来单位" :rules="formValidator('required', '选择往来单位')">
            <nt-dropdown-grid configKey="company" v-model="formModel.companyId" 
              v-model:text="formModel.companyName" :readnly="formModel.id" placeholder="请选择往来单位" :searchable="true"
              :iconSearch="false"></nt-dropdown-grid>
          </nt-form-item>
          <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
            <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%;" />
          </nt-form-item>
          <el-form-item label="单据编号">
            <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
          </el-form-item>
          <nt-form-item prop="handsPersonId" label="财务人员">
            <nt-dropdown-grid configKey="staff" v-model="formModel.handsPersonId"
              v-model:text="formModel.handsPersonName" :disabled="viewOnly" placeholder="请选择财务人员" :searchable="true"
              :iconSearch="false"></nt-dropdown-grid>
          </nt-form-item>
          <el-form-item label="金额" :rules="formValidator('required', '请输入金额')">
            <el-input placeholder="请输入金额" v-model.number="formModel.total" />
          </el-form-item>
          <el-form-item label="备注" prop="remark" class="no-margin-bottom">
            <el-input placeholder="请输入备注" v-model="formModel.remark" />
          </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="align-right no-margin no-padding padding-top-16 height-auto">
      <el-button @click="onCancelClick">取消</el-button>
      <el-button @click="onSubmitClick(false)" type="primary">保存</el-button>
    </el-footer>
  </el-container>
</template>
<script>

import { DataBillMixin } from '@/utils/data_bill_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
import { EnumEntityType } from '@/utils/enum_utils'

export default {
  name: "PaymentInit",
  mixins: [
  DataBillMixin
  ],
  components: {
    NtFormItem
  },
  data() {
    return {
      billType:  this.billType,
      entityType:EnumEntityType.PAYMENT_INIT  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    },
    billType:{
      type: String
    }
  },
  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  // created() {
  //   this.entityType = this.EnumEntityType.PAYMENT_INIT
  //   console.log('fkdkdddd', this.billType)
  // },
  methods: {
    _initialize(){
      // this.formModel.billType =this.billType
    },
    async _createNewEntity() {
      // let billNo = await this.createBillNo('PI')
      return {
        data: {
          // billNo: billNo,
          billType:  this.billType,
          billTime: this.getDateTime() // getNowFormatDateTime()
        }
      }
    },
    async _loadEntity(params) {
      if (!this.entityId) {
        // this.fileList = []
        return this._createNewEntity()
      } else {
        let res = await this.getEntity(this.EnumEntityType.PAYMENT_INIT, { id: this.entityId })
        return res
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      if (!this.formModel.total) {
        this.$message.error(`单据${matchItem.billNumber} 本次付款金额不能为0`)
        return false
      }

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel,{billType:this.billType})
      // console.log('fkdidid', this.formModel,{billType:this.billType})
      return this.saveEntity(this.EnumEntityType.PAYMENT_INIT, postData)
    },
  },

}
</script>