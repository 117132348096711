<!-- 客户账本
  欠款金额 欠款笔数  回款情况：最近收款（单独提取）  下次收款 （大于当前时间）  到期未收款最小时间-到期未收款最大时间《当前时间  超期未付总额，超期未付总笔数
    欠款笔数： 开单后，未付款核销，（订单类不算欠款）
    【客户欠款笔数】： 统计未核销的 销售单，销售退货单  （费用，支持，预付，付款 不计算）
    【供应商欠款笔数】： 统计未核销的 采购单，采购退货单  （费用，支持，预付，付款 不计算）
    总欠款： 所有的交易单据

    下次收款时间： 费用单类型，单据日期作为最近收款时间
 -->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>客户账本</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-row :gutter="16">
        <el-col :span="8">
          <div class="statistic-card">
            <el-statistic :value="summary.unsettleCount">
              <template #title>
                总欠款客户(家)
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="statistic-card">
            <el-statistic :value="(summary.debtTotal/10000).toFixed(4)">
              <template #title>
                欠款合计(万)
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="statistic-card">
            <el-statistic :value="summary.count" title="New transactions today">
              <template #title>
                总客户(家)
              </template>
            </el-statistic>
          </div>
        </el-col>
      </el-row>
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="客户">
          <nt-dropdown-grid  configKey="customer" v-model="queryModel.id"
          placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" class="nt-rpt-table margin-top" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="客户信息" prop="companyName">
          <template #default="{row}">
            <div>{{row.companyName}}</div>
            <el-tag>结算类型</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="欠款交易信息(占比)" prop="companyName">
          <template #default="{row}">
            <div class="display-flex space-between">
              <div style="align-self:center;">
                <div>欠款({{row.unsettleCount||0}}笔)</div>
                <el-link @click="onViewDetailClick(row)">{{row.customerDebtTotal}} {{ !row.unsettleCount && row.customerDebtTotal? '(预付)':''  }}</el-link>
              </div>
              <div>占比({{ (!summary.debtTotal? 0: row.customerDebtTotal/summary.debtTotal*100.00).toFixed(2) }}%)</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="回款情况" prop="companyName">
          <template #default="{row}">
            <div>最近收款 {{ formatShortDate(row.lastTakeTime) }}</div>
            <div v-if="row.nearbyPaymentTime" >预计下次收款 <el-link @click="onNextPaymentClick(row)">{{ formatShortDate(row.nearbyPaymentTime) }} (￥{{ row.nearbyUnsettleTotal }})</el-link></div>
          </template>
        </el-table-column>
        <el-table-column label="到期未收款" >
          <template #default="{row}">
            <div v-if="row.minOverDueTime">{{formatOverDueTime(row)}}</div>
            <el-space v-if="row.minOverDueTime">
              <el-link @click="onOverDueClick(row)">最长欠款{{formatDays(row)}}天</el-link>
              <el-link @click="onOverDueClick(row)">超期￥{{row.overDueUnsettleTotal}}({{row.overDueUnsettleCount}}笔)</el-link>
            </el-space>
          </template>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
        layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
        @size-change="onPageSizeChange" />
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <customer-detail-flow-page @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></customer-detail-flow-page>
  </el-drawer>
  <el-drawer v-model="isOverdueVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isOverdueVisible = false">
    <customer-overdue-page @cancel="isOverdueVisible = false" :query="subQuery" v-if="isOverdueVisible"></customer-overdue-page>
  </el-drawer>
  <el-drawer v-model="isTradeListVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isTradeListVisible = false">
    <customer-trade-head-page @cancel="isTradeListVisible = false" :query="subQuery" v-if="isTradeListVisible"></customer-trade-head-page>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import * as dayjs from 'dayjs'
import { getCompanySummaryList,getDebtSummary } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import customerDetailFlowPage from '@/views/report/supplier/company_trade_detail'
import customerOverduePage from '@/views/report/supplier/company_overdue_payment'
import customerTradeHeadPage from '@/views/report/supplier/company_trade_by_payment_time'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "CustomerFinancialSummary",
  mixins: [DataListMixin],
  components: {
    NTDateRange,
    NtRptLayout,
    NtRptToolbar,
    customerDetailFlowPage,
    customerOverduePage,
    customerTradeHeadPage
  },
  data() {
    return {
      isDetailVisible: false,
      isOverdueVisible: false,
      isTradeListVisible: false,
      summary:{},
      // 查询条件
      queryModel: {
        companyId: ''
      },
      subQuery:{}
    }
  },
  methods: {

    onDateChange: function (value, dateString) {
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _initialize(){
      let res = await getDebtSummary({companyType:"1,3"})
      if(res.code ==200){
        this.summary = res.data
      }
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getCompanySummaryList(Object.assign(params, { companyType: "1,3" }))
    },
    _loadFinished() {
    },
    formatOverDueTime(row){
      let ar = []
      if(row.minOverDueTime){
        ar.push(this.formatShortDate(row.minOverDueTime))
      }
      if(row.maxOverDueTime && !ar.includes(this.formatShortDate(row.maxOverDueTime))){
        ar.push(this.formatShortDate(row.maxOverDueTime))
      }
      return ar.join('--')
    },
    formatDays(row){
      if(!row.minOverDueTime){
        return 
      }
      let minDt = dayjs(row.minOverDueTime)
      let dt = dayjs(new Date())
      return dt.diff(minDt,'day')
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(row){
      this.subQuery = {companyId:row.companyId}
      this.isDetailVisible = true
    },
    onOverDueClick(row){
      this.subQuery = {companyId:row.companyId}
      this.isOverdueVisible = true
    },
    onNextPaymentClick(row){
      this.subQuery = {companyId:row.companyId,paymentStartTime:this.formatShortDate(row.nearbyPaymentTime),paymentEndTime:this.formatShortDate(row.nearbyPaymentTime)}
      this.isTradeListVisible = true
    }
  }
}
</script>