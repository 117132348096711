
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>出入库流水</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按单据"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="单据日期" prop="billTime" width="110">
          <template #default="{row}">
            {{formatDate(row.billTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="单据号" prop="billNo" width="180">
        </el-table-column> -->
        <nt-bill-no-column label="单据编码" width="165"></nt-bill-no-column>
        <el-table-column label="单据类型" prop="billType" width="120">
          <template #default="{row}">
            {{EnumBillType[row.billType] || row.billType}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="业务类型" prop="bizType" width="100">
        </el-table-column> -->
        <el-table-column label="仓库" prop="storeName" width="100">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" width="260">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="80">
        </el-table-column>
        <el-table-column label="本期入库" align="center" header-align="center">
          <el-table-column label="主数量" width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==1">{{ row.baseQuantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单价"  width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==1">{{ (row.costTotal/ row.baseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" align="right" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==1">{{ row.costTotal }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="本期出库" align="center">
          <el-table-column label="主数量" width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==-1">{{ row.baseQuantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单价"  width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==-1">{{ (row.costTotal/ row.baseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" align="right" header-align="center">
            <template #default="{row}">
              <span v-if="row.ioType==-1">{{ row.costTotal }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getFlowDetail } from '@/service/report/rpt_repository'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "store_flow_detail",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getFlowDetail(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'SALE_OUT,SALE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

