<template>
  <el-form-item v-bind="$attrs" ref="refMyltem" >
    <template #error>
      <!-- 需指定key，否则在table中提示transitionGroupkey 未指定 -->
      <el-icon class="right-top" :key="$attrs.id" ref="refIcon" 
      @mouseenter="visible = true" @mouseleave="visible = false" 
      color="var(--el-color-danger)"><Warning /></el-icon>
    </template>
    <slot></slot>
    <!-- 需采用虚拟trigger方式，否则提示错误，计算弹窗时-->
    <el-tooltip placement="top" :content="this.$refs.refMyltem?.validateMessage" 
      v-model:visible="visible" :key="`tips_${$attrs.key}`" virtual-triggering  trigger="hover" 
      :virtual-ref="this.$refs.refIcon" popper-class="nt-error-tooltip">
    </el-tooltip>
  </el-form-item>
</template>
<script >
export default {
  data(){
      return {
        visible:false
      }
    },
  methods:{}
  }
</script>

<style lang="less" >
.nt-error-tooltip.el-popper {
  padding: 6px 12px;
  background: var(--el-color-danger);
  border:none;
  .el-popper__arrow::before {
    background: var(--el-color-danger);
    right: 0;
    border:none;
  }
}


</style>
