 
<template>
  <el-form ref="formRef" :model="config" label-position="top" size="small" @change="onPropertyChangeHandler">
    <div style="font-weight:bold;font-size:20px;" class="margin-bottom">{{ config.label}}</div>
    <el-form-item prop="prefix" label="前缀">
      <el-input v-model="config.prefix" />
    </el-form-item>
    <el-form-item prop="prefix" label="文本变量">
      <el-input v-model="config.v" read-only>
        <template #suffix>
          <el-icon @click="onVarClick" style="cursor:pointer;"><MoreFilled /></el-icon>
        </template>
        </el-input>
    </el-form-item>
    <el-form-item  prop="textStyle" label="文本样式" >
      <TextStyleEditor v-model="config.textStyle" />
    </el-form-item>
    <el-form-item label="">
      <span class="margin-right">是否打印</span><el-switch v-model="config.isPrinted" />
    </el-form-item>
    <el-form-item label="位置">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.left" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.top" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="大小">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.width" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.height" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    
  </el-form>
  <el-dialog
    v-model="varVisible" destroyOnClose
    class="nt-resource-dialog"
    :title="'业务字段选择'+billType"
    :close-on-click-modal="false"
    @close="varVisible=false"
  >
    <bill-field-selector :billType="billType" @choose="onChooseHandler" @cancel="varVisible =false" v-if="varVisible"></bill-field-selector>
  </el-dialog>
</template>

<script >


import TextStyleEditor from './text_style.vue'
import BillFieldSelector from '../selector/bill_field_selector'
export default {
    data () {
      return {
        varVisible:false
      }
    },
    inject:['billType'],
    components:{TextStyleEditor,BillFieldSelector},
    props:{
      config:{},
    },
    methods:{

      onVarClick(){
        this.varVisible= true;
      },

      onChooseHandler(field){
        this.config.label = field.label
        this.config.prefix = field.prefix,
        this.config.demoText = field.demoText
        this.config.v = field.prop // `\$\[${field.prop}\]`
        this.varVisible = false
      }
    }
}

</script>