import { axios as service } from '@/utils/request'


export const querySystemTemplateList = (data) => {
  return service({
    url: '/template/sys_list',
    method: 'post',
    data
  })
}

export const queryPageTemplateList = (data) => {
  return service({
    url: '/template/list',
    method: 'post',
    data
  })
}

export const queryTemplateList = (data) => {
  // let data = {billType:billType,templateType:templateType}
  return service({
    url: '/template/all',
    method: 'post',
    data
  })
}


export const saveTemplate = (data) => {
  return service({
    url: '/template/save',
    method: 'post',
    data
  })
}

export const createFromSys = (data) => {
  return service({
    url: '/template/createFromSys',
    method: 'post',
    data
  })
}


export const getTemplate = (data) => {
  return service({
    url: '/template/get',
    method: 'post',
    data
  })
}
export const deleteTemplate = (data) => {
  return service({
    url: '/template/delete',
    method: 'post',
    data
  })
}


// export const getPrintTemplate = (data) => {
//   return service({
//     url: '/template/getPrintTemplate',
//     method: 'post',
//     data
//   })
// }


export const setDefaultTemplate = (data) => {
  return service({
    url: '/template/setDefault',
    method: 'post',
    data
  })
}

export const updateStatus = (data) => {
  return service({
    url: '/template/updateStatus',
    method: 'post',
    data
  })
}


export const bindWaybillAccount = (data) => {
  return service({
    url: '/template/bandWaybillAccount',
    method: 'post',
    data
  })
}

export const bindPrinter = (data) => {
  return service({
    url: '/template/bindPrinter',
    method: 'post',
    data
  })
}