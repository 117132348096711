
<template>
  <el-container class="padding-8 height-100">
    <el-header class="no-margin no-padding height-auto">
      <div class="margin-bottom"> 状态-{{ waybill?.wayBillStatus }} 顺丰速运，{{ waybill?.wayDept }}南岭花园网点（000） 物流轨迹=tab</div>
      <div class="display-flex">
        <el-card style="flex:1">
          <template #header>
            <div class="card-header">
              <span>发货人</span>
            </div>
          </template>
          <div>{{ waybill?.senderName }}-{{ waybill?.senderTel }}</div>
          <div>{{ waybill?.senderAddress }}</div>
        </el-card>
        <div style="width:20px;align-self:center;">
          <el-icon size="20" color="var(--el-color-primary)">
            <Right />
          </el-icon>
        </div>
        <el-card style="flex:1">
          <template #header>
            <div class="card-header">
              <span>收件人</span>
            </div>
          </template>
          <div>{{ waybill?.buyerName }}-{{ waybill?.buyerTel }}</div>
          <div>{{ waybill?.buyerAddress }}</div>
        </el-card>
      </div>
      <!-- <div>订单号，所属平台，当前订单状态</div> -->
      <div>包裹内容商品详情</div>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="waybill?.platformOrderPackageList"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName"></el-table-column>
        <el-table-column label="SKU" prop="goodsSku"></el-table-column>
        <el-table-column label="数量" width="120" prop="quantity"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-button type="danger" @click="onCancelWaybillClick">回收电子面单</el-button>
    </el-footer>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getWaybill, cancelWaybill } from '@/service/print_order/platform_waybill'
export default {
  name: "PackageDetail",
  mixins: [DataListMixin],
  components: {
  },
  data() {
    return {
      waybill: null
    }
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    waybillNo: {
      type: String,
      required: true
    }
  },
  async mounted() {
    let res = await getWaybill({ orderId: this.orderId, waybillNo: this.waybillNo });
    if (res.code == 200) {
      this.waybill = res.data
    }
  },
  methods: {
    async onCancelWaybillClick() {
      let res = await cancelWaybill({ id: this.waybill.id }); // 批量接口
      if (res.code == 200) {
        if(res.data?.failCount==0){
          this.$message.success('回收成功')
        } else{
          this.$message.error(res.data.resultList[0].message)
        }
      } else {
        this.$message.error(res.msg || '回收失败')
       
      }
    }
  }
}
</script>