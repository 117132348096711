/*
  打印分页处理工具：
    1. 根据模版配置信息 + 需要打印的的数据源
    2. 提取汇总信息
    3. 提取分页合计信息
    4. 提取内容行数
    
 */
import { getTemplate} from '@/service/print_order/template'

class NtBillPrintCreator {
  #templateConfig = null
  #datasource = []
  #pageSize = 0 // 每页可容纳行数
  #bodyPageCount = 0 // 根据加载的数据源，结合小计 合计， 推算本次打印需要的页数
  #summary = {} // 合计汇总信息
  #pageSubtotalInfo = {} // 根据页码记录每页需要显示的 小计行  ，合计行： 1 需要一行合计， 2 需要两个合计行
  #mergeInfo = null // 合并信息 {start: matchColIndex, end: matchColIndex + parseInt(col.colspan||2)}

  #bodyMode = null // block 自由容器模式  grid 网格列表模式
  #isAutoHeight = false // 是否高度自适应
  constructor() {
  }
  _initialize(tplConfig){
    
    this.#templateConfig = tplConfig
    this.#bodyMode = this.#templateConfig.pageSetting.bodyContainer
    
    if(this.#bodyMode =='block'){
      /*
        自由容器模式：
          1. 高度自适应：适用热敏，页面高度由内容制定----------单页显示
          2. 非自适应： body 区域，高度固定， 显示的内容项目 有配置的行数*列表 决定分页数量
       */
        let fContainer = this.#templateConfig.configJson.freeContainer
        this.#isAutoHeight = fContainer.autoHeight
        if(this.#isAutoHeight){ // 自适应模式
          this.#bodyPageCount = 1 // 单页显示
          this.#pageSize = this.#datasource.length
        } else {
          //需分页控制打印
          this.#pageSize = (fContainer.columns||1)*(fContainer.rows||1)// 每页可容纳个数
          this.#bodyPageCount = Math.ceil(this.#datasource.length/ this.#pageSize)
        }
          
    } else{
      let gConfig = this.#templateConfig.configJson.body || {contentHeight:5}
      this.#isAutoHeight = gConfig.autoHeight
      /** 增加高度自适应控制 */
      if(gConfig.autoHeight){
        this.#bodyPageCount = 1 //网格模式，高度自适应模式，仅显示一页
        this.#pageSize = this.#datasource.length // 一页完整显示
        // 计算bodyheight ===重新计算
        // TODO  控制是否需要合计行
      } else{
        this.#pageSize = Math.floor(this.getContentHeight()/(gConfig.contentHeight||10)) // 最大可容纳行数
        // 至少一行，否则，后续出现除灵infinte
        if(this.#pageSize <1){
          this.#pageSize = 1
        }
        this.initPageInfo() // 网格模式 计算内容行，总分页数，
        this.createSummaryInfo() // 根据配置信息，及数据源，提取汇总信息
        //提取跨列合并信息
    
        let bodyConfig = this.#templateConfig.configJson.body
        if(bodyConfig?.subtotal){ // 兼容面单只有items，没有网格列表
          let matchColIndex = bodyConfig.columns.findIndex(item=>item.isSubtotalText)
          if(!matchColIndex == -1 && tbodyConfig.columns.length>0){
            matchColIndex = 0
          }
          if(matchColIndex>=0){
            let col = bodyConfig.columns[matchColIndex]
            // 合计行，跨列合并范围
            this.#mergeInfo = {start: matchColIndex, end: matchColIndex + parseInt(col.colspan||2)}
          }
        }
      }
      
    }

    // 注意：高度自适应模式下，根据实际内容，重新计算bodyHeight
    if(!this.#isAutoHeight){
      tplConfig.configJson.bodyHeight = tplConfig.configJson.bodyHeight + 'mm' // mm 模式
    } else {
      if(this.#bodyMode == 'block'){ //自由容器
        // auto  ？ 
        // tplConfig.configJson.freeContainer.height //元素高度
        let rows = Math.ceil(this.#datasource.length/(tplConfig.configJson.freeContainer.columns||1))
        let gap = (tplConfig.configJson.freeContainer.rowGap||0) *(rows-1)
        tplConfig.configJson.bodyHeight = (tplConfig.configJson.freeContainer.height * rows + gap) + 'px' // TODO  是否需要单位
      } else { // 表格列表模式
        // 标题行高度 + 内容行高度* （数据源长度 + 显示合计行? 1:0) 
        let gridConfig = this.#templateConfig.configJson.body ||{}
        let titleHeight = gridConfig.titleHeight || 5 // 标题行高
        let contentHeight =  gridConfig.contentHeight|| 5 //mm
        let height = titleHeight + (contentHeight* (this.#datasource.length + (gridConfig.subtotal?1:0)))
        tplConfig.configJson.bodyHeight = height+'mm'; // TODO  是否需要单位  不一致
      }
    }
    
  }
  // 创建打印数据
  async createPrintPageData(templateInfo /* 模版id，或是已加载好的模版配置json */,printObject,dataDetails){ // 根据templateId 的类型，构建分页，合计等信息
    // 优先从缓存加载单据模版
    this.#datasource = dataDetails
    let tplConfig  = templateInfo // 默认 已加载好的模版配置信息
    if (typeof templateInfo === "string") {
      let resTemplate = await getTemplate({id: templateInfo}) // 该方法加入本地缓存处理
      tplConfig = resTemplate.data||{configJson:'{}'}
      tplConfig.configJson = JSON.parse(tplConfig.configJson||'{}')
      tplConfig.pageSetting = JSON.parse(tplConfig.pageSetting|| '{}')
    }
    

    let printData = Object.assign(printObject,{_pages:[],_tpl:tplConfig})
    

    this._initialize(tplConfig) // 初始化打印配置信息

    let isFreeContainer = tplConfig.pageSetting.bodyContainer == 'block'
    
    if(this.#bodyPageCount==0){ // 没有body节点，即为面单模式
      printData._pages.push({data: null})
    } else{ // erp 业务单据模式，头 明细 脚
      // 准备分页数据
      for(let index=0;index<this.#bodyPageCount;index++){
        let pageData = []
        // 区分自由容器，网格模式
        if(isFreeContainer){
          pageData = this.getFreeContainerDataByPage(index)
        } else {
          pageData = this.getDataByPage(index)
        }
        let pageMergeInfo
        if(this.#mergeInfo){ // 需要显示合并信息 + 本页需要添加的合计行
          pageMergeInfo = Object.assign({}, this.#mergeInfo,{rows: this.getPageSubtotalRows(index)})
        }

        printData._pages.push({data: pageData,mergeInfo:pageMergeInfo})
      }
    }

    return printData
  }

  getMergeInfo(){
    return this.#mergeInfo
  }

  getTemplateConfig(){
    return this.#templateConfig
  }

  getPageCount(){
    return this.#bodyPageCount
  }
  getPageSubtotalRows(pageIndex){ // 获取当前页的合并行数： 合计1， 页小计1，最大2
    return this.#pageSubtotalInfo[pageIndex]?.subtotalCount || 0
  }

  /*
    templateConfig: 模版配置设计信息,
    打印单据列表数据源
  */
  getDataSummary(list) {
    let gridConfig = this.#templateConfig.configJson.body || { columns: [] } // 网格配置信息节点
    let arCols = gridConfig.columns?.filter(item => item.subtotalType != 'NONE')
    let summary = {}
    // 处理当前页小计
    arCols.forEach(col => {
      summary[col.prop] = 0
    })
    let avg_count_map = {}
    let allRows = list.length
    list.forEach(row => {
      arCols.forEach(col => {
        // subtotalType  SUM 求和  AVG 平均值(数据为空，不作为计算) COUNT 记录数
        if (['SUM', 'AVG'].includes(col.subtotalType) && row[col.prop] != null && row[col.prop] != undefined && !Number.isNaN(row[col.prop])) {
          summary[col.prop] += row[col.prop]
          if (col.subtotalType == 'AVG') {
            avg_count_map[col.prop] = (avg_count_map[col.prop] || 0) + 1
          }
        }
      })
    })
    arCols.filter(col => col.subtotalType == 'AVG').forEach(col => {
      if (avg_count_map[col.prop]) { // 存在有效的 统计行信息
        summary[col.prop] = summary[col.prop] / (avg_count_map[col.prop] * 1.00)
      } else {
        summary[col.prop] = ''
      }
    })
    arCols.filter(col => col.subtotalType == 'COUNT').forEach(col => {
      summary[col.prop] = allRows
    })
    return summary
  }

  initPageInfo() {
    let gridConfig = this.#templateConfig.configJson.body// 网格配置信息节点

    if(!gridConfig){ // 面单不存在body配置信息，仅打印一页
      this.#bodyPageCount = 0
      return
    }

    let subtotalSetting = gridConfig.subtotalSetting || {}

    if (!gridConfig.subtotal) {
      this.#bodyPageCount = Math.ceil(this.#datasource.length /  this.#pageSize) // 根据单据商品行数，打印的张数
      return
    }

    // 判断是否一页就能显示完
    if(this.#datasource.length<this.#pageSize){
      this.#pageSubtotalInfo[0]= {subtotalCount: 1,start: 0,size:this.#datasource.length}
      this.#bodyPageCount = 1
      subtotalSetting.subPage = false // 仅一页时，不显示页小计
      return
    }

    // 需要的总页数： 总记录数-减去每页固定合计行：合计+页小计
    // 实际数据行
    let pageCount = 0
    let mergeRows = subtotalSetting.lastPage?1:2

    if (!subtotalSetting.subPage){
      mergeRows -= 1
    }
    var contentRows = this.#pageSize-mergeRows
    
    // 总共需要多少页
    pageCount = Math.ceil(this.#datasource.length/contentRows) // body高度-标题栏高度
    for(let pageIndex=0;pageIndex<pageCount;pageIndex++){
      if(pageIndex==pageCount-1){ // 最后一页,
        this.#pageSubtotalInfo[pageIndex] = {subtotalCount: mergeRows, start: pageIndex * contentRows,size:this.#datasource.length-(pageIndex * contentRows)}
      } else {
        this.#pageSubtotalInfo[pageIndex] = {subtotalCount: mergeRows,start: pageIndex * contentRows,size:contentRows}
      }
      
    }

    // 最后一页空白行数
    let emptyRowsInLastPage = pageCount*contentRows - this.#datasource.length // 总页数乘以每页实际显示的数据（不含合计行) - 总数量

    if(emptyRowsInLastPage==0){ // 最后一页刚好填满
      if(mergeRows !=2){ 
        if(subtotalSetting.lastPage){// 合计行在最后一页显示
          pageCount++ // 增加一页，仅显示合计
          this.#pageSubtotalInfo[pageCount-1] = {subtotalCount: mergeRows+1,start: 0,size:0}
        }
      }
    } else { // 最后一页仍有空白行
      if(mergeRows !=2){ 
        if(subtotalSetting.lastPage){// 合计行在最后一页显示
          this.#pageSubtotalInfo[pageCount-1].subtotalCount +=1
        }
      }
    }
    this.#bodyPageCount = pageCount
  }

  createSummaryInfo() { // 计算合计统计信息 
    let gridConfig = this.#templateConfig.configJson.body
    if (!gridConfig || !gridConfig.subtotal) return //不显示合计行， 面单模版不存在body配置

    // 提取需要合计统计列集合
    let arCols = gridConfig.columns?.filter(item => item.subtotalType != 'NONE')
    let allRows = this.#datasource.length

    if (!arCols?.length || !allRows) {
      return
    }

    this.#summary = this.getDataSummary(this.#datasource) // 合计汇总信息
  }

  // 自由容器模式下，获取数据
  getFreeContainerDataByPage(pageIndex){
    let startIndex = this.#pageSize*pageIndex
    return this.#datasource.slice(startIndex, (startIndex + this.#pageSize))
  }

  getDataByPage(pageIndex) {
    let gridConfig = this.#templateConfig.configJson.body || {} // 网格配置信息节点
    let list = []
    if (gridConfig.subtotal) { // 显示合计
      let pInfo = this.#pageSubtotalInfo[pageIndex]
      list = this.#datasource.slice(pInfo.start, (pInfo.start + pInfo.size))
    } else {
      let start = this.#pageSize * pageIndex
      list = this.#datasource.slice(start, (start + this.#pageSize))
    }

    let pageSummary = this.getDataSummary(list) // 分页小计信息


    let diffCount = this.#pageSize - list.length
    if (diffCount > 0) {
      let nData = new Array(diffCount)
      for (let idx = 0; idx < nData.length; idx++) {
        nData[idx] = {} // null 数组，表格不渲染
      }
      list.push(...nData) // 网格列表空白处，填充空白行
    }

    if (gridConfig.subtotal) {
      let pInfo = this.#pageSubtotalInfo[pageIndex]

      let matchCol = this.#templateConfig.configJson.body.columns?.find(item => item.isSubtotalText)
      if (!matchCol) { // 未指定合计列，则默认第一列显示合计
        matchCol = this.#templateConfig.configJson.body.columns[0]
      }

      let subtotalSetting = gridConfig.subtotalSetting || {}
      if (pInfo.subtotalCount == 2) { // 合计+ 分页小计
        let sRow = list[list.length - 1]
        sRow[matchCol.prop] = '合计'
        Object.assign(sRow, this.#summary)

        // 页小计行
        sRow = list[list.length - 2]
        sRow[matchCol.prop] = '小计(页)'
        Object.assign(sRow, pageSummary)
      } else if (pInfo.subtotalCount == 1) {
        // 显示 合计  或 页小计 二选1
        if (subtotalSetting.subPage) { // 每页显示 显示页小计
          let sRow = list[list.length - 1]
          sRow[matchCol.prop] = '小计(页)'
          Object.assign(sRow, pageSummary)
        }
        else {
          let sRow = list[list.length - 1]
          sRow[matchCol.prop] = '合计'
          Object.assign(sRow, this.#summary)
        }
      }
    }
    return list
  }

  getContentHeight(){ // 获取网格内容区高度
    let gridConfig = this.#templateConfig.configJson.body ||{}
    let titleHeight = gridConfig.titleHeight || 0 // 标题行高
    let configJson = this.#templateConfig.configJson
    let bodyHeight = configJson.bodyHeight || 0 
    return (bodyHeight - titleHeight) 
  }
  
}


export default NtBillPrintCreator