/* 高亮显示指定文本内容指令 */
export default {
  
  mounted:function(el,binding){
    const searchText = binding.value;

    if (!searchText) {
      return;
    }
    let words = searchText.split(',')
    let textContent = el.textContent
    words.forEach(item => {
      const regex = new RegExp(item, 'gi')
      textContent = textContent.replace(regex, `<span class='highlight-area'>${item}</span>`)
    });
    
    el.innerHTML = textContent
  },
  unmounted:function(el){
  }
}