<template>
  <div class="nt-height-100 nt-bg-page display-flex relative" 
  :style="{
    paddingLeft: (setting?.padding?.left||0)+'px',
    paddingRight: (setting?.padding?.right||0)+'px',
    paddingTop: (setting?.padding?.top||0)+'px',
    paddingBottom: (setting?.padding?.bottom||0)+'px',
    background:'#fff',
    alignContent:'start',
    position:'relative',
    flexWrap:'wrap', alignItems: 'start',columnGap:config.columnGap+'px',rowGap:config.rowGap+'px'}" >
    <div  :style="{width: getElementWidth(),height: ((config.height||200)+'px'),border:'1px solid red',boxSizing:'border-box'}">
      <drag-container  v-model="this.config.items"   @change="onElementChange">
      自由容器模式
      </drag-container>
      
    </div>
    <VueDragResize :parent="false" :y="startY"  :h="2" :resizable="false" axis="y" @dragging="onDragging" class="1custom-spliter"
      @dragStop="onDragstop" :onDragStart="onDragStart">
      <i class="iconfont icon-chaifen">{{ startY }}</i>
    </VueDragResize>
    <div :style="{width: getElementWidth(),height: ((config.height||200)+'px'),lineHeight:  ((config.height||200)+'px'), textAlign:'center',color:'#ddd',fontSize:'22px', boxSizing:'border-box', border:'2px dashed #ddd'}" v-for="index in totalElement">
      示意效果
    </div>
    <!-- {{ this.config }} -->
  </div>
</template>

<script >
import DragContainer from './drag_container'
import VueDragResize from 'vue-draggable-resizable' // https://github.com/mauricius/vue-draggable-resizable
import { useDispatchDesignerStore } from '../useDispatchDesignerStore'
export default {
  data () {
    return {
      store: useDispatchDesignerStore(),
      config: this.modelValue || {}, //Object.assign({height:200}, this.modelValue),// 默认自由容器设计高度 
      setting: this.pageSetting,
      startY: this.modelValue?.height
    }
  },
  components:{DragContainer,VueDragResize},
  props:{
    modelValue:{},
    pageSetting:{}
  },
  watch:{
    modelValue(val){
      this.config = this.modelValue
      if(!this.config.height){
        // 1mm = 3.78 px
        // this.config.height= (this.setting.bodyHeight-(this.setting.padding.top||0)-(this.setting.padding.bottom||0)) * 3.78
        this.config.height= (this.setting.bodyHeight) * 3.78 -(this.setting.padding.top||0)-(this.setting.padding.bottom||0)
        this.startY = this.config.height
      }
    },
    pageSetting(val){
      this.setting = this.pageSetting
    }
  },
  computed:{
    totalElement:function(){
      let rows = this.config.rows||1
      // if(this.config.autoHeight){ //自由容器在高度自适应的模式下，设计时，仅显示一行，高度由bodyheight 控制
      //   rows=1
      // }
      return rows*(this.config.columns||1) -1
    }
  },
  methods:{
    getElementWidth(){
      //根据每行设置的显示个数，自动计算宽度
      // 总间隔宽度 / 显示元素个数
      let gap = (this.config.columns-1)*this.config.columnGap/((this.config.columns))
      return `calc(${100.00/(this.config.columns||1)}% - ${gap}px)` 
    },

    getElementHeight(){
      //根据每行设置的显示个数，自动计算宽度
      let rows = this.config.rows||1
      // if(this.config.autoHeight){ //自由容器在高度自适应的模式下，设计时，仅显示一行，高度由bodyheight 控制
      //   rows=1
      // }
      let gap = (rows-1)*this.config.rowGap/((rows))
      return `calc(${100.00/(rows)}% - ${gap}px)`
    },

    getEelemnetTotal(){
      // 自由容器内可容器的总数 rows*columns
      let rows = this.config.rows||1
      // if(this.config.autoHeight){ //自由容器在高度自适应的模式下，设计时，仅显示一行，高度由bodyheight 控制
      //   rows=1
      // }
      return rows*(this.config.columns||1) -1
    },
    onElementChange(item){
      this.store.current = item
    },
    onDragStart(evt) {// 开始拖动
      // this.startY = 0
      // this.startHeight = this.config.height // (this.config.height||200) //开始拖拽前，记录初始高度
      // this.startY = evt.offsetY
      // console.log('onDragStart-offsetY', evt.offsetY      )
      // return false
    },
    onDragging(x, y) {// 
      // let adjustHeight = (this.startY - y)
      // console.log('onDragging', this.startY,y)
      // let adjustHeight = (this.startHeaderY - y) ///3.78 // 3.78 为px =》mm 转换比率
      this.config.height = y //  this.startHeight - adjustHeight
      this.startY = y
    },
    onDragstop(x, y) {
      console.log('xxxxx',y)
      // this.startY = null
      // let adjustHeight = (this.startY - y)
      // let adjustHeight = (this.startHeaderY - y) ///3.78 // 3.78 为px =》mm 转换比率
      // this.config.height = this.startHeight - adjustHeight
      // window.dispatchEvent(new Event('resize')) // 必须加，组件dragresize 仅首次计算父容器大小，并监控window.resize 重新计算
      // this.startY = y
      // this.paperInfo.bodyHeight = this.bodyHeight
    },
  }
}
</script>
<style lang="less" scoped>
  .selected>div{
    background: #eee !important;
  }
  .custom-spliter{
    z-index: 99 !important;
    -webkit-transform: none !important;
    transform: none !important;
    width: 0px;
    color: var(--el-color-danger);
    // border: none;
    position: absolute;
    bottom:9px;
    left:50%;
    font-size: 18px;
    cursor: n-resize;
  }
</style>