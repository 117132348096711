<!-- 打印记录 -->
<template>
  <el-container class="nt-height-100">
    <el-header class="height-auto no-padding no-margin display-flex flex-wrap row-gap-8">
      <QueryFieldList :queryModel="queryForm" :editors="editors"></QueryFieldList>
        <div>
          <el-button style="width:64px;" size="small" type="primary" @click="onQueryClick">查询</el-button>
          <el-button style="width:64px;" size="small" @click="onResetQueryClick" type="primary">重置</el-button>
        </div>
    </el-header>
    <el-main class=" no-padding nt-box-panel margin-top-8">
      <el-table :data="datasource" height="100%" border class="nt-resource-table" stripe scrollbar-always-on v-loading="isLoading" @selection-change="onSelectionChange">
        <el-table-column type="index"></el-table-column>
        <el-table-column type="selection"></el-table-column>
        <!-- <el-table-column label="订单来源" prop="platform" width="105"></el-table-column> -->
        <el-table-column label="所属店铺" prop="shopName" width="160">
          <template #default="{row}">
            <span>{{ formatShopName(row.shopId) }}</span>
            <span class="left-top iconfont icon-shipinhaoxiaodian1" style="color:#FF6146;font-size:25px;opacity:.5;"></span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="买家昵称" prop="buyerName" width="110"></el-table-column> -->
        <el-table-column label="收件人" prop="buyerName">
          <template #default="{row}">
            <div>
              <span>{{ row.buyerName }}-{{ row.buyerTel }}</span>
            </div>
            <div style="font-size:14px;">{{ row.provinceName }}{{ row.cityName }}{{ row.countyName }}</div>
            <div>
              {{ row.buyerAddress }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="快递模版" prop="waybillTemplateId"></el-table-column> -->
        <el-table-column label="运单信息" width="190" prop="waybillNo">
          <template #default="{row}">
            <el-link type="primary" @click="onViewPackageDetailClick(row)">{{ row.waybillNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo" width="190">
          <template #default="{row}">
            <el-link type="primary" @click="onViewOrderDetailClick(row)">{{ row.orderNo }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" prop="platformOrder.platformOrderStatus" width="85">
          <template #default="{row}">
            {{ formatOrderStatus(row.platformOrder.platform, row.platformOrder.platformOrderStatus) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #header>
            <el-button link type="primary" size="small"> 排序方式</el-button>
          </template>
          <template #default="{row}">
            <!-- 监控实施发货状态及结果 -->
            <el-icon class="is-loading" v-if="row.processStatus==1">
              <Loading />
            </el-icon>
            <span v-if="row.processStatus==1" style="font-size: 12px;">正在发货...</span>
            <span v-if="row.processStatus==2" style="font-size: 12px;"><el-icon color="var(--el-color-success)"><CircleCheck /></el-icon>发货完成(绿)</span>
            <span v-if="row.processStatus==3" style="font-size: 12px;">
              <el-tooltip effect="light"  placement="top-start" >
                <template #content>
                  <div style="width:200px;">
                    {{row.errorMsg}}
                  </div>
                </template>
                <el-icon color="var(--el-color-danger)"><CircleClose /></el-icon>
              </el-tooltip>
              发货失败
            </span>
            <el-button v-if="!row.processStatus" link type="primary" size="small" @click="onRowDispatchClick(row)">发货</el-button>
            <el-button v-if="!row.processStatus" link type="danger" size="small" @click="onRowCancleClick(row)">撤销</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding no-margin height-auto margin-top align-center relative">
      <div class="display-flex center">
        <div class="display-flex column-gap-0" link type="primary" @click="onEditClick">
          <el-switch class="margin-right" size="small" :model-value="config.isOpen"/>
          <el-button link type="primary" >{{config.isOpen? '已开启自动发货':'已关闭自动发货'  }}</el-button>
        </div>
        
        <el-button type="primary" @click="onDispatchHandler">发货</el-button>
        <el-button type="danger" @click="onCancelPreDispatchHandler">撤销预发货</el-button>
      </div>
      <el-pagination class="absolute right-top" :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
    </el-footer>
  </el-container>
  <el-dialog title="自动发货设置"
    v-model="isVisible" destroyOnClose width="650" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog"
    @close="isVisible=false"
  >
    <div class=" nt-bg-white" style="padding:12px 24px ;">
      <div class="display-flex nt-width-100">
        <span style="flex-basis:120px;">自动发货</span>
        <el-switch class="margin-right" v-model="formModel.isOpen" active-text="开启自动发货"/>
      </div>
      <div class="display-flex  nt-width-100 margin-top ">
        <span style="flex-basis:120px;">自动发货计划</span>
        <div class="display-flex flex-auto">
          <span>每天</span>
          <el-select placeholder="时" style="width:100px;" v-model="formModel.hours">
            <el-option v-for="(_,index) in (new Array(24))" :key="index" :label="index" :value="index">
            </el-option>
          </el-select>
          <el-select placeholder="分"  style="width:100px;"  v-model="formModel.minutes">
            <el-option v-for="(_,index) in (new Array(6))" :key="index" :label="index*10" :value="index*10">
            </el-option>
            <el-option  label="5" :value="5"></el-option>
          </el-select>
          <span>发货</span>
        </div>
      </div>
      <div class="nt-bg-page padding margin-top" style="font-size:12px;">
        免责声明： “自动发货”无法完全替代手动发货，使用过程中，请注意检查自动发货结果<br/>
        使用过程中应及时确认预发货订单的发货情况，服务商不承担因未及时确认预发货订单的发货情况造成的损失。
      </div>
    </div>
    
    <template #footer>
      <div class="align-center margin-bottom">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </div>
    </template>
  </el-dialog>
  <ProgressDialog ref="refProgressDialog"></ProgressDialog>
  <!-- 订单详情 -->
  <order-detail-page ref="refOrderDetail" />
  <package-detail-page ref="refPackageDetail"  />
</template>
<script>


import  QueryFieldList from '../../query/query_field_list'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import OrderDetailPage from '@/views/print_order/detail/order_detail'
import PackageDetailPage from '@/views/print_order/detail/package_detail' // 包裹详情
import {getTenantKvByConfigKey,saveTenantKv} from '@/service/resource/general'
import ProgressDialog from '@/views/print_order/progress_dialog'
import NtWaybillDispatchManager from '@/utils/waybill_dispatch_manager' // 面单发货管理器
import {LoginUserStore} from '@/piana/login_user_service'
import {waybillPreDispathQuery} from '../../query/config/query_waybill_editor'
import { cancelPreDispatch} from '@/service/print_order/platform_waybill'
export default {
  name: "PRE_DISPATCH_LIST",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    ProgressDialog,
    
    OrderDetailPage,
    PackageDetailPage,
    QueryFieldList
  },
  data() {
    return {
      config:{isOpen:false,hours:0,minutes:0}, // 自动发货设置
      isVisible:false,
      formModel:{isOpen:false,hours:0,minutes:0},
      queryForm: {},
      // editors:config
      editors: waybillPreDispathQuery
    }
  },
  async created(){
    this.onResetQueryClick()
    this._shopList = LoginUserStore().shopList
    this._dispatchManager = new NtWaybillDispatchManager((resultList,result ) => {
      if (result.type == 'success') {
        // 根据结果，判断是否满足打印, 根据list
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.processStatus = item.success?2:3 // 判断发货结果 2 发货成功  3发货失败
            if(item.success){
              matchItem.isDispatch = true // 已成功发货，禁止勾选，如已勾选，则取消
            } else{
              matchItem.errorMsg = !item.success? item.message:'' // 记录异常消息 如发货异常，则显示 红色 失败提示信息-over
            }
          }
        })
      } else if (result.type == 'create') { // 发送 【发货请求】失败
        // 弹窗提示 错误信息
        resultList.forEach(item => {
          let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
          if (matchItem) {
            matchItem.done=true
          }
        })
      } else if (result.type == 'overTime') { // 查询[发货]结果超时
        // 标记失败，提示重试
        
        // let matchItem = this.datasource.find(el => el.orderId == item.orderId && el.packageNum == item.packageNum)
        // if (matchItem) {
        //   matchItem.done=true
        // }
      } else if(result.type == 'query'){
        // 轮询网络异常 TODO
      }
      
    })

    let res = await getTenantKvByConfigKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'AUTO_PRE_DISPATCH'})
    if (res.code == 200) {
      if (res.data) {
        this.config = JSON.parse(res.data.configValue||'{}')
        this.formModel = this.config
      }
    } else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    onResetQueryClick(){
      this.editors.forEach(item=>{
        this.queryForm[item.queryKey]= item.defaultValue || {}
      })
    },
    async _loadList(params) {
      // 构建查询条件，====》处理特殊条件， 如门店id，是否存在异常  大于0 小于0 如何区分？
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_WAYBILL, Object.assign({isPreDispatch:true,isDispatch:false}, params));
    },
    formatAbnormalText(orderExtra){
      // 格式化异常原因 1： 高亮偏远地区--含自定义  2 快递不可达 4 异常地址
      let info={
        '1': '偏远地区',
        '2': '快递不可达',
        '4': '异常地址'
      }
      
      let result = []
      if((orderExtra.abnormalFlag & 1) == 1){
        result.push(info['1'])
      } 
      if((orderExtra.abnormalFlag & 2) == 2){
        result.push(info['2'])
      } 
      if((orderExtra.abnormalFlag) & 4 == 4){
        result.push(info['4'])
      }
      
      return result
    },
    formatShopName(shopId){
      let matchItem =  this._shopList.find(item=>item.shopId==shopId)
      return matchItem?.shopName || shopId
    },
    formatOrderStatus(platform,orderStatus){
      let info= {
        'WX_VIDEO_10':'待付款',
        'WX_VIDEO_20':'待发货',
        'WX_VIDEO_21':'部分发货',
        'WX_VIDEO_30':'代收货',
        'WX_VIDEO_100':'已完成',
        'WX_VIDEO_200':'已取消', // 全部商品售后之后，订单取消
        'WX_VIDEO_250':'已取消', // 未付款用户主动取消或超时未付款订单自动取消

      }
      return info[`${platform}_${orderStatus}`] || orderStatus
    },
    onQueryClick() {
      this.resetPage = true
      this.loadList({dynamic:Object.values(this.queryForm)})
    },
    onEditClick(){
      this.formModel = Object.assign({},this.config)
      this.isVisible = true
    },
    async onSubmitClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'AUTO_PRE_DISPATCH', configValue: JSON.stringify(this.formModel) }
      postData.isEnabled =  this.formModel.isOpen? 1:0
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.config = this.formModel
        this.isVisible = false
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onDispatchHandler(){
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      
      let pList = this.selectedRows.map(item=>({orderId:item.orderId,packageNum:item.packageNum}))
      this.$refs.refProgressDialog.showDialog('send',{title:'订单发货',packageList:pList})
    },
    onCancelPreDispatchHandler(){
      if (this.selectedRows.length == 0) {
        this.$message.error("请选择操作订单")
        return
      }
      
      let pList = this.selectedRows.map(item=>({orderId:item.orderId,packageNum:item.packageNum}))
      this.$refs.refProgressDialog.showDialog('preDispatch',{title:'撤销预发货',processType:'CANCEL',packageList:pList},_=>{
        this.loadList()
      })
    },
    onRowDispatchClick(row){
      //单个预发货
      let list = [{
        orderId:row.orderId,
        packageNum: row.packageNum
      }]
      row.processStatus = 1 // 正在发货
      this._dispatchManager.dispatchWaybill(list)
    },
    async onRowCancleClick(row){
      //撤销预发货
      let res = await cancelPreDispatch([{orderId:row.orderId,packageNum:row.packageNum}])
      if (res.code == 200) {
        this.$message.success('更新成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    onViewPackageDetailClick(row){
      this.$refs.refPackageDetail.show(row.orderId,row.waybillNo)
    },
    onViewOrderDetailClick(row){
      this.$refs.refOrderDetail.show(row.orderId)
    }
  }
}
</script>