import { axios as service } from '@/utils/request'


// export const getBizCompanyList = (data) => {
//   return service({
//     url: '/bizCompany/list',
//     method: 'post',
//     data
//   })
// }



export const getBizCompanyCategoryById = (data) => {
  return service({
    url: '/bizCompany/getCategoryById',
    method: 'post',
    data
  })
}


export const deleteBizCompanyCategoryById = (data) => {
  return service({
    url: '/bizCompany/deleteCategoryById',
    method: 'post',
    data
  })
}

export const getBizCompanyCategoryList = () => {
  return service({
    url: '/bizCompany/categoryList',
    method: 'post'
  })
}

export const saveBizCompanyCategory = (data) => {
  return service({
    url: '/bizCompany/saveCategory',
    method: 'post',
    data
  })
}
