
<template>
  <div style="border:1px solid #e5e5e5;width:100%;height:100px;position:relative">
    <div :class="`paper-padding ${hoverBorder}`" ref="refContainer">
      <div class="border-top" ref="refTop"
        @mouseover="onBorderOver('top')"  @mouseout="onBorderOver('')" 
        @click="onPaddingClick('top')">
        <span><span>{{config.top||0}}px</span></span>
      </div>
      <div class="border-bottom" ref="refBottom"
        @mouseover="onBorderOver('bottom')" @mouseout="onBorderOver('')"
        @click="onPaddingClick('bottom')">
        <span><span>{{config.bottom||0}}px</span></span>
      </div>
      <div class="border-left" @mouseover="onBorderOver('left')" @mouseout="onBorderOver('')"
        @click="onPaddingClick('left')"
      >
        <span><span>{{config.left||0}}px</span></span>
      </div>
      <div class="border-right" @mouseover="onBorderOver('right')" @mouseout="onBorderOver('')"
      @click="onPaddingClick('right')">
        <span><span>{{config.right||0}}px</span></span>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisible" destroyOnClose width="220" show-header="false"
    :close-on-click-modal="false"
    @close="dialogVisible=false"
  >
    <div>
      <el-input v-model="editSize">
        <template #suffix>px</template>
      </el-input>
    </div>
    <div class="margin-top">
      <el-button @click="editSize=10" size="small">10</el-button>
      <el-button @click="editSize=20" size="small">20</el-button>
      <el-button @click="editSize=30" size="small">30</el-button>
      <el-button @click="editSize=40" size="small">80</el-button>
    </div>
    <div class="margin-top">
      <el-button @click="editSize=50" size="small">50</el-button>
      <el-button @click="editSize=60" size="small">60</el-button>
      <el-button @click="editSize=70" size="small">70</el-button>
      <el-button @click="editSize=80" size="small">80</el-button>
    </div>
    <template #footer>
      <div class="dialog-footer align-right">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="onConfirmSizeClick">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script >

export default {
  data() {
    return {
      hoverBorder: null,
      currentPosition:null,
      dialogVisible:false,
      editSize:0 // 弹窗编辑边距值
    }
  },
  props: {
    config:{}
  },
  methods: {
    onBorderOver(position) {
      this.hoverBorder = position
    },
    onConfirmSizeClick(){
      this.config[this.currentPosition] = this.editSize
      this.dialogVisible=false
    },
    onPaddingClick(position){
      this.currentPosition = position
      this.editSize = this.config[this.currentPosition]
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.paper-padding {
  border: 8mm solid #ddd;
  border-left: 10mm solid #eee;
  border-right: 10mm solid#eee;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #666;

  &.right {
    border-right-color: #bfbfbf;
  }

  &.left {
    border-left-color: #bfbfbf;
  }

  &.top {
    border-top-color: #bfbfbf;
  }

  &.bottom {
    border-bottom-color: #bfbfbf;
  }

  .border-top,
  .border-bottom,
  .border-left,
  .border-right {
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    span>span {
      font-size: 10px;
      color: #666;
      text-align: center;
    }
  }

  .border-top {
    left: 0px;
    right: 0px;
    height: 8mm;
    top: -8mm;
    border-bottom: 1px solid#e5e5e5;
    z-index: 9999;
  }

  .border-bottom {
    left: 0px;
    right: 0px;
    height: 8mm;
    bottom: -8mm;
    border-top: 1px solid#e5e5e5;
  }

  .border-left {
    flex-direction: column;
    left: -10mm;
    width: 10mm;
    top: 0mm;
    bottom: 0mm;
    border-right: 1px solid #e5e5e5;
  }

  .border-right {
    flex-direction: column;
    top: 0mm;
    right: -10mm;
    width: 10mm;
    bottom: 0mm;
    border-left: 1px solid#e5e5e5;
  }
}
</style>