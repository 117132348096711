/**
 * 查询条件配置项
 *  用于配置打单查询条件支持的查询字段，
 {
    queryKey:'查询编码', // 用于确定查询的字段
    cType:'', //组件类型
    props:{}, // 组件属性定义
    placeholder:'请输入', 
    options:[// 针对el-select 有效
        {label:'快递单打印',value:'UNPRINT'},
        {label:'快递单已打印',value:'PRINTED'}
    ]
 }
    后台根据queryKey，组合成查询sql， queryKey===逐一确认
    快捷查询，直接报表查询条件?===>根据查询条件回显界面
    复杂的业务逻辑，自定义查询组件进行封装
 */
// import {LoginUserStore} from '@/piana/login_user_service'
// const store = LoginUserStore(window.$pinia)


export default [
    {
        queryKey:'shopIds', // 用于确定查询的字段
        cType: 'NtSelectShop', //组件类型
        label:'店铺名称', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType:'SHOP_IDS', // 默认查询类型
        },
        props:{ // 组件属性
            placeholder:'订单来源', // in 数组
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtDateRange', //组件类型
        isFixed:true,
        isMain:true, // 仅用于主面板  isQuick 用于快捷查询
        label:'', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'TIME_RANGE_CREATE' 
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型', 
            options:[// 针对el-select 有效
                {label:'按下单时间',value:'TIME_RANGE_CREATE'},
                {label:'按付款时间',value:'TIME_RANGE_PAY'},
                {label:'按发货时间',value:'TIME_RANGE_DISPATCH'},
                // {label:'按成团时间',value:'TIME_RANGE_GROUP'},
                {label:'按打印时间',value:'TIME_RANGE_PRINT'},
                // {label:'按限时发货时间',value:'TIME_RANGE_DEADLINE'}
            ]
        }
    },
    {
        queryKey:'queryTimeType', // 用于确定查询的字段
        cType: 'NtSelectDateRangeQuick', //组件类型
        isFixed:true,
        isQuick:true, // 仅用于isQuick 用于快捷方案定制页，用于设置快捷查询时间的变量
        label:'', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'TIME_RANGE_DISPATCH' 
        },
        props:{ // 组件属性
            placeholder:'时间过滤类型',
            options:[// 针对el-select 有效
                {label:'按下单时间',value:'TIME_RANGE_CREATE',default:true},
                {label:'按付款时间',value:'TIME_RANGE_PAY'},
                {label:'按发货时间',value:'TIME_RANGE_DISPATCH'},
                // {label:'按成团时间',value:'TIME_RANGE_GROUP'},
                {label:'按打印时间',value:'TIME_RANGE_PRINT'},
                {label:'按限时发货时间',value:'TIME_RANGE_DEADLINE'}
            ],
            options2:[// 针对el-select 有效
                {label:'今天',value:'TODAY'},
                {label:'昨天',value:'YESTERDAY'},
                {label:'最近7天',value:'LAST7DAYS'},
                {label:'近30天',value:'LAST30DAYS'},
                {label:'上月',value:'LASTMONTH'}
            ]
        }
    },
    {
        queryKey:'printType', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'打印状态', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType: null
        },
        props:{ // 组件属性
            placeholder:'打印状态', 
            clearable:true,
            options:[// 针对el-select 有效 快递单1 发货单2===》快递单与发货单=》组合字段
                {label:'快递单未打印',value:'WAYBILL_NOT_PRINT'}, // 一单多个包裹？？？？
                {label:'快递单已打印',value:'WAYBILL_PRINT'},
                {label:'快递单未打印+已打印',value:'ALL_WAYBILL'}, // 组合条件？？？
                {label:'发货单未打印',value:'DISPATCH_NOT_PRINT'},
                {label:'发货单已打印',value:'DISPATCH_PRINT'},
                {label:'发货单未打印+已打印',value:'ALL_DISPATCH'},
                {label:'快递单发货单均未打印',value:'NOT_DISPATCH_AND_WAYBILL'},
                {label:'快递单发货单均已打印',value:'DISPATCH_AND_WAYBILL'},
            ]
        }
    },
    {
        queryKey:'tradeStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'交易状态', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType: null
        },
        props:{ // 组件属性
            placeholder:'交易状态', 
            options:[// 针对el-select 有效
                {label:'等待发货',value:'TRADE_NO_DISPATCH'},
                {label:'部分发货',value:'TRADE_PART_DISPATCH'},
                {label:'已发货',value:'TRADE_DISPATCH'},
                {label:'预发货',value:'TRADE_PRE_DISPATCH'},
                {label:'交易完成',value:'TRADE_FINISH'},
                {label:'交易关闭',value:'TRADE_CLOSE'}
            ]
        }
    },
    {
        queryKey:'paymentType', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'支付方式', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType: null
        },
        props:{ // 组件属性
            placeholder:'支付方式', 
            options:[
                {label:'微信支付',value:'PAYMENT_WECHAT'},
                {label:'先用后付订单',value:'PAYMENT_USE_BEFORE'},
                {label:'抽奖商品0元',value:'PAYMENT_CASINO'},
                {label:'会员积分兑换订单',value:'PAYMENT_CREDIT_EXCHANGE'},
            ]
        }
    },
    {
        queryKey:'flagType', // 用于确定查询的字段
        cType: 'NtSelectFlag', //组件类型
        label:'旗帜标注', //用于快捷配置显示标题
        isFixed:true,
        defaultValue:{
            queryType: 'FLAGS', // 默认不选择下拉框项
        },
        props:{ // 组件属性
            placeholder:'旗帜标注'
        }
    },
    {
        queryKey:'deadline', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'超时状态', //用于快捷配置显示标题
        defaultValue:{
            queryType: null, // 默认不选择下拉框项
        },
        props:{ // 组件属性
            placeholder:'超时状态', 
            options:[
                {label:'一天后超时',value:'DISPATCH_ONE_DAY_OVER'},
                {label:'2天后超时',value:'DISPATCH_TWO_DAY_OVER'},
                {label:'已超时12小时',value:'DISPATCH_OVER_12'},
                {label:'已超时24小时',value:'DISPATCH_OVER_24'}
            ]
        }
    },
    {
        queryKey:'lockStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'锁定/异常状态', //用于快捷配置显示标题
        defaultValue:{
            queryType: null, // 默认不选择下拉框项
        },
        props:{ // 组件属性
            placeholder:'锁定/异常状态', 
            options:[
                {label:'未锁定',value:'INNER_UNLOCK'},
                {label:'已锁定',value:'INNER_LOCK'},
                {label:'有异常',value:'INNER_ABNORMAL'},
                {label:'无异常',value:'INNER_NO_ABNORMAL'},
                {label:'偏远地区',value:'INNER_FAR_AREA'}
            ]
        }
    },
    {
        queryKey:'packageStatus', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'包裹筛选', //用于快捷配置显示标题
        defaultValue:{
            queryType: null, // 默认不选择下拉框项
        },
        props:{ // 组件属性
            placeholder:'包裹/商品筛选', 
            
            options:[
                {label:'单包裹',value:'PACKAGE_SINGLE'},
                {label:'多包裹',value:'PACKAGE_MULTIPLE'},
                {label:'一种商品',value:'PACKAGE_GOODS_SINGLE'},
                {label:'一种单件商品',value:'PACKAGE_GOODS_SINGLE_QTY1'},
                {label:'一种多件商品',value:'PACKAGE_GOODS_SINGLE_QTY2'},
                {label:'多种商品',value:'PACKAGE_GOODS_SINGLE_MULTIPLE'}
            ]
        }
    },
    
    // {
    //     queryKey:'orderFlag', // 用于确定查询的字段
    //     cType: 'NtSelect', //组件类型
    //     label:'订单标识', //用于快捷配置显示标题
    //     props:{ // 组件属性
    //         placeholder:'订单标识', 
    //         queryType: null, // 默认不选择下拉框项
    //         options:[
    //             {label:'已合并订单',value:'PRINTED'},
    //             {label:'已拆分订单',value:'SEND'},
    //             {label:'可合并订单',value:'SEND'},
    //             {label:'已退款订单',value:'SEND'},
    //             {label:'订单可拆分',value:'SEND'},
    //             {label:'订单有发票',value:'SEND'},
    //             {label:'已拆分订单',value:'SEND'},
    //             {label:'锁定订单',value:'SEND'},
    //             {label:'非锁定订单',value:'SEND'},
    //             {label:'快递单已打印',value:'SEND'},
    //             {label:'发货单已打印',value:'SEND'},
    //             {label:'拣货单已打印',value:'SEND'},
    //             {label:'仅获取运单号',value:'SEND'},
    //         ]
    //     }
    // },
    // {
    //     queryKey:'refundType', // 用于确定查询的字段
    //     cType: 'NtSelect', //组件类型
    //     label:'退款类型', //用于快捷配置显示标题
    //     isFixed:true,
    //     props:{ // 组件属性
    //         placeholder:'退款类型', 
    //         options:[// 针对el-select 有效
    //             {label:'全部',value:null},
    //             {label:'有退款',value:'PRINTED'},
    //             {label:'无退款',value:'SEND'}
    //         ]
    //     }
    // },
    {
        queryKey:'notesFlag', // 用于确定查询的字段
        cType: 'NtSelect', //组件类型
        label:'留言备注', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:null
        },
        props:{ // 组件属性
            placeholder:'留言备注类型',
            options:[
                {label:'有留言或备注',value:'NOTE_OR'},
                {label:'无留言',value:'NOTE_NO_CUSTOM_NOTE'},
                {label:'无备注',value:'NOTE_NO_MERCHANT_NOTE'},
                {label:'有留言且有备注',value:'NOTE_BOTH'},
                {label:'无留言且有备注',value:'NOTE_NO_CUSTOM_WITH_MERCHANT_NOTE'},
                {label:'有留言',value:'NOTE_CUSTOM_NOTE'},
                {label:'有备注',value:'NOTE_MERCHANT_NOTE'},
            ]
        }
    },
    {
        queryKey:'weight', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品重量', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'weight',
            startValue:null,
            endValue:null
        },
        props:{ // 组件属性
            startPlaceholder:'重量(起)', // 区间，待确
            endPlaceholder:'重量(止)',
            size:'small'
        }
    },
    {
        queryKey:'total', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'订单金额', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'total',
            startValue:null,
            endValue:null
        },
        props:{ // 组件属性
            startPlaceholder:'金额(起)', // 区间，待确
            endPlaceholder:'金额(止)',
            size:'small'
        }
    },
    {
        queryKey:'volume', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品体积', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'volume',
            startValue:null,
            endValue:null
        },
        props:{ // 组件属性
            startPlaceholder:'体积(起)', // 区间，待确
            endPlaceholder:'体积(止)',
            size:'small'
        }
    },
    {
        queryKey:'goodsQuantity', // 用于确定查询的字段
        cType: 'NtTextRange', //组件类型
        label:'商品数量', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'quantity',
            startValue:null,
            endValue:null
        },
        props:{ // 组件属性
            startPlaceholder:'数量(起)', // 区间，待确
            endPlaceholder:'数量(止)',
            size:'small'
        }
    },
    {
        queryKey:'orderNo', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'单号类型', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'WAYBILL_NO',
            value:null
        },
        props:{ // 组件属性
            placeholder:'多个逗号分开', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'快递单号',value:'WAYBILL_NO'},
                {label:'订单号',value:'ORDER_NO'},
            ]
        }
    },
    {
        queryKey:'receiver', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'收件人信息', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'RECEIVER_NAME',
            value:null
        },
        props:{ // 组件属性
            placeholder:'', //
            size:"small",
            options:[// 针对el-select 有效
                {label:'收件人姓名',value:'RECEIVER_NAME',placeholder:'请输入姓名'},
                {label:'收件人电话',value:'RECEIVER_TEL',placeholder:'请输入电话'},
                {label:'收件地址',value:'RECEIVER_ADDRESS',placeholder:'请输入地址'},
            ]
        }
    },
    {
        queryKey:'customerNote', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'买家/留言', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'NOTE_CUSTOMER',
            value:''
        },
        props:{ // 组件属性
            options:[// 针对el-select 有效
                {label:'买家留言',value:'NOTE_CUSTOMER',default:true,placeholder:'请输入买家留言'},
                {label:'商家备注',value:'NOTE_MERCHANT',placeholder:'请输入商家备注'},
            ]
        }
    },
    {
        queryKey:'goodsCode', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'商品信息', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'GOODS_NAME',
            value:'',
        },
        props:{ // 组件属性
            options:[// 针对el-select 有效
                {label:'商品编码',value:'GOODS_CODE',placeholder:'请输入商品编码'},
                {label:'商品名称',value:'GOODS_NAME',placeholder:'请输入商品名称'},
                {label:'商品简称',value:'GOODS_SHORT_NAME',placeholder:'请输入商品简称'},
                {label:'商品ID',value:'GOODS_ID',placeholder:'请输入商品ID'},
                {label:'商品规格',value:'GOODS_SKU',placeholder:'请输入商品规格'},
                {label:'规格简称',value:'GOODS_SKU_SHORT',placeholder:'请输入规格简称'},
            ]
        }
    },
    {
        queryKey:'excludeGoodsName', // 用于确定查询的字段
        cType: 'NtSelectWithText', //组件类型
        label:'排除商品', //用于快捷配置显示标题
        defaultValue:{ // 用于控制组件初始化的值
            queryType:'EXLUDE_GOODS_NAME',
            value:'',
        },
        props:{ // 组件属性
            options:[// 针对el-select 有效
                {label:'排除商品',value:'EXLUDE_GOODS_NAME',placeholder:'请输入商品名称'},
                {label:'排除规格',value:'EXLUDE_GOODS_SKU',placeholder:'请输入规格'}
            ]
        }
    },
]