import { axios as service } from '@/utils/request'


export const queryList = (data) => {
  return service({
    url: '/waybill/queryList',
    method: 'post',
    data
  })
}
export const getWaybill = (data) => {
  return service({
    url: '/waybill/get',
    method: 'post',
    data
  })
}

export const createWaybills = (data) => {
  return service({
    url: '/waybill/create',
    method: 'post',
    data
  })
}


export const cancelWaybill = (data) => {
  return service({
    url: '/waybill/cancel',
    method: 'post',
    data
  })
}

export const recreateWaybill = (data) => {
  return service({
    url: '/waybill/recreateWaybill',
    method: 'post',
    data
  })
}


export const queryCreateResult = (data) => {
  return service({
    url: '/waybill/queryCreateResult',
    method: 'post',
    data,
    donNotShowLoading:true,
    catchTimeout:true,//自定义参数
    timeout:15*1000
  })
}

/**
 * 更新面单打印次数
 * @param {*} data 
 * @returns 
 */
export const updatePrint = (data) => {
  return service({
    url: '/waybill/updatePrint',
    method: 'post',
    data,
    donNotShowLoading:true,
    catchTimeout:true,//自定义参数
    timeout:15*1000
  })
}

/**
 * 更新发货单打印次数
 * @param {*} data 
 * @returns 
 */
export const updateDispatchPrint = (data) => {
  return service({
    url: '/waybill/updateDispatchPrint',
    method: 'post',
    data,
    donNotShowLoading:true,
    catchTimeout:true,//自定义参数
    timeout:5*1000
  })
}



/**
 * 根据运单号+ 订单Id 获取打印报文： 来源 平台接口，自身业务数据
 * @param {*} data 
 * @returns 
 */
export const getPrintData = (data) => {
  return service({
    url: '/waybill/getPrintData',
    method: 'post',
    data
  })
}



/**
 * 批量更新预发货状态
 * @param {*} data 
 * @returns 
 */
export const updatePreDispatch = (data) => {
  return service({
    url: '/waybill/updatePreDispatch',
    method: 'post',
    data
  })
}




/**
 * 批量取消预发货状态
 * @param {*} data 
 * @returns 
 */
export const cancelPreDispatch = (data) => {
  return service({
    url: '/waybill/cancelPreDispatch',
    method: 'post',
    data
  })
}
