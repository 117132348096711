<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <el-form-item label="计量单位" >
          <el-input placeholder="请输入计量单位查询" v-model="queryModel.unitName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button  @click="onAddClick" type="primary">新增</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table"
       :data="datasource" v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left"> </el-table-column>
        <el-table-column label="基本单位" width="220" prop="unitName">
          <template #default="scope">
            {{scope.row.unitName}}
            <el-tag v-if="scope.row.isMultiple" size="small">主</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="单位描述"  prop="remark"> </el-table-column>
        <el-table-column label="状态" width="120" prop="enabled">
          <template #default="scope">
            <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
            <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?'}"
          :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding margin-top">
      <el-row justify="end">
        <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
      </el-row>
      
    </el-footer>
    <el-dialog v-model="isDetailVisible" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :parentUnit="parentUnit"
      :entityId="currentRecord.id" v-if="isDetailVisible"></detail-page>
  </el-dialog>
  </el-container>
</template>

<script>
import detailPage from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'

export default {
  name: "UnitList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem
  },
  data() {
    return {
      parentUnit: null,
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.GOODS_UNIT, Object.assign({}, params));
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onAddChildClick(record) { // 添加子单位
      this.currentRecord = {}
      this.parentUnit = { id: record.id, unitName: record.unitName }
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.GOODS_UNIT, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onRowEditClick(record) {
      this.currentRecord = record

      this.isDetailVisible = true
    },
    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.GOODS_UNIT, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    }

  }
}
</script>
