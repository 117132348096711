import { axios as service } from '@/utils/request'


export const getMaterialStockList = (data) => {
  return service({
    url: '/report/stock/materialStock',
    method: 'post',
    data
  })
}
export const getMaterialExtendStockList = (data) => {
  return service({
    url: '/report/stock/materialExtendStock',
    method: 'post',
    data
  })
}

/*入库详情 */
export const getInDetailList = (data) => {
  return service({
    url: '/report/stock/inDetailList',
    method: 'post',
    data
  })
}


/*入库汇总 */
export const getInSummaryList = (data) => {
  return service({
    url: '/report/stock/inSummary',
    method: 'post',
    data
  })
}

/*出库详情 */
export const getOutDetailList = (data) => {
  return service({
    url: '/report/stock/outDetailList',
    method: 'post',
    data
  })
}

/*出库汇总 */
export const getOutSummaryList = (data) => {
  return service({
    url: '/report/stock/outSummary',
    method: 'post',
    data
  })
}


/*进销存汇总 */
export const getInOutSummaryList = (data) => {
  return service({
    url: '/report/stock/inOutSummary',
    method: 'post',
    data
  })
}

export const getAllocateList = (data) => {
  return service({
    url: '/report/stock/allocateList',
    method: 'post',
    data
  })
}
