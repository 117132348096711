<template>
  <el-container  style="height:550px;background:#fff;">
    <el-main class="padding">
      <el-tabs v-model="activeTabName" style="height:100%" class="bill-selector-tab" >
        <el-tab-pane :label="billType=='FINANCIAL_PAY'? '采购业务':'销售业务'" name="trade" lazy>
          <trade-head-list :companyId="companyId" billType="PURCHASE_IN,PURCHASE_ORDER,PURCHASE_BACK,SALE_OUT,SALE_BACK,SALE_ORDER" @cancel="onCancelClick" @choose="rows=>onRowsChooseHandler(rows,'TRADE_HEAD')"></trade-head-list>
        </el-tab-pane>
        <!-- 暂不支持 费用单退款操作，简化操作： 采购反向 费用收入，支出 进行退款操作 -->
        <!-- <el-tab-pane label="费用支出" lazy >
          <expense-list bizType="FINANCIAL_FEE_COMPANY" :billType="billType"
          :companyId="companyId" @cancel="onCancelClick" @choose="rows=>onRowsChooseHandler(rows,'INCOME_HEAD')"
          ></expense-list>
        </el-tab-pane> -->
        <el-tab-pane :label="billType=='FINANCIAL_PAY'? '预付款单':'预收款单'" name="prepay" lazy >
          <prepay-list bizType="FINANCIAL_PAY_PREPAY,FINANCIAL_TAKE_PREPAY" :billType="billType"
          :companyId="companyId" @cancel="onCancelClick" @choose="rows=>onRowsChooseHandler(rows,'PREPAY_HEAD')"
          ></prepay-list>
        </el-tab-pane>
        <el-tab-pane :label="billType=='FINANCIAL_PAY'? '期初应付':'期初应收'" name="payment_init" lazy>
          <payment-init-list  :companyId="companyId" @cancel="onCancelClick" @choose="rows=>onRowsChooseHandler(rows,'PAYMENT_INIT')"></payment-init-list>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>

<script>
  
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import TradeHeadList from './trade_bill_list' // 销售单-采购单 订单，退货
  import PaymentInitList from './payment_init_list' // 期初应收应付初始化
  import PrepayList from './prepay_list' // 预付款单列表
  import ExpenseList from './expense_list' // 预付款单列表
  export default {
    name: 'PaymentBillSelector',
    mixins:[DataListMixin],
    components: {
      TradeHeadList,
      PaymentInitList,
      PrepayList,
      ExpenseList
    },
    data () {
      return {
        activeTabName: null,
      }
    },
    
    props:{
      companyId:{
        type:String,
        required:true
      },
      // 付款类型： 收款单 PAYMENT_IN  付款单 PAYMENT_OUT  预收款 PAYMENT_IN_ADVANCE  预付款 PAYMENT_OUT_ADVANCE
      // 期初应收应付初始化  PAYMENT_INIT
      billType:{ 
        type: String,
        required: true
      },
    },
    // computed:{
    //   billType:function(){
    //     if(this.paymentType == 'PAYMENT_IN'){
    //       return 'SALE_OUT,SALE_ORDER,SALE_BACK' // 收款单=》销售出库单
    //     } else if(this.paymentType == 'PAYMENT_IN_ADVANCE'){ // 废
    //       return 'SALE_ORDER' // 预收款单=》销售订单
    //     } else if(this.paymentType == 'PAYMENT_OUT'){
    //       return 'PURCHASE_IN,PURCHASE_ORDER,PURCHASE_BACK' // 付款单=》采购入库单
    //     } else if(this.paymentType == 'PAYMENT_OUT_ADVANCE'){ // 非
    //       return 'PURCHASE_ORDER' // 预付款单=》采购订单
    //     } else if(this.paymentType == 'PAYMENT_INIT'){ // 废
    //       return 'PAYMENT_INIT' // 期初应收应付初始化
    //     } 
    //   },
    // },
    created(){
      this.activeTabName = 'trade'
      // if(['PAYMENT_OUT','PAYMENT_IN'].includes(this.paymentType)){ // 收付款
        
      // } else if(['PAYMENT_OUT_ADVANCE','PAYMENT_IN_ADVANCE'].includes(this.paymentType)){ // 预付订单
      //   this.activeTabName =  'trade_order'
      // }else if(['PREPAY_BACK'].includes(this.paymentType)){ // 预付款退款
      //   this.activeTabName = 'prepay'
      // }
    },
    methods: {
      onCancelClick(){
        this.$emit('cancel')
      },
      onRowsChooseHandler(rows,billType){
        this.$emit('choose', rows,billType)
      }
    }
  }
</script>

<style scoped lang="less">
  .bill-selector-tab{
    display:flex;
    :deep(.el-tabs__content){
      flex:1 1 100%;
      .el-tab-pane{
        height:100%;
      }
    }
  }
</style>