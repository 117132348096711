<!-- 批量操作结果弹窗提示 -->
<template>
  <div class="el-overlay" v-if="isVisible" @click="isVisible=false">
    <div class="nt-loading display-flex" style="width:200px;" @click.stop="">
      <el-icon class="is-loading"><Loading /></el-icon><span>{{text || 'loading...'}}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: "nt_loading_dialog",
  components: {
  },
  data() {
    return {
      text:null,
      isVisible: false
    }
  },
  props:{
  },
  methods: {
    async show(text) {// 显示结果弹窗
      this.text = text
      this.isVisible = true
    },
    hide(){
      this.isVisible=false
    }

  }
}
</script>
<style lang="less" scoped>
  .nt-loading{
    position: absolute;top: 50%;right: 50%;transform: translate(50%, -50%);
    background-color: #fff;
    padding:24px;
    border-radius: 5px;
  }
</style>
