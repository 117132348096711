<template>
  <el-container class="padding" style="height:300px;" v-loading="isLoading">
    <el-main class="no-padding">
      <el-container class="nt-height-100">
        <el-aside width="280px" class="margin-right nt-bg-white padding-8 nt-box-panel">
          <div :title="pack.waybillNo? '当前包裹已生成运单，不允许拆包':''"  :class="`custom-package  ${pack.waybillNo?'disabled':''}  nt-box-panel  ${pack==currentPackage? 'nt-bottom-right-check':''}`" :key="key" v-for="pack in packageList" @click="onPackageClick(pack)">
            <div style="line-height:16px;">
              <span style="font-weight:bold;">包裹#</span>{{ pack.packageNum }}
              <span v-if="pack.waybillNo">({{ pack.waybillNo }})</span>
            </div>
            <div style="line-height:16px;color:#999;" class="display-flex space-between margin-top-4">
              <div>{{pack.items.length}}个明细，共{{getQuantity(pack)}}件商品</div>
              <el-popconfirm title="确定删除当前选中包裹吗?" width="220" @confirm="onDeletePackageHandler(pack)">
                <template #reference>
                  <el-button type="danger" link :disabled="pack.waybillNo" @click.stop > 删除</el-button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </el-aside>
        <el-main class="no-padding">
          <el-table :data="datasource" ref="refTable" class="nt-resource-table" border height="100%" @selection-change="onSelectionChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="商品名称" prop="goodsName"></el-table-column>
            <el-table-column label="包裹商品数量" prop="quantity" width="120"></el-table-column>
            <el-table-column label="拆出数量" width="85">
              <template #default="{ row }">
                <el-input v-model.number="row.operateQuantity" />
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-main>

    <el-footer class="align-center no-padding padding-top align-right height-auto display-flex space-between">
      <div>
        <!-- <span style="font-size:12px;color:var(--el-color-warning)">注意：已生成面单的包裹，不允许拆分包裹</span> -->
      </div>
      <div>
        <span class="margin-right-8" v-if="currentPackage?.waybillNo" style="color:var(--el-color-danger);font-size: 12px;">当前包裹已生成运单，不允许拆包</span>
        <el-button @click="onCancelHandler"> 取消</el-button>
        <el-button @click="onConfirmHandler" :loading="isSubmitting" :disabled="!currentPackage || currentPackage.waybillNo"  type="primary"> 确定</el-button>
      </div>
    </el-footer>
  </el-container>
</template>

<script>

import { createPackage, deletePackage, packageList } from '@/service/print_order/order'

export default {
  name: "splitOrder",
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      currentPackage:null,
      datasource: [],
      packageList:[]
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  async created() {
    this.initCreatedPackage()
    console.log('fking order', this.order)
  },
  methods: {
    async initCreatedPackage() {
      // 获取订单的包裹信息，加工后界面上显示
      // 列表包含多个包裹，每个包裹包含多个商品
      this.isLoading=true

      let res = await packageList({ orderId: this.order.id })
      if (res.code == 200) {
        let packageInfo = {}
        res.data.forEach(el => {
          if (!packageInfo[el.packageNum]) {
            packageInfo[el.packageNum] = {orderId:el.orderId,packageNum:el.packageNum,waybillNo:el.waybillNo,items:[el]}
          } else {
            packageInfo[el.packageNum].items.push(el)
          }
        })
        this.packageList = Object.values(packageInfo)
        let matchItem =  this.packageList.find(item=>!item.waybillNo)
        if(!matchItem){ // 默认选中第一个有效的可拆分包裹 即未生成面单号
          matchItem = this.packageList[0]
        }
        this.onPackageClick(matchItem)
      }
      this.isLoading=false
      
    },
    getQuantity(pack){ // 商品总数
      return pack.items.reduce((cur,next)=>{
        cur+=next.quantity
        return cur
      },0)
    },
    onSelectionChange(rows){
      this.selectedRows = rows
    },
    onPackageClick(pack){
      if(this.currentPackage == pack){
        return
      }

      this.$refs.refTable.clearSelection()
      this.currentPackage = pack
      this.datasource = pack.items // this.packageInfos[key]
      this.datasource.forEach(el=>el.operateQuantity=1) // 默认拆出一个
      this.$refs.refTable.toggleAllSelection()
    },
    async onDeletePackageHandler(pack) {
      //删除确认
      this.isLoading = true
      let res = await deletePackage({
        orderId: this.order.id,
        packageNum: pack.packageNum
      })
      this.isLoading =false
      if (res.code == 200) {
        if(this.currentPackage == pack){
          this.currentPackage = this.packageList[0]
        }

        //刷新包裹列表
        let matchIndex = this.packageList.findIndex(item=>item.packageNum == pack.packageNum)
        if(matchIndex != -1){
          this.packageList.splice(matchIndex,1)
        }
        matchIndex = this.order.checkedPackageList.findIndex(item=>item.packageNum == pack.packageNum)
        if(matchIndex !=-1){
          this.order.checkedPackageList.splice(matchIndex,1)
        }
        
        this.$message.success("删除成功")
      } else {
        this.$message.error(res.msg||res.data)
      }
    },
    async onConfirmHandler() {
      // 过滤出当前需要拆分的商品信息
      let packageItemList = []
      // 不能整个包裹拆出
      let existPartSplit =  this.selectedRows.findIndex(el=>el.quantity>(el.operateQuantity||0))
      if(existPartSplit==-1 && this.selectedRows.length == this.datasource.length){
        this.$message.error("不能将包裹中商品整体拆出")
        return;
      }
      this.selectedRows.forEach(el => {
        if (el.operateQuantity) {
          // 复制明细项,加工数据，按照后台的处理格式
          let item = Object.assign({}, el, { quantity: el.operateQuantity })
          delete item.operateQuantity
          packageItemList.push(item)
        }
      })
      if (packageItemList.length == 0) {
        this.$message.error("请输入新运单包裹的商品数量")
        return;
      }
      this.isSubmitting = true
      let res = await createPackage({
        orderId: this.currentPackage.orderId,
        packageNum: this.currentPackage.packageNum,
        packageList: packageItemList
      })
      this.isSubmitting=false

      if (res.code == 200) {
        this.$message.success("操作成功")
        this.$emit("success")
      } else {
        this.$message.error(res.msg)
      }

    },
    onCancelHandler() {
      this.$emit("cancel")
    },

  }
}
</script>
<style scoped lang="less">
  .custom-package{
    padding:12px;
    cursor: pointer;
    margin-top: 8px;
    &.disabled{
      background-color: var(--el-color-info-light-9);
      cursor:not-allowed;
    }
  }
  .custom-package:first-child{
    margin-top: 0px;
  }
</style>
