import { axios as service } from '@/utils/request'

// 绑定门店列表
export const getBindingShopList = (data) => {
  return service({
    url: '/tenant/bindingShopList',
    method: 'post',
    data
  })
}


// 绑定门店默认发货地址
export const updateShopBindingAddress = (data) => {
  return service({
    url: '/tenant/updateShopBindingAddress',
    method: 'post',
    data
  })
}


// 移除门店默认发货地址
export const removeShopBindingAddress = (data) => {
  return service({
    url: '/tenant/removeShopBindingAddress',
    method: 'post',
    data
  })
}



/**
 * 生成授权码
 * @param {*} data 
 * @returns 
 */
export const createAuthCode = (data) => {
  return service({
    url: '/tenant/createAuthCode',
    method: 'post',
    data
  })
}

/**
 * 绑定授权码
 * @param {*} data 
 * @returns 
 */
export const bindAuthCode = (data) => {
  return service({
    url: '/tenant/bindAuthCode',
    method: 'post',
    data
  })
}


/**
 * 删除门店绑定
 * @param {*} data 
 * @returns 
 */
export const deleteBinding = (data) => {
  return service({
    url: '/tenant/deleteBinding',
    method: 'post',
    data
  })
}


/**
 * 获取租户同步资料信息
 * @param {*} data 
 * @returns 
 */
export const getSyncInfo = (data) => {
  return service({
    url: '/tenant/getSyncInfo',
    method: 'post',
    data
  })
}
