<template>
  <el-container class="no-padding" style="height:100%;background:#fff;">
      <!-- <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
        <span>付款历史</span>
      </el-header> -->
      <el-main class="no-padding">
        <el-table
          height="100%"
          border
          size="middle"
          rowKey="id"
          :data="datasource"
          v-loading="isLoading"
          >
          <el-table-column label="操作">
            <template #default="{row}">
              <!-- 按付款单Id进行撤销操作 -->
              <el-button size="small" type="danger" @click="onRevokeClick(row.headerId)">撤销退款</el-button>
            </template>
          </el-table-column>
          <el-table-column label="payMode" prop="accountHead.payMode" width="120"></el-table-column>
          <el-table-column label="付款单号" prop="accountHead.billNo" width="140"></el-table-column>
          <el-table-column label="付款类型" prop="accountHead.bizType" width="140"></el-table-column>
          <el-table-column label="付款日期" prop="accountHead.billTime" width="140">
            <template #default="{row}">
              {{ formatShortDate(row.accountHead.billTime) }}
            </template>
          </el-table-column>
          <el-table-column label="付款金额" width="160"  prop="total">
            <template #default="{row}">
              {{ row.total}}
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark"></el-table-column>
        </el-table>
      </el-main>
    </el-container>
  
</template>

<script>
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {getAccountItemList} from '@/service/financial/account_head'
  import {revokeRefundOrder} from '@/service/financial/account_head'
  export default {
    name: 'AccountItemList',
    mixins:[DataListMixin],
    components: {
      
    },
    data () {
      return {
        billHeader:null,
      }
    },
    props:{
      billType:{
        type:String,
        required:true
      },
      billId:{
        type:String,
        required:true
      }
    },
    methods: {
      async _loadList(params){
        return await getAccountItemList({billType:this.billType,billId: this.billId})
      },
      async onRevokeClick(billId){
        let res = await revokeRefundOrder({headerId:billId})
        if(res.code==200){
          this.loadList()
        } else{
          this.$message.error(res.msg || '撤销失败')
        }
      },
    }
  }
</script>
