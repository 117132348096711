<template>
  <div class="display-flex relative space-between" :style="{alignItems:'start',height:this.height+'px',paddingBottom: containerPaddingBottom ,transition: 'var(--el-transition-duration)'}">
    <div class="flex-auto " ref="refContainer" >
      <div class="nt-auto-field-width" ref="refItemList" :style="{'--nt-field-flex-basis': fieldWidth+'px'}">
        <slot>查询字段列表</slot>
      </div>
      
    </div>
    <div>
      <slot name="action">
        <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
      </slot>
    </div>
    <div class="nt-query-action absolute" @click="onCollapseClick" v-if="this.row>1">
      <i  :class="`iconfont icon-zhankaigengduo ${isCollcapse? 'is-up':''}`" ></i>
      <span>{{isCollcapse?'展开更多':'收起'}}</span>
    </div>
    
  </div>
  
</template>
<script >

/**
  默认显示 一行 ，行高32 gap 8 计算
  通过js 计算查询字段的数量，根据不同的屏幕宽度，自动计算每行显示的列数，由此推断需要总的行数 TODO
 */
const _height = 32
const _rowGap = 8
const _fieldWidth = 330 // 每个字段的最小宽度
export default {
  data(){
    return {
      row:1,
      fieldWidth: _fieldWidth,
      isCollcapse:true // 是否收起
    }
  },
  props:{
  },
  computed:{
    height:function () {
      return (_height+_rowGap) * (this.isCollcapse? 1 : this.row)
    },
    containerPaddingBottom:function(){
      if(this.row==1 || !this.isCollcapse){
        return '4px'
      } else{
        return '0px'
      }
    }
  },
  mounted(){
    let cWdith = this.$refs.refContainer.getBoundingClientRect().width // 字段容器的宽度
    let colCount = Math.floor(cWdith/_fieldWidth)
    // 计算出的显示个数，取小正数后，存在空出的空间，重新计算 每个字段的宽度
    let fieldCount = this.$refs.refItemList.getElementsByClassName('el-form-item').length
    if(fieldCount>=colCount){
      this.fieldWidth = Math.floor(cWdith/colCount)-12
    }
    this.row = Math.ceil(fieldCount/colCount)
  },
  methods:{
    onCollapseClick(){
      this.isCollcapse = !this.isCollcapse
    }
  }
}
</script>

<style lang="less" scoped>
  .nt-query-action{
    cursor:pointer;
    font-size:10px;
    bottom:-6px;
    z-index:10;
    left:50%;
    transition: all 0.3s linear;
    transform: translateX(-50%);
    overflow:hidden;
    background:var(--el-color-primary-light-5);
    color:var(--el-color-white);
    padding:2px 4px;
    border-radius:2px;
    &:hover{
      
      background:var(--el-color-primary);
    }
    .iconfont{
      display:inline-block;
      transition: var(--el-transition-duration);
      transform: rotate(180deg) scale(.8);
      font-size:10px;
      margin-right:2px;
      
    }
    .iconfont.is-up{
      transform: rotate(0) scale(.8);
    }
  }
</style>