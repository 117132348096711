
<!-- 应付明细(按往来单位)，按业务员，按部门
      往来单位 单据日期 单据类型 业务类型 单据编号 到期日期 单据日期 本期应收  本期已收 本期应付 本期已付  期末余额
              期初余额
              ....................................
      按往来单位-小计
-->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>应付明细(按往来单位)</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.native="onSearchQueryClick">
        <el-form-item label="客户">
          <nt-dropdown-grid  configKey="supplier" v-model="queryModel.id"
          placeholder="请选择客户" :searchable="true" :iconSearch="false"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle"  class="nt-rpt-table" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="往来单位" prop="companyName" width="280"></el-table-column>
        <el-table-column label="单据日期" prop="billTime" width="110">
          <template #default="{row}">
              <span v-if="!row.isDetail">期初余额</span>
              <span v-if="row.billTime"> {{  formatShortDate(row.billTime) }}</span>
            </template>
        </el-table-column>
        <!-- <el-table-column label="单据类型" prop="billType" width="150">
          <template #default="{row}">
            {{ this.EnumBillType[row.billType] }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="业务类型" prop="initTotal" width="90"></el-table-column> -->
        <nt-bill-no-column label="单据编码" width="165" show-bill-type></nt-bill-no-column>
        <!-- <el-table-column label="单据编码" width="175">
          <template #default="{row}">
            <el-space class="nt-custom left-top scale-8">
              <el-tag type="primary" v-if="this.EnumBillType[row.billType]">{{ this.EnumBillType[row.billType] }}</el-tag>
            </el-space>
            <el-text truncated line-clamp="1" size="small">{{row.billNo}}</el-text>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="单据编号" prop="billNo" width="160"></el-table-column> -->
        <el-table-column label="期初余额" prop="initTotal" width="110" align="right">
        </el-table-column>
        <el-table-column label="本期应收" prop="payBillTotal" width="110" align="right">
          <template #default="{row}">
            {{ (row.payBillTotal)}}
          </template>
        </el-table-column>
        <el-table-column label="本期已收" prop="paySettleTotal" width="110" align="right">
          <template #default="{row}">
            {{ row.paySettleTotal }}
          </template>
        </el-table-column>
        <el-table-column label="本期应付" prop="takeBillTotal" width="110" align="right">
          <template #default="{row}">
            {{ (row.takeBillTotal) }}
          </template>
        </el-table-column>
        <el-table-column label="本期已付" prop="takeSettleTotal" width="110" align="right">
          <template #default="{row}">
            {{ (row.takeSettleTotal)}}
          </template>
        </el-table-column>
        <el-table-column label="期末余额" prop="balance" width="110" align="right">
          <template #default="{row}">
            {{ (row.balance||0).toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column ></el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getPaymentDetailList } from '@/service/report/rpt_company'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import { getNowFormatYear, openDownloadDialog, sheet2blob } from "@/utils/util"
// import TradeBillList from '../bill_list/TradeBillList'
import NTDateRange from '@/views/plugin/nt_date_range'
import moment from 'moment'

export default {
  name: "SupplierPayment",
  mixins: [DataListMixin],
  components: {
    // TradeBillList,
    NtRptLayout,
    NtRptToolbar,
    NTDateRange
  },
  data() {
    return {
      tradeBillVisible: false,
      toggleSearchStatus: false,
      tradeBillInfo: {},

      // 查询条件
      queryModel: {
        companyId: '',
        startTime: getNowFormatYear() + '-01-01',
        endTime: moment().format('YYYY-MM-DD'),
      },

    }
  },
  mounted() {
  },
  methods: {

    onDateChange: function (value, dateString) {
      // console.log(dateString[0],dateString[1]);
      this.queryModel.startTime = dateString[0];
      this.queryModel.endTime = dateString[1];
    },
    async _loadList(params) {
      delete params.createTimeRange
      return await getPaymentDetailList(Object.assign(params, { bizType: 'SUPPLIER' }))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      // 查看欠款详情：查看本地的采购入库，采购出库， 期初初始化单据
      this.tradeBillInfo.companyId = record.id
      this.tradeBillInfo.billType = 'PURCHASE_IN,PURCHASE_BACK'
      this.tradeBillVisible = true
    }

  }
}
</script>


<style scoped>
.item-info {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 8px
}

.item-img {
  cursor: pointer;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>