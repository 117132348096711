<template>
  <el-popover v-bind="$attrs" :virtual-ref="targetElement" placement="left" virtual-triggering :visible="isVisible" trigger="click"
    :width="160">
    <el-menu class="nt-custom no-border" style="--el-menu-item-height:36px;">
      <!-- <el-menu-item index="1" @click="onSplitOrderClick">
        <el-icon></el-icon>
        <span>拆分订单</span>
      </el-menu-item> -->
      <el-menu-item index="1" @click="onSplitPackageClick" class="no-padding">
        <span class="padding-8">一单多包</span>
      </el-menu-item>
      <!-- <el-menu-item index="2">
        <el-icon><document /></el-icon>
        <span>详情</span>
      </el-menu-item> -->
      <!-- <el-menu-item index="3">
        <el-icon><setting /></el-icon>
        <span>打印</span>
      </el-menu-item> -->
      <!-- <el-menu-item index="4">
        <el-icon><setting /></el-icon>
        <span>打印发货单</span>
      </el-menu-item> -->
      <el-menu-item index="5" @click="onClearOrderAbnormalClick" class="no-padding">
        <span class="padding-8">忽略异常</span>
      </el-menu-item>
      <!-- <el-menu-item index="6">
        <el-icon>
          <setting />
        </el-icon>
        <span>操作日志</span>
      </el-menu-item> -->
    </el-menu>
  </el-popover>
  <el-dialog v-model="isPackageVisible" :close-on-click-modal="false" title="一单多包" class="nt-resource-dialog"
    width="750px" :destroyOnClose="true">
    <packagePage :order="order" @cancel="isPackageVisible = false" @success="onPackageSuccessHandler" />
  </el-dialog>
  <el-dialog v-model="isSplitVisible" :close-on-click-modal="false" title="拆分订单" class="nt-resource-dialog" width="750px"
    :destroyOnClose="true">
    <splitPage :order="order" @cancel="isSplitVisible = false" @success="onSplitOrderSuccessHandler" />
  </el-dialog>
</template>
<script >

import packagePage from '../split_package'
import splitPage from '../split'

import { clearAbnormal } from '@/service/print_order/order'
export default {
  data() {
    return {
      isVisible: false,
      targetElement: null,
      isPackageVisible: false,
      isSplitVisible: false,
      order: {}
    }
  },
  components: {
    packagePage,
    splitPage
  },
  emits: ['refresh'],
  methods: {
    show(refElement, order) {
      this.targetElement = refElement
      this.order = order
      this.isVisible = true
    },
    hide() {
      this.isVisible = false
    },
    onSplitPackageClick() {
      this.isPackageVisible = true
    },
    onSplitOrderClick() {
      this.isSplitVisible = true
    },
    onSplitOrderSuccessHandler() {
      // 通知列表刷新
    },
    onPackageSuccessHandler(){
      this.isPackageVisible =false
      this.$emit('refresh') // 通知列表属性
    },
    async onClearOrderAbnormalClick() {
      // 忽略订单异常
      let res = await clearAbnormal([this.order.id])
      if (res.code == 200) {
        this.order.orderExtra.abnormalFlag = 0
        this.$message.success('更新成功')
      } else {
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },

  }
}
</script>
