<!-- 下拉框数据源选择 -->

<template>
  <div>
    <el-select @visible-change="onDropdownVisibleChange" v-bind="$attrs" style="min-width: 200px;"
       v-model="value" v-loading="isLoading" filterable remote
      :remote-method="onStartSearch"
      @change="onSelectedHandler">
      <!-- <div slot="dropdownRender" slot-scope="menu">
        <v-nodes :vnodes="menu" />
        <a-divider style="margin: 4px 0;" />
        <a-row justify="end" type="flex" @mousedown="e => e.preventDefault()">
          <a-col class="padding-right" type="auto">
            <a-pagination size="small" 
              :show-size-changer="false"
              showLessItems
              :show-total="total => `共 ${total} 条`"
              :current="pageInfo.pageIndex"
              :pageSize="pageInfo.pageSize"
              :total="pageInfo.total"
              @change="onPageIndexChange"
              @showSizeChange="onPageSizeChange"
            />
          </a-col>
        </a-row>
        
        <div
          style="padding: 4px 8px; cursor: pointer;"
          @mousedown="e => e.preventDefault()"
          @click="onAddClick">
          <a-icon type="plus" /> Add item
        </div>
      </div> -->
      <el-option v-for="item in items" :key="item[configInfo.value]" :value="item[configInfo.value]" :label="item[configInfo.text]"
      :entity="item" :class="{'v-option': item[configInfo.value]== value}">
        {{ item[configInfo.text] }}
      </el-option>
      <template #footer>
        <el-pagination
          :current-page="this.pageInfo.pageIndex"
          :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="this.pageInfo.total"
           
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="onPageIndexChange"
          @size-change="onPageSizeChange"
        />
      </template>
    </el-select>
    <el-dialog
      :visible="detailVisible" destroyOnClose width="85%"
      class="nt-resource-dialog" :close-on-click-modal="false"
      title="详情"
      @close="detailVisible=false"
    >
      <!-- 动态组件加载... -->
      <component :is="detailPage" ></component>
    </el-dialog>
  </div>
</template>

<script>

  /*
    往来单位：供应商 客户 下拉框选择
      按需触发下拉框内容加载
      带分页，带搜索框

    下拉框数据，通过配置文件加载
      是否支持搜索
      数据源类型
      url
      method
      id 返回
      显示文本
      单选，多选

      针对分页类型，传入 {id,text} 对象类型， 控制文本显示
        根据传入的对象生成默认的 select-option  ---> 隐藏
        加载数据时，如果已存在--则隐藏

   */
  
  import {DropdownConfig} from './dropdown_config'
  export default {
    name: 'NT-Selector',
    components: {
    },
    data(){
      return {
        detailVisible:false,
        detailPage: null, // 动态添加资料页
        queryModel: {
          
        },
        pageInfo:{
          pageIndex:1,
          pageSize:10,
          total:0
        },
        configInfo: DropdownConfig[this.configKey], // 加载配置文件信息
        items: [
        ]
      }
    },
    emits: ['update:modelValue'], // v-model  配套使用  modelValue
    props:{
      modelValue: {
      },
      text:{
        type: String
      },
      configKey:{
        type:String, // 配置项key
        required:true
      }
    },
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },
    watch:{
      value:function(){ // 页面表单元素先渲染，渲染后发起后台请求获取数据并重新绑定数据时，触发watch事件
        console.log('value change watch', this.value)
        if(!this._cache && this.text){ // 首次为获取过数据时，默认为传入的显示信息
          this.items =[{
           [this.configInfo.value] : this.value,
           [this.configInfo.text] : this.text
          }]
        }
        // this.initDefaultItem()
      }
    },
    methods:{
      initDefaultItem(){

        if(this.value){
          this.items =[{
           [this.configInfo.value] : this.value,
           [this.configInfo.text] : this.text
          }]
        }
      },
      async onDropdownVisibleChange(isShow){

        // if(this.dataLoaded || !isShow){
        //   return
        // }
        // this.dataLoaded = true
        // await this.loadDatasource()
      },
      async onStartSearch(keyword){
        // if(!keyword){
        //   return
        // }
        /*首次无关键查询时，缓存数据*/
        console.log('onStartSearch',keyword)
        // TODO  搜索数据  延时操作
        // this.keyword = keyword
        if(!keyword && this._cache){
          this.items = this._cache
          this.pageInfo.total = this._total
          return
        }
        this.pageInfo.pageIndex = 1
        this.visible =true
        await this.loadDatasource({companyName: keyword})
        if(!keyword){
          this._cache = this.items
          this._cache = this.pageInfo.total
        }
      },
      async loadDatasource(params){
        // 根据配置信息加载数据源
        // 首次显示时，加载列表数据
        
        // 根据配置参数加载数据源，动态构建参数
        let arParams = []
        if(this.configInfo.entityType){
          arParams.push(this.configInfo.entityType)
        }
        let requestParam = Object.assign({},this.configInfo.requestParam,{ pageIndex: this.pageInfo.pageIndex, pageSize: this.pageInfo.pageSize },params)
        arParams.push(requestParam)
        let res = await this.configInfo.postMethod(...arParams) // 动态构建参数
        
        if(res.code ==200){
          this.items=[]
          let matchItem = res.data.list.find(el=>el[this.configInfo.value]==this.value)
          if(!matchItem && this.value){ // 没有进行关键词搜索时，入参作为第一option
            // this.items=[{
            //   [this.configInfo.value]: this.value,
            //   [this.configInfo.text]: this.text
            // }]
          }
          this.items.push(...res.data.list)
        }
        console.log('fking item',this.items)
      },
      onSelectedHandler(val,option){
        this.pageInfo.pageIndex = 1
        console.log('val', val)
        this.$emit('update:modelValue',val ) // 触发watch 监控事件
      },
      onAddClick(){
        // 弹窗数据源编辑框 TODO  根据配置组件加载弹窗
      },
      onDetailSaveSuccessHandler(newEntity){ // 创建资料后回调
        // 成功创建后，直接插入列表，默认选中
      },
      onPageSizeChange(current, val) {
        this.pageInfo.pageSize = val
        this.loadDatasource()
      },

      onPageIndexChange(val) {
        this.pageInfo.pageIndex = val
        this.loadDatasource()
      },

    }

  }
</script>
