<!-- 可拖拽网格
  https://github.com/decoin1987/vue-table-resize
 -->
<template>
  <div>
    <table ref="refTable" @click="onTableClick" >
      <thead>
        <tr style="height:10mm;background:#fff;">
          <template v-for="(col,index) in config.columns" >
            <th  :data-index="index" :style="{width: (col.width||60) +'px'}">{{col.label}}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr style="height:12mm;" v-for="(line,index) in getPageData()">
          <template v-for="(col) in config.columns" >
            <td  >
             <el-text line-clamp="1">{{ parseText(line,col.prop)}}</el-text> 
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script >
// import {getReflectValue} from '@/views/designer/config/reflect'


let SETTING =  {
  gripTagName: "div",
  tableClass: "table__resize-table",
  gripClass: "table__resize-grip",
  gripActiveClass: "table__resize-grip--active",
  tableId: null,
  emit: null,
}

export default {
  data () {
    return {
      
    }
  },
  props:{
    config:{},
    isPreview:{
      type: Boolean,
      default:false
    },
    pageIndex:{
      type: Number,
      default:1
    },
    pageSize:{
      type: Number
    },
    datasource:{

    }
  },
  created(){
  },
  mounted(){
    
    const OPTIONS = {};
    let table = this.$refs.refTable
    for (let el in SETTING) {
      OPTIONS[el] = table.hasAttribute(el)
        ? table.getAttribute(el.toLowerCase())
        : SETTING[el];
    }
    
    const GRIP_CLASS = "." + OPTIONS.gripClass;
    let TH_EL;
    let START_X;

    table.className = OPTIONS.tableClass;
    // if (options && options.tableId) {
    //   table.id = options.tableId;
    // }
    if(this.isPreview){
      return
    }

    table.querySelectorAll("th").forEach((th) => {
      th.style.position = "relative";
      let grip = document.createElement(OPTIONS.gripTagName);
      grip.className = OPTIONS.gripClass;
      grip.addEventListener("mousedown", (el) => {
        if (el) {
          TH_EL = th;
          el.target.classList.add(OPTIONS.gripActiveClass);
          START_X = el.pageX - th.offsetWidth;
        }
      });
      grip.addEventListener("dblclick", () => {
        th.style.width = "";
      });
      th.appendChild(grip);
    });

    // const HANDLERS =
    //   (vnode.data && vnode.data.on) ||
    //   (vnode.componentOptions && vnode.componentOptions.listeners);

    // const emit = (EMITTER_NAME, data = {}) => {
    //   if (HANDLERS && HANDLERS[EMITTER_NAME]) {
    //     HANDLERS[EMITTER_NAME](data);
    //   }
    // };

    document.addEventListener("mousemove", (el) => {
      if (TH_EL) {
        TH_EL.style.width = el.pageX - START_X + "px";
      }
    });

    document.addEventListener("mouseup", () => {
      if (TH_EL) {
        TH_EL.querySelector(GRIP_CLASS).classList.remove(
          OPTIONS.gripActiveClass
        );
        let width = TH_EL.clientWidth
        let index = TH_EL.dataset.index
        this.$emit('onColumnWidthChange',index, width)
        // TODO 触发拖拽结束事件
        // if (OPTIONS.emit) {
        //   emit(OPTIONS.emit, TH_EL);
        // }
        TH_EL = null;
      }
    });
  },
  methods:{
    onTableClick(){
      this.$emit('click')
    },
    parseText(row,expr){
      return getReflectValue(row,expr)
    },
    getPageData(){
      if(!this.datasource){
        return []
      }
      let startIndex = this.pageIndex*this.pageSize
      let list = this.datasource.slice(startIndex, startIndex + this.pageSize)
      return list
    }
    
  }

}
</script>
<style lang="less">
  .table__resize-table {
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #ccc;

    td,
    th {
      border: 1px solid #ccc;
      padding: 5px 10px;
    }

    th {
      user-select: none;
    }

    th:last-child {
      .table__resize-grip {
        //right: 0;
        //width: 4px;
        &::after {
          border-right: none;
        }
      }
    }
  }

  .table__resize-grip {
    top: 0;
    right: -5px;
    bottom: 0;
    width: 9px;
    position: absolute;
    cursor: col-resize;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      top: 10%;
      bottom: 10%;
      left: 1px;
      right: 1px;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }

    &:hover {
      background-color: #ccc;
    }
  }

  .table__resize-grip--active {
    background-color: #ccc;
  }
</style>