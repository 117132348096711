<template>
  <el-popover trigger="click" width="300px" placement="left-start">
    <template #reference>
      <el-button link type="primary">标记说明</el-button>
    </template>
    <div class="nt-print-age" >
      <div class="nt-tip-item" ><span class=" order-tag check pre-send">预</span><span>预发货</span></div>
      <div class="nt-tip-item"><span class=" order-tag check merge">合</span><span>当前订单由其他2张或以上合并而来</span></div>
      <div class="nt-tip-item"><span class=" order-tag check abnormal">异</span><span>订单存在异常</span></div>
      <div class="nt-tip-item"><span  class=" order-tag check lock">锁</span><span>订单已被锁定</span></div>
      <div class="nt-tip-item"><span  class=" order-tag check multiple">多</span><span>订单存在多个运单包裹</span></div>
      
      <div class="nt-tip-item"><span class=" order-tag check waybill">运</span><span>运单已生成</span></div>
      <div class="nt-tip-item"><span  class=" order-tag check print">打</span><span>运单已打印</span></div>
      <div class="nt-tip-item"><span  class=" order-tag check send">发</span><span>运单已发货</span></div>
      <div class="nt-tip-item"><span class="order-tag" style="color:var(--el-color-danger);">[超]</span><span>订单发货已超时</span></div>
    </div>
  </el-popover>
</template>

<script>

  export default {
    data () {
      return {
      }
    },
    props:{
    },
    methods:{
      
    }
}
</script>
