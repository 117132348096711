<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title>收款单</template>

          <template #extra>
            <el-button class="el-menu-item" @click="jumpToPage('/financial/payment_in_list', '付款单列表')">单据列表</el-button>
            <el-button class="el-menu-item" @click="onPaymentBillClick" v-if="!viewOnly">选择付款单据</el-button>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row class="form-row">
          <el-col :span="6">
            <nt-form-item prop="companyId" label="客户" :rules="formValidator('required', '请选择客户')">
              <nt-dropdown-grid configKey="company" v-model="formModel.companyId"
                v-model:text="formModel.companyName" :readonly="viewOnly" placeholder="请选择客户" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%;" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="handlerId" label="财务人员">
              <nt-dropdown-grid configKey="staff" v-model="formModel.handlerId" style="width:100%"
                v-model:text="formModel.handlerName" :disabled="viewOnly" placeholder="请选择财务人员" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="6">
            <nt-form-item label="单据类型" :rules="formValidator('required', '单据类型不能为空')">
              <el-select v-model="formModel.bizType" :disabled="viewOnly">
                <el-option value="FINANCIAL_TAKE" label="应收款" />
                <el-option value="FINANCIAL_TAKE_PREPAY" label="预收款" />
              </el-select>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="收款合计">
              <el-input  v-model="formModel.total" :readnly = "viewOnly || formModel.bizType == 'FINANCIAL_TAKE_PREPAY'"/>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="优惠金额" prop="discountTotal">
              <el-input placeholder="请输入优惠金额" :readonly="viewOnly" v-model="formModel.discountTotal" @change="onDiscountTotalChange" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="实际收款">
              <el-input  v-model="formModel.billTotal" readonly />
            </nt-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <nt-form-item prop="paymentMethodId" label="结算方式" :rules="formValidator('required', '请选择结算方式')">
              <nt-dropdown-grid configKey="paymentMethod" style="width:100%" v-model="formModel.paymentMethodId" v-model:text="formModel.paymentMethodName"
                :disabled="viewOnly" placeholder="请选择结算方式" :searchable="true" :iconSearch="false" @choose="onPaymentMethodChoose"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="accountId" label="收款账户" :rules="formValidator('required', '请选择收款账户')">
              <nt-dropdown-grid configKey="account" v-model="formModel.accountId" v-model:text="formModel.accountName"
                :disabled="viewOnly" placeholder="请选择收款账户" :searchable="true" :iconSearch="false" @choose="onAccountChoose"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item label="备注" prop="remark">
              <el-input placeholder="请输入备注" v-model="formModel.remark" />
            </nt-form-item>
          </el-col>
        </el-row>
        <el-button @click="onRefOrderRefrechClick">重算订单分摊</el-button>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.accountItemList" border height="100%" show-summary
          :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="40" prop="status" v-if="!viewOnly" align="center">
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon>
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="单据类型" width="120" prop="billType">
            <template #default="scope">
              {{ this.EnumBillType[scope.row.billType] }}
            </template>
          </el-table-column>
          <el-table-column label="单据编号" prop="billNo"  width="165">
            <template #default="{row}">
              <el-text truncated line-clamp="1" size="small" >{{ row.billNo }}</el-text>
              <el-tooltip placement="top-start" :content="`当前单据核销冲抵原订单预付款:${row.currentDepositOff}`">
                <el-tag type="danger" size="small" class="absolute right-top" v-if="row.currentDepositOff">冲预付</el-tag>
              </el-tooltip>
              <el-tag type="danger" size="small" class="absolute right-top" v-if="['FINANCIAL_PAY_PREPAY','FINANCIAL_TAKE_PREPAY'].includes(row.billType)">冲预付</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="单据总额" prop="billTotal" align="right" width="100"></el-table-column>
          <el-table-column label="已结算" prop="settleTotal" align="right" width="100"></el-table-column>
          <!-- 付款单： 增加显示 已预付 已冲销 本次冲销-自动分摊，针对采购（引订单） -->
          <el-table-column label="已预付" prop="orderDepositTotal" align="right" width="100" ></el-table-column>
          <el-table-column label="已冲销" prop="depositOffTotal" align="right" width="100" ></el-table-column>
          <el-table-column label="本次冲销" prop="currentDepositOff" align="right" width="100" ></el-table-column>
          <el-table-column label="本次收款" width="100" prop="total" align="right" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`accountItemList[${scope.$index}].total`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入收款金额')">
                <el-input v-model="scope.row.total" />
              </nt-form-item>
              <span v-else>{{ scope.row.total }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`accountItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>

        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-dialog v-model="paymentBillVisible" :close-on-click-modal="false" destroyOnClose width="75%" class="nt-resource-dialog" title="收款单据选择"
    @close="paymentBillVisible = false">
    <payment-bill-selector :companyId="formModel.companyId" :billType="billType"
      @choose="onPaymentBillChooseHandler" @cancel="paymentBillVisible = false"></payment-bill-selector>
  </el-dialog>
</template>
<script>

import NtBillLayout from '@/views/layout/nt_bill_layout'
import PaymentBillSelector from '@/views/financial/selector/payment_bill_selector' // 采购，销售订单

import { DataBillMixin } from '@/utils/data_bill_mixin'

import NtFormItem from '@/views/plugin/nt_form_item'

import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'

export default {
  name: "PaymentIn",
  mixins: [
    DataBillMixin
  ],
  components: {
    
    PaymentBillSelector,
    NtFormItem,
    NtBillLayout,
    NtBillToolbar
  },
  data() {
    return {
      paymentBillVisible: false,
      fileList: [],
      billType: 'FINANCIAL_TAKE',
      entityType:EnumEntityType.ACCOUNT_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    entityId: {
      type: String
    }
  },
  // computed: {
  //   paymentType: function () { // 付款类型： 付款   预付订单   预付供应商
  //     let info={
  //       'ORDER_ADVANCE':'PAYMENT_IN_ADVANCE', // 订单预付款
  //       'PAYMENT':'PAYMENT_IN', // 付款
  //       'PREPAY_BACK':'PREPAY_BACK', // 预付款退款
  //     }
  //     return info[this.formModel.bizType] || 'NOT_FOUND'
  //   }
  // },
  watch: {
    entityId: function () {
      this.loadEntity()
    },
    "formModel.companyId": function () {
      // 切换供应商时，清空列表
      if (!this.isLoading) {
        this.formModel.accountItemList = []
      }
    },
    "formModel.bizType": function () {
      // 切换供应商时，清空列表
      if (!this.isLoading) {
        this.formModel.accountItemList = []
      }
    }
  },
  methods: {
    async _createNewEntity() {
      // let billNo = await this.createBillNo('FK')
      return {
        data: {
          // billNo: billNo,
          bizType: 'FINANCIAL_TAKE',
          accountItemList: [],
          billTime: this.getDateTime() // getNowFormatDateTime()
        }
      }
    },
    async _createByRefBill(refBill) { // 引用订单直接生成入库单
      if (refBill?.id) {
        // this.isLoading = true
        // 采购订单，退货 ,采购入库 直接生成付款单
        let res = await this.getEntity(this.EnumEntityType.TRADE_HEAD, { id: refBill.id })
        if (res?.code == 200) {

          let details = [{
            billNo: res.data.billNo,
            billId: res.data.id,
            billType: refBill.billType,
            billTotal: res.data.billTotal,
            total: res.data.billTotal - (res.data.depositTotal || 0),
            finishTotal: res.data.depositTotal || 0,
            settleTotal: res.data.settleTotal || 0
          }]

          // let billNo = await this.createBillNo('SK')
          this.formModel = {
            // billNo: billNo,
            billTime: this.getDateTime(), // getNowFormatDateTime(),
            bizType: refBill.bizType,
            companyId: res.data.companyId,
            companyName: res.data.companyName,
            accountItemList: details
          }
          // 进入新增状态
          this.viewOnly = false

          this._loadFinished()
        }
      }
    },

    async _loadEntity(params) {
      if (!this.entityId) {
        this.fileList = []
        return this._createNewEntity()

      } else {
        let res = await this.getEntity(this.entityType, { id: this.entityId })
        return res
      }
    },

    _loadFinished() {
      this._billAdvancePayInfo = {} // 提取采购入库引用订单的信息， orderDepositTotal  depositOffTotal --最小
      this.formModel.accountItemList.forEach(el => {
        if(el.orderDepositTotal){ // 原订单存在预付款
           let refOrder = this._billAdvancePayInfo[el.linkId]
           if(!refOrder){
            this._billAdvancePayInfo[el.linkId] = {orderDepositTotal: el.orderDepositTotal,depositOffTotal:(el.currentDepositOff||0)}
           } else if(el.currentDepositOff<refOrder.depositOffTotal){
            refOrder.depositOffTotal = el.currentDepositOff // 记录最后一下冲销后余额（最小值）
           }
        }
      })
      
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      if (this.formModel.bizType == 'FINANCIAL_TAKE' && this.formModel.accountItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }

      return true
    },

    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, billType: this.billType }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))

      if (this.fileList && this.fileList.length > 0) {
        postData.fileName = this.fileList
      }

      return this.saveEntity(this.entityType, postData)
    },
    onPaymentMethodChoose(val){
      if(!this.formModel.accountId &&  val.accountId){
        this.formModel.accountId = val.accountId
        this.formModel.accountName = val.accountName
      }
    },
    onAccountChoose(val){
      if(!this.formModel.paymentMethodId && val.paymentMethodId){
        this.formModel.paymentMethodId = val.paymentMethodId
        this.formModel.paymentMethodName = val.paymentMethodName
      }
    },

    onRowDeleteClick(rowIndex) {
      let row = this.formModel.accountItemList.splice(rowIndex, 1)[0]
      if(row.billType == "SALE_OUT" && row.orderDepositTotal){ // 销售引用存在预付款的订单，重算分摊
        this.onRefOrderRefrechClick()
      }
      this.onFormChangeHandler()
    },

    onPaymentBillClick() {
      let companyId = this.formModel.companyId
      if (!companyId) {
        this.$message.warning('请选择客户！');
        return
      }
      this.paymentBillVisible = true
    },

    onPaymentBillChooseHandler(rows, billType/* tradhead payment-init */) { // TODO
      let newList = []

      if (billType == 'TRADE_HEAD') {
        newList = this.addTradeHeadBill(rows)
      } else if (billType == 'PAYMENT_INIT') {
        newList = this.addPaymentInitBill(rows)
      } else if (billType == 'PREPAY_HEAD') { //  预付款，预收款
        newList = this.addPrepayBill(rows)
      }
      else if (billType == 'INCOME_HEAD') { //  预付款，预收款
        newList = this.addIncomeBill(rows)
      }
      this.formModel.accountItemList.push(...newList)
      this.onFormChangeHandler()
      this.paymentBillVisible = false
    },
    addTradeHeadBill(bills) { // 添加付款单据
      // console.log('fking',bills)
      let listEx = []
      bills.forEach(el => {
        if(el.linkId && !this._billAdvancePayInfo[el.linkId] && el.orderDepositTotal){
          // 销售出库单，引用，且销售订单存在预付款， 主要处理多张不同的采购入库引用同一张订单时，自动冲销剩余可重新金额
          this._billAdvancePayInfo[el.linkId] = { orderDepositTotal: (el.orderDepositTotal || 0), depositOffTotal: (el.depositOffTotal || 0) }
        }
        let item = {}
        item.billType = el.billType
        item.billId = el.id
        item.linkId = el.linkId // 关联订单--主要用于重算分摊
        item.billNo = el.billNo
        item.payMode = el.payMode //资金方向
        item.billTotal = el.payMode * (el.billTotal || 0)  //单据总额
        item.settleTotal =  (el.settleTotal || 0) // 已结算金额
        // 销售退货 payMode = 1 进  -1 销售出库
        item.total =  el.payMode * (Math.abs(item.billTotal) - item.settleTotal) // 默认剩余未支付金额
          // [deposit_off_total] 累计订单冲销金额   current_deposit_off 本次冲销订单金额
        let refTrade = this._billAdvancePayInfo[el.linkId] // 采购入库引采购单，且 订单有预付款
        if(refTrade){
          let depositBalance= (refTrade.orderDepositTotal ||0)-(refTrade.depositOffTotal||0) // 订单剩余可冲销余额
          let payTotal= (el.billTotal -(el.settleTotal||0))// 采购剩余应付金额
          item.orderDepositTotal = (refTrade.orderDepositTotal||0)
          item.depositOffTotal = refTrade.depositOffTotal||0
          item.currentDepositOff= depositBalance>=payTotal ? payTotal : depositBalance // 本次冲销 可冲销金额大于应付金额，全额冲销
          item.total = payTotal - (item.currentDepositOff||0)//应付
          refTrade.depositOffTotal += item.currentDepositOff // 更新原单累计冲销
        }
        listEx.push(item)
      })


      return listEx
    },

    addPaymentInitBill(bills) { // 添加期初应收应付
      let listEx = []
      let total = 0
      this.paymentInitVisible = false
      // TODO  过滤重复数据
      for (let i = 0; i < bills.length; i++) {
        let info = bills[i]
        let newItem = {}

        newItem.billType = info.billType
        newItem.payMode = info.payMode
        newItem.billId = info.id
        newItem.billNo = info.billNo
        newItem.billTotal = info.payMode * info.total // 单据总额
        newItem.settleTotal =  (info.settleTotal || 0) 
        newItem.total = info.payMode * (info.total - (info.settleTotal || 0)) // 应付

        total += newItem.total
        listEx.push(newItem)
      }
      return listEx
    },
    addIncomeBill(bills) { // 选择费用结算单据
      let listEx = []
      let total = 0
      this.paymentInitVisible = false
      // TODO  过滤重复数据
      for (let i = 0; i < bills.length; i++) {
        let info = bills[i]
        let newItem = {}

        newItem.billType = info.billType
        newItem.payMode = info.payMode
        newItem.billId = info.id
        newItem.billNo = info.billNo
        newItem.billTotal = info.payMode * info.total // 单据总额
        newItem.settleTotal =  (info.settleTotal || 0) 
        newItem.total = info.payMode * (info.total - (info.settleTotal || 0)) // 应付


        total += newItem.total
        listEx.push(newItem)
      }
      return listEx
    },

    addPrepayBill(bills) { // 添加期初应收应付
      let listEx = []
      let total = 0
      for (let i = 0; i < bills.length; i++) {
        let info = bills[i]
        let newItem = {}

        newItem.payMode = info.payMode
        newItem.billType = info.bizType
        newItem.billId = info.id
        newItem.billNo = info.billNo
        newItem.billTotal = -info.payMode * info.total
        newItem.settleTotal = (info.settleTotal || 0) //已结算
        newItem.total = -info.payMode *(info.total - (info.settleTotal || 0)) 

        total += newItem.total
        listEx.push(newItem)
      }
      return listEx
    },

    onFormChangeHandler() {
      // 根据当前编辑信息，重新计算：实付金额
      let total = 0
      if(this.formModel.bizType == 'FINANCIAL_TAKE_PREPAY'){
        total = this.formModel.total
      } else {
        this.formModel.accountItemList.forEach(el => total += parseFloat(el.total))
        this.formModel.total = total
      }
      this.formModel.discountTotal = this.formModel.discountTotal || 0
      this.formModel.billTotal = total - (this.formModel.discountTotal || 0)
    },
    async onRefOrderRefrechClick(){
      let list = this.formModel.accountItemList.filter(item=>{
        return item.billType == 'SALE_OUT' && item.orderDepositTotal
      })
      
      if(list.length){
        let arIds = list.map(item=>item.billId)
        let res = await this.getEntityList(this.EnumEntityType.TRADE_HEAD, { id: arIds.join(',') })

        // 提取关联订单的预付款及已冲销信息
        this._billAdvancePayInfo = []
        res.data.forEach(item=>{
          if(item.linkId && !this._billAdvancePayInfo[item.linkId]){
            this._billAdvancePayInfo[item.linkId] ={orderDepositTotal: item.orderDepositTotal,depositOffTotal:item.depositOffTotal }
          }
        }) // 关联订单
        if(this._billAdvancePayInfo.length){
          // 重算网格的分摊金额--- 删除明细，也需触发重算
          this.formModel.accountItemList.forEach(el=>{
            let refTrade = this._billAdvancePayInfo[el.linkId]
            if(refTrade){
              let depositBalance= (refTrade.orderDepositTotal ||0)-(refTrade.depositOffTotal||0) // 订单剩余可冲销余额
              let payTotal= (el.billTotal -(el.settleTotal||0))// 采购剩余应付金额
              item.orderDepositTotal = (refTrade.orderDepositTotal||0)
              item.depositOffTotal = refTrade.depositOffTotal||0
              item.currentDepositOff= depositBalance>=payTotal ? payTotal : depositBalance // 本次冲销 可冲销金额大于应付金额，全额冲销
              item.total = payTotal - (item.currentDepositOff||0)//应付
              refTrade.depositOffTotal += item.currentDepositOff // 更新原单累计冲销
            }
          })
        }
      }
    },
    getSummaries({columns,data}){
      let sumResult = []
      columns.forEach((column, index) => {
        if (index <2 ) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }

}
</script>
