<template>
  <el-tree class="nt-height-100 nt-list-node"
    :data="datasource"
    :props="props"
    :indent="0"
    @node-click="onNodeClick"
    default-expand-all
  >
  </el-tree>
</template>
<script >

import { DataListMixin } from '@/utils/data_list_mixin.js'
export default {
  mixins: [DataListMixin],
  data(){
    return {
      datasource:[],
      props: {
        label: 'feeName'
      }
    }
  },
  components:{},
  props:{
    ioType:{
      type: Number,
      required:true
    }
  },
  methods:{
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.FEE_TYPE, Object.assign({ioType:this.ioType}, params));
    },
    _loadFinished(){ //列表加载结束后默认显示显示第一个
      this.onNodeClick(this.datasource.length>0 ? this.datasource[0]:null)
    },
    onNodeClick(node){
      this.$emit('change',node)
    }
  }
}
</script>
