<template>
  <el-menu class=""  mode="horizontal" :ellipsis="false" 
    style="height:42px;
    --el-menu-hover-text-color:var(--el-color-primary-dark-2);
    --el-menu-base-level-padding:15px;--el-menu-icon-width:10px;"
    :close-on-click-outside="true"
    text-color="#666">
      <!-- <el-menu-item index="0">
        <div style="font-weight: bold;font-size:20px;">
          <slot name="title" >店铺名称<el-icon @click="onCommandClick('SWITCH')" ><Switch/></el-icon></slot>
        </div>
      </el-menu-item> -->
      <WaybillTemplateSelector @choose="val=>onCommandClick('WAYBILL_TEMPLATE',val)"></WaybillTemplateSelector>
      <div class="flex-auto" ></div>
      <el-menu-item index="1" @click="onCommandClick('CREATE_WAYBILL')"><i class="iconfont icon-chuangjian margin-right-4"></i>打印快递单</el-menu-item>
      <!-- <el-menu-item index="2" @click="onCommandClick('PRINT_WAYBILL')"><i class="iconfont icon-chuangjian margin-right-4"></i>打印面单</el-menu-item> -->
      <!-- <el-menu-item index="33" @click="onCommandClick('PRINT_PICKUP')"><i class="iconfont icon-chuangjian margin-right-4"></i>打印拣货单</el-menu-item> -->
      <el-menu-item index="3" @click="onCommandClick('PRINT_DISPATCH')"><i class="iconfont icon-chuangjian margin-right-4"></i>打印发货单</el-menu-item>
      <el-menu-item index="4" @click="onCommandClick('DELIVERY_SEND')"><i class="iconfont icon-chuangjian margin-right-4"></i>发货</el-menu-item>
      <!-- <el-menu-item index="44" @click="onCommandClick('DELIVERY_PRE_SEND')"><i class="iconfont icon-chuangjian margin-right-4"></i>预发货</el-menu-item> -->
      <el-menu-item index="5" @click="onCommandClick('MERAGE')"><i class="iconfont icon-chuangjian margin-right-4"></i>合单</el-menu-item>
      <el-menu-item index="6" @click="onCommandClick('CANCEL_MERAGE')"><i class="iconfont icon-chuangjian margin-right-4"></i>撤销合单</el-menu-item>
      <el-menu-item index="7" @click="onCommandClick('BATCH_REMARK')"><i class="iconfont icon-chuangjian margin-right-4"></i>批量备注</el-menu-item>
      <el-sub-menu index="8">
        <template #title>测试</template>
        <el-menu-item index="8-1" @click="onCommandClick('RERESH')">刷新</el-menu-item>
        <el-menu-item index="8-2" @click="onCommandClick('DEBUG')">调试</el-menu-item>
        <el-menu-item index="8-3" @click="onCommandClick('CREATE_AFTERSALE')">生成售后单</el-menu-item>
        <el-menu-item index="8-4" @click="onCommandClick('PRITN_TEST')">打印测试</el-menu-item>
      </el-sub-menu>
    </el-menu>
  
</template>

<script>
  import WaybillTemplateSelector from './waybill_template_selector'
  export default {
    data () {
      return {
      }
    },
    components:{WaybillTemplateSelector},
    methods:{
      onCommandClick:function(cmd,val){
        this.$emit('command', cmd,val) 
      }
    }
}
</script>