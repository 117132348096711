<template>
  <el-table-column v-bind="$attrs">
    <template #default="{row}">
      <el-space class="nt-custom left-top scale-8">
        <!-- 是否显示单据类型？ -->
        <el-tag type="primary" v-if="showBillType && row[billTypeName]">{{EnumBillType[row[billTypeName]] || row[billTypeName]}}</el-tag>
        <el-tag v-if="showStatus && row.status == '0'" type="danger" >未审核</el-tag>
        <el-tag v-if="showStatus && row.status == '1'" type="success">已审核</el-tag>
        <slot name="extra"  :row="row"></slot>
      </el-space>
      <el-link class="bill-no" style="color:var(--el-color-primary-light-3)" :underline="true" @click="onBillNoClick(row)">{{ row[billNoName] }}</el-link>
    </template>
  </el-table-column>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script >

import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
import { EnumBillType } from '@/utils/enum_utils'
export default {
  data() {
    return {
      EnumBillType
    }
  },
  components:{BillViewerPage},
  props: {
    billNoName:{ // 单据号 字段名称
      type:String,
      default:"billNo"
    },
    idName:{ // 单据主键id 名称
      type:String,
      default:"billId"
    },
    billTypeName:{ // 单据类型 名称
      type:String,
      default:"billType"
    },
    showBillType:{
      type: Boolean,
      default:false
    },
    showStatus:{
      type: Boolean,
      default:false
    },
    viewOnly:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    async onBillNoClick(row) {
      // console.log('xxxx', row,this.billTypeName,this.idName)
      this.$refs.refViewer.showBill(row[this.billTypeName],row[this.idName],this.viewOnly)
    },
  }
}
</script>

<style lang="less" scoped>
  .bill-no{
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
    font-size:var(--el-font-size-extra-small)
  }
</style>