<template>
  <div class="nt-nav-menu display-flex">
    <!-- <logo /> -->
    <div class="align-center padding-top">
      <img style="width: 30px;border-radius: 100%;" src="https://gw.alipayobjects.com/zos/rmsportal/dURIMkkrRFpPgTuzkwnB.png"/>
    </div>
    <el-scrollbar style="flex-basis: 1 1 100%;">
      <el-menu  :collapse="true" style="width:100%;background-color: transparent;">
        <el-popover :ref="`refModule_${subIndex}`" placement="right-end" width="auto" trigger="hover" popper-style="border-radius:0px;" :show-arrow="false" :offset="8" v-for="(sub,subIndex) in rootMenuList" :key="'xx_'+ subIndex">
          <template #reference>
            <el-menu-item  v-if="hasSubRouteMenu(sub.id)" class="padding-4" style="margin-bottom: 4px;">
              <div class="menu-title">
                <i :class="`iconfont ${sub.icon}`" style="font-size:26px;"></i>
                <div  style="font-size:12px;margin-top: 6px;">{{sub.menuName}}</div>
              </div>
              
            </el-menu-item>
          </template>
          <el-row type="flex" class="pop-secondary-menu padding-left padding-right" :gutter="40" >
            <template v-for="(item,index) in getMenuListByModule(sub.id)" :key="'s_'+index">
              <div class="nt-module"  v-if="hasSubRouteMenu(item.id)">
                <div class="nt-module-title" >
                  {{item.menuName}}
                </div>
                <div class="nt-module-item " v-for="(child,childIndex) in getMenuListByModule(item.id)" @click="onMenuItemClick(child,subIndex)" :key="'s_x'+subIndex">
                  <div>{{child.menuName}}</div>
                  <el-button v-if="getFunctionList(child.id)" @click.stop ="onMenuItemClick(getFunctionList(child.id),subIndex)" class="nt-function-list" size="small" type="primary">
                    列表
                  </el-button>
                </div>
              </div>
            </template>
            
          </el-row>
        </el-popover>
      </el-menu>
    </el-scrollbar>
  </div>

</template>

<script>
  import store from '@/store'
  export default {
    name: "SideMenu2",
    components: {  },
    mixins: [],
    data () {
      return {
        // Xcollapsed:this.collapsed
      }
    },
    props: {
      menus: {
        type: Array,
        required: true
      }
    },
    computed:{
      rootMenuList:function(){
        // TODO  过滤子节点空的模块
        return store.getters.allMenu.filter(item=>!item.parentId)
      }
    },
    created(){
    },
    methods: {
      getMenuListByModule(moduleId){
        // TODO  增加过滤子节点为空的数据
        return store.getters.allMenu.filter(item=>item.parentId == moduleId)
      },
      hasSubRouteMenu(menuId){ // 根据当前节点搜索树节点，
        let children = store.getters.allMenu.filter(item=>item.parentId == menuId)
        for(let el of children){
          if(!!el.url){
            return true
          } else {
            return this.hasSubRouteMenu(el.id)
          }

        }
        return false
      },
      getFunctionList(moduleId){ // 单据列表
        return store.getters.allMenu.find(item=>item.parentId == moduleId)
      },
      
      onMenuItemClick(menuItem,mIndex/*主模块索引 */){
        menuItem.text = menuItem.menuName
        this.$emit('menuSelect', menuItem)
        console.log(mIndex, this.$refs[`refModule_${mIndex}`])
        let popMenu = this.$refs[`refModule_${mIndex}`]
        if(popMenu){
          popMenu[0].hide()
        }
        // this.$refs[`refModule_${mIndex}`]?[0].delayHide()
      }

    }
  }
</script>
<style lang="less" scoped>
.pop-secondary-menu{
    // background-image: url(https://img0.baidu.com/it/u=250041470,2245037106&fm=253&fmt=auto&app=138&f=PNG?w=500&h=738);
  // background-size: 1px 10px 50px 100px;
    // backdrop-filter: blur(54px) saturate(150%);
  }
.nt-nav-menu{
  height:100%;background-color: #001529;flex-direction: column;
  // https://pic.616pic.com/bg_w1180/00/19/88/P231V26e6z.jpg
  // https://pic.616pic.com/bg_w1180/00/23/10/4Dh9CCvtbm.jpg
  background-image: url(https://pic.616pic.com/bg_w1180/00/23/10/4Dh9CCvtbm.jpg);
  // background-size: 1px 10px 50px 100px;
  backdrop-filter: blur(54px) saturate(150%);
  :deep(.el-menu){
    border:none !important;
  }
  
  .menu-title{
    width: 100%;
    line-height: initial;
    color:hsla(0,0%,100%,.65);
    text-align: center;
  }
  :deep(.el-menu-item){
    margin:4px;
    padding:8px 2px !important;
    height:auto;
    &:hover{
      border-radius: 4px;
      // border:1px solid #eee;
      box-shadow: 2px 2px 8px 0 rgba(29, 35, 41, 0.05);
      background-color: #1890ff;
      .menu-title{
        color:#fff;
      }
      
    }
  }
}
.nt-module{
  min-width:180px;
  padding:0px 8px;
  .nt-module-title{
    padding:4px 12px;
    background-color:var(--el-color-primary-light-5);
    color:#fff;
    border-radius: 5px;
    font-size:12px;
    position: relative;
    opacity: .8;
    margin-bottom: 8px;
  }
  .nt-module-item{
    display:flex;
    padding:2px 12px;
    justify-content:space-between;
    align-items:center; 
    cursor: pointer; 
    height:40px;
    line-height:40px;
    .nt-function-list{
      display:none;
    }
    &:hover{
      background:var(--el-menu-hover-bg-color);
      .nt-function-list{
        display:initial;
      }
    } 
  }
  &:not(:last-child){
    border-right:1px solid #f5f5f5;
  }
}

</style>