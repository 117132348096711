<template>
  <el-container class="nt-bg-page nt-height-100">
    <el-main class="padding">
      <!-- el-main padding 给内部阴影区域留出显示空间 -->
      <el-container class="nt-height-100">
        <el-aside width="250px" class="nt-box-panel margin-right nt-bg-white">
          <role-list-page @change="onRoleSelectChange"/>
        </el-aside>
        <el-main class="padding nt-box-panel">
          <el-container class="nt-height-100">
            <el-header class="no-margin no-padding height-auto display-flex space-between">
              <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
                <el-form-item label="登录名称">
                  <el-input placeholder="输入登录名称模糊查询" autocomplete="off" v-model="queryModel.loginName"></el-input>
                </el-form-item>
                <el-form-item label="用户姓名">
                  <el-input placeholder="输入用户姓名模糊查询" autocomplete="off" v-model="queryModel.userName"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
                </el-form-item>
              </el-form>
              <div>
                <el-button  @click="onAddClick" type="primary">新增</el-button>
              </div>
              
            </el-header>
            <el-main class="no-padding margin-top">
              <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
                v-loading="isLoading">
                <el-table-column label="登录名称" width="220" prop="loginName"></el-table-column>
                <el-table-column label="用户姓名" width="180" prop="userName"></el-table-column>
                <el-table-column label="角色" width="220" prop="roleName"></el-table-column>
                <el-table-column label="电话号码" width="140" prop="phonenum"></el-table-column>
                <el-table-column label="备注"  prop="remark"></el-table-column>
                <el-table-column label="状态" width="100" prop="remark">
                  <template #default="scope">
                    <el-tag v-if="scope.row.isEnabled" type="success">启用</el-tag>
                    <el-tag v-if="!scope.row.isEnabled" type="danger">禁用</el-tag>
                  </template>
                </el-table-column> 
                <nt-operation-column label="操作" fixed="right" width="55" align="center" 
                  :confirm="{DELETE:'是否确认删除?'}"
                  :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DEFAULT']" 
                  @command="onColumnOperationAction">
                  <template #extra>
                  </template>
                </nt-operation-column>
                
              </el-table>
            </el-main>
            <el-footer class="no-padding margin-top height-auto">
              <el-row type="flex" justify="end">
                <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
                  :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
                  layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
                  @size-change="onPageSizeChange" />
              </el-row>
            </el-footer>
          </el-container>

        </el-main>
      </el-container>

    </el-main>

    <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="850px" class="nt-resource-dialog" title="详情"
      @cancel="isDetailVisible = false">

      <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
        v-if="isDetailVisible"></detail-page>
    </el-dialog>
  </el-container>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail/'
import RoleListPage from './role'
export default {
  name: "UserList",
  mixins: [DataListMixin],
  components: {
    RoleListPage,
    detailPage
  },
  data() {
    return {
      selectedRole: null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedRole){ //不加载用户列表
        return null
      }
      return await this.getPageEntityList(this.EnumEntityType.USER, Object.assign({roleId:this.selectedRole.id}, params));
    },
    
    onSearchQueryClick() {
      this.loadList();
      // this.getSystemConfig();
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList();
      // this.getSystemConfig();
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.USER, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.USER, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onRoleSelectChange(role){
      console.log('selected role', role)
      this.selectedRole = role
      // 清空用户列表查询条件
      this.loadList()
    }
    
  }
}
</script>