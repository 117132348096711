
<template>
  <nt-list-layout>
    <template #toolbar>
      <nt-bill-list-toolbar @command="onToolbarClick" :hidden="['close', 'activate']">
        <template #title>库存期初</template>
      </nt-bill-list-toolbar>
    </template>
    <template #header>
      <el-form  @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom" >
        <el-form-item label="单据编号" class="no-margin-bottom" >
          <el-input placeholder="请输入单据编号" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期"  >
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" 
          start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD" ></nt-date-range>
        </el-form-item>
        <el-form-item label="商品信息" >
          <el-input placeholder="请输入条码、名称、型号" v-model="queryModel.keyword"></el-input>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        :data="datasource"
        height="100%"
        size="large"
        @selection-change="onSelectionChange"
        class="nt-bill-table-list"
        v-loading="isLoading">
        <el-table-column label="#" width="40" type="index" fixed="left"/>
        <el-table-column type="selection" width="40" align="center"/>

        <el-table-column label="仓库" width="90" prop="storeName">
        </el-table-column>
        <el-table-column label="商品名称"  prop="goodsName">
        </el-table-column>
        <el-table-column label="单位" width="90" prop="unitName">
        </el-table-column>
        <el-table-column label="属性" width="90" prop="sku">
        </el-table-column>
        <el-table-column label="数量" width="90" prop="quantity" align="right">
        </el-table-column>
        <el-table-column label="成本价" width="90" prop="price" align="right">
        </el-table-column>
        <el-table-column label="成本金额" width="80" prop="costTotal" align="right">
        </el-table-column>
        <el-table-column label="备注" width="200" prop="remark">
        </el-table-column>
        <el-table-column label="制单时间" width="160" prop="createTime">
        </el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['ENABLE','DISABLE','DEFAULT']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-list-layout>
  
  <el-dialog v-model="isDetailVisible" destroyOnClose width="500" class="nt-resource-dialog"  title="库存初始化"
    :close-on-click-modal="false" @close="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :readOnly="viewOnly" :height="550"
      :entityId="currentRecord?.id" v-if="isDetailVisible"></detail-page>
  </el-dialog>
  <nt-result-dialog ref="refResult"></nt-result-dialog>
</template>
<script>


import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail'

import NtDateRange from '@/views/plugin/nt_date_range'
import NtQueryPanel from '@/views/plugin/nt_query_panel'


export default {
  name: "PurchaseOrderList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtDateRange,
    NtQueryPanel
  },
  data() {
    return {
      currentRecord: {},
      toggleSearchStatus: false,
      // 查询条件
      queryModel: {
        billNo: "",
        remark: ""
      }
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.STORE_FLOW, Object.assign({ billType: 'REPOSITORY_STORE_INIT' }, params));
    },
    
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.STORE_FLOW, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    //拖拽组件

    onRowEditClick(record) {
      this.currentRecord = record
      this.viewOnly = false
      this.isDetailVisible = true
    },
    onBillNoClick(record) {
      this.currentRecord = record
      this.viewOnly = true
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },

    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    }
  }
}
</script>