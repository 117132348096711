<!--单据明细列详情-->
<template>
  <el-container class="nt-bg-page padding">
    <el-main class="padding nt-bg-white nt-box-panel">
      <el-form ref="formRef" :model="formData" label-width="110" >
        <el-form-item prop="label" label="列标题">
          <div class="display-flex">
            <el-input v-model="formData.label" style="width:180px;"></el-input>
            <TextStyleEditor v-model="formData.titleStyle" :hidden="['size','weight']"></TextStyleEditor>
          </div>
        </el-form-item>
        <el-form-item prop="textStyle" label="明细内容样式">
          <TextStyleEditor v-model="formData.textStyle"></TextStyleEditor>
        </el-form-item>
        <el-form-item prop="subtotalType" label="合计方式">
          <el-radio-group v-model="formData.subtotalType">
            <el-radio-button label="NONE" >无</el-radio-button>
            <el-radio-button label="SUM" >SUM</el-radio-button>
            <el-radio-button label="AVG" >AVG</el-radio-button>
            <el-radio-button label="COUNT" >COUNT</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="subtotalText" label="显示合计文本">
          <el-switch v-model="formData.isSubtotalText" />
        </el-form-item>
        <el-form-item prop="colSpan" label="合计文本占列数">
          <el-input v-model="formData.colspan"></el-input>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="no-padding no-marign height-auto align-right margin-top">
      <el-button type="info" @click="onCancelClick">取消</el-button>
      <el-button type="primary" @click="onConfirmClick">确定</el-button>
    </el-footer>
  </el-container>
</template>

<script >

import TextStyleEditor from '../property/text_style.vue'
export default {
  data () {
    return {
      formData:Object.assign({textStyle:{textAlign:'left',fontWeight:'',fontSize:14},subtotalType:false},this.config)
    }
  },
  components:{TextStyleEditor},
  props:{
    config:{
      type: Object
    }
  },
  methods:{
    preCheckConfig(){

    },
    onConfirmClick(){
      this.$emit('success', JSON.parse(JSON.stringify(this.formData)))
    },
    onCancelClick(){
      this.$emit('cancel')
    },
  }
}
</script>