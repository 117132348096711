<!-- 

  文本属性配置面板
    1. 文本表示式-支持手动输入
    2. 标题前缀
    2. 字体大小-粗体
    3. 是否打印
    4. 坐标位置
    5. 控件大小
    6. 内边距
    9. 字间距
-->
<template>
  <el-form ref="formRef" :model="config" label-position="top" size="small" @change="onPropertyChangeHandler">
    <div style="font-weight:bold;font-size:20px;" class="margin-bottom">普通文本</div>
    <el-form-item prop="v" label="文本内容">
        <!-- 如果配置内容为变量，则不允许进行文本编辑 -->
      <el-input v-model="config.v" @change="onValueChange" >
        <!-- <template #suffix>
          <el-popover
            :visible="varVisible"
            placement="left-start"
            title="打印内容选择"
            trigger="click"
          >
            <! -- 根据配置文件显示可选择的变量列表 -- >
            <div v-for="item in VarList">
              <div>{{item.title }}</div>
              <div>
                <el-button @click="_=>onVarClick(el)" v-for="el in item.items">{{el.title}}</el-button>
              </div>
              
            </div>
            <template #reference>
              <el-icon class="el-input__icon" @click="varVisible=true"><calendar /></el-icon>
            </template>
          </el-popover>
          
        </template> -->
      </el-input>
    </el-form-item>
    <!-- <el-form-item prop="prefix" label="前缀">
      <el-input v-model="config.prefix" />
    </el-form-item> -->
    <el-form-item  prop="fontStyle" label="文本样式" >
      <TextStyleEditor v-model="config.textStyle" />
    </el-form-item>
    <el-form-item label="">
      <span class="margin-right">是否打印</span><el-switch v-model="config.isHidden" />
    </el-form-item>
    <el-form-item label="位置">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.left" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.top" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="大小">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.width" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.height" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    
  </el-form>
</template>

<script >

  import TextStyleEditor from './text_style.vue'
  export default {
    data () {
      return {
      }
    },
    inject:['billType'],
    components:{TextStyleEditor},
    props:{
      config:{}
    },
    methods:{
    }
  }
</script> 