<template>
  <el-form @change="onFormChangeHandler" :model="formModel" ref="frmEdit" label-width="110" class="nt-height-100" :style="{height: this.height+ 'px'}" v-loading="isLoading || isSubmitting">
    <nt-bill-layout>
      <template #toolbar>
        <nt-bill-toolbar :entity="formModel" :pageInfo="pageNavigator" @command="onToolbarCommand" :show-exist = "height>0"
          :isSubmitting="isSubmitting">
          <template #title >销售订单</template>
          <template #status v-if="formModel.status==1" >
            <el-tag v-if="executeStatus=='close'" type="info" round>已关闭</el-tag>
            <el-tag v-if="executeStatus=='finish'" type="success" round>已全部执行</el-tag>
            <el-tag v-if="executeStatus=='part'" type="warning" round>部分执行</el-tag>
            <el-tag v-if="executeStatus=='none'" type="warning" round>未执行</el-tag>
          </template>
          <template #extra>
            <!-- 审核状态，且非关闭，系统完成关闭+ 人工关闭时，允许手动关闭 -->
            <el-button   class="el-menu-item" @click="onCloseClick" v-if="formModel.id && formModel.status == '1' && !['close','finish'].includes(executeStatus)" >关闭</el-button>
            <el-button  class="el-menu-item" @click="jumpToPage('/sale_order_list','销售订单列表')" >单据列表</el-button>
            <!-- 入库时直接进入采购入库单，并自动填充数据 -->
            <el-dropdown split-button @command="onCommandClick" @click="onSaleOutClick" v-if="formModel.status==1">出库
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="SALE_OUT_HISTORY">出库记录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </nt-bill-toolbar>
      </template>
      <template #header>
        <el-row>
          <el-col :span="6">
            <nt-form-item prop="companyId" label="客户" :rules="formValidator('required', '选择客户')">
              <nt-dropdown-grid configKey="customer" v-model="formModel.companyId"
                v-model:text="formModel.companyName" :disabled="viewOnly" placeholder="请选择客户" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="单据日期" :rules="formValidator('required', '选择选择日期')">
              <el-date-picker v-model="formModel.billTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billTime" label="计划到货时间" >
              <el-date-picker v-model="formModel.planTime" type="date" :disabled="viewOnly" style="width:100%" />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="billNo" label="单据编号">
              <el-input placeholder="请输入单据编号" v-model="formModel.billNo" readonly />
            </nt-form-item>
          </el-col>
          <el-col :span="6">
            <nt-form-item prop="deptId" label="部门">
              <nt-dropdown-grid configKey="dept" v-model="formModel.deptId" style="width:100%;"
                v-model:text="formModel.deptName" :readonly="viewOnly" placeholder="请选择部门" :searchable="true"
                :iconSearch="false"></nt-dropdown-grid>
              </nt-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="remark" label="备注">
              <el-input placeholder="请输入备注" v-model="formModel.remark" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end" v-if="formModel.depositTotal">
          <el-space style="font-weight:bold;">
            <span>已预付：{{ formModel.depositTotal}}</span>
            <span>已冲销：{{ formModel.settleTotal}}</span>
          </el-space>
        </el-row>
      </template>
      <template #body>
        <el-table class="nt-editable-table" :data="formModel.tradeItemList" border height="100%" show-summary :summary-method="getSummaries">
          <el-table-column label="#" width="35" type="index">
          </el-table-column>
          <el-table-column width="45" prop="status" v-if="!viewOnly" align="center">
            <template #header v-if="!viewOnly">
              <el-icon @click="onAddLineClick" size="18" style="padding-top:12px;font-weight:bold;cursor:pointer;">
                <Plus />
              </el-icon>
            </template>
            <template #default="scope">
              <el-popconfirm title="确定删除吗?" @confirm="onRowDeleteClick(scope.$index)">
                <template #reference>
                  <el-icon style="cursor:pointer;">
                    <Delete color="red" />
                  </el-icon>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column label="商品" width="200" prop="goodsName" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <span v-if="viewOnly || scope.row.linkId">{{ scope.row.goods.goodsName }}</span>
              <nt-form-item   label-width="0" :prop="`tradeItemList[${scope.$index}].goodsId`" v-else
                :rules="formValidator('required', '请选择商品')">
                <nt-dropdown-grid key="goods" configKey="goods" v-model:text="scope.row.goods.goodsName"
                :disabled="viewOnly" placeholder="编码/助记码/名称" :searchable="true"
                @choose="(rows) => onRowGoodsChange([rows], scope.row, scope.$index)"
                ></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单位" width="110" prop="unitName">
            <template #default="scope">
              <span  v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.unitName }}</span>
              <nt-form-item   label-width="0" :prop="`tradeItemList[${scope.$index}].unitId`" v-else
                :rules="formValidator('required', '请选择计量单位')">
                <nt-dropdown-grid key="goodsUnit" configKey="goodsUnit" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.unitId" v-model:text="scope.row.unitName"
                :disabled="viewOnly" placeholder="计量单位" :searchable="false"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="SKU" width="180" prop="sku">
            <template #default="scope">
              <span   v-if="viewOnly || scope.row.linkId || !scope.row.goodsId">{{ scope.row.sku }}</span>
              <nt-form-item  label-width="0"  :prop="`tradeItemList[${scope.$index}].goodsSkuId`" v-else>
                <nt-dropdown-grid key="goodsUnit" configKey="goodsSku" :request-param="{goodsId:scope.row.goodsId}" v-model="scope.row.goodsSkuId" v-model:text="scope.row.sku"
                :disabled="viewOnly" placeholder="商品属性" :searchable="true"></nt-dropdown-grid>
              </nt-form-item>
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stockQuantity" width="80">
          </el-table-column>
          <el-table-column label="价格" width="80" prop="price" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].price`" v-if="!viewOnly"
                :rules="formValidator('required', '请输入价格')">
                <el-input v-model="scope.row.price" />
              </nt-form-item>
              <span v-else>{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="80" prop="quantity" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" v-model="scope.row.quantity" v-if="!viewOnly"
                :prop="`tradeItemList[${scope.$index}].quantity`" :rules="formValidator('required', '请输入数量')">
                <el-input v-model="scope.row.quantity" />
              </nt-form-item>
              <span v-else>{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已执行" width="80" prop="finishQuantity" v-if="formModel.status==1">
            <template #default="scope">
              <span>{{ scope.row.finishQuantity }}</span>
              <el-tag type="danger" class="right-top scale-8" v-if="!scope.row.finishQuantity" size="small">未执行</el-tag>
              <el-tag type="warning" class="right-top scale-8" v-else-if="scope.row.finishQuantity<scope.row.quantity" size="small">部分执行</el-tag>
              <el-tag type="success" class="right-top scale-8" v-else-if="scope.row.finishQuantity>= scope.row.quantity" size="small">已执行</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="税率(%)" width="80" prop="taxRate" :class-name="!viewOnly ? 'editor' : ''">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].taxRate`" v-if="!viewOnly">
                <el-input v-model="scope.row.taxRate" />
              </nt-form-item>
              <span v-else>{{ scope.row.taxRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税价合计" width="90" prop="totalWithTax">
          </el-table-column>
          <el-table-column label="备注" prop="remark" class-name="editor">
            <template #default="scope">
              <nt-form-item label-width="0" :prop="`tradeItemList[${scope.$index}].remark`" v-if="!viewOnly">
                <el-input v-model="scope.row.remark" />
              </nt-form-item>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nt-bill-layout>
  </el-form>
  
  <el-drawer
    v-model="accountItemVisible" destroyOnClose size="65%"
    class="nt-resource-dialog"
    title="付款历史"
    :z-index="99"
    @close="accountItemVisible=false">
    <account-item-list  @cancel="accountItemVisible =false"  billType="SALE_ORDER" :billId="formModel.id" v-if="accountItemVisible"></account-item-list>
  </el-drawer>
  <el-drawer
    v-model="saleOutHistoryVisible" destroyOnClose size="65%"
    class="nt-resource-dialog"
    title="销售出库历史"
    :z-index="99"
    @close="saleOutHistoryVisible=false">
    <trade-head-list  @cancel="saleOutHistoryVisible =false"  billType="SALE_OUT" :billId="formModel.id" v-if="saleOutHistoryVisible"></trade-head-list>
  </el-drawer>
</template>
<script>
import NtBillLayout from '@/views/layout/nt_bill_layout'
import { closeTradeHead } from '@/service/trade_head'
import { DataBillMixin } from '@/utils/data_bill_mixin'
import { getNowFormatDateTime } from '@/utils/util'
import billSelector from '@/views/bill/selector/LinkBillList'
// 付款历史
import AccountItemList from '@/views/financial/selector/account_item_list'
// 出库记录
import TradeHeadList from '@/views/bill/selector/TradeHeadList'
import NtFormItem from '@/views/plugin/nt_form_item'

import NtBillToolbar from '@/views/plugin/nt_bill_toolbar'
import { EnumEntityType } from '@/utils/enum_utils'
export default {
  name: "PurchaseOrderModal",
  mixins: [DataBillMixin],
  components: {
    billSelector,
    AccountItemList,
    TradeHeadList,
    NtFormItem,
    NtBillLayout,
    NtBillToolbar
  },

  data() {
    return {
      accountItemVisible: false,
      saleOutHistoryVisible: false,
      billType: 'SALE_ORDER',
      entityType:EnumEntityType.TRADE_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },

  props: {
    entityId: {
      type: String
    }
  },

  watch: {
    entityId: function () {
      this.loadEntity()
    }
  },
  computed:{
    executeStatus:function(){
      // 订单执行状态
      if(this.formModel.isClosed){
        return 'close' // 手动关闭订单，不再引用
      } 
      let info={
        '0': 'none',
        '1': 'part',// 部分执行
        '2': 'finish'
      }
      return info[this.formModel.executeStatus]
    }
  },
  created() {
  },
  methods: {
    async _initialize() {
      
    },
    async _createNewEntity() {
      // let billNo = await this.createBillNo('XSDD')
      return {
        data: {
          // billNo: billNo,
          tradeItemList: [{goods:{} }],
          discountTotal: 0,
          otherTotal: 0,
          total: 0,
          billTotal: 0,
          companyId: '',
          companyName: '',
          linkId: '',
          linkNo: '',
          billTime: this.getDateTime() // getNowFormatDateTime()
        }
      }
    },

    async _loadEntity(params) {
      if (!this.entityId) {
        this.fileList = []
        return this._createNewEntity()
      } else {
        let res = await this.getEntity(this.entityType, { id: this.entityId })
        return res
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      if (this.formModel.tradeItemList.length == 0) {
        this.$message.error(`单据明细不能为空`)
        return false
      }

      return true
    },

    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId, billType: this.billType }, this.formModel)
      postData = JSON.parse(JSON.stringify(postData))
      if (this.fileList && this.fileList.length > 0) {
        postData.fileName = this.fileList
      }

      let res = this.saveEntity(this.entityType, postData)
      return res
    },

    _loadFinished() {
      this.$refs.frmEdit?.clearValidate()
    },

    onSaleOutClick(){ // 生成销售出库单
      if(this.formModel.isClosed){
        this.$message.error("当前订单已关闭，不允许继续操作入库")
        return
      }
      if(this.formModel.executeStatus == 2){
        this.$message.error("当前订单已执行完毕")
        return
      }
      this.jumpToPage('/sale_out', '销售出库',{id:this.formModel.id,billType:this.billType})
    },

    async onCloseClick(){ // 关闭订单
      let res = await closeTradeHead({id:this.formModel.id})
      if(res.code == 200){
        this.formModel.closeFlag |= 4  // 更新状态
        this.$message.success("操作成功")
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }

    },

    onFormChangeHandler() {
      // 根据当前编辑信息，重新计算：实付金额
      let total = 0
      this.formModel.tradeItemList.forEach(el => {
        let itemTotal = (el.quantity || 0) * (el.price || 0)
        let taxTotal = (el.quantity || 0) * (el.price || 0) * (el.taxRate || 0)
        el.totalWithTax = itemTotal + taxTotal
        total += el.totalWithTax
      })

      this.formModel.total = total
      this.formModel.billTotal = total - (this.formModel.discountTotal || 0) + (this.formModel.otherTotal || 0)
      this.$forceUpdate()
    },

    onRowGoodsChange(rows, record, index) {
      if (!rows || rows.length === 0) {
        return
      }
      let getAddItem = row => {
        return {
          goodsId: row.id,
          // goodsSkuId: row.id,
          // sku: row.sku,
          unitId:row.unitId,
          unitName: row.unitName,
          // goodsName: row.goodsName,
          goods:row,
          quantity: 1,
          price: row.purchaseDecimal, // TODO  采购价格计算逻辑
          taxRate: 0,
          taxMoney: 0,
          stockQuantity:0,
          totalWithTax: row.purchaseDecimal
        }
      }
      // 根据id判断是否已经添加
      rows.forEach(async(el, idx) => {
        let matchItem = this.formModel.tradeItemList.find(item => item.goodsSkuId == el.id)
        // console.log('matchItem', matchItem)
        if (!matchItem) {
          let newItem = getAddItem(el)
          // newItem.key = "__" + (new Date()).getTime() + "_" + idx
          if (idx == 0) {// 第一个选择的商品直接填充当前行
            let current = this.formModel.tradeItemList[index]
            newItem.quantity = current.quantity || 1
            newItem.remark = current.remark
            newItem.taxRate = current.taxRate
            this.formModel.tradeItemList[index] = newItem // 替换地址所指向位置，内存地址
              // 根据仓库，goodsSku 获取存货信息
              this.refreshStockInfo(this.formModel.tradeItemList[index]) // 注意：直接采用newItem 作为入参，ui 未刷新
          } else {
            this.formModel.tradeItemList.push(newItem)
            // 根据仓库，goodsSku 获取存货信息
            this.refreshStockInfo(newItem)
          }
        }
      })

      this.$forceUpdate()
    },
    async onStoreChangeHandler(row){ // 切换仓库时，重新计算存货信息
      row.stockQuantity= await this.refreshStockInfo(row)
    },

    onRowDeleteClick(rowIndex) {
      this.formModel.tradeItemList.splice(rowIndex, 1)
    },

    onAddLineClick() {
      this.formModel.tradeItemList.push({ goods:{} })
    },
    async _copyBill() { // 复制单据
      // let billNo = await this.createBillNo('XSDD')
      this.formModel.tradeItemList?.forEach(item=>{
        item.linkType = null
        item.linkNo = null
        item.linkId = null
      })
      return {
        id: '',
        companyId: '',
        companyName: '',
        status: null,
        linkId: '',
        linkType:'',
        linkNo: '',
        billTime: this.getDateTime() // getNowFormatDateTime()
      }
    },

    onCommandClick(command){
      if(command == 'SALE_OUT_HISTORY'){ // 销售出库记录
        this.saleOutHistoryVisible = true
      } else if(command == 'PAYMENT_HISTORY'){ // 付款历史
        this.accountItemVisible = true
      }
    },

    getSummaries({ columns, data }) {
      let sumResult = []
      columns.forEach((column, index) => {
        if (index < 2) {
          sumResult[2] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sumResult[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)}`
        } else {
          // sumResult[index] = 'N/A'
        }
      })
      return sumResult
    }
  }
}
</script>