<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom ${getHighlightClass()} display-flex ${config?'config':''}`" :style="{columnGap:'0px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <el-input v-model="value" v-bind="$attrs" :placeholder="placeholder"  :style="{width:(width||140)+'px'}" @change="onTextChange"></el-input>
  </div>
  
</template>

<script>
  // import {defaultQueryModel} from '../config/query_editor'
  export default {
    data () {
      return {
        value: this.modelValue?.value,
        // defaultValue:defaultQueryModel[this.queryKey]
      }
    },
    props:{
      modelValue:{},
      defaultValue:{ // 默认选择的值
      //   type:String
      },
      
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      queryKey:{
        type:String,
        required:true
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        this.value = this.modelValue?.value
      }
    },
    
    methods:{
      getHighlightClass(){
        return this.defaultValue?.value != this.value? 'highlight':''
      },
      onTextChange(){
        let data = {
          // queryKey: this.queryKey,//'QUERY_TIME_RANGE',
          queryType: this.queryType,// 'ORDER_TIME/PAYMENT_TIME/SEND_TIME/',
          // valueType: 'SINGLE',
          value:this.value,
          // startValue: dayjs(this.dateRange[0]).format('YYYY-MM-DD') , // '区间开始值'
          // endValue: dayjs(this.dateRange[1]).format('YYYY-MM-DD') // '区间开始值'
        }
        this.$emit('update:modelValue',data)
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    &.highlight{
      :deep(.el-select__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-select){
        flex:1;
        .el-select__wrapper{
          box-shadow: none;
          
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
  }
</style>