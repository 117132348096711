<!-- 多属性sku配置管理
  由于显示商品详情时，必须获取全量的根属性，用于展示，选择时，采用popover 的形式，无需弹窗
-->
<template>
  
  <el-container class="nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto">
      <div class="display-flex margin-bottom-4">
        <div>此处通过选择弹窗，属性选择--避免属性过多占用空间,最大支持3个</div>
        <el-checkbox v-model="formModel.multipleAttr" :true-value="1" :false-value="0">是否开启多单位</el-checkbox>
        <el-popover placement="left" :width="400" trigger="click" ref="refAttr">
          <template #reference>
            <el-button size="small">选择属性</el-button>
          </template>
          <el-table :data="attrList" @selection-change="onAttrSelectionChange" border class="nt-resource-table">
            <el-table-column type="selection"></el-table-column>
            <el-table-column width="150" prop="attrName" label="属性" />
            <el-table-column label="属性值">
              <template #default="{row}">
                <el-space :size="5">
                  <el-tag v-for="item in row.childAttrList" :key="item.id" small>{{ item.attrName }}</el-tag>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
          <div class="padding-top align-center">
            <el-button size="small" @click="onAttrCancelClick">取消</el-button> <el-button @click="onAttrConfirmClick" type="primary" size="small">确定</el-button>
          </div>
        </el-popover>
        
      </div>
      <el-table  border :data="goodsSelectedAttrList" class="nt-resource-table">
        <el-table-column  type="selection"></el-table-column>
        <el-table-column label="属性" prop="attrName" width="120">
        </el-table-column>
        <el-table-column label="属性值">
          <template #default="{row}">
            <el-space :size="5">
              <el-checkbox @change="checked=>onAttrChangeHandler(row.id,item.id,checked)" :checked="checkAttrIsChoose(row.id,item.id)" v-for="item in row.childAttrList" :label="item.attrName" size="small" :key="item.id" />
              <!-- <el-tag small v-for="item in row.childAttrList" >{{ item.attrName }}</el-tag> -->
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" head-align="center" align="center">
          <template #default="{row}">
            <el-button type="danger" size="small" @click="onTopTableRowRemoveClick(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="margin-top-4" style="color:var(--el-color-primary)">已选属性组合 在业务单据中使用时，只会显示下方的属性组合 <el-button @click="onCreateAttrGroupClick" size="small">生成属性组合</el-button></div>
    </el-header>
    <el-main class="no-padding">
      <el-table  border height="100%" :data="formModel.goodsSkuList" class="nt-resource-table">
        <el-table-column  type="selection"></el-table-column>
        <el-table-column label="图片" width="60">
        </el-table-column>
        <el-table-column :label="item.attrName" :prop="item.id" v-for="(item,index) in dynamicAttrColumns" :key="item.id">
          <template #default="{row}">
            {{ formatAttrNameById(row[item.id]) }}
          </template>
        </el-table-column>
        <el-table-column label="属性值" prop="key">
        </el-table-column>
        <el-table-column label="属性编码">
          <template #default="{row}">
            <el-input v-model="row.skuCode" />
          </template>
        </el-table-column>
        <el-table-column label="条形码/sku">
          <template #default="{row}">
            <el-input v-model="row.barcode" />
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    
  </el-container>
</template>

<script>
  import formValidator from '@/utils/formValidator' 
  import { getEntityList} from '@/service/resource/general'
  import { EnumEntityType } from '@/utils/enum_utils'
  
  export default {
    name: 'attr_detail',
    data () {
      return {
        attrList:[],
        dynamicAttrColumns:[], // 下方网格中动态列关联的已选属性
        selectRows:[],
        goodsSkuList:[] // 下方网格数据列表
      }
    },
    props:{
      formModel:{
        type:Object,
        required:true
      }
    },
    computed:{
      goodsSelectedAttrList:function(){ // 已选择的属性列表
        if(!this.formModel.attrJson){
          return []
        }
        let arList = this.formModel.attrJson.map(item=>{ // 当前详情已选择的属性类型列表id
          return Object.keys(item)[0]
        })

        return this.attrList.filter(item=> arList.includes(item.id))
      },
      
    },
    watch:{
      formModel:function(){
        this.refreshGoodsSkuList()
      }
    },
    async created(){
      // 加载根属性，用于popover
      let res = await getEntityList(EnumEntityType.GOODS_ATTR,{parentId:''})
      this.attrList = res.data || []

      this._attrInfo = {} // 缓存全量的属性值，用于显示网格值，及计算编码时使用
      this.attrList.forEach(item=>{
        item.childAttrList?.forEach(el=>{
          this._attrInfo[el.id]= {attrName:el.attrName,parentId:item.id}
        })
      })
      this.refreshGoodsSkuList()
    },
    methods: {
      formValidator,
      onAttrSelectionChange(rows){
        this.selectionRows = rows
      },
      onTopTableRowRemoveClick(attrTypeId){// 顶部网格
        let index = this.formModel.attrJson.findIndex(item=> Object.keys(item)[0] == attrTypeId)
        if(index!=-1){
          this.formModel.attrJson.splice(index,1)
        }
      },
      onRowDeleteClick(rowIndex) {
        this.formModel.goodsSkuList.splice(rowIndex, 1)
      },
      // 构建动态属性列，及将attrIds，转为网格支持的格式，并将id转为attrName显示
      refreshGoodsSkuList(){
        let dynamicColsInfo = {} // 
        this.formModel.goodsSkuList?.map(item=>{
          let attrIds = item.attrIds
          attrIds.split(',').forEach(aId=>{
            // 根据属性值，找出其父属性，并将父属性作为行属性进行复制
            let parentId = this._attrInfo[aId].parentId
            item[parentId] = aId
            if(!dynamicColsInfo[parentId]){ // 已存在则跳过
              let match = this.attrList.find(item=>item.id==parentId)
              // 动态属性列 {id:'11',attrName:'xxxx'}
              dynamicColsInfo[parentId]= {id:parentId,attrName: match.attrName}
            }
          })
          return item
        })
        // 解析动态属性类型列
        this.dynamicAttrColumns =  Object.values(dynamicColsInfo)
      },
      onAttrCancelClick(){
        this.$refs.refAttr.hide()
      },
      onAttrConfirmClick(){
        this.$refs.refAttr.hide()
        // 将详细中尚未添加的属性填充到网格， 默认选中所有的属性值
        this.selectionRows.forEach(row=>{
          let parentId = row.id
          let matchIndex = this.formModel.attrJson.findIndex(item=>{
             return Object.keys(item)[0] == parentId
          })
          if(matchIndex ==-1){
            let arChildId = row.childAttrList.map(el=>el.id)
            this.formModel.attrJson.push({[row.id]: arChildId.join(',') }) // {父属性id:"属性值id列表，逗号隔开"}
          }
        })
      },
      checkAttrIsChoose(parentId,attrId){ // 判断是否已选
        let matchItem = this.formModel.attrJson.find(item=>{
            return Object.keys(item)[0] == parentId
        })
        if(!matchItem){
          return false
        }
        
        return matchItem[parentId].split(',').includes(attrId)
      },
      onAttrChangeHandler(parentId,attrId,checked){
        let matchItem = this.formModel.attrJson.find(item=>{
          return Object.keys(item)[0] == parentId
        })

        if(matchItem){
          let val = matchItem[parentId]
          let ar = []

          val && (ar = val.split(','))

          let matchIndex = ar.indexOf(attrId)

          !checked && matchIndex!=-1 && ar.splice(matchIndex,1) // 移除
          checked && matchIndex == -1 && ar.push(attrId) // 添加
          matchItem[parentId] = ar.join(',')
        }
      },
      formatAttrNameById(attrId){
        return this._attrInfo[attrId]?.attrName || attrId
      },
      /*
        卡迪尔计算属性列表
        let spec = arSpec
        let arSpec = [
          ["红","绿","蓝"],
          ["大","中","小"],
          ["m","xl","s"]
        ]
        算法参考 https://www.jianshu.com/p/ffd9528c78f0
       */
      onCreateAttrGroupClick(){
        // 商品未被使用时，允许修改sku，重新生成时，根据编码规则,属性编码按照多个属性拼接），条形码根据当前商品编码+属性组合编码
        let attrJson = this.formModel.attrJson
        // 提取属性， 属性值， 形成spec 二维数组，做卡迪尔准备
        let arSpec = []
        let arProp = [] // 属性
        attrJson.forEach(item=>{
          let key = Object.keys(item)[0]
          let specValue = item[key]
          if(specValue){ // 已勾选属性值
            arProp.push(key)
            arSpec.push(specValue.split(','))
          }
        })
        // 卡迪尔交叉计算结果
        let skuList = arSpec.reduce((x,y) =>{
            let arr = [];
            x.forEach(x => y.forEach(y => arr.push(x.concat([y]))))
            return arr;
        },[[]])

        // 形成表格数据源
        let list = skuList.map(item=>{
          // TODO  根据属性值编码，商品编码，自动构建 条码 skuCode
          let attrIds = item.join(',')
          let existInfo = this.formModel.goodsSkuList.find(item=>item.attrIds == attrIds) // 合并已编辑过的数据
          return Object.assign({},existInfo,{attrIds: attrIds})
        })
        this.formModel.goodsSkuList = list
        this.refreshGoodsSkuList() //重新计算动态列，及属性id转换
      }
    }
  }
</script>
