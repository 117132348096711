<!-- 关联店铺 -->
 
<template>
  <el-container class="nt-height-100">
    <el-header class="no-margin no-padding height-auto">
      <el-row type="flex" justify="space-between">
        <div style="font-weight:bold;color:#666;font-size:18px;">店铺绑定设置</div>
        <div class="display-flex">
          <div>
            <span v-if="authCode" style="color:var(--el-color-primary);font-weight: bold;">{{ authCode }}</span>
            <el-icon style="cursor: pointer;" class="margin-right" v-if="authCode" title="复制授权码" @click.stop="onCopyClick(authCode)"><CopyDocument /></el-icon>
            <!-- <span>我的授权码</span> -->
            <el-button link size="small" type="primary" @click="onCreateCodeClick">获取授权码</el-button>
          </div>
          
          <el-button type="primary" size="small" @click="isAuthCodeVisible=true"> 通过授权码绑定 </el-button>
          <!-- <el-button type="primary"> 去授权-下拉选择</el-button> -->
        </div>
      </el-row>
      <div class="display-flex margin-top" style="font-size:14px;color:#666;">
        <div class="nt-bg-white padding-8" style="flex-basis:50%;">
          <div style="font-weight:bold;color:#666;" class="margin-bottom-4">方式一: 增加绑定其他店铺,如店铺B</div>
          <div>使用店铺B账号登录系统,进入[基础设置]->[店铺绑定]->[生成] 复制绑定码</div>
          <div>使用当前店铺A账号登录系统,进入[基础设置]->[店铺绑定]->[通过授权码绑定] 输入店铺A提供的绑定码进行绑定</div>
        </div>
        <div class="nt-bg-white padding-8" style="flex-basis:50%;">
          <div style="font-weight:bold;color:#666;" class="margin-bottom-4">方式二: 将当前店铺绑定到其他店铺</div>
          <div>使用当前店铺A账号登录系统,进入[基础设置]->[店铺绑定]->[生成] 复制绑定码</div>
          <div>使用店铺B账号登录系统,进入[基础设置]->[店铺绑定]->[通过授权码绑定] 输入店铺A提供的绑定码进行绑定</div>
        </div>
      </div>
      <!-- <el-row type="flex" justify="space-between">
        
        <div>
          方式二：
          示例：店铺A已使用易打单打单，新增店铺B，需将B店铺关联到A下。
          1.确定B店铺已订购授权使用易打单；

          2.在浏览器中登录A店铺，点击【授权关联新店铺】操作。
        </div>
      </el-row> -->
      <el-row type="flex" justify="space-between" class="margin-top-8">
        <el-form inline @keyup.enter.native="onSearchQueryClick">
          <!-- <nt-form-item label="名称" >
            <el-input placeholder="请输入名称查询" v-model="queryModel.storeName"></el-input>
          </nt-form-item>
          <nt-form-item>
            <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
          </nt-form-item> -->
          
        </el-form>
        
        
      </el-row>
    </el-header>
    <el-main class="no-padding margin-top-8">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="店铺名称" prop="shopName"></el-table-column>
        <el-table-column label="商户平台" prop="shopPlatform" width="110"></el-table-column>
        <el-table-column label="授权状态" width="85"></el-table-column>
        <el-table-column label="授权到期时间" width="110" prop="createTime"></el-table-column>
        <el-table-column label="发件人信息" prop="address">
          <template #default="{row}">
            <div v-if="row.address">
              <div>{{ row.address.userName }}-{{ row.address.cellPhone }}</div>
              <div>{{ row.address.addressDetail }}</div>
              <el-button link type="primary" size="small" @click="onChooseAddressClick(row)">修改</el-button>
              <el-button link type="danger" size="small" @click="onRemoveAddressClick(row)">删除</el-button>
            </div>
            <el-button v-else link type="primary" size="small" @click="onChooseAddressClick(row)">绑定</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110">
          <template #default="{row}">
            <el-button size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
  <el-dialog v-model="isAddressVisible" :close-on-click-modal="false" destroyOnClose width="1000px" class="nt-resource-dialog" title="地址选择"
    @cancel="isAddressVisible = false">
    <AddressSelector @choose="onAddressChooseHandler" v-if="isAddressVisible" @cancel="isAddressVisible = false"></AddressSelector>
  </el-dialog>

  <el-dialog v-model="isAuthCodeVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isAuthCodeVisible = false">
    <div class="padding">
      <el-input v-model="shopAuthCode"></el-input>
    </div>
    <template #footer>
      <div class="padding">
        <el-button size="small" type="info" @click="isAuthCodeVisible=false">取消</el-button>
        <el-button size="small" type="primary" @click="onConfirmClick">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import AddressSelector from '@/views/selector/address'
import {getBindingShopList,updateShopBindingAddress,removeShopBindingAddress,createAuthCode,bindAuthCode,} from '@/service/resource/tenant'

export default {
  name: "shopList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    AddressSelector
  },
  data() {
    return {
      authCode:null,
      shopAuthCode:null, // 输入框授权码
      isAuthCodeVisible:false,
      isAddressVisible:null,
      currentRow:null,
    }
  },
  methods: {
    async _loadList(params) {
      return await getBindingShopList( Object.assign({}, params));
    },
    
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onCreateCodeClick(){
      let res = await createAuthCode({})
      if(res.code == 200){
        this.authCode = res.data
        navigator.clipboard.writeText(this.authCode).then(() => {
          this.$message.success('授权码已复制')
        })
      } else {
          this.$message.error(res.msg || '删除失败')
        }
    },
    onCopyClick(text){
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success('授权码已复制')
      })
    },
    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async  onRemoveAddressClick(row){
      let res = await removeShopBindingAddress({id:row.id})
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    
    async onConfirmClick(){
      if(!this.shopAuthCode){
        this.$message.error('请输入授权码')
        return
      }
      let res = await bindAuthCode({code:this.shopAuthCode})
      if (res.code == 200) {
        this.isAuthCodeVisible =false
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    onChooseAddressClick(row){
      this.currentRow = row
      this.isAddressVisible = true
    },
    async onAddressChooseHandler(address){
      let res = await updateShopBindingAddress({id:this.currentRow.id,addressId:address.id})
      if (res.code == 200) {
        this.isAddressVisible =false
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
      
    }
  }
}
</script>
