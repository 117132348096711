
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick" >
        <el-form-item label="采购1时间">
          <NtDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NtDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" class="nt-rpt-table" size="middle" height="100%" :data="datasource" v-loading="isLoading">
        <el-table-column label="操作" width="90">
          <template #default="scope">
            <el-space>
              <a @click="onViewDetailClick(scope.row)">查看详情</a>
            </el-space>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="goodsName" width="140">
        </el-table-column>
        <el-table-column label="规格" prop="standard" width="120">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="110">
        </el-table-column>
        
        <el-table-column label="订单数量" prop="baseQuantity" width="90" align="right">
        </el-table-column>
        <el-table-column label="入库数量-主？" prop="finishQuantity" width="120" align="right">
        </el-table-column>
        <el-table-column label="订单总额" prop="total" width="90" align="right">
        </el-table-column>
        <el-table-column >
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" :with-header="false" class="nt-inner-drawer"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
    <i class="nt-drawer-close" @click="isDetailVisible = false">关闭</i>
  </el-drawer>
</template>
<script>

import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getOrderTotalByGoods,getOrderTotalBySupplier } from '@/service/report/rpt_purchase'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NtDateRange from '@/views/plugin/nt_date_range'

export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    TradeItemPage,
    NtRptLayout,
    NtRptToolbar,
    NtDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      },
      // 查询条件
      queryModel: {
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD')
      },
      
    }
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getOrderTotalByGoods(Object.assign(params, {}))
    },
    onSearchQueryClick() {
      this.loadList();
    },
    onViewDetailClick(record) {
      this.subQuery = {
        goodsSkuId: record.goodsSkuId,
        startTime: this.queryModel.startTime,
        endTime: this.queryModel.endTime,
        billType: 'PURCHASE_IN,PURCHASE_BACK', // 销售出库单，销售退货单
      }
      this.isDetailVisible = true
    }
  }
}
</script>

