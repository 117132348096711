<template>
  <el-container class="nt-height-100 relative">
    <el-tooltip effect="dark" content="添加往来单位分类" placement="top-start">
      <el-button icon="Plus" @click="onAddClick" class="nt-float-button-add" circle></el-button>
    </el-tooltip>

    <el-main class="padding">
      <el-tree ref="refTree" nodeKey="id" class="nt-height-100 nt-list-node " :data="datasource" default-expand-all
        :expand-on-click-node="false" highlight-current :props="props" :indent="16" @current-change="onNodeChange">
        <template #default="{ node, data }">
          <el-row justify="space-between" class="nt-width-100" align="middle">
            <span>{{ node.label }}</span>
            <el-space :size="5" class="nt-operation" v-if="data.id > 0">
              <el-tooltip content="添加下级分类" placement="top-start">
                <el-icon size="large" @click.stop="onAddChildClick(data)">
                  <FolderAdd />
                </el-icon>
              </el-tooltip>
              
              <el-icon size="large" @click.stop="onRowEditClick(data)">
                <Edit />
              </el-icon>
              <el-popconfirm title="确定删除?" @confirm.stop="onRowDeleteClick(data)">
                <template #reference>
                  <el-icon size="large" v-if="!data.children || data.children.length == 0">
                    <Delete />
                  </el-icon>
                </template>
              </el-popconfirm>
            </el-space>
          </el-row>
        </template>
      </el-tree>
    </el-main>
  </el-container>


  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="550px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" @cancel="isDetailVisible = false" :parentCategory="parentCategory"
      :entityId="currentRecord.id" v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>
<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import detailPage from './detail/'
import { getBizCompanyCategoryList, deleteBizCompanyCategoryById } from '@/service/resource/biz_company'
export default {
  name: "bizCompanyCategoryList",
  mixins: [DataListMixin],
  components: {
    detailPage
  },
  data() {
    return {
      currentRow: null,
      parentCategory: null,
      props: {
        label: 'categoryName'
      }
    }
  },
  methods: {
    async _loadList(params) {
      let res = await getBizCompanyCategoryList();
      let rootList = []
      if (res.data) {
        // 构建层次数
        rootList = res.data.filter(item => !item.parentId)
        let getChildren = function (id) {
          let list = res.data.filter(item => item.parentId == id)
          list.forEach(item => {
            item.children = getChildren(item.id)
          })
          return list
        }
        rootList.forEach(item => item.children = getChildren(item.id))
      }
      rootList.splice(0, 0, { id: null, categoryName: '全部' }, { id: '-2', categoryName: '未分类' })

      res.data = rootList //[{categoryName:'全部',children:rootList}]
      return res
    },
    _loadFinished() { //列表加载结束后默认显示显示第一个
      if (this.datasource.length > 0) {
        let root = this.datasource[0]
        setTimeout(_ => {
          this.onNodeChange(root)
        }, 0)
      }

    },
    onSearchQueryClick() {
      this.loadList();
    },
    onAddClick() {
      this.currentRecord = {}
      this.parentCategory = null
      this.isDetailVisible = true
    },
    onAddChildClick(data){
      this.currentRecord = {}
      this.parentCategory = { id: data.id, categoryName: data.categoryName }
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.parentCategory = null
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await deleteBizCompanyCategoryById({ id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onNodeChange(node) {
      this.$refs.refTree?.setCurrentNode(node)
      this.$emit('change', node.id)
    }
  }
}
</script>
