<template>
  <el-container class="layout" :class="[device]" style="height:100%;border-radius:0px;">
    <el-aside width="65px" style="background-color: var(--el-color-primary-light-6);">
      <function-menu @menuSelect="myMenuSelect"></function-menu>
    </el-aside>
    <el-main class="no-padding" >
      <el-container class="nt-height-100">
        <!-- <el-header class="no-padding no-margin" style="height:38px;">
          <el-menu style="height:100%" mode="horizontal" :ellipsis="false">
            <el-menu-item index="0">
              <div style="font-weight:bold;font-size:16px;opacity: .9;">视频号-SAAS电商贸公司</div>
            </el-menu-item>
            <div class="flex-auto" />
            <el-menu-item index="1">消息</el-menu-item>
            <el-menu-item index="2">我</el-menu-item>
            
          </el-menu>
        </el-header> -->
        <el-main class="no-padding">
          <slot></slot>
        </el-main>
      </el-container>
      
    </el-main>
 </el-container>
</template>

<script>
  // import SideMenu from '@/components/menu/SideMenu'
  import FunctionMenu from '@/components/menu/FunctionMenu'
  // import GlobalHeader from '@/components/page/GlobalHeader'
  // import GlobalFooter from '@/components/page/GlobalFooter'
  // import { triggerWindowResizeEvent } from '@/utils/util'
  // import { mapState, mapActions } from 'vuex'
  // import { mixin, mixinDevice } from '@/utils/mixin.js'
  // import Vue from 'vue'
  // import {LoginUserStore} from '@/piana/login_user_service'

  export default {
    name: 'GlobalLayout',
    components: {
      // SideMenu,
      FunctionMenu,
      // GlobalHeader,
      // GlobalFooter,
      // update-start---- author:os_chengtgen -- date:20190830 --  for:issues/463 -编译主题颜色已生效，但还一直转圈，显示主题 正在编译 ------
      // // SettingDrawer
      // 注释这个因为在个人设置模块已经加载了SettingDrawer页面
      // update-end ---- author:os_chengtgen -- date:20190830 --  for:issues/463 -编译主题颜色已生效，但还一直转圈，显示主题 正在编译 ------

    },
    // mixins: [mixin, mixinDevice],
    data() {
      return {
        // collapsed: false,
        activeMenu:{},
        // menus: []
      }
    },
    computed: {
      // mainRouters:function(){
      //   return $store.state.permission.addRouters // 全局已加载路由
      // },
      // permissionMenuList:function(){
      //   return $store.state.user.permissionList  // 用户权限
      // },
      // ...mapState({
      //   // 主路由
      //   mainRouters: state => state.permission.addRouters,
      //   // 后台菜单
      //   permissionMenuList: state => state.user.permissionList
      // })
    },
    watch: {
      // sidebarOpened(val) {
      //   this.collapsed = !val
      // }
    },
    async created() {
      // TODO 加载主菜单，加载用户登录基本信息
      // await LoginUserStore().loadTenentShop()
      // console.log('loading userInfo here in globalLayout',LoginUserStore().shopName)

      // console.log('global layout create',$store.state.app)
      //--update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
      //this.menus = this.mainRouters.find((item) => item.path === '/').children;
      // 加载用户权限功能列表

      // this.menus = $store.state.user.permissionList //  this.permissionMenuList
      // console.log('sider menu data', this.menus)
      // console.log('new menu',$store.state.user.allMenu )
      // TODO  边栏菜单 过滤根节点  parentId=null

      // 根据后台配置菜单，重新排序加载路由信息
      //console.log('----加载菜单逻辑----')
      //console.log(this.mainRouters)
      //console.log(this.permissionMenuList)
      //console.log('----navTheme------'+this.navTheme)
      //--update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    },
    methods: {
      // ...mapActions(['setSidebar']),
      // toggle() {
      //   this.collapsed = !this.collapsed
      //   this.setSidebar(!this.collapsed)
      //   triggerWindowResizeEvent()
      // },
      // menuSelect() {
      //   if (!this.isDesktop()) {
      //     this.collapsed = false
      //   }
      // },
      myMenuSelect(value){
        //此处触发动态路由被点击事件
        // console.log('myMenuSelect',value)
        value.path = value.url
        this.activeMenu = value
        // this.findMenuBykey(this.menus,value.key) // 根据key 设置激活的菜单
        this.$emit("dynamicRouterShow",value.url, this.activeMenu.id, this.activeMenu.text, this.activeMenu.component)
        let storeKey = 'route:title:' + this.activeMenu.url
        this.$ls.set(storeKey, this.activeMenu.text)
      },
      findMenuBykey(menus,key){
        for(let i of menus){
          if(i.url==key){
            this.activeMenu = {...i}
          }else if(i.children && i.children.length>0){
            this.findMenuBykey(i.children,key)
          }
        }
      },
      searchGlobalLayout(key, id, title, component){
        this.$emit("dynamicRouterShow", key, id, title, component)
      }
      //update-end-author:taoyan date:20190430 for:动态路由title显示配置的菜单title而不是其对应路由的title
    }
  }

</script>


<style lang="less">

  body {
    // 打开滚动条固定显示
    overflow-y: scroll;

    &.colorWeak {
      filter: invert(80%);
    }
  }

  .layout {
    min-height: 100vh !important;
    overflow-x: hidden;

    &.mobile {

      .ant-layout-content {

        .content {
          margin: 24px 0 0;
        }
      }

      /**
       * ant-table-wrapper
       * 覆盖的表格手机模式样式，如果想修改在手机上表格最低宽度，可以在这里改动
       */
      .ant-table-wrapper {
        .ant-table-content {
          overflow-y: auto;
        }
        .ant-table-body {
          min-width: 800px;
        }
      }
      .sidemenu {
        .ant-header-fixedHeader {

          &.ant-header-side-opened, &.ant-header-side-closed {
            width: 100%
          }
        }
      }

      .topmenu {
        /* 必须为 topmenu  才能启用流式布局 */
        &.content-width-Fluid {
          .header-index-wide {
            margin-left: 0;
          }
        }
      }
      .header, .top-nav-header-index {
        .user-wrapper .action {
          padding: 0 12px;
        }
      }
    }

    &.ant-layout-has-sider {
      flex-direction: row;
    }

    .trigger {
      font-size: 22px;
      line-height: 42px;
      padding: 0 18px;
      cursor: pointer;
      transition: color 300ms, background 300ms;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .topmenu {
      .ant-header-fixedHeader {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: 100%;
        transition: width .2s;

        &.ant-header-side-opened {
          width: 100%;
        }

        &.ant-header-side-closed {
          width: 100%;
        }
      }
      /* 必须为 topmenu  才能启用流式布局 */
      &.content-width-Fluid {
        .header-index-wide {
          max-width: unset;
          margin-left: 24px;
        }

        .page-header-index-wide {
          max-width: unset;
        }
      }

    }

    .sidemenu {
      .ant-header-fixedHeader {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: 100%;
        transition: width .2s;

        &.ant-header-side-opened {
          width: calc(100% - 150px)
        }

        &.ant-header-side-closed {
          width: calc(100% - 80px)
        }
      }
    }

    .header {
      height: 64px;
      padding: 0 12px 0 0;
      background: #fff;
      box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
      position: relative;
    }

    .header, .top-nav-header-index {

      .user-wrapper {
        float: right;
        height: 100%;

        .action {
          cursor: pointer;
          padding: 0 14px;
          display: inline-block;
          transition: all .3s;

          height: 70%;
          line-height: 36px;

          &.action-full {
            height: 100%;
          }

          &:hover {
            background: rgba(255, 255, 255, 0.3);
          }

          .avatar {
            margin: 20px 10px 20px 0;
            color: #1890ff;
            background: hsla(0, 0%, 100%, .85);
            vertical-align: middle;
          }

          .icon {
            font-size: 16px;
            padding: 4px;
          }

          .anticon {
            color: white;
          }
        }
      }

      &.dark {
        .user-wrapper {

          .action {
            color: black;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }

            .anticon {
              color: black;
            }
          }
        }
      }
    }

    &.mobile {
      .top-nav-header-index {

        .header-index-wide {

          .header-index-left {

            .trigger {
              color: rgba(255, 255, 255, 0.85);
              padding: 0 12px;
            }

            .logo.top-nav-header {
              text-align: center;
              width: 56px;
              line-height: 58px;
            }
          }
        }

        .user-wrapper .action .avatar {
          margin: 20px 0;
        }

        &.light {

          .header-index-wide {

            .header-index-left {
              .trigger {
                color: rgba(0, 0, 0, 0.65);
              }
            }
          }
          //
        }
      }
    }

    &.tablet {
      // overflow: hidden; text-overflow:ellipsis; white-space: nowrap;
      .top-nav-header-index {

        .header-index-wide {

          .header-index-left {
            .logo > a {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

    }

    .top-nav-header-index {
      box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
      position: relative;
      transition: background .3s, width .2s;

      .header-index-wide {
        width: 100%;
        margin: auto;
        padding: 0 20px 0 0;
        display: flex;
        height: 49px;

        .ant-menu.ant-menu-horizontal {
          border: none;
          height: 64px;
          line-height: 64px;
        }

        .header-index-left {
          flex: 1 1;
          display: flex;

          .logo.top-nav-header {
            width: 165px;
            height: 64px;
            position: relative;
            line-height: 64px;
            transition: all .3s;
            overflow: hidden;

            img {
              display: inline-block;
              vertical-align: middle;
              height: 32px;
            }

            h1 {
              color: #fff;
              display: inline-block;
              vertical-align: top;
              font-size: 16px;
              margin: 0 0 0 12px;
              font-weight: 400;
            }
          }
        }

        .header-index-right {
          float: right;
          height: 49px;
          overflow: hidden;
          .action:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }

      &.light {
        background-color: #fff;

        .header-index-wide {
          .header-index-left {
            .logo {
              h1 {
                color: #002140;
              }
            }
          }
        }
      }

      &.dark {

        .user-wrapper {

          .action {
            color: white;

            &:hover {
              background: rgba(255, 255, 255, 0.3);
            }
          }
        }
        .header-index-wide .header-index-left .trigger:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }

    }

    // 内容区
    .layout-content {
      margin: 24px 24px 0px;
      height: 64px;
      padding: 0 12px 0 0;
    }

  }

  .topmenu {
    .page-header-index-wide {
      margin: 0 auto;
      width: 100%;
    }
  }

  // drawer-sider 自定义
  .ant-drawer.drawer-sider {
    .sider {
      box-shadow: none;
    }

    &.dark {
      .ant-drawer-content {
        background-color: rgb(0, 21, 41);
      }
    }
    &.light {
      box-shadow: none;
      .ant-drawer-content {
        background-color: #fff;
      }
    }

    .ant-drawer-body {
      padding: 0
    }
  }

  // 菜单样式
  .sider {
    box-shadow: 2px 116px 6px 0 rgba(0, 21, 41, .35);
    position: relative;
    z-index: 10;

    &.ant-fixed-sidemenu {
      position: fixed;
      height: 100%;
    }

    .logo {
      height: 64px;
      position: relative;
      line-height: 64px;
      padding-left: 10px;
      -webkit-transition: all .3s;
      transition: all .3s;
      background: #ffffff;
      overflow: hidden;

      img, h1 {
        display: inline-block;
        vertical-align: middle;
      }

      img {
        height: 32px;
      }

      h1 {
        color: #fff;
        font-size: 18px;
        margin: 0 0 10px 8px;
        font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 600;
      }
    }

    &.light {
      background-color: #fff;
      box-shadow: 2px 116px 8px 0 rgba(29, 35, 41, 0.05);

      .logo {
        background: #fff;
        box-shadow: 1px 1px 0 0 #e8e8e8;

        h1 {
          color: unset;
        }
      }

      .ant-menu-light {
        border-right-color: transparent;
      }
    }

  }

  // 外置的样式控制
  .user-dropdown-menu-wrapper.ant-dropdown-menu {
    padding: 4px 0;

    .ant-dropdown-menu-item {
      width: 160px;
    }

    .ant-dropdown-menu-item > .anticon:first-child,
    .ant-dropdown-menu-item > a > .anticon:first-child,
    .ant-dropdown-menu-submenu-title > .anticon:first-child
    .ant-dropdown-menu-submenu-title > a > .anticon:first-child {
      min-width: 12px;
      margin-right: 8px;
    }

  }

  // 数据列表 样式
  .table-alert {
    margin-bottom: 16px;
  }

  .table-page-search-wrapper {

    .ant-form-inline {

      .ant-form-item {
        display: flex;
        margin-bottom: 12px;
        margin-right: 0;

        .ant-form-item-control-wrapper {
          flex: 1 1;
          display: inline-block;
          vertical-align: middle;
        }

        > .ant-form-item-label {
          line-height: 32px;
          padding-right: 8px;
          width: auto;
        }
        .ant-form-item-control {
          height: 32px;
          line-height: 32px;
        }
      }
    }

    .table-page-search-submitButtons {
      display: block;
      margin-bottom: 12px;
      white-space: nowrap;
    }

  }

  .content {

    .table-operator {
      margin-bottom: 18px;

      button {
        margin-right: 8px;
      }
    }
  }
</style>