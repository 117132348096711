
export const getReflectValue = (source/*数据源 */,expr/*表达式*/) => {
    let arField = expr.split('.')
    let target = source
    for(var key of arField){
        // 数组尚未考虑 TODO
        if(Object.keys(target).includes(key)){
            target = target[key]
        } else {
            target = null
            break
        }
    }
    return target
    
}

/*
    解析变量的key
*/
export const parseVarExpr = val =>{
    let m = val.match(/\=\[(.+?)\]/g)
    if (m){
     return m[0].match(/\=\[(.+?)\]/)[1]
    }
    return null
}

/*
    判断是否为变量类型
*/
export const checkValidVar = val =>{
    let m = val.match(/\=\[(.+?)\]/g)
    return m? true:false
}
