
<template>
  <el-container class="nt-height-100 relative">
    <el-main class="padding nt-box-panel">
      <el-container class="no-padding nt-height-100">
        <el-header class="no-margin height-auto no-padding align-right">
          <!-- <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
            <nt-form-item>
              <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
            </nt-form-item>
          </el-form> -->
          <div>
            <el-button  type="success" @click="onChooseAddressClick">从平台选择地址</el-button>
            <el-button @click="onAddClick" type="primary" >新增</el-button>
          </div>
        </el-header>
        <el-main class="no-padding margin-top">
          <el-table height="100%" border ref="table" class="nt-resource-table"  :data="datasource"
            v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left">
            </el-table-column>
            <el-table-column label="发货人" width="140" prop="userName"></el-table-column>
            <el-table-column label="手机" width="140" prop="cellPhone" ></el-table-column>
            <el-table-column label="联系电话" width="160" prop="phone"></el-table-column>
            <el-table-column label="区域" width="220">
              <template #default="{row}">
                {{ row.provinceName }}-{{ row.cityName }}-{{ row.districtName }}
              </template>
            </el-table-column>
            <el-table-column label="地址"  prop="addressDetail"></el-table-column>
            
            <el-table-column label="备注" width="220" prop="remark"></el-table-column>
            <el-table-column label="是否默认" width="85" prop="isDefault">
              <template #default="scope">
                <el-tag v-if="scope.row.isDefault" type="success">是</el-tag>
                <el-tag v-if="!scope.row.isDefault" type="warning">否</el-tag>
              </template>
            </el-table-column>
            <nt-operation-column label="操作" fixed="right" width="85" align="center" 
              :confirm="{DELETE:'是否确认删除?'}"
              :disableExpr="{DELETE:null}" :hidden="['VERIFY','UNVERIFY','DISABLE','ENABLE']" 
              @command="onColumnOperationAction">
              <template #extra>
              </template>
            </nt-operation-column>
            
          </el-table>
        </el-main>
        <el-footer class="no-padding margin-top height-auto">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize" size="small"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
    
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" address-type="SEND_ADDRESS" @cancel="isDetailVisible = false" :entity="currentRecord"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
  <el-dialog v-model="isAddressVisible" :close-on-click-modal="false" destroyOnClose width="800px" class="nt-resource-dialog" title="地址选择"
    @cancel="isAddressVisible = false">
    <PlatformAddressSelector @choose="onAddressChooseHandler" v-if="isAddressVisible" @cancel="isAddressVisible = false"></PlatformAddressSelector>
  </el-dialog>
</template>
<script>

import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import ntPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
import PlatformAddressSelector from '@/views/selector/platform_address'
export default {
  name: "shopAddressList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem,
    ntPlatformShopTree,
    PlatformAddressSelector
  },
  data() {
    return {
      currentRecord:null,
      isAddressVisible:false
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.ADDRESS, Object.assign({},{addressType:'SEND_ADDRESS'},params));
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.ADDRESS, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },

    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },

    onChooseAddressClick(){
      this.isAddressVisible = true
    },
    onAddressChooseHandler(address){
      this.currentRecord = {
        addressType:'SEND_ADDRESS',
        userName: address.userName,
        phone: address.houseNumber,
        cellPhone: address.telNumber,
        provinceName: address.provinceName,
        cityName : address.cityName,
        districtName : address.countyName,
        addressDetail: address.detailInfo

      }
      this.isAddressVisible = false
      this.isDetailVisible = true
    },

    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onRowSetDefaultClick(record) {
      let res = await this.setEntityDefault(this.EnumEntityType.ADDRESS, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    
    async onPlatformClick(shop){
      this.selectedShopId = shop.id
      this.loadList()
    },
  }
}
</script>