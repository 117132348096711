
<template>
  <el-container class="padding nt-height-100">
    <el-aside class="margin-right-12 nt-box-panel padding-8 nt-bg-white" width="250px">
      <nt-platform-shop-tree @change="onShopNodeClick"></nt-platform-shop-tree>
    </el-aside>
    <el-main class="padding nt-box-panel" style="overflow: hidden;">
      <el-container class="height-auto nt-height-100">
        <el-header class="no-margin no-padding height-auto">
          <el-row type="flex" justify="space-between">
            <el-form inline @keyup.enter.native="onSearchQueryClick">
              <nt-form-item label="备注" >
                <el-input placeholder="请输入备注查询" v-model="queryModel.remark"></el-input>
              </nt-form-item>
              <nt-form-item>
                <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
              </nt-form-item>
              <nt-form-item>
                <el-checkbox v-model="queryModel.isSync">显示已同步数据</el-checkbox>
              </nt-form-item>
            </el-form>
            <div>
              <el-tooltip content="仓库  结算方式 资金账户 商品映射">
                <el-button size="small" type="primary"> 批量设置 </el-button>
              </el-tooltip>
              <!-- 逻辑考虑： 优先按上次同步？  按映射规则配置 -->
              <el-button size="small" type="primary"> 自动审核策略 </el-button>
              <el-button size="small"  type="primary" @click="isPaymentTypeVisible=true"> 数据同步映射配置 </el-button>
              <el-button size="small"  type="primary" @click="onSyncErpClick"> 审核入ERP </el-button>
              <el-button size="small"  type="primary"> 订单按门店同步 </el-button>
            </div>
          
          </el-row>
          
        </el-header>
        <el-main class="no-padding">
          <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
            @selection-change="onSelectionChange"
            v-loading="isLoading">

            <el-table-column label="#" width="40" type="index" align="center" fixed="left">
            </el-table-column>
            <el-table-column width="40" type="selection" align="center" fixed="left">
            </el-table-column>
            <el-table-column label="结算方式" width="110" class="no-padding">
              <template #header>
                <span>结算方式</span>
                <el-tooltip placement="top" content="结算方式为成功将线上交易单据同步至erp系统时的结算方式">
                  <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
              </template>
              <template #default="{row}">
                <nt-dropdown-grid key="ndg_payment_method"  configKey="paymentMethod"  v-model="row.erpPaymentMethodId"  v-model:text="row.erpPaymentMethodName"
                :disabled="viewOnly" placeholder="请选择转入账户" :searchable="true"
                ></nt-dropdown-grid>
              </template>
            </el-table-column>
            <el-table-column label="结算账户" width="110" class="no-padding">
              <template #header>
                <span>结算账户</span>
                <el-tooltip placement="top" content="线上交易单据金额将进入当前选择的资金账户中">
                  <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
              </template>
              <template #default="{row}">
                <nt-dropdown-grid key="ndg_account"  configKey="account"  v-model="row.accountId"  v-model:text="row.accountName"
                :disabled="viewOnly" placeholder="请选择结算账户" :searchable="true"
                ></nt-dropdown-grid>
              </template>
            </el-table-column>
            <goods-column labe="商品信息" prop="orderItemList" width="350" class="no-padding"></goods-column>
            <el-table-column label="订单编号" width="200" prop="orderNo">
              <template #default="{row}">
                <el-tag class="absolute left-top" size="small" type="info">微信视频号</el-tag>
                <div>{{ row.orderNo }}</div>
              </template>
            </el-table-column>
            <el-table-column label="所属门店" width="120" prop="shopName"></el-table-column>
            <!-- <el-table-column label="订单来源" width="120" prop="platform"></el-table-column> -->
            
            <!-- <el-table-column label="付款状态" width="120" prop="paymentStatus"></el-table-column> -->
            
            <el-table-column label="买家备注" width="120" prop="buyerRemark"></el-table-column>
            
            <el-table-column label="运费" width="80" prop="freight" align="right">
              <template #default="{row}">{{ formatFenTotal(row.freight) }}</template>
            </el-table-column>
            <el-table-column label="商品总额" width="100" prop="goodsTotal" align="right">
              <template #default="{row}">{{ formatFenTotal(row.goodsTotal) }}</template>
            </el-table-column>
            
            <!-- <el-table-column label="商品信息" width="220" prop="platform"></el-table-column> -->
            <el-table-column label="折扣金额" width="100" prop="discountTotal" align="right">
              <template #default="{row}">{{ formatFenTotal(row.discountTotal) }}</template>
            </el-table-column>
            <el-table-column label="实付金额" width="120" prop="payTotal" align="right">
              <template #default="{row}">{{ formatFenTotal(row.payTotal) }}</template>
            </el-table-column>
            <el-table-column label="订单状态" width="90" prop="orderStatus"></el-table-column>
            <el-table-column label="付款方式" width="100" prop="paymentMethod"></el-table-column>
            <el-table-column label="买家" width="220" prop="nickName">
              <template #default="{row}">
                <div>{{ row.buyerName }}({{ row.buyerTel }})</div>
                <div>{{ row.buyerAddress }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="支付状态" width="160" prop="paymentStatus"></el-table-column> -->
            <!-- <el-table-column label="发货状态" width="160" prop="createTime"></el-table-column>
            <el-table-column label="物流状态" width="160" prop="createTime"></el-table-column> -->
            <el-table-column label="ERP单号" width="160" prop="orderExtra.erpOrderNo"></el-table-column>
            <el-table-column label="同步结果" width="60" prop="orderExtra.erpSyncError" fixed="right">
              <template #default="{row}">
                <el-icon v-if="row.orderExtra.erpOrderId" color="var(--el-color-success)" size="18"><SuccessFilled  /></el-icon>
                <el-tooltip :content="row.orderExtra.erpSyncError" v-else-if="row.orderExtra.erpSyncError">
                  <el-icon v-if="row.orderExtra.erpSyncError" color="var(--el-color-danger)" size="18"><WarningFilled  /></el-icon>
                </el-tooltip>
              </template>
            </el-table-column>
            
            
          </el-table>
        </el-main>
        <el-footer class="no-padding margin-top height-auto">
          
          <el-row type="flex" justify="end">
            <div style="color:red;">TODO：显示当前符合条件的同步单据同步信息</div>
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background small
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
  
  <el-dialog v-model="isPaymentTypeVisible" destroyOnClose width="850px" class="nt-resource-dialog" title="数据同步映射配置"
    @cancel="isPaymentTypeVisible = false">
    <shop-kv-mapping/>
  </el-dialog>
  <nt-result-dialog ref="refResult"></nt-result-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import GoodsColumn from './goods_column'
import {syncErpOrder} from '@/service/online_sale'
import ShopKvMapping from '@/views/platform/mapping'
import NtPlatformShopTree from '@/views/plugin/nt_platform_shop_tree'
export default {
  name: "platform_order_list",
  mixins: [DataListMixin],
  components: {
    NtFormItem,GoodsColumn,ShopKvMapping,NtPlatformShopTree
  },
  data() {
    return {
      isPaymentTypeVisible:false,
      currentShopId:null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.currentShopId) return
      return await this.getPageEntityList(this.EnumEntityType.PLATFORM_ORDER,Object.assign({shopId:this.currentShopId},params));
    },
    
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectionChange(rows) {
      this.selectedRows = rows
    },
    onShopNodeClick(shop){
      this.currentShopId = shop.id
      this.loadList()
    },

    async onSyncErpClick(){
      if(this.selectedRows.length==0){
        this.$message.warning('请选择需要同步的订单')
        return
      }
      let order = this.selectedRows

      
      // 准备数据
      let syncList = this.selectedRows.map(item=>{
        let children = item.orderItemList.map(el=>{
          return {
            orderItemId:el.id,
            storeId: el.storeId,
            erpGoodsId : el.erpGoodsId,
            erpGoodsUnitId: el.erpGoodsUnitId,
            eprGoodsSkuId: el.erpGoodsSkuId
          }
        })
        return {
          shopId: item.shopId,
          paymentMethodId: item.erpPaymentMethodId,// erp付款方式
          accountId : item.accountId,// erp资金结算账户
          platformOrderId: item.id,// 平台订单id
          syncPlatformOrderItemVoList:children
        }
      })
      let res = await syncErpOrder(syncList)
      if(res.code === 200){
        // 弹窗显示结果
        // console.log('batch process result',res.data)
        this.$refs.refResult.showDialog('同步结果',res.data)
        this.loadList()
      } else{
        this.$message.error(res.msg || res.data)
      }
    },
    
  }
}
</script>