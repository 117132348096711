<template>
	<el-scrollbar class="nt-height-100">
    <el-row :gutter="0" class="nt-designer-tool">
      <draggable style="display: flex;width:100%;align-items: start;flex-wrap: wrap;" v-model="list"
        :group="{ name: 'nt-printer-svc', pull: 'clone', put: false }" ghost-class="nt-ghost-item" :sort="false"
        item-key="key" :clone="startClone">
        <template #item="{ element }">
          <el-col :span="24" :key="element.prop">
            <div class="tool-item">
              <i :class="`tool-icon iconfont ${element.icon}`"></i>
              
              <div class="tool-label">{{ element.label }}</div>
            </div>
          </el-col>
        </template>
      </draggable>
    </el-row>
  </el-scrollbar>
</template>

<script >
import draggable from 'vuedraggable'
import { Controls } from '../config/design_config'

export default {
  data () {
    return {
      list:this.controlList
    }
  },
  components:{draggable},
  props:{
    controlList:{ // 设计组件列表
      type: Object,
      required:true
    },
  },
  methods:{
    startClone: function (origin, xx) {
      let item = Controls[origin.key || origin.type] // 根据配置信息，获取拖拽组件默认配置信息
      item = JSON.parse(JSON.stringify(item))
      if (origin.type == 'sys_text') { // 如果是系统变量
        item.label = origin.label
        item.prop = origin.prop
        item.prefix = origin.prefix
      }
      item.isPrinted = true
      item.id = Date.now()
      return item
    }
  }
}

</script>