/*
  动态组件生成器
*/
const _all_editor_list = {
  supplier: import('@/views/resource/company/detail'), // 供应商资料
  customer: import('@/views/resource/company/detail'), // 客户资料
  store: import('@/views/resource/store/detail'), // 仓库资料
  goodsSku: import('@/views/resource/goods/detail'), // 商品sku资料
}

export const   createEditorComponent = async function(resourceName){
  // import 路径不支持变量，仅支持 字符串常量
  // import(/* webpackChunkName: "dashboard" */ '@/views/print_order').then(module=>{
  //   console.log(module.default)
  // })
  if(!_all_editor_list[resourceName]){
    return null
  }
  let vuePage = await _all_editor_list[resourceName]
  return vuePage.default
  // return  resolve => require([vuePath], resolve) // 不通
  // let fking = await asyncFn()
  
}