<template>
  <div>
    <div class="table-page-search-wrapper">
        <!-- 搜索区域 -->
        <a-form layout="inline" @keyup.enter.native="onSearchQueryClick">
          <a-row :gutter="24">
            <a-col :md="6" :sm="24">
              <a-form-item label="单据编号" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
                <a-input placeholder="请输入单据编号查询" v-model="queryParam.number"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商品信息" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
                <a-input placeholder="请输入名称、规格、型号" v-model="queryParam.materialParam"></a-input>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-range-picker
                  style="width: 100%"
                  v-model="queryParam.createTimeRange"
                  format="YYYY-MM-DD"
                  :placeholder="['开始时间', '结束时间']"
                  @change="onDateChange"
                  @ok="onDateOk"
                />
              </a-form-item>
            </a-col>
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-col :md="6" :sm="24">
                <a-button type="primary" @click="onSearchQueryClick">查询</a-button>
                <a-button style="margin-left: 8px" @click="onSearchResetClick">重置</a-button>
              </a-col>
            </span>
          </a-row>
        </a-form>
      </div>
      <!-- table区域-begin -->
      <a-table
        bordered
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="datasource"
        :pagination="false"
        v-loading="isLoading"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: getType}"
        :customRow="rowAction">
        <span slot="numberCustomRender" slot-scope="text, record">
          <a @click="myHandleDetail(record)">{{record.number}}</a>
        </span>
      </a-table>
      <a-row justify="end" type="flex" class="margin-top">
        <a-col type="auto">
          <a-pagination size="small"
            show-size-changer
            :show-total="total => `共 ${total} 条`"
            :current="this.pageInfo.pageIndex"
            :pageSize="this.pageInfo.pageSize"
            :total="this.pageInfo.total"
            @change="onPageIndexChange"
            @showSizeChange="onPageSizeChange"
          />
        </a-col>
      </a-row>
      <a-row type="flex" justify="end" class="margin-top">
        <a-space>
          <a-button @click="onCancelClick">取消</a-button>
          <a-button type="primary"  @click="handleOk">确定</a-button>
        </a-space>
      </a-row>
      <!-- table区域-end -->
      <!-- 表单区域 -->
      <!-- <bill-detail ref="modalDetail"></bill-detail> -->
  </div>
  
</template>

<script>
  // import BillDetail from '../../bill/dialog/BillDetail'
  // import { JeecgListMixin } from '@/mixins/JeecgListMixin'
  // import {mixinDevice} from '@/utils/mixin'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  // import { findBillDetailByNumber } from '@/api/api'
  // import {getDepostHeadList} from '@/service/depot_head'
  // import {getTradeList} from '@/service/trade_head'
  // import Vue from 'vue'
  export default {
    name: 'DebtBillList',
    mixins:[ DataListMixin],
    components: {
      // BillDetail
    },
    data () {
      return {
        title: "操作",
        visible: false,
        disableMixinCreated: true,
        selectedRowKeys: [],
        selectionRows: [],
        selectBillRows: [],
        // selectBillIds: '',
        queryParam: {
          companyId: "",
          materialParam: "",
          number: "",
          type: "",
          subType: "",
          // roleType: $app.ls.get('roleType'),
          status: ""
        },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
          { title: '往来单位', dataIndex: 'organName',width:120, ellipsis:true},
          {
            title: '单据编号', dataIndex: 'billNo', width: 120,
          },
          { title: '商品信息', dataIndex: 'materialsList',width:200, ellipsis:true,
            customRender:function(t,r,index){
              let nameList = r.tradeItemList.map(item=>item.material.name)
              return nameList.join(",")
            }
          },
          { title: '单据日期', dataIndex: 'billTime',width:130},
          { title: '制单人', dataIndex: 'userName',width:70, ellipsis:true},
          { title: '单据总额', dataIndex: 'billTotal',width:70 },
          { title: '预付金额', dataIndex: 'orderDeposit',width:70 },
          { title: '已结算金额', dataIndex: 'settleTotal',width:70 },
          { title: '待结算金额', width:70,
            customRender:function(t,r,index){
              
              return (r.billTotal-(r.settleTotal||0))/100.00
            } 
          }
        ],
        // url: {
        //   list: "/depotHead/debtList"
        // }
      }
    },
    computed: {
      getType: function () {
        return 'checkbox';
      }
    },
    props:{
      billType:{
        type:String,
        required:true
      },
      companyId:{
        type:String,
        required:true
      }
    },
    created() {
    },
    methods: {
      async _loadList(params){
        return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({companyId:this.companyId,billType:this.billType},params,this.queryParam))
      },
      _loadFinished(){
      },
      // show(companyId, type, subType, organType, status) {
      //   this.queryParam.companyId = companyId
      //   this.queryParam.type = type
      //   this.queryParam.subType = subType
      //   this.queryParam.status = status
      //   this.columns[0].title = organType
      //   // if(type === '入库') {
      //   //   this.columns[6].title = '已付欠款'
      //   //   this.columns[7].title = '待付欠款'
      //   // } else if(type === '出库') {
      //   //   this.columns[6].title = '已收欠款'
      //   //   this.columns[7].title = '待收欠款'
      //   // }
      //   // this.model = Object.assign({}, {});
      //   this.visible = true;
      //   // this.ipagination.pageSize = 100
      //   // this.ipagination.pageSizeOptions = ['100', '200', '300']
      //   // this.loadData(1)
      //   this.loadList()
      // },
      // myHandleDetail(record) {
      //   findBillDetailByNumber({ number: record.number }).then((res) => {
      //     if (res && res.code === 200) {
      //       let type = res.data.depotHeadType
      //       type = type.replace('其它','')
      //       this.handleDetail(res.data, type)
      //     }
      //   })
      // },
      close () {
        this.$emit('cancel');
        // this.visible = false;
      },
      onCancelClick(){
        this.close()
      },
      handleCancel () {
        this.close()
      },
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectionRows = selectionRows;
      },
      onSearchQueryClick(){},
      handleOk () {
        this.selectBillRows = this.datasource.filter(el=>{
          return this.selectedRowKeys.includes(el.id)
        })
        // this.getSelectBillRows();
        this.$emit('choose', this.selectBillRows);
        this.selectedRowKeys = []
        this.selectBillRows = []
      },
      onDateChange: function (value, dateString) {
        this.queryParam.beginTime=dateString[0];
        this.queryParam.endTime=dateString[1];
      },
      onDateOk(value) {
        console.log(value);
      },
      onSearchResetClick() {
        this.queryParam = {
          type: this.queryParam.type,
          subType: this.queryParam.subType
        }
        this.loadData(1);
      },
      // getSelectBillRows() {
        
      //   let dataSource = this.datasource;
      //   let billIds = "";
      //   this.selectBillRows = []
      //   for (let i = 0, len = dataSource.length; i < len; i++) {
      //     if (this.selectedRowKeys.includes(dataSource[i].id)) {
      //       this.selectBillRows.push(dataSource[i]);
      //       billIds = billIds + "," + dataSource[i].id
      //     }
      //   }
      //   // this.selectBillIds = billIds.substring(1);
      // },
      rowAction(record, index) {
        return {
          on: {
            click: () => {
              let arr = []
              arr.push(record.id)
              this.selectedRowKeys = arr
            },
            dblclick: () => {
              let arr = []
              arr.push(record.id)
              this.selectedRowKeys = arr
              this.handleOk()
            }
          }
        }
      }
    }
  }
</script>
