import { axios as service } from '@/utils/request'

export const getStockPeriodList = (data) => {
  return service({
    url: '/period/periodList',
    method: 'post',
    data
  })
}
export const unsettle = (data) => {
  return service({
    url: '/period/unsettle',
    method: 'post',
    data
  })
}

export const getNewStockPeriod = (data) => {
  return service({
    url: '/period/getNewPeriod',
    method: 'post',
    data
  })
}

// 开始结转
export const startSettle = (data) => {
  return service({
    url: '/period/settle',
    method: 'post',
    data
  })
}

// 成本重算
export const costCalculate = (data) => {
  return service({
    url: '/period/costRecalculate',
    method: 'post',
    data
  })
}
