<!-- 物流模版配置： 按区域，按价格 -->
<template>
  <el-container class="nt-height-100 padding">
    <!-- <el-aside class="margin-right nt-box-panel nt-bg-white" width="250px" >
      <nt-dict-tree @change="onPlatformClick" parent-key="SYS_E_PLATFORM"></nt-dict-tree>
    </el-aside> -->
    <el-header class="height-auto no-margin no-padding">
      <div class="nt-box-panel padding-8" style="color:#8c8c8c;font-size:14px;background-color:var(--el-color-primary-light-9);">
        <div style="font-weight:bold;">使用说明：</div>
        <div class="margin-top-4 margin-bottom-4">开启自动模板匹配，当收货地址 或订单价格与规则设置一致时，将才有规则配置的模板作为面单生成模板</div>
        <div>可以指定按区域或价格优先匹配，如两者都不匹配时，可指定模板模板作为保底配置</div>
      </div>
      <div class=" display-flex margin-top-4" style="align-self: normal;">
        <div>
          <span style="font-size:14px;margin-right:4px;">所属平台</span>
          <el-select v-model="selectedPlatform" style="width:240px;" @change="onPlatformChangeHandler">
            <el-option value="WX_VIDEO" label="微信小店"></el-option>
            <el-option value="TAOBAO" label="淘宝"></el-option>
          </el-select>
        </div>
        <el-switch
          v-model="setting.isOpen"
          size="large"
          inline-prompt
          active-text="开启自动模版匹配"
          inactive-text="关闭自动模版匹配"
          @change="onSaveConfig"
        />
        <el-radio-group v-model="setting.priority" class="margin-left" :disabled="!setting.isOpen" @change="onSaveConfig">
          <el-radio-button label="区域优先" value="AREA" />
          <el-radio-button label="价格优先" value="PRICE" />
        </el-radio-group>
        <div class="display-flex">
          <span style="word-break: keep-all;">默认模版</span>
          <nt-dropdown-grid configKey="waybillTemplate" v-model="setting.templateId" :requestParam="{platform: selectedPlatform}" @change="onTemplateChange"
            v-model:text="setting.templateName" :readonly="!setting.isOpen" placeholder="请选择面单模版" :searchable="true"
            :iconSearch="false"></nt-dropdown-grid>
        </div>
      </div>
    </el-header>
    <el-main class="no-padding margin-top-4">
      <el-tabs v-model="activeTabName"  addable class="nt-width-100 nt-tab-page nt-height-100 nt-box-panel">
        <template #add-icon>
          <el-button size="small" type="primary" @click="onAddClick">添加</el-button>
        </template>
        <el-tab-pane label="按区域" name="area" lazy>
          <area-page ref="refArea" :platform="selectedPlatform"></area-page>
        </el-tab-pane>
        <el-tab-pane label="按价格" name="price" lazy>
          <price-range-page  ref="refPrice"  :platform="selectedPlatform"></price-range-page>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>
<script>
// import {markRaw} from 'vue'
// import { computed } from 'vue'
import AreaPage from './area'
import PriceRangePage from './price_range'
import { getTenantKvByConfigKey, saveTenantKv } from '@/service/resource/general'
export default {
  name: "Delivery_template",
  components: {
    AreaPage,
    PriceRangePage
  },

  data() {
    return {
      activeTabName:"area",
      selectedPlatform:'WX_VIDEO',
      setting:{isOpen:false,priority:'AREA'}
    }
  },
  created(){
    this.onPlatformChangeHandler(this.selectedPlatform)
  },
  methods: {
    onCategoryChange(categoryId) {
      this.categoryId = categoryId
    },
    onTemplateChange(tpl){
      // this.setting.platform = tpl.platform  // 保存模版所属平台（冗余便于记录到orderPackage里面，后续）
      this.onSaveConfig()
    },
    async onPlatformChangeHandler(val){
      this.setting.templateId = null
      this.setting.templateName = null
      let res = await getTenantKvByConfigKey({ parentKey: 'CUSTOM_CONFIG',configType:'WAYBILL_OPTION', configKey: this.selectedPlatform })
      if (res.code == 200) {
        if(res.data?.configValue){
          this.setting = JSON.parse(res.data?.configValue|| null) || {isOpen:false,priority:'AREA'}
        } else {
          this.setting = {isOpen:false,priority:'AREA'} // 未配置，默认值
        }
      }
      
    },
    async onSaveConfig() {
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'WAYBILL_OPTION', configKey: this.selectedPlatform,
        configValue: JSON.stringify(Object.assign({platform:this.selectedPlatform},this.setting))
      }
      let res = await saveTenantKv(postData)
      if (res.code === 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onAddClick(){
      if(this.activeTabName == 'area'){
        this.$refs.refArea.addDetail()
      } else{
        this.$refs.refPrice.addDetail()
      }
    }
  }
}
</script>