<template>
  <div class="nt-height-100 ">
    <div style="height:300px;width:500px; margin:auto; margin-top:10%;" class="padding-16 border nt-box-panel nt-bg-white">
      <div>这是成本重算</div>
      <div>成本重算描述</div>
      <div>正在成本重算，进度滚动加载...</div>
      <div class="margin-top align-center">
        <el-button type="primary" @click="onStartClick">开始成本重算</el-button>
      </div>
    </div>
  </div>
  
</template>

<script>

import {costCalculate} from '@/service/stock_period'

export default {
  data () {
    return {
    }
  },
  props:{
  },
  methods:{
    async onStartClick(){
      let res = await costCalculate({})
      if (res.code === 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}

</script>