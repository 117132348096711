import { axios as service } from '@/utils/request'

import { summaryTableUtils } from '@/utils/table_summary'

export const getPurchaseInTotalByGoods = (data) => {
  return service({
    url: '/report/purchase/totalByGoods',
    method: 'post',
    data
  })
}

export const getPurchaseInTotalByCategory = (data) => {
  return service({
    url: '/report/purchase/totalByCategory',
    method: 'post',
    data
  })
}

export const getPurchaseInTotalBySupplier = (data) => {
  return service({
    url: '/report/purchase/totalBySupplier',
    method: 'post',
    data
  })
}


export const getOrderTotalByGoods = (data) => {
  return service({
    url: '/report/purchase/orderTotalByGoods',
    method: 'post',
    data
  })
}


export const getOrderTotalBySupplier = (data) => {
  return service({
    url: '/report/purchase/orderTotalBySupplier',
    method: 'post',
    data
  })
}



// 采购订单明细-按供应商小计
export const getOrderDetailBySupplier = async (data) => {
  // 根据供应商进行小计数据统计
  let res = await service({
    url: '/report/purchase/orderDetailBySupplier',
    method: 'post',
    data
  })
  if(res.code ==200){
    // 分组小计数据处理
    res.data = summaryTableUtils(res.data,"companyId,companyName,billId,billNo",{quantity:'SUM',total:'SUM'})
  }
  return res
}



// 校验订单明细
export const getTradeItemList = async (data) => {
  // 根据供应商进行小计数据统计
  let res = await service({
    url: '/report/purchase/inner/tradeItemList',
    method: 'post',
    data
  })
  if(res.code ==200){
    // 分组小计数据处理
    // res.data = summaryTableUtils(res.data,"companyId,companyName,billId,billNo",{quantity:'SUM',total:'SUM'})
  }
  return res
}

