import { axios as service } from '@/utils/request'


export const getSupplierSummaryList = (data) => {
  return service({
    url: '/report/supplier/rptSupplier',
    method: 'post',
    data
  })
}



export const getCustomerSummaryList = (data) => {
  return service({
    url: '/report/supplier/rptCustomer',
    method: 'post',
    data
  })
}

export const getBillList = (data) => {
  return service({
    url: '/report/supplier/rptBillList',
    method: 'post',
    data
  })
}
