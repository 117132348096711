/**
*datasource 目标转换结果列
*groupKeys 字符串，多个采用逗号分隔 按该字段信息 分类统计范围
*summaryKey 横向结果字段*cols 动态列表对象信息提取范围
*/
export const crossTableUtils = function (datasource, groupKeys, summaryKey, cols) {
  let arCols = cols.split(",")
  let arGroupKeys = groupKeys.split(",");
  let arSummaryKeys = summaryKey.split(",");
  let dynamicColKey = arCols[0]// 动态列id
  // 动态列对象计算逻辑
  let colList = datasource?.reduce((pre, cur) => {
    let item = pre.find(el => el[dynamicColKey] === cur[dynamicColKey])
    if (!item) {// 将arCols 范围数据提取至动态列列表
      item = {}
      arCols.forEach(key => item[key] = cur[key])
      pre.push(item)
    }
    return pre
  }, [])
  // 交叉结果集， 列表=》横向动态
  let result = datasource.reduce((pre, cur) => {
    let item = pre.find(el => isRowEqualGroupBy(el, cur, arGroupKeys))
    if(!item){
      item = {_:true,child:{}}
      pre.push(item)
    }
    arSummaryKeys.forEach(key=>{
      item.child[`${key}_${cur[dynamicColKey]}`] = cur[key]
      // 行小计
      let subTotal = item.child[`${key}_summary`] || 0
      item.child[`${key}_summary`] = subTotal + (cur[key]||0)
    })
    if(item._){
      //拷贝其他显示字段 非动态列属性
      Object.keys(cur).forEach(key => {
        if (!arCols.includes(key) && ![summaryKey, 'child'].includes(key)) {
          item[key] = cur[key]
        }
      })
    }
    return pre
  }, [])

  return { cols: colList, data: result }
}

/*分组小计
count'SUM// key 为字段名称，值为统计方式:SUM 合计AVG 平均 COUNT 记录数
*/
const isRowEqualGroupBy = function (prevRow, curRow, arGroupKeys) {
  for (let key of arGroupKeys) {
    if (prevRow[key] !== curRow[key]) {
      return false
    }
  }
  return true
}

/*判断是否存在平均值 根据已存在的统计信息 summarycount,sum fields,按照配置信
息计算结果
*/
const fillFieldsSummaryValue = function (smmaryRow, fields) {
  Object.keys(fields).forEach(key => {
    let val = fields[key]
    let calSumKey = `sum_${key}`
    if (val.toUpperCase() == 'SUM') {// 小计
      smmaryRow[key] = smmaryRow._summary[calSumKey].toFixed(2)
    } else if (val.toUpperCase() === 'AVG') {// 平均值
      smmaryRow[key] = (smmaryRow._summary[calSumKey] / smmaryRow._summary.count).toFixed(2)
    } else if (val.toUpperCase() === 'COUNT') {// 记录数
      smmaryRow[key] = smmaryRow._summary.count
    }
  })
  // smmaryRow._summary = true // 清除临时数据，并设置为 合计行
}

/*
*根据分组条件，植入小计--合计 小计方式:SUM 求和 VAG平均值 COUNT 记录数
fieldA:'SUM', //合计---必须fieldB:AVG',// 平均 合计/记录数
fieldC:'COUNT' // 记录数---必须
*/
export const summaryTableUtils = function (datasource, groupKeys, fields) {
  let arGroupKeys = groupKeys.split(",");
  let subtotal = {_summary: {} } // 小计
  let summary = {_summary: {} } // 合计
  let result = datasource.reduce((pre, cur) => {
    if (pre.length != 0 && !isRowEqualGroupBy(pre[pre.length - 1], cur, arGroupKeys)) { //新的小计开始
      fillFieldsSummaryValue(subtotal, fields) // 根据需要合计的字段填写结果
      pre.push(subtotal)
      subtotal = { _summary: {} }
    }
    // 小计方式:SUM 求和VAG 平均值 COUNT 记录数summary 临时数据 count 记录数-必须
    subtotal._summary.count = (subtotal._summary.count||0) + 1
    summary._summary.count = (summary._summary.count||0) + 1
    // 根据需要统计的字段列表: 分别累加相应字段的sum，累计格式sum fieldName
    Object.keys(fields).forEach(key => {
      subtotal._summary[`sum_${key}`] = (subtotal._summary[`sum_${key}`] || 0) + cur[key]
      summary._summary[`sum_${key}`] = (summary._summary[`sum_${key}`] || 0) + cur[key]
    })
    // 分组字段回填
    Object.keys(cur).forEach(key => {
      if (groupKeys.includes(key)) {
        subtotal[key] = cur[key]
      }
    })

    pre.push(cur)
    return pre
  }, [])

  if (result.length) {
    fillFieldsSummaryValue(subtotal, fields) // 根据需要合计的字段填写结果
    result.push(subtotal) //最后一条小计
    fillFieldsSummaryValue(summary,fields)// 根据需要合计的字段填写结果
    result.push(summary) // 总合计行
  }

  return result
}