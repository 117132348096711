<template>
  <el-popover :width="300" trigger="click">
    <template #reference>
      <el-icon title="商品显示设置" style="cursor:pointer;" color="var(--el-color-primary)"><Setting /></el-icon>
    </template>
    <template #default>
      <div class="border-bottom padding-bottom-8" style="font-weight: bold;">商品显示设置</div>
      <div class="display-flex padding">
        <span>每行显示</span>
        <el-radio-group v-model="config.columns" size="small" @change="onSaveClick">
          <el-radio-button label="1个" :value="1" />
          <el-radio-button label="2个" :value="2" />
        </el-radio-group>
        
      </div>
      <div class="padding-left">
        <el-checkbox label="超过2行隐藏" v-model="config.maxRowLimit" :true-value="1" :false-value="0" @change="onSaveClick" />
      </div>
    </template>
  </el-popover>
</template>
<script >

import {saveTenantKv} from '@/service/resource/general'
import { LoginUserStore } from '@/piana/login_user_service'
export default {
  data() {
    return {
    }
  },
  computed:{
    config: function(){
      let matchItem = LoginUserStore().getConfig('GOODS_DISPALY')
      return JSON.parse(matchItem?.configValue || null) || { columns: 1, maxRowLimit:2 }
    }
  },
  methods: {
    async onSaveClick(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'GOODS_DISPALY', configValue: JSON.stringify(this.config) }
      LoginUserStore().updateConfig('GOODS_DISPALY',postData)
      let res = await saveTenantKv(postData)
      if (res.code !== 200) {
        this.$message.error(res.msg || '操作失败')
      }
    }
  }
}
</script>
