<!-- 系统监控页 -->
<template>
  <el-container class="" direction="horizontal" style="height:700px;">
    <el-aside width="400px">
      <el-table height="350"  class="nt-resource-table" :data="queueList" @row-click="onTenantClick">
        <el-table-column label="租户" width="200" prop="tenantId">
          <template #default="{row}">
            <div>{{row.tenantId }}</div>
            <div>{{ row.syncType }}</div>
          </template>
        </el-table-column>
        <el-table-column label="下次运行时间" >
          <template #default="{row}">
            <div>{{ formatDate(row.time) }}</div>
          </template>
        </el-table-column>
        
      </el-table>
    </el-aside>
    <el-main class="no-padding margin-left">
      <el-table height="100%"  ref="table" class="nt-resource-table" :data="historyList">
        <el-table-column label="时间" width="200">
          <template #default="{row}">
            <div>{{ formatDate(row.key) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="租户" width="100">
          <template #default="{row}">
            <div>{{ row.value.tenantId }}</div>
          </template>
        </el-table-column>
        <el-table-column label="任务类型" width="160">
          <template #default="{row}">
            <div>{{ row.value.syncType }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结果" >
          <template #default="{row}">
            <div>{{ formatDate(row.value.lastSync) }}</div>
            <div style="font-size:10px;">{{ row.value }}</div>
          </template>
        </el-table-column>
        
      </el-table>
    </el-main>
    <!-- <el-footer class="height-auto align-center margin-top-8">
    </el-footer> -->
  </el-container>
</template>
<script>

import {getSystemQueueHistoryList,getQueueList} from '@/service/resource/general'
import * as dayjs from 'dayjs'
export default {
  name: "MonitoryList",
  components: {
  },
  data() {
    return {
      queueList:[],
      historyList:[]
    }
  },
  async created(){
    let res = await getQueueList({})
    if(res.code == 200){
      this.queueList = res.data
    }
  },
  methods: {
    formatDate(ts){
      return dayjs(parseInt(ts)).format('YYYY-MM-DD HH:mm:ss')
    },
    async onTenantClick(row){
      let res = await getSystemQueueHistoryList({tenantId:row.tenantId})
      if(res.code == 200){
        let list = Object.entries(res.data).map(([key, value]) => ({ key:key, value: JSON.parse(value) }))
        list.sort((a,b)=>b.key-a.key)
        this.historyList = list
      }
    }
  }
}
</script>