
<template>
  <el-container class="padding-8" style="height:550px;background:#fff;">
    <el-main class="no-padding">
      <el-container class="padding-8" style="height:100%">
        <el-aside class="margin-right-4" width="350" style="padding:0px 8px;border-right:1px solid #ddd;">
          <categoryPage @change="onCategoryChangeHandler" read-only />
        </el-aside>
        <el-main class="no-padding">
          <el-container class="no-padding" style="height:100%;">
            <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
              <el-form inline @keyup.enter.native="onSearchQueryClick">
                <nt-form-item label="关键词">
                  <el-input placeholder="请输入条码、名称、规格查询" v-model="queryModel.materialParam"></el-input>
                </nt-form-item>
                <nt-form-item>
                  <el-button type="primary" @click="onSearchQueryClick">查询1</el-button>
                </nt-form-item>
              </el-form>
            </el-header>
            <el-main class="no-padding">
              <el-table height="100%" border ref="refGoodsTable" size="middle"
               @selection-change="onSelectionChangeHandler"
               :data="datasource" v-loading="isLoading">
                <el-table-column label="#" width="50" type="index" fixed="left"> </el-table-column>
                <el-table-column type="selection" v-if="multiple"></el-table-column>
                <el-table-column label="名称" width="220" prop="goodsName"></el-table-column>
                <el-table-column label="条码" width="100" prop="mainGoodsSku.barcode"></el-table-column>
                <el-table-column label="规格" width="100" prop="standard"></el-table-column>
                <el-table-column label="型号" width="100" prop="model"></el-table-column>
                <el-table-column label="颜色" width="100" prop="color"></el-table-column>
                <el-table-column label="类别" width="100" prop="categoryName"></el-table-column>
                <el-table-column label="主单位" width="100" prop="unitName"></el-table-column>
                <el-table-column label="基础重量" width="100" prop="weight"></el-table-column>
                <el-table-column label="保质期" width="100" prop="expiryNum"></el-table-column>

                <el-table-column label="库存" width="100" prop="quantity"></el-table-column>
                <el-table-column label="采购价" width="100" prop="mainGoodsSku.purchaseDecimal">
                </el-table-column>
                <el-table-column label="零售价" width="100" prop="mainGoodsSku.commodityDecimal">
                </el-table-column>
                <el-table-column label="销售价" width="100" prop="mainGoodsSku.wholesaleDecimal">
                </el-table-column>
                <el-table-column label="最低售价" width="100" prop="mainGoodsSku.lowDecimal">
                </el-table-column>
                <el-table-column label="仓位货架" width="100" prop="position"></el-table-column>
                <el-table-column label="备注" width="220" prop="remark"></el-table-column>
              </el-table>
            </el-main>
            <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
              <el-row type="flex" justify="space-between">
                <el-pagination :current-page="this.pageInfo.pageIndex" size="small" :page-size="this.pageInfo.pageSize"
                  :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
                  layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
                  @size-change="onPageSizeChange" />
                  <div>
                    <el-button @click="onCancelClick" type="info" size="small">取消</el-button> 
                    <el-button type="primary" size="small" :disabled="!selectedRows.length" @click="onConfirmClick">确定(已选{{ selectedRows.length }})</el-button>
                  </div>
              </el-row>
            </el-footer>
          </el-container>

        </el-main>
      </el-container>
    </el-main>
    
  </el-container>
</template>
<script>

import categoryPage from '@/views/resource/goods_category'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "erp-goods-selector",
  mixins: [DataListMixin],
  components: {
    categoryPage,
    NtFormItem,
  },
  data() {
    return {
      // currentRecord: {},
      // selectedRows:[]
    }
  },
  props:{
    multiple:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.GOODS, params);
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onCancelClick(){
      this.$emit('cancel')
    },
    onConfirmClick(){
      // 根据单选或复选 返回单个对象或集合
      this.$emit('choose', this.multiple? this.selectedRows: this.selectedRows[0])
    },
    onSelectionChangeHandler(selection) {
      this.selectedRows = selection
    },
    onCategoryChangeHandler(category) {
      // 根据分类获取商品列表 TODO
      console.log('onCategoryChangeHandler', category)
    }
  }
}
</script>