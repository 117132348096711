<template>
  <div>
    <WaybillTemplateSelector @choose="val=>onCommandClick('WAYBILL_TEMPLATE',val)" class="margin-right"></WaybillTemplateSelector>
    <el-button type="primary" @click="onCommandClick('CREATE_PRINT_WAYBILL')">打印快递单</el-button>
    <el-button type="danger" @click="onCommandClick('PRINT_DISPATCH')">打印发货单</el-button>
    <el-dropdown placement="top" split-button @click="onCommandClick('DELIVERY_SEND')" type="success" class="margin-left">
      发货
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="onCommandClick('PRE_DISPATCH')">预发货</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown placement="top">
      <el-button type="info" class="margin-left" >
        更多操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item @click="onCommandClick('DEBUG')">调试</el-dropdown-item>  -->
          <el-dropdown-item @click="onCommandClick('CREATE_WAYBILL')">仅生成面单</el-dropdown-item>
          <el-dropdown-item @click="onCommandClick('PRINT_PICKING')">打印拿货标签</el-dropdown-item>
          <el-dropdown-item @click="onCommandClick('CANCEL_PRE_DISPATCH')">取消预发货</el-dropdown-item>
          <el-dropdown-item @click="onCommandClick('MERAGE')">合并订单</el-dropdown-item>
          <el-dropdown-item @click="onCommandClick('CANCEL_MERAGE')">撤销合并</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import WaybillTemplateSelector from './waybill_template_selector'
export default {
  data() {
    return {
    }
  },
  emits:['command'],
  components: { WaybillTemplateSelector },
  methods: {
    onCommandClick: function (cmd, val) {
      this.$emit('command', cmd, val)
    }
  }
}
</script>