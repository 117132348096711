<template>
  <el-container style="height:650px;">
    <el-main class="no-padding">

      <el-container class="nt-height-100" >
        <el-main class="no-padding">
          
          <el-form :model="formModel" ref="frmEdit" label-width="100" v-loading="isLoading" class="nt-height-100">
            <el-container class="nt-height-100">
              <el-header class="no-margin no-padding" style="height:auto;padding-bottom:0px;">
                <nt-form-item prop="deliveryId" label="所属店铺" :rules="formValidator('required', '请选择店铺')">
                  <nt-select-shop placeholder="请选择店铺" :disabled="formModel.templateId" v-model="shop"  style="width:260px;" @defaultShop="onShopChange"
                  @change="onShopChange"></nt-select-shop>
                </nt-form-item>
                <nt-form-item prop="deliveryId" label="物流快递" :rules="formValidator('required', '请选择快递公司')">
                  <el-select v-model.trim="formModel.deliveryId"  :disabled="formModel.templateId"  @change="onDeliveryChange">
                    <el-option v-for="item in allDelivery" :key="item.deliveryId" :label="item.deliveryName" :value="item.deliveryId" />
                  </el-select>
                </nt-form-item>
                <nt-form-item prop="templateType" label="模版样式" :rules="formValidator('required', '请选择模版样式')">
                  <el-select v-model.trim="formModel.templateType"   @change="onTemplateTypeChange">
                    <el-option v-for="item in templateTypeList" :key="item.templateType" :label="item.templateType" :value="item.templateType" />
                  </el-select>
                </nt-form-item>
                <nt-form-item prop="templateName" label="模版名称" :rules="formValidator('required', '请输入名称')">
                  <el-input placeholder="请输入模版名称" v-model.trim="formModel.templateName" />
                </nt-form-item>
                
                <nt-form-item prop="waybillAccountId" label="面单账号" :rules="formValidator('required', '请选择面单账号')">
                  <nt-dropdown-grid configKey="waybillAccount" v-model="formModel.waybillAccountId"  :requestParam="dynamicProp"
                    v-model:text="formModel.waybillAccountId" :disabled="viewOnly" placeholder="请选择面单账号" :searchable="true"
                    :iconSearch="false"></nt-dropdown-grid>
                </nt-form-item>
                <nt-form-item prop="printerName" label="默认打印机" >
                  <nt-printer v-model="formModel.printerName" placeholder="请选择默认打印机" ></nt-printer>
                </nt-form-item>
                
                <nt-form-item prop="templateDesc" label="模版备注">
                  <el-input placeholder="请输入模版备注" v-model.trim="formModel.templateDesc" />
                </nt-form-item>
              </el-header>
              <el-main class="no-padding">
                <el-table height="100%" border ref="table" size="small"
                  :data="formModel.platformWaybillTemplateOptionList">
                  <el-table-column label="#" width="50" type="index" fixed="left">
                  </el-table-column>
                  <el-table-column label="自定义信息" prop="optionId">
                    <template #default="{ row }">
                      {{ formatOptionName(row.optionId) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="字体大小" width="120" prop="fontSize">
                    <template #default="{ row }">
                      <el-select v-model="row.fontSize">
                        <el-option v-for="item in fontSizeList" :key="item.value" :label="item.text" :value="item.value" />
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="粗体" width="90" align="center" prop="isBold">
                    <template #default="{ row }">
                      <el-checkbox v-model="row.isBold" :true-label="1" />
                    </template>
                  </el-table-column>
                  <el-table-column label="是否使用" width="90" prop="isOpen" align="center">
                    <template #default="{ row }">
                      <el-checkbox v-model="row.isOpen" :true-label="1" />
                    </template>
                  </el-table-column>

                </el-table>
              </el-main>
            </el-container>
          </el-form>
        </el-main>
        <el-aside width="420px" class="margin-left">
          这是可视化设计器
        </el-aside>
      </el-container>
    </el-main>
    <el-footer class="no-padding padding-top height-auto border-top">
      <el-row type="flex" justify="space-between">
        <nt-form-item prop="isDefault" label="设置为默认">
          <el-checkbox v-model="formModel.isDefault" :true-value="1" />
        </nt-form-item>
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
        </el-space>
      </el-row>
    </el-footer>
  </el-container>
  
</template>
  
<script>

import NtFormItem from '@/views/plugin/nt_form_item'
import { DataEditMixin } from '@/utils/data_edit_mixin'
import {getWaybillTemplate, saveWaybillTemplate,deliveryList } from '@/service/print_order/platform_waybill_template'
import NtSelectShop from '@/views/plugin/nt_select_shop'
import {LoginUserStore} from '@/piana/login_user_service'
import NtPrinter from '@/views/plugin/nt_printer'
export default {
  name: "WaybillTemplateDetail",
  components: { NtFormItem,NtSelectShop,NtPrinter },
  mixins: [DataEditMixin],
  inject:['printerList'],
  data() {
    return {
      shop:null,
      allDelivery:[],
      selectedDelivery:null,
      templateTypeList:[],
      fontSizeList: [{ value: 0, text: '正常' }, { value: 1, text: '加粗' }],
      trueFalseList: [{ value: 0, text: '否' }, { value: 1, text: '是' }],
    }
  },
  props: {
    entity: {}
  },
  computed:{
    dynamicProp:function(){
      return {shopId:this.shop?.shopId,deliveryId:this.formModel.deliveryId}
    },
  },
  methods: {
    _loadFinished(){
      if(this.formModel.waybillAccountShopId){
        this.shop = LoginUserStore().getShopById(this.formModel.waybillAccountShopId)
      }
    },
    async _loadEntity(params) {
      if (this.entity?.id) {
        return await getWaybillTemplate( { id: this.entity.id })
      } else if(this.entity){
        // 通过平台选择模版
        let list = this.entity.templateOptionList
        delete this.entity.templateOptionList // 选择模版带过来的属性，更名后删除 
        // 注意： 模版直接通过平台返回的json 属性名称存在差异
        list = list.map(item=>(
          {
            optionId:item.optionId,
            fontSize:item.fontSize,
            isBold:item.bold,
            isOpen:item.open
          }
        ))
        console.log('fk', list)
        this.entity.platformWaybillTemplateOptionList = list
        return {data: Object.assign({},this.entity)}
      } else {
        let defaultOptions = [0,1,2,3,4,5,6,7].map(item=>{// 设置默认的8个选项
          return {optionId: item,fontSize: 0, isBold: false,isOpen: item>2}
        })
        return {
          data:{
            platformWaybillTemplateOptionList:defaultOptions
          }
        }
      }
    },
    async loadDeliveryList(shopId){ // 根据门店账户的变化，重新加载其已开通的物流列表
      let res = await deliveryList({shopId:shopId}) 
      if(res.code ==200){
        this.allDelivery = res.data
        
        if(!this.formModel.deliveryId){ // 通过弹窗是存在deliveryId 
          this.selectedDelivery = this.allDelivery[0]
          this.formModel.deliveryId = this.selectedDelivery.deliveryId
        }
        if(this.formModel.deliveryId){
          this.onDeliveryChange(this.formModel.deliveryId)
        }
      }
    },
    onDeliveryChange(deliveryId){
      this.selectedDelivery = this.allDelivery.find(el=>el.deliveryId == deliveryId)
      this.templateTypeList = this.selectedDelivery?.standardTemplateTypeVoList // 物流公司的 标准模版列表
      if(this.templateTypeList){
        this.formModel.templateType = this.templateTypeList[0].templateType
      }
    },
    onShopChange(shop){ // 切换不同门店的租户
      this.shop = shop
      this.formModel.waybillAccountTenantId = shop.shopTenantId
      this.formModel.waybillAccountShopId = shop.shopId
      this.formModel.platform = shop.shopPlatform
      this.loadDeliveryList(shop.shopId)
    },

    onTemplateTypeChange(tType){
      let typeInfo = this.selectedDelivery.standardTemplateTypeVoList.find(el=>el.templateType == tType )
      if(typeInfo){
        // 设置页面尺寸信息
        Object.assign(this.formModel,{
          width: typeInfo.width,
          height: typeInfo.height,
          customWidth: typeInfo.customConfigWidth,
          customHeight: typeInfo.customConfigHeight,
          customLeft: typeInfo.customConfigLeft,
          customTop: typeInfo.customConfigTop,
          templateUrl: typeInfo.templateUrl
        })
      }
      // 模版样式变化后，更加templateUrl 加载预览效果 TODO
    },
    
    formatOptionName(optionId) {
      let info = {
        0: '商品总数量',
        1: '商品名称+规格+编码+数量',
        2: '商品名称+规格+数量',
        3: '商品名称+数量',
        4: '店铺名称',
        5: '订单号',
        6: '买家留言',
        7: '卖家备注',
      }
      return info[optionId] || optionId
    },
    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值
      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entity?.id }, this.formModel)
      return saveWaybillTemplate(postData)
    },
  }
}
</script>