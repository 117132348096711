
<template>
  <el-container class="nt-height-100 nt-bg-white">
    <el-main class="no-padding">
      <el-tabs v-model="activeTabName"  class="nt-height-100 nt-width-100 nt-tab-page" >
        <el-tab-pane label="平台面单" name="platform" lazy >
          <platform-template></platform-template>
        </el-tab-pane>
        <el-tab-pane label="自定义面单" name="customer" lazy >
          <custom-template></custom-template>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>
<script>

import { computed } from 'vue'
import PlatformTemplate from './platform'
import CustomTemplate from './custom'
export default {
  name: "WaybillTemplateList",
  components: {
    PlatformTemplate,
    CustomTemplate
  },
  provide(){
    return {
      printerList: computed(_=>this.printerList),
    }
  },
  data() {
    return {
      activeTabName:'platform',
      printerList:[]
    }
  },
  
  created() {
    
  },
  methods: {
    
  }
}
</script>