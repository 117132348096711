<template>
  <el-container class="padding nt-bg-page" style="height:500px;">
    <el-tabs v-model="activeTabName"  class="nt-width-100 nt-height-100 nt-tab-page nt-box-panel" >
      <el-tab-pane label="支付方式" name="payment" lazy key="payment">
        <payment-kv ></payment-kv>
      </el-tab-pane>
      <el-tab-pane label="结算账户" name="account" lazy key="account">
        <account-kv ></account-kv>
      </el-tab-pane>
      <el-tab-pane label="门店默认仓库" name="store" lazy  key="store">
        <shop-store-kv/>
      </el-tab-pane>
      <el-tab-pane label="商品分类默认仓库" name="category" lazy key="category">
        <!-- 商品分类指的的是电脑平台的商品分类 -->
        <!-- 按平台-门店-分类（末级--小商店-每级都存在商品） 默认仓库规则，优先按分类进行匹配，次之门店仓库 -->
        <category-kv></category-kv>
      </el-tab-pane>
    </el-tabs>
  </el-container>
  
</template>
<script>
import PaymentKv from './payment_kv'
import ShopStoreKv from './shop_store_kv'
import CategoryKv from './category_kv'
import AccountKv from './account_kv'
export default {
  name: "platform_mapping",
  components: {
    PaymentKv,
    ShopStoreKv,
    CategoryKv,
    AccountKv
  },
  data() {
    return {
      activeTabName:'payment'
    }
  },
  
  created() {
  },
  methods: {
  }
}
</script>
<style scoped lang="less">
  .bill-selector-tab{
    display:flex;
    flex-direction:column;
    :deep(.el-tabs__content){
      flex:1 1 100%;
      .el-tab-pane{
        height:100%;
      }
    }
  }
</style>