
<template>
  <el-container class="nt-bg-page padding">
    <el-main class="padding nt-bg-white nt-box-panel">
      <el-form ref="formRef" :model="formData" label-width="110" size="small">
        <el-form-item prop="textStyle" label="合计文本样式">
          <TextStyleEditor v-model="formData.textStyle"></TextStyleEditor>
        </el-form-item>
        <el-form-item prop="contentStyle" label="内容样式">
          <TextStyleEditor v-model="formData.contentStyle"></TextStyleEditor>
        </el-form-item>
        <el-form-item prop="lastPage" label="仅末页显示">
          <el-radio-group v-model="formData.lastPage">
            <el-radio-button :label="true" >是</el-radio-button>
            <el-radio-button :label="false" >否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="subPage" label="开启分页小计">
          <div class="display-flex" style="align-items: center;">
            <el-switch v-model="formData.subPage" />
            <el-tooltip effect="dark" placement="top" content="开启后，存在多页单据打印时，每页显示当前页小计">
              <el-icon size="14" ><InfoFilled /></el-icon>
            </el-tooltip>
            <el-form-item prop="subPageStyle" label="" v-if="formData.subPage" style="flex:1;">
              <TextStyleEditor v-model="formData.subPageStyle" :hidden="['align']"></TextStyleEditor>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="no-padding no-marign height-auto align-right margin-top">
      <el-button type="info" @click="onCancelClick">取消</el-button>
      <el-button type="primary" @click="onConfirmClick">确定</el-button>
    </el-footer>
  </el-container>
</template>

<script >

import TextStyleEditor from '../property/text_style.vue'
export default {
  data () {
    return {
      formData:Object.assign({textStyle:{textAlign:'left',fontWeight:'',fontSize:14},subtotalType:false},this.config)
    }
  },
  components:{TextStyleEditor},
  props:{
    config:{
      type: Object
    }
  },
  mounted(){
  },
  methods:{
    onConfirmClick(){
      this.$emit('success', JSON.parse(JSON.stringify(this.formData)))
    },
    onCancelClick(){
      this.$emit('cancel')
    },
  }
}
</script>