import { axios as service } from '@/utils/request'


export const getPageIncomeItemList = (data) => {
  return service({
    url: '/incomeItem/list',
    method: 'post',
    data
  })
}
