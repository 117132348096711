
<!-- 
  二维码属性配置面板
    1. 文本表示式-支持手动输入
    2. 字体大小-粗体
    3. 是否打印
    4. 坐标位置
    5. 控件大小
    6. 内边距
    9. 字间距
 -->
<template>
  <el-form ref="formRef" :model="config" label-position="top" size="small">
    <el-form-item prop="v" label="文本内容">
      <!-- 如果配置内容为变量，则不允许进行文本编辑 -->
      <el-input v-model="config.v" @input="onValueChange">
        <template #suffix>
          <el-icon @click="onVarClick" style="cursor:pointer;"><MoreFilled /></el-icon>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item label="">
      <span class="margin-right">是否打印</span><el-switch v-model="config.isHidden" />
    </el-form-item>
    <el-form-item label="位置">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.left" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.top" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="大小">
      <el-row :gutter="16">
        <el-col :span="12"> <el-input v-model="config.width" :read-only="true"></el-input></el-col>
        <el-col :span="12"> <el-input v-model="config.height" :read-only="true"></el-input></el-col>
      </el-row>
    </el-form-item>

  </el-form>
  <el-dialog
    v-model="varVisible" destroyOnClose
    class="nt-resource-dialog"
    :title="'业务字段选择'+billType"
    :close-on-click-modal="false"
    @close="varVisible=false"
  >
    <bill-field-selector :billType="billType" @choose="onChooseHandler" useMode="barcode" @cancel="varVisible =false" v-if="varVisible"></bill-field-selector>
  </el-dialog>
</template>

<script >
// import {usePageDesignerStore} from '../usePageDesignerStore'
// import { ControlCategory } from '../config/design_config'
import BillFieldSelector from '../selector/bill_field_selector'

export default {
  data() {
    return {
      varVisible:false
      // store:usePageDesignerStore()
    }
  },
  components:{BillFieldSelector},
  inject:['billType'],
  props: {
    config: {}
  },
  methods: {
    onVarClick(){
      this.varVisible= true;
    },
    // onValueChange(){
    //   this.config.prop = ""
    //   this.config.label = ""
    // }
    onChooseHandler(field){
      this.config.label = field.label
      this.config.prefix = field.prefix,
      this.config.v = field.prop // `\$\[${field.prop}\]`
      this.varVisible = false
    }
  }
}
</script>