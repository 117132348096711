<!-- by 752718920 -->
<template>
  <div style="height:100%;background-color: #fff;" class="padding display-flex flex-column">
    <div class="query-panel">
      <!-- 查询区域 -->
      <div class="table-page-search-wrapper">
          <!-- 搜索区域 -->
          <a-form-model layout="inline" @keyup.enter.native="onSearchQueryClick">
            <a-row :gutter="24">
              <a-col :md="8" :sm="24">
                <a-form-model-item label="单据编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input placeholder="请输入单据编号" v-model="queryModel.number"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-model-item label="单据日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-range-picker
                    style="width:100%"
                    v-model="queryModel.createTimeRange"
                    format="YYYY-MM-DD"
                    :placeholder="['开始时间', '结束时间']"
                    @change="onDateChange"
                    @ok="onDateOk"
                  />
                </a-form-model-item>
              </a-col>
              <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="onSearchQueryClick">查询</a-button>
                  <a-button style="margin-left: 8px" @click="onSearchResetClick">重置</a-button>
                  <a @click="handleToggleSearch" style="margin-left: 8px">
                    {{ toggleSearchStatus ? '收起' : '展开' }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
                </a-col>
              </span>
              <template v-if="toggleSearchStatus">
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="选择客户" showSearch optionFilterProp="children" v-model="queryModel.companyId">
                      <a-select-option v-for="(item,index) in cusList" :key="index" :value="item.id">
                        {{ item.supplier }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="仓库名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="请选择仓库" showSearch optionFilterProp="children" v-model="queryModel.depotId">
                      <a-select-option v-for="(depot,index) in depotList" :value="depot.id" :key="index">
                        {{ depot.storeName }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="制单人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="选择制单人" showSearch optionFilterProp="children" v-model="queryModel.creator">
                      <a-select-option v-for="(item,index) in userList" :key="index" :value="item.id">
                        {{ item.userName }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="关联单据" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入关联单据" v-model="queryModel.linkNumber"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="单据状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="选择单据状态" v-model="queryModel.status">
                      <a-select-option value="0">未审核</a-select-option>
                      <a-select-option value="1">已审核</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-model-item label="单据备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入单据备注" v-model="queryModel.remark"></a-input>
                  </a-form-model-item>
                </a-col>
              </template>
            </a-row>
          </a-form-model>
        </div>
    </div>
      <div class="body-content flex-auto display-flex flex-column">
        <!-- <div class="table-operator"  style="margin-top: 5px">
          <a-button  @click="myHandleAdd" type="primary" icon="plus">新增</a-button>
          <a-button  icon="delete" @click="batchDel">删除</a-button>
          <a-button icon="check" @click="batchSetStatus(1)">审核</a-button>
          <a-button  icon="stop" @click="batchSetStatus(0)">反审核</a-button>
          <a-button   icon="download">导出</a-button>
          <a-tooltip placement="left" title="支出单主要处理一些进货支出以外的支出如水电支出、房租支出等。" slot="action">
            <a-icon   type="question-circle" style="font-size:20px;float:right;" />
          </a-tooltip>
        </div> -->
        <div class="table-list flex-auto" style="border:1px solid #000;">
          <a-table
            ref="table"
            size="middle"
            bordered
            rowKey="id"
            :columns="columns"
            :data-source="datasource"
            :components="handleDrag(columns)"
            :pagination="false"
            :scroll="scroll"
            v-loading="isLoading">
            <!-- <template slot="action" slot-scope="text, record">
              <a @click="myHandleDetail(record, '其它出库', prefixNo)">查看</a>
              <a-divider  type="vertical" />
              <a   @click="onRowEditClick(record)">编辑</a>
              <a-divider  type="vertical" />
              <a   @click="myHandleCopyAdd(record)">复制</a>
              <a-divider   type="vertical" />
              <a-popconfirm   title="确定删除吗?" @confirm="() => myHandleDelete(record)">
                <a>删除</a>
              </a-popconfirm>
            </template>
            <template slot="customRenderStatus" slot-scope="status">
              <a-tag v-if="status == '0'" color="red">未审核</a-tag>
              <a-tag v-if="status == '1'" color="green">已审核</a-tag>
              <a-tag v-if="status == '9'" color="orange">审核中</a-tag>
            </template> -->
          </a-table>
          <a-row type="flex" justify="end" class="padding">
            <a-pagination size="small"
              show-size-changer
              :show-total="total => `共 ${total} 条`"
              :current="this.pageInfo.pageIndex"
              :pageSize="this.pageInfo.pageSize"
              :total="this.pageInfo.total"
              @change="onPageIndexChange"
              @showSizeChange="onPageSizeChange"
            />
          </a-row>
          
        </div>
          <!-- table区域-end -->
    </div>
    
    
  </div>
  
</template>
<script>
  // import {getDepotFlowList} from '@/service/depot_flow'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  
  import Vue from 'vue'
  export default {
    name: "DepotFlowList",
    mixins:[DataListMixin],
    components: {
    },
    data () {
      return {
        currentRecord:{},
        toggleSearchStatus:false,
        detailVisible:false,
        selectedRowKeys:[],
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 18,
          offset: 1
        },
        scroll:{},
        cardStyle:{},
        // 表头
        columns: [
          { title: '单据类型', dataIndex: 'billType',width:80},
          { title: '单据编号', dataIndex: 'billNumber',width:80},
          { title: '商品', width:80, ellipsis:true, dataIndex: 'materialName'
          },
          { title: '单位', width:50, ellipsis:true, dataIndex: 'unitName'
          },
          { title: '仓库', width:50, ellipsis:true, dataIndex: 'storeName'
          },
          { title: '单据日期', dataIndex: 'billTime',width:125,
            customRender: (_,r,index) =>  this.formatDate(r.billTime)
          },
          { title: '数量', dataIndex: 'quantity',width:60,
            customRender:function(t,r,index){
              return r.quantity/100.00;
            }
          },
          { title: '金额合计', dataIndex: 'total',width:80,
            customRender:function(t,r,index){
              return r.quantity*r.price/100;
            }}
        ]
      }
    },
    props:{
      materialId:{
        type:String
      }
    },
    computed: {
    },
    created () {
    },
    methods: {
      // async _loadList(params){
      //   return await getDepotFlowList(Object.assign({materialId:this.materialId},params));
      // },
      
    
      onRowEditClick(record){
        this.currentRecord = record
        this.detailVisible = true
      },
      onDateOk(){},
      onSearchQueryClick(){
        this.loadList()
      },
      onSearchResetClick(){
        this.queryModel = {}
        this.resetPage = true
        this.loadList(true)
      },
      onDateChange(){},
      handleToggleSearch(){},
      
      onSelectChange(selectedRowKeys, selectionRows) {
        this.selectedRowKeys = selectedRowKeys;
        this.selectionRows = selectionRows;
      },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less'
</style>