<template>
  <el-container class="no-padding" style="height:600px;">
    <el-header class="no-padding" style="height:auto;">
      <el-form :model="queryModel" inline>
        <el-row>
          <el-col :span="6">
            <el-form-item label="单据编号" prop="billNo">
              <el-input placeholder="请输入单据编号查询" v-model="queryModel.billNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商品信息" prop="keyword">
              <el-input placeholder="条码|名称|规格|型号" v-model="queryModel.keyword"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单据日期">
              <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
                start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD" style="width:100%;"></nt-date-range>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="onQueryClick">查询</el-button>
              <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-container class="no-padding" style="height:100%">
        <el-header class="no-padding margin-bottom" style="height:280px;">
          <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="datasource" :row-style="getRowStyle"
            @row-click="onBillRowClickHandler" v-loading="isLoading">
            <!-- <el-table-column label="选择" width="40" type="selection">
            </el-table-column> -->
            <el-table-column label="往来单位" prop="companyName">
            </el-table-column>
            <el-table-column label="单据编号" width="160" prop="billNo">
            </el-table-column>
            <el-table-column label="单据日期" width="120" align="center" prop="billTime">
              <template #default="scope">
                {{ formatDate(scope.row.billTime, 'YYYY-MM-DD') }}
              </template>
            </el-table-column>
            <el-table-column label="金额合计" width="90" prop="total">
              <template #default="scope">
                {{ ((scope.row.total || 0)).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="160"></el-table-column>
            <el-table-column label="制单人" width="100" prop="userName"></el-table-column>
            <el-table-column label="执行状态" width="100">
              <template #default="{row}">
                <el-tag v-if="(row.executeStatus ||0) == 0" type="danger" round>未执行</el-tag>
                <el-tag v-if="row.executeStatus == 1" type="warning" round>部分执行</el-tag>
                <el-tag v-if="row.executeStatus == 2" type="success" round>已执行</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-header>
        <el-main class="no-padding">
          <el-table size="middle" rowKey="id" border height="100%"
            :data="billHeader?.tradeItemList" @selectable="function (row, index) {
              return (record.finishQuantity || 0) >= record.quantity
            }" @select="onSelectDetailChange">
            <el-table-column label="选择" width="45" type="selection">
            </el-table-column>
            <el-table-column label="名称" prop="goods.goodsName">
            </el-table-column>
            <el-table-column label="规格" width="100" prop="standard">
            </el-table-column>
            <el-table-column label="SKU" width="120" prop="sku">
            </el-table-column>
            <el-table-column label="单位" width="90" prop="unitName">
            </el-table-column>
            <el-table-column label="数量" width="80" prop="quantity">
            </el-table-column>
            <el-table-column label="已引用" width="80" prop="finishQuantity">
            </el-table-column>
            <el-table-column label="单价" width="90" prop="price">
              <template #default="scope">
                {{ (scope.row.price || 0).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="备注" width="160" prop="remark"></el-table-column>
          </el-table>
        </el-main>
      </el-container>

    </el-main>
    <el-footer class="no-padding" style="height:auto;">
      <el-row type="flex" justify="space-between" class="margin-top">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" @click="onConfirmClick">确定</el-button>
        </el-space>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: 'LinkBillList',
  mixins: [DataListMixin],
  components: {
    NtDateRange
  },
  data() {
    return {
      billHeader: null,
    }
  },
  props: {
    billType: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: true
    }
  },
  created() {
    this.allSelectedRows = {}
  },
  methods: {
    async _loadList(params) {
      this.billHeader = null
      // executeStatus 0 未执行 1 部分执行: 表示 可引用，即未执行结束，isClosed=0 未手动关闭
      return await this.getPageEntityList(this.EnumEntityType.TRADE_HEAD, Object.assign({ billType: this.billType, companyId: this.companyId, isClosed: '0', executeStatus: '0,1', status: '1' }, params));
    },
    _loadFinished() {
      if (this.datasource && this.datasource.length > 0) {
        this.billHeader = this.datasource[0]
      }
    },

    onSelectDetailChange(rows) {
      this.allSelectedRows[this.billHeader.id] = rows
    },

    onConfirmClick() {
      let allRows = []
      Object.values(this.allSelectedRows).forEach(billRows => {
        allRows.push(...billRows)
      })
      if (allRows.length) {
        this.$emit('choose', allRows, this.billHeader)
      } else {
        this.$message.warning('抱歉，请选择单据明细！')
      }
    },

    onSearchResetClick() {
      this.queryModel = {
        billNo: '',
        keword: '',
        startTime: null,
        endTime: null
      }
      this.resetPage = true
      this.loadList()
    },
    onQueryClick() {
      this.resetPage = true
      this.loadList()
    },

    onBillRowClickHandler(row) {
      this.billHeader = row
    },
    getRowStyle({ row }) {
      if (this.billHeader && this.billHeader?.id == row.id) {
        return { background: '#e6f7ff' }
      }
    },
    onCancelClick() {
      this.$emit('cancel');
    }
  }
}
</script>
