<template>
  <el-popover
    v-bind="$attrs"
    :virtual-ref="targetElement"
    virtual-triggering
    :visible ="isVisible"
    trigger="click"
    :width="300"
  >
  <div>
    <el-image width="100%" :src="goods?.goodsThumb"></el-image>
    <div>
      <div style="font-weight: bold;">{{ goods?.goodsName }}</div>
      <div>规格 {{ goods?.attrs }}</div>
      <div class="display-flex space-between">
        <span>单价￥{{ goods?.price }}</span>
        <span>商品数量{{ goods?.quantity }}</span>
      </div>
      <div>商家编码{{ goods?.skuId }}</div>
    </div>
  </div>
  </el-popover>
</template>
<script >

export default {
  data() {
    return {
      isVisible:false,
      targetElement:null,
      goods:{}
    }
  },
  methods: {
    show(refElement,goods){
      this.targetElement = refElement
      this.goods = goods
      this.isVisible = true
    },
    hide(){
      this.isVisible =false
    }
  }
}
</script>
