

<template>
  <el-form ref="formRef" :model="config" label-position="top" size="small" >
    <div style="font-weight:bold;font-size:20px;" class="margin-bottom">页面设置</div>
    <el-form-item label="模版标题">
      <el-input v-model="config.title" />
    </el-form-item>
    <!-- <el-form-item label="表体模式">
      < !- - 
        高度自适应： 在业务的明细内容，body区域将根据内容高度自动延伸，超出纸张的高度，自动增加一页， 不支持 页小计
        固定高度： 系统自动计算，超过时，自动生成新的完整一页 (当前已实现模式)
        -- >
      <el-radio-group v-model="config.bodyMode">
        <el-radio value="auto" >高度自适应</el-radio>
        <el-radio value="fixed" >固定高度</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <!-- <el-form-item label="表体容器类型"> -->
      <!-- 
        网格： 通过选择列布局，宽度铺满，高度根据表体模式切换
        自由容器： 即在容器内，可添加内容---表体数据源   + 单据主体信息 两部分
        -->
      <!-- <el-radio-group v-model="config.bodyContainer">
        <el-radio value="grid" >网格模式</el-radio>
        <el-radio value="block">自由容器</el-radio>
      </el-radio-group> -->
    <!-- </el-form-item> -->
    <!-- <el-form-item label="页脚显示">
      <el-switch
        v-model="config.showFooter"
        size="large"
        active-text="显示页脚"
      />
      
    </el-form-item> -->
    <el-form-item label="纸张类型">
      <el-select v-model="config.pageId" placeholder="请选择纸张类型" @change="onPaperTypeChange">
        <el-option
          v-for="item in paperOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div style="color:#666;text-align: center;width:100%;" v-if="config.pageId !=='custom'">纸张尺寸({{ config.pageWidth }}X{{ config.pageHeight }}mm)</div>
    </el-form-item>
    <el-form-item label="宽度(mm)" v-if="config.pageId =='custom'">
      <el-input v-model="config.pageWidth" >
        <template #suffix>px</template>
      </el-input>
    </el-form-item>
    <el-form-item label="高度(mm)" v-if="config.pageId =='custom'">
      <el-input v-model="config.pageHeight" >
        <template #suffix>px</template>
      </el-input>
    </el-form-item>
    <el-form-item label="纸张边距" class="no-margin">
      <PaddingPanel :config="config.padding" />
    </el-form-item>
  </el-form>
</template>

<script >
  import PaddingPanel from './padding'
  
  export default {
    data() {
      return {
        paperOptions:[
          {
            value: 'A4-1',
            label: 'A4一分纸241*280mm',
          },
          {
            value: 'A4-2',
            label: 'A4二分纸张241*140mm',
          },
          {
            value: 'A4-3',
            label: 'A4三分纸张241*93mm'
          },
          {
            value: 'A4',
            label: 'A4 210*297mm'
          },
          {
            value: 'A5',
            label: 'A5 148*210mm'
          },
          {
            value: 'RM-1',
            label: '热敏100*150mm',
          },
          {
            value: 'RM-2',
            label: '热敏100*180mm',
          },
          {
            value: 'RM-3',
            label: '热敏100*210mm',
          },
          {
            value: 'custom',
            label: '自定义',
          },
        ]
      }
    },
    components:{PaddingPanel},
    props:{
      config:{} // bodyMode:"auto",bodyContainer:'grid'
    },
    created(){
      // console.log('fking config', this.config)
    },
    methods: {
      onPaperTypeChange(pageId){
        if(pageId == 'custom'){
          return
        }

        let pageInfo = {
          'A4-1':{width:241,height:280}, //A4整张241*280mm
          'A4-2':{width:241,height:140}, //A4二分纸张241*140mm
          'A4-3':{width:241,height:94}, //A4三分纸张241*93mm
          'A4':{width:241,height:297}, //A4
          'A5':{width:148,height:210}, //A5
          'RM-1':{width:100,height:150}, //热敏100*150mm
          'RM-2':{width:100,height:180}, //热敏100*180mm
          'RM-3':{width:100,height:210}, //热敏100*210mm
        }
        let sizeInfo = pageInfo[pageId] || {width:100,height:100} // 默认100*100cm
        this.config.pageWidth = sizeInfo.width
        this.config.pageHeight = sizeInfo.height
      }
    }
  }
</script>