const tpl =`
<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  </head>
    <style>
        body {
        }
        *{
            box-sizing: border-box;
        }
        .nt-print-page{
            position:relative;
        }
        .nt-print-block{
            position:relative;
        }
        .nt-print-item{
            display:inline-block;
            position:absolute;
        }
        .nt-print-text{
            white-space:nowrap;
            padding:0px;
        }
        .display-flex{
            display:flex;
            align-items:start;
            flex-wrap:wrap;
        }

        .nt-table-detail{
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
        }
        .nt-table-detail>table{
            width:100%;
            border-collapse:collapse;
        }

        .nt-table-detail>table td{
            border:{{configJson.body.borderWidth}} solid #000;
            padding:4px;
        }
    </style>
  </head>
    <div class="nt-print-page" style="width:{{pageWidth}}px;height:{{pageHeight}}px;border:1px solid #000;">
        {{#renderWaybill}}
        {{#configJson.items}}
            {{#createElement}}{{/createElement}}
        {{/configJson.items}}
        {{/renderWaybill}}

        {{#renderHeader}}
        <div class="nt-print-block" style="height:{{configJson.headerHeight}}mm;">
         {{#configJson.header}}
            {{#createElement}}{{/createElement}}34q
         {{/configJson.header}}
        </div>
        {{/renderHeader}}
        {{! 自由容器模式}}
        {{#renderFreeContainer}}
        <div class="nt-print-block display-flex" data-tip="自由容器" 
        style="
            height:300px;
            
            column-gap:{{configJson.freeContainer.columnGap}}px;row-gap:{{configJson.freeContainer.rowGap}}px;
        ">
            {{! 根据容器的行 列, 构建二次模版 最终以数据源的长度计算}}
            {{{dsStart}}}
            <div style="padding-left:{{setting.padding.left}}px;padding-right:{{setting.padding.right}}px;
            width:{{freeContainerWidth}};height:300px;box-sizing:border-box;">
                <div style="overflow:hidden;position:relative;width:100%;height:100%;">
                    {{! 下方容器元素，再二次渲染时创建 即先占位 items, 而成渲染时，再创建元素}}
                    {{#configJson.freeContainer.items}}
                        {{#createElement}}{{/createElement}}
                    {{/configJson.freeContainer.items}}
                </div>
            </div>
            {{{dsEnd}}}
        </div>
        {{/renderFreeContainer}}
        {{! 网格模式==2选一}}
        {{#renderBody}}
        <div class="nt-print-block" style="height:{{configJson.bodyHeight}};">
            <div class="nt-table-detail" style="left:{{setting.padding.left}}px;right:{{setting.padding.right}}px">
            <table >
                <thead>
                    <tr style="height:{{configJson.body.titleHeight}}mm;">
                    {{#configJson.body.columns}}
                    <td width="{{width}}px" {{#formatCellStyle}}{{/formatCellStyle}}>{{label}}</td>
                    {{/configJson.body.columns}}
                    </tr>
                </thead>
                <tbody>
                {{{dsStart}}}
                    <tr style="height:{{configJson.body.contentHeight}}mm;">
                    {{#configJson.body.columns}}
                    {{! 直接生成二次模版 可以使用 {{& 来输出 { ，使用 {{/ 来输出 }  createColspan 生成模版占位符 }}
                        {{#createCell}}{{/createCell}}
                    {{/configJson.body.columns}}
                    </tr>
                {{{dsEnd}}}
                </tbody>
            </table>
            </div>
        </div>
        {{/renderBody}}

        {{#renderFooter}}
        <div class="nt-print-block" style="height:{{configJson.footerHeight}}mm;">
        {{#configJson.footer}}
            {{#createElement}}{{/createElement}}
        {{/configJson.footer}}
        </div>
        {{/renderFooter}}

    </div>
</html>
`
export default tpl