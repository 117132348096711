
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        ref="table"
        size="middle"
        class="nt-rpt-table"
        :data="datasource"
        v-loading="isLoading"
        >
        <el-table-column label="单据编号" width="160" >
          <template #default="scope">
            <el-space>
              <a @click="onViewBillDetailClick(scope.row)">{{scope.row.billNo}}</a>
            </el-space>
          </template>
        </el-table-column>
        
        <el-table-column label="类型"  prop="billType">
          <template #default="{row}">
          {{ this.EnumBillType[row.billType] }}
        </template>
        </el-table-column>
        <el-table-column label="往来单位"  prop="companyName">
        </el-table-column>
        <el-table-column label="交易账户"  prop="accountName">
        </el-table-column>
        <el-table-column label="交易金额"  prop="total">
          <template #default="scope">
            {{ ((scope.row.total||0)/100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="单据日期"  prop="billDate">
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-rpt-layout>
  
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
  import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
  import {getAccountFlowList} from '@/service/financial/account_flow'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {EnumBillType} from '@/utils/enum_utils'
  export default {
    name: "AccountFlow",
    mixins:[DataListMixin],
    components: {
      NtRptLayout,
    NtRptToolbar,
      BillViewerPage
    },
    data () {
      return {
        detailVisible:false,
        toggleSearchStatus:false,
        
      }
    },
    props:{
      query:{ // 查询条件
        type:Object,
        required: true
      }
    },
    methods: {
      async _loadList(params){
        return await getAccountFlowList(Object.assign(params,this.query))
      },
      onSearchQueryClick() {
        this.loadList();
      },
      onViewBillDetailClick(record){
        this.$refs.refViewer.showBill(record.billType,record.billId)
      }
    }
  }
</script>

<style scoped>
  @import '~@assets/less/common.less';
</style>
<style scoped>
  .item-info {
    float:left;
    width:30px;
    height:30px;
    margin-left:8px
  }
  .item-img {
    cursor:pointer;
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>