<!-- 动态单据查看工具入库页 -->
<template>
  <el-dialog
    class="nt-none-border-dialog"
    :header="null"
      v-model="billVisible" destroyOnClose width="80%" append-to-body
      :close-on-click-modal="false"
      title="详情"
      @close="billVisible=false"
    >
      <!-- 动态组件加载... -->
      <component :is="dynamicBill" :entityId="entityId" :viewOnly="viewOnly" :height="650" @cancel="billVisible=false"></component>
    </el-dialog>
</template>
<script>
  
  import {createBillComponent} from '@/utils/bill_viewer_utils'
  export default {
    name: "billViewer",
    components: {
    },
    data () {
      return {
        billVisible:false,
        dynamicBill:null,
        entityId:null,
        viewOnly:true
      }
    },
    props:{
    },
    created(){

    },
    methods: {
      async showBill(billName,billId,viewOnly=true){// 显示单据
        this.entityId = billId
        this.viewOnly = viewOnly
        
        this.dynamicBill = await createBillComponent(billName)
        // 查看模式
        this.billVisible = true
      }
    }
  }
</script>
