<template>
  <el-dialog
    v-model="isVisible" destroyOnClose width="850" append-to-body :close-on-click-modal="false"
    class="nt-resource-dialog"
    @close="isVisible=false"
  >
  <div class="custom-waybill display-flex" style="width:500px;">
    <div class=" relative display-flex space-between" @click="onTemplateClick(tpl)"
      v-for="tpl in waybillTemplateList" :key="tpl.id">
      <div class="display-flex" style="column-gap: 2px;"><el-icon>
          <ElemeFilled />
        </el-icon>{{ tpl.templateName }}</div>
    </div>
  </div>
  </el-dialog>
  
</template>

<script>
import {LoginUserStore} from '@/piana/login_user_service'
export default {
  data() {
    return {
      waybillTemplateList: LoginUserStore().templateList,
      selectedTpl: null,
      isVisible: false
    }
  },

  methods: {
    showDialog(callback){
      this._callback = callback
      this.isVisible = true
    },
    onTemplateClick(tpl) {
      this.selectedTpl = tpl
      this.isVisible=false
      this._callback?.(tpl)
      this.$emit('choose', tpl)
    }
  }
}
</script>
<style lang="less" scoped>
.custom-waybill {
  flex-wrap: wrap;

  &>div {
    cursor: pointer;
    flex: 0 0 calc(50% - 8px);
    background-color: #f5f5f5;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 5px;

    .el-icon {
      font-size: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}</style>