<!--
  业务单据变量选择窗口
-->
<template>
	<div>
		<el-row  type="flex" class="padding-left padding-right" :gutter="40">
      <template v-for="(item,index) in list" :key="'s_'+index">
        <div class="nt-module">
          <div class="nt-module-title" >
            {{item.title}}
          </div>
          <div @click="onFieldChooseClick(child)" class="nt-module-item " v-for="(child,childIndex) in item.children"  :key="'s_x'+subIndex">
            <div >{{child.label}}</div>
          </div>
        </div>
      </template>
      
    </el-row>
	</div>
</template>

<script >
import * as billConfig from '../config/bill_field_list'

export default {
  data () {
    return {
      list: billConfig[this.billType]
    }
  },
  components:{},
  props:{
    billType:{
      type: String,
      required:true
    }
  },
  created(){
    console.log('fking', this.billType)
  },
  methods:{
    onFieldChooseClick(field){
      this.$emit('choose',field)
    }
  }
}

</script>
<style lang="less" scoped>
.nt-module{
  min-width:180px;
  padding:0px 8px;
  .nt-module-title{
    padding:4px 12px;
    color:var(--el-text-color-placeholder);
    font-size:12px;
  }
  .nt-module-item{
    display:flex;
    padding:2px 12px;
    justify-content:space-between;
    align-items:center; 
    cursor: pointer; 
    height:40px;
    line-height:40px;
    .nt-function-list{
      display:none;
    }
    &:hover{
      background:var(--el-menu-hover-bg-color);
      .nt-function-list{
        display:initial;
      }
    } 
  }
  &:not(:last-child){
    border-right:1px solid #f5f5f5;
  }
}
</style>