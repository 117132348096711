import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import notification from 'ant-design-vue/es/notification'
import { USER_ID,INDEX_MAIN_PAGE_PATH } from '@/store/mutation-types'
import {LoginUserStore} from '@/piana/login_user_service'
// import { generateIndexRouter } from "@/utils/util"

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/user/login', '/user/register', '/user/register-result'] // no redirect whitelist

router.beforeEach( (to, from, next) => {
  console.log('to',to)
  if(to.query.code){
    next()
    return
  }
  // $app.ls.remove(USER_ID)
  NProgress.start() // start progress bar
  // console.log('to path', to.path)
  if(to.path.startsWith('/print')){
    console.log('start from print with code')
    next()
    return
  }
  
  if ($app.ls.get(USER_ID)) {
    /* has token */
    if (to.path === '/' || to.path === '/user/login') {
      next({ path: INDEX_MAIN_PAGE_PATH })
      NProgress.done()
    } else {
      // console.log('xxxxx', to.path)
      LoginUserStore().loadTenentShop()
      if (!store.getters.allMenu || store.getters.allMenu.length==0) { // 首次没有任何菜单时，加载菜单
        store.dispatch('getAllMenuList').then(res=>{
          let rootRoute = { // tab 路由总入口
            path: '/',
            name: 'tabLayout',
            component: ()=>require('@/components/layouts/TabLayout'), // 构建根tab切换布局路由,
            children: []
          }
          
          res.forEach(item=>{
            // 动态加载组件  pagePath
            if(item.pagePath){ // 实际路由菜单,动态加载页面组件
              
              let extra = JSON.parse(item.extra||'{}')
              let meta = Object.assign({
                  title: item.menuName,
                  componentName:item.routeName
                },
                extra
              )
              rootRoute.children.push({
                path: item.url,
                name: "page_" + item.id, // item.routeName,
                component: ()=>{
                  let com =   require('@/views'+item.pagePath)
                  return {
                    ...com.default,
                    name: "page_" + item.id
                  }
                },
                meta: meta
              })
            }
          })
          router.addRoute(rootRoute)
          const redirect = decodeURIComponent(from.query.redirect || to.path)
          next({ path: redirect }) // 重定向，再次进入路由，进入下方的else  next
        })
      } else {
        if (to.path) {
          _hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/user/login' })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
