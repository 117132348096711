<template>
  <el-container class="no-padding" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="单据编号" :labelCol="{ span: 5 }" >
          <el-input placeholder="请输入单据编号查询" v-model="queryModel.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
          <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
        </el-form-item>

      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" rowKey="id" :data="datasource" v-loading="isLoading"
        @selection-change="onSelectionChangeHandler">
        <el-table-column label="" width="40" type="selection"></el-table-column>
        <el-table-column label="单据编码" width="165" prop="billNo">
          <template #default="{row}">
            <el-text truncated line-clamp="1" size="small">{{ row.billNo}}</el-text>
          </template>
        </el-table-column>
        <el-table-column label="业务类型" width="120" prop="billType"></el-table-column>
        <el-table-column label="单据日期" width="110" align="center" prop="billTime">
          <template #default="scope">
            {{ formatDate(scope.row.billTime, 'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="单据总额" prop="total" width="90">
          <template #default="{ row }">
            {{ row.total }}
          </template>
        </el-table-column>
        <el-table-column label="已结算" prop="settleTotal" width="90">
          <template #default="{ row }">
            {{ row.settleTotal }}
          </template>
        </el-table-column>
        <el-table-column label="未结算" width="120">
          <template #default="{ row }">
            {{ row.total - (row.settleTotal || 0)}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="制单人" prop="userName" width="120"></el-table-column>
        <el-table-column label="付款状态" prop="paymentStatus" width="100" align="center">
          <template #default="{row}">
            <el-tag type="danger"  v-if="row.paymentStatus == 0"> 未付款</el-tag>
            <el-tag type="warning" v-if="row.paymentStatus == 1"> 部分付款</el-tag>
            <el-tag type="success"  v-if="row.paymentStatus == 2"> 已付款</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="备注" prop="remark"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top" style="margin-top:12px;height:auto;">
      <el-row type="flex" justify="space-between">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" size="small" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
        <el-space>
          <el-button @click="onCancelClick">取消</el-button>
          <el-button type="primary" :disabled="!selectionRows.length" @click="onConfirmClick">确定{{`(${selectionRows.length})`}}</el-button>
        </el-space>
      </el-row>

    </el-footer>
  </el-container>
</template>

<script>
// import {getPaymentInitList} from '@/service/payment_init'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'
export default {
  name: 'PaymentInitList',
  mixins: [DataListMixin],
  components: {
    NtDateRange
  },
  data() {
    return {
      selectionRows: [],
    }
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  emits: ['choose', 'cancel'],
  created() {
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.PAYMENT_INIT, Object.assign({ companyId: this.companyId,paymentStatus:'0,1', status: '1' }, params));
    },

    onCancelClick() {
      this.$emit('cancel');
    },

    onSelectionChangeHandler(rows) {
      this.selectionRows = rows;
    },
    onSearchQueryClick() { },
    onConfirmClick() {
      if (this.selectionRows.length <= 0) {
        this.$message.error('请选择单据')
        return
      }

      this.$emit('choose', this.selectionRows);
    },
    onSearchResetClick() {
      this.queryModel = {
        type: this.queryModel.type,
        subType: this.queryModel.subType
      }
      this.resetPage = true
      this.loadList();
    }
  }
}
</script>

<style scoped></style>