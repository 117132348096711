<template>
  <div class="padding" style="height:350px;">
    <el-table ref="refTable" :data="tableData" height="100%" border @select="onRowSelection">
      <el-table-column width="38" type="selection" />
      <el-table-column prop="label" label="列名" />
    </el-table>
  </div>
</template>

<script >
import * as billConfig from '../config/bill_field_list'
export default {
  data () {
    return {
      billConfig,
      selectRows:[]
    }
  },
  emits:['select'],
  inject:['billType'],
  components:{},
  props:{
    config:{
      type: Object
    }
  },
  computed:{
    tableData:function(){
      return this.billConfig[this.billType]?.details || []
    }
  },
  
  mounted(){
    this.config.columns.forEach(el=>{
      let matchItem = this.tableData.find(item=>item.prop == el.prop)
      if(matchItem){
        this.$refs.refTable.toggleRowSelection(matchItem)
      }
    })
  },
  
  methods:{
    onRowSelection(selection,row){
      this.$emit('select',row) // 当前发生变化的行，够选，或删除
    },
  }
}
</script>