<template>
  <el-table-column v-bind="$attrs" class-name="no-padding nested-table">
    <template #header>
      <div class="display-flex align-center" style="column-gap:2px;font-weight:initial;">
        <!-- <div class="goods-sku" :style="{ width: '70px' }">SKU编码</div> -->
        <div class="sku-attr" style="flex:1;">SKU属性</div>
        <div class="erp-goods-sku" :style="{ width: '140px' }" >ERP-SKU</div>
      </div>
    </template>
    <template #default="{ row }">
      <el-table :data="row.platformGoodsSkuList" size="small" :show-header="false" class="tbl-mapping">
          <el-table-column label="sku" prop="skuCode">
            <template #default="scope">
              {{ formatSkuAttr(scope.row.attrs) }}
              <el-tag class="absolute left-top" size="small" style="margin-top:2px;margin-left:2px;">{{ scope.row.skuCode }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="ERP商品信息">
            <template #default="scope">
              <div>{{ scope.row.erpGoodsName }}</div>
              <div>
                <el-tag size="small" v-if="scope.row.erpGoodsUnitName">{{ scope.row.erpGoodsUnitName }}</el-tag>
                <el-tag size="small" v-if="scope.row.erpSku">{{ scope.row.erpSku }}</el-tag>
              </div>
              <i class="absolute  right-top iconfont icon-guanlianpinpaici" style="color:var(--el-color-primary);font-size:22px;font-weight:bold;cursor:pointer;" @click="onLinkClick(scope.row)"></i>
            </template>
          </el-table-column>
      </el-table>
    </template>
  </el-table-column>
  <el-dialog append-to-body v-model="dialogVisible"  destroyOnClose width="450" class="nt-resource-dialog" title="ERP商品选择" :close-on-click-modal="false"
    @close="dialogVisible = false">
    <erp-goods-choose @choose="onGoodsChooseHandler" @cancel="dialogVisible = false" v-if="dialogVisible"></erp-goods-choose>
  </el-dialog>
</template>
<script >

import {bindSkuToErpSku} from '@/service/resource/platform_goods'
import ErpGoodsChoose from './erp_goods_choose'
export default {
  data() {
    return {
      record:null,
      dialogVisible:false
    }
  },
  components:{
    ErpGoodsChoose
  },
  props: {
    hideColumns: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    formatSkuAttr(attrs) {
      let arAttrs = JSON.parse(attrs || null) || []
      let ar = arAttrs.map(item => {
        return `${item.attr_key}:${item.attr_value}`
      })
      return ar.join(',')
    },
    
    onLinkClick(row){
      this.record = row
      this.dialogVisible = true
    },
    async onGoodsChooseHandler(erpGoodsInfo){
      
      let list = [{
        goodsId: this.record.goodsId,
        goodsSkuId: this.record.skuId,
        shopId: this.record.shopId,
        erpGoodsId :erpGoodsInfo.goodsId,
        erpGoodsUnitId: erpGoodsInfo.unitId,
        erpGoodsSkuId: erpGoodsInfo.skuId
      }]
      let res = await bindSkuToErpSku(list)
      if(res.code == 200){
        // 刷新列表
        this.record.erpGoodsName = erpGoodsInfo.goodsName
        this.record.erpGoodsUnitName = erpGoodsInfo.unitName
        this.record.erpSku = erpGoodsInfo.sku
        this.$message.success('关联成功')
      }
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less">
  .nested-table{
    &.el-table__cell{
      padding:0px !important;
      >.cell{
        padding:0px !important;
      }
    }
    .tbl-mapping {
      .el-table__cell{
        padding:0px;
      }
    }
  }
</style>
