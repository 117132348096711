import { axios as service } from '@/utils/request'

/**
 * 
 * @param {手动关闭订单} data 
 * @returns 
 */
export const closeTradeHead = (data) => {
  return service({
    url: '/tradeHead/close',
    method: 'post',
    data
  })
}



/**
 * 
 * @param {批量关闭订单} data 
 * @returns 
 */
export const batchCloseTradeHead = (data) => {
  return service({
    url: '/tradeHead/batchClose',
    method: 'post',
    data
  })
}


/**
 * 
 * @param {批量撤销关闭订单--激活订单} data 
 * @returns 
 */
export const batchActivateTradeHead = (data) => {
  return service({
    url: '/tradeHead/batchActivate',
    method: 'post',
    data
  })
}