
<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-header class="no-margin no-padding height-auto display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <nt-form-item label="所属店铺">
          <el-input placeholder="请输入名称查询" v-model:value="queryModel.accountName"></el-input>
        </nt-form-item>
        <nt-form-item label="编号">
          <el-input placeholder="请输入商品标题,属性，SKU" v-model:value="queryModel.accountNo"></el-input>
        </nt-form-item>
        <nt-form-item label="商品简称/有-无">
          <el-input placeholder="请输入备注查询" v-model:value="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item label="商品重量/已-未设置-">
          <el-input placeholder="请输入备注查询" v-model:value="queryModel.remark"></el-input>
        </nt-form-item>
        <nt-form-item>
          <el-button type="primary" size="small" @click="onSearchQueryClick">查询</el-button>
          <el-button size="small" @click="onAddClick">新增</el-button>
        </nt-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
       @expand-change="onExpandChange" :expand-row-keys="expandKeys" row-key="id"
        v-loading="isLoading" @selection-change="onSelectionChange">
        <el-table-column label="#" width="45" type="selection">
        </el-table-column>
        
        
        <el-table-column label="商品图片" width="90" prop="iconUrl">
          <template #default="{row}">
            <el-image :src="row.iconUrl" width="50" height="50"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="商品标题"  prop="title">
          <template #default="{row}">
            <div>{{ row.title }}</div>
            <div>id:{{ row.goodsId }}</div>
            <div @click="onRowExpandClick(row)">展开</div>
          </template>
        </el-table-column>
        <el-table-column label="简称" width="140">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.shortName"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="重量" width="140">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.weight"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="体积" width="140">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.volume"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="市场" width="160">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.marketName" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="档口" width="160">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.position"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="成本价" width="160">
          <template #default="{row}">
            <el-input size="small" v-model="row.goodsExtra.marketPrice"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="{row}">
            <el-button size="small"  @click="onRowSaveClick(row)">保存</el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand" >
          <template #default="scope">
            <div style="padding-left:135px;padding-right:45px;">
              <el-table :data="scope.row.platformGoodsSkuList" border size="small" :show-header="false"  class="tbl-mapping">
                <el-table-column>
                  <template #default="{row}">
                    <div>{{ scope.row.goodsName }}</div>
                    <div>id:{{ scope.row.goodsId }}</div>
                    <div>货号:{{ row.skuId }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="简称" width="140">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.shortName"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="重量" width="140">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.weight"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="体积" width="140">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.volume"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="市场" width="160">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.marketName" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="档口" width="160">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.position"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="成本价" width="160">
                  <template #default="{row}">
                    <el-input size="small" v-model="row.skuExtra.marketPrice"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="120"></el-table-column>
              </el-table>
            </div>
            
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="no-padding margin-top height-auto">
      <el-row type="flex" justify="space-between">
        <div>
          <el-button> 批量修改市场</el-button>
          <el-button> 批量修改档口</el-button>
          <el-button> 批量修改成本价</el-button>
          <el-button @click="onSaveClick"> 批量保存</el-button>
        </div>
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import { getGoodsList,saveGoodsExtra } from '@/service/resource/platform_goods'
import NtMarketColumn from '../nt_market_column'
export default {
  name: "MarketList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    NtMarketColumn
  },
  data() {
    return {
      expandKeys:[]
    }
  },
  methods: {
    async _loadList(params) {
      let res = await getGoodsList(Object.assign({},params));
      res.data?.list.forEach(item=>{
        item.goodsExtra =  item.goodsExtra  || {}
        item.platformGoodsSkuList.forEach(el=>{
          el.skuExtra = el.skuExtra||{}
        })
      })
      console.log('xxxx',res)
      return res;
    },
    async onRowSaveClick(row){
      console.log('xxxx',row)
      let goodsExtra = row.goodsExtra||{}
      let list = [{
        shopId:row.shopId,
        goodsId:row.goodsId,
        shortName:goodsExtra.shortName,
        weight:goodsExtra.weight,
        volume:goodsExtra.volume,
        
        position:goodsExtra.position,
        marketName:goodsExtra.marketName,
        marketPrice:goodsExtra.marketPrice
      }]
      
      row.platformGoodsSkuList.forEach(el=>{
        let skuExtra = el.skuExtra||{}
        list.push({
          shopId:row.shopId,
          goodsId:row.goodsId,
          skuId:el.skuId,
          shortName:skuExtra.shortName,
          weight:skuExtra.weight,
          volume:skuExtra.volume,
          position:skuExtra.position,
          marketName:skuExtra.marketName,
          marketPrice:skuExtra.marketPrice
        })
      })
      this.saveData(list)
      row.isEdit=false
    },
    async saveData(list){
      let res = await saveGoodsExtra({shopId:'1',list:list})
      if(res.code == 200){
        
        this.$message.success("操作成功")
      } else{
        this.$message.error(res.msg || res.message || res.data || '操作失败')
      }
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    onExpandChange(currentRow,allExpandRow){
      this.expandKeys = allExpandRow.map(el=>el.id)
      // console.log('fkingall',rows, expanded)
    },
    onRowExpandClick(row){
      let matchIndex = this.expandKeys.indexOf(row.id)
      if(matchIndex != -1){
        this.expandKeys.splice(matchIndex,1)
      } else {
        this.expandKeys.push(row.id)
      }
    },
    
    async onSaveClick(){
      let list = []
      this.selectedRows.forEach(el=>{
        let goodsExtra = row.goodsExtra||{}
        list.push({
          shopId:el.shopId,
          goodsId:el.goodsId,
          shortName:goodsExtra.shortName,
          weight:goodsExtra.weight,
          volume:goodsExtra.volume,
          
          position:goodsExtra.position,
          marketName:goodsExtra.marketName,
          marketPrice:goodsExtra.marketPrice
        })
        
        el.platformGoodsSkuList.forEach(item=>{
          let skuExtra = item.skuExtra||{}
          list.push({
            shopId:el.shopId,
            goodsId:el.goodsId,
            skuId:item.skuId,
            shortName:skuExtra.shortName,
            weight:skuExtra.weight,
            volume:skuExtra.volume,
            position:skuExtra.position,
            marketName:skuExtra.marketName,
            marketPrice:skuExtra.marketPrice
          })
        })
      })
      // 批量保存
      this.saveData(list)
      // let res = await saveGoodsExtra(list)
      // console.log('xxxxx', res)
    }

    // async onRowSetDefaultClick(record) {
    //   let res = await this.setEntityDefault(this.EnumEntityType.GOODS_SHORT_NAME, { id: record.id })
    //   if (res.code == 200) {
    //     this.$message.success('操作成功')
    //     this.loadList()
    //   } else {
    //     this.$message.error(res.msg || '操作失败')
    //   }
    // },

    // async onRowEnabledClick(record, status) {
    //   let res = await this.updateEntityStatus(this.EnumEntityType.GOODS_SHORT_NAME, { id: record.id, status: status })
    //   if (res.code == 200) {
    //     this.$message.success('操作成功')
    //     this.loadList()
    //   } else {
    //     this.$message.error(res.msg || res.data)
    //   }
    // }
  }
}
</script>
<style scoped lang="less">
  :deep(.el-table__expand-column){
    display: none;
  }
</style>