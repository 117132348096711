<!-- 订单排序规则设置 -->
<template>
  <el-container class="nt-height-100">
    <el-main class=" padding nt-box-panel">
      <div>
        <el-radio-group v-model="checkFlag" style="display:inline-grid;">
          <el-radio value="1">订单导入校验省</el-radio>
          <el-radio value="0">订单导入省不校验</el-radio>
          
        </el-radio-group>
      </div>
      <div>
        <el-button type="primary" @click="onSaveClick">保存</el-button>
      </div>
      <div>免责申明：由于【收件地址】复杂，开启省市不校验，会存在地址误差，请务必检查【收件地址】是否错误，“易打单”不承担因个人未检查而导致快递配送错误造成的损失。</div>
    </el-main>
  </el-container>
</template>
<script>

import {getTenantKvByConfigKey,saveTenantKv} from '@/service/resource/general'
export default {
  name: "IMPORT_SETTING",
  components: {
    
  },

  data() {
    return {
      checkFlag:'1'
    }
  },
  async created(){
    let res = await getTenantKvByConfigKey({parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'IMPORT_PROVICE_CHECK'})
    if(res.code ==200){
      if(res.data){
        this.checkFlag =  res.data.configValue
      }
    }else {
      this.$message.error(res.msg || '获取数据失败')
    }
  },
  methods: {
    async onSaveClick(){
      let postData = {parentKey:'CUSTOM_CONFIG',configType:'PRINT_OPTION',configKey:'IMPORT_PROVICE_CHECK',configValue: this.checkFlag}
      let res = await saveTenantKv(postData)
      if(res.code===200){
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg || '操作失败')
        }
    }
  }
}
</script>