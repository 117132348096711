
<!-- 现金银行统计：
  上期余额 本期收入金额 本期支出金额  本期余额 本期收入笔数 本期支出笔数 -->
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>现金银行统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="searchQuery">
        <el-form-item label="时间范围">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table
        border
        ref="table"
        size="middle"
        height="100%"
        class="nt-rpt-table"
        :data="datasource"
        v-loading="isLoading">
        <!-- 上期余额 本期收入金额 本期支出金额  本期余额 本期收入笔数 本期支出笔数 -->
        <el-table-column label="账户类型"  prop="accountTypeName" width="160">
        </el-table-column>
        <el-table-column label="账户名称"  prop="accountName">
        </el-table-column>
        <el-table-column label="上期余额" width="120" prop="initTotal">
          <template #default="scope">
            {{ (scope.row.initTotal||0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期收入金额" width="120" prop="inTotal">
          <template #default="scope">
            {{ (scope.row.inTotal||0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期支出金额" width="120" prop="outTotal">
          <template #default="scope">
            {{ (scope.row.outTotal||0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期余额" width="120" prop="total">
          <template #default="scope">
            {{ (scope.row.total||0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="本期收入笔数" width="120" prop="inCount"></el-table-column>
        <el-table-column label="本期支出笔数" width="120" prop="outCount"></el-table-column>
          
      </el-table>
    </template>
    <template #footer>
      <el-pagination
        :current-page="this.pageInfo.pageIndex"
        :page-size="this.pageInfo.pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :total="this.pageInfo.total"
        background 
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="onPageIndexChange"
        @size-change="onPageSizeChange"
      />
    </template>
  </nt-rpt-layout>
  
  <el-drawer
    v-model="detailVisible" destroyOnClose size="75%"
    class="nt-resource-dialog"
    title="详情"
    @close="detailVisible=false"
  >
    <account-in-out-list  @cancel="detailVisible =false"  :query="subQuery" :accountId="currentRecord.id" v-if="detailVisible"></account-in-out-list>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
  import {getAccountSummaryList} from '@/service/report/rpt_account'
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import AccountInOutList from './flow'
  import NTDateRange from '@/views/plugin/nt_date_range'
  export default {
    name: "AccountSummary",
    mixins:[DataListMixin],
    components: {
      NtRptLayout,
    NtRptToolbar,
      AccountInOutList,
      NTDateRange
    },
    data () {
      return {
        detailVisible:false,
        toggleSearchStatus:false,
        queryModel: {
          startTime: this.moment().format('YYYY-MM-01'),
          endTime: this.moment().format('YYYY-MM-DD')
        },
        subQuery:{

        },
        // 表头
        columns: [
          {
            title: '#', dataIndex: 'rowIndex', width:40, align:"center",
            customRender:function (t,r,index) {
              return (t !== '合计') ? (parseInt(index) + 1) : t
            }
          },
          {title: '库存流水', dataIndex: 'action', align:"center", width: 60,
            scopedSlots: { customRender: 'action' }
          },
          // {title: 'id', dataIndex: 'id', width: 100},
          {title: '账户名称', dataIndex: 'accountName', width: 140, ellipsis:true},
         
          {title: '期初', dataIndex: 'initTotal', width: 60, ellipsis:true,
            customRender:function(t,r,index){
              return ((r.initTotal||0)/100).toFixed(2)
            }
          },
          {title: '本期发生', dataIndex: 'total', width: 60,
            customRender:function(t,r,index){
              return (r.total/100).toFixed(2)
            }
          },
          {title: '期末余额', dataIndex: 'total', width: 60,
            customRender:function(t,r,index){
              return (((r.initTotal||0) + (r.total||0))/100).toFixed(2)
            }
          }
        ]
      }
    },
    methods: {
      
      async _loadList(params){
        return await await getAccountSummaryList(Object.assign(params,{}))
      },
      searchQuery() {
        this.loadList();
      },
      viewDetailClick(record) {
        this.currentRecord = record;
        this.subQuery = {
          accountId:record.id,
          startTime: this.queryModel.startTime,
          endTime: this.queryModel.endTime
        }
        this.detailVisible = true
      }
    }
  }
</script>

<style scoped>
  @import '~@assets/less/common.less';
</style>
<style scoped>
  .item-info {
    float:left;
    width:30px;
    height:30px;
    margin-left:8px
  }
  .item-img {
    cursor:pointer;
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>