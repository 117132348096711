<template>
  <el-container class="nt-height-100">
    <el-header class="no-padding no-margin height-auto border-bottom" style="background:rgb(240,240,240);">
      <slot name="toolbar"></slot>
    </el-header>
    <el-main class="padding">
      <el-container class="nt-height-100 ">
        <el-header class="height-auto no-padding" >
          <nt-query-panel>
            <slot name="header"></slot>
          </nt-query-panel>
        </el-header>
        <el-main class="no-padding margin-top-4">
          <slot name="body"></slot>
        </el-main>
        <el-footer class="height-auto no-padding margin-top">
          <el-row justify="end">
            <slot name="footer"></slot>
          </el-row>
        </el-footer>
      </el-container>
    </el-main>
    <slot></slot>
  </el-container>
</template>
<script >
import NtQueryPanel from '@/views/plugin/nt_query_panel'
export default {
  data(){
    return {
    }
  },
  components:{
    NtQueryPanel
  },
  methods:{

  }
}
</script>
