import { axios as service } from '@/utils/request'


export const getCategoryList = (data) => {
  return service({
    url: '/platformGoods/category',
    method: 'post',
    data
  })
}

export const getGoodsList = (data) => {
  return service({
    url: '/platformGoods/list',
    method: 'post',
    data
  })
}


export const bindSkuToErpSku = (data) => {
  return service({
    url: '/platformGoods/bindSku',
    method: 'post',
    data
  })
}


// 批量保存商品附加属性
export const saveGoodsExtra = (data) => {
  return service({
    url: '/platformGoods/saveExtra',
    method: 'post',
    data
  })
}


// 提交商品同步请求，获取提交后的任务id
export const submitSyncTask = (data) => {
  return service({
    url: '/platformGoods/syncGoods',
    method: 'post',
    data
  })
}

// 查询同步任务结果
export const queryTaskResult = (data) => {
  return service({
    url: '/platformGoods/queryTaskResult',
    method: 'post',
    data
  })
}