
<template>
  <el-container class="padding nt-height-100" >
    <el-header class="no-margin height-auto no-padding display-flex space-between">
      <el-form inline @keyup.enter.native="onSearchQueryClick" class="no-margin-bottom">
        <el-form-item label="单据编号" :labelCol="labelCol" >
          <el-input placeholder="请输入单据编号" v-model="queryParam.billNo"></el-input>
        </el-form-item>
        <el-form-item label="单据日期">
          <nt-date-range v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"
            start-placeholder="开始时间" end-placeholder="结束时间" dateFormat="YYYY-MM-DD"></nt-date-range>
        </el-form-item>
        <el-form-item label="客户" :labelCol="labelCol" style="width:250px;">
          <el-select placeholder="选择客户" showSearch optionFilterProp="children" v-model="queryParam.companyId">
            <el-select-option v-for="(item, index) in organList" :key="index" :value="item.id">
              {{ item.supplier }}
            </el-select-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
        
      </el-form>
      <div>
        <el-button  @click="onAddClick" type="primary">新增</el-button>
      </div>
    </el-header>
    <el-main class="no-padding margin-top">
      <el-table height="100%" border ref="table" class="nt-bill-table-list" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="#" width="50" type="index" fixed="left">
        </el-table-column>
        <el-table-column label="单据编码" width="140">
          <template #default="{row}">
            <a @click="onBillNoClick(row)" class="margin-top">{{ row.billNo }}</a>
          </template>
        </el-table-column>
        <el-table-column label="往来单位" width="220" prop="companyName">
        </el-table-column>
        <el-table-column label="单据日期" width="100" align="center" prop="billTime">
          <template #default="{row}">
            {{ formatShortDate(row.billTime) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="制单人" prop="userName" width="120"></el-table-column> -->
        <el-table-column label="财务人员" prop="handsPersonName" width="120"></el-table-column>
        <el-table-column label="期初应付" width="100">
          <template #default="{row}">
            {{ row.total }}
          </template>
        </el-table-column>
        <el-table-column label="待核销金额" prop="" width="100">
          <template #default="{row}">
            {{ row.total - (row.settleTotal||0) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" ></el-table-column>
        <nt-operation-column label="操作" fixed="right" width="55" align="center" 
          :confirm="{DELETE:'是否确认删除?',VERIFY:'确定审核当前单据',UNVERIFY:'确定反审当前单据'}"
           :hidden="['VERFIFY','UNVERIFY','ENABLE','DISABLE']" 
          @command="onColumnOperationAction">
          <template #extra>
          </template>
        </nt-operation-column>
        
      </el-table>
    </el-main>
    <el-footer class="height-auto no-padding margin-top">
      <el-row justify="end">
        <el-pagination
          :current-page="this.pageInfo.pageIndex"
          :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]"
          :total="this.pageInfo.total"
          background 
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="onPageIndexChange"
          @size-change="onPageSizeChange"
        />
      </el-row>
    </el-footer>
  </el-container>

  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="750px" class="nt-resource-dialog" title="期初应付"
    @cancel="isDetailVisible = false">
    <detail-page :height="550" @success="onDetailSaveSuccessHandler" :billType="billType" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
  </template>
<script>

import detailPage from './detail'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtDateRange from '@/views/plugin/nt_date_range'

import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "MoneyOutList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtDateRange,
    NtFormItem
  },
  data() {
    return {
      currentRecord: {},
      detailVisible: false,
      billType: "PAY_INIT",
      queryParam: {
        billNo: "",
        companyId: ""
      },
    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.PAYMENT_INIT, Object.assign({ billType: this.billType }, params));
    },
    onAddClick() {
      this.currentRecord = {}
      this.isDetailVisible = true
    },
    async onRowDeleteClick(record) {
      let res = await this.deleteEntity(this.EnumEntityType.PAYMENT_INIT, { id: record.id })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || '删除失败')
      }
    },
    onBillNoClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
      this.viewOnly = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
      this.viewOnly = false
    },

    onDetailSaveSuccessHandler() {
      this.isDetailVisible = false
      this.loadList()
    },
    batchDel() {
      //批量删除
    },
    onDateOk() { },
    onSearchQueryClick() {
      this.loadList()
    },
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onDateChange() { },
    handleToggleSearch() { },
    async batchSetStatus(status) {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！');
        return;
      } else {
        let _this = this
        this.$confirm({
          title: "确认操作",
          content: "是否操作选中数据?",
          async onOk() {
            let res = await _this.batchUpdateEntityStatus(_this.EnumEntityType.PAYMENT_INIT, { status: status, ids: _this.selectedRowKeys })
            if (res.code === 200) {
              _this.$message.success('操作成功')
              _this.loadList()
            } else {
              _this.$message.error(res.msg || res.data || '操作失败')
            }
          }
        });
      }
    },
  }
}
</script>
