<template>
  <el-row :gutter="8">
    <el-col style="flex-basis:32px;">
      <el-avatar :size="32" shape="square" :src="goods.goodsThumb" />
    </el-col>
    <el-col class="flex-auto">
      <div class="goods-title" :title="goods.goodsName" style="line-height:16px;height:16px;font-size:14px;">
        <el-text line-clamp="1">{{ goods.goodsName }}</el-text>
      </div>
      <div class="goods-price">{{ goods.price }} *{{ goods.quantity }}</div>
    </el-col>
  </el-row>
</template>
<script >

export default {
  data() {
    return {
    }
  },
  components: {},
  props: {
    goods:{
    }
  },
  methods: {

  }
}
</script>
