<template>
  <el-container class="padding nt-height-100 nt-bg-page">
    <el-aside width="250px" class="margin-right nt-box-panel nt-bg-white" v-spliter-directive>
      <dict-type @change="onDictTypeChange"></dict-type>
    </el-aside>
    <el-main class="padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="height-auto margin-bottom">
          <el-button @click="onAddClick" type="primary" :disabled="!parentKey">新增</el-button>
        </el-header>
        <el-main class="no-padding">
          <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
            v-loading="isLoading">
            <el-table-column label="#" width="60" type="index"></el-table-column>
            
            <el-table-column label="枚举编码" width="220" prop="configKey">
            </el-table-column>
            <el-table-column label="枚举值" width="200" prop="configValue">
            </el-table-column>
            <el-table-column label="备注" prop="remark">
            </el-table-column>
            
            <el-table-column label="状态" width="120" prop="enabled" align="center">
              <template #default="scope">
                <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
                <el-tag v-if="!scope.row.enabled" type="danger">禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
              <template #default="scope">
                <el-space>

                  <a @click="onRowEnabledClick(scope.row, 1)" v-if="!scope.row.enabled">启用</a>
                  <a @click="onRowEnabledClick(scope.row, 0)" v-if="scope.row.enabled">禁用</a>
                  <a @click="onRowEditClick(scope.row)">编辑</a>
                  <el-popconfirm title="确定删除吗?" @confirm="() => onRowDeleteClick(scope.row)">
                    <a>删除</a>
                  </el-popconfirm>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <!-- <el-footer class="height-auto no-padding margin-top">
          <el-row justify="end">
            <el-pagination
            :current-page="this.pageInfo.pageIndex"
            :page-size="this.pageInfo.pageSize"
            :page-sizes="[10, 30, 50, 100]"
            :total="this.pageInfo.total"
            background 
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="onPageIndexChange"
            @size-change="onPageSizeChange"
          />
          </el-row>
          
        </el-footer> -->
      </el-container>
    </el-main>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    <detail-page @success="onDetailSaveSuccessHandler" :parent-key="parentKey" @cancel="isDetailVisible = false" :entityId="currentRecord.id"
      v-if="isDetailVisible"></detail-page>
  </el-dialog>
</template>

<script>

import detailPage from './detail/'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import {getSysKvListByParent} from '@/service/resource/general'
import NtFormItem from '@/views/plugin/nt_form_item'
import DictType from './dict_type'
export default {
  name: "PersonList",
  mixins: [DataListMixin],
  components: {
    detailPage,
    NtFormItem,
    DictType
  },
  data() {
    return {
      parentKey:null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.parentKey){
        return null
      }
      return await getSysKvListByParent({parentKey:this.parentKey});
    },
    async onRowDeleteClick(record) {
      // let res = await this.deleteEntity(this.EnumEntityType.STAFF, { id: record.id })
      // if (res.code == 200) {
      //   this.$message.success('操作成功')
      //   this.loadList()
      // } else {
      //   this.$message.error(res.msg || '删除失败')
      // }
    },
    onSearchClick() {
      this.resetPage = true
      this.loadList()
    },
    onSearchResetClick() {
      this.resetPage = true
      this.queryModel = {}
      this.loadList()
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onAddClick() {
      this.currentRecord = {},
      this.isDetailVisible = true
    },
    onRowEditClick(record) {
      this.currentRecord = record
      this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.STAFF, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    onDictTypeChange(node){
      this.parentKey = node.configKey
      // 清空用户列表查询条件
      this.loadList()
    }
  }
}
</script>