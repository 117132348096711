<template>
  <el-form v-bind="$attrs" :key="`form_${$attrs?.id}`">
    <nt-form-item :prop="$attrs.prop" :id="`form_item_${$attrs?.id}`" :rules="$attrs.rules" :required="$attrs.required">
      <slot></slot>
    </nt-form-item>
  </el-form>
</template>
<script >
import NtFromItem from './nt_form_item.vue'
export default {
  data(){
    return {
    }
  },
  components:{NtFromItem},
  methods:{}
}
</script>
