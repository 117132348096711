<template>
  <el-conainer class="no-padding" style="height:500px">
    <el-main  class="no-padding" >
      <el-table :data="datasource" class="nt-resource-table">
        <el-table-column label="模版名称" prop="templateName">
        </el-table-column>
        <el-table-column label="页面宽度" width="120" prop="pageWidth">
        </el-table-column>
        <el-table-column label="页面高度" width="120" prop="pageHeight">
        </el-table-column>
        <el-table-column label="操作" width="120" >
          <template #default="{row}">
            <el-button @click="_=>onTemplateChooseHandler(row)" size="small" >使用模版</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer style="background-color: #fff;padding:12px;z-index: 99;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
    
  </el-conainer>
</template>

<script>
  import {DataListMixin} from '@/utils/data_list_mixin.js'
  import {querySystemTemplateList} from '@/service/print_order/template'
  
  
  export default {
    name: "template-list",
    mixins:[DataListMixin],
    components:{},
    data () {
      return {
        isDesignlVisible:false,
        isTemplateChooseVisible:false,
        selectedRecord:null,
      }
    },
    props:{
      templateType:{ //模版类型： 单据模版 BILL_TYPE_TEMPLTE  面单模版 WAYBILL_TEMPLATE  拣货模版PICKUP_TEMPALTE
        type:String,
        required:true
      },
      billType:{ // 业务单据类型
        type:String
      },
      deliveryId:{ // 物流公司
        type:String
      }
    },
    methods:{
      async _loadList(params){
        let query = Object.assign({templateType:this.templateType,billType:this.billType,deliveryId:this.deliveryId},{},params)
        return await querySystemTemplateList(query);
      },
      
      _loadFinished(){

      },
      
      onTemplateChooseHandler(sysTemplate){
        // 添加模版，并刷新列表
        this.$emit('onChoose', sysTemplate)
      }
    }
  }
</script>
