<!-- 
 下拉选择框 + 时间范围
  -->
<template>
  <div class="nt-custom display-flex" :style="{width:(width||288)+'px',columnGap:'0px'}">
    <!-- 下拉框为时间类型： 如下单时间，购买时间等 -->
    <el-select :class="`${getHighlightClass('dropdown')}`" v-model="qType" v-bind="$attrs" :placeholder="tips" size="small" style="width:100px;align:center;" @change="onQueryTypeChange" >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">{{item.label}}</el-option>
    </el-select>
    <!-- 编辑后的查询范围 -->
      <el-date-picker
        v-model="startValue"
        type="date"
        :class="`no-padding flex-auto first ${getHighlightClass('start')}`"
        placeholder="起"
        size="small"
        @change="onDateChange"
      />
      <span>-</span>
      <el-date-picker
        v-model="endValue"
        type="date"
        :class="`no-padding flex-auto ${getHighlightClass('end')}`"
        placeholder="止"
        size="small"
        @change="onDateChange"
      />
  </div>
  
</template>

<script>
  import * as dayjs from 'dayjs'
  export default {
    data () {
      return {
        qType: this.modelValue?.queryType, // this.queryType,
        tips: this.placeholder,
        startValue:this.modelValue?.startValue || this.parseDefault(this.defaultStartValue), // 解析默认值表里
        endValue: this.modelValue?.endValue || this.parseDefault(this.defaultEndValue), // 解析默认值表里
        defaultValue: Object.assign({},this.modelValue) // 默认值
      }
    },
    props:{
      modelValue:{},
      options:{
        type:Array,
        default:_=>[]
      },
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      defaultStartValue:{
      },
      defaultEndValue:{
      }
    },
    watch:{
      modelValue:function(){
        this.startValue = this.modelValue?.startValue
        this.endValue = this.modelValue?.endValue
        this.qType = this.modelValue?.queryType
      }
    },
    created(){
      let matchItem = this.options.find(item=>item.value==this.qType)
      this.tips = matchItem?.placeholder
    },
    mounted(){
      if(!this.modelValue && (this.defaultStartValue || this.defaultEndValue)){
        this._triggerChange() // 存在任何默认值配置，触发事件通知v-model发生变化
      }
    },
    methods:{
      parseDefault(str){
        //今天 $today  昨天$yesterday  本月1号 $startDayOfMonth  本月最后一天 $lastDayOfMonth， 前三天 $day3  前七天 $day7
        if(str == '$today'){
          return dayjs().format('YYYY-MM-DD')
        } else if(str =='$yesterday'){
          let dt = dayjs().subtract(1, 'day')
          return dt.format('YYYY-MM-DD')
        }else if(str =='$startDayOfMonth'){
          const dt = dayjs().startOf('month')
          return dt.format('YYYY-MM-DD')
        }else if(str =='$lastDayOfMonth'){
          const dt = dayjs().endOf('month')
          return dt.format('YYYY-MM-DD')
        }
        else if(str =='$day3'){
          let dt = dayjs().subtract(2, 'day')
          return dt.format('YYYY-MM-DD');
        }
        else if(str =='$day7'){
          let dt = dayjs().subtract(6, 'day')
          return dt.format('YYYY-MM-DD');
        }
        return str
      },
      getHighlightClass(type){
        if(!this.defaultValue){
          return ""
        }
        if(type == 'start' && this.startValue){
          return this.defaultValue?.startValue != dayjs(this.startValue).format('YYYY-MM-DD')? 'highlight':''
        }
        if(type == 'end' && this.endValue){
          return this.defaultValue?.endValue != dayjs(this.endValue).format('YYYY-MM-DD')? 'highlight':''
        }
        if(type=='dropdown'){
          return this.defaultValue?.queryType != this.qType? 'highlight':''
        }
        
      },
      onQueryTypeChange(val){
        let matchItem = this.options.find(item=>item.value==this.qType)
        this.tips = matchItem?.placeholder
        this._triggerChange()
      },
      onDateChange(){
        this._triggerChange()
      },
      _triggerChange(){
        /*
        queryKey:'QUERY_TIME_RANGE',
        queryType:'ORDER_TIME/PAYMENT_TIME/SEND_TIME/',
        valueType:'SINGLE/RANGE'
        value:'单值类型使用',
        startValue: '区间开始值'
        endValue: '区间开始值'
        */
        let data = {
          queryType: this.qType
        }
        if(this.startValue){
          data.startValue = dayjs(this.startValue).format('YYYY-MM-DD')
        }
        if(this.endValue){
          data.endValue = dayjs(this.endValue).format('YYYY-MM-DD')
        }
        this.$emit('update:modelValue',data)
      }
    }
}
</script>
<style lang="less" scoped>
  .nt-custom{
    :deep(.el-select__wrapper){
      padding:3px 4px;
      background-color: var(--el-fill-color) !important;
      box-shadow: none;
      border:1px solid var(--el-border-color);
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    :deep(.el-date-editor):nth-of-type(0){
      border:2px solid red;
    }
    :deep(.highlight) {
      .el-input__wrapper,.el-select__wrapper{
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        .el-input__inner,.el-select__selected-item{
          font-weight: bold;
        }
      }
    }
    
    :deep(.first){
      .el-input__wrapper{
        box-sizing: border-box;
        box-shadow: none;
        border:1px solid var(--el-border-color);
        border-left:0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        &.is-focus{
          border:none;
          box-shadow:0 0 0 1px var(--el-input-focus-border-color) inset;
        }
      }
    }
    :deep(.el-input__wrapper){
      .el-input__prefix{
        display: none;
      }
    }
    
  }
</style>