<template>
  <el-tree style="height:100%;width:100%;"
    :data="datasource"
    :props="props"
    :load="loadNode"
    :indent="8"
    lazy
    @node-click="onNodeClick"
  />
</template>
<script >

import {getSysKvListByParent} from '@/service/resource/general'
import {allBindingShop} from '@/service/resource/platform_shop'
import { DataListMixin } from '@/utils/data_list_mixin.js'
export default {
  mixins: [DataListMixin],
  data(){
    return {
      datasource:[],
      props: {
        label: 'configValue',
        isLeaf: 'leaf',
      }
    }
  },
  components:{},
  async created(){
    let res = await getSysKvListByParent({parentKey:'SYS_E_PLATFORM'}); // 获取数据字典类型列表
    this.datasource = res.data
  },
  methods:{
    onNodeClick(node){
      if(node.shopName){ // 点击店铺节点
        this.$emit('change',node)
      }
    },
    async loadNode(node,resolve,reject){
      if(node.level===0) return resolve([])
      let res =  await allBindingShop( {platform:node.data.configKey});
      res.data?.forEach(item => {
        item.configValue = item.shopName
        item.leaf = true
      })
      return resolve(res.data)
    }
  }
}
</script>
