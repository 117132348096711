import { axios as service } from '@/utils/request'


export const getConfig = (data) => {
  return service({
    url: '/user/config',
    method: 'post',
    data
  })
}


/*
  根据授权码直接登录系统
 */
export const loginByCode = (code) => {
  return service({
    url: '/user/loginByCode?code='+code,
    method: 'get'
  })
}
