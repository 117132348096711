<template>
  <el-container class="no-padding nt-height-100 nt-bg-page" style="overflow-y:auto;">
    <el-header class="no-padding no-margin height-auto">
      <el-menu mode="horizontal" class ="print-header no-border" :ellipsis="false" 
      style="--el-menu-item-font-size:16px;--el-menu-horizontal-height:50px;
        --el-menu-bg-color: var(--el-color-primary-dark-2);
        --el-menu-text-color: #fff;" router>
        <span class="padding" style="color:#fff;font-size:18px;font-weight:bold;line-height:100%;width:160px;align-content:center;">精打妙算</span>
        <el-menu-item index="/print">首页</el-menu-item>
        <el-menu-item index="/print/index">打印发货</el-menu-item>
        <el-menu-item index="/print/pick">备货单</el-menu-item>
        <el-sub-menu index="4" >
          <template #title>常用工具</template>
          <div class="display-flex" style="column-gap:24px;align-items:flex-start;">
            <div class="padding">
              <div style="color:#666;" class="padding-left margin-bottom-4">发货</div>
              <el-menu-item index="/print/preDispatch">预发货</el-menu-item>
              <el-menu-item index="/print/scanSend">扫描发货</el-menu-item>
              <el-menu-item index="/print/scanPickCode">扫描拿货标签</el-menu-item>
              <!-- <el-menu-item index="importSend">导入订单发货</el-menu-item> -->
            </div>
           
            <div class="padding">
              <div style="color:#666;"  class="padding-left margin-bottom-4">统计查询</div>
              <el-menu-item index="/print/waybillHistory">面单查询</el-menu-item>
              <el-menu-item index="/print/abnormalOrder">异常订单</el-menu-item>
              <el-menu-item index="/print/sendHistory">发货记录</el-menu-item>
              <!-- <el-menu-item index="4-7">快递对账单</el-menu-item> -->
            </div>
            <div class="padding">
              <div style="color:#666;"  class="padding-left margin-bottom-4">其他设置</div>
              <el-menu-item index="/print/binding">店铺绑定</el-menu-item>
              <el-menu-item index="4-13">账号管理</el-menu-item>
              <el-menu-item index="4-13">操作日志</el-menu-item>
            </div>
          </div>
        </el-sub-menu>
        
        <el-sub-menu index="6" >
          <template #title>基础设置</template>
          <div class="display-flex" style="column-gap:24px;align-items:flex-start;">
            <div class="padding">
              <div style="color:#666;" class="padding-left margin-bottom-4">模板设置</div>
              <el-menu-item index="/print/waybillTemplate">快递模板</el-menu-item>
              <el-menu-item index="/print/pickTemplate">备货单模板</el-menu-item>
              <el-menu-item index="/print/dispatchTemplate">发货单模板</el-menu-item>
              <el-menu-item index="/print/pickingCodeTemplate">拣货标签模板</el-menu-item>
            </div>
            <div class="padding">
              <div style="color:#666;"  class="padding-left margin-bottom-4">发货设置</div>
              <el-menu-item index="/print/senderAddress">发货人</el-menu-item>
              <el-menu-item index="/print/deliveryConfig">物流匹配</el-menu-item>
              <el-menu-item index="/print/pickingRule">拣货码规则</el-menu-item>
            </div>
            <div class="padding">
              <div style="color:#666;"  class="padding-left margin-bottom-4">拦截设置</div>
              <el-menu-item index="/print/abnormalAddress">异常地址</el-menu-item>
              <el-menu-item index="/print/rejectArea">不可达地区</el-menu-item>
              <el-menu-item index="/print/farArea">偏远地区高亮</el-menu-item>
            </div>
          </div>
        </el-sub-menu>
        <el-menu-item index="/print/extra">商品管理</el-menu-item>
       
        <span class="flex-auto" index="5"></span>
        <el-sub-menu index="2-4">
          <template #title>
            <el-icon><Avatar /></el-icon>
            <span>楚林企业店</span>
            <el-button link size="small" @click="onMonitorClick">监控</el-button>
          </template>
          <div class="padding-left padding-right">
            <div><span>用户昵称：楚林企业店 </span><el-button link size="small" type="primary">编辑</el-button></div>
            <div>服务有效期：2022-10-10</div>
            <div class="border-top align-center">
              <el-button link type="primary" size="small">退出登录</el-button>
            </div>
          </div>
        </el-sub-menu>
        <!-- <el-menu-item index="2"><el-icon><Avatar /></el-icon>楚林企业店</el-menu-item> -->
      </el-menu>

    </el-header>
    <el-main class="no-padding">
      <div :class="`nt-height-100 route-container`">
        <router-view :key="$route.fullPath" v-if="isReady"/>
      </div>
    </el-main>
  </el-container>
  <el-dialog v-model="isMonitorVisible" :close-on-click-modal="false" destroyOnClose width="95%"  title="监控"
    @cancel="isMonitorVisible = false">
    <MonitorPage @cancel="isMonitorVisible = false"
      v-if="isMonitorVisible"></MonitorPage>
  </el-dialog>
</template>

<script>
import { useCainiaoPrintServiceStore } from '@/piana/cainiao_printer_service.js'
import { useWeixinPrintServiceStore } from '@/piana/weixin_printer_service.js'
import {LoginUserStore} from '@/piana/login_user_service'
import {loginByCode} from '@/service/user'
import { ACCESS_TOKEN,USER_ID,USER_LOGIN_NAME} from '@/store/mutation-types'
import MonitorPage from '@/views/print_order/system_monitor'

export default {
  name: 'PrintLayout',
  components: {
    MonitorPage
  },
  data() {
    return {
      isReady:false,
      isMonitorVisible:false
    }
  },
  provide() {
    return {
    }
  },
  computed: {
  },
  async created() {
    // 进入打印模块后，进行数据初始化
    this.isReady = false
    if(this.$route.query.code){
      // 通过授权获取登录session
      let res = await loginByCode(this.$route.query.code)
      // console.log('xxxxx',res)
      if(res.code == 200){
        // 登录异常，跳转至登录页面
        // 登录成功后更新本地token 缓存
        $app.ls.set(USER_ID, res.data.userId, 7 * 24 * 60 * 60 * 1000);
        $app.ls.set(USER_LOGIN_NAME, res.data.userName, 7 * 24 * 60 * 60 * 1000);
        $app.ls.set(ACCESS_TOKEN, res.data.token, 7 * 24 * 60 * 60 * 1000)
      }
      // 授权失败，跳转至登录页面
    }
    
    // 自动识别打印机组件--优先获取菜鸟打印服务
    let isCnConnected  = await useCainiaoPrintServiceStore().connect((val)=>{},'first').catch(e=>{
      // console.log('xxxx',e)
      return false
    })
    let  isWxConnected = await useWeixinPrintServiceStore().connect((val) => {}, 'first').catch(e=>{
      // console.log('xxxx',e)
      return false
    })
    if (isWxConnected) {
      // 成功连接 执行获取打印机 菜鸟
      // console.log('视频号打印组件已连接')
      useCainiaoPrintServiceStore().send(JSON.stringify({
        "cmd": "getPrinters",
        "requestID": "123458976",
        "version": "1.0"
      }))

    }
    if (isCnConnected) {
      // 视频号组件--获取打印机指令
      // console.log('菜鸟打印组件已连接')
      useWeixinPrintServiceStore().send(JSON.stringify({
        requestID: 'xxxx',
        command: 'getPrinterList'
      }))

    }

    await LoginUserStore().loadTenentShop()
    LoginUserStore().setPrinterList(useCainiaoPrintServiceStore().printerList)
    this.isReady = true


  },
  methods: {
    onMonitorClick(){
      this.isMonitorVisible=true
    },
  }
}
</script>
<style lang="less" scoped>
  .print-header{
    :deep(.el-menu-item.is-active),:deep(.el-sub-menu.is-active) {
      border-bottom:0px !important;
      background:var(--el-menu-hover-bg-color) !important;
    }
    :deep(.el-sub-menu.is-active){
      background:var(--el-menu-hover-bg-color) !important;
      .el-sub-menu__title{
        border-bottom:0px !important;
      }
      
    }
    :deep(.el-sub-menu__title):hover{
      background:var(--el-menu-hover-bg-color) !important;
    }
    
  }
</style>
