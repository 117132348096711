<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding nt-box-panel nt-bg-white">
        <nt-form-item  label="枚举编码" prop="configKey" :rules="formValidator('required','请输入枚举编码')">
          <el-input placeholder="请输入枚举编码" v-model="formModel.configKey"  />
        </nt-form-item>
        
        <nt-form-item  label="枚举值" prop="configValue" :rules="formValidator('required','请输入枚举值')" class="no-margin-bottom">
          <el-input placeholder="请输入枚举值"  v-model="formModel.configValue" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
  
</template>
<script>

  import { DataEditMixin } from '@/utils/data_edit_mixin'
  import {saveTenantKv,getTenantKv} from '@/service/resource/general'
  import NtFormItem from '@/views/plugin/nt_form_item'
  export default {
    name: "StaffModal",
    mixins: [DataEditMixin],
    components:{NtFormItem},
    data () {
      return {
        igoreBillNo:true // 基类控制忽略生成单据号
      }
    },
    props:{
      entityId:{
        type:String
      },
      parentKey:{
        type:String,
        required:true
      }
    },
    created () {
    },
    methods: {
      async _loadEntity(){
        if (this.entityId) {
          return await getTenantKv({id: this.entityId})
        }
      },
      async _checkSubmit(){
        // 数据校验
        let isValid = await this.$refs.frmEdit.validate().catch(_=>false)
        
        if(!isValid) return false //校验成功返回的是具体的字段+值
        
        return true
      },
      async _saveEntity(){
        let postData = Object.assign({id: this.entityId,configType:'DICT'},this.formModel)
        postData = JSON.parse(JSON.stringify(postData))
        postData.parentKey = this.parentKey
        return saveTenantKv( postData)
      },
    }
  }
</script>