
<template>
  <el-container class="padding nt-height-100 relative" >
    <el-aside class="margin-right-12 nt-box-panel padding-8 nt-bg-white" width="250px" style="overflow: hidden;" v-spliter-directive>
      <nt-dict-tree @change="onPlatformClick" parent-key="SYS_E_PLATFORM"></nt-dict-tree>
    </el-aside>
    <el-main class="padding nt-box-panel">
      <el-container class="nt-height-100">
        <el-header class="no-margin no-padding align-right height-auto">
          <el-row type="flex" justify="space-between">
            <el-form inline @keyup.enter.native="onSearchQueryClick">
              <nt-form-item label="名称" >
                <el-input placeholder="请输入名称查询" v-model="queryModel.storeName"></el-input>
              </nt-form-item>
              <nt-form-item>
                <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
                <el-button style="margin-left: 8px" @click="onSearchResetClick">重置</el-button>
              </nt-form-item>
              
            </el-form>
            <div class="display-flex">
              <div>
                <span v-if="authCode">{{ authCode }}</span>
                <el-icon v-if="authCode" title="复制授权码" @click.stop="onCopyClick(authCode)"><CopyDocument /></el-icon>
                <!-- <span>我的授权码</span> -->
                <el-button link size="small" type="primary" @click="onCreateCodeClick">生成</el-button>
              </div>
              
              <el-button type="danger" @click="isAuthCodeVisible=true"> 通过授权码绑定 </el-button>
              <el-button type="primary"> 去授权-下拉选择</el-button>
            </div>
            
          </el-row>
        </el-header>
        <el-main class="no-padding">
          <el-table height="100%" border ref="table" class="nt-resource-table" :data="datasource"
            v-loading="isLoading">
            <el-table-column label="#" width="50" type="index" fixed="left">
            </el-table-column>
            <el-table-column label="店铺id" width="100" prop="id"></el-table-column>
            <el-table-column label="店铺名称" width="220" prop="shopName"></el-table-column>
            <el-table-column label="logo" width="80" prop="address"></el-table-column>
            <el-table-column label="店铺二维码" width="220" prop="qrCode"></el-table-column>
            <el-table-column label="appId" width="100" prop="appId"></el-table-column>
            <el-table-column label="备注" width="220" prop="remark"></el-table-column>
            <el-table-column label="所属平台" width="220" prop="platform"></el-table-column>
            <el-table-column label="授权时间" width="100" prop="createTime"></el-table-column>
            <el-table-column label="服务到期时间" width="160" prop="createTime"></el-table-column>
            <el-table-column label="是否启用" width="100" prop="enabled">
              <template #default="scope">
                <el-tag v-if="scope.row.enabled" type="success">启用</el-tag>
                <el-tag v-if="!scope.row.enabled" type="warning">禁用</el-tag>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="160" fixed="right">
              <template #default="scope">

                <el-space spacer="|" style="opacity:.8;">
                  <!-- <el-button link @click="onRowEditClick(scope.row)">编辑</el-button>
                  <el-popconfirm title="确定删除?" @confirm="onRowDeleteClick(scope.row)">
                    <template #reference>
                      <el-button link :disabled="scope.row.status == '1'" type="danger">删除</el-button>
                    </template>
                  </el-popconfirm> -->
                  <a @click="onRowEnabledClick(scope.row, 1)" v-if="!scope.row.enabled">启用</a>
                  <a @click="onRowEnabledClick(scope.row, 0)" v-if="scope.row.enabled">禁用</a>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <!-- <el-footer class="height-auto margin-top">
          <el-row type="flex" justify="end">
            <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
              :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
              layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
              @size-change="onPageSizeChange" />
          </el-row>
        </el-footer> -->
      </el-container>
    </el-main>
  </el-container>
  <el-dialog v-model="isDetailVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isDetailVisible = false">
    
  </el-dialog>

  <el-dialog v-model="isAuthCodeVisible" :close-on-click-modal="false" destroyOnClose width="650px" class="nt-resource-dialog" title="详情"
    @cancel="isAuthCodeVisible = false">
    <div class="padding">
      <el-input v-model="shopAuthCode"></el-input>
    </div>
    <template #footer>
      <div class="padding">
        <el-button size="small" type="info" @click="isAuthCodeVisible=false">取消</el-button>
        <el-button size="small" type="primary" @click="onConfirmClick">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtDictTree from '@/views/plugin/nt_dict_tree'
import {createAuthCode,bindAuthCode} from '@/service/resource/tenant'
import {allBindingShop} from '@/service/resource/platform_shop'
export default {
  name: "shopList",
  mixins: [DataListMixin],
  components: {
    NtFormItem,
    NtDictTree
  },
  data() {
    return {
      authCode:null,
      shopAuthCode:null, // 输入框授权码
      isAuthCodeVisible:false,
      selectedPlatform: null
    }
  },
  methods: {
    async _loadList(params) {
      if(!this.selectedPlatform) return
      return await allBindingShop( Object.assign({},{platform:this.selectedPlatform}, params));
    },
    
    onSearchQueryClick() {
      this.resetPage = true
      this.loadList()
    },
    
    onSearchResetClick() {
      this.queryModel = {}
      this.resetPage = true
      this.loadList(true)
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },

    async onCreateCodeClick(){
      let res = await createAuthCode({})
      if(res.code == 200){
        this.authCode = res.data
        navigator.clipboard.writeText(this.authCode).then(() => {
          this.$message.success('授权码已复制')
        })
      } else {
          this.$message.error(res.msg || '删除失败')
        }
    },

    onAddClick() {
      this.currentRecord = {},
        this.isDetailVisible = true
    },
    async onRowEnabledClick(record, status) {
      let res = await this.updateEntityStatus(this.EnumEntityType.PLATFORM_SHOP, { id: record.id, status: status })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
    async onPlatformClick(dict){
      this.selectedPlatform = dict.configKey
      this.loadList()
    },
    onCopyClick(text){
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success('授权码已复制')
      })
    },
    async onConfirmClick(){
      if(!this.shopAuthCode){
        this.$message.error('请输入授权码')
        return
      }
      let res = await bindAuthCode({code:this.shopAuthCode})
      if (res.code == 200) {
        this.isAuthCodeVisible =false
        this.$message.success('操作成功')
        this.loadList()
      } else {
        this.$message.error(res.msg || res.data)
      }
    },
  }
}
</script>