
<template>
  <nt-rpt-layout>
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>采购订单统计</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      
    </template>
    <template #body>
      
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  <el-container class="padding-8" style="height:100%;background:#fff;">
    <el-header class="no-margin" style="height:auto;padding-bottom:0px;">
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="仓库">
          <nt-dropdown-grid configKey="store" v-model="queryModel.storeId"
              placeholder="请选择仓库" :searchable="true"
            :iconSearch="false"  style="width:100%;"></nt-dropdown-grid>
        </el-form-item>
        <el-form-item label="单据时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime" format="YYYY-MM-DD"
            :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="no-padding">
      <el-table border ref="table" size="middle" rowKey="id" :data="datasource" v-loading="isLoading">
        <nt-bill-no-column label="单据编码" width="150"  class-name="no-padding"></nt-bill-no-column>
        <el-table-column label="单据类型" prop="billType" width="100">
          <template #default="scope">
          {{ this.EnumBillType[scope.row.billType] }}
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName">
        </el-table-column>
        <el-table-column label="商品" prop="goodsName">
        </el-table-column>
        <el-table-column label="单位" prop="unitName"  width="60">
        </el-table-column>

        <el-table-column label="仓库" prop="storeName">
        </el-table-column>
        <el-table-column label="单据日期" width="110" align="center" prop="billTime">
          <template #default="scope">
            {{formatDate(scope.row.billTime,'YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="quantity" width="80">
        </el-table-column>
        <el-table-column label="单价" prop="price" width="80">
          <template #default="scope">
            {{ ((scope.row.price) / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="税率" prop="taxRate" width="80">
          <template #default="scope">
            {{ (scope.row.taxRate / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="税额" prop="taxTotal" width="80">
          <template #default="scope">
            {{ (scope.row.price * scope.row.taxRate / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="出库时间" width="180" prop="createTime" class="no-padding">
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="total, sizes, prev, pager, next, jumper" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>
  <BillViewerPage ref="refViewer"></BillViewerPage>
</template>
<script>
import { getOutDetailList } from '@/service/report/rpt_stock'
// import BillViewerPage from '@/views/dynamic_bill_viewer' // 业务单据查看统一入库页
import { DataListMixin } from '@/utils/data_list_mixin.js'
// import { getEntityList } from '@/service/resource/general'
// import { EnumEntityType, EnumBillType } from '@/utils/enum_utils'
import { queryMaterialCategoryTreeList } from '@/api/api'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "outDetail",
  mixins: [DataListMixin],
  components: {
    NTDateRange
  },
  data() {
    return {
      detailVisible: false,
      toggleSearchStatus: false,
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      currentWeight: 0,
      storeSelected: [],
      storeList: [],
      categoryTree: [],

    }
  },
  async created() {
  },
  mounted() {
  },
  methods: {
    loadTreeData() {
      let that = this;
      let params = {};
      params.id = '';
      queryMaterialCategoryTreeList(params).then((res) => {
        if (res) {
          that.categoryTree = [];
          for (let i = 0; i < res.length; i++) {
            let temp = res[i];
            that.categoryTree.push(temp);
          }
        }
      })
    },
    async _loadList(params) {
      return await getOutDetailList(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
  }
}
</script>

