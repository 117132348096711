<template>
  <el-form :model="formModel" ref="frmEdit" label-width="80" v-loading="isLoading">
    <el-container class="padding-16">
      <el-main class="padding-16 nt-box-panel nt-bg-white">
        <nt-form-item label="收货人" prop="userName" :rules="formValidator('required', '请输入名称')">
          <el-input placeholder="请输入收货人" v-model="formModel.userName" />
        </nt-form-item>
        <nt-form-item label="手机号">
          <el-input placeholder="请输入手机号" v-model="formModel.cellPhone" />
        </nt-form-item>
        <nt-form-item label="联系电话">
          <el-input placeholder="请输入联系电话" v-model="formModel.phone" />
        </nt-form-item>
        <nt-form-item  label="所属区域" >
          <nt-area-cascade ref="refRegion" v-model="formModel.regionInfo"></nt-area-cascade>
        </nt-form-item>
        <nt-form-item label="地址">
          <el-input placeholder="请输入地址" v-model="formModel.addressDetail"/>
        </nt-form-item>
        <nt-form-item label="备注" class="no-margin-bottom">
          <el-input :rows="2" placeholder="请输入备注" v-model="formModel.remark" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top-16 height-auto">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import NtFormItem from '@/views/plugin/nt_form_item'
import NtAreaCascade from '@/views/plugin/nt_area_cascade'
export default {
  name: "address_detail",
  mixins: [DataEditMixin],
  components: { 
    NtFormItem,
    NtAreaCascade
   },
  data() {
    return {
    }
  },
  props: {
    entity:{},
    addressType:{
      type:String,
      required:true
    }
  },
  created() {
  },
  methods: {
    async _loadEntity() {
      if (this.entity?.id) {
        return await this.getEntity(this.EnumEntityType.ADDRESS, { id: this.entity.id })
      } else if(this.entity){ // 通过平台选择地址
        return {data:this.entity}
      }
    },
    _loadFinished() {
      if(this.formModel.provinceCode && this.formModel.cityCode && this.formModel.districtCode){
        this.formModel.regionInfo = JSON.stringify([this.formModel.provinceCode, this.formModel.cityCode, this.formModel.districtCode])
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entity.id,shopId:this.shopId,addressType:this.addressType}, this.formModel)

      postData = JSON.parse(JSON.stringify(postData))
      let objPaths = this.$refs.refRegion.getSelectPathObject(true)
      if(objPaths.length>=3){
        postData.provinceCode = objPaths[0].code
        postData.provinceName = objPaths[0].name
        postData.cityCode = objPaths[1].code
        postData.cityName = objPaths[1].name
        postData.districtCode = objPaths[2].code
        postData.districtName = objPaths[2].name
      }
      return this.saveEntity(this.EnumEntityType.ADDRESS, postData)
    }
  }
}
</script>