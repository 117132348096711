<template>
  <el-dialog v-model="isVisible" destroyOnClose width="850px" title="条件设置" :z-index="99"
      @close="isVisible = false" @open="onDialogOpen">
    <el-container style="height:500px;">
      <el-main class="nt-bg-page padding">
        <el-container class="nt-height-100">
          <el-aside class=" padding-8  nt-bg-white" width="240px">
            <el-table border :data="fixedFieldList" height="100%" size="small">
              <el-table-column label="固定条件" prop="label">
              </el-table-column>
            </el-table>
          </el-aside>
          <el-main class="margin-left margin-right  padding-8 nt-bg-white">
            <el-table border :data="customFields" height="100%"  size="small">
              <el-table-column label="已选查询字段" prop="label">
              </el-table-column>
              <el-table-column width="60">
                <template #default="{row,$index}">
                  <el-space direction="vertical" size="small">
                    <el-button size="small" :disabled="$index==0" class="no-padding" style="width:15px;height:15px" :icon="Top" circle @click="onMoveUpClick($index)" ><el-icon><Top /></el-icon></el-button>
                    <el-button size="small" :disabled="$index==customFields.length-1" class="no-padding" style="width:15px;height:15px" circle @click="onMoveDownClick($index)" ><el-icon><Bottom /></el-icon></el-button>
                  </el-space>
                </template>
              </el-table-column>
            </el-table>
          </el-main>
          
          <el-aside class="nt-bg-page nt-box-panel padding-8 nt-bg-white" width="240px">
            <el-table ref="refTable" border :data="selectableFieldList" height="100%"  size="small" @select="onRowCheckboxChange" @select-all="onSelectAllChange">
              
              <el-table-column label="可选查询字段" prop="label">
              </el-table-column>
              <el-table-column type="selection" width="45" align="center" header-align="center"></el-table-column>
            </el-table>
          </el-aside>
        </el-container>
      </el-main>
      <el-footer class="height-auto no-padding no-margin align-right margin-top-8">
        <el-button @click="isVisible=false">取消</el-button>
        <el-button type="primary" @click="onConfirmClick">确定</el-button>
      </el-footer>
    </el-container>
    
  </el-dialog>
</template>

<script>

import { getTenantKvByConfigKey, saveTenantKv } from '@/service/resource/general'
import {LoginUserStore} from '@/piana/login_user_service'
import editors from './config/query_editor'
  export default {
    name:"query_setting",
    data () {
      return {
        isVisible:false,
        customFields:[],
        editors: editors.filter(el=>el.isQuick!=true), // 排除 快捷方案使用
      }
  },
  computed:{
    fixedFieldList:function(){ // 固定查询条件
      return this.editors.filter(item=>item.isFixed)
    },
    selectableFieldList(){
      return this.editors.filter(item=>!item.isFixed)
    }
  },
  async mounted() {
    let config = LoginUserStore().getConfig('PRINT_QUERY_FIELD')
    if(config){
      let arFeidls = (config.configValue||'').split(',')
      setTimeout(_=>{
        arFeidls.forEach(key=>{
          let matchItem = this.editors.find(item=>item.queryKey==key)
          if(matchItem){ // 过滤已选择
            this.customFields.push(matchItem)
          }
        })
      },0)
    }
  },
  methods:{
    showDialog(){
      this.isVisible= true
    },
    onDialogOpen(){
      this.customFields.forEach(row=>this.$refs.refTable?.toggleRowSelection(row,true))
    },
    onRowCheckboxChange(all,row){
      let matchIndex = this.customFields.findIndex(el=>el.queryKey==row.queryKey)
      if(matchIndex==-1){
        this.customFields.push(row)
      } else {
        this.customFields.splice(matchIndex,1)
      }
    },
    onMoveUpClick(index){
      let item = this.customFields.splice(index, 1)[0]
      this.customFields.splice(index-1, 0, item)
      this.customFields = [...this.customFields] // 强制变更内存地址，触发table组件的watch 事件 watched: 'config.columns'
    },
    onMoveDownClick(index){
      let item = this.customFields.splice(index, 1)[0]
      this.customFields.splice(index+1, 0, item)
      this.customFields = [...this.customFields] // 强制变更内存地址，触发table组件的watch 事件 watched: 'config.columns'
    },
    onSelectAllChange(rows){
      if(rows.length==0){ // 清空
        this.customFields=[]
      } else { //全选
        rows.forEach(row=>{
          let matchIndex = this.customFields.findIndex(el=>el.queryKey==row.queryKey)
          if(matchIndex==-1){
            this.customFields.push(row)
          }
        })
      }
    },
    async onConfirmClick(){
      let ar = this.customFields.map(el=>el.queryKey)
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION',configKey: 'PRINT_QUERY_FIELD', configValue: ar.join(',') }
      
      let res = await saveTenantKv(postData)
      if(res.code == 200){
        this.$emit('success', ar) // 与勾选框的checkbox -change事件冲突，改为success
        this.isVisible = false
        LoginUserStore().updateConfig('PRINT_QUERY_FIELD',postData)
        this.$message.success("保存成功")
        
      } else{
        this.$message.error(res.msg || res.message || res.data || '保存失败')
      }
    }
  }
}
</script>
