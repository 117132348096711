
<!-- 库存成本明细账 -->
<template>
  <nt-rpt-layout :footer="false">
    <template #toolbar>
      <nt-rpt-toolbar @command="onToolbarClick" :hidden="[]">
        <template #title>库存成本明细账</template>
      </nt-rpt-toolbar>
    </template>
    <template #header>
      <el-form inline @keyup.enter.native="onSearchQueryClick">
        <el-form-item label="按仓库1 小计"></el-form-item>
        <el-form-item label="销售时间">
          <NTDateRange v-model:startTime="queryModel.startTime" v-model:endTime="queryModel.endTime"  format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"></NTDateRange>
        </el-form-item>
        <el-form-item label="商品信息">
          <el-input placeholder="条码/名称/规格/型号/颜色" v-model="queryModel.materialParam"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearchQueryClick">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #body>
      <el-table border ref="table" size="middle" height="100%" class="nt-rpt-table" :data="datasource" v-loading="isLoading">
        <el-table-column label="仓库" prop="storeName" width="100">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" width="260">
        </el-table-column>
        <el-table-column label="主单位" prop="unitName" width="80">
        </el-table-column>
        <el-table-column label="单据日期" prop="billTime" width="110">
          <template #default="{row}">
            <span v-if="row.isDetail">{{formatShortDate(row.billTime) }}</span>
          </template>
        </el-table-column>
        <nt-bill-no-column label="单据编码" width="165"></nt-bill-no-column>
        <!-- <el-table-column label="单据号" prop="billNo" width="180">
        </el-table-column> -->
        <el-table-column label="单据类型" prop="billType" width="120">
          <template #default="{row}">
            <!-- {{ this.EnumBillType }}
            {{this.EnumBillType[scope.row.billType] }} -->
            <span v-if="row.isDetail">{{EnumBillType[row.billType] || row.billType}}</span>
            <span v-else>库存期初</span>
            
          </template>
        </el-table-column>
        <el-table-column label="往来单位" prop="companyName" width="180">
        </el-table-column>
        
        
        <el-table-column label="本期入库" align="center" header-align="center">
          <el-table-column label="主数量" width="90" prop="inBaseQuantity" header-align="center">
          </el-table-column>
          <el-table-column label="单价"  width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.inCostTotal && (row.inBaseQuantity||0)!=0">{{ (row.inCostTotal/ row.inBaseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" prop="inCostTotal" align="right" header-align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="本期出库" align="center">
          <el-table-column label="主数量" width="90" prop="outBaseQuantity" header-align="center">
          </el-table-column>
          <el-table-column label="单价"  width="90" header-align="center">
            <template #default="{row}">
              <span v-if="row.outCostTotal && (row.outBaseQuantity||0)!=0">{{ (row.outCostTotal/ row.outBaseQuantity).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" prop="outCostTotal" align="right" header-align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column label="期末结存" align="center">
          <el-table-column label="数量"  width="90"  align="right" header-align="center">
            <template #default="{row}">
              {{ row.lastBaseQuantity}}
            </template>
          </el-table-column>
          <el-table-column label="单价"  width="90"  align="right" header-align="center">
            <template #default="{row}">
              <span v-if="row.lastBaseQuantity != 0">{{ ( row.lastTotal/row.lastBaseQuantity).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="90" align="right" header-align="center">
            <template #default="{row}">
              {{ row.lastTotal}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
        </el-table-column>

      </el-table>
    </template>
    <template #footer>
      
    </template>
  </nt-rpt-layout>
  
  <el-drawer v-model="isDetailVisible" destroyOnClose size="75%" class="nt-resource-dialog" title="详情"
    @close="isDetailVisible = false">
    <TradeItemPage @cancel="isDetailVisible = false" :query="subQuery" v-if="isDetailVisible"></TradeItemPage>
  </el-drawer>
</template>
<script>
import NtRptLayout from '@/views/layout/nt_rpt_layout'
import NtRptToolbar from '@/views/plugin/nt_rpt_toolbar'
import { getStockCostDetail } from '@/service/report/rpt_repository'
import { DataListMixin } from '@/utils/data_list_mixin.js'
import TradeItemPage from '@/views/report/detail/trade_item'
import NTDateRange from '@/views/plugin/nt_date_range'
export default {
  name: "MaterialStock",
  mixins: [DataListMixin],
  components: {
    NtRptLayout,
    NtRptToolbar,
    TradeItemPage,
    NTDateRange
  },
  data() {
    return {
      isDetailVisible: false,
      toggleSearchStatus: false,
      subQuery: null, //详情查询条件
      // 查询条件
      queryModel: {
        categoryId: '',
        materialParam: '',
        createTimeRange: '',
        startTime: this.moment().format('YYYY-MM-01'),
        endTime: this.moment().format('YYYY-MM-DD'),
      },
      depotSelected: [],
      depotList: [],

    }
  },
  created() {
  },
  methods: {
    async _loadList(params) {
      delete params.createTimeRange
      return await getStockCostDetail(Object.assign(params, {}))
    },
    _loadFinished() {
    },
    onSearchQueryClick() {
      this.loadList();
    },
    getBaseQuantitySubtotal(row){
      return (row.initBaseQuantity||0)  + (row.inBaseQuantity||0)  - (row.outBaseQuantity||0) 
    },
    getSubtotal(row){
      return (row.initTotal||0)  + (row.inCostTotal||0)  - (row.outCostTotal||0) 
    },
  }
}
</script>

