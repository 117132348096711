
<template>
  <el-container class="no-padding" style="height:100%;background:#fff;">
    
    <el-main class="no-padding">
      <el-table height="100%" border ref="table" size="middle" :data="datasource"
        v-loading="isLoading">
        <el-table-column label="物流公司"  prop="deliveryName"></el-table-column>
      </el-table>
    </el-main>
    <el-footer class="padding-8" style="height:auto;border-top:1px solid #f5f5f5;">
      <el-row type="flex" justify="end">
        <el-pagination :current-page="this.pageInfo.pageIndex" :page-size="this.pageInfo.pageSize"
          :page-sizes="[10, 30, 50, 100]" :total="this.pageInfo.total" background
          layout="prev, next" @current-change="onPageIndexChange"
          @size-change="onPageSizeChange" />
      </el-row>
    </el-footer>
  </el-container>

</template>
<script>

import { DataListMixin } from '@/utils/data_list_mixin.js'
import NtFormItem from '@/views/plugin/nt_form_item'
export default {
  name: "ShopDeliveryList",
  mixins: [DataListMixin],
  components: {
    NtFormItem
  },
  data() {
    return {

    }
  },
  methods: {
    async _loadList(params) {
      return await this.getPageEntityList(this.EnumEntityType.SHOP_DELIVERY, params);
    }
  }
}
</script>