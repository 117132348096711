/**
 * 表单项校验规则生成工具类
 */
var _RULES_ = {
    max: (trigger='change',val,msg) =>{
        return {
            trigger: trigger,
            validator: (rule,value) =>{
                if(value){
                    if(isNaN(value)){
                        return Promise.reject(new Error(msg||'请输入有效数字'))
                    }
                    if(parseInt(value) >parseInt(val)){
                        return Promise.reject(new Error(msg||'请检查最大值'))
                    }
                }
                return Promise.resolve()
            }
        }
    },
    required: (trigger='change',_,msg) =>{
        return {
            required: true,
            trigger:trigger,
            message: msg|| '内容不能为空'
        }
    }
}

/**
 * exp 校验表达式 required|min(10)|max(11)|maxLen(1)
 * customMsg: 自定义提示消息
 */
export default function(exp,customMsg){
    if(!exp) return null
    let ruleItemList = exp.split('|')
    let vFnList  = [] // 解析表达式后，找到对应的校验函数
    ruleItemList.map( item => {
        var res = item.match(/\(([^)]*)\)/) // 解析括号参数值
        var ruleName = item
        var pVal = null //括号内参数值
        if(res){
            pVal=res[1]
            ruleName = item.substr(0,res.index)
        }
        let validatorFunc = _RULES_[ruleName]
        if(validatorFunc){
            let invokeResult = validatorFunc('blur',pVal,customMsg)
            if(invokeResult instanceof Array){
                vFnList.push(...invokeResult)
            } else {
                vFnList.push(invokeResult)
            }
        } else{
            console.error(ruleName + '规则校验规则未定义')
        }
    })
    return vFnList
}