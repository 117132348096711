<!-- 订单拆分 -->
<template>
  <el-container class="no-padding">
    <el-main class="padding">
      <el-table :data="datasource" :columns="columns" row-key="id" border  style="height:200px;">
        <el-table-column label="商品Id" prop="goodsId"></el-table-column>
        <el-table-column label="商品名称" prop="goodsName"></el-table-column>
        <el-table-column label="数量" prop="quantity"></el-table-column>
        <el-table-column label="已拆出" prop="splitQuantity"></el-table-column>
        <el-table-column label="已打包数" prop="packageCount"></el-table-column>
        <el-table-column label="本次拆分数量">
          <template #default="{ row }">
            <el-input style="margin: -5px 0" v-model="row.operateQuantity" />
          </template>
        </el-table-column>
        <!-- <template slot="customRender" slot-scope="text, record, index, column">
            <span>{{ text }}</span>
          </template>
          <template slot="splitRender" slot-scope="text, record, index, column">
            <el-input
            style="margin: -5px 0"
            :value="record.operateQuantity"
            @change="e => onQuantityChangeHandler(e.target.value, record.id, column)"/>
          </template> -->
      </el-table>
    </el-main>
    <el-footer class="align-center no-padding padding-top" style="height:auto;padding-top:8px;">
      <el-button @click="onConfirmHandler" style="margin-right:12px;" type="primary"> 确定</el-button>
      <el-button @click="onCancelHandler"> 取消</el-button>
    </el-footer>
  </el-container>
</template>

<script>

import { getOrder, splitOrder } from '@/service/print_order/order'

export default {
  name: "splitOrder",
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      datasource: [],
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  async created() {
    this.isLoading = true
    let res = await getOrder(this.order.id)
    this.isLoading = false
    if (res.code === 200) {
      let list = res.data.orderItemList || []
      // 根据明细项已被拆出，被打包，初始化可拆出数量
      list.forEach(el => {
        // 可拆数量为0是，禁止编辑
        el.operateQuantity = 1
      })
      this.datasource = list
    }

  },
  methods: {
    async onConfirmHandler() {
      // 过滤出当前需要拆分的商品信息
      let splitItemList = []
      this.datasource.forEach(el => {
        if (el.operateQuantity) {
          // 复制明细项,加工数据，按照后台的处理格式
          let item = Object.assign({}, el, { quantity: el.operateQuantity })
          delete item.operateQuantity
          splitItemList.push(item)
        }
      })
      if (splitItemList.length == 0) {
        this.$message.error("请输入需要拆分的商品数量")
        return;
      }
      let res = await splitOrder({
        orderId: this.order.id,
        orderItems: splitItemList
      })

      if (res.code == 200) {
        this.$message.success("操作成功")
        this.$emit("success")
      } else {
        this.$message.error(res.msg)
      }

    },
    onCancelHandler() {
      this.$emit("cancel")
    }
  }
}
</script>
