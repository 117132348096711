<template>
  <div style="height:100%;">
    <router-view />
  </div>
</template>

<script>

  export default {
    name: "BlankLayout",
  }
</script>

<style scoped>

</style>