<template>
  <el-form :model="formModel" ref="frmEdit" label-width="120" v-loading="isLoading">
    <el-container class="no-padding" style="background:#fff;">
      <el-header class="no-margin no-padding" style="height:auto;padding-bottom:0px;">

      </el-header>
      <el-main class="no-padding">
        <nt-form-item prop="paymentMethodId" label="结算方式" :rules="formValidator('required', '请选择结算方式')">
          <nt-dropdown-grid configKey="paymentMethod" style="width:100%" v-model="formModel.paymentMethodId" v-model:text="formModel.paymentMethodName"
            :disabled="viewOnly" placeholder="请选择结算方式" :searchable="true" :iconSearch="false" @choose="onPaymentMethodChoose"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item prop="accountId" label="结算账户" :rules="formValidator('required', '请选择结算账户')">
          <nt-dropdown-grid configKey="account" v-model="formModel.accountId" v-model:text="formModel.accountName"
            :disabled="viewOnly" placeholder="请选择结算账户" :searchable="true" :iconSearch="false" @choose="onAccountChoose"></nt-dropdown-grid>
        </nt-form-item>
        <nt-form-item label="退款金额">
          <el-input :rows="2" placeholder="退款金额" v-model="formModel.total" />
        </nt-form-item>
        <nt-form-item label="退款备注">
          <el-input :rows="2" placeholder="请输入备注" v-model="formModel.remark" />
        </nt-form-item>
      </el-main>
      <el-footer class="no-padding padding-top" style="height:auto;border-top:1px solid #f5f5f5;">
        <el-row type="flex" justify="end">
          <el-space>
            <el-button @click="onCancelClick">取消</el-button>
            <el-button type="primary" :loading="isSubmitting" @click="onSubmitClick(false)">保存</el-button>
          </el-space>
        </el-row>
      </el-footer>
    </el-container>
  </el-form>
</template>
<script>

import { DataEditMixin } from '@/utils/data_edit_mixin'
import {createRefundOrder} from '@/service/financial/account_head'
import NtFormItem from '@/views/plugin/nt_form_item'
import { EnumEntityType } from '@/utils/enum_utils'
export default {
  name: "ORDER_DEPOST_REFUND",
  mixins: [DataEditMixin],
  components: { NtFormItem },
  data() {
    return {
      billType: this.billType,
      entityType:EnumEntityType.INCOME_HEAD  // 浏览特定位置单据时使用，bill_mixin
    }
  },
  props: {
    billType: {
      type: String,
      required:true
    },
    orderId: {
      type: String,
      required:true
    }
  },
  created() {
  },
  methods: {
    async _loadEntity() {
      
    },
    _loadFinished() {
    },
    onPaymentMethodChoose(val){
      if(!this.formModel.accountId &&  val.accountId){
        this.formModel.accountId = val.accountId
        this.formModel.accountName = val.accountName
      }
    },
    onAccountChoose(val){
      if(!this.formModel.paymentMethodId && val.paymentMethodId){
        this.formModel.paymentMethodId = val.paymentMethodId
        this.formModel.paymentMethodName = val.paymentMethodName
      }
    },

    async _checkSubmit() {
      // 数据校验
      let isValid = await this.$refs.frmEdit.validate().catch(_ => false)

      if (!isValid) return false //校验成功返回的是具体的字段+值

      return true
    },
    async _saveEntity() {
      let postData = Object.assign({ id: this.entityId }, this.formModel)
      // 构造支付明细
      let accountItem = {
        billType: this.billType,
        billId: this.orderId,
        billTotal:0,
        total:postData.total,
        remark:'订单预付款退款'
      }
      postData = JSON.parse(JSON.stringify(postData))
      postData.accountItemList = [accountItem]
      let res = await createRefundOrder(postData)
      return Object.assign(res,{data:this.formModel}) // 模拟基础edit处理能力
    },
  }
}
</script>