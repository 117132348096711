<!-- 下拉选择框 -->
<template>
  <div :class="`nt-custom ${getHighlightClass()} display-flex ${config?'config':''}`" :style="{columnGap:'0px'}">
    <div class="config-label" v-if="config">{{ label }}</div>
    <el-select :title="placeholder" v-model="value" v-bind="$attrs" :placeholder="placeholder" multiple
    collapse-tags-tooltip collapse-tags
     clearable size="small" :style="{width:(width||140)+'px'}" @change="onSelectChange">
      <el-option
        v-for="item in flags"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <div class="display-flex">
          <el-icon :color="item.color" :size="18" ><Flag/></el-icon>
          <span >{{ item.label }}</span>
        </div>
      </el-option>
      <template #tag>
        <el-icon v-for="item in value" :key="item" :color="getColor(item)"  :size="18" ><Flag/></el-icon>
      </template>
    </el-select>
  </div>
  
</template>

<script>
import {LoginUserStore} from '@/piana/login_user_service'
  export default {
    data () {
      return {
        flags:[],
        value: this.converFlagToArray(this.modelValue?.value),
        defaultValue : Object.assign({}, this.modelValue)
      }
    },
    props:{
      modelValue:{},
      
      placeholder:{
        type:String
      },
      width:{
        type:Number
      },
      config:{
        type:Boolean,
        default:false
      },
      label:{
        type:String
      }
    },
    watch:{
      modelValue:function(){
        // 将值转为数组
        this.value = this.converFlagToArray(this.modelValue?.value)
        this.defaultValue = Object.assign({}, this.modelValue)
      }
    },
    created(){
      let colors={
        1:'#FF4D4A',2:'#217EFF',4:'#FFC619',8:'#17BE6B'
      }
      let configItem =  LoginUserStore().getConfig('FLAG_REMARK')
      let flagConfig  = {}
      if(configItem){
        flagConfig = JSON.parse(configItem.configValue||null) || {}
      }
      this.flags = Object.keys(colors).map(key=>({
          color: colors[key],
          label: flagConfig[key] || `自定义旗帜${key}`,
          value: parseInt(key)})
      )
    },
    methods:{
      converFlagToArray(val){
        let ar = []
        if(val){
          [1,2,4,8].forEach(item=>{
            if((item & val) == item ){
              ar.push(item)
            }
          })
        }
        
        return ar
      },
      getColor(flag){
        let colors={
          1:'#FF4D4A',2:'#217EFF',4:'#FFC619',8:'#17BE6B'
        }
        return colors[flag]
      },
      getHighlightClass(){
        // console.log('xxxx',this.defaultValue)
        // let defaultV = this.defaultValue.reduce((cur,next)=>cur | next,0)
        // let currentV = this.value.reduce((cur,next)=>cur | next,0)
        // console.log('check',defaultV,currentV)
        // return defaultV != currentV? 'highlight':''
      },
      onSelectChange(){
        let result = this.value.reduce((cur,next)=>cur | next,0)
        
        let data = {
          value: result
        }
        this.$emit('update:modelValue', Object.assign({},this.defaultValue, data))
      }
    }
}
</script>

<style lang="less" scoped>
  .nt-custom{
    &.highlight{
      :deep(.el-select__wrapper){
        background-color: var(--el-color-primary-light-5) !important;
        color:#666;
        border:1px solid var(--el-color-primary);
        font-weight: bold;
      }
    }
    .config-label{
      display: none;
      font-size:12px;
      padding:3px 4px;
      box-sizing: border-box;
      background-color: var(--el-fill-color);
      border:1px solid var(--el-border-color);
      border-right:0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width:90px;
      height: 24px;
    }
    &.config{
      .config-label{
        display: block;
      }
      :deep(.el-select){
        flex:1;
        .el-select__wrapper{
          box-shadow: none;
          
          border:1px solid var(--el-border-color);
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      
      }
    }
    
  }
</style>