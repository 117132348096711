// import Vue from 'vue'
// import Router from 'vue-router'
import { createRouter,createWebHistory, createWebHashHistory } from 'vue-router'
import { constantRouterMap } from '@/config/router.config' // 固定路由配置文件

//update-begin-author:taoyan date:20191011 for:TASK #3214 【优化】访问online功能测试 浏览器控制台抛出异常
// try {
//   const originalPush = Router.prototype.push
//   Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
//   }
// } catch (e) {
// }
//update-end-author:taoyan date:20191011 for:TASK #3214 【优化】访问online功能测试 浏览器控制台抛出异常

// Vue.use(Router)

// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRouterMap
// })

// 加载系统全量路由信息，根据t_function_menu 生成全量路由信息 TODO
// 路由跳转时，不通过 next  进行路由加载

const router = createRouter({
  history:  createWebHistory(),//createWebHashHistory(),
  routes:constantRouterMap
})

export default router