<template>
  <el-popover :width="300" trigger="click">
    <template #reference>
      <el-icon title="收货设置" style="cursor:pointer;" color="var(--el-color-primary)"><Setting /></el-icon>
    </template>
    <template #default>
      <div class="border-bottom padding-bottom-8" style="font-weight: bold;">收货设置</div>
      <div class=" display-flex">
        <span style="font-size:14px;font-weight:bold;">开启异常址检测</span>
        <el-switch @change="onSaveAbnoramlAddress" :active-value="1" :inactive-value="0"
          v-model="abnormalConfig.isOpen"
        />
      </div>
      <div style="font-size:12px; color:#666;">
        开启后自动为您标识出可能存在 “骗运费” 风险的异常地址订单
      </div>
      <div class="margin-top display-flex">
        <span style="font-size:14px;font-weight:bold;">设置偏远地区高亮：</span>
        <el-link type="primary" @click="onGotoClick('/print/farArea')">前往设置</el-link>
      </div>
      <div style="font-size:12px; color:#666;">
        自定义设置按关键字高亮显示需要关注的收件信息
      </div>
      <div class="margin-top display-flex">
        <span style="font-size:14px;font-weight:bold;">不可达地区打印拦截</span>
        <el-switch @change="onSaveRejectAddress" :active-value="1" :inactive-value="0"
          v-model="rejectConfig.isOpen"
        />
      </div>
      <div style="font-size:12px; color:#666;">
        开启拦截后，打印时可跳过不可达地区的订单
      </div>
    </template>
  </el-popover>
</template>
<script >

import {saveTenantKv} from '@/service/resource/general'
import { LoginUserStore } from '@/piana/login_user_service'
export default {
  data() {
    return {
    }
  },
  computed:{
    abnormalConfig: function () {
      let matchItem = LoginUserStore().getConfig('ABNORMAL_ADDRESS')

      return JSON.parse(matchItem?.configValue || null) || { isOpen: 0 }
    },
    rejectConfig: function () {
      let matchItem = LoginUserStore().getConfig('REJECT_AREA')
      return JSON.parse(matchItem?.configValue || null) || { isOpen: 0 }
    }
  },
  methods: {
    async onSaveAbnoramlAddress(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'ABNORMAL_ADDRESS', configValue: JSON.stringify(this.abnormalConfig) }
      LoginUserStore().updateConfig('ABNORMAL_ADDRESS',postData)
      let res = await saveTenantKv(postData)
      if (res.code !== 200) {
        this.$message.error(res.msg || '操作失败')
      }
    },
    async onSaveRejectAddress(){
      let postData = { parentKey: 'CUSTOM_CONFIG', configType:'PRINT_OPTION', configKey: 'REJECT_AREA', configValue: JSON.stringify(this.rejectConfig) }
      LoginUserStore().updateConfig('REJECT_AREA',postData)
      let res = await saveTenantKv(postData)
      if (res.code !== 200) {
        this.$message.error(res.msg || '操作失败')
      }
    },
    onGotoClick(url){
      this.$router.push(url);
    }
  }
}
</script>
